import { initLoadable, Loadable } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import {
  IGetHistoricalBalancesHandlerResponse,
  IHistoricalAccountBalance,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { RootState } from '../../../../app/store';
import { useSelector } from 'react-redux';

export type AccountState = {
  balances: Loadable<IHistoricalAccountBalance[]>;
};

const initialState: AccountState = {
  balances: initLoadable<IHistoricalAccountBalance[]>(),
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    getBalancesAction: (state, action: PayloadAction<{ accountId: string; start: ReduxDate; end: ReduxDate }>) => {
      state.balances.loading = true;
      state.balances.loaded = false;
    },
    getBalancesSuccessAction: (state, action: PayloadAction<IGetHistoricalBalancesHandlerResponse>) => {
      state.balances.loading = false;
      state.balances.loaded = true;
      state.balances.value = action.payload.balances;
    },
    getBalancesFailAction: (state) => {
      state.balances.loading = false;
      state.balances.loaded = false;
    },
    clearBalancesAction: (state) => {
      state.balances = initLoadable<IHistoricalAccountBalance[]>();
    },
  },
});

export const { getBalancesAction, getBalancesSuccessAction, getBalancesFailAction, clearBalancesAction } =
  accountSlice.actions;

export const useAccounts = () => useSelector((state: RootState) => state.accounts);
