import React, { FC, useEffect, useRef, useState } from 'react';
import {
  ExpenseElementOption,
  ExpenseElementType,
  expensePaymentStatusMap,
  invoiceStatusMap,
  purchaseOrderStatusMap,
  quoteStatusMap,
  ReduxExpense,
  workOrderStatusMap,
} from '../redux/expenseData';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { ExpenseElementListItem } from './ExpenseElementListItem';
import { ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { useDispatch } from 'react-redux';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { useSearchHook } from '../../search/useSearchHook';

export type JobElementSelectProps = {
  value?: string;
  jobId?: string;
  onChange: (association: string, type: ExpenseElementType) => void;
} & Omit<
  AutocompleteProps<ExpenseElementOption, false, false, false>,
  'options' | 'value' | 'onChange' | 'getOptionLabel'
>;
export const ExpenseChildrenSelect: FC<JobElementSelectProps> = ({ jobId, onChange, ...autocompleteProps }) => {
  const { searchButtonGroupStyles, bigButtonInGroupStyles, littleButtonInGroupStyles } = useSearchHook();
  const [options, setOptions] = useState<ExpenseElementOption[]>([]);
  const [internalValue, setInternalValue] = useState<ExpenseElementOption | null>(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [optionsType, setOptionsType] = useState<ExpenseElementType | undefined>();
  const [selectedJob, setSelectedJob] = useState<ReduxExpense | undefined>();
  const buttonOptions = ['All', 'Payments', 'Invoices', 'POs', 'WOs', 'Quotes'];
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number, option: string) => {
    setSelectedIndex(index);
    setOpen(false);
    switch (option) {
      case 'Payments':
        setOptionsType(ExpenseElementType.Payment);
        break;
      case 'Invoices':
        setOptionsType(ExpenseElementType.Invoice);
        break;
      case 'Purchase Orders':
        setOptionsType(ExpenseElementType.PurchaseOrder);
        break;
      case 'Work Orders':
        setOptionsType(ExpenseElementType.WorkOrder);
        break;
      case 'Quotes':
        setOptionsType(ExpenseElementType.Quote);
        break;
      default:
        setOptionsType(undefined);
    }
  };
  const handleFillJobOptions = (job: ReduxExpense, type?: ExpenseElementType): ExpenseElementOption[] => {
    const workOrders: ExpenseElementOption[] =
      job.workOrders?.map((x) => {
        return {
          label: x.name ? `Work Order ${x.number}: ${x.name}` : 'Work Order',
          number: x.number,
          status: x.workOrderStatus && workOrderStatusMap.get(x.workOrderStatus),
          propertyAccountNo: x.propertyAccount?.id,
          type: ExpenseElementType.WorkOrder,
        };
      }) ?? [];
    const quotes: ExpenseElementOption[] =
      job.quotes?.map((x) => {
        return {
          label: x.name ? `Quote ${x.number}: ${x.name}` : 'Quote',
          number: x.number,
          amount: x.quoteAmount,
          vendorName: x.vendor?.name,
          status: x.expenseQuoteStatus && quoteStatusMap.get(x.expenseQuoteStatus),
          vendor: x.vendor,
          propertyAccountId: x.propertyAccount?.id,
          propertyAccountName: x.propertyAccount?.name,
          type: ExpenseElementType.Quote,
        };
      }) ?? [];
    const purchaseOrders: ExpenseElementOption[] =
      job.purchaseOrders?.map((x) => {
        return {
          label: x.name ? `Purchase Order ${x.number}: ${x.name}` : 'Purchase Order',
          number: x.number,
          amount: x.amount,
          vendorName: x.vendor?.name,
          status: x.purchaseOrderStatus && purchaseOrderStatusMap.get(x.purchaseOrderStatus),
          vendor: x.vendor,
          propertyAccountNo: x.propertyAccount?.id,
          type: ExpenseElementType.PurchaseOrder,
        };
      }) ?? [];
    const invoices: ExpenseElementOption[] =
      job.invoices?.map((x) => {
        return {
          label: x.name ? `Invoice ${x.number}: ${x.name}` : 'Invoice',
          number: x.number,
          amount: x.invoiceAmount,
          vendorName: x.vendor?.name,
          status: x.expenseInvoiceStatus && invoiceStatusMap.get(x.expenseInvoiceStatus),
          vendor: x.vendor,
          propertyAccountId: x.propertyAccount?.id,
          propertyAccountName: x.propertyAccount?.name,
          type: ExpenseElementType.Invoice,
        };
      }) ?? [];
    const payments: ExpenseElementOption[] =
      job.payments?.map((x) => {
        return {
          label: x.number ? `Payment ${x.number}: ${x.name}` : 'Payment',
          number: x.number,
          amount: x.amount,
          vendorName: x.vendor?.name,
          status: x.status && expensePaymentStatusMap.get(x.status),
          vendor: x.vendor,
          propertyAccountId: x.propertyAccount?.id,
          propertyAccountName: x.propertyAccount?.name,
          type: ExpenseElementType.Payment,
        };
      }) ?? [];
    switch (type) {
      case ExpenseElementType.Payment:
        return payments;
      case ExpenseElementType.WorkOrder:
        return workOrders;
      case ExpenseElementType.Invoice:
        return invoices;
      case ExpenseElementType.PurchaseOrder:
        return purchaseOrders;
      case ExpenseElementType.Quote:
        return quotes;
      default:
        return [...payments, ...invoices, ...purchaseOrders, ...workOrders, ...quotes];
    }
  };

  useEffect(() => {
    if (selectedJob) {
      setOptions(handleFillJobOptions(selectedJob, optionsType));
    }
  }, [optionsType]);

  useEffect(() => {
    if (!jobId) return;
    (async () => {
      try {
        setLoading(true);
        const response = await ApiClientSingleton.getInstance().expenses_GetExpense(jobId);
        if (response) {
          setLoading(false);
          const newOptions = handleFillJobOptions(response.toJSON().expenseDto);
          setOptions(newOptions);
          setSelectedJob(response.toJSON().expenseDto);
        }
      } catch {
        setLoading(false);
        dispatch(showToastMessageAction({ message: 'Error loading job children', severity: 'error' }));
      }
    })();
  }, [jobId]);

  const handleChange = (event: React.SyntheticEvent, newValue: ExpenseElementOption | null) => {
    if (newValue?.number && newValue.type) {
      onChange(newValue.number, newValue.type);
    }
    setInternalValue(newValue);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box display={'flex'} py={'1rem'}>
      <Box minWidth={'10rem'}>
        <ButtonGroup
          sx={searchButtonGroupStyles}
          ref={anchorRef}
          variant={'outlined'}
          size={'large'}
          aria-label="search type split button"
        >
          <Button sx={bigButtonInGroupStyles}>{buttonOptions[selectedIndex]}</Button>
          <Button
            size="small"
            aria-controls={open ? 'search-type-split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select search type"
            aria-haspopup="menu"
            onClick={handleToggle}
            sx={littleButtonInGroupStyles}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 999999,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="search-type-split-button-menu" autoFocusItem>
                    {buttonOptions.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index, option)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
      <Autocomplete
        fullWidth
        options={options}
        onChange={handleChange}
        loading={loading}
        value={internalValue as any}
        {...autocompleteProps}
        renderOption={(props, option) => {
          return <ExpenseElementListItem option={option} {...props} />;
        }}
      />
    </Box>
  );
};
