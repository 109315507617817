import React, { FC, useEffect, useState } from 'react';
import { ExpandableCard } from '../../../_shared/styledComponents/ExpandableCard';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { getRecurringPaymentAction, useTenant } from '../../redux/tenantSlice';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import { Feature } from '../../../global/Feature';
import { RecurringPaymentDialog } from './RecurringPaymentDialog';
import { ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import {
  IRecurringPayment,
  LeaseDetailedStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useTheme } from '@mui/material';
import { useStatement } from '../../../_shared/statements/redux/statementSlice';
import { LeaseStatement } from '../../../_shared/statements/components/LeaseStatement';
import { useLeases } from '../../../_shared/leases/redux/leasesSlice';
import Tooltip from '@mui/material/Tooltip';

type PaymentsCardProps = {
  onOpenPaymentDialog: () => void;
  propertyHasPaymentsEnabled: boolean;
  focus: boolean;
  selectedLease?: ReduxLease;
  propertyHasCreditCardPaymentsEnabled?: boolean;
};

export const PaymentsCard: FC<PaymentsCardProps> = ({
  onOpenPaymentDialog,
  propertyHasPaymentsEnabled,
  focus,
  selectedLease,
  propertyHasCreditCardPaymentsEnabled,
}) => {
  const { bankAccounts, recurringPayment } = useTenant();
  const { leaseDepositBalance } = useLeases();
  const { selectedStatement } = useStatement();
  // const [balance, setBalance] = useState(0);
  const dispatch = useDispatch();
  const [autoPayDetails, setAutoPayDetails] = useState<IRecurringPayment | undefined>(
    recurringPayment.value?.find((a) => a.leaseId === selectedLease?.id),
  );
  const theme = useTheme();
  const autoPayTitle = autoPayDetails ? 'Update Recurring Payment' : 'Schedule Recurring Payment';
  const [openAutoPaymentDialog, setOpenAutoPaymentDialog] = useState(false);

  const handleCloseAutoPaymentDialog = () => setOpenAutoPaymentDialog(false);
  const userHasBankAccountsEnabled = bankAccounts.value && bankAccounts.value.length > 0;

  const hasDepositBalance = (leaseDepositBalance.value ?? 0) > 0;

  const disablePayments: boolean =
    selectedLease?.detailedStatus === LeaseDetailedStatus.ClosedEviction ||
    selectedLease?.detailedStatus === LeaseDetailedStatus.ReturnFundsEvicted ||
    selectedLease?.detailedStatus === LeaseDetailedStatus.MovedOutEviction ||
    selectedLease?.detailedStatus === LeaseDetailedStatus.ActivePendingEviction ||
    selectedLease?.detailedStatus === LeaseDetailedStatus.ActiveMonthToMonthPendingEviction ||
    hasDepositBalance;

  useEffect(() => {
    dispatch(getRecurringPaymentAction());
  }, []);

  useEffect(() => {
    setAutoPayDetails(recurringPayment.value?.find((a) => a.leaseId === selectedLease?.id));
  }, [recurringPayment.value, selectedLease?.id]);
  // if (selectedStatement.loading)
  //   return <Skeleton variant={'rectangular'} animation={'wave'} width={'100%'} height={250} />;
  return (
    <Stack spacing={1}>
      <ExpandableCard
        title={'Payments'}
        subtitle={selectedLease ? `Current Balance: $${selectedStatement.value?.totalBalance}` : ''}
        focus={focus}
      >
        <Stack spacing={1}>
          <Feature flag={'CAM.Feature.Payments.TenantBilling'}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Stack spacing={2}>
                <LeaseStatement leaseId={selectedLease?.id} isTenantPortalView />
                {autoPayDetails && (
                  <Typography>
                    Auto-Pay is enabled using your bank account ending in **{autoPayDetails.lastFour} for $
                    {autoPayDetails?.amount} on day {autoPayDetails?.dayOfPayment} of each month.{' '}
                    {!autoPayDetails?.isDisabled &&
                      'If you wish to make changes, please update your recurring payment options below.'}
                  </Typography>
                )}
                {autoPayDetails?.isDisabled && (
                  <Typography sx={{ color: theme.palette.error.main }}>
                    **This recurring payment has been disabled. Please update your auto-pay options below to re-enable.
                  </Typography>
                )}

                {!propertyHasPaymentsEnabled && !selectedStatement.loading && (
                  <Typography>
                    You cannot currently make payments via the payment portal. Please contact your property manager for
                    details.
                  </Typography>
                )}
              </Stack>
            </CardContent>
            {propertyHasPaymentsEnabled && (
              <CardActions sx={{ justifyContent: 'right' }}>
                {(userHasBankAccountsEnabled || propertyHasCreditCardPaymentsEnabled) && (
                  <>
                    <Button
                      variant={'outlined'}
                      onClick={() => setOpenAutoPaymentDialog(true)}
                      disabled={disablePayments && !hasDepositBalance}
                    >
                      {autoPayTitle}
                    </Button>
                    <Tooltip title={hasDepositBalance ? 'Please see above to pay deposit balance first' : ''}>
                      <span>
                        <Button onClick={onOpenPaymentDialog} variant={'contained'} disabled={disablePayments}>
                          Make Payment
                        </Button>
                      </span>
                    </Tooltip>
                  </>
                )}
              </CardActions>
            )}
          </Feature>
        </Stack>
        <RecurringPaymentDialog
          open={openAutoPaymentDialog}
          onClose={handleCloseAutoPaymentDialog}
          title={autoPayTitle}
          selectedLeaseCurrentRent={selectedLease?.leaseTerms && selectedLease?.leaseTerms[0]}
          leaseId={selectedLease?.id}
          userHasAutoPay={!!autoPayDetails}
        />
      </ExpandableCard>
    </Stack>
  );
};
