import React from 'react';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ReportDisplay } from './ReportDisplay';
import { SelectReport } from './SelectReport';
import { CustomizeReport } from './CustomizeReport';
import { setSelectedReportAction, useReports } from '../../../../redux/reportSlice';
import { useDispatch } from 'react-redux';
import { AdditionalInfoReportDialog } from './AdditionalInfoReportDialog';

const steps = ['Select Report', 'Customize', 'Generate Report'];

export const Reports = () => {
  const dispatch = useDispatch();
  const { selectedReport } = useReports();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (activeStep === 1) dispatch(setSelectedReportAction(undefined));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    dispatch(setSelectedReportAction(undefined));
    setActiveStep(0);
  };

  return (
    <StyledInfoBox label={'Reports'}>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length - 1 ? (
          <React.Fragment>
            <ReportDisplay />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box my={2}>{activeStep === 0 ? <SelectReport /> : activeStep === 1 ? <CustomizeReport /> : <></>}</Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                variant={activeStep === steps.length - 2 ? 'outlined' : undefined}
                onClick={handleNext}
                disabled={selectedReport.value === undefined}
              >
                {activeStep === steps.length - 2 ? 'Generate Report' : 'Next'}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
      <AdditionalInfoReportDialog />
    </StyledInfoBox>
  );
};
