import React, { FC, useEffect } from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { FileUploadButton } from '../../../_shared/buttons/FileUploadButton';
import { useDispatch } from 'react-redux';
import { setIncludeArchivedAction, useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { Feature } from '../../../global/Feature';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { Claims } from '@monkeyjump-labs/cam-fe-shared/dist/types/constants';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Stack from '@mui/material/Stack';
import {
  uploadBulkAssetsAction,
  useDocuments,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/documents/documentSlice';

type AddPropertyButtonProps = {
  onOpenProperty: () => void;
  onOpenBulkAddAssets: () => void;
};

enum MenuOpen {
  CLOSED,
  MENU,
  BILLING_OWNER_SETTINGS,
}

export const AddPropertyButton: FC<AddPropertyButtonProps> = ({ onOpenProperty, onOpenBulkAddAssets }) => {
  const dispatch = useDispatch();
  const menuRef = React.useRef<HTMLDivElement>(null);
  const { currentUser } = useUser((u) => u);
  const [openMenu, setOpenMenu] = React.useState(MenuOpen.CLOSED);
  const { enableArchived } = useAssets();
  const { importExport } = useDocuments();

  useEffect(() => {
    importExport.submitted && setOpenMenu(MenuOpen.CLOSED);
  }, [importExport.submitted]);

  const handleSplitButtonToggle = (toggle: MenuOpen) => {
    setOpenMenu((prevOpen) => (prevOpen === toggle ? MenuOpen.CLOSED : toggle));
  };

  const handleClose = (event: Event) => {
    if (menuRef.current && menuRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpenMenu(MenuOpen.CLOSED);
  };

  const handleAssetUpload = (file: File) => {
    dispatch(uploadBulkAssetsAction({ file: file, includeArchived: enableArchived }));
  };

  const handleRefreshAssets = () => {
    setOpenMenu(MenuOpen.CLOSED);
    dispatch(setIncludeArchivedAction(!enableArchived));
  };

  const getMenuOptions = () => {
    if (openMenu === MenuOpen.MENU)
      return [
        <MenuItem key={'manually-add-property'} onClick={onOpenProperty}>
          Manually Add Property
        </MenuItem>,
        <Feature flag={'Feature.Properties.Import'} key="property-import-features">
          <MenuItem key={'download-bulk-import-assets'} onClick={onOpenBulkAddAssets}>
            Download Import Spreadsheet
          </MenuItem>
          <FileUploadButton
            isInMenu
            onUpload={handleAssetUpload}
            variant={'outlined'}
            loading={importExport.submitting}
          >
            Upload Assets
          </FileUploadButton>
        </Feature>,
      ];
    else if (openMenu === MenuOpen.BILLING_OWNER_SETTINGS)
      return <MenuItem onClick={handleRefreshAssets}>{enableArchived ? 'Hide' : 'Show'} archived items</MenuItem>;
  };

  return (
    <Stack direction="row" ref={menuRef}>
      <ButtonGroup
        variant="contained"
        aria-label="split button"
        sx={{ display: 'flex', flexGrow: '1', backgroundColor: 'primary.main' }}
      >
        <Button variant={'contained'} onClick={onOpenProperty} sx={{ flexGrow: 1 }}>
          Add Property
        </Button>
        <Button
          size="small"
          aria-controls={openMenu ? 'split-button-menu' : undefined}
          aria-expanded={openMenu ? 'true' : undefined}
          aria-label="select-merge-strategy"
          aria-haspopup="menu"
          onClick={() => handleSplitButtonToggle(MenuOpen.MENU)}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      {currentUser.value?.claims?.find((c) => c.type == Claims.BillingOwner) && (
        <IconButton
          onClick={() => handleSplitButtonToggle(MenuOpen.BILLING_OWNER_SETTINGS)}
          aria-label={'billing-owner-settings'}
        >
          <SettingsIcon />
        </IconButton>
      )}
      <Popper open={!!openMenu} anchorEl={menuRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {getMenuOptions()}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
};
