import React, { ChangeEvent, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import { uploadDocumentAction, useApplications } from '../../../../redux/applicationSlice';
import {
  ApplicationType,
  Gender,
  IContactInfo,
  IContactPerson,
  IPostalAddress,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { PhoneTextField } from '../../../../../_shared/texfields/PhoneTextField';
import { SsnTextField } from '../../../../../_shared/texfields/SsnTextField';
import { isSsnValid } from '../../../../../_shared/utils/ssnValidator';
import { DatePicker } from '@mui/x-date-pickers-pro';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FileUploadButton } from '../../../../../_shared/buttons/FileUploadButton';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { AddEmergencyContact } from './AddEmergencyContact';
import { ReduxApplication } from '../../../../redux/applicationTypes';
import Stack from '@mui/material/Stack';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { USStatesSelect } from '../../../../../_shared/texfields/USStateSelect';
import { DriversLicense } from '../../../../../_shared/texfields/DriversLicense';

type Props = {
  editingApplicant: ReduxApplication;
  onUpdateApplicant: (key: keyof ReduxApplication, value?: string | boolean) => void;
  onUpdateAddress: (key: keyof IPostalAddress, value?: string) => void;
  onUpdateContactInfo: (key: keyof IContactInfo, value?: string) => void;
  updateApplicantDetails: () => void;
  onUpdateEmergencyContact?: (key: keyof IContactPerson, value?: string) => void;
  onUpdateEmergencyContactInfo?: (key: keyof IContactInfo, value?: string) => void;
  editingInWizard?: boolean;
};

export const ApplicantInfo = ({
  editingApplicant,
  onUpdateApplicant,
  onUpdateAddress,
  onUpdateContactInfo,
  updateApplicantDetails,
  onUpdateEmergencyContact,
  onUpdateEmergencyContactInfo,
  editingInWizard,
}: Props) => {
  const dispatch = useDispatch();
  const { selectedApplication } = useApplications();
  const [ssnValue, setSsnValue] = useState('');
  const [ssnPlaceholderValue, setSsnPlaceholderValue] = useState(editingApplicant.lastFourSsn);

  const handleSsnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSsnValue(e.target.value);
    setSsnPlaceholderValue(e.target.value);
  };

  const handleSsnBlur = () => {
    const isValid = isSsnValid(ssnValue);
    if (isValid) {
      const temporaryPlaceholder = 'XXX-XX-' + ssnPlaceholderValue?.substring(ssnPlaceholderValue.length - 4);
      setSsnPlaceholderValue(temporaryPlaceholder);
      onUpdateApplicant('socialSecurityNo', ssnValue);
    } else {
      setSsnValue('');
      setSsnPlaceholderValue(editingApplicant.lastFourSsn);
    }
  };

  const handleDriverLicenseUpload = (file: File) => {
    //TODO: create separate action for DL upload, this is currently going to Documents not DLImage
    updateApplicantDetails();
    selectedApplication?.value?.id &&
      dispatch(
        uploadDocumentAction({
          applicationId: selectedApplication.value.id,
          file,
        }),
      );
  };

  if (editingApplicant) {
    return (
      <Stack spacing={2}>
        <StyledInfoBox label={'Applicant Info'}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                fullWidth
                required
                margin="dense"
                id="firstName"
                label="First Name"
                type="text"
                variant="standard"
                value={editingApplicant.firstName ? editingApplicant.firstName : ''}
                onChange={(e) => onUpdateApplicant('firstName', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                margin="dense"
                id="lastName"
                label="Last Name"
                type="text"
                variant="standard"
                value={editingApplicant.lastName ? editingApplicant.lastName : ''}
                onChange={(e) => onUpdateApplicant('lastName', e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <SsnTextField
                fullWidth
                disabled={!editingInWizard}
                required={!editingApplicant.lastFourSsn && editingInWizard}
                margin="dense"
                id="lastFourSsn"
                label="Social Security Number"
                type="text"
                variant="standard"
                onBlur={handleSsnBlur}
                InputLabelProps={{ shrink: true }}
                placeholder={ssnPlaceholderValue ? ssnPlaceholderValue : ''}
                value={ssnValue ? ssnValue : ''}
                onChange={(e) => handleSsnChange(e)}
              />
            </Grid>
            <Grid item xs={4}>
              <PhoneTextField
                fullWidth
                required
                margin="dense"
                id="phone"
                label="Phone Number"
                variant="standard"
                value={editingApplicant.contactInfo?.phone ? editingApplicant.contactInfo.phone : ''}
                onChange={(e) => onUpdateContactInfo('phone', e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                variant="standard"
                value={editingApplicant.contactInfo?.email ? editingApplicant.contactInfo?.email : ''}
                onChange={(e) => onUpdateContactInfo('email', e.target.value)}
              />
            </Grid>
            <Grid item xs={8}>
              <DriversLicense
                state={editingApplicant.driverLicenseState}
                licenseNumber={editingApplicant.driverLicenseNo}
                onChangeState={(value: string) => onUpdateApplicant('driverLicenseState', value)}
                onChangeLicenseNumber={(value) => onUpdateApplicant('driverLicenseNo', value)}
              />
            </Grid>
            <Grid item xs={4}>
              {editingApplicant.driverLicenseImage ? (
                <ListItem key={editingApplicant.driverLicenseImage.uri}>
                  <Link href={editingApplicant.driverLicenseImage.uri}>{editingApplicant.driverLicenseImage.name}</Link>
                </ListItem>
              ) : (
                <>
                  <FileUploadButton onUpload={handleDriverLicenseUpload} loading={selectedApplication.submitting}>
                    Upload License Image
                  </FileUploadButton>
                  <Typography variant="body2">Note: Uploaded image will appear in documents tab</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                label={'Birthday'}
                slotProps={{
                  textField: {
                    required: editingInWizard,
                    fullWidth: true,
                    sx: { mt: '-.1rem' },
                  },
                }}
                onChange={(date) => onUpdateApplicant('birthday', toReduxDate(date ?? new Date()))}
                value={toStandardDate(editingApplicant.birthday) ?? null}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth required={editingInWizard}>
                <InputLabel id="select-gender">Gender</InputLabel>
                <Select
                  labelId="select-gender"
                  id="gender"
                  value={editingApplicant.gender ? editingApplicant.gender : Gender.PreferNotToSay}
                  label="Gender"
                  onChange={(e) => onUpdateApplicant('gender', e.target.value)}
                >
                  <MenuItem value={Gender.PreferNotToSay}>Other/Prefer Not To Say</MenuItem>
                  <MenuItem value={Gender.Male}>Male</MenuItem>
                  <MenuItem value={Gender.Female}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="dense"
                id="streetAddress1"
                label="Street Address 1"
                type="string"
                variant="standard"
                value={
                  editingApplicant.contactInfo?.postalAddress?.streetAddress1
                    ? editingApplicant.contactInfo.postalAddress.streetAddress1
                    : ''
                }
                onChange={(e) => onUpdateAddress('streetAddress1', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="dense"
                id="streetAddress2"
                label="Street Address 2"
                type="text"
                variant="standard"
                value={
                  editingApplicant.contactInfo?.postalAddress?.streetAddress2
                    ? editingApplicant.contactInfo.postalAddress.streetAddress2
                    : ''
                }
                onChange={(e) => onUpdateAddress('streetAddress2', e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                margin="dense"
                id="city"
                label="City"
                type="text"
                variant="standard"
                value={
                  editingApplicant.contactInfo?.postalAddress?.city
                    ? editingApplicant.contactInfo.postalAddress.city
                    : ''
                }
                onChange={(e) => onUpdateAddress('city', e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <USStatesSelect
                value={
                  editingApplicant.contactInfo?.postalAddress?.state
                    ? editingApplicant.contactInfo.postalAddress.state
                    : ''
                }
                onChange={(value: string) => onUpdateAddress('state', value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                margin="dense"
                id="zip"
                label="Zip Code"
                type="number"
                variant="standard"
                value={
                  editingApplicant.contactInfo?.postalAddress?.zip ? editingApplicant.contactInfo.postalAddress.zip : ''
                }
                onChange={(e) => onUpdateAddress('zip', e.target.value)}
              />
            </Grid>
            {editingApplicant.applicationType === ApplicationType.Primary ? (
              <>
                <Grid item xs={3}>
                  <DatePicker
                    label={'Desired Move-In Date'}
                    slotProps={{
                      field: { clearable: true },
                      textField: { fullWidth: true, sx: { mt: '-.1rem' } },
                    }}
                    onChange={(date) => onUpdateApplicant('requestedMoveInDate', toReduxDate(date ?? new Date()))}
                    value={toStandardDate(editingApplicant.requestedMoveInDate) ?? null}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    id="unitRequested"
                    label="Desired Unit Type"
                    type="text"
                    variant="standard"
                    value={editingApplicant.unitRequested ? editingApplicant.unitRequested : ''}
                    onChange={(e) => onUpdateApplicant('unitRequested', e.target.value)}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      editingApplicant.consentForBackgroundCheck ? editingApplicant.consentForBackgroundCheck : false
                    }
                    onChange={(e) => {
                      onUpdateApplicant('consentForBackgroundCheck', e.target.checked);
                    }}
                  />
                }
                label="Consent to Background Check"
              />
            </Grid>
          </Grid>
        </StyledInfoBox>
        {editingApplicant.applicationType === ApplicationType.Primary &&
        onUpdateEmergencyContactInfo &&
        onUpdateEmergencyContact ? (
          <AddEmergencyContact
            editingApplicant={editingApplicant}
            onUpdateEmergencyContact={onUpdateEmergencyContact}
            onUpdateEmergencyContactInfo={onUpdateEmergencyContactInfo}
          />
        ) : (
          <></>
        )}
      </Stack>
    );
  } else return <></>;
};
