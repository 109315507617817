import React, { FC, FormEvent, useEffect, useState } from 'react';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ExpenseElementType, expenseStatusMap } from '../redux/expenseData';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { updateExpenseAction, updateExpenseStatusAction, useExpenses } from '../redux/expenseSlice';
import { useDispatch } from 'react-redux';
import { ExpenseStatusSelect } from './ExpenseStatusSelect';

export const ExpenseInfo: FC = () => {
  const { selectedExpense } = useExpenses();
  const dispatch = useDispatch();
  const [editingJob, setEditingJob] = useState(selectedExpense.value);
  const [dirty, setDirty] = useState(false);
  const job = selectedExpense.value;

  useEffect(() => {
    selectedExpense.submitted && setDirty(false);
  }, [selectedExpense.submitted]);

  const handleUpdateJob = (e: FormEvent) => {
    e.preventDefault();
    if (!job?.id || !editingJob) return;
    if (editingJob.name !== job.name || editingJob.description !== job.description) {
      dispatch(
        updateExpenseAction({
          id: job.id,
          body: editingJob,
        }),
      );
    }
    if (editingJob.expenseStatus !== job.expenseStatus) {
      dispatch(
        updateExpenseStatusAction({
          id: job.id,
          body: { propertyId: job.propertyId, status: editingJob.expenseStatus },
        }),
      );
    }
  };

  const handleStatusSelection = (status: string) => {
    Array.from(expenseStatusMap).map(([s]) => {
      if (status === s) {
        setEditingJob({ ...editingJob, expenseStatus: s });
      }
    });
    setDirty(true);
  };

  return (
    <StyledInfoBox label={'Expense Info'}>
      <form onSubmit={handleUpdateJob}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={'Name'}
              value={editingJob?.name ?? ''}
              onChange={(e) => {
                setDirty(true);
                setEditingJob({ ...editingJob, name: e.target.value });
              }}
              fullWidth
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={6}>
            <ExpenseStatusSelect
              value={editingJob?.expenseStatus}
              onChange={(e) => handleStatusSelection(e.target.value)}
              statusMap={expenseStatusMap}
              type={ExpenseElementType.Expense}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Description'}
              value={editingJob?.description ?? ''}
              onChange={(e) => {
                setDirty(true);
                setEditingJob({ ...editingJob, description: e.target.value });
              }}
              fullWidth
              multiline
              rows={4}
              variant={'outlined'}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
              <LoadingButton
                variant={'contained'}
                disabled={!dirty}
                loading={selectedExpense.submitting}
                type={'submit'}
              >
                Update Expense
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </StyledInfoBox>
  );
};
