import React, { FC, useEffect, useState } from 'react';
import { ReduxLeaseTerm } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { format, isAfter, isBefore } from 'date-fns';
import { ReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { RentInformationEditor } from './RentInformationEditor';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { IRentInfo } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { tryFormatDate } from '../../utils/TryFormatDate';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { LeaseStartAndEndDatePicker } from './LeaseStartAndEndDatePicker';
import Typography from '@mui/material/Typography';

export type RentCollectionInformationEditorProps = {
  isNew?: boolean;
  editable?: boolean;
  value: ReduxLeaseTerm[];
  onChange?: (newValue: ReduxLeaseTerm[]) => unknown;
  onSetStartDate?: (date: Date, selectedTerm: number) => void;
  onSetEndDate?: (date: Date, selectedTerm: number) => void;
  onSetCurrentLeaseTermsDirty?: () => void;
};

export const LeaseTermsEditor: FC<RentCollectionInformationEditorProps> = ({
  isNew,
  editable,
  value,
  onChange,
  onSetEndDate,
  onSetStartDate,
  onSetCurrentLeaseTermsDirty,
}) => {
  const property = useProperty();
  const currentPropertyDate = property.selectedProperty.value?.currentPropertyDate ?? new Date();
  const toUserFriendlyDateRange = (dateOne?: ReduxDate, dateTwo?: ReduxDate) => {
    const dateOneString = dateOne ? format(toStandardDate(dateOne), 'PPP') : 'Unset';
    const dateTwoString = dateTwo ? format(toStandardDate(dateTwo), 'PPP') : 'Unset';
    return `${dateOneString} - ${dateTwoString}`;
  };
  const rentOptions = value.map((term, index) => {
    return {
      key: index,
      value: toUserFriendlyDateRange(term.startDate, term.endDate),
    };
  });
  const [selectedRent, setSelectedRent] = useState<number>(0);

  useEffect(() => {
    value.map((leaseTerm, index) => {
      //currently this will only work in property if time traveling happens
      const startDateIsBefore = isBefore(new Date(leaseTerm.startDate ?? ''), new Date(currentPropertyDate));
      const endDateIsAfter = isAfter(new Date(leaseTerm.endDate ?? ''), new Date(currentPropertyDate));
      if (startDateIsBefore && endDateIsAfter) {
        setSelectedRent(index);
      }
    });
  }, []);

  const handleRentChange = (event: SelectChangeEvent<number>) => {
    setSelectedRent(typeof event.target.value === 'string' ? parseInt(event.target.value) : event.target.value);
  };

  const handleChange = (newRent: IRentInfo) => {
    onChange &&
      onChange(
        value.map((x, index) => {
          if (index === selectedRent) {
            return { ...x, rent: newRent };
          } else {
            return x;
          }
        }),
      );
    if (!value[selectedRent].startDate || !value[selectedRent].endDate) return;
    if (
      new Date(currentPropertyDate) > new Date(value[selectedRent].startDate!) &&
      new Date(currentPropertyDate) < new Date(value[selectedRent].endDate!)
    ) {
      onSetCurrentLeaseTermsDirty && onSetCurrentLeaseTermsDirty();
    }
  };

  const handleSetStartDate = (date: Date) => {
    onSetStartDate && onSetStartDate(date, selectedRent);
    if (!value[selectedRent].startDate || !value[selectedRent].endDate) return;
    if (
      new Date(currentPropertyDate) > new Date(value[selectedRent].startDate!) &&
      new Date(currentPropertyDate) < new Date(value[selectedRent].endDate!)
    ) {
      onSetCurrentLeaseTermsDirty && onSetCurrentLeaseTermsDirty();
    }
  };

  const handleSetEndDate = (date: Date) => {
    onSetEndDate && onSetEndDate(date, selectedRent);
    if (!value[selectedRent].startDate || !value[selectedRent].endDate) return;
    if (
      new Date(currentPropertyDate) > new Date(value[selectedRent].startDate!) &&
      new Date(currentPropertyDate) < new Date(value[selectedRent].endDate!)
    ) {
      onSetCurrentLeaseTermsDirty && onSetCurrentLeaseTermsDirty();
    }
  };

  if (!value || typeof value[selectedRent] === 'undefined') {
    return <Typography>No lease terms found</Typography>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth variant={'standard'} sx={{ mt: '.5rem' }}>
          <InputLabel id="rent-collection-information">Rent Terms</InputLabel>
          <Select value={selectedRent} onChange={handleRentChange}>
            {rentOptions.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Signed On"
          disabled={!value[selectedRent]?.signedOn}
          inputProps={{ readOnly: true }}
          value={value[selectedRent]?.signedOn ? tryFormatDate(value[selectedRent].signedOn!) : ''}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        {onSetStartDate && onSetEndDate && (
          <LeaseStartAndEndDatePicker
            onChangeLeaseStartAndEndDate={handleSetStartDate}
            onChangeLeaseEndDateOnly={handleSetEndDate}
            leaseStartDate={value[selectedRent]?.startDate ? toStandardDate(value[selectedRent]?.startDate) : undefined}
            leaseEndDate={value[selectedRent]?.endDate ? toStandardDate(value[selectedRent]?.endDate) : undefined}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {value[selectedRent]?.rent && (
          <RentInformationEditor
            isNew={isNew}
            editable={editable}
            value={value[selectedRent].rent!}
            onChange={handleChange}
          />
        )}
      </Grid>
    </Grid>
  );
};
