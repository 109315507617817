import React, { FC, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ReduxEntry, ReduxStatement } from '../redux/statementTypes';
import { tryFormatDate } from '../../utils/TryFormatDate';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { IncludeClosedToggle } from '../../tasks/components/IncludeClosedToggle';
import { StatementPreviousBalance } from './StatementPreviousBalance';
import Connector from '@monkeyjump-labs/cam-fe-shared/dist/services/signalrConnection';
import { StatementDataGrid } from './StatementDataGrid';
import { MarkDepositedPopper } from './MarkDepositedPopper';
import { TableRow, useStatementConfig } from '../useStatementConfig';
import { StatementTimeSelector, StatementTimeSelectorProps } from './StatementTimeSelector';
import { StatementMonthSelectorProps, StatementPeriodSelector } from './StatementPeriodSelector';
import {
  ISlimAccountRef,
  StatementPeriodType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useDispatch } from 'react-redux';
import { ChangeDepositAccountPopper } from './ChangeDepositAccountPopper';
import { NumericFormat } from 'react-number-format';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { JournalEntryDetailDrawer } from './journalEntryDetailDrawer/JournalEntryDetailDrawer';

export type BaseStatementViewProps = {
  fullView?: boolean;
  statement: ReduxStatement | undefined;
  loading: boolean;
  submitting: boolean;
  showActions?: boolean;
  includeArchived?: boolean;
  onChangeIncludeArchived?: () => void;
  isTenantPortalView?: boolean;
  statementPeriod?: StatementPeriodType;
  onMarkAsPosted?: (dateDeposited: Date | null, selectedJeId: string) => void;
  onRollbackDeposit?: (jeId: string) => void;
  onRefreshData?: (payload?: string) => void;
  onOpenPopupStatement?: (statementLine?: ReduxEntry, subAccountId?: string) => void;
  highlightedJournalEntryId?: string;
  isPopupStatement?: boolean;
  showAssociationChip?: boolean;
  showDepositChip?: boolean;
  isPlatformStatement?: boolean;
};

export type PeriodStatementViewProps = {
  periodSettings: StatementMonthSelectorProps;
} & BaseStatementViewProps;

export type TimeStatementViewProps = {
  timeSettings: StatementTimeSelectorProps;
} & BaseStatementViewProps;

export type NoTimeStatementViewProps = { skipTimeControls: true } & BaseStatementViewProps;

export type StatementViewProps = PeriodStatementViewProps | TimeStatementViewProps | NoTimeStatementViewProps;

const isPeriodStatementView = (props: StatementViewProps): props is PeriodStatementViewProps => {
  return (props as PeriodStatementViewProps).periodSettings !== undefined;
};

const isTimeStatementView = (props: StatementViewProps): props is TimeStatementViewProps => {
  return (props as TimeStatementViewProps).timeSettings !== undefined;
};

export const StatementView: FC<StatementViewProps> = (props) => {
  const {
    fullView,
    statement,
    loading,
    submitting,
    showActions,
    includeArchived,
    onChangeIncludeArchived,
    isTenantPortalView,
    onRollbackDeposit,
    onMarkAsPosted,
    onRefreshData,
    statementPeriod,
    onOpenPopupStatement,
    highlightedJournalEntryId,
    isPopupStatement,
    showAssociationChip,
    showDepositChip,
    isPlatformStatement,
  } = props;
  const {
    selectedContext: { propertyId, currentDate },
  } = useAssets();
  const dispatch = useDispatch();
  const { formatTableColumns, formatTableRows, handleBulkDeposit, handleChangeDeposit } = useStatementConfig(
    fullView,
    isTenantPortalView,
    showActions,
    isPlatformStatement,
  );
  const [tableRows, setTableRows] = useState<TableRow[]>([]);
  const [openDepositDialog, setOpenDepositDialog] = useState(false);
  const [openChangeDepositAccount, setOpenChangeDepositAccount] = useState(false);
  const [dateDeposited, setDateDeposited] = useState<Date | null>(currentDate ? toStandardDate(currentDate) : null);
  const [selectedJeId, setSelectedJeId] = useState<string | undefined>();
  const [selectedJEIds, setSelectedJEIds] = useState<string[]>([]);

  useEffect(() => {
    if (
      propertyId &&
      statement?.leaseId &&
      statement?.startDate !== undefined &&
      statement?.endDate !== undefined &&
      statementPeriod !== undefined &&
      onRefreshData &&
      !isTenantPortalView
    ) {
      const connector = Connector.getExistingInstance();

      connector.connectStatementUpdate(propertyId, refreshData);

      return () => {
        if (statement?.leaseId !== undefined) connector.disconnectStatementUpdate(propertyId, refreshData);
      };
    }
  }, [statement?.leaseId, statement?.account]);

  const refreshData = (payload: { leaseId?: string }) => {
    if (!onRefreshData) return;
    if (statement?.leaseId && statement.leaseId !== payload.leaseId) return;

    onRefreshData();
  };

  const handleDepositClick = (jeId?: string) => {
    if (jeId === undefined) {
      if (!selectedJEIds.length) {
        dispatch(
          showToastMessageAction({
            message: 'Please select at least one entry to mark as deposited',
            severity: 'error',
          }),
        );
        return;
      }
    }
    setSelectedJeId(jeId);
    setOpenDepositDialog(true);
  };

  const handleChangeDepositOpen = (jeId?: string) => {
    setSelectedJeId(jeId);
    setOpenChangeDepositAccount(true);
  };

  const handleChangeDepositAction = (newDepositAccount?: ISlimAccountRef) => {
    if (selectedJeId === undefined || newDepositAccount?.id === undefined) return;
    handleChangeDeposit(selectedJeId, newDepositAccount?.id, isPopupStatement, statement?.isBankAccountStatement);
    setOpenChangeDepositAccount(false);
  };

  const handleDepositClose = () => {
    setSelectedJeId(undefined);
    setOpenDepositDialog(false);
  };

  const handleSetJEIds = (jeId?: string, checked?: boolean) => {
    if (jeId === undefined) return;
    if (!checked) {
      setSelectedJEIds(selectedJEIds.filter((id) => id !== jeId));
    } else if (!selectedJEIds.includes(jeId)) {
      setSelectedJEIds([...selectedJEIds, jeId]);
    }
  };

  const onBulkDeposit = () => {
    handleBulkDeposit(selectedJEIds, toReduxDate(dateDeposited), isPopupStatement, propertyId);
    handleDepositClose();
    setSelectedJEIds([]);
  };

  const columns = formatTableColumns(
    submitting,
    handleDepositClick,
    handleChangeDepositOpen,
    onRollbackDeposit,
    showDepositChip,
    showAssociationChip,
    onOpenPopupStatement,
    handleSetJEIds,
    isPopupStatement,
  );

  useEffect(() => {
    setTableRows(statement?.entries ? formatTableRows(statement.entries, isTenantPortalView) : []);
  }, [statement]);

  function handleMarkAsPosted() {
    if (selectedJeId === undefined) return;
    onMarkAsPosted && onMarkAsPosted(dateDeposited, selectedJeId);
    handleDepositClose();
  }

  const showPeriodSelector = isPeriodStatementView(props);
  const showTimeSelector = isTimeStatementView(props);

  return (
    <Stack spacing={2} marginTop={'1rem'}>
      {showTimeSelector && <StatementTimeSelector {...props.timeSettings} />}
      {showPeriodSelector && <StatementPeriodSelector {...props.periodSettings} />}
      {includeArchived !== undefined && onChangeIncludeArchived && (
        <IncludeClosedToggle
          label={'Include Archived Entries'}
          includeClosed={includeArchived}
          onToggleIncludeClosed={onChangeIncludeArchived}
          sx={{ alignSelf: 'flex-end' }}
        />
      )}
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack>
          <Stack direction="row" spacing={1}>
            <Typography fontWeight="bold" display="inline">
              Statement Beginning :
            </Typography>
            <Typography>{statement?.startDate ? tryFormatDate(statement?.startDate) : ''}</Typography>
          </Stack>
          <StatementPreviousBalance
            isTenantPortalView={isTenantPortalView}
            previousBalance={statement?.previousBalance?.amount}
            previousPendingBalance={statement?.previousPendingBalance}
            previousTotalBalance={statement?.previousTotalBalance}
          />
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <StatementDataGrid
          statement={statement}
          loading={loading}
          tableRows={tableRows}
          columns={columns}
          highlightedJournalEntryId={highlightedJournalEntryId}
          onBulkDeposit={handleDepositClick}
          selectedJEIds={selectedJEIds}
        />
        {statement?.recapturedFunds && (
          <Box display={'flex'} justifyContent={'right'}>
            <Stack direction={'row'} spacing={1}>
              <Typography fontWeight="bold" display="inline">
                Deposit Funds Transferred:
              </Typography>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  onOpenPopupStatement && statement?.recapturedFunds && onOpenPopupStatement(statement.recapturedFunds)
                }
              >
                <Typography>
                  <NumericFormat
                    value={statement.recapturedFunds.amount ?? NaN}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale
                  />{' '}
                  on {statement.recapturedFunds.date && tryFormatDate(statement?.recapturedFunds?.date)}
                </Typography>
              </Link>
            </Stack>
          </Box>
        )}
      </Stack>
      <MarkDepositedPopper
        open={openDepositDialog}
        onDepositClose={handleDepositClose}
        dateDeposited={dateDeposited}
        setDateDeposited={setDateDeposited}
        onMarkAsPosted={!selectedJeId ? onBulkDeposit : handleMarkAsPosted}
      />
      <ChangeDepositAccountPopper
        open={openChangeDepositAccount}
        onChangeDepositClose={() => {
          setSelectedJeId(undefined);
          setOpenChangeDepositAccount(false);
        }}
        onChangeDepositAccount={handleChangeDepositAction}
        currentDepositAccountId={
          statement?.entries?.find((entry) => entry.journalEntryId === selectedJeId)?.depositAccount?.id
        }
      />
      {!isPopupStatement && <JournalEntryDetailDrawer onOpenPopupStatement={onOpenPopupStatement} />}
    </Stack>
  );
};
