import React, { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import IsoIcon from '@mui/icons-material/Iso';
import { ReduxMtAccount } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { useIcons } from '../../icons/useIcons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FundsFlowDirection } from '@monkeyjump-labs/cam-fe-shared/dist/types/propertyTypes';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import DeleteIcon from '@mui/icons-material/Delete';
import { DefaultSelection } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useTheme } from '@mui/material';

type BankAccountActionsProps = {
  isPropertyList: boolean;
  acct: ReduxMtAccount;
  plaidNeedsRefresh: boolean;
  onDeleteAccount: (acct: ReduxMtAccount) => void;
  onRevalidateAccount: (acct: ReduxMtAccount) => void;
  onAddDisburseFunds: (accountId: string, fundsFlowDirection: FundsFlowDirection) => void;
  onViewStatement?: (linkedAccountId?: string) => void;
};

export const BankAccountActions: FC<BankAccountActionsProps> = ({
  isPropertyList,
  onDeleteAccount,
  onRevalidateAccount,
  acct,
  onAddDisburseFunds,
  onViewStatement,
  plaidNeedsRefresh,
}) => {
  const { selectedProperty } = useProperty();
  const { getActionIcon, ActionType } = useIcons();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openPopper = Boolean(anchorEl);
  const id = openPopper ? 'add-disburse-funds-menu' : undefined;

  const [anchorElAcct, setAnchorElAcct] = React.useState<null | HTMLElement>(null);
  const openPopperAcct = Boolean(anchorElAcct);
  const idAcct = openPopperAcct ? 'manage-account-menu' : undefined;
  const theme = useTheme();

  const handleAddDisburseFunds = (fundsFlowDirection: FundsFlowDirection) => {
    acct.mtAccountId && onAddDisburseFunds(acct.mtAccountId, fundsFlowDirection);
    setAnchorEl(null);
  };

  const handleViewStatement = (selectedBankAccountId?: string) => {
    if (selectedProperty.value) {
      const linkedGlAccount = selectedProperty.value.rentalBillingInfo?.find(
        (i) => i.bankAccount?.mtAccountId === selectedBankAccountId,
      )?.linkedGlAccount;
      // const accountName = selectedProperty.value.rentalBillingInfo?.find(
      //   (i) => i.bankAccount?.mtAccountId === selectedBankAccountId,
      // )?.bankAccount?.name;
      onViewStatement && onViewStatement(linkedGlAccount?.id);
    }
  };

  const handleRevalidation = (acct: ReduxMtAccount) => {
    setAnchorElAcct(null);
    onRevalidateAccount(acct);
  };

  const handleDeleteAccount = (acct: ReduxMtAccount) => {
    setAnchorElAcct(null);
    onDeleteAccount(acct);
  };

  return (
    <>
      {isPropertyList && acct.mtAccountId !== undefined && (
        <>
          {onViewStatement && (
            <Tooltip title={'View Statement'}>
              <IconButton onClick={() => handleViewStatement(acct.mtAccountId)}>
                {getActionIcon(ActionType.Statement)}
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={'Add/Disburse Funds'}>
            <IconButton onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}>
              <IsoIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id={id}
            open={openPopper}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <MenuItem onClick={() => handleAddDisburseFunds('Add Funds')}>Add Funds</MenuItem>
            <MenuItem onClick={() => handleAddDisburseFunds('Withdraw Funds')}>Withdraw Funds</MenuItem>
          </Menu>
        </>
      )}
      <Tooltip title={'Manage Account'}>
        <IconButton
          color={plaidNeedsRefresh ? 'warning' : 'default'}
          onClick={(e) => setAnchorElAcct(anchorElAcct ? null : e.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id={idAcct}
        open={openPopperAcct}
        anchorEl={anchorElAcct}
        onClose={() => setAnchorElAcct(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MenuItem
          sx={plaidNeedsRefresh ? { backgroundColor: theme.palette.warning.main } : {}}
          onClick={() => handleRevalidation(acct)}
        >
          <ListItemIcon>
            <PublishedWithChangesIcon />
          </ListItemIcon>
          Revalidate Account
        </MenuItem>
        <MenuItem
          onClick={() => handleDeleteAccount(acct)}
          disabled={
            isPropertyList &&
            (acct.defaultSelection?.includes(DefaultSelection.General) ||
              acct.defaultSelection?.includes(DefaultSelection.Deposit))
          }
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Remove Account
        </MenuItem>
      </Menu>
    </>
  );
};
