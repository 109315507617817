import React, { FC, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { PropertyTreeItem } from './treeItems/PropertyTreeItem';
import { IAssetNode } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { AddProperty } from '../addAssets/AddProperty';
import { AddBuilding } from '../addAssets/AddBuilding';
import { AddUnit } from '../addAssets/AddUnit';
import { AddRoom } from '../addAssets/AddRoom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { BulkAddAssetsDialog } from '../addAssets/BulkAddAssetsDialog';
import { AddPropertyButton } from './AddPropertyButton';
import { Roles } from '../../../../app/constants';
import { Authorized } from '../../../auth/Authorized';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import Skeleton from '@mui/material/Skeleton';
import { SimpleTreeView } from '@mui/x-tree-view-pro';

declare module '@mui/x-tree-view-pro' {
  interface TreeItemContentProps {
    assetName: string;
    onOpen: () => void;
    isSimulated: boolean;
    isArchived: boolean;
    isCamProperty: boolean;
  }
}

export type AssetTreeProps = {
  loading: boolean;
  value: IAssetNode[];
  selected: string;
  selectedPropertyId?: string;
  selectedBuildingId?: string;
  selectedUnitId?: string;
};

export const AssetTree: FC<AssetTreeProps> = ({
  value,
  selected,
  selectedPropertyId,
  selectedBuildingId,
  selectedUnitId,
  loading,
}) => {
  const userRoles = useUser((u) => u.currentUser.value?.roles);
  const [openProperty, setOpenProperty] = React.useState(false);
  const [openBuilding, setOpenBuilding] = useState(false);
  const [openUnit, setOpenUnit] = useState(false);
  const [openRoom, setOpenRoom] = useState(false);
  const [openBulkAddAssets, setOpenBulkAddAssets] = useState(false);

  function findSelectedNodeIds(childNodes: any, treeRoot: any, nodeId: string): string[] {
    if (!childNodes) {
      return [];
    }

    for (let i = 0; i < childNodes.length; i++) {
      const childNode = childNodes[i];
      if (childNode.id === nodeId) {
        return [treeRoot.id, childNode.id];
      }

      const foundIds: string[] = findSelectedNodeIds(childNode.children || [], treeRoot, nodeId);

      if (foundIds.length > 0) {
        return [treeRoot.id, childNode.id, ...foundIds];
      }
    }

    return [];
  }

  const getExpandedIds = (value: IAssetNode[], nodeId: string): string[] => {
    if (nodeId === '') {
      return [];
    }
    let finalIds: string[] = [];

    value.forEach((treeRoot) => {
      if (finalIds.length > 0) return;
      if (treeRoot.id === nodeId) {
        finalIds = [treeRoot.id];
        return;
      }

      finalIds = findSelectedNodeIds(treeRoot.children, treeRoot, nodeId);

      if (finalIds.length > 0) {
        return;
      }
    });

    finalIds = finalIds.filter((value) => {
      return value !== nodeId;
    });

    const unique = new Set(finalIds);
    return Array.from(unique);
  };

  const [expanded, setExpanded] = useState(getExpandedIds(value, selected));

  useEffect(() => {
    setExpanded(getExpandedIds(value, selected));
  }, [value, selected]);

  const handleOpenProperty = () => {
    setOpenProperty(true);
  };

  const handleCloseProperty = () => {
    setOpenProperty(false);
  };

  const handleCloseBuilding = () => {
    setOpenBuilding(false);
  };

  const handleOpenBuilding = () => {
    setOpenBuilding(true);
  };

  const handleCloseUnit = () => {
    setOpenUnit(false);
  };

  const handleOpenUnit = () => {
    setOpenUnit(true);
  };

  const handleCloseRoom = () => {
    setOpenRoom(false);
  };

  const handleOpenRoom = () => {
    setOpenRoom(true);
  };

  const handleOpenBulkAddAssets = () => {
    setOpenBulkAddAssets(true);
  };

  const handleCloseBulkAddAssets = () => {
    setOpenBulkAddAssets(false);
  };

  return (
    <Grid container sx={{ height: '100%', position: 'sticky', top: 0 }} direction="column">
      <Grid item pb=".5rem">
        {userRoles?.includes(Roles.SuperAdmin) ? (
          <Authorized trueUser role={Roles.SuperAdmin}>
            <AddPropertyButton onOpenBulkAddAssets={handleOpenBulkAddAssets} onOpenProperty={handleOpenProperty} />
          </Authorized>
        ) : (
          <Button variant="contained" onClick={handleOpenProperty} fullWidth>
            Add Property
          </Button>
        )}
      </Grid>
      <Grid container item flexGrow="1">
        <Paper sx={{ display: 'flex', flexGrow: '1' }}>
          {loading ? (
            <Skeleton variant={'rectangular'} animation={'wave'} width={'100%'} height={'100%'} />
          ) : value.length === 0 ? (
            <Typography variant={'subtitle1'}>No properties created</Typography>
          ) : (
            <SimpleTreeView
              aria-label="asset-tree"
              slots={{ collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon }}
              sx={{ overflowY: 'auto', width: '100%' }}
              selectedItems={selected}
              expandedItems={expanded}
              onExpandedItemsChange={(e, itemIds) => setExpanded(itemIds)}
            >
              {value.map((p) => (
                <PropertyTreeItem
                  key={p.id}
                  value={p}
                  onOpenBuilding={handleOpenBuilding}
                  onOpenUnit={handleOpenUnit}
                  onOpenRoom={handleOpenRoom}
                />
              ))}
            </SimpleTreeView>
          )}
          <AddProperty onClose={handleCloseProperty} open={openProperty} />
          <AddBuilding propertyId={selectedPropertyId} open={openBuilding} onClose={handleCloseBuilding} />
          <AddUnit buildingId={selectedBuildingId} open={openUnit} onClose={handleCloseUnit} />
          <AddRoom unitId={selectedUnitId} open={openRoom} onClose={handleCloseRoom} />
          <BulkAddAssetsDialog open={openBulkAddAssets} onClose={handleCloseBulkAddAssets} />
        </Paper>
      </Grid>
    </Grid>
  );
};
