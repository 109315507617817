import {
  IPlatformStatementDto,
  IStatementDto,
  IStatementEntryDto,
  JournalEntryStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { isDate } from 'date-fns';
import { ReduxDate, toReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

export function mapPlatformReduxStatement(statement: IPlatformStatementDto): ReduxStatement {
  return {
    ...statement,
    startDate: toReduxDate(statement.startDate),
    endDate: toReduxDate(statement.startDate),
    entries: statement.entries?.map(mapReduxEntry),
  };
}

export function mapReduxStatement(statement: IStatementDto): ReduxStatement {
  return {
    ...statement,
    startDate: toReduxDate(statement.startDate),
    endDate: toReduxDate(statement.endDate),
    entries: statement.entries?.map(mapReduxEntry),
    recapturedFunds: statement.recapturedFunds ? mapReduxEntry(statement.recapturedFunds) : undefined,
  };
}

export type PlatformStatement = Omit<IPlatformStatementDto, 'date'> & {
  date?: ReduxDate;
};

export type ReduxStatement = Omit<IStatementDto, 'startDate' | 'endDate' | 'entries' | 'recapturedFunds'> & {
  startDate?: ReduxDate;
  endDate?: ReduxDate;
  entries?: ReduxEntry[];
  recapturedFunds?: ReduxEntry;
};

export type ReduxEntry = Omit<IStatementEntryDto, 'date' | 'releaseDate' | 'expectedReleaseDate'> & {
  date?: string;
  releaseDate?: ReduxDate;
  expectedReleaseDate?: ReduxDate;
  pending?: number;
  posted?: number;
  total?: number;
};

export function mapReduxEntry(line: IStatementEntryDto): ReduxEntry {
  return {
    ...line,
    date: isDate(line.date) ? line.date?.toISOString() : (line.date as unknown as string),
    releaseDate: toReduxDate(line.releaseDate),
    expectedReleaseDate: toReduxDate(line.expectedReleaseDate),
    pending:
      line.status === JournalEntryStatus.Pending ||
      (line.status === JournalEntryStatus.Archived && line.previousStatus === JournalEntryStatus.Pending)
        ? line.amount
        : undefined,
    posted:
      line.status === JournalEntryStatus.Posted ||
      (line.status === JournalEntryStatus.Archived && line.previousStatus === JournalEntryStatus.Posted)
        ? line.amount
        : undefined,
    total: line.status !== JournalEntryStatus.Archived ? line.balance : undefined,
  };
}
