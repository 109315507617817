import React, { FC } from 'react';
import { CustomTreeItemContent } from './customTreeItemContent/customTreeItemContent';
import { IAssetNode } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { StyledTreeItem } from './StyledTreeItem';
import { convertAssetTypeToAssetNodeType } from '../../../../_shared/paymentProcessing/authorizeNet/helpers';

type RoomItemProps = {
  value: IAssetNode;
};

export const RoomTreeItem: FC<RoomItemProps> = ({ value }) => {
  if (value && value.id) {
    return (
      <StyledTreeItem
        ContentComponent={CustomTreeItemContent}
        ContentProps={
          {
            assetName: convertAssetTypeToAssetNodeType(value.assetNodeType),
          } as any
        }
        key={value.id}
        itemId={value.id}
        label={value.name}
      ></StyledTreeItem>
    );
  } else {
    return <></>;
  }
};
