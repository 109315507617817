import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { initLoadable } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { currencyFormatter } from '../../../_shared/utils/currencyFormatter';
import { useTenant } from '../../redux/tenantSlice';

export const PaymentCompleted = () => {
  const { leases } = useTenant();
  const boxStyle = { display: 'flex', flexGrow: 1, justifyContent: 'center' };

  const creditCardSuccessResult = useSelector((state: RootState) => state.tenant.creditCardResult || initLoadable([]));

  const formatted: any = {};
  if (creditCardSuccessResult) {
    const date = new Date(`${creditCardSuccessResult['dateTime']} UTC`);
    formatted['Date and Time'] = date.toLocaleString();
    formatted['Total Amount'] = currencyFormatter.format(creditCardSuccessResult['totalAmount']);
    formatted['Card Type'] = creditCardSuccessResult['accountType'];
    formatted['Account'] = creditCardSuccessResult['accountNumber'];
    formatted['Transaction Id'] = creditCardSuccessResult['transId'];
    formatted['Authorization Code'] = creditCardSuccessResult['authorization'];
    formatted['Reference Id'] = creditCardSuccessResult['refId'];
    formatted['First Name'] = creditCardSuccessResult['billTo'] && creditCardSuccessResult['billTo']['firstName'];
    formatted['Last Name'] = creditCardSuccessResult['billTo'] && creditCardSuccessResult['billTo']['lastName'];
    formatted['Unit Name'] = creditCardSuccessResult['unitName'];
  }

  return (
    <Stack spacing={2} sx={{ pt: '1rem' }}>
      <Stack direction={'row'} justifyContent={'center'}>
        {formatted['Date and Time'] && (
          <Paper sx={{ width: '33%' }} square elevation={3}>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} align={'center'}>
                    <Typography fontWeight={'bold'}>***** CREDIT CARD RECEIPT *****</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(formatted).map((key) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell align="right">{formatted[key]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </Stack>
      <Box sx={boxStyle}>
        <Typography variant={'h6'}>
          Payment completed! It may take a few moments for your statement to reflect your new balance.
        </Typography>
      </Box>
      <Box sx={boxStyle}>
        <Link href={leases.selectedValue?.id ? `/tenantPortal/${leases.selectedValue?.id}` : `/tenantPortal`}>
          Return to Main Page
        </Link>
      </Box>
    </Stack>
  );
};
