import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { StatementPeriodSelector } from './StatementPeriodSelector';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro';
import Box from '@mui/material/Box';
import { isPeriod, Period, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { StatementPeriodType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { StatementType } from './LeaseStatement';

export type StatementTimeSelectorProps = {
  dateRange?: DateRange<Date>;
  setDateRange?: (range: DateRange<Date>) => void;
  selectedPeriodType?: StatementPeriodType;
  setSelectedPeriodType?: (periodType: StatementPeriodType) => void;
  periodValue?: Period;
  onPeriodSelection?: (period: Period) => void;
  statementType?: StatementType;
  setStatementType?: (statementType: StatementType) => void;
  periods?: Period[];
};

export const StatementTimeSelector: FC<StatementTimeSelectorProps> = ({
  dateRange,
  setDateRange,
  selectedPeriodType,
  setSelectedPeriodType,
  onPeriodSelection,
  periodValue,
  statementType,
  setStatementType,
  periods,
}) => {
  const { selectedContext } = useAssets();

  const handlePeriodSelection = (value: string) => {
    if (isPeriod(value)) onPeriodSelection && onPeriodSelection(value);
  };

  return (
    <Stack direction="row" spacing={2}>
      <FormControl fullWidth>
        <InputLabel id="select-Statement-type">Select Statement Type:</InputLabel>
        <Select
          labelId="select-Statement-type"
          id="select-Statement-type"
          value={statementType ?? ''}
          label="Select Statement Type:"
          onChange={(e) => setStatementType && setStatementType(e.target.value as StatementType)}
        >
          <MenuItem key={'Current'} value={'current'}>
            Current
          </MenuItem>
          <MenuItem key={'Relative'} value={'relative'}>
            Relative
          </MenuItem>
          <MenuItem key={'Custom'} value={'custom'}>
            Custom
          </MenuItem>
        </Select>
      </FormControl>
      {statementType !== 'custom' && (
        <FormControl fullWidth>
          <InputLabel id="select-period-type">Select Period Type:</InputLabel>
          <Select
            labelId="select-period-type"
            id="select-period-type"
            value={selectedPeriodType ?? ''}
            label="Select period Type:"
            onChange={(e) => setSelectedPeriodType && setSelectedPeriodType(e.target.value as StatementPeriodType)}
          >
            <MenuItem key={'Month'} value={StatementPeriodType.Month}>
              Month
            </MenuItem>
            <MenuItem key={'Quarter'} value={StatementPeriodType.Quarter}>
              Quarter
            </MenuItem>
            <MenuItem key={'year'} value={StatementPeriodType.Year}>
              Year
            </MenuItem>
          </Select>
        </FormControl>
      )}
      {statementType === 'relative' && (
        <StatementPeriodSelector
          selectedPeriod={periodValue}
          onPeriodSelection={handlePeriodSelection}
          periods={periods}
          selectedPeriodType={selectedPeriodType}
        />
      )}
      {statementType === 'custom' && (
        <Box width={'100%'}>
          <DateRangePicker
            maxDate={toStandardDate(selectedContext.currentDate)}
            value={dateRange}
            onChange={setDateRange}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Box>
      )}
    </Stack>
  );
};
