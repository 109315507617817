import React, { FC, useEffect, useState } from 'react';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers-pro';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import { ReduxDate, toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { requestMoveOutDateAction, useTenant } from '../../redux/tenantSlice';

type ScheduleMoveOutDialogProps = {
  open: boolean;
  onClose: () => void;
  currentMoveOutDate: ReduxDate | undefined;
  leaseId?: string;
};

export const ScheduleMoveOutDialog: FC<ScheduleMoveOutDialogProps> = ({
  open,
  onClose,
  currentMoveOutDate,
  leaseId,
}) => {
  const dispatch = useDispatch();
  const { leases } = useTenant();
  const [moveOutDate, setMoveOutDate] = useState<Date | null>(
    currentMoveOutDate ? toStandardDate(currentMoveOutDate) : null,
  );
  const [dirty, setDirty] = useState(false);
  const handleSetMoveOutDate = (date: Date | null) => {
    setDirty(true);
    setMoveOutDate(date);
  };

  useEffect(() => {
    leases.submitted && handleClose();
  }, [leases.submitted]);

  useEffect(() => {
    setMoveOutDate(currentMoveOutDate ? toStandardDate(currentMoveOutDate) : null);
  }, [currentMoveOutDate]);

  const handleClose = () => {
    setMoveOutDate(currentMoveOutDate ? toStandardDate(currentMoveOutDate) : null);
    setDirty(false);
    onClose();
  };

  return (
    <DialogLayout
      onClose={handleClose}
      title={'Schedule Move Out'}
      open={open}
      aria-labelledby="schedule-move-out-title"
    >
      <DialogContent>
        <Stack spacing={2} marginTop={'1rem'}>
          <DialogContentText>Scheduling a move out date will notify your property manager.</DialogContentText>
          <DatePicker
            label={'Select Move Out Date'}
            disablePast
            slotProps={{ field: { clearable: true } }}
            onChange={(date) => handleSetMoveOutDate(date)}
            value={moveOutDate ?? null}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} variant={'outlined'}>
          Back
        </Button>
        <LoadingButton
          autoFocus
          variant={'contained'}
          disabled={!dirty}
          onClick={() =>
            leaseId &&
            dispatch(
              requestMoveOutDateAction({
                leaseId: leaseId,
                moveOutDate: toReduxDate(moveOutDate) ?? undefined,
              }),
            )
          }
        >
          {currentMoveOutDate ? 'Update Move Out' : 'Request Move Out'}
        </LoadingButton>
      </DialogActions>
    </DialogLayout>
  );
};
