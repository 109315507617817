import React, { FC } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import ReactInputMask, { Props } from 'react-input-mask';

type SsnTextFieldProps = Omit<TextFieldProps, 'value' | 'onBlur' | 'onChange'> & Omit<Props, 'mask'>;

export const SsnTextField: FC<SsnTextFieldProps> = ({ onChange, value, onBlur, disabled, ...otherProps }) => {
  return (
    <ReactInputMask mask="999-99-9999" onChange={onChange} value={value} onBlur={onBlur} disabled={disabled}>
      <TextField {...otherProps} />
    </ReactInputMask>
  );
};
