import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { StyledInfoBox } from '../../../_shared/styledComponents/StyledInfoBox';
import { ReduxAccount } from '@monkeyjump-labs/cam-fe-shared/dist/types/propertyTypes';
import {
  addGlCodeAction,
  updateGlCodeAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import LoadingButton from '@mui/lab/LoadingButton';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  AccountType,
  IAddAccountHandlerRequest,
  IUpdateAccountHandlerRequest,
  SubAccountType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import FormControl from '@mui/material/FormControl';
import { FormHelperText } from '@mui/material';

export type Props = {
  propertyId: string;
  open: boolean;
  accounts?: ReduxAccount[];
  onClose: () => any;
  editId?: string;
};

type AccountOption = {
  id?: string;
  label?: string;
};

export const AccountDialog = ({ open, onClose, propertyId, accounts, editId }: Props) => {
  const dispatch = useDispatch();
  const { glAccounts } = useProperty();
  const [selectedAccountType, setSelectedAccountType] = useState<AccountOption | null>(null);
  const [accountName, setAccountName] = useState<string>();
  const [accountNumber, setAccountNumber] = useState<string | undefined>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [parentAccounts, setParentAccounts] = useState<AccountOption[]>([]);
  const [parentAccountId, setParentAccountId] = useState<string | null>(null);
  const [subAccountType, setSubAccountType] = useState<SubAccountType | null>(null);
  const [isSubAccount, setIsSubAccount] = useState<boolean>(false);
  const [isDirectlyAssignable, setIsDirectlyAssignable] = useState<boolean>(true);

  const accountTypeOptions = Object.values(AccountType).map((type) => ({ id: type, label: type }));
  const subAccountTypeOptions = {
    [AccountType.Assets.toString()]: [SubAccountType.AccountsReceivable, SubAccountType.Cash],
    [AccountType.Revenue.toString()]: [SubAccountType.RentalIncome],
    [AccountType.Liabilities.toString()]: [SubAccountType.AccountsPayable],
    ['']: [],
  };
  const subAccountLabelMap = Object.fromEntries(
    Object.values(SubAccountType).map((x) => [x, x.replace(/([A-Z])/g, ' $1').trim()]),
  );

  const clearForm = () => {
    setAccountName('');
    setAccountNumber(undefined);
    setIsEditable(true);
    setSelectedAccountType(null);
    setIsDirectlyAssignable(true);
  };

  useEffect(() => {
    if (glAccounts.submitted) {
      clearForm();
      onClose();
    }
  }, [glAccounts.submitted]);

  useEffect(() => {
    if (selectedAccountType) {
      setParentAccounts(
        accounts
          ?.filter((a) => a.accountType === selectedAccountType.id)
          .map((a) => ({
            label: `${a.accountNumber} ${a.accountName}`,
            id: a.id,
          })) ?? [],
      );
    } else {
      setParentAccounts([]);
    }
  }, [accounts, selectedAccountType]);

  useEffect(() => {
    if (open && editId) {
      const account = accounts?.find((account) => account.id === editId);
      const defaultName = account?.accountName;
      const isEditable = !account?.isStandard ?? true;
      setIsEditable(isEditable);
      setAccountName(defaultName ?? '');
      setAccountNumber(account?.accountNumber);
      setIsDirectlyAssignable(account?.isAssignable ?? true);
    }
    if (!open) clearForm();
  }, [open]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!editId) {
      const payload: IAddAccountHandlerRequest = {
        propertyId: propertyId,
        parentAccountId: parentAccountId!,
        accountNumber: accountNumber,
        accountName: accountName!,
        subAccountType: subAccountType ?? undefined,
        accountType: selectedAccountType!.id! as AccountType,
        isAssignable: isDirectlyAssignable,
      };
      dispatch(addGlCodeAction(payload));
    } else if (accountName) {
      const payload: IUpdateAccountHandlerRequest & { accountId: string } = {
        propertyId: propertyId,
        accountId: editId,
        accountName: accountName,
        accountNumber: accountNumber!,
        isAssignable: isDirectlyAssignable,
      };
      dispatch(updateGlCodeAction(payload));
    }
  };

  const handleSetIsSubAccount = () => {
    setIsSubAccount(!isSubAccount);
    setParentAccountId(null);
  };

  const hasSubAccountTypes =
    selectedAccountType?.id == AccountType.Assets ||
    selectedAccountType?.id == AccountType.Liabilities ||
    selectedAccountType?.id == AccountType.Revenue;

  return (
    <DialogLayout
      title={`${!editId ? 'New' : 'Edit'} GL Code`}
      maxWidth="md"
      fullWidth={true}
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <StyledInfoBox label={'Information'}>
            <Grid container columnSpacing={2} rowSpacing={0}>
              {!editId && (
                <>
                  <Grid item xs={hasSubAccountTypes ? 6 : 12}>
                    <Autocomplete
                      renderInput={(params) => (
                        <TextField {...params} margin={'dense'} required label={'Account Type'} variant={'standard'} />
                      )}
                      options={accountTypeOptions}
                      onChange={(e, v) => setSelectedAccountType(v)}
                      value={selectedAccountType}
                    />
                  </Grid>
                  {hasSubAccountTypes && (
                    <Grid item xs={6}>
                      <Autocomplete
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin={'dense'}
                            label={'Specific Type (optional)'}
                            variant={'standard'}
                          />
                        )}
                        getOptionLabel={(option) => subAccountLabelMap[option]}
                        options={subAccountTypeOptions[selectedAccountType?.id ?? '']}
                        onChange={(e, v) => setSubAccountType(v ?? null)}
                        value={subAccountType}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6} alignContent={'flex-end'}>
                    <FormControlLabel
                      control={<Checkbox checked={isSubAccount} onChange={handleSetIsSubAccount} />}
                      label={'Is Sub Account'}
                    />
                  </Grid>
                  <Grid item xs={6} margin={'dense'}>
                    {isSubAccount && (
                      <Autocomplete
                        renderInput={(params) => (
                          <TextField {...params} margin={'dense'} label={'Parent Account'} variant={'standard'} />
                        )}
                        options={parentAccounts}
                        onChange={(event: any, newValue: { id?: string; label?: string } | null) => {
                          setParentAccountId(newValue?.id ?? null);
                        }}
                        value={parentAccounts.find((a) => a.id === parentAccountId) ?? null}
                      />
                    )}
                  </Grid>
                </>
              )}
              <Grid item xs={8}>
                <TextField
                  autoFocus
                  fullWidth
                  required
                  disabled={!isEditable}
                  margin="dense"
                  id="accountName"
                  label="Account Name"
                  type="text"
                  variant="standard"
                  value={accountName}
                  onChange={(event: { currentTarget: { value: string } }) => {
                    setAccountName(event.currentTarget.value);
                  }}
                />
              </Grid>
              <Grid item xs={4} spacing={2}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="customAccountNumber"
                  label="Account Number"
                  type="text"
                  variant="standard"
                  required
                  value={accountNumber ?? ''}
                  onChange={(event: { currentTarget: { value: string } }) => {
                    setAccountNumber(event.currentTarget.value);
                  }}
                />
              </Grid>
              {(accounts?.find((account) => account.id === editId)?.isToggleable || !editId) && (
                <Grid item xs={6} spacing={2} mt={2}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDirectlyAssignable}
                          onChange={(e) => {
                            setIsDirectlyAssignable(e.target.checked);
                          }}
                        />
                      }
                      label="Allow Direct Posting"
                    />
                    <FormHelperText>Allow transactions to post to this account</FormHelperText>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </StyledInfoBox>
          <Grid item xs={12}>
            <DialogActions sx={{ marginTop: '1rem' }}>
              <Button onClick={onClose}>Close</Button>
              <LoadingButton type="submit" variant="contained" loading={glAccounts.submitting}>
                {!editId ? 'Add Code' : 'Save'}
              </LoadingButton>
            </DialogActions>
          </Grid>
        </form>
      </DialogContent>
    </DialogLayout>
  );
};
