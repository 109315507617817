import React, { FC, FormEvent, useEffect, useState } from 'react';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';
import { DialogProps } from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import { createRecurringPaymentAction, deleteRecurringPaymentAction, useTenant } from '../../redux/tenantSlice';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { AccountOptionsSelect } from './AccountOptionsSelect';
import { IRentFee } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import LoadingButton from '@mui/lab/LoadingButton';
import { DayOfTheMonthOption } from '../../redux/tenantTypes';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { NumberValueTextField } from '../../../_shared/texfields/NumberValueTextField';
import { ReduxLeaseTerm } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { appTheme } from '../../../../AppTheme';
import { ReduxMtAccount } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

type AutoPaymentDialogProps = Omit<DialogProps, 'onClose'> & {
  title: string;
  open: boolean;
  onClose: () => void;
  selectedLeaseCurrentRent?: ReduxLeaseTerm;
  leaseId?: string;
  userHasAutoPay: boolean;
};

type AutoPayOptionType = {
  dayOfTheMonth?: number;
  amount?: number;
  bankAccount?: ReduxMtAccount;
};

export const RecurringPaymentDialog: FC<AutoPaymentDialogProps> = ({
  title,
  open,
  onClose,
  selectedLeaseCurrentRent,
  leaseId,
  userHasAutoPay,
}) => {
  const dispatch = useDispatch();
  const { recurringPayment, bankAccounts } = useTenant();
  const rent: number = Math.round(100 * (selectedLeaseCurrentRent?.rent?.rentAmount ?? 0)) / 100;
  const otherFeesAmount: number =
    Math.round(
      100 * (selectedLeaseCurrentRent?.rent?.otherFees?.reduce((t: number, r: IRentFee) => t + (r.fee ?? 0), 0) ?? 0),
    ) / 100;
  const suggestedAmount: number = Math.round(100 * (rent + otherFeesAmount)) / 100;
  const defaultAutoPayOptions = {
    dayOfTheMonth: 1,
    amount: suggestedAmount,
  };
  const [autoPayOptions, setAutoPayOptions] = useState<AutoPayOptionType>(defaultAutoPayOptions);

  const handleClose = () => {
    setAutoPayOptions(defaultAutoPayOptions);
    onClose();
  };

  useEffect(() => {
    if (recurringPayment.submitted) {
      handleClose();
    }
  }, [recurringPayment.submitted]);

  useEffect(() => {
    if (open) {
      const selectedAutoPay = recurringPayment.value?.find((a) => a.leaseId === leaseId);
      const recurringPaymentAccount = bankAccounts?.value?.find(
        (account) => account.mtAccountId === selectedAutoPay?.mtAccountId,
      );
      if (selectedAutoPay && recurringPaymentAccount) {
        setAutoPayOptions({
          dayOfTheMonth: selectedAutoPay.dayOfPayment,
          amount: selectedAutoPay.amount,
          bankAccount: recurringPaymentAccount,
        });
      } else setAutoPayOptions({ ...autoPayOptions, amount: suggestedAmount });
    }
  }, [open]);

  const handleSetBankAccount = (accountName?: string) => {
    if (!accountName) return setAutoPayOptions({ ...autoPayOptions, bankAccount: undefined });
    const account = bankAccounts?.value?.find((account) => account.name === accountName);
    setAutoPayOptions({ ...autoPayOptions, bankAccount: account });
  };

  const handleSetAutoPay = (e: FormEvent) => {
    e.preventDefault();
    if (!autoPayOptions.bankAccount) {
      dispatch(showToastMessageAction({ message: 'You must select a bank account', severity: 'error' }));
      return;
    }
    dispatch(
      createRecurringPaymentAction({
        mtAccountId: autoPayOptions.bankAccount.mtAccountId,
        lastFour: autoPayOptions.bankAccount.safeBankAccount,
        amount: autoPayOptions.amount,
        dayOfPayment: autoPayOptions.dayOfTheMonth,
        leaseId: leaseId,
        isDisabled: false,
      }),
    );
  };

  const handleDeleteAutopay = () => {
    leaseId && dispatch(deleteRecurringPaymentAction(leaseId));
  };

  return (
    <DialogLayout title={title} open={open} onClose={handleClose} aria-labelledby={'auto-payment-dialog'}>
      <form onSubmit={handleSetAutoPay}>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant={'h6'} sx={{ mt: '.5rem' }} fontStyle={'italic'}>
              Monthly Payment: ${suggestedAmount}
            </Typography>
            <Divider />
            <NumberValueTextField
              variant={'outlined'}
              required
              value={autoPayOptions.amount}
              valueUnits={'dollars'}
              onChange={(e) => setAutoPayOptions({ ...autoPayOptions, amount: Number(e.target.value) })}
              label={'Amount'}
              type={'number'}
            />
            <TextField
              variant={'outlined'}
              required
              value={autoPayOptions.dayOfTheMonth}
              onChange={(e) =>
                setAutoPayOptions({
                  ...autoPayOptions,
                  dayOfTheMonth: Number(e.target.value) as DayOfTheMonthOption,
                })
              }
              label={'Day of the Month'}
              type={'number'}
              helperText={'Options: 1 - 28'}
              InputProps={{
                inputProps: {
                  min: 1,
                  max: 28,
                },
              }}
            />
            <AccountOptionsSelect
              onSetSelectedAccount={handleSetBankAccount}
              accounts={bankAccounts?.value}
              value={autoPayOptions.bankAccount}
            />
            <Typography variant={'body2'}>
              *Selecting an account other than your default account will change your default account.
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ mr: '.5rem' }}>
            Back
          </Button>
          <LoadingButton variant={'contained'} type={'submit'} loading={recurringPayment.submitting}>
            {title}
          </LoadingButton>
          {userHasAutoPay && (
            <LoadingButton
              variant={'contained'}
              onClick={handleDeleteAutopay}
              loading={recurringPayment.submitting}
              sx={{ backgroundColor: appTheme.palette.warning.main }}
            >
              Remove Recurring Payment
            </LoadingButton>
          )}
        </DialogActions>
      </form>
    </DialogLayout>
  );
};
