import React, { FC, SyntheticEvent, useState } from 'react';
import { debounce, InternalStandardProps as StandardProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { ISlimUserWithClaims } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { TokenSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/tokenSingleton';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { getAllAssetsAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { getCurrentUserInfoAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { Claims } from '@monkeyjump-labs/cam-fe-shared/dist/types/constants';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../../AppRouter';

export type UserImpersonatorProps = StandardProps<
  React.HTMLAttributes<HTMLDivElement>,
  'defaultValue' | 'onChange' | 'children'
> & { textFieldProps?: TextFieldProps; optionProps?: undefined; onClose?: () => void };

const WhiteTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    backgroundColor: 'white',
  },
  '& InputBase': {
    backgroundColor: 'white',
  },
});

export const UserImpersonator: FC<UserImpersonatorProps> = ({ onClose, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getOptions = debounce(async (inputValue: string) => {
    const response = await ApiClientSingleton.getInstance().userInfo_ListUsers(inputValue);
    const allUsers: ISlimUserWithClaims[] = response.toJSON().users;
    setOptions(allUsers);
  }, 100);

  const [value, setValue] = useState<ISlimUserWithClaims | undefined>(
    TokenSingleton.getImpersonatingUser() ? { username: TokenSingleton.getImpersonatingUser() } : undefined,
  );
  const [options, setOptions] = useState<ISlimUserWithClaims[]>([]);

  const handleChange = (event: SyntheticEvent, v: ISlimUserWithClaims | null) => {
    setValue(v || undefined);
    if (v?.username) {
      TokenSingleton.setImpersonatingUser(v.username);
      onClose && onClose();
    } else {
      TokenSingleton.resetImpersonation();
    }
    const userHasOnlyTenantClaims = v?.claims?.every((c) => c.type === Claims.Tenant);
    if (!userHasOnlyTenantClaims) {
      dispatch(getAllAssetsAction());
      navigate(`${appRoutes.home}`);
    } else {
      navigate(`${appRoutes.tenantPortal}`);
    }
    dispatch(getCurrentUserInfoAction());
  };

  return (
    <Autocomplete
      {...props}
      sx={{ backgroundColor: 'light' }}
      fullWidth
      multiple={false}
      disableClearable={false}
      freeSolo={false}
      options={options}
      filterOptions={(options) => options.filter((o) => o)}
      getOptionLabel={(option) => option.username || ''}
      renderInput={(params) => <WhiteTextField {...params} />}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText primary={option.username} secondary={`${option.firstName} ${option.lastName}`} />
        </ListItem>
      )}
      value={value}
      onInputChange={(_, v) => getOptions(v)}
      onChange={handleChange}
      onOpen={() => getOptions('')}
    />
  );
};
