import {
  DogSizeAllowed,
  IListing,
  ListingStatus,
  QueryOperator,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { addMonths } from 'date-fns';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

export type ListingParameter = {
  field: keyof IListing;
  operator: QueryOperator;
  value: string;
};

export type ListingFilter = {
  label: string;
  options: {
    value: ListingParameter[];
    label: string;
  }[];
};

export const useListingFilters = () => {
  //TODO: do we want to use selectedContext of the property? or should we be using current real date?
  //TODO: if needed, make sure currentDate of property is available in the advertising web view
  const { selectedContext } = useAssets();
  const currentDate = selectedContext.currentDate ?? toReduxDate(new Date());

  const propertyFilters: ListingFilter[] = [
    {
      label: 'Status',
      options: [
        {
          value: [
            {
              field: 'status',
              operator: QueryOperator.Eq,
              value: ListingStatus.Unpublished,
            },
          ],
          label: 'Unpublished',
        },
        {
          value: [
            {
              field: 'status',
              operator: QueryOperator.Eq,
              value: ListingStatus.PublishedInternally,
            },
          ],
          label: 'Published Internally',
        },
        {
          value: [
            {
              field: 'status',
              operator: QueryOperator.Eq,
              value: ListingStatus.PublishedExternally,
            },
          ],
          label: 'Published Externally',
        },
      ],
    },
  ];

  const filters: ListingFilter[] = [
    {
      label: 'Price',
      options: [
        {
          value: [
            {
              field: 'price',
              operator: QueryOperator.Lt,
              value: '1000.01',
            },
          ],
          label: 'less than $1000',
        },
        {
          value: [
            {
              field: 'price',
              operator: QueryOperator.Gt,
              value: '1000',
            },
            {
              field: 'price',
              operator: QueryOperator.Lt,
              value: '1500.01',
            },
          ],
          label: '$1001 - $1500',
        },
        {
          value: [
            {
              field: 'price',
              operator: QueryOperator.Gt,
              value: '1500',
            },
            {
              field: 'price',
              operator: QueryOperator.Lt,
              value: '2000.01',
            },
          ],
          label: '$1501 - $2000',
        },
        {
          value: [
            {
              field: 'price',
              operator: QueryOperator.Gt,
              value: '2000',
            },
            {
              field: 'price',
              operator: QueryOperator.Lt,
              value: '2500.01',
            },
          ],
          label: '$2001 - $2500',
        },
        {
          value: [
            {
              field: 'price',
              operator: QueryOperator.Gt,
              value: '2500',
            },
            {
              field: 'price',
              operator: QueryOperator.Lt,
              value: '3000.01',
            },
          ],
          label: '$2501 - $3000',
        },
        {
          value: [
            {
              field: 'price',
              operator: QueryOperator.Gt,
              value: '3000',
            },
          ],
          label: 'greater than $3000',
        },
      ],
    },
    {
      label: 'Beds',
      options: [
        {
          value: [
            {
              field: 'bedrooms',
              operator: QueryOperator.Eq,
              value: '1',
            },
          ],
          label: '1',
        },
        {
          value: [
            {
              field: 'bedrooms',
              operator: QueryOperator.Eq,
              value: '2',
            },
          ],
          label: '2',
        },
        {
          value: [
            {
              field: 'bedrooms',
              operator: QueryOperator.Eq,
              value: '3',
            },
          ],
          label: '3',
        },
        {
          value: [
            {
              field: 'bedrooms',
              operator: QueryOperator.Eq,
              value: '4',
            },
          ],
          label: '4',
        },
        {
          value: [
            {
              field: 'bedrooms',
              operator: QueryOperator.Gt,
              value: '4',
            },
          ],
          label: '5+',
        },
      ],
    },
    {
      label: 'Baths',
      options: [
        {
          value: [
            {
              field: 'bathrooms',
              operator: QueryOperator.Eq,
              value: '1',
            },
          ],
          label: '1',
        },
        {
          value: [
            {
              field: 'bathrooms',
              operator: QueryOperator.Eq,
              value: '2',
            },
          ],
          label: '2',
        },
        {
          value: [
            {
              field: 'bathrooms',
              operator: QueryOperator.Eq,
              value: '3',
            },
          ],
          label: '3',
        },
        {
          value: [
            {
              field: 'bathrooms',
              operator: QueryOperator.Gt,
              value: '3',
            },
          ],
          label: '4+',
        },
      ],
    },
    {
      label: 'Pets',
      options: [
        {
          value: [
            {
              field: 'allowsCats',
              operator: QueryOperator.Eq,
              value: 'true',
            },
          ],
          label: 'Cats',
        },
        {
          value: [
            {
              field: 'maxDogSizeAllowed',
              operator: QueryOperator.Ne,
              value: DogSizeAllowed.None,
            },
          ],
          label: 'Dogs',
        },
        {
          value: [
            {
              field: 'allowsCats',
              operator: QueryOperator.Eq,
              value: 'true',
            },
            {
              field: 'maxDogSizeAllowed',
              operator: QueryOperator.Ne,
              value: DogSizeAllowed.None,
            },
          ],
          label: 'Both',
        },
        {
          value: [
            {
              field: 'allowsCats',
              operator: QueryOperator.Eq,
              value: 'false',
            },
            {
              field: 'maxDogSizeAllowed',
              operator: QueryOperator.Eq,
              value: DogSizeAllowed.None,
            },
          ],
          label: 'None',
        },
      ],
    },
    {
      label: 'Move-In',
      options: [
        {
          value: [
            {
              field: 'dateAvailable',
              operator: QueryOperator.Lt,
              value: toReduxDate(addMonths(toStandardDate(currentDate), 1)),
            },
          ],
          label: 'Now',
        },
        {
          value: [
            {
              field: 'dateAvailable',
              operator: QueryOperator.Gt,
              value: toReduxDate(addMonths(toStandardDate(currentDate), 1)),
            },
            {
              field: 'dateAvailable',
              operator: QueryOperator.Lt,
              value: toReduxDate(addMonths(toStandardDate(currentDate), 3)),
            },
          ],
          label: '1-3 months',
        },
        {
          value: [
            {
              field: 'dateAvailable',
              operator: QueryOperator.Gt,
              value: toReduxDate(addMonths(toStandardDate(currentDate), 3)),
            },
            {
              field: 'dateAvailable',
              operator: QueryOperator.Lt,
              value: toReduxDate(addMonths(toStandardDate(currentDate), 5)),
            },
          ],
          label: '3-5 months',
        },
        {
          value: [
            {
              field: 'dateAvailable',
              operator: QueryOperator.Gt,
              value: toReduxDate(addMonths(toStandardDate(currentDate), 5)),
            },
          ],
          label: '5+ months',
        },
      ],
    },
  ];

  const handleUpdateFilters = (parameters: ListingParameter[], filters: ListingParameter[]): ListingParameter[] => {
    //remove any filters that already exist with the same field
    const updatedFilters = filters.filter(
      (filter) => !parameters.some((parameter) => parameter.field === filter.field),
    );
    return [...updatedFilters, ...parameters];
  };

  return { filters, propertyFilters, handleUpdateFilters };
};
