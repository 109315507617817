import React, { FC, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import {
  getMoreNotificationsByUserAction,
  getNotificationsByUserAction,
  useNotifications,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/notifications/notificationSlice';
import { useDispatch } from 'react-redux';
import { NotificationMenuItem } from './NotificationsMenuItem';
import MenuItem from '@mui/material/MenuItem';
import { ReduxUserNotification } from '@monkeyjump-labs/cam-fe-shared/dist/types/notificationTypes';
import { useAuth0 } from '@auth0/auth0-react';
import Divider from '@mui/material/Divider';
import Refresh from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';

export type NotificationsMenuProps = {
  isAuthTokenSet: boolean;
};

export const NotificationsMenu: FC<NotificationsMenuProps> = ({ isAuthTokenSet }) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const notifications = useNotifications();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    if (isAuthenticated && isAuthTokenSet) {
      dispatch(getNotificationsByUserAction());
    }
  }, [isAuthenticated, isAuthTokenSet]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(getNotificationsByUserAction());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRefreshNotifications = () => {
    dispatch(getNotificationsByUserAction());
  };

  const handleGetMoreNotifications = () => {
    dispatch(getMoreNotificationsByUserAction());
  };

  const handleBadgeContent = (): string | undefined => {
    if (!notifications.globalNotifications.value || notifications.globalNotifications.value.length === 0)
      return undefined;
    else if (notifications.globalNotifications.value.length === notifications.globalNotifications.pageSize) {
      return `${notifications.globalNotifications.value.length}+`;
    } else return `${notifications.globalNotifications.value.length}`;
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="notifications"
        aria-controls="notifications-menu"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Badge badgeContent={handleBadgeContent()} color="error">
          <NotificationsIcon sx={{ color: 'white' }} />
        </Badge>
      </IconButton>
      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box sx={{ border: '.1rem solid lightgray', mx: '1rem', my: '.5rem', minWidth: '35rem' }}>
          <Stack>
            {notifications.globalNotifications.value?.length === 0 || !notifications.globalNotifications.value ? (
              <MenuItem>No New Notifications</MenuItem>
            ) : (
              <>
                <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
                  <IconButton onClick={handleRefreshNotifications}>
                    <Refresh />
                  </IconButton>
                </Box>
                <Divider />
                {notifications.globalNotifications.value?.map((notification: ReduxUserNotification) => (
                  <NotificationMenuItem key={notification.id} notification={notification} onClose={handleClose} />
                ))}
                <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
                  <IconButton onClick={handleGetMoreNotifications}>
                    <ExpandMoreIcon />
                  </IconButton>
                </Box>
              </>
            )}
          </Stack>
        </Box>
      </Menu>
    </>
  );
};
