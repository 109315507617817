import React, { FC, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import Skeleton from '@mui/material/Skeleton';
import { clearBalancesAction, getBalancesAction, useAccounts } from '../../../../_shared/accounts/redux/accountSlice';
import { AccountBalancesChart } from './AccountBalancesChart';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useDispatch } from 'react-redux';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { addDays } from 'date-fns';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import Switch from '@mui/material/Switch';

type AccountBalancesDialogProps = {
  accountId?: string;
  onClose: () => void;
};

export const AccountBalancesDialog: FC<AccountBalancesDialogProps> = ({ accountId, onClose }) => {
  const {
    selectedContext: { currentDate },
  } = useAssets();
  const { glAccounts: accounts } = useProperty();
  const selectedAccount = accounts.value?.find((a) => a.id === accountId);
  const balances = useAccounts().balances;
  const dispatch = useDispatch();
  const currentDateStandard = toStandardDate(currentDate) ?? new Date();
  const [startDate, setStartDate] = React.useState<Date>(addDays(currentDateStandard, -90));
  const [endDate, setEndDate] = React.useState<Date>(currentDateStandard);
  const [directOnly, setDirectOnly] = React.useState<boolean>(true);
  useEffect(() => {
    setStartDate(addDays(currentDateStandard, -90));
    setEndDate(currentDateStandard);
  }, [currentDate]);
  useEffect(() => {
    if (accountId) {
      dispatch(
        getBalancesAction({
          accountId: accountId,
          start: toReduxDate(startDate),
          end: toReduxDate(endDate),
        }),
      );
    } else {
      dispatch(clearBalancesAction());
    }
  }, [open, accountId, startDate, endDate]);
  const onCloseHandler = () => {
    setStartDate(addDays(currentDateStandard, -90));
    setEndDate(currentDateStandard);
    onClose();
  };
  return (
    <Dialog open={balances.loaded || balances.loading} onClose={onCloseHandler} fullWidth maxWidth="md">
      <DialogTitle>Account Balances for {selectedAccount?.accountName ?? <Skeleton width={200} />}</DialogTitle>
      <DialogContent>
        <Stack direction={'row'} spacing={2}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(date) => setStartDate(date ?? addDays(currentDateStandard, -90))}
          />
          <DatePicker label="End Date" value={endDate} onChange={(date) => setEndDate(date ?? currentDateStandard)} />
          <Switch title={'Direct Only'} checked={directOnly} onChange={() => setDirectOnly(!directOnly)} />
        </Stack>
        {balances.loaded && balances.value ? (
          <AccountBalancesChart balances={balances.value} directOnly={directOnly} />
        ) : (
          <Skeleton variant="rectangular" height={400} />
        )}
      </DialogContent>
    </Dialog>
  );
};
