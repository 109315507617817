import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { useDispatch } from 'react-redux';
import { unsetSelectedApplication } from '../../../redux/applicationSlice';
import { useParams } from 'react-router-dom';
import { NotFound } from '../../../../notFound/NotFound';
import { LeasesTable } from '../../../../_shared/leases/components/leaseTable/LeasesTable';
import { StyledInfoBox } from '../../../../_shared/styledComponents/StyledInfoBox';

type UrlParams = {
  applicantId: string | undefined;
};

export const LeasingTables = () => {
  const dispatch = useDispatch();
  const property = useProperty();
  const { applicantId } = useParams<UrlParams>();
  useEffect(() => {
    !applicantId && dispatch(unsetSelectedApplication());
  }, []);

  if (property.selectedProperty.value?.id) {
    return (
      <>
        <Stack spacing={3}>
          <StyledInfoBox label={'Leases Under Review'}>
            <LeasesTable
              propertyId={property.selectedProperty.value.id}
              leasesType={'draft'}
              assetId={property.selectedProperty.value.id}
            />
          </StyledInfoBox>
          <StyledInfoBox label={'Renew Existing Lease'}>
            <LeasesTable
              propertyId={property.selectedProperty.value.id}
              leasesType={'renewal'}
              assetId={property.selectedProperty.value.id}
            />
          </StyledInfoBox>
        </Stack>
      </>
    );
  } else return <NotFound />;
};
