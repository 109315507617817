import React, { FC, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { ListingParameter, useListingFilters } from '../../../redux/useListingFilters';
import { SelectChangeEvent } from '@mui/material/Select';

type FilterSelectProps = {
  value: string;
  onSetFilter: (filters: ListingParameter[]) => void;
  clearFilter: boolean;
  disabled?: boolean;
};

export const FilterSelect: FC<FilterSelectProps> = ({ value, onSetFilter, clearFilter, disabled }) => {
  const { filters, propertyFilters } = useListingFilters();
  const filter =
    filters.find((filter) => filter.label === value) ?? propertyFilters.find((filter) => filter.label === value);
  const [internalValue, setInternalValue] = React.useState<string>('');

  useEffect(() => {
    if (clearFilter) {
      setInternalValue('');
    }
  }, [clearFilter]);

  const handleChange = (event: SelectChangeEvent) => {
    setInternalValue(event.target.value as string);
    const selectedFilter = filter?.options.find((option) => option.label === event.target.value);
    selectedFilter && onSetFilter(selectedFilter.value);
  };
  return (
    <FormControl fullWidth disabled={disabled} size={'small'}>
      <InputLabel id={`${value}-filter`} aria-label={`${value}-filter`}>
        {value}
      </InputLabel>
      <Select label={value} aria-labelledby={`${value}-filter`} onChange={handleChange} value={internalValue}>
        {filter?.options.map((option) => (
          <MenuItem key={option.label} value={option.label ?? ''}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
