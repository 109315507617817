import React, { FC } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { DetailStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import MenuItem from '@mui/material/MenuItem';
import { mapDetailStatusToDisplayName } from '@monkeyjump-labs/cam-fe-shared/dist/types/detailTypes';

type DetailStatusSelectProps = {
  value?: DetailStatus;
  onChange: (e: SelectChangeEvent) => void;
} & Omit<SelectProps, 'onChange' | 'variant'>;

export const DetailStatusSelect: FC<DetailStatusSelectProps> = ({ value, onChange, size }) => {
  return (
    <FormControl fullWidth sx={{ mt: '.5rem' }}>
      <InputLabel id={'detail-status-input'}>Status</InputLabel>
      <Select
        id={'detail-status'}
        value={value ?? DetailStatus.Current}
        required
        labelId={'detail-status-input'}
        label={'Status'}
        onChange={(e) => {
          onChange(e);
        }}
        size={size}
      >
        {Object.values(DetailStatus).map((status) => (
          <MenuItem key={status} value={status}>
            {mapDetailStatusToDisplayName(status)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
