import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteEmailTemplateAction, listEmailTemplatesAction, useEmailTemplates } from '../redux/emailTemplateSlice';
import { GridColDef, GridPaginationModel, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { StripedDataGrid } from '../../datagrids/StripedDataGrid';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssetTypeFromPathname } from '../../utils/getAssetTypeFromPathname';
import { AssetParams } from '../../../../AppRouter';
import { EmailTemplateTab } from '../../tabs/TabTypeEnums';
import { ActionCell } from '../../datagrids/ActionCell';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReduxEmailTemplate } from '@monkeyjump-labs/cam-fe-shared/dist/types/emailTemplateTypes';

export type EmailTemplateTableProps = {
  propertyId: string;
};

export const EmailTemplateTable: FC<EmailTemplateTableProps> = ({ propertyId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { emailTemplates } = useEmailTemplates();
  const { id, outerTab, innerTab } = useParams<AssetParams>();
  const pathAssetType = getAssetTypeFromPathname();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rows, setRows] = useState<ReduxEmailTemplate[]>([]);

  useEffect(() => {
    dispatch(listEmailTemplatesAction({ propertyId: propertyId, page: page, pageSize: pageSize }));
  }, [propertyId]);

  useEffect(() => {
    if (emailTemplates.loaded) {
      setRows(emailTemplates.value ?? []);
    }
  }, [emailTemplates.loaded]);

  const onViewTemplate = (emailTemplate: ReduxEmailTemplate) => {
    navigate(
      `/assets/${pathAssetType}/${id}/${outerTab}/${innerTab}/emailTemplate/${emailTemplate.id}/${EmailTemplateTab.Info}`,
    );
  };

  const onDelete = (emailTemplateId: string) => {
    dispatch(deleteEmailTemplateAction({ id: emailTemplateId }));
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<ReduxEmailTemplate>) => {
        return (
          <Link sx={{ cursor: 'pointer' }} color={'inherit'}>
            <Typography onClick={() => onViewTemplate(params.row)}>{params.value}</Typography>
          </Link>
        );
      },
    },
    {
      field: 'subject',
      headerName: 'Subject',
      flex: 1,
      display: 'flex',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1.25,
      display: 'flex',
      cellClassName: 'actions',
      getActions: (params: GridRowParams<ReduxEmailTemplate>) => [
        <ActionCell
          key={params.row.id}
          showInMenu={false}
          label={'Delete'}
          onClick={() => params.row.id && onDelete(params.row.id)}
          icon={<DeleteIcon />}
        />,
      ],
    },
  ];

  return (
    <StripedDataGrid
      disableRowGrouping
      autoHeight
      getRowHeight={() => 'auto'}
      rowHeight={75}
      rowCount={emailTemplates.totalCount ?? 0}
      rows={rows}
      columns={columns}
      loading={emailTemplates.submitting}
      paginationMode={'server'}
      sortingMode={'server'}
      paginationModel={{ page: page, pageSize: pageSize }}
      onPaginationModelChange={(model: GridPaginationModel) => {
        setPage(model.page);
        setPageSize(model.pageSize);
      }}
      pageSizeOptions={[10, 25, 50, 100]}
    ></StripedDataGrid>
  );
};
