import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';

export const AssetPathCell = (params: GridRenderCellParams) => {
  const assetPath =
    params.row.propertyName +
    (params.row.buildingName ? ` / ${params.row.buildingName}` : '') +
    (params.row.unitName ? ` / ${params.row.unitName}` : '');
  return (
    <div>
      <Tooltip title={assetPath ?? ''}>
        <Typography variant={'body2'}>{params.row.propertyName}</Typography>
      </Tooltip>
      {params.row.buildingName && <Typography variant={'body2'}>{params.row.buildingName}</Typography>}
      {params.row.unitName && <Typography variant={'body2'}>{params.row.unitName}</Typography>}
    </div>
  );
};
