import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { AutocompleteInputChangeReason } from '@mui/material/Autocomplete/Autocomplete';
import {
  getAutomationsByPropertyIdAction,
  useSopTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import {
  AssociationType,
  IAssociation,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';

export type TaskOption = {
  id?: string;
  name?: string;
};

export type SopTaskAutocompleteProps = {
  onValueChange: (configId: string | null) => void;
  value?: string | null | undefined;
  size?: 'small' | 'medium' | undefined;
  localSopTaskAssociation?: IAssociation;
} & Omit<
  AutocompleteProps<TaskOption, undefined, undefined, undefined>,
  'value' | 'options' | 'renderInput' | 'getOptionLabel' | 'size'
>;

export const SopTaskAutocomplete: FC<SopTaskAutocompleteProps> = ({
  onValueChange,
  size,
  localSopTaskAssociation,
  ...autocompleteProps
}) => {
  const dispatch = useDispatch();
  const { sopTasks } = useSopTasks();
  const { selectedContext } = useAssets();
  const [options, setOptions] = useState<TaskOption[]>([]);
  const [optionValue, setOptionValue] = useState<TaskOption | null | undefined>();
  const [includePropertyConfigs, setIncludePropertyConfigs] = useState(false);
  const page = 0;
  const pageSize = 100;

  useEffect(() => {
    selectedContext.propertyId &&
      dispatch(
        getAutomationsByPropertyIdAction({
          id: selectedContext.propertyId,
          page: page,
          pageSize: pageSize,
          associationType: localSopTaskAssociation?.associationType,
          associatedId: localSopTaskAssociation?.associatedId,
          includePropertyTasks:
            localSopTaskAssociation !== undefined && localSopTaskAssociation.associationType === AssociationType.Detail
              ? includePropertyConfigs
              : true,
        }),
      );
  }, [includePropertyConfigs]);

  useEffect(() => {
    const options: TaskOption[] | undefined = sopTasks.value?.map((task) => {
      return {
        id: task.id,
        name: task.name,
      };
    });
    setOptions(options ?? []);
  }, [sopTasks]);

  const handleReset = (event: SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'clear') {
      setOptionValue(null);
    } else setOptionValue(optionValue);
  };

  const handleOptionLabel = (option: TaskOption) => {
    if (!option || !option.name) {
      return '';
    } else return option.name;
  };
  const handleValueChange = (option: TaskOption | null) => {
    onValueChange(option?.id ?? null);
    setOptionValue(option);
  };

  return (
    <Box border={'.1rem solid lightgray'} p={1} borderRadius={1}>
      {localSopTaskAssociation && localSopTaskAssociation.associationType === AssociationType.Detail && (
        <FormGroup sx={{ mt: 1 }}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={includePropertyConfigs}
                onChange={() => setIncludePropertyConfigs(!includePropertyConfigs)}
              />
            }
            label={'Include Property SOP Tasks'}
          />
        </FormGroup>
      )}
      <Autocomplete
        fullWidth
        size={size}
        options={options}
        onChange={(event, value) => {
          handleValueChange(value);
        }}
        onInputChange={(event, value, reason) => handleReset(event, value, reason)}
        renderInput={(params) => {
          return <TextField {...params} variant="standard" label="SOP Tasks" />;
        }}
        value={optionValue ?? (null as any)}
        getOptionLabel={(option: TaskOption) => handleOptionLabel(option)}
        {...autocompleteProps}
      />
    </Box>
  );
};
