import React, { FC } from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { JournalEntryStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type OpenEditCellProps = {
  onClick: () => void;
  displayValue?: string;
} & GridRenderCellParams;

export const OpenEditCell: FC<OpenEditCellProps> = ({ onClick, displayValue, ...params }) => {
  return (
    <Link sx={{ cursor: 'pointer' }} color="inherit">
      <Typography
        variant={'body2'}
        fontStyle={params.row.status === JournalEntryStatus.Pending ? 'italic' : 'normal'}
        onClick={onClick}
      >
        {displayValue ?? params.value}
      </Typography>
    </Link>
  );
};
