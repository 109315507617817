import {
  IAvailableReportDto,
  IReportData,
  IReportFilter,
  ReportData,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  initInteractable,
  initLoadable,
  Interactable,
  Loadable,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';

export type CustomizableFilter = IReportFilter & { value?: string };

export interface ReportState {
  reportOptions: Loadable<IAvailableReportDto[]>;
  selectedReport: Interactable<IAvailableReportDto>;
  selectedFilters: Interactable<CustomizableFilter[]>;
  generatedReport: Loadable<IReportData>;
  additionalReport: Loadable<IReportData>;
  selectedGrouping: Interactable<string>;
}

const initialState: ReportState = {
  reportOptions: initLoadable<IAvailableReportDto[]>(),
  selectedReport: initInteractable<IAvailableReportDto>(),
  selectedFilters: initInteractable<CustomizableFilter[]>(),
  generatedReport: initLoadable<IReportData>(),
  additionalReport: initLoadable<IReportData>(),
  selectedGrouping: initInteractable<string>(),
};
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    getAvailableReportsAction: (state, action: PayloadAction<string>) => {
      state.reportOptions.loading = true;
      state.reportOptions.loaded = false;
    },
    getAvailableReportsSuccessAction: (state, action: PayloadAction<IAvailableReportDto[]>) => {
      state.reportOptions.loading = false;
      state.reportOptions.loaded = true;
      state.reportOptions.value = action.payload;
    },
    getAvailableReportsFailAction: (state) => {
      state.reportOptions.loading = false;
      state.reportOptions.loaded = false;
    },
    setSelectedReportAction: (state, action: PayloadAction<IAvailableReportDto | undefined>) => {
      state.selectedReport.value = action.payload;
      state.selectedFilters.value = action.payload?.filters?.map((x) => {
        return { ...x, value: x.defaultValue };
      });
      state.selectedGrouping.value = action.payload?.groupings?.at(0);
    },
    setSelectedFilterAction: (state, action: PayloadAction<CustomizableFilter>) => {
      state.selectedFilters.value = state.selectedFilters.value?.map((x) => {
        if (x.name === action.payload.name) {
          return action.payload;
        }
        return x;
      });
    },
    setSelectedGroupingAction: (state, action: PayloadAction<string | undefined>) => {
      state.selectedGrouping.value = action.payload;
    },
    generateReportAction: (
      state,
      action: PayloadAction<{ propertyId: string; reportName: string; data: any; grouping?: string }>,
    ) => {
      state.generatedReport.loaded = false;
      state.generatedReport.loading = true;
    },
    generateReportSuccessAction: (state, action: PayloadAction<ReportData>) => {
      state.generatedReport.loaded = true;
      state.generatedReport.loading = false;
      state.generatedReport.value = action.payload;
    },
    generateReportFailAction: (state) => {
      state.generatedReport.loaded = false;
      state.generatedReport.loading = false;
    },
    getAdditionalReportDataAction: (state, action: PayloadAction<{ propertyId: string; data: any }>) => {
      state.additionalReport.loaded = false;
      state.additionalReport.loading = true;
    },
    getAdditionalReportDataSuccessAction: (state, action: PayloadAction<ReportData>) => {
      state.additionalReport.loaded = true;
      state.additionalReport.loading = false;
      state.additionalReport.value = action.payload;
    },
    getAdditionalReportDataFailAction: (state) => {
      state.additionalReport.loaded = false;
      state.additionalReport.loading = false;
    },
    clearAdditionalReportDataAction: (state) => {
      state.additionalReport.value = undefined;
    },
  },
});

export const {
  setSelectedReportAction,
  getAvailableReportsFailAction,
  getAvailableReportsSuccessAction,
  getAvailableReportsAction,
  setSelectedFilterAction,
  setSelectedGroupingAction,
  generateReportSuccessAction,
  generateReportFailAction,
  generateReportAction,
  getAdditionalReportDataSuccessAction,
  getAdditionalReportDataFailAction,
  getAdditionalReportDataAction,
  clearAdditionalReportDataAction,
} = reportSlice.actions;

export const useReports = () => useSelector((state: RootState) => state.reports);
