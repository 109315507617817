import React, { FC, useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { addMonths } from 'date-fns';
import { HighlighterBox } from '../../styledComponents/HighlighterBox';

type LeaseStartAndEndDatePickerProps = {
  onChangeLeaseStartAndEndDate: (date: Date) => void;
  onChangeLeaseEndDateOnly: (date: Date) => void;
  leaseStartDate?: Date;
  leaseEndDate?: Date;
};

export const LeaseStartAndEndDatePicker: FC<LeaseStartAndEndDatePickerProps> = ({
  onChangeLeaseStartAndEndDate,
  onChangeLeaseEndDateOnly,
  leaseStartDate,
  leaseEndDate,
}) => {
  const [highlighter, setHighlighter] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setHighlighter(false), 6000);
  }, [highlighter]);

  const handleSetStartDate = (date: Date) => {
    onChangeLeaseStartAndEndDate(date);
    setHighlighter(true);
  };

  const handleSetEndDate = (date: Date) => {
    onChangeLeaseEndDateOnly(date);
  };

  return (
    <>
      <DatePicker
        label={'Lease Start Date'}
        slotProps={{ field: { clearable: false }, textField: { fullWidth: true } }}
        onChange={(date) => date && handleSetStartDate(date)}
        value={leaseStartDate ?? null}
      />
      <HighlighterBox highlight={highlighter}>
        <DatePicker
          label={'Lease End Date'}
          slotProps={{ field: { clearable: false }, textField: { fullWidth: true } }}
          onChange={(date) => date && handleSetEndDate(date)}
          value={leaseEndDate ?? null}
          minDate={leaseStartDate ? addMonths(new Date(leaseStartDate), 2) : undefined}
        />
      </HighlighterBox>
    </>
  );
};
