import React, { FC, useState } from 'react';
import { ExpenseElementType, purchaseOrderStatusMap, ReduxPurchaseOrder } from '../../redux/expenseData';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { StripedDataGrid, styledFilterPanelProps } from '../../../datagrids/StripedDataGrid';
import { GridColDef, GridRenderCellParams, GridRenderEditCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import { NameCell } from './cells/NameCell';
import { tryFormatDate } from '../../../utils/TryFormatDate';
import { removePurchaseOrderAction, useExpenses } from '../../redux/expenseSlice';
import { GlCodeCell } from './cells/GlCodeCell';
import { StatusCell, StatusEditCell } from './cells/StatusCell';
import { ActionCell } from '../../../datagrids/ActionCell';
import { useDispatch } from 'react-redux';
import { AssociateElementDialog } from '../dialogs/AssociateElementDialog';
import { AssociatedValueCell } from './cells/AssociatedValueCell';
import { InternalAssociationCell } from './cells/InternalAssociationCell';
import { VendorDetailsCell } from './cells/VendorDetailsCell';
import EditIcon from '@mui/icons-material/Edit';
import { useIcons } from '../../../icons/useIcons';
import { AddExpenseElementAssociation } from '../dialogs/AddExpenseElementDialog';
import {
  ExpenseInvoiceStatus,
  ExpensePaymentExpensePaymentStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type PurchaseOrderTableProps = {
  onOpenDrawer: (number?: string, type?: ExpenseElementType) => void;
  onOpenAddExpenseChildItem: (
    type: ExpenseElementType,
    isUpstream?: boolean,
    downstreamDetails?: AddExpenseElementAssociation,
  ) => void;
};

export const PurchaseOrderTable: FC<PurchaseOrderTableProps> = ({ onOpenDrawer, onOpenAddExpenseChildItem }) => {
  const { selectedExpense } = useExpenses();
  const { getActionIcon, ActionType } = useIcons();
  const dispatch = useDispatch();
  const rows = selectedExpense.value?.purchaseOrders ?? [];
  const [openInternalExpenseAssociationDialog, setOpenInternalExpenseAssociationDialog] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState<string | undefined>();

  function* createActions(params: GridRowParams<ReduxPurchaseOrder>) {
    if (!params.row.number) return;
    yield (
      <ActionCell
        icon={getActionIcon(ActionType.AssociateExpenseItem)}
        key={'associateQuote'}
        label={params.row.associatedQuotes ? 'Change Associated Quotes' : 'Associate Quotes'}
        onClick={() => {
          setSelectedNumber(params.row.number);
          setOpenInternalExpenseAssociationDialog(true);
        }}
        showInMenu
      />
    );
    yield (
      <ActionCell
        icon={getActionIcon(ActionType.Delete)}
        key={'removePurchaseOrder'}
        label={'Remove Purchase Order'}
        onClick={() => {
          selectedExpense.value?.id &&
            params.row.number &&
            dispatch(
              removePurchaseOrderAction({
                jobId: selectedExpense.value?.id,
                poNumber: params.row.number,
              }),
            );
        }}
        showInMenu
      />
    );
    yield (
      <ActionCell
        icon={getActionIcon(ActionType.Add)}
        key={'createInvoice'}
        label={'Create Invoice'}
        onClick={() =>
          onOpenAddExpenseChildItem(ExpenseElementType.PurchaseOrder, true, {
            association: params.row.number,
            vendor: params.row.vendor,
            propertyAccount: params.row.propertyAccount,
          })
        }
        showInMenu
      />
    );
  }

  const columns: GridColDef<ReduxPurchaseOrder>[] = [
    {
      field: 'number',
      headerName: '#',
      flex: 1.5,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <NameCell {...params} onOpenDrawer={onOpenDrawer} type={ExpenseElementType.PurchaseOrder} />
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <NameCell {...params} onOpenDrawer={onOpenDrawer} type={ExpenseElementType.PurchaseOrder} />
      ),
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <VendorDetailsCell vendor={params.row.vendor} contact={params.row.vendorContact} {...params} />
      ),
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <>{params.value ? tryFormatDate(params.value ?? '') : ''}</>,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <>{params.value ? tryFormatDate(params.value ?? '') : ''}</>,
    },
    {
      field: 'totalAmount',
      headerName: 'PO Amount',
      flex: 0.75,
      renderCell: (params: GridRenderCellParams) => <>{params.value ? `$${params.value}` : ''}</>,
    },
    {
      field: 'associatedInvoice',
      headerName: 'Invoices',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <InternalAssociationCell
          typeToRender={ExpenseElementType.Invoice}
          handleOpenDrawer={onOpenDrawer}
          number={params.row.number}
          multiplePossible={true}
          {...params}
        />
      ),
    },
    {
      field: 'invoiceTotal',
      headerName: 'Invoice Total',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <AssociatedValueCell type={ExpenseElementType.PurchaseOrder} number={params.row.number} {...params} />
      ),
    },
    {
      field: 'associatedPayment',
      headerName: 'Payments',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <InternalAssociationCell
          tableType={ExpenseElementType.PurchaseOrder}
          typeToRender={ExpenseElementType.Payment}
          handleOpenDrawer={onOpenDrawer}
          number={params.row.number}
          multiplePossible={true}
          {...params}
        />
      ),
    },
    {
      field: 'paymentTotal',
      headerName: 'Payment Total',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <AssociatedValueCell
          type={ExpenseElementType.PurchaseOrder}
          number={params.row.number}
          distantAssociationType={ExpenseElementType.Payment}
          {...params}
        />
      ),
    },
    {
      field: 'costToComplete',
      headerName: 'Cost to Complete',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const total = params.row.totalAmount ?? 0;
        const associatedInvoices = selectedExpense.value?.invoices
          ?.filter((x) => x.expenseInvoiceStatus !== ExpenseInvoiceStatus.Archived)
          ?.filter((x) => x.associatedPurchaseOrder === params.row.number);
        const associatedPayments = associatedInvoices
          ?.flatMap((x) => {
            const payments = selectedExpense.value?.payments?.filter(
              (p) => p.status !== ExpensePaymentExpensePaymentStatus.Archived,
            );
            if (payments) {
              return payments.filter((y) => y.associatedInvoice === x.number);
            }
            return [];
          })
          .filter((x) => x !== undefined);
        const costToComplete =
          total - (associatedPayments?.reduce((acc, curr) => (curr.amount ? acc + curr.amount : 0), 0) ?? 0);
        return <>{`$${costToComplete}`}</>;
      },
    },
    {
      field: 'propertyAccount',
      headerName: 'GL Code',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <GlCodeCell {...params} />,
    },
    {
      field: 'purchaseOrderStatus',
      headerName: 'Status',
      flex: 0.75,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => <StatusCell statusMap={purchaseOrderStatusMap} {...params} />,
      editable: true,
      renderHeader: () => {
        return (
          <>
            {'Status '}
            <EditIcon fontSize="small" color="disabled" />
          </>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <StatusEditCell type={ExpenseElementType.PurchaseOrder} statusMap={purchaseOrderStatusMap} {...params} />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.75,
      getActions: (params: GridRowParams) => Array.from(createActions(params)),
    },
  ];

  return (
    <Stack spacing={2} mt={'1rem'}>
      <Box>
        <Button
          variant={'outlined'}
          startIcon={<Add />}
          onClick={() => onOpenAddExpenseChildItem(ExpenseElementType.PurchaseOrder)}
        >
          Add Purchase Order
        </Button>
      </Box>
      <StripedDataGrid
        disableRowGrouping
        rows={rows}
        columns={columns}
        autoHeight
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        slotProps={{
          filterPanel: styledFilterPanelProps,
        }}
      />
      <AssociateElementDialog
        selectedNumber={selectedNumber}
        type={ExpenseElementType.PurchaseOrder}
        onClose={() => {
          setSelectedNumber(undefined);
          setOpenInternalExpenseAssociationDialog(false);
        }}
        open={openInternalExpenseAssociationDialog}
      />
    </Stack>
  );
};
