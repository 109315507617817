import React, { FC, useEffect } from 'react';
import { EmailTemplateType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { FormHelperText, Grid, Select, SelectChangeEvent } from '@mui/material';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { TextFieldWithHighlights } from './TextFieldWithHighlights';
import { EmailTemplatePreview } from './EmailTemplatePreview';
import { useDispatch } from 'react-redux';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { fetchKeysAction, useEmailTemplates } from '../redux/emailTemplateSlice';
import { ReduxEmailTemplate } from '@monkeyjump-labs/cam-fe-shared/dist/types/emailTemplateTypes';

export enum EmailTemplateDialogMode {
  Add = 'Add',
  Edit = 'Edit',
}

export type EmailTemplateInfoFormProps = {
  mode: EmailTemplateDialogMode;
  editingTemplate: ReduxEmailTemplate;
  onEditTemplate: (template: ReduxEmailTemplate) => void;
  dirty: boolean;
};

export const EmailTemplateInfoForm: FC<EmailTemplateInfoFormProps> = ({
  mode,
  editingTemplate,
  onEditTemplate,
  dirty,
}) => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const { allowedKeys } = useEmailTemplates();
  const [targetSpecificAllowedKeys, setTargetSpecificAllowedKeys] = React.useState<string[]>([]);
  const [helperText, setHelperText] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    const allowedKeysString = targetSpecificAllowedKeys.join(' ');
    const tooltip = `Allowed keys: ${allowedKeysString}`;
    setHelperText(tooltip);
  }, [targetSpecificAllowedKeys]);

  useEffect(() => {
    selectedContext.propertyId && dispatch(fetchKeysAction(selectedContext.propertyId));
  }, [selectedContext.propertyId]);

  useEffect(() => {
    if (allowedKeys.value) {
      if (!editingTemplate.type) return;
      setTargetSpecificAllowedKeys(allowedKeys.value[editingTemplate.type] ?? []);
    }
  }, [editingTemplate.type, allowedKeys.value]);

  return (
    <Stack>
      <StyledInfoBox label={'Info'}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              fullWidth
              required
              variant={'outlined'}
              label={'Template Name'}
              value={editingTemplate.name ?? ''}
              onChange={(e) => onEditTemplate({ ...editingTemplate, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            {mode === EmailTemplateDialogMode.Edit ? (
              <TextField
                fullWidth
                required
                variant={'outlined'}
                label={'Type'}
                value={editingTemplate.type}
                disabled
                helperText={helperText}
              />
            ) : (
              <FormControl fullWidth sx={{ mt: '.5rem' }}>
                <InputLabel>Type</InputLabel>
                <Select
                  required
                  value={editingTemplate.type}
                  label={'Type'}
                  onChange={(e: SelectChangeEvent<EmailTemplateType>) => {
                    e.target.value && onEditTemplate({ ...editingTemplate, type: e.target.value as EmailTemplateType });
                  }}
                >
                  {[EmailTemplateType.Lease, EmailTemplateType.Application].map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
                {editingTemplate.type && <FormHelperText>{helperText}</FormHelperText>}
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextFieldWithHighlights
              allowedKeys={targetSpecificAllowedKeys}
              fullWidth
              required
              variant={'outlined'}
              label={'Subject'}
              value={editingTemplate.subject ?? ''}
              onChange={(e) => onEditTemplate({ ...editingTemplate, subject: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldWithHighlights
              allowedKeys={targetSpecificAllowedKeys}
              fullWidth
              multiline
              minRows={5}
              required
              variant={'outlined'}
              label={'Body'}
              value={editingTemplate.body ?? ''}
              onChange={(e) => onEditTemplate({ ...editingTemplate, body: e.target.value })}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <LoadingButton type={'submit'} variant={'contained'} loading={false} disabled={!dirty}>
            Save
          </LoadingButton>
        </Box>
      </StyledInfoBox>
      <EmailTemplatePreview emailTemplate={editingTemplate} />
    </Stack>
  );
};
