import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  DefaultSelection,
  ISlimAccountRef,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import List from '@mui/material/List';
import { ListItem } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { ReduxDate, ReduxMtAccount } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Divider from '@mui/material/Divider';
import { BankAccountStatus } from './BankAccountStatus';
import { BankAccountActions } from './BankAccountActions';
import { DialogLayout } from '../../dialogs/DialogLayout';
import { AddOrWithdrawFundsForm } from './AddOrWithdrawFundsForm';
import {
  addOrWithdrawFundsToPropertyBankAccountAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { useDispatch } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import WarningIcon from '@mui/icons-material/Warning';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { FundsFlowDirection } from '@monkeyjump-labs/cam-fe-shared/dist/types/propertyTypes';

type BankingInfoTableProps = {
  rows: ReduxMtAccount[];
  onDeleteAccount: (acct: ReduxMtAccount) => void;
  onSetDefaultAcct: (acctId: string, defaultSelection: DefaultSelection) => void;
  isPropertyList: boolean;
  onViewStatement?: (linkedAccountId?: string) => void;
  loadingNewAccount?: boolean;
  onPlaidRefresh: (acct: ReduxMtAccount) => void;
};

export const BankingInfoList: FC<BankingInfoTableProps> = ({
  rows,
  onDeleteAccount,
  onSetDefaultAcct,
  isPropertyList,
  onViewStatement,
  loadingNewAccount,
  onPlaidRefresh,
}) => {
  const dispatch = useDispatch();
  const { selectedProperty, glAccounts } = useProperty();
  const [selectedBankAccountId, setSelectedBankAccountId] = useState<string | undefined>(undefined);
  const [openAddDisburseFundsDialog, setOpenAddDisburseFundsDialog] = useState(false);
  const [fundsFlowDirection, setFundsFlowDirection] = useState<FundsFlowDirection | undefined>(undefined);
  const [glAccountId, setGlAccountId] = useState<string | undefined>(undefined);
  const [accountRefreshModal, setAccountRefreshModal] = useState(false);

  useEffect(() => {
    if (selectedProperty.value) {
      const linkedGlId = selectedProperty.value.rentalBillingInfo?.find(
        (i) => i.bankAccount?.mtAccountId === selectedBankAccountId,
      )?.linkedGlAccount?.id;
      setGlAccountId(linkedGlId);
    }
  }, [selectedBankAccountId]);

  useEffect(() => {
    if (glAccounts.submitted) handleCloseAddWithdrawFundDialog();
  }, [glAccounts.submitted]);

  useEffect(() => {
    if (rows) {
      const plaidNeedsRefresh = rows.some((a) => a.isNeedsPlaidRefresh);
      if (plaidNeedsRefresh) setAccountRefreshModal(true);
      else setAccountRefreshModal(false);
    }
  }, [rows]);

  const handleAddDisburseFunds = (accountId: string, fundsFlowDirection: FundsFlowDirection) => {
    setSelectedBankAccountId(accountId);
    setFundsFlowDirection(fundsFlowDirection);
    setOpenAddDisburseFundsDialog(true);
  };

  const handleCloseAddWithdrawFundDialog = () => {
    setFundsFlowDirection(undefined);
    setOpenAddDisburseFundsDialog(false);
  };

  const handleSubmit = (secondaryAccount: ISlimAccountRef, description: string, amount: number, date: ReduxDate) => {
    if (!selectedProperty.value?.id || !glAccountId || !fundsFlowDirection || !secondaryAccount?.id) return;
    dispatch(
      addOrWithdrawFundsToPropertyBankAccountAction({
        propertyId: selectedProperty.value.id,
        primaryAccountId: glAccountId,
        secondaryAccountId: secondaryAccount.id,
        description,
        amount,
        date,
        flowOfFunds: fundsFlowDirection,
      }),
    );
  };

  const revalidateAccountModal = (
    <>
      <DialogLayout
        title="Please re-validate bank account"
        open={accountRefreshModal}
        onClose={() => setAccountRefreshModal(false)}
        level="warning"
      >
        <Stack direction="row" spacing={3} sx={{ p: 4 }}>
          <WarningIcon />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            It appears that something has changed with a bank account, such as a password or another security related
            measure.
            <br />
            {isPropertyList
              ? 'Please re-validate the affected account.'
              : 'Please re-validate the affected account in the Manage Account section.'}
          </Typography>
        </Stack>
      </DialogLayout>
    </>
  );

  return (
    <Box>
      {rows.length === 0 && <Typography>No bank accounts currently set up.</Typography>}
      <List sx={{ width: '100%', height: '90%', backgroundColor: 'background.paper' }}>
        {loadingNewAccount && <LinearProgress />}
        {rows.map((acct) => {
          return (
            <ListItem key={acct.mtAccountId} sx={{ display: 'flex', flexGrow: 1 }}>
              <Card sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
                <CardContent>
                  <Stack direction="row">
                    {acct.isNeedsPlaidRefresh && (
                      <Tooltip title={'Re-validate bank account'}>
                        <WarningIcon color="warning"></WarningIcon>
                      </Tooltip>
                    )}
                    <Typography>{`${acct.name} (${acct.plaidSubType}: # **${acct.safeBankAccount})`}</Typography>
                  </Stack>
                </CardContent>
                <CardActions>
                  <BankAccountStatus acct={acct} onSetDefaultAcct={onSetDefaultAcct} isPropertyList={isPropertyList} />
                  <Divider sx={{ ml: 1 }} orientation="vertical" flexItem />
                  <BankAccountActions
                    isPropertyList={isPropertyList}
                    acct={acct}
                    plaidNeedsRefresh={acct.isNeedsPlaidRefresh ?? false}
                    onDeleteAccount={onDeleteAccount}
                    onViewStatement={onViewStatement}
                    onAddDisburseFunds={handleAddDisburseFunds}
                    onRevalidateAccount={onPlaidRefresh}
                  />
                </CardActions>
              </Card>
            </ListItem>
          );
        })}
      </List>
      {isPropertyList && (
        <DialogLayout
          title={fundsFlowDirection ?? ''}
          onClose={handleCloseAddWithdrawFundDialog}
          open={openAddDisburseFundsDialog}
        >
          <AddOrWithdrawFundsForm
            onSubmit={handleSubmit}
            onClose={handleCloseAddWithdrawFundDialog}
            flowOfFundsDirection={fundsFlowDirection}
            loading={glAccounts.submitting}
          />
        </DialogLayout>
      )}
      {revalidateAccountModal}
    </Box>
  );
};
