import React, { FC, useEffect, useState } from 'react';
import {
  AssociationType,
  ICamAssociation,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import { useAssociations } from './useAssociations';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { useIcons } from '../icons/useIcons';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

type AssociationListItemProps = {
  a: ICamAssociation;
  onListItemSelect: (a: ICamAssociation) => void;
  onRemoveAssociation?: (association: ICamAssociation) => void;
};

export const AssociationListItem: FC<AssociationListItemProps> = ({ a, onListItemSelect, onRemoveAssociation }) => {
  const theme = useTheme();
  const { getAssociationIcon } = useIcons();
  const [color, setColor] = useState('inherit');
  const { handleGetChipColor } = useAssociations();

  useEffect(() => {
    const getColor = async () => {
      try {
        if (a.associationType === AssociationType.Task && a.associatedId) {
          const color = handleGetChipColor([a]);
          switch (color) {
            case 'secondary':
              setColor(theme.palette.secondary.main);
              break;
            case 'info':
              setColor(theme.palette.secondary.light);
              break;
            case 'success':
              setColor(theme.palette.success.main);
              break;
            default:
              break;
          }
        }
      } catch (error) {
        console.error('Error fetching color:', error);
        setColor('error');
      }
    };

    getColor();
  }, [a]);

  return (
    <ListItem
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        backgroundColor: color,
        borderRadius: theme.shape.borderRadius,
        my: '.1rem',
        border: '.1rem solid gray',
      }}
    >
      <Link style={{ color: 'inherit', cursor: 'pointer' }} onClick={() => onListItemSelect(a)}>
        <Box display={'flex'} flexGrow={1}>
          {getAssociationIcon(a.associationType)} {a.label}
        </Box>
      </Link>
      {onRemoveAssociation && (
        <Tooltip title={'Remove Association'}>
          <IconButton size={'small'} onClick={() => onRemoveAssociation(a)}>
            <LinkOffIcon />
          </IconButton>
        </Tooltip>
      )}
    </ListItem>
  );
};
