import React from 'react';
import { Children } from './Children';
import { Pets } from './Pets';
import { Vehicles } from './Vehicles';
import { updateApplicationAction } from '../../../../redux/applicationSlice';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import { ApplicationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ReduxApplication } from '../../../../redux/applicationTypes';

type Props = {
  applicant: ReduxApplication;
  onOpenAddChild?: () => void;
  onOpenAddPet: () => void;
  onOpenAddVehicle: () => void;
  onOpenAddHousingHistory?: () => void;
};

export const OtherInfo = ({ applicant, onOpenAddChild, onOpenAddPet, onOpenAddVehicle }: Props) => {
  const dispatch = useDispatch();

  const handleRemoveDependent = (updatedDependents: ReduxApplication) => {
    applicant.id &&
      applicant.applicationType &&
      dispatch(
        updateApplicationAction({
          id: applicant?.id,
          type: applicant.applicationType,
          body: updatedDependents,
        }),
      );
  };

  return (
    <Stack spacing={2}>
      {applicant.applicationType === ApplicationType.Primary ? (
        <Children applicant={applicant} onRemoveChild={handleRemoveDependent} onOpen={onOpenAddChild} />
      ) : (
        <></>
      )}
      <Pets applicant={applicant} onRemovePet={handleRemoveDependent} onOpen={onOpenAddPet} />
      <Vehicles applicant={applicant} onRemoveVehicle={handleRemoveDependent} onOpen={onOpenAddVehicle} />
    </Stack>
  );
};
