import React, { FC } from 'react';
import { DialogLayout } from '../../dialogs/DialogLayout';
import { ImageCropper } from './ImageCropper';
import DialogContent from '@mui/material/DialogContent';

type ImageCropperDialogProps = {
  open: boolean;
  onClose: () => void;
  selectedFile?: File;
  onUpload: (file: File) => void;
};

export const ImageCropperDialog: FC<ImageCropperDialogProps> = ({ onClose, onUpload, selectedFile, open }) => {
  return (
    <DialogLayout title={'Image Cropper'} onClose={onClose} open={open}>
      <DialogContent sx={{ my: 2 }}>
        <ImageCropper selectedFile={selectedFile} onUpload={onUpload} />
      </DialogContent>
    </DialogLayout>
  );
};
