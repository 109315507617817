import React, { FC } from 'react';
import { StripedDataGrid } from '../../../../../_shared/datagrids/StripedDataGrid';
import { GridColDef } from '@mui/x-data-grid-premium';
import { IVendor } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { ReduxVendor } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

type VendorManagementTable = {
  loading: boolean;
  value: ReduxVendor[];
};

export const VendorManagementTable: FC<VendorManagementTable> = ({ loading, value }) => {
  const navigate = useNavigate();
  const { selectedContext } = useAssets();

  const handleVendorClick = (vendorId?: string) => {
    vendorId && navigate(`/assets/property/${selectedContext.propertyId}/info/vendors/vendor/${vendorId}/info`);
  };

  const columns: GridColDef<IVendor>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <Link sx={{ cursor: 'pointer' }} onClick={() => handleVendorClick(params.row?.id)}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
      renderCell: (params) => {
        return <Link href={`tel:${params.row.contactInfo?.phone}`}>{params.row.contactInfo?.phone}</Link>;
      },
    },
    {
      field: 'contactInfo',
      headerName: 'Email',
      flex: 1,
      renderCell: (params) => {
        return <Link href={`mailto:${params.row.contactInfo?.email}`}>{params.row.contactInfo?.email}</Link>;
      },
    },
    {
      field: 'primaryContact',
      headerName: 'Contact Person',
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.primaryContact?.firstName} {params.row.primaryContact?.lastName}
          </>
        );
      },
    },
  ];
  return (
    <StripedDataGrid
      disableRowGrouping
      autoHeight
      loading={loading}
      columns={columns}
      rows={value}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
    />
  );
};
