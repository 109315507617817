import { PaymentTitle } from './LeasePaymentDialog';
import {
  acceptLeaseDepositAction,
  acceptLeasePaymentAction,
  applyLeaseChargeAction,
  applyLeaseCreditAction,
  logPaymentAction,
  rectifyDepositAction,
  returnLeaseFundsAction,
} from '../../leases/redux/leaseTransactionSlice';
import { endpointIsntIntegratedAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { LeaseDispatchType } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { useDispatch } from 'react-redux';
import {
  ISlimAccountRef,
  PaymentType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const useHandleTransaction = () => {
  const dispatch = useDispatch();

  const handleTransaction = (
    leaseId: string,
    assetId: string,
    leasesType: LeaseDispatchType,
    paymentType: PaymentType | undefined,
    paymentNo: string | undefined,
    type: PaymentTitle,
    description: string,
    amount: number,
    account?: ISlimAccountRef,
    secondaryAccount?: ISlimAccountRef,
  ) => {
    switch (type) {
      case 'Accept Deposit':
        dispatch(
          acceptLeaseDepositAction({
            leaseId: leaseId,
            propertyId: assetId,
            body: {
              description,
              amount,
              paymentNo,
              paymentType,
            },
          }),
        );
        break;
      case 'Accept Payment':
        dispatch(
          acceptLeasePaymentAction({
            leaseId: leaseId,
            assetId: assetId,
            leaseType: leasesType,
            body: {
              description,
              amount,
              paymentNo,
              paymentType,
            },
          }),
        );
        break;
      case 'Apply Charge':
        dispatch(
          applyLeaseChargeAction({
            leaseId: leaseId,
            assetId: assetId,
            leaseType: leasesType,
            body: {
              revenueAccountId: account?.id,
              description,
              amount,
            },
          }),
        );
        break;
      case 'Apply Credit':
        dispatch(
          applyLeaseCreditAction({
            leaseId: leaseId,
            assetId: assetId,
            leaseType: leasesType,
            body: {
              description,
              amount,
              arAccountId: account?.id,
            },
          }),
        );
        break;
      case 'Rectify Deposit':
        dispatch(
          rectifyDepositAction({
            leaseId: leaseId,
            assetId: assetId,
            leaseType: leasesType,
          }),
        );
        break;
      case 'Return Funds':
        dispatch(
          returnLeaseFundsAction({
            leaseId: leaseId,
            assetId: assetId,
            leaseType: leasesType,
            body: {
              description: 'Return Funds to Tenant',
              paymentNo: paymentNo,
              accountId: account?.id,
            },
          }),
        );
        break;
      case 'Log Returned Funds':
        dispatch(
          logPaymentAction({
            leaseId: leaseId,
            assetId: assetId,
            leaseType: leasesType,
            body: {
              amount: amount,
              description: description,
              assetAccountId: account?.id,
              arAccountId: secondaryAccount?.id,
              paymentType: paymentType,
              paymentNo: paymentNo,
            },
          }),
        );
        break;
      default:
        dispatch(endpointIsntIntegratedAction());
    }
  };

  return { handleTransaction };
};
