import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getAutomationsByPropertyIdAction,
  useSopTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import { SopTaskDialog } from './SopTaskDialog';
import { getAllCategoriesAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/categorySlice';
import Button from '@mui/material/Button';
import { SopTaskList } from './SopTaskList';
import { ReduxSopTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetParams } from '../../../../../../../AppRouter';
import { SopTaskTab } from '../../../../../../_shared/tabs/TabTypeEnums';

type TaskAutomationsProps = {
  isDialog?: boolean;
};

export const SopTaskTemplates: FC<TaskAutomationsProps> = ({ isDialog }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, outerTab, innerTab } = useParams<AssetParams>();
  const { selectedContext } = useAssets();
  const propertyId = selectedContext.propertyId;
  const { sopTasks, selectedSopTask } = useSopTasks();
  const [openAutomationDialog, setOpenAutomationDialog] = useState(false);
  const [showAddAutomation, setShowAddAutomation] = useState(false);
  const [pageSize, setPageSize] = useState<number>(100);
  const [page] = useState<number>(0);

  useEffect(() => {
    if (!propertyId) return;
    dispatch(
      getAutomationsByPropertyIdAction({
        id: propertyId,
        page: page,
        pageSize: pageSize,
        associatedId: undefined,
        associationType: undefined,
        includePropertyTasks: undefined,
      }),
    );
    dispatch(getAllCategoriesAction({ propertyId, includeDisabled: false }));
  }, [propertyId]);

  useEffect(() => {
    propertyId &&
      dispatch(
        getAutomationsByPropertyIdAction({
          id: propertyId,
          page: page,
          pageSize: pageSize,
          associatedId: undefined,
          associationType: undefined,
          includePropertyTasks: undefined,
        }),
      );
  }, [pageSize, selectedSopTask.submitted]);

  const handleOpen = () => {
    setOpenAutomationDialog(true);
  };

  const handleClose = () => {
    setOpenAutomationDialog(false);
    setShowAddAutomation(false);
  };

  const handleShowAddAutomation = () => {
    !isDialog && setOpenAutomationDialog(true);
    setShowAddAutomation(true);
  };

  const handleHideAddAutomation = () => {
    setShowAddAutomation(false);
  };

  const handleShowEditAutomation = (automation: ReduxSopTask) => {
    navigate(`/assets/property/${id}/${outerTab}/${innerTab}/sopTask/${automation.id}/${SopTaskTab.Task}`);
  };

  return (
    <>
      {isDialog ? (
        <Button variant={'outlined'} onClick={handleOpen}>
          SOP Tasks
        </Button>
      ) : (
        <>
          <SopTaskList
            sopTasks={sopTasks.value ?? []}
            isLoading={sopTasks.loading}
            isSubmitting={sopTasks.submitting}
            onAddAutomation={handleShowAddAutomation}
            onEditAutomation={handleShowEditAutomation}
            isInAutomationDialog={false}
            page={page}
            pageSize={pageSize}
          />
          {sopTasks.value && sopTasks.value.length >= 10 && (
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right', mr: '1rem' }}>
              <LoadingButton loading={sopTasks.loading} onClick={() => setPageSize(pageSize + 10)}>
                See More
              </LoadingButton>
            </Box>
          )}
        </>
      )}
      {selectedContext.propertyId && (
        <SopTaskDialog
          open={openAutomationDialog}
          onClose={handleClose}
          showAddAutomation={showAddAutomation}
          onShowAddAutomation={handleShowAddAutomation}
          onHideAddAutomation={handleHideAddAutomation}
          onShowEditAutomation={handleShowEditAutomation}
          isListInDialog={isDialog ?? false}
          propertyId={selectedContext.propertyId}
          page={page}
          pageSize={pageSize}
        />
      )}
    </>
  );
};
