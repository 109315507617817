import React from 'react';
import { useFinancialMetrics } from '@monkeyjump-labs/cam-fe-shared/dist/redux/metrics/metricsSlice';
import Grid from '@mui/material/Grid';
import { KpiDisplay } from './KpiDisplay';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const CurrentFinancialMetrics = () => {
  const { currentPropertyMetrics } = useFinancialMetrics();
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <Grid container spacing={2} paddingY={'1rem'} paddingX={'.2rem'}>
      <Grid item xs={6}>
        <KpiDisplay
          title={'Monthly Collections'}
          dollarAmount
          kpi={currentPropertyMetrics.value?.financialMetrics?.monthlyCollections ?? 0}
          tooltip={
            <Stack>
              <Typography variant={'caption'}>
                In Transit:{' '}
                {numberFormat.format(currentPropertyMetrics.value?.financialMetrics?.inTransitMonthlyCollections ?? 0)}
              </Typography>
              <Typography variant={'caption'}>
                Undeposited:{' '}
                {numberFormat.format(
                  currentPropertyMetrics.value?.financialMetrics?.undepositedMonthlyCollections ?? 0,
                )}
              </Typography>
            </Stack>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <KpiDisplay
          title={'Total Deposits'}
          dollarAmount
          kpi={currentPropertyMetrics.value?.financialMetrics?.totalDeposits ?? 0}
          tooltip={
            <Stack>
              <Typography variant={'caption'}>
                Deposit Liability:{' '}
                {numberFormat.format(currentPropertyMetrics.value?.financialMetrics?.depositLiability ?? 0)}
              </Typography>
              <Typography variant={'caption'}>
                Deposits Receivable:{' '}
                {numberFormat.format(currentPropertyMetrics.value?.financialMetrics?.depositsReceivable ?? 0)}
              </Typography>
            </Stack>
          }
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <KpiDisplay
          title={'Total Delinquency'}
          dollarAmount
          kpi={currentPropertyMetrics.value?.financialMetrics?.totalDelinquency ?? 0}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <KpiDisplay
          title={'Delinquency for Evicted Units'}
          dollarAmount
          kpi={currentPropertyMetrics.value?.financialMetrics?.evictionUnitsDelinquency ?? 0}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <KpiDisplay
          title={'Delinquency for Non-Evicted Units'}
          kpi={currentPropertyMetrics.value?.financialMetrics?.nonEvictedUnitsDelinquency ?? 0}
          dollarAmount
        />
      </Grid>
    </Grid>
  );
};
