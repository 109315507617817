import React, { FC, useEffect } from 'react';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import { VendorManagementTable } from './VendorManagementTable';
import {
  addVendorForPropertyAction,
  listVendorsForPropertyAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Add from '@mui/icons-material/Add';
import { AddVendorDialog } from './AddVendorDialog';
import { useDispatch } from 'react-redux';
import { IAddVendorHandlerRequest } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const VendorManagement: FC = () => {
  const { selectedProperty, vendors } = useProperty();
  const [addVendorOpen, setAddVendorOpen] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    selectedProperty.value?.id && dispatch(listVendorsForPropertyAction({ propertyId: selectedProperty.value.id }));
  }, [selectedProperty]);

  useEffect(() => {
    if (vendors.submitted && addVendorOpen) {
      setAddVendorOpen(false);
    }
  }, [vendors.submitted]);

  const handleOpenVendorDialog = () => {
    setAddVendorOpen(true);
  };

  const handleCloseVendorDialog = () => {
    setAddVendorOpen(false);
  };

  const handleAddVendor = (vendor: IAddVendorHandlerRequest) => {
    selectedProperty.value?.id &&
      dispatch(addVendorForPropertyAction({ propertyId: selectedProperty.value.id, body: vendor }));
  };

  return (
    <>
      <AddVendorDialog
        open={addVendorOpen}
        onClose={handleCloseVendorDialog}
        onSubmit={handleAddVendor}
        submitting={vendors.submitting}
      />
      <StyledInfoBox label={'Vendor Management'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button startIcon={<Add />} onClick={handleOpenVendorDialog} variant={'outlined'}>
              Add Vendor
            </Button>
          </Grid>
          <Grid item xs={12}>
            <VendorManagementTable loading={vendors.loading} value={vendors.value ?? []} />
          </Grid>
        </Grid>
      </StyledInfoBox>
    </>
  );
};
