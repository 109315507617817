import React, { FC } from 'react';
import { ReduxDetail } from '@monkeyjump-labs/cam-fe-shared/dist/types/detailTypes';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { SelectChangeEvent } from '@mui/material/Select';
import { DetailStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { DetailSpecificationsContent } from './DetailSpecificationsContent';
import Stack from '@mui/material/Stack';
import { DetailStatusSelect } from './DetailStatusSelect';
import { DatePicker } from '@mui/x-date-pickers-pro';

export type DetailInfoFormProps = {
  detail: ReduxDetail;
  onEditDetail: (detail: ReduxDetail) => void;
  onDeleteSpecification: (key: string) => void;
  onAddSpecification: (key: string, value: string) => void;
  isEditingMode?: boolean;
  dirty?: boolean;
  loading: boolean;
  isSubDetail?: boolean;
};

export const DetailInfoForm: FC<DetailInfoFormProps> = ({
  detail,
  onEditDetail,
  isEditingMode,
  onDeleteSpecification,
  onAddSpecification,
  dirty,
  loading,
  isSubDetail,
}) => {
  return (
    <Stack spacing={2}>
      <StyledInfoBox label={'Info'}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              fullWidth
              required
              variant={'outlined'}
              label={'Name'}
              value={detail.name ?? ''}
              onChange={(e) => onEditDetail({ ...detail, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              multiline
              minRows={2}
              variant={'outlined'}
              label={'Description'}
              value={detail.description ?? ''}
              onChange={(e) => onEditDetail({ ...detail, description: e.target.value })}
            />
          </Grid>
          <Grid item xs={isSubDetail ? 12 : 6}>
            <DatePicker
              slotProps={{ field: { clearable: true } }}
              label={'Date Installed'}
              onChange={(date: Date | null) =>
                onEditDetail({ ...detail, dateInstalled: date !== null ? toReduxDate(date) : undefined })
              }
              value={detail.dateInstalled ? toStandardDate(detail.dateInstalled) : null}
            />
          </Grid>
          <Grid item xs={isSubDetail ? 12 : 6} sx={{ minWidth: '3rem' }}>
            <DetailStatusSelect
              value={detail.status}
              onChange={(e: SelectChangeEvent) =>
                onEditDetail({
                  ...detail,
                  status: e.target.value as DetailStatus,
                })
              }
            />
          </Grid>
          {isEditingMode && (
            <>
              <Grid item xs={8} />
              <Grid item xs={4}>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
                  <LoadingButton variant={'contained'} type={'submit'} loading={loading} disabled={!dirty}>
                    Save Changes
                  </LoadingButton>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </StyledInfoBox>
      <Grid item xs={12}>
        <DetailSpecificationsContent
          detail={detail}
          onDeleteSpecification={onDeleteSpecification}
          onAddSpecification={onAddSpecification}
          isEditingMode={isEditingMode ?? false}
        />
      </Grid>
    </Stack>
  );
};
