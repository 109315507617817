import React, { FC, FormEvent, useEffect, useState } from 'react';
import {
  DetailAssociationType,
  IUnitTemplate,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DrawerLayout, DrawerNavItem } from '../../../global/layout/DrawerLayout';
import { useRouting } from '../../utils/useRouting';
import { DetailsTab, UnitTemplateTab } from '../../tabs/TabTypeEnums';
import { useIcons } from '../../icons/useIcons';
import { getAssetTypeFromPathname } from '../../utils/getAssetTypeFromPathname';
import { Box } from '@mui/material';
import { updateUnitTemplateAction, useUnitTemplates } from '../redux/unitTemplateSlice';
import { UnitTemplateInfoForm } from './UnitTemplateInfoForm';
import { FullForm } from '../../styledComponents/FullForm';
import { DetailsDrawerTable } from '../../details/components/drawer/DetailsDrawerTable';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const UnitTemplateDrawer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getTabIcon } = useIcons();
  const { unitTemplates } = useUnitTemplates();
  const { id, outerTab, innerTab, templateId, tab } = useParams();
  const { handleNavigateFromAssociationClose } = useRouting();
  const [editingUnitTemplate, setEditingUnitTemplate] = useState<IUnitTemplate | undefined>(undefined);
  const [drawerName, setDrawerName] = useState<string>('Unit Template');

  useEffect(() => {
    const template = unitTemplates.value?.find((t) => t.id === templateId);

    if (template) {
      setEditingUnitTemplate(template);
      setDrawerName(template.name ?? 'Unit Template');
    }
  }, [templateId]);

  const handleCloseDrawer = () => {
    setEditingUnitTemplate(undefined);
    handleNavigateFromAssociationClose();
  };

  const tabs: DrawerNavItem[] = [
    {
      icon: getTabIcon('info'),
      label: 'Info',
      value: UnitTemplateTab.Info,
    },
    {
      icon: <FormatListBulletedIcon />,
      label: 'Details',
      value: UnitTemplateTab.Details,
    },
  ];

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (templateId) {
      dispatch(
        updateUnitTemplateAction({
          id: templateId,
          name: editingUnitTemplate?.name,
          info: editingUnitTemplate?.info,
        }),
      );
    }
  };

  return (
    <>
      {editingUnitTemplate && (
        <>
          <DrawerLayout
            title={drawerName}
            open={!!editingUnitTemplate}
            onCloseDrawer={handleCloseDrawer}
            tabs={tabs}
            tabValue={tab}
            onTabChange={(newValue: string) => {
              navigate(
                `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/unitTemplate/${templateId}/${newValue}`,
              );
            }}
          >
            <Box sx={{ width: '100%', m: 1 }}>
              {
                {
                  info: (
                    <FullForm onSubmit={onSubmit}>
                      <UnitTemplateInfoForm
                        editingTemplate={editingUnitTemplate}
                        onEditTemplate={(template) => {
                          setEditingUnitTemplate(template);
                        }}
                      />
                    </FullForm>
                  ),
                  details: (
                    <DetailsDrawerTable
                      detailAssociationType={DetailAssociationType.UnitTemplate}
                      associatedId={templateId ?? ''}
                      handleViewDetail={(detail) => {
                        navigate(
                          `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/detail/${detail.id}/${DetailsTab.Info}`,
                        );
                      }}
                    />
                  ),
                }[tab ?? 'info']
              }
            </Box>
          </DrawerLayout>
        </>
      )}
    </>
  );
};
