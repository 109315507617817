import React, { FC, FormEvent, useEffect, useState } from 'react';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';
import { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { createTaskFromTenantRequestAction, useTenant } from '../../redux/tenantSlice';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import {
  IAddTaskFromTenantRequestHandlerRequest,
  TaskType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';

type AddMaintenanceRequestDialogProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
  selectedLeaseId?: string;
};

export const AddMaintenanceRequestDialog: FC<AddMaintenanceRequestDialogProps> = ({
  open,
  onClose,
  selectedLeaseId,
}) => {
  const dispatch = useDispatch();
  const { maintenanceRequests } = useTenant();
  const [value, setValue] = useState<IAddTaskFromTenantRequestHandlerRequest>({
    name: undefined,
    description: undefined,
    taskType: TaskType.Normal,
  });

  const handleClose = () => {
    onClose();
    setValue({
      name: undefined,
      description: undefined,
      taskType: TaskType.Normal,
    });
  };

  useEffect(() => {
    maintenanceRequests.submitted && handleClose();
  }, [maintenanceRequests.submitted]);

  const handleTaskType = (e: SelectChangeEvent) => {
    e.target.value === 'Normal'
      ? setValue({ ...value, taskType: TaskType.Normal })
      : e.target.value === 'Safety'
        ? setValue({ ...value, taskType: TaskType.Safety })
        : setValue({ ...value, taskType: TaskType.Urgent });
  };

  const handleAddMaintenanceRequest = (e: FormEvent) => {
    e.preventDefault();
    selectedLeaseId && dispatch(createTaskFromTenantRequestAction({ leaseId: selectedLeaseId, body: value }));
  };

  return (
    <DialogLayout
      open={open}
      onClose={handleClose}
      title={'Add Maintenance Request'}
      aria-labelledby="add-maintenance-request-title"
      sx={{
        zIndex: 5,
      }}
    >
      <form onSubmit={handleAddMaintenanceRequest}>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              autoFocus
              fullWidth
              required
              variant={'outlined'}
              label={"What's wrong?"}
              onChange={(e) => setValue({ ...value, name: e.target.value })}
              value={value?.name ?? ''}
            />
            <TextField
              fullWidth
              label={'Give some details about the nature of the issue'}
              variant={'outlined'}
              required
              multiline
              minRows={4}
              onChange={(e) => setValue({ ...value, description: e.target.value })}
              value={value?.description ?? ''}
            />
            <FormControl fullWidth>
              <InputLabel id="task-type">Urgency of Issue</InputLabel>
              <Select
                labelId="task-type-label"
                id="task-type"
                label="Urgency of Issue"
                value={value?.taskType ? value.taskType : ''}
                onChange={(e) => {
                  handleTaskType(e);
                }}
              >
                <MenuItem value={'Normal'}>Normal</MenuItem>
                <MenuItem value={'Safety'}>Safety</MenuItem>
                <MenuItem value={'Urgent'}>Urgent</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant={'outlined'}>
            Back
          </Button>
          <LoadingButton loading={maintenanceRequests.submitting} variant={'contained'} type={'submit'}>
            Add Request
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};
