import React, { useEffect, useState } from 'react';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import { addCommentToApplicationAction, useApplications } from '../../../../redux/applicationSlice';
import { useDispatch } from 'react-redux';
import { CommentList } from '../../../../../_shared/comments/CommentList';
import { ReduxComment } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { ReduxApplication } from '../../../../redux/applicationTypes';

type Props = {
  applicant: ReduxApplication;
};

export const ApplicationComments = ({ applicant }: Props) => {
  const dispatch = useDispatch();
  const { selectedApplication } = useApplications();
  const [orderedComments, setOrderedComments] = useState<ReduxComment[] | undefined>();

  useEffect(() => {
    setOrderedComments(handleOrderedComments());
  }, [applicant.comments]);

  const handleOrderedComments = () => {
    if (applicant.comments) {
      const comments = [...applicant.comments];
      comments?.sort((a, b) => (!a.createdOn > !b.createdOn ? 1 : -1));
      return comments;
    }
  };

  const handleAddComment = (comment: string) => {
    if (applicant.id && applicant.applicationType) {
      dispatch(
        addCommentToApplicationAction({
          id: applicant.id,
          comment,
        }),
      );
    }
  };

  return (
    <StyledInfoBox label={'Comments'}>
      <CommentList
        comments={orderedComments ?? []}
        onAddComment={handleAddComment}
        loading={selectedApplication.submitting}
      />
    </StyledInfoBox>
  );
};
