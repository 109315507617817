import React, { FC } from 'react';
import { NumberValueTextField } from '../../../../../_shared/texfields/NumberValueTextField';
import { ReportFilterType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import { CustomizableFilter, setSelectedFilterAction } from '../../../../redux/reportSlice';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { DatePicker } from '@mui/x-date-pickers-pro';

type FilterOptionProps = {
  value: CustomizableFilter;
};
export const FilterOption: FC<FilterOptionProps> = ({ value }) => {
  const dispatch = useDispatch();

  if (value.type == ReportFilterType.Date) {
    return (
      <DatePicker
        label={value.label}
        onChange={(d: Date | null) => {
          dispatch(setSelectedFilterAction({ ...value, value: d ? toReduxDate(d) : undefined }));
        }}
        slotProps={{ textField: { fullWidth: true } }}
        value={value.value ? toStandardDate(value.value) : undefined}
      />
    );
  }
  // else if (value.type === ReportFilterType.Select) {
  //   return (
  //     <FormControl fullWidth sx={{ pt: 1 }}>
  //       <InputLabel id={value.name} sx={{ pt: 1 }}>
  //         {value.label}
  //       </InputLabel>
  //       <Select labelId={value.name} id={value.name} value={value.defaultValue} label={value.label} onChange={() => {}}>
  //         {value.options?.map((o) => (
  //           <MenuItem key={o} value={o}>
  //             {o}
  //           </MenuItem>
  //         ))}
  //       </Select>
  //     </FormControl>
  //   );
  // }
  else if (value.type === ReportFilterType.Number) {
    return (
      <NumberValueTextField
        fullWidth
        label={value.label}
        onChange={() => {}}
        value={value.defaultValue}
        valueUnits={'none'}
      />
    );
  } else return <>Filter display not implemented</>;
};
