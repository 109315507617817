export function findIdColumnName(strings?: string[]): string | undefined {
  if (!strings) return undefined;
  for (let i = 0; i < strings.length; i++) {
    for (let j = 0; j < strings.length; j++) {
      if (i !== j && strings[j].endsWith('Id') && strings[j].includes(strings[i])) {
        return strings[i];
      }
    }
  }
  return undefined;
}
