import React, { useEffect, useState } from 'react';
import { ExpandableCard } from '../../../_shared/styledComponents/ExpandableCard';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { updateUserAction, useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { useDispatch } from 'react-redux';

export const UserInfo = () => {
  const dispatch = useDispatch();
  const user = useUser((u) => u.currentUser.value);
  const [firstName, setFirstName] = useState(user?.firstName ?? '');
  const [lastName, setLastName] = useState(user?.lastName ?? '');
  const [phone, setPhone] = useState(user?.phone);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName ?? '');
      setLastName(user.lastName ?? '');
      setPhone(user.phone);
    }
  }, [user?.firstName, user?.lastName]);

  const handleUpdateUser = () => {
    dispatch(updateUserAction({ phoneNumber: phone, firstName: firstName, lastName: lastName }));
  };

  return (
    <ExpandableCard title={'User Information'} subtitle={''} focus={true}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleUpdateUser();
        }}
      >
        <CardContent>
          <Stack spacing={1} direction={'row'}>
            <TextField
              label={'First Name'}
              value={firstName}
              fullWidth
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField label={'Last Name'} value={lastName} fullWidth onChange={(e) => setLastName(e.target.value)} />
            {
              //address?
              //can email be updated here since it's connected to auth?
            }
          </Stack>
        </CardContent>
        <CardActions sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
          <LoadingButton
            type={'submit'}
            variant={'contained'}
            disabled={firstName === user?.firstName && lastName === user?.lastName}
          >
            Update Information
          </LoadingButton>
        </CardActions>
      </form>
    </ExpandableCard>
  );
};
