import React, { FC } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';

type SearchMenuIconProps = {
  onSelect: () => void;
  anchorRef: React.RefObject<HTMLDivElement>;
  badgeContent: string | undefined;
  secondaryBadgeContent: string | undefined;
};

export const SearchMenuIcon: FC<SearchMenuIconProps> = ({
  onSelect,
  anchorRef,
  badgeContent,
  secondaryBadgeContent,
}) => {
  return (
    <InputAdornment
      position={'end'}
      onClick={onSelect}
      ref={anchorRef}
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Box position="relative">
        <Badge badgeContent={badgeContent} color="secondary">
          <MenuIcon />
        </Badge>
        <Box position="absolute" top={0} right={0}>
          <Badge badgeContent={secondaryBadgeContent} color="success" />
        </Box>
      </Box>
    </InputAdornment>
  );
};
