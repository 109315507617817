import { useDispatch } from 'react-redux';
import { ReduxTimeConfiguration } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { isReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';

export const useTimeConfigurationChecks = () => {
  const dispatch = useDispatch();

  const checkTimeConfiguration = (config: ReduxTimeConfiguration, onPassesChecks: () => void) => {
    const start = config.startDateTime;
    const end = config.endDateTime;
    if (isReduxDate(end) || isReduxDate(start)) {
      dispatch(
        showToastMessageAction({
          message: 'Please select a valid start and end time',
          severity: 'warning',
        }),
      );
    } else if (end === start) {
      dispatch(showToastMessageAction({ message: 'Start and end times must be different', severity: 'warning' }));
    } else if (start && end && toStandardDate(start) > toStandardDate(end)) {
      dispatch(showToastMessageAction({ message: 'Start time must be before end time', severity: 'warning' }));
    } else onPassesChecks();
  };

  return { checkTimeConfiguration };
};
