import React, { FormEvent, useRef } from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { WizardStepper } from '../../../../_shared/steppers/WizardStepper';
import { ApplicantInfo } from '../applicantForms/applicantInfo/ApplicantInfo';
import { ReviewAndSubmit } from '../applicantForms/reviewAndSubmit/ReviewAndSubmit';
import {
  IContactInfo,
  IEmployment,
  IPostalAddress,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { EmploymentAndHistory } from '../applicantForms/employmentAndHistory/EmploymentAndHistory';
import { ReduxApplication } from '../../../redux/applicationTypes';

type Props = {
  editApplicantDetails: (e: FormEvent) => void;
  updateApplicantDetails: () => void;
  onBack: () => void;
  onNext: () => void;
  activeStep: number;
  editingApplicant: ReduxApplication;
  onUpdateApplicant: (key: keyof ReduxApplication, value?: string | boolean) => void;
  onUpdateAddress: (key: keyof IPostalAddress, value?: string) => void;
  onUpdateContactInfo: (key: keyof IContactInfo, value?: string) => void;
  onUpdateEmployment: (key: keyof IEmployment, value?: string | boolean) => void;
  onUpdateEmployerContact: (key: keyof IContactInfo, value?: string) => void;
  onUpdateEmployerAddress: (key: keyof IPostalAddress, value?: string) => void;
  onOpenAddHousingHistory: () => void;
};

export const WizardCosignerForm = ({
  editApplicantDetails,
  updateApplicantDetails,
  onBack,
  activeStep,
  editingApplicant,
  onUpdateApplicant,
  onUpdateAddress,
  onUpdateContactInfo,
  onUpdateEmployment,
  onUpdateEmployerContact,
  onUpdateEmployerAddress,
  onOpenAddHousingHistory,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const cosignerSteps = ['Applicant Info', 'Employment/Housing', 'Review & Submit'];

  return (
    <Container>
      <form ref={formRef} onSubmit={editApplicantDetails} autoComplete="off">
        <Stack spacing={2}>
          <WizardStepper
            steps={cosignerSteps}
            onBack={onBack}
            onNext={editApplicantDetails}
            activeStep={activeStep}
            onValidate={() => formRef.current?.reportValidity() ?? true}
          />
          {
            {
              0: (
                <ApplicantInfo
                  editingApplicant={editingApplicant}
                  updateApplicantDetails={updateApplicantDetails}
                  onUpdateApplicant={onUpdateApplicant}
                  onUpdateAddress={onUpdateAddress}
                  onUpdateContactInfo={onUpdateContactInfo}
                  editingInWizard={true}
                />
              ),
              1: (
                <EmploymentAndHistory
                  applicant={editingApplicant}
                  updateApplicantDetails={updateApplicantDetails}
                  onUpdateEmployment={onUpdateEmployment}
                  onUpdateEmployerContact={onUpdateEmployerContact}
                  onUpdateEmployerAddress={onUpdateEmployerAddress}
                  onUpdateApplicant={onUpdateApplicant}
                  editingInWizard={true}
                  onOpenAddHousingHistory={onOpenAddHousingHistory}
                />
              ),
              2: <ReviewAndSubmit applicant={editingApplicant} />,
            }[activeStep]
          }
        </Stack>
      </form>
    </Container>
  );
};
