import React, { FC, useEffect, useState } from 'react';
import { Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { IHistoricalAccountBalance } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ReduxDate, toReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { useTheme } from '@mui/material';

export type AccountBalancesChartProps = {
  balances: IHistoricalAccountBalance[];
  directOnly?: boolean;
};

type DataPoint = {
  date: ReduxDate;
  pendingBalance: number;
  postedBalance: number;
};

export const AccountBalancesChart: FC<AccountBalancesChartProps> = ({ directOnly, balances }) => {
  const [data, setData] = useState<DataPoint[]>([]);
  const theme = useTheme();
  useEffect(() => {
    const dataPoints: DataPoint[] = balances
      .filter((b) => b.date)
      .map((b) => ({
        date: toReduxDate(b.date!),
        pendingBalance: (directOnly ? b.pendingDirectBalance : b.pendingTotalBalance) ?? 0,
        postedBalance: (directOnly ? b.postedTotalBalance : b.postedTotalBalance) ?? 0,
        totalBalance: directOnly
          ? (b.pendingDirectBalance ?? 0) + (b.postedDirectBalance ?? 0)
          : (b.pendingTotalBalance ?? 0) + (b.postedTotalBalance ?? 0),
      }));
    setData(dataPoints);
  }, [balances]);

  const currencyFormatter = (value: unknown) => {
    let currencyString: number | undefined;
    if (typeof value === 'string') currencyString = parseFloat(value);
    if (typeof value === 'number') currencyString = value;
    const currencyValue =
      typeof currencyString === 'number' &&
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(currencyString);
    return (currencyValue || undefined) ?? value?.toString() ?? '';
  };
  return (
    <AreaChart data={data} width={800} height={400} margin={{ top: 4, right: 4, bottom: 4, left: 4 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis tickFormatter={currencyFormatter} />
      <Tooltip formatter={currencyFormatter} />
      <Area dataKey="pendingBalance" name="Pending" stackId="1" fill={theme.palette.primary.light} />
      <Area dataKey="postedBalance" name="Posted" stackId="1" fill={theme.palette.secondary.light} />
      <Area dataKey="totalBalance" name="Total" stackId="2" fill={theme.palette.primary.dark} />
    </AreaChart>
  );
};
