import React, { FC, FormEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { TaskCategoryAutocomplete } from '../../../../../../_shared/tasks/components/TaskCategoryAutocomplete';
import { useDispatch } from 'react-redux';
import { createTaskAutomationAction, useSopTasks } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import { FullForm } from '../../../../../../_shared/styledComponents/FullForm';
import { SingleDocument } from '../../../../../../_shared/documents/components/SingleDocument';
import { AssigneeAutocomplete, UserOption } from '../../../../../../_shared/tasks/components/AssigneeAutocomplete';
import {
  AssetType,
  AssociationType,
  IAddTaskConfigurationHandlerRequest,
  ITaskCategory,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { getUsersForAssetAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { NewChecklist } from '../../../../../../_shared/checklists/components/NewChecklist';

type AddNewAutomationProps = {
  onHideAddAutomation: () => void;
  isListInDialog: boolean;
  onCloseMainDialog: () => void;
  propertyId: string;
  page: number;
  pageSize: number;
  associatedId?: string;
  associationType?: AssociationType;
};

export const AddNewSopTask: FC<AddNewAutomationProps> = ({
  onHideAddAutomation,
  isListInDialog,
  onCloseMainDialog,
  propertyId,
  page,
  pageSize,
  associatedId,
  associationType,
}) => {
  const { sopTasks } = useSopTasks();
  const [newAutomation, setNewAutomation] = useState<IAddTaskConfigurationHandlerRequest>({ checklistItems: [] });
  const [assignee, setAssignee] = useState<UserOption | null>(null);
  const [file, setFile] = useState<File>();
  const dispatch = useDispatch();
  const [addChecklist, setAddChecklist] = useState(false);
  const [checklist, setChecklist] = useState<string[] | undefined>();

  useEffect(() => {
    dispatch(
      getUsersForAssetAction({
        assetId: propertyId,
        associationType: AssetType.RentalProperty,
      }),
    );
  }, [propertyId]);

  useEffect(() => {
    setNewAutomation({
      ...newAutomation,
      defaultAssigneeId: assignee?.id ?? undefined,
    });
  }, [assignee]);

  const handleCategorySelection = (value: ITaskCategory | null) => {
    setNewAutomation({ ...newAutomation, category: value ?? undefined });
  };

  const handleDeleteItem = (index: number) => {
    if (!checklist) return;
    const checklistArray = [...checklist];
    checklistArray.splice(index, 1);
    setChecklist(checklistArray);
  };

  const handleAddItem = (value: string) => {
    if (!checklist) {
      setChecklist([value]);
    } else {
      const checklistArray = [...checklist];
      checklistArray.push(value);
      setChecklist(checklistArray);
    }
  };

  const addNewAutomation = (e: FormEvent) => {
    e.preventDefault();
    dispatch(
      createTaskAutomationAction({
        id: propertyId,
        body: {
          ...newAutomation,
          checklistItems: checklist,
          association:
            associatedId && associationType
              ? { associatedId: associatedId, associationType: associationType }
              : undefined,
        },
        document: file,
        page: page,
        pageSize: pageSize,
      }),
    );
    isListInDialog && onHideAddAutomation();
  };

  return (
    <FullForm onSubmit={addNewAutomation}>
      <DialogContent>
        <Grid marginTop={1} container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoFocus
              fullWidth
              required
              sx={{ margin: 0 }}
              variant={'outlined'}
              label={'Name'}
              onChange={(e) => setNewAutomation({ ...newAutomation, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TaskCategoryAutocomplete onChange={handleCategorySelection} value={newAutomation.category} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              multiline
              minRows={2}
              variant={'outlined'}
              label={'Description'}
              onChange={(e) => setNewAutomation({ ...newAutomation, description: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AssigneeAutocomplete value={assignee} onValueChange={setAssignee} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SingleDocument
              sx={{ width: '100%' }}
              value={file && { name: file?.name }}
              onUpload={setFile}
              onDelete={() => setFile(undefined)}
            />
          </Grid>
          <Grid item xs={12}>
            {addChecklist ? (
              <NewChecklist checklist={checklist} onAddItem={handleAddItem} onDeleteItem={handleDeleteItem} />
            ) : (
              <Button onClick={() => setAddChecklist(true)}>Add Checklist</Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isListInDialog ? (
          <Button onClick={onHideAddAutomation}>Back to Automations List</Button>
        ) : (
          <Button onClick={onCloseMainDialog}>Close</Button>
        )}
        <LoadingButton variant={'contained'} type={'submit'} loading={sopTasks.submitting}>
          Add SOP Task
        </LoadingButton>
      </DialogActions>
    </FullForm>
  );
};
