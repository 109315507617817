import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { CustomizeFilters } from './CustomizeFilters';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useReports } from '../../../../redux/reportSlice';
import { convertVariableToDisplayName } from './useFormatReportData';
import { CustomizeGroupings } from './CustomizeGroupings';

export const CustomizeReport: FC = () => {
  const { selectedReport, selectedGrouping } = useReports();
  return (
    <Stack spacing={2}>
      <Typography variant={'h4'}>Report: {convertVariableToDisplayName(selectedReport.value?.name)}</Typography>
      <Stack border={'.1rem solid gray'} borderRadius={1} display={'flex'} flexGrow={1} minHeight={350}>
        <Box p={1}>
          <Typography variant={'h5'}>Filters</Typography>
        </Box>
        <Divider />
        <CustomizeFilters />

        <Box p={1}>
          <Typography variant={'h5'}>Grouping</Typography>
        </Box>
        <Divider />
        {selectedGrouping && <CustomizeGroupings />}
      </Stack>
    </Stack>
  );
};
