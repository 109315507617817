import { useDispatch } from 'react-redux';
import { setBackUrlAction, useGlobal } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssetTypeFromPathname } from './getAssetTypeFromPathname';

export const useRouting = () => {
  const dispatch = useDispatch();
  const { backUrl } = useGlobal();
  const navigate = useNavigate();
  const { id, outerTab, innerTab } = useParams();

  const handleNavigateFromAssociationClose = () => {
    const assetType = getAssetTypeFromPathname();
    const navigateUrl = `/assets/${assetType}/${id}/${outerTab}/${innerTab}`;

    const lastSlashIndex = backUrl?.lastIndexOf('/');
    const backUrlWithoutLastTab = backUrl?.substring(0, lastSlashIndex);

    const lastSlashCurrentUrlIndex = location.pathname.lastIndexOf('/');
    const currentUrlWithoutLastTab = location.pathname.substring(0, lastSlashCurrentUrlIndex);

    if (backUrl && backUrlWithoutLastTab !== currentUrlWithoutLastTab) {
      navigate(backUrl);
    } else {
      navigate(navigateUrl);
    }

    backUrl && dispatch(setBackUrlAction(undefined));
  };

  return { handleNavigateFromAssociationClose };
};
