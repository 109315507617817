import { AssetType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ICamAssociation } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

interface AuthorizeNetIFrame {
  onReceiveCommunication: (str: string) => void;
}

declare global {
  interface Window {
    AuthorizeNetIFrame: AuthorizeNetIFrame;
  }
}

export const parseQueryString = (queryString: string) => {
  const arr = queryString.split('&');
  return arr.reduce(
    (acc, curr) => {
      const [key, value] = curr.split('=');
      acc[key] = value;
      return acc;
    },
    {} as { [key: string]: string },
  );
};

export const convertAssetTypeToAssetNodeType = (assetType?: AssetType): string | undefined => {
  if (!assetType) return undefined;
  switch (assetType) {
    case AssetType.RentalProperty:
      return 'property';
    case AssetType.Building:
      return 'building';
    case AssetType.BuildingUnit:
      return 'unit';
    case AssetType.Room:
      return 'room';
    default:
      return undefined;
  }
};

export const createDocumentLink = (propertyId?: string, association?: ICamAssociation, documentId?: string) => {
  if (!propertyId || !association || !documentId) return '';
  const { associationType, associatedId, associationChildType, associationChildNumber } = association;

  const rootUrl = `${process.env.REACT_APP_API_URL}/properties/${propertyId}/${associationType}/${associatedId}/documents/${documentId}`;
  const queryString =
    associationChildType && associationChildNumber
      ? `?childAssociationType=${associationChildType}&childAssociationId=${associationChildNumber}`
      : '';

  return `${rootUrl}${queryString}`;
};
