import React, { FC, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AssetTree } from './assetTree/AssetTree';
import { useOutlet, useParams } from 'react-router-dom';
import { getAllAssetsAction, useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import { SendEmailDialog } from '../../_shared/communications/components/emailDialogs/SendEmailDialog';
import { AssetParams } from '../../../AppRouter';
import { useGlobal } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { AddTaskFromAssociationDialog } from '../../_shared/details/components/dialogs/AddTaskFromAssociationDialog';
import { SearchAssociationsDialog } from '../../_shared/communications/components/emailDialogs/SearchAssociationsDialog';
import { AddTenantDialog } from '../../_shared/leases/components/leaseDialogs/AddTenantDialog';
import Connector from '@monkeyjump-labs/cam-fe-shared/dist/services/signalrConnection';

export const Assets: FC = () => {
  const assets = useAssets();
  const dispatch = useDispatch();
  const outlet = useOutlet();
  const { id } = useParams<AssetParams>();
  const { propertyId, buildingId, unitId } = useAssets((assets) => assets.selectedContext);
  const { hideAssetTree } = useGlobal();

  useEffect(() => {
    dispatch(getAllAssetsAction());
  }, []);

  useEffect(() => {
    if (!propertyId) return;

    const connector = Connector.getExistingInstance();
    connector.subscribeToPropertyGroup(propertyId);

    return () => {
      connector.unsubscribeToPropertyGroup(propertyId);
    };
  }, [propertyId]);

  return (
    <>
      <Grid container sx={{ minHeight: 'calc(100vh - 104px)' }}>
        <Grid
          item
          lg={2}
          md={3}
          sm={12}
          sx={{
            display: hideAssetTree ? 'none' : 'block',
          }}
        >
          <AssetTree
            loading={assets.allAssets.loading}
            value={assets.allAssets.value ?? []}
            selected={id ?? ''}
            selectedPropertyId={propertyId}
            selectedBuildingId={buildingId}
            selectedUnitId={unitId}
          />
        </Grid>
        <Grid
          container
          item
          flexGrow={1}
          lg={hideAssetTree ? 12 : 10}
          md={hideAssetTree ? 12 : 9}
          sm={12}
          style={{
            overflow: 'hidden',
            transition: 'flex-basis 0.3s ease-in-out',
          }}
        >
          {outlet ?? (
            <Box margin={8}>
              <Typography variant="h4" color="secondary">
                Please Select an Asset
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <SendEmailDialog />
      <AddTaskFromAssociationDialog />
      <SearchAssociationsDialog />
      <AddTenantDialog />
    </>
  );
};
