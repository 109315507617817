import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FC, PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { TabProps } from '@mui/material';
import Tab from '@mui/material/Tab';

export type FeatureProps = PropsWithChildren<{
  flag: string;
}>;

export const Feature: FC<FeatureProps> = ({ flag, children }) => {
  const normalizedFlag = flag.replace(/\./g, '').toLowerCase();
  const flags = useFlags();
  const entry = Object.entries(flags).filter(([k]) => k.toLowerCase() == normalizedFlag);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, flagValue] = entry.length > 0 ? entry[0] : [];
  if (flagValue && children) {
    return <>{children}</>;
  }
  return null;
};

const BetaBox = styled(Box)({
  position: 'relative',
  border: '1px solid orange',
  padding: '-1px',
});

export const Beta: FC<FeatureProps> = ({ flag, children }) => {
  return (
    <Feature flag={flag}>
      <Tooltip title="This is beta content">
        <BetaBox>{children}</BetaBox>
      </Tooltip>
    </Feature>
  );
};

export const BetaTab: FC<{ flag: string; showBorder?: boolean } & TabProps> = ({
  flag,
  showBorder,
  label,
  ...others
}) => {
  const normalizedFlag = flag.replace(/\./g, '').toLowerCase();
  const flags = useFlags();
  const [, flagValue] = Object.entries(flags).filter(([k]) => k.toLowerCase() == normalizedFlag)[0];
  if (flagValue) {
    return (
      <Tab
        sx={showBorder ? { border: '1px solid orange', padding: '-1px' } : {}}
        label={
          <Tooltip title={'This is beta content'}>
            <>{label}</>
          </Tooltip>
        }
        {...others}
      />
    );
  }
  return null;
};

export const FeatureTab: FC<{ flag: string } & TabProps> = ({ flag, ...others }) => {
  const normalizedFlag = flag.replace(/\./g, '').toLowerCase();
  const flags = useFlags();
  const [, flagValue] = Object.entries(flags).filter(([k]) => k.toLowerCase() == normalizedFlag)[0];
  if (flagValue) {
    return <Tab {...others} />;
  }
  return null;
};
