import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { TenantTable } from './TenantTable';
import Stack from '@mui/material/Stack';
import { LeaseDispatchType, ReduxTenant } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { StyledInfoBox } from '../styledComponents/StyledInfoBox';
import { useDispatch } from 'react-redux';
import { cancelInviteTenantAction, getTenantInvitationStatusAction, useLeases } from '../leases/redux/leasesSlice';
import { InviteTenantDialog } from './InviteTenantDialog';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';

type TenantInfoProps = {
  tenants: ReduxTenant[];
  leaseId: string;
  onUpdateTenants: (tenants: ReduxTenant[]) => void;
  leasesType: LeaseDispatchType;
  assetId: string;
};

export const TenantInfo: FC<TenantInfoProps> = ({ tenants, leaseId, onUpdateTenants, leasesType, assetId }) => {
  const dispatch = useDispatch();
  const { tenantInvites } = useLeases();
  const [tenant, setTenant] = useState<ReduxTenant | undefined>();

  const handleOpenInviteTenant = (tenant: ReduxTenant | undefined) => {
    setTenant(tenant);
  };

  const handleCloseInviteTenant = () => {
    setTenant(undefined);
  };

  useEffect(() => {
    dispatch(getTenantInvitationStatusAction(leaseId));
  }, [leaseId]);

  const handleCancelInvite = (tenantId: string) => {
    const invitation = tenantInvites.value?.find((invite) => invite.tenantId === tenantId);
    if (!invitation?.invitationId) {
      dispatch(
        showToastMessageAction({
          message: 'could not cancel invite',
          severity: 'error',
        }),
      );
    } else
      dispatch(
        cancelInviteTenantAction({
          leaseId: leaseId,
          invitationId: invitation.invitationId,
          leaseType: leasesType,
          assetId: assetId,
        }),
      );
  };

  return (
    <StyledInfoBox label={'Tenants'}>
      <Stack spacing={2}>
        <Box sx={{ minHeight: 350 }}>
          <TenantTable
            value={tenants}
            leaseId={leaseId}
            leaseType={leasesType}
            onUpdateTenants={onUpdateTenants}
            onOpenInviteTenant={handleOpenInviteTenant}
            onCancelInvite={handleCancelInvite}
            assetId={assetId}
          />
        </Box>
      </Stack>
      <InviteTenantDialog
        tenant={tenant}
        open={tenant !== undefined}
        onClose={handleCloseInviteTenant}
        leaseId={leaseId}
        assetId={assetId}
        leasesType={leasesType}
      />
    </StyledInfoBox>
  );
};
