import React from 'react';
import Stack from '@mui/material/Stack';
import { EmploymentInfo } from './EmploymentInfo';
import { HousingHistory } from './HousingHistory';
import {
  ApplicationType,
  IContactInfo,
  IEmployment,
  IPostalAddress,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ReduxApplication } from '../../../../redux/applicationTypes';

type EmploymentAndHistoryProps = {
  applicant: ReduxApplication;
  onUpdateEmployment: (key: keyof IEmployment, value: string | boolean) => void;
  onUpdateEmployerContact: (key: keyof IContactInfo, value: string) => void;
  onUpdateEmployerAddress: (key: keyof IPostalAddress, value: string) => void;
  onUpdateApplicant: (key: keyof ReduxApplication, value: string) => void;
  updateApplicantDetails: () => void;
  editingInWizard?: boolean;
  onOpenAddHousingHistory?: () => void;
};

export const EmploymentAndHistory = ({
  applicant,
  onUpdateEmployment,
  onUpdateEmployerContact,
  onUpdateEmployerAddress,
  updateApplicantDetails,
  onUpdateApplicant,
  onOpenAddHousingHistory,
}: EmploymentAndHistoryProps) => {
  return (
    <Stack spacing={2}>
      <EmploymentInfo
        applicant={applicant}
        onUpdateEmployment={onUpdateEmployment}
        onUpdateEmployerContact={onUpdateEmployerContact}
        onUpdateEmployerAddress={onUpdateEmployerAddress}
        onUpdateApplicant={onUpdateApplicant}
        editingInWizard={true}
        updateApplicantDetails={updateApplicantDetails}
      />
      {applicant.applicationType === ApplicationType.CoSigner ||
      applicant.applicationType === ApplicationType.Primary ? (
        <HousingHistory
          onOpen={onOpenAddHousingHistory}
          applicantHousingHistory={applicant.housingHistory}
          applicantId={applicant.id}
          applicationType={applicant.applicationType}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};
