import React, { FC, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import { setBackUrlAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import Stack from '@mui/material/Stack';
import { EmailTable } from './emailTable/EmailTable';
import {
  setDetailedEmailInfoAction,
  setOpenEmailDialogAction,
  setSelectedAssociationAction,
  setSelectedEmailThreadAction,
  useCommunication,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/communications/communicationSlice';
import {
  EmailAssociationInfo,
  EmailType,
  ReduxEmailThread,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/communicationTypes';
import { useDispatch } from 'react-redux';
import { assignReplyTypes, stripThreadId } from '@monkeyjump-labs/cam-fe-shared/dist/utils/emailUtils';
import { AssetType, AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  createTaskFromEmailUsingAIAction,
  setNewTaskFromAssociationValuesAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { setAssociationSearchParentAction } from '../../search/redux/searchSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetParams } from '../../../../AppRouter';
import { getUsersForAssetAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { getAssetTypeFromPathname } from '../../utils/getAssetTypeFromPathname';

type EmailsProps = {
  associationInfo?: EmailAssociationInfo;
};

export const Emails: FC<EmailsProps> = ({ associationInfo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyEmails, associationEmails } = useCommunication();
  const { id, outerTab, innerTab } = useParams<AssetParams>();

  useEffect(() => {
    id && dispatch(getUsersForAssetAction({ assetId: id, associationType: AssetType.RentalProperty }));
  }, []);

  const handleOpenAddAssociationDialog = (id: string) => {
    dispatch(
      setAssociationSearchParentAction({
        id: id,
        type: AssociationType.EmailThread,
      }),
    );
  };

  const handleOpenReply = (emailThread: ReduxEmailThread) => {
    dispatch(setSelectedEmailThreadAction(emailThread));
    const replyEmailAddresses = assignReplyTypes(emailThread, 'recipient');
    const ccEmailAddresses = assignReplyTypes(emailThread, 'cc');
    dispatch(setDetailedEmailInfoAction({ recipients: replyEmailAddresses, cc: ccEmailAddresses }));
    dispatch(setOpenEmailDialogAction(EmailType.Reply));
  };

  const handleOpenEmailDetail = (emailThread: ReduxEmailThread) => {
    setBackUrlAction(location.pathname);
    navigate(`/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/emailThread/${emailThread.id}`);
  };

  const handleOpenSendEmailDialog = () => {
    if (associationInfo?.associatedId && associationInfo?.associationType) {
      dispatch(
        setSelectedAssociationAction({
          associatedId: associationInfo?.associatedId,
          associationType: associationInfo?.associationType,
          childNumber: associationInfo.childNumber,
          associationChildType: associationInfo.childType,
        }),
      );
      dispatch(setOpenEmailDialogAction(EmailType.SelectedLeaseOrApplicant));
    } else {
      dispatch(setOpenEmailDialogAction(EmailType.Uncontrolled));
    }
    const emails: EmailAssociationInfo[] =
      associationInfo?.emailAddresses?.map((e) => ({
        emailAddresses: [e],
        label: e,
        associationType: associationInfo?.associationType,
        associatedId: associationInfo?.associatedId,
        childNumber: associationInfo?.childNumber,
        childType: associationInfo?.childType,
      })) ?? [];
    dispatch(setDetailedEmailInfoAction({ recipients: emails }));
  };

  const handleOpenAddTask = (id: string, fromAI?: boolean) => {
    const thread = propertyEmails.value?.find((e) => e.id === id) ?? associationEmails.value?.find((e) => e.id === id);
    if (thread) {
      if (fromAI) {
        dispatch(createTaskFromEmailUsingAIAction({ email: thread.emails[0], emailThreadId: id }));
      } else
        dispatch(
          setNewTaskFromAssociationValuesAction({
            id: id,
            valueAssociationType: AssociationType.EmailThread,
            name: thread.emails[0].subject ? stripThreadId(thread.emails[0].subject) : '',
            description: thread.emails[0].body ?? '',
          }),
        );
    }
  };

  return (
    <StyledInfoBox label={'Email'}>
      <Stack>
        <Box display={'flex'} justifyContent={'right'}>
          <Button variant={'contained'} onClick={handleOpenSendEmailDialog}>
            Send Email
          </Button>
        </Box>
      </Stack>
      <EmailTable
        associationType={associationInfo?.associationType}
        associatedId={associationInfo?.associatedId}
        childType={associationInfo?.childType}
        childNumber={associationInfo?.childNumber}
        onOpenEmailDetail={handleOpenEmailDetail}
        onOpenAddAssociation={handleOpenAddAssociationDialog}
        onReply={handleOpenReply}
        onOpenAddTask={handleOpenAddTask}
      />
    </StyledInfoBox>
  );
};
