import {
  initInteractable,
  initLoadable,
  initPageableCollection,
  Interactable,
  Loadable,
  PageableCollection,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { ReduxEntry, ReduxStatement } from './statementTypes';
import {
  AssetType,
  IAccount,
  IAddIncomeToAssetHandlerRequest,
  IPaymentChipDto,
  IStatementEntryDto,
  IUpdateIncomeHandlerRequest,
  JournalEntryStatus,
  StatementPeriodType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { Period, ReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

export interface StatementState {
  selectedStatement: Interactable<ReduxStatement>;
  selectedStatementLine: Loadable<{ selectedLineId: string; journalEntries?: ReduxEntry[] }>;
  statementPeriods: Loadable<Period[]>;
  popupStatement: Interactable<ReduxStatement>;
  popupPeriods: Loadable<Period[]>;
  incomeStatement: PageableCollection<ReduxEntry>;
}

const initialState: StatementState = {
  selectedStatement: initInteractable<ReduxStatement>(),
  selectedStatementLine: initLoadable<{ selectedLineId: string; journalEntries?: ReduxEntry[] }>(),
  popupStatement: initInteractable<ReduxStatement>(),
  popupPeriods: initLoadable([]),
  statementPeriods: initLoadable([]),
  incomeStatement: initPageableCollection<ReduxEntry>(),
};

function updateStatementEntriesOnArchivingAction(
  statementEntries: ReduxEntry[],
  payload: {
    journalEntryId: string;
    updatedJournalEntryStatus: JournalEntryStatus;
    posted?: number;
    pending?: number;
  },
): ReduxEntry[] {
  const entryIndex = statementEntries.findIndex((x) => x.journalEntryId === payload.journalEntryId);
  if (entryIndex === -1 || typeof entryIndex !== 'number') return statementEntries; // Exit if the entry is not found
  if (!statementEntries.entries) return statementEntries;
  const archiving = payload.updatedJournalEntryStatus === JournalEntryStatus.Archived;
  const archivingValue =
    (archiving ? -1 : 1) * (statementEntries[entryIndex]?.posted ?? statementEntries[entryIndex]?.pending ?? 0);
  const updatingEntry = statementEntries[entryIndex];

  return statementEntries.map((entry, index) => {
    if (
      entry.journalEntryId === payload.journalEntryId ||
      (entry.statusGroupId && entry.statusGroupId == updatingEntry?.statusGroupId) // Also update entries in the group
    ) {
      const postedValue: Pick<ReduxEntry, 'posted' | 'pending'> =
        payload.updatedJournalEntryStatus === JournalEntryStatus.Archived
          ? {}
          : payload.updatedJournalEntryStatus === JournalEntryStatus.Pending
            ? { pending: entry.pending }
            : { posted: entry.posted };

      // Calculate the new total using the closest total before this entry
      let closestTotalIndex = entryIndex - 1;
      while (closestTotalIndex >= 0 && statementEntries[closestTotalIndex].total === undefined) {
        closestTotalIndex--;
      }
      const baseTotal = closestTotalIndex >= 0 ? statementEntries[closestTotalIndex].total : 0;
      const total = (baseTotal ?? 0) + (postedValue.posted ?? postedValue.pending ?? 0);

      return {
        ...entry,
        ...postedValue,
        total,
        status: payload.updatedJournalEntryStatus,
        previousStatus: entry.status,
      };
    } else if (index > entryIndex) {
      return { ...entry, total: entry.total ? entry.total + archivingValue : undefined };
    }
    return entry;
  });
}

/* eslint-disable @typescript-eslint/no-unused-vars */
function mapPostEntries(
  entries: ReduxEntry[],
  journalEntryId: string,
  datePosted: ReduxDate | undefined,
  isPosting?: boolean,
): ReduxEntry[] {
  const foundEntry = entries.find((x) => x.journalEntryId === journalEntryId);
  if (!foundEntry) return entries;
  return entries.map((entry) => {
    if (
      entry.journalEntryId === journalEntryId ||
      (entry.statusGroupId && entry.statusGroupId == foundEntry?.statusGroupId)
    ) {
      return {
        ...entry,
        status: isPosting ? JournalEntryStatus.Posted : JournalEntryStatus.Pending,
        date: isPosting ? datePosted : undefined,
        posted: isPosting ? entry.pending : undefined,
        pending: isPosting ? undefined : entry.posted,
      };
    }
    return entry;
  });
}

// noinspection JSUnusedLocalSymbols
export const statementSlice = createSlice({
  name: 'statements',
  initialState,
  reducers: {
    getStatementByLeaseIdAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        startDate: ReduxDate;
        statementPeriodType: StatementPeriodType;
        endDate?: ReduxDate;
        silent?: boolean;
        isDepositStatement?: boolean;
      }>,
    ) => {
      if (action.payload.silent) return;

      state.selectedStatement.loading = true;
      state.selectedStatement.loaded = false;
    },
    getStatementByLeaseIdSuccessAction: (
      state,
      action: PayloadAction<{ statement: ReduxStatement; silent?: boolean }>,
    ) => {
      if (!action.payload.silent) {
        state.selectedStatement.loading = false;
        state.selectedStatement.loaded = true;
      }
      state.selectedStatement.value = action.payload.statement;
    },
    getStatementByLeaseIdFailAction: (state) => {
      state.selectedStatement.loading = false;
      state.selectedStatement.loaded = false;
      state.selectedStatement.value = undefined;
    },
    getStatementPeriodsByLeaseIdAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        statementPeriodType: StatementPeriodType;
      }>,
    ) => {
      state.statementPeriods.loading = true;
      state.statementPeriods.loaded = false;
    },
    getStatementPeriodsByLeaseIdSuccessAction: (state, action: PayloadAction<Period[]>) => {
      state.statementPeriods.loading = false;
      state.statementPeriods.loaded = true;
      state.statementPeriods.value = action.payload;
    },
    getStatementPeriodsByLeaseIdFailAction: (state) => {
      state.statementPeriods.loading = false;
      state.statementPeriods.loaded = false;
      state.statementPeriods.value = undefined;
    },
    getStatementPeriodsForPropertyAccountAction: (
      state,
      action: PayloadAction<{
        propertyId: string;
        accountId: string;
        periodType?: StatementPeriodType;
        isPopup?: boolean;
      }>,
    ) => {
      if (action.payload.isPopup) {
        state.popupPeriods.loading = true;
        state.popupPeriods.loaded = false;
      } else {
        state.statementPeriods.loading = true;
        state.statementPeriods.loaded = false;
      }
    },
    getStatementPeriodsForPropertyAccountSuccessAction: (
      state,
      action: PayloadAction<{ periods: Period[]; isPopup?: boolean }>,
    ) => {
      if (action.payload.isPopup) {
        state.popupPeriods.loading = false;
        state.popupPeriods.loaded = true;
        state.popupPeriods.value = action.payload.periods;
      } else {
        state.statementPeriods.loading = false;
        state.statementPeriods.loaded = true;
        state.statementPeriods.value = action.payload.periods;
      }
    },
    getStatementPeriodsForPropertyAccountFailAction: (state, action: PayloadAction<{ isPopup?: boolean }>) => {
      if (action.payload.isPopup) {
        state.popupPeriods.loading = false;
        state.popupPeriods.loaded = false;
        state.popupPeriods.value = undefined;
      } else {
        state.statementPeriods.loading = false;
        state.statementPeriods.loaded = false;
        state.statementPeriods.value = undefined;
      }
    },
    getPropertyAccountStatementAction: (
      state,
      action: PayloadAction<{
        propertyId: string;
        accountId: string;
        periodType: StatementPeriodType;
        startDate: ReduxDate;
        endDate?: ReduxDate;
        includeArchived?: boolean;
        isPopup?: boolean;
      }>,
    ) => {
      if (action.payload.isPopup) {
        state.popupStatement.loading = true;
        state.popupStatement.loaded = false;
      } else {
        state.selectedStatement.loading = true;
        state.selectedStatement.loaded = false;
      }
    },
    getPropertyAccountStatementSuccessAction: (
      state,
      action: PayloadAction<{ statement: ReduxStatement; isPopup?: boolean }>,
    ) => {
      if (action.payload.isPopup) {
        state.popupStatement.loading = false;
        state.popupStatement.loaded = true;
        state.popupStatement.value = action.payload.statement;
      } else {
        state.selectedStatement.loading = false;
        state.selectedStatement.loaded = true;
        state.selectedStatement.value = action.payload.statement;
      }
    },
    getPropertyAccountStatementFailAction: (state, action: PayloadAction<{ isPopup?: boolean }>) => {
      if (action.payload.isPopup) {
        state.popupStatement.loading = false;
        state.popupStatement.loaded = false;
        state.popupStatement.value = undefined;
      } else {
        state.selectedStatement.loading = false;
        state.selectedStatement.loaded = false;
        state.selectedStatement.value = undefined;
      }
    },
    getTenantStatementByLeaseIdAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        periodStart: ReduxDate;
      }>,
    ) => {
      state.selectedStatement.loading = true;
      state.selectedStatement.loaded = false;
    },
    getTenantStatementByLeaseIdSuccessAction: (state, action: PayloadAction<ReduxStatement>) => {
      state.selectedStatement.loading = false;
      state.selectedStatement.loaded = true;
      state.selectedStatement.value = action.payload;
    },
    getTenantStatementByLeaseIdFailAction: (state) => {
      state.selectedStatement.loading = false;
      state.selectedStatement.loaded = false;
      state.selectedStatement.value = undefined;
    },
    getIncomeForAssetAction: (
      state,
      action: PayloadAction<{
        assetId: string;
        assetType: AssetType;
        includeRentalIncome: boolean;
        includeArchived: boolean;
        includeChildAssets: boolean;
        startDate: ReduxDate;
        endDate: ReduxDate;
        page: number;
        pageSize: number;
        orderBy: keyof IStatementEntryDto | undefined;
        ascending: boolean;
      }>,
    ) => {
      state.incomeStatement.loading = true;
      state.incomeStatement.loaded = false;
    },
    getIncomeForAssetSuccessAction: (
      state,
      action: PayloadAction<{
        income: ReduxEntry[];
        totalCount: number;
      }>,
    ) => {
      state.incomeStatement.loading = false;
      state.incomeStatement.loaded = true;
      state.incomeStatement.value = action.payload.income;

      state.incomeStatement.totalCount = action.payload.totalCount;
    },
    getIncomeForAssetFailAction: (state) => {
      state.incomeStatement.loading = false;
      state.incomeStatement.loaded = false;
    },
    getSelectedIncomeAction: (state, action: PayloadAction<string>) => {},
    setSelectedIncomeAction: (state, action: PayloadAction<ReduxEntry>) => {
      state.incomeStatement.submittingValue = action.payload;
    },
    clearSelectedIncomeAction: (state) => {
      state.incomeStatement.submittingValue = undefined;
      state.incomeStatement.submitting = false;
      state.incomeStatement.submitted = false;
    },
    getPlatformStatementAction: (state, action: PayloadAction<{ propertyId: string; monthYear: string }>) => {
      state.selectedStatement.loading = true;
      state.selectedStatement.loaded = false;
    },
    getPlatformStatementSuccessAction: (state, action: PayloadAction<ReduxStatement>) => {
      state.selectedStatement.loading = false;
      state.selectedStatement.loaded = true;
      state.selectedStatement.value = action.payload;
    },
    getPlatformStatementFailAction: (state) => {
      state.selectedStatement.loading = false;
      state.selectedStatement.loaded = false;
      state.selectedStatement.value = undefined;
    },

    getPlatformPeriodsAction: (state, action: PayloadAction<{ propertyId: string }>) => {
      state.statementPeriods.loading = true;
      state.statementPeriods.loaded = false;
    },
    getPlatformPeriodsSuccessAction: (state, action: PayloadAction<Period[]>) => {
      state.statementPeriods.loading = false;
      state.statementPeriods.loaded = true;
      state.statementPeriods.value = action.payload;
    },
    getPlatformPeriodsFailAction: (state) => {
      state.statementPeriods.loading = false;
      state.statementPeriods.loaded = false;
      state.statementPeriods.value = undefined;
    },
    markAsPostedAction: (
      state,
      action: PayloadAction<{
        propertyId: string;
        journalEntryId: string;
        datePosted: ReduxDate;
        isPopup?: boolean;
        isIncomeStatement?: boolean;
      }>,
    ) => {
      if (action.payload.isPopup) {
        state.popupStatement.submitting = true;
        state.popupStatement.submitted = false;
      } else if (action.payload.isIncomeStatement) {
        state.incomeStatement.submitting = true;
        state.incomeStatement.submitted = false;
      } else {
        state.selectedStatement.submitting = true;
        state.selectedStatement.submitted = false;
      }
    },
    journalEntryArchivingAction: (
      state,
      action: PayloadAction<{
        journalEntryId: string;
        isArchiving: boolean;
        isIncome?: boolean;
      }>,
    ) => {
      if (action.payload.isIncome) {
        state.incomeStatement.submitting = true;
        state.incomeStatement.submitted = false;
      } else {
        state.selectedStatement.submitting = true;
        state.selectedStatement.submitted = false;
      }
    },
    journalEntryArchivingSuccessAction: (
      state,
      action: PayloadAction<{
        journalEntryId: string;
        updatedJournalEntryStatus: JournalEntryStatus;
        isIncome?: boolean;
      }>,
    ) => {
      if (state.incomeStatement?.value) {
        state.incomeStatement.value = updateStatementEntriesOnArchivingAction(
          state.incomeStatement.value,
          action.payload,
        );
        state.incomeStatement.submitting = false;
        state.incomeStatement.submitted = true;
      }
      if (state.selectedStatement?.value?.entries) {
        state.selectedStatement.value.entries = updateStatementEntriesOnArchivingAction(
          state.selectedStatement.value.entries,
          action.payload,
        );
        state.selectedStatement.submitting = false;
        state.selectedStatement.submitted = true;
      }
      if (state.popupStatement.value?.entries) {
        state.popupStatement.value.entries = updateStatementEntriesOnArchivingAction(
          state.popupStatement.value.entries,
          action.payload,
        );
        state.popupStatement.submitted = true;
        state.popupStatement.submitting = false;
      }
    },
    journalEntryArchivingFailAction: (state, action: PayloadAction<{ isIncome?: boolean }>) => {
      if (action.payload.isIncome) {
        state.incomeStatement.submitting = false;
        state.incomeStatement.submitted = false;
      } else {
        state.selectedStatement.submitting = false;
        state.selectedStatement.submitted = false;
      }
    },
    markAsPostedSuccessAction: (
      state,
      action: PayloadAction<{
        journalEntryId: string;
        datePosted: ReduxDate;
        isPopup?: boolean;
        isIncomeStatement?: boolean;
      }>,
    ) => {
      if (action.payload.isPopup) {
        if (state.popupStatement?.value?.entries) {
          state.popupStatement.value.entries = mapPostEntries(
            state.popupStatement.value.entries,
            action.payload.journalEntryId,
            action.payload.datePosted,
            true,
          );
        }
        state.popupStatement.submitting = false;
        state.popupStatement.submitted = true;
      } else if (action.payload.isIncomeStatement) {
        if (state.incomeStatement?.value) {
          state.incomeStatement.value = mapPostEntries(
            state.incomeStatement.value,
            action.payload.journalEntryId,
            action.payload.datePosted,
            true,
          );
        }
        state.incomeStatement.submitting = false;
        state.incomeStatement.submitted = true;
      }
      if (state.selectedStatement?.value?.entries) {
        state.selectedStatement.value.entries = mapPostEntries(
          state.selectedStatement.value.entries,
          action.payload.journalEntryId,
          action.payload.datePosted,
          true,
        );
        state.selectedStatement.submitting = false;
        state.selectedStatement.submitted = true;
      }
    },
    markAsPendingAction: (
      state,
      action: PayloadAction<{
        propertyId: string;
        journalEntryId: string;
        isPopup?: boolean;
        isIncomeStatement?: boolean;
      }>,
    ) => {
      if (action.payload.isPopup) {
        state.popupStatement.submitting = true;
        state.popupStatement.submitted = false;
      } else if (action.payload.isIncomeStatement) {
        state.incomeStatement.submitting = true;
        state.incomeStatement.submitted = false;
      } else {
        state.selectedStatement.submitting = true;
        state.selectedStatement.submitted = false;
      }
    },
    markAsPendingSuccessAction: (
      state,
      action: PayloadAction<{ journalEntryId: string; isPopup?: boolean; isIncomeStatement?: boolean }>,
    ) => {
      if (action.payload.isPopup) {
        if (state.popupStatement?.value?.entries) {
          state.popupStatement.value.entries = mapPostEntries(
            state.popupStatement.value.entries,
            action.payload.journalEntryId,
            undefined,
            false,
          );
        }
        state.popupStatement.submitting = false;
        state.popupStatement.submitted = true;
      } else if (action.payload.isIncomeStatement) {
        if (state.incomeStatement?.value) {
          state.incomeStatement.value = mapPostEntries(
            state.incomeStatement.value,
            action.payload.journalEntryId,
            undefined,
            false,
          );
        }
        state.incomeStatement.submitting = false;
        state.incomeStatement.submitted = true;
      }
      if (state.selectedStatement?.value?.entries) {
        state.selectedStatement.value.entries = mapPostEntries(
          state.selectedStatement.value.entries,
          action.payload.journalEntryId,
          undefined,
          false,
        );
        state.selectedStatement.submitting = false;
        state.selectedStatement.submitted = true;
      }
    },
    markAsPendingOrPostedFailAction: (
      state,
      action: PayloadAction<{ isPopup?: boolean; isIncomeStatement?: boolean }>,
    ) => {
      if (action.payload.isPopup) {
        state.popupStatement.submitting = false;
        state.popupStatement.submitted = false;
      } else if (action.payload.isIncomeStatement) {
        state.incomeStatement.submitting = false;
        state.incomeStatement.submitted = false;
      } else {
        state.selectedStatement.submitting = false;
        state.selectedStatement.submitted = false;
      }
    },
    clearStatementAction: (state) => {
      state.selectedStatement = initInteractable<ReduxStatement>();
      state.statementPeriods = initLoadable([]);
    },
    updatePaymentChipSignalRAction: (state, action: PayloadAction<IPaymentChipDto>) => {
      if (state.selectedStatement?.value?.entries) {
        state.selectedStatement.value.entries = state.selectedStatement.value.entries?.map((x) => {
          if (x.groupId === action.payload.groupId) {
            return { ...x, externalTransactions: action.payload.externalTransactions };
          }

          return x;
        });
      }
    },
    paymentSubmittingAction: (state) => {
      state.selectedStatement.submitting = true;
      state.selectedStatement.submitted = false;
    },
    paymentSubmittedAction: (state) => {
      state.selectedStatement.submitting = false;
      state.selectedStatement.submitted = true;
    },
    paymentSubmitFailedAction: (state) => {
      state.selectedStatement.submitting = false;
      state.selectedStatement.submitted = false;
    },
    addIncomeToAssetAction: (
      state,
      action: PayloadAction<{ assetId: string; assetType: AssetType; body: IAddIncomeToAssetHandlerRequest }>,
    ) => {
      state.incomeStatement.submitting = true;
      state.incomeStatement.submitted = false;
    },
    addIncomeToAssetSuccessAction: (state) => {
      state.incomeStatement.submitting = false;
      state.incomeStatement.submitted = true;
    },
    addIncomeToAssetFailAction: (state) => {
      state.incomeStatement.submitting = false;
      state.incomeStatement.submitted = false;
    },
    updateAssetIncomeAction: (
      state,
      action: PayloadAction<{
        journalEntryId: string;
        body: IUpdateIncomeHandlerRequest;
      }>,
    ) => {
      state.incomeStatement.submitting = true;
      state.incomeStatement.submitted = false;
    },
    updateAssetIncomeSuccessAction: (state) => {
      state.incomeStatement.submitting = false;
      state.incomeStatement.submitted = true;
    },
    updateAssetIncomeFailAction: (state) => {
      state.incomeStatement.submitting = false;
      state.incomeStatement.submitted = false;
    },
    markIncomeDepositedAction: (
      state,
      action: PayloadAction<{
        journalEntryId: string;
        depositAccountId: string;
      }>,
    ) => {
      state.incomeStatement.submitting = true;
      state.incomeStatement.submitted = false;
    },
    removeIncomeFromAssetAction: (state, action: PayloadAction<{ journalEntryId: string }>) => {
      state.incomeStatement.submitting = true;
      state.incomeStatement.submitted = false;
    },
    removeIncomeFromAssetSuccessAction: (state) => {
      state.incomeStatement.submitting = false;
      state.incomeStatement.submitted = true;
    },
    removeIncomeFromAssetFailAction: (state) => {
      state.incomeStatement.submitting = false;
      state.incomeStatement.submitted = false;
    },
    bulkDepositJournalEntriesAction: (
      state,
      action: PayloadAction<{ propertyId: string; jeIds: string[]; dateDeposited: ReduxDate; isPopup?: boolean }>,
    ) => {
      if (action.payload.isPopup) {
        state.popupStatement.submitting = true;
        state.popupStatement.submitted = false;
      } else {
        state.selectedStatement.submitting = true;
        state.selectedStatement.submitted = false;
      }
    },
    bulkDepositJournalEntriesSuccessAction: (
      state,
      action: PayloadAction<{ jeIds: string[]; dateDeposited: ReduxDate; isPopup?: boolean }>,
    ) => {
      if (action.payload.isPopup) {
        if (state.popupStatement?.value?.entries) {
          state.popupStatement.value.entries = state.popupStatement.value.entries?.map((x) => {
            if (x.journalEntryId && action.payload.jeIds.includes(x.journalEntryId)) {
              return {
                ...x,
                status: JournalEntryStatus.Posted,
                datePosted: action.payload.dateDeposited,
                posted: x.pending,
                pending: undefined,
              };
            }
            return x;
          });
        }
        state.popupStatement.submitting = false;
        state.popupStatement.submitted = true;
      }
      if (state.selectedStatement?.value?.entries) {
        state.selectedStatement.value.entries = state.selectedStatement.value.entries?.map((x) => {
          if (x.journalEntryId && action.payload.jeIds.includes(x.journalEntryId)) {
            return {
              ...x,
              status: JournalEntryStatus.Posted,
              datePosted: action.payload.dateDeposited,
              posted: x.pending,
              pending: undefined,
            };
          }
          return x;
        });
      }
      state.selectedStatement.submitting = false;
      state.selectedStatement.submitted = true;
    },
    bulkDepositJournalEntriesFailAction: (state, action: PayloadAction<{ isPopup?: boolean }>) => {
      if (action.payload.isPopup) {
        state.popupStatement.submitting = false;
        state.popupStatement.submitted = false;
      } else {
        state.selectedStatement.submitting = false;
        state.selectedStatement.submitted = false;
      }
    },
    changeDepositAccountAction: (
      state,
      action: PayloadAction<{
        jeId: string;
        newDepositAccountId: string;
        isPopup?: boolean;
        isBankAccountStatement?: boolean;
        isIncomeStatement?: boolean;
      }>,
    ) => {
      if (action.payload.isPopup) {
        state.popupStatement.submitting = true;
        state.popupStatement.submitted = false;
      } else if (action.payload.isIncomeStatement) {
        state.incomeStatement.submitting = true;
        state.incomeStatement.submitted = false;
      } else {
        state.selectedStatement.submitting = true;
        state.selectedStatement.submitted = false;
      }
    },
    changeDepositAccountSuccessAction: (
      state,
      action: PayloadAction<{
        jeId: string;
        newDepositAcct: IAccount;
        isPopup?: boolean;
        isBankAccountStatement?: boolean;
        isIncomeStatement?: boolean;
      }>,
    ) => {
      if (action.payload.isPopup) {
        if (state.popupStatement?.value?.entries) {
          state.popupStatement.value.entries = state.popupStatement.value.entries?.filter(
            (x) => x.journalEntryId !== action.payload.jeId,
          );
        }
        //also update the parent statement if it has that line
        if (state.selectedStatement?.value?.entries) {
          state.selectedStatement.value.entries = state.selectedStatement.value.entries?.map((x) => {
            if (x.journalEntryId === action.payload.jeId) {
              return {
                ...x,
                depositAccount: action.payload.newDepositAcct,
              };
            }
            return x;
          });
        }
        state.popupStatement.submitting = false;
        state.popupStatement.submitted = true;
      } else if (action.payload.isIncomeStatement) {
        if (state.incomeStatement?.value) {
          state.incomeStatement.value = state.incomeStatement.value.map((x) => {
            if (x.journalEntryId === action.payload.jeId) {
              return {
                ...x,
                depositAccount: action.payload.newDepositAcct,
              };
            }
            return x;
          });
        }
        state.incomeStatement.submitting = false;
        state.incomeStatement.submitted = true;
      } else {
        if (state.selectedStatement?.value?.entries) {
          if (action.payload.isBankAccountStatement) {
            state.selectedStatement.value.entries = state.selectedStatement.value.entries?.filter(
              (x) => x.journalEntryId !== action.payload.jeId,
            );
          } else {
            state.selectedStatement.value.entries = state.selectedStatement.value.entries?.map((x) => {
              if (x.journalEntryId === action.payload.jeId) {
                return {
                  ...x,
                  depositAccount: action.payload.newDepositAcct,
                };
              }
              return x;
            });
          }
        }
        state.selectedStatement.submitting = false;
        state.selectedStatement.submitted = true;
      }
    },
    getStatementLineDetailByJournalEntryIdAction: (state, action: PayloadAction<string>) => {
      state.selectedStatementLine.loading = true;
      state.selectedStatementLine.loaded = false;
      if (state.selectedStatementLine.value) state.selectedStatementLine.value.selectedLineId = action.payload;
      else state.selectedStatementLine.value = { selectedLineId: action.payload };
    },
    getStatementLineDetailByJournalEntryIdSuccessAction: (state, action: PayloadAction<ReduxEntry[]>) => {
      state.selectedStatementLine.loading = false;
      state.selectedStatementLine.loaded = true;
      if (state.selectedStatementLine.value) state.selectedStatementLine.value.journalEntries = action.payload;
      else state.selectedStatementLine.value = { selectedLineId: '', journalEntries: action.payload };
    },
    getStatementLineDetailByJournalEntryIdFailAction: (state) => {
      state.selectedStatementLine.loading = false;
      state.selectedStatementLine.loaded = false;
      state.selectedStatementLine.value = undefined;
    },
    clearSelectedStatementLineAction: (state) => {
      state.selectedStatementLine.loading = false;
      state.selectedStatementLine.loaded = false;
      state.selectedStatementLine.value = undefined;
    },
  },
});

export const {
  getStatementByLeaseIdAction,
  getStatementByLeaseIdSuccessAction,
  getStatementByLeaseIdFailAction,
  getStatementPeriodsByLeaseIdAction,
  getStatementPeriodsByLeaseIdFailAction,
  getStatementPeriodsByLeaseIdSuccessAction,
  getPropertyAccountStatementAction,
  getPropertyAccountStatementFailAction,
  getPropertyAccountStatementSuccessAction,
  getStatementPeriodsForPropertyAccountAction,
  getStatementPeriodsForPropertyAccountFailAction,
  getStatementPeriodsForPropertyAccountSuccessAction,
  getTenantStatementByLeaseIdFailAction,
  getTenantStatementByLeaseIdSuccessAction,
  getTenantStatementByLeaseIdAction,
  getIncomeForAssetSuccessAction,
  getIncomeForAssetFailAction,
  getIncomeForAssetAction,
  getPlatformPeriodsAction,
  getPlatformPeriodsSuccessAction,
  getPlatformStatementAction,
  getPlatformStatementFailAction,
  getPlatformStatementSuccessAction,
  getPlatformPeriodsFailAction,
  markAsPostedAction,
  markAsPendingOrPostedFailAction,
  markAsPostedSuccessAction,
  journalEntryArchivingAction,
  journalEntryArchivingFailAction,
  journalEntryArchivingSuccessAction,
  markAsPendingSuccessAction,
  markAsPendingAction,
  clearStatementAction,
  updatePaymentChipSignalRAction,
  paymentSubmittedAction,
  paymentSubmitFailedAction,
  paymentSubmittingAction,
  addIncomeToAssetFailAction,
  addIncomeToAssetSuccessAction,
  addIncomeToAssetAction,
  removeIncomeFromAssetSuccessAction,
  removeIncomeFromAssetFailAction,
  updateAssetIncomeAction,
  updateAssetIncomeSuccessAction,
  updateAssetIncomeFailAction,
  removeIncomeFromAssetAction,
  bulkDepositJournalEntriesAction,
  bulkDepositJournalEntriesFailAction,
  bulkDepositJournalEntriesSuccessAction,
  changeDepositAccountAction,
  changeDepositAccountSuccessAction,
  getSelectedIncomeAction,
  clearSelectedIncomeAction,
  setSelectedIncomeAction,
  getStatementLineDetailByJournalEntryIdFailAction,
  getStatementLineDetailByJournalEntryIdSuccessAction,
  getStatementLineDetailByJournalEntryIdAction,
  clearSelectedStatementLineAction,
} = statementSlice.actions;

export const useStatement = () => useSelector((state: RootState) => state.statements);
