import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { openLeaseModalAction, setEditingLeaseAction } from '../../../redux/leasesSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import { LeaseDispatchType } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { LeaseDialogTab } from '../../../../tabs/TabTypeEnums';

type UnitNameCellProps = {
  leasesType: LeaseDispatchType;
} & GridRenderCellParams;

export const UnitNameCell = ({ leasesType, ...props }: UnitNameCellProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch(setEditingLeaseAction({ leaseId: props.row.id, leaseType: leasesType }));
    dispatch(openLeaseModalAction(true));
    navigate(`lease/${props.row.id}/${LeaseDialogTab.General}`);
  };

  const assetPath =
    (props.row.propertyName ? `${props.row.propertyName} /` : '') +
    (props.row.buildingName ? ` ${props.row.buildingName}` : '') +
    (props.row.unitName ? ` / ${props.row.unitName}` : '');

  return (
    <Link sx={{ cursor: 'pointer' }} color="inherit">
      <Tooltip title={assetPath ?? ''}>
        <Typography variant="body2" onClick={handleClick}>
          {props.row.propertyName}
        </Typography>
      </Tooltip>
      <Tooltip title={assetPath ?? ''}>
        <Typography variant="body2" onClick={handleClick}>
          {props.row.buildingName}
        </Typography>
      </Tooltip>
      <Tooltip title={assetPath ?? ''}>
        <Typography variant="body2" onClick={handleClick}>
          {props.row.unitName}
        </Typography>
      </Tooltip>
    </Link>
  );
};
