import React, { FC, useEffect, useState } from 'react';
import { BankingInfoList } from '../../../../../_shared/paymentProcessing/modernTreasury/BankingInfoList';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import {
  deletePropertyModernTreasuryAccountAction,
  setPropertyAccountAsDefaultAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { showErrorAction, showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { ReduxMtAccount } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import {
  BankAccountType,
  DefaultSelection,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { AddBankAccountButton } from './AddBankAccountButton';
import {
  clearPlaidLinkTokenAction,
  plaidRefreshSuccessfulAction,
  updatePlaidLinkTokenAction,
  useBankAccounts,
} from '../../../../../_shared/bankAccounts/redux/bankAccountsSlice';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';

type BankAccountsProps = {
  onViewStatement: (linkedAccountId?: string) => void;
};
export const BankAccounts: FC<BankAccountsProps> = ({ onViewStatement }) => {
  const { selectedContext } = useAssets();
  const { selectedProperty } = useProperty();
  const { plaidProcessorToken } = useBankAccounts();
  const dispatch = useDispatch();
  const [mtAccountId, setMtAccountId] = useState('');
  const [rows, setRows] = useState(
    selectedProperty.value?.rentalBillingInfo ? selectedProperty.value.rentalBillingInfo.map((i) => i.bankAccount) : [],
  );

  const [getToken, setToken] = useState('');
  const { plaidLinkTokenUpdate } = useBankAccounts();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useEffect(() => {
    setRows(
      selectedProperty.value?.rentalBillingInfo
        ? selectedProperty.value.rentalBillingInfo.map((i) => i.bankAccount)
        : [],
    );
  }, [selectedProperty.value?.rentalBillingInfo]);

  useEffect(() => {
    if (plaidLinkTokenUpdate.loaded && plaidLinkTokenUpdate.value) {
      setToken(plaidLinkTokenUpdate.value);
    }
  }, [plaidLinkTokenUpdate.loaded]);

  const handleDeleteAccount = (acct: ReduxMtAccount) => {
    if (acct.mtAccountId) {
      selectedContext.propertyId &&
        dispatch(
          deletePropertyModernTreasuryAccountAction({
            propertyId: selectedContext.propertyId,
            acctId: acct.mtAccountId,
          }),
        );
    } else {
      dispatch(showToastMessageAction({ message: 'Cannot delete property account', severity: 'error' }));
    }
  };

  const handlePlaidRefresh = (acct: ReduxMtAccount) => {
    selectedContext.propertyId &&
      acct.plaidAccessToken &&
      dispatch(
        updatePlaidLinkTokenAction({
          userOrPropertyId: selectedContext.propertyId,
          bankAccountType: BankAccountType.Business,
          accessToken: acct.plaidAccessToken,
        }),
      );
    setMtAccountId(acct.mtAccountId!);
  };

  const handleSetAsDefaultAccount = (acctId: string, defaultSelection: DefaultSelection) => {
    selectedContext.propertyId &&
      dispatch(
        setPropertyAccountAsDefaultAction({
          bankAccountId: acctId,
          propertyId: selectedContext.propertyId,
          defaultSelection: defaultSelection,
        }),
      );
  };

  const config: PlaidLinkOptions = {
    onSuccess: () => {
      dispatch(plaidRefreshSuccessfulAction({ mtAccountId: mtAccountId, propertyId: selectedContext.propertyId! }));
    },

    onExit: (err) => {
      if (err != null)
        dispatch(
          showErrorAction({
            error: err?.display_message,
            fallbackMessage: "Couldn't create Plaid processor token",
          }),
        );
      else dispatch(clearPlaidLinkTokenAction());
    },
    token: getToken,
  };

  const plaidLinkUpdate = usePlaidLink(config);

  useEffect(() => {
    if (plaidLinkUpdate.ready && getToken && getToken !== '') plaidLinkUpdate.open();
  }, [getToken, plaidLinkUpdate.open, plaidLinkUpdate.ready]);

  return (
    <StyledInfoBox label={'Bank Accounts'}>
      <Stack spacing={2}>
        <AddBankAccountButton
          bankAccountType={BankAccountType.Business}
          userOrPropertyId={selectedContext.propertyId!}
        />

        <BankingInfoList
          rows={rows.filter((r) => r !== undefined) as ReduxMtAccount[]}
          loadingNewAccount={plaidProcessorToken.loading || selectedProperty.submitting}
          isPropertyList={true}
          onDeleteAccount={handleDeleteAccount}
          onSetDefaultAcct={handleSetAsDefaultAccount}
          onPlaidRefresh={handlePlaidRefresh}
          onViewStatement={onViewStatement}
        />
      </Stack>
    </StyledInfoBox>
  );
};
