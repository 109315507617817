import React, { FC } from 'react';
import { ListItem } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Link from '@mui/material/Link';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Tooltip from '@mui/material/Tooltip';
import { ICamAssociation } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import { addAttachmentToAssociatedAssetAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/communications/communicationSlice';
import { ImagePreviewComponent } from '../../documents/components/ImagePreviewComponent';
import { getFileExtension } from '../../utils/getFileExtension';

type AttachmentLinkProps = {
  attachment: ReduxDocument;
  associations: ICamAssociation[];
  emailThreadId?: string;
  emailId: string;
  onCloseMenu: () => void;
  onOpenAddAttachmentDialog: (attachmentId?: string, emailId?: string) => void;
};

export const AttachmentLink: FC<AttachmentLinkProps> = ({
  attachment,
  emailThreadId,
  emailId,
  associations,
  onCloseMenu,
  onOpenAddAttachmentDialog,
}) => {
  const dispatch = useDispatch();
  const documentUrl = `${process.env.REACT_APP_API_URL}/email/${emailThreadId}/emails/${emailId}/document/${attachment.id}`;

  const handleAddAttachmentToAssociations = (attachmentId?: string) => {
    if (!emailThreadId || !attachmentId) return;
    if (associations.length === 1) {
      dispatch(
        addAttachmentToAssociatedAssetAction({
          threadId: emailThreadId,
          emailId: emailId,
          attachmentId: attachmentId,
          association: associations[0],
        }),
      );
      onCloseMenu();
    } else {
      onOpenAddAttachmentDialog(attachmentId, emailId);
      onCloseMenu();
    }
  };

  return (
    <ListItem key={attachment.id}>
      {associations.length > 0 && (
        <IconButton onClick={() => handleAddAttachmentToAssociations(attachment.id)}>
          <ListItemIcon sx={{ mr: '-2rem' }}>
            <Tooltip
              title={associations.length === 1 ? 'Add to email thread Association' : 'Add to email thread Associations'}
              PopperProps={{
                disablePortal: true,
              }}
            >
              <NoteAddIcon />
            </Tooltip>
          </ListItemIcon>
        </IconButton>
      )}
      <ListItemText>
        <ImagePreviewComponent imageUrl={documentUrl} docType={getFileExtension(attachment.name)}>
          <Link href={documentUrl} download={attachment.name} color={'inherit'}>
            {attachment.name}
          </Link>
        </ImagePreviewComponent>
      </ListItemText>
    </ListItem>
  );
};
