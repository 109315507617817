import {
  IQueryExpression,
  QueryExpression,
  QueryGroupOperator,
  QueryOperator,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { add, endOfDay, format, parseISO, sub } from 'date-fns';
import { Filter } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GridFilterModel,
} from '@mui/x-data-grid-premium';
import { getSpecificLeaseStatusFilters } from './filteringLeaseStatusUtils';
import { ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';

export type FilterOperator =
  | '='
  | '>'
  | '<'
  | '!='
  | 'IsNull'
  | 'IsNotNull'
  | 'isEmpty'
  | 'isNotEmpty'
  | 'is'
  | 'equals'
  | 'after'
  | 'onOrAfter'
  | 'before'
  | 'onOrBefore';

export const dateFilters = getGridDateOperators().filter(
  (operator) => operator.value === 'is' || operator.value === 'after' || operator.value === 'before',
);

export const stringContainsFilters = getGridStringOperators().filter((operator) => operator.value === 'contains');
export const stringFilters = getGridStringOperators().filter((operator) => operator.value === 'equals');

export const numericFilters = getGridNumericOperators().filter(
  (operator) => operator.value === '=' || operator.value === '!=' || operator.value === '>' || operator.value === '<',
);

export function getDateFilter<TModel>(item: Filter<TModel>): IQueryExpression {
  const filterDate = new Date(item.value);
  const filterDateOnly = new Date(filterDate.valueOf() + filterDate.getTimezoneOffset() * 60 * 1000);
  const beginningOfDayFormatted = format(endOfDay(sub(filterDateOnly, { days: 1 })), "yyyy-MM-dd'T'HH:mm:ss");
  const endOfDayFormatted = format(add(filterDateOnly, { days: 1 }), 'yyyy-MM-dd');
  switch (item.operator) {
    case 'after':
      return {
        parameters: [
          {
            field: item.name as string,
            operator: QueryOperator.Gt,
            value: endOfDayFormatted,
          },
        ],
      };
      break;
    case 'onOrAfter':
      return {
        parameters: [
          {
            field: item.name as string,
            operator: QueryOperator.Gt,
            value: beginningOfDayFormatted,
          },
        ],
      };
      break;
    case 'before':
      return {
        parameters: [
          {
            field: item.name as string,
            operator: QueryOperator.Lt,
            value: beginningOfDayFormatted,
          },
        ],
      };
      break;
    case 'onOrBefore':
      return {
        parameters: [
          {
            field: item.name as string,
            operator: QueryOperator.Lt,
            value: endOfDayFormatted,
          },
        ],
      };
      break;
    //TODO: currently parameters with null values gets a db exception
    // case 'isEmpty':
    //   break;
    // case 'isNotEmpty':
    //   break;
    default: //'is'
      return {
        groupOperator: QueryGroupOperator.And,
        parameters: [
          {
            field: item.name as string,
            operator: QueryOperator.Gt,
            value: beginningOfDayFormatted,
          },
          {
            field: item.name as string,
            operator: QueryOperator.Lt,
            value: endOfDayFormatted,
          },
        ],
      };
  }
}

export function getNameFilter<TModel>(item: Filter<TModel>): IQueryExpression {
  switch (item.name) {
    case 'firstName':
      return {
        groupOperator: QueryGroupOperator.Or,
        parameters: [
          {
            field: item.name as string,
            operator: QueryOperator.Eq,
            value: item.value,
          },
          {
            field: 'lastName',
            operator: QueryOperator.Eq,
            value: item.value,
          },
        ],
      };
      break;
    case 'lastName':
      return {
        groupOperator: QueryGroupOperator.Or,
        parameters: [
          {
            field: item.name as string,
            operator: QueryOperator.Eq,
            value: item.value,
          },
          {
            field: 'firstName',
            operator: QueryOperator.Eq,
            value: item.value,
          },
        ],
      };
      break;
    default:
      return {
        parameters: [
          {
            field: item.name as string,
            operator: QueryOperator.Eq,
            value: item.value,
          },
        ],
      };
  }
}

export function convertToQueryOperator(operator: FilterOperator): QueryOperator {
  switch (operator) {
    case 'IsNull':
    case 'isEmpty':
      return QueryOperator.IsNull;
    case 'IsNotNull':
    case 'isNotEmpty':
      return QueryOperator.IsNotNull;
    case '=':
    case 'equals':
    case 'is':
      return QueryOperator.Eq;
    case '>':
    case 'after':
    case 'onOrAfter':
      return QueryOperator.Gt;
    case '<':
    case 'before':
    case 'onOrBefore':
      return QueryOperator.Lt;
    case '!=':
      return QueryOperator.Ne;
  }
}

export function convertToQueryExpression<TModel>(filters: Filter<TModel>[]): QueryExpression {
  const queryExpression: IQueryExpression = {
    groupOperator: QueryGroupOperator.And,
    parameters: [],
    subExpressions: [],
  };
  if (!filters) {
    return new QueryExpression(queryExpression);
  }
  for (const filter of filters) {
    const date = parseISO(filter.value);
    if (!isNaN(date.valueOf()) && typeof filter.name === 'string') {
      queryExpression.subExpressions!.push(getDateFilter(filter));
    } else if (filter.name === 'firstName' || filter.name === 'lastName') {
      queryExpression.subExpressions!.push(getNameFilter(filter));
    } else if (filter.name === 'detailedStatus') {
      queryExpression.subExpressions!.push(getSpecificLeaseStatusFilters(filter as Filter<ReduxLease>));
    } else if (typeof filter.name === 'string' && typeof filter.value !== undefined && filter.value) {
      queryExpression.parameters?.push({
        field: filter.name,
        operator: convertToQueryOperator(filter.operator),
        value: filter.value,
      });
    }
  }

  return new QueryExpression(queryExpression);
}

type ReduxFilter<T> = {
  name: keyof T;
  operator: FilterOperator;
  value: string;
};

export function formatReduxFilters<T>(model: GridFilterModel): ReduxFilter<T>[] | undefined {
  const parameterHasValues = model.items.length > 0 && model.items.every((parameter) => parameter.value);
  if (parameterHasValues) {
    const filters = model.items.map((items) => {
      return {
        name: items.field as keyof T,
        operator: items.operator as FilterOperator,
        value: items.value,
      };
    });
    return filters;
  }
  return undefined;
}
