import React, { FC, useEffect } from 'react';
import { IContactPerson, IVendor } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Autocomplete, { AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

type VendorContactAutocompleteProps = {
  loading?: boolean;
  vendor: IVendor;
  onChange?: (value?: IContactPerson) => void;
  value: IContactPerson | null;
} & Omit<AutocompleteProps<IContactPerson, false, false, false>, 'options' | 'onChange'>;

export const VendorContactAutocomplete: FC<VendorContactAutocompleteProps> = ({
  loading,
  vendor,
  onChange,
  value,
  ...autocompleteProps
}) => {
  const [internalValue, setInternalValue] = React.useState<IContactPerson | null>(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);
  const handleChange = (event: React.SyntheticEvent, newValue: IContactPerson | null) => {
    console.log('detected vendor contact change in VendorContactAutocomplete.tsx', newValue);
    setInternalValue(newValue);
    onChange && onChange(newValue ?? undefined);
  };
  const filter = createFilterOptions<IContactPerson>();

  return (
    <Autocomplete
      clearOnBlur
      options={vendor.contacts ?? []}
      loading={loading}
      onChange={handleChange}
      value={internalValue}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Regular option
        return `${option.firstName} ${option.lastName}`;
      }}
      {...autocompleteProps}
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <Stack divider={<Divider />}>
              {option.firstName && (
                <Typography>
                  {option.firstName} {option.lastName}
                </Typography>
              )}
              {option.contactInfo?.phone && <Typography variant={'subtitle2'}>{option.contactInfo.phone}</Typography>}
              {option.contactInfo?.email && <Typography variant={'subtitle2'}>{option.contactInfo.email}</Typography>}
            </Stack>
          </ListItem>
        );
      }}
      filterOptions={(options, params) => {
        return filter(options, params);
      }}
    />
  );
};
