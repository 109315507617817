import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';
import {
  easeCubic,
  forceCenter,
  forceLink,
  forceManyBody,
  forceSimulation,
  select,
  SimulationLinkDatum,
  SimulationNodeDatum,
} from 'd3';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material';

interface LoadingProps {
  loading: boolean;
  variation?: 'primary' | 'secondary' | 'material' | undefined;
  size: 'small' | 'medium' | 'large';
}

export const Loading: FC<PropsWithChildren<LoadingProps>> = ({ loading, children, variation }) => {
  const svgRef = useRef(null);
  const theme = useTheme();
  const color = variation === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main;
  const width = 300;
  const height = 300;
  //TODO: sizing for small and medium; I think the current sizing works best for large
  useEffect(() => {
    if (svgRef.current && variation !== 'material' && loading) {
      interface Node extends SimulationNodeDatum {
        id: number;
      }

      type Link = SimulationLinkDatum<Node>;
      // Define the SVG container
      const svg = select<SVGElement, null>(svgRef.current as any);
      // Generate 16 nodes
      const nodes: Node[] = Array.from({ length: 16 }, (_, i) => ({
        id: i,
        x: 150 + 32 * Math.cos((i * 2 * Math.PI) / 16),
        y: 150 + 32 * Math.sin((i * 2 * Math.PI) / 16),
      }));
      // Generate links between adjacent nodes
      const links: Link[] = Array.from({ length: 16 }, (_, i) => ({
        source: i,
        target: (i + 1) % 16, // connect to the next node, and wrap around at the end
      }));
      // Update function for the simulation
      const ticked = () => {
        circle.attr('cx', (d) => d.x!).attr('cy', (d) => d.y!);
        line
          .attr('x1', (d) => (d.source as Node).x!)
          .attr('y1', (d) => (d.source as Node).y!)
          .attr('x2', (d) => (d.target as Node).x!)
          .attr('y2', (d) => (d.target as Node).y!);
      };

      const center = forceCenter(width / 2, height / 2);
      const charge = forceManyBody().strength(-125);
      const linkForce = forceLink(links).distance(-50);

      // Create a group (container) element to hold nodes and links
      const container = svg.append('g').attr('transform', `translate(${width / 2}, ${height / 2})`);
      // Create lines for the links
      const line = container.selectAll('.link').data(links).enter().append('line').attr('stroke', color);
      // Create circles for the nodes
      const circle = container
        .selectAll('circle')
        .data(nodes)
        .enter()
        .append('circle')
        .attr('r', 5)
        .attr('fill', color);

      // Create the force simulation
      const simulation = forceSimulation(nodes)
        .force('center', center)
        .force('charge', charge)
        .force('link', linkForce)
        .on('tick', ticked);

      const spin = () => {
        container
          .transition()
          .duration(1000)
          .ease(easeCubic)
          .attrTween('transform', () => {
            return (t: number) => `rotate(${t * 180}, ${width / 2}, ${height / 2})`;
          })
          .tween('link', () => {
            return (t: number) => {
              center.strength(Math.abs(t - 0.5) * 1);
              charge.strength((Math.abs(t - 0.5) * 1000) / 4.5 - 125);
              linkForce.distance(Math.abs(t - 0.5) * 100 - 50);
              simulation.alpha(0.3);
            };
          })
          .on('end', () => {
            setTimeout(spin, 250);
          });
      };

      spin();
    }
  }, [loading]);

  if (loading) {
    return (
      <Container>
        {variation === 'material' || typeof variation === 'undefined' ? (
          <CircularProgress />
        ) : (
          <svg ref={svgRef} width={width} height={height} />
        )}
      </Container>
    );
  }

  return <>{children}</>;
};
