import React, { FC, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import { AssetTab } from '../../_shared/tabs/TabTypeEnums';
import Paper from '@mui/material/Paper';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { AssetParams } from '../../../AppRouter';
import { useDispatch } from 'react-redux';
import { clearApplicantTableFiltersAndSortAction } from '../../property/redux/applicationSlice';
import { clearLeaseFiltersAndSortsAction } from '../../_shared/leases/redux/leasesSlice';
import { clearAssetNotificationFiltersAndSortAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/notifications/notificationSlice';
import { AssetTabSkeleton } from '../../_shared/styledComponents/AssetTabSkeleton';
import {
  AssetType,
  IGetBuildingHandlerResponse,
  IGetUnitHandlerResponse,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { getAssetTypeFromPathname } from '../../_shared/utils/getAssetTypeFromPathname';
import { InnerTabLayout } from './InnerTabLayout';
import { useTabConfig } from '../../_shared/tabs/useTabConfig';
import { ReduxRentalProperty } from '@monkeyjump-labs/cam-fe-shared/dist/types/propertyTypes';
import { OuterTabsHeader } from './OuterTabsHeader';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IsCamProperty } from '../../../app/constants';

type OuterTabsLayoutProps = {
  assetType: AssetType;
  loading: boolean;
  value?: ReduxRentalProperty | IGetBuildingHandlerResponse | IGetUnitHandlerResponse;
};
export const OuterTabsLayout: FC<OuterTabsLayoutProps> = ({ assetType, loading, value }) => {
  const { id, outerTab } = useParams<AssetParams>();
  const [selectedOuterTab, setSelectedOuterTab] = useState<AssetTab>(outerTab ?? AssetTab.Overview);
  const dispatch = useDispatch();
  const { findDefaultTab } = useTabConfig();
  const navigate = useNavigate();
  const flags = useFlags();
  const normalizedFinancialsTabFlag = 'Feature.Financials.FinancialsTab'.replace(/\./g, '').toLowerCase();
  const entry = Object.entries(flags).filter(([k]) => k.toLowerCase() == normalizedFinancialsTabFlag);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, flagValue] = entry.length > 0 ? entry[0] : [];

  useEffect(() => {
    outerTab && setSelectedOuterTab(outerTab);
  }, [outerTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: AssetTab) => {
    const defaultTab = findDefaultTab(assetType, newValue);
    navigate(`/assets/${getAssetTypeFromPathname()}/${id}/${newValue}/${defaultTab}`);
    if (outerTab === AssetTab.Leasing) {
      dispatch(clearApplicantTableFiltersAndSortAction());
      dispatch(clearLeaseFiltersAndSortsAction());
    }
    if (outerTab === AssetTab.Overview) {
      dispatch(clearAssetNotificationFiltersAndSortAction());
    }
  };

  if (loading) {
    return <AssetTabSkeleton />;
  } else if (!value) {
    return <></>;
  }
  return (
    <Stack flexGrow={1} sx={{ ml: 2 }}>
      <OuterTabsHeader assetType={assetType} value={value} />
      <Box flexGrow={1} sx={{ width: '100%', typography: 'body1', display: 'flex', flexDirection: 'column' }}>
        <TabContext value={selectedOuterTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label={`${assetType} tabs`}>
              <Tab label="Coordination" value={AssetTab.Overview} />
              {!IsCamProperty(value.id) && <Tab label="Leasing" value={AssetTab.Leasing} />}
              <Tab label="Operations" value={AssetTab.Operations} />
              {flagValue && <Tab label="Financials" value={AssetTab.Financials} />}
              <Tab label="Configuration" value={AssetTab.Info} />
            </TabList>
          </Box>
          <Paper sx={{ flexGrow: 1 }}>
            {
              {
                overview: <InnerTabLayout assetType={assetType} tab={AssetTab.Overview} />,
                leasing: <InnerTabLayout assetType={assetType} tab={AssetTab.Leasing} />,
                operations: <InnerTabLayout assetType={assetType} tab={AssetTab.Operations} />,
                financials: <InnerTabLayout assetType={assetType} tab={AssetTab.Financials} />,
                info: <InnerTabLayout assetType={assetType} tab={AssetTab.Info} />,
              }[selectedOuterTab ?? AssetTab.Overview]
            }
          </Paper>
        </TabContext>
        <Outlet />
      </Box>
    </Stack>
  );
};
