import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { ReduxListing } from '../../redux/listingData';
import { handleFormatAddress } from '../shared/ListingCard';

type AgentInfoProps = {
  listing: ReduxListing;
};

export const AgentInfo: FC<AgentInfoProps> = ({ listing }) => {
  return (
    <Stack alignItems={'end'}>
      <Stack spacing={1} direction={'row'}>
        <Typography fontStyle={'italic'}>Contact Agent:</Typography>
        <Typography>
          {listing.agent?.firstName} {listing.agent?.lastName}
        </Typography>
      </Stack>
      <Stack spacing={1} direction={'row'}>
        {listing.agent?.contactInfo?.phone && (
          <Link href={`tel:${listing.agent?.contactInfo?.phone}`}>{listing.agent?.contactInfo?.phone}</Link>
        )}
        {listing.agent?.contactInfo?.email && listing.agent?.contactInfo?.phone && <Typography>/</Typography>}
        {listing.agent?.contactInfo?.email && (
          <Link
            href={`mailto:${listing.agent?.contactInfo?.email}?subject=RE:%20Listing%20at%20${encodeURIComponent(handleFormatAddress(listing.address))}`}
          >
            {listing.agent?.contactInfo?.email}
          </Link>
        )}
      </Stack>
    </Stack>
  );
};
