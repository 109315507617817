import React, { ChangeEvent, FC } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import {
  IRentFee,
  IRentInfo,
  RentFrequency,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import AttachMoney from '@mui/icons-material/AttachMoney';
import { NumberValueTextField } from '../../texfields/NumberValueTextField';

export type RentInformationEditorProps = {
  isNew?: boolean;
  editable?: boolean;
  value: IRentInfo;
  onChange?: (newValue: IRentInfo) => unknown;
};

export const RentInformationEditor: FC<RentInformationEditorProps> = ({ editable, value, onChange, isNew }) => {
  function getFriendlyName(accountName: string | undefined) {
    if (accountName?.endsWith(' Revenue')) {
      return accountName.substring(0, accountName.indexOf(' Revenue'));
    } else {
      return accountName;
    }
  }

  function createRentChangeHandler<T extends keyof IRentInfo>(field: T) {
    return (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
      const newValue = { ...value, [field]: event.target.value };
      onChange && onChange(newValue);
    };
  }

  const createRentOtherFeesChangeHandler = (accountId: string | undefined) => {
    return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | undefined>) => {
      onChange &&
        onChange({
          ...value,
          otherFees: value!.otherFees!.map((x) => {
            if (x.account?.id === accountId) {
              x = {
                label: x.label,
                account: x.account,
                fee: event.target.value ? Number(event.target.value) : undefined,
              };
            }
            return x;
          }),
        });
    };
  };

  return (
    <Grid container spacing={2}>
      {/* Deposit */}
      {isNew && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">Deposit + Initial Fees</Typography>
          </Grid>
          <Grid item xs={12}>
            <NumberValueTextField
              fullWidth
              margin="dense"
              variant="standard"
              valueUnits={'dollars'}
              label="Deposit"
              required
              value={value?.deposit ? value.deposit : ''}
              onChange={createRentChangeHandler('deposit')}
              InputProps={{
                readOnly: !editable,
                startAdornment: (
                  <Icon>
                    <AttachMoney />
                  </Icon>
                ),
              }}
            />
          </Grid>
        </>
      )}
      {/*Rent & Rental Frequency*/}
      <Grid item xs={12}>
        <Typography variant="h6">Standard Rent Fees</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberValueTextField
          fullWidth
          margin="dense"
          variant="standard"
          valueUnits={'dollars'}
          label="Rent Fee"
          required
          value={value?.rentAmount ? value.rentAmount : ''}
          onChange={createRentChangeHandler('rentAmount')}
          InputProps={{
            readOnly: !editable,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="dense" variant="standard">
          <InputLabel id="rental-frequency-label">Rental Frequency</InputLabel>
          <Select
            labelId="rental-frequency-label"
            readOnly={editable}
            value={value?.frequency || RentFrequency.Monthly}
            onChange={createRentChangeHandler('frequency')}
          >
            <MenuItem value={RentFrequency.Monthly}>Month</MenuItem>
            <MenuItem value={RentFrequency.Biweekly}>Bi-Weekly</MenuItem>
            <MenuItem value={RentFrequency.Weekly}>Weekly</MenuItem>
            <MenuItem value={RentFrequency.Annual}>Annual</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {/* Late Fee */}
      <Grid item xs={12} sm={6}>
        <NumberValueTextField
          fullWidth
          margin="dense"
          variant="standard"
          valueUnits={'dollars'}
          label="Late Fee"
          required
          value={value?.lateFee ? value.lateFee : ''}
          onChange={createRentChangeHandler('lateFee')}
          InputProps={{
            readOnly: !editable,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <></>
      </Grid>
      {/* Month to Month Fee */}
      <Grid item xs={12} sm={6}>
        <NumberValueTextField
          fullWidth
          margin="dense"
          variant="standard"
          valueUnits={'dollars'}
          label="Month to Month Fee"
          required
          value={value?.monthToMonthFee ? value.monthToMonthFee : ''}
          onChange={createRentChangeHandler('monthToMonthFee')}
          InputProps={{
            readOnly: !editable,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Other Fees</Typography>
      </Grid>

      {
        /* Show All other Rent Fees */
        value?.otherFees &&
          value.otherFees.map((item: IRentFee, i) => (
            <Grid key={i} item xs={12} sm={6}>
              <NumberValueTextField
                fullWidth
                margin="dense"
                variant="standard"
                valueUnits={'dollars'}
                label={getFriendlyName(item.label)}
                value={item.fee ? item.fee : ''}
                onChange={createRentOtherFeesChangeHandler(item.account?.id)}
                InputProps={{
                  readOnly: !editable,
                }}
              />
            </Grid>
          ))
      }
    </Grid>
  );
};
