import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { FileUploadButton } from '../buttons/FileUploadButton';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {
  cancelFileUploadAction,
  fileUploadingAction,
  useGlobal,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useDispatch } from 'react-redux';
import { DialogLayout } from './DialogLayout';

export const UploadFileDialog: FC<Omit<DialogProps, 'open' | 'onClose'>> = (dialogProps) => {
  const dispatch = useDispatch();
  const { uploadDialog } = useGlobal();

  const handleClose = () => {
    dispatch(cancelFileUploadAction());
  };

  const handleUpload = (file: File) => {
    dispatch(fileUploadingAction(file));
  };

  return (
    <DialogLayout
      title={uploadDialog.title ?? 'Upload File'}
      {...dialogProps}
      open={uploadDialog.isVisible}
      onClose={handleClose}
    >
      <DialogContent>
        <Typography sx={{ my: '1rem' }}>{uploadDialog.message}</Typography>
        <FileUploadButton onUpload={handleUpload} loading={uploadDialog.uploading}>
          Upload
        </FileUploadButton>
      </DialogContent>
      <DialogActions>
        {uploadDialog.cancelText && <Button onClick={handleClose}>{uploadDialog.cancelText}</Button>}
        {uploadDialog.okText && <Button onClick={handleClose}>{uploadDialog.okText}</Button>}
      </DialogActions>
    </DialogLayout>
  );
};
