import React, { FC, useEffect, useState } from 'react';
import { SopTaskList } from '../../../../property/components/propertyTabs/propertyInfoTabs/sopTaskTemplates/components/SopTaskList';
import {
  getAutomationsByAssociationIdAction,
  resetDrawerSopTasksStateAction,
  useSopTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import { useDispatch } from 'react-redux';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ReduxSopTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';

export type DetailsDrawerSopTasksProps = {
  detailId: string | undefined;
  propertyId: string | undefined;
  onEditAutomation: (automation: ReduxSopTask) => void;
  onAddAutomation: () => void;
  refresh: boolean;
  onRefresh: () => void;
};

export const DetailsDrawerSopTasks: FC<DetailsDrawerSopTasksProps> = ({
  detailId,
  propertyId,
  onEditAutomation,
  onAddAutomation,
  refresh,
  onRefresh,
}) => {
  const dispatch = useDispatch();
  const { drawerSopTasks } = useSopTasks();
  const [includePropertyConfigs, setIncludePropertyConfigs] = useState(false);

  useEffect(() => {
    detailId &&
      propertyId &&
      dispatch(
        getAutomationsByAssociationIdAction({
          id: propertyId,
          page: 0,
          pageSize: 100,
          associatedId: detailId,
          associationType: AssociationType.Detail,
          includePropertyTasks: includePropertyConfigs,
        }),
      );
  }, [detailId, includePropertyConfigs]);

  useEffect(() => {
    if (!refresh) return;
    detailId &&
      propertyId &&
      dispatch(
        getAutomationsByAssociationIdAction({
          id: propertyId,
          page: 0,
          pageSize: 100,
          associatedId: detailId,
          associationType: AssociationType.Detail,
          includePropertyTasks: false,
        }),
      );
    onRefresh();
  }, [refresh]);

  useEffect(() => {
    if (drawerSopTasks.loaded) dispatch(resetDrawerSopTasksStateAction());
  }, [drawerSopTasks.loaded]);

  return (
    <SopTaskList
      sopTasks={drawerSopTasks.value ?? []}
      isLoading={drawerSopTasks.loading}
      isSubmitting={drawerSopTasks.submitting}
      onEditAutomation={onEditAutomation}
      onAddAutomation={onAddAutomation}
      page={0}
      pageSize={100}
      associationType={AssociationType.Detail}
      associatedId={detailId}
      includePropertyConfigs={includePropertyConfigs}
      toggleIncludePropertyConfigs={() => setIncludePropertyConfigs(!includePropertyConfigs)}
    />
  );
};
