import React, { FC, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import {
  IQueryExpression,
  ListUnitTemplatesHandlerRequest,
  ListUnitTemplatesHandlerResponse,
  QueryOperator,
  UnitTemplate,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export type TemplateOption = {
  id: string;
  label: string;
};

type UnitTemplateSelectProps = {
  value: TemplateOption | null;
  onValueChange: (value: TemplateOption | null) => void;
  required?: boolean;
};

export const UnitTemplateSelect: FC<UnitTemplateSelectProps> = ({ value, onValueChange, required }) => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const [options, setOptions] = React.useState<TemplateOption[]>([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        if (!selectedContext.propertyId) {
          setLoading(false);
          dispatch(showToastMessageAction({ message: 'Error loading unit templates', severity: 'error' }));
          return;
        }
        const query: IQueryExpression = selectedContext.buildingId
          ? { parameters: [{ field: 'buildingId', operator: QueryOperator.Eq, value: selectedContext.buildingId }] }
          : {};
        const response: ListUnitTemplatesHandlerResponse =
          await ApiClientSingleton.getInstance().templates_ListTemplates(
            selectedContext.propertyId,
            new ListUnitTemplatesHandlerRequest({ query: query }),
          );
        if (response) {
          const options: TemplateOption[] = response.toJSON().templates.map((template: UnitTemplate) => {
            return {
              id: template.id!,
              label: template.name!,
            };
          });
          setOptions(options);
          setLoading(false);
        }
      } catch {
        setLoading(false);
        dispatch(showToastMessageAction({ message: 'Error loading unit templates', severity: 'error' }));
      }
    })();
  }, [selectedContext.propertyId]);

  return (
    <Autocomplete
      renderInput={(params) => (
        <TextField variant="standard" label={'Select Unit Template'} required={required ?? false} {...params} />
      )}
      options={options}
      value={value}
      onChange={(_, newValue) => {
        onValueChange(newValue);
      }}
      loading={loading}
      getOptionLabel={(option: TemplateOption) => option.label ?? ''}
      isOptionEqualToValue={(option: TemplateOption, value: TemplateOption) => {
        return option.id === value?.id;
      }}
      renderOption={(props, option, { index }) => (
        <React.Fragment key={option.id}>
          {index > 0 && <Divider />}
          <ListItem {...props}>
            <Stack>
              <Typography>{option.label}</Typography>
            </Stack>
          </ListItem>
        </React.Fragment>
      )}
    />
  );
};
