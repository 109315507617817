import React from 'react';
import FormGroup, { FormGroupProps } from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

type Props = {
  label: string;
  includeClosed: boolean;
  onToggleIncludeClosed: (checked: boolean) => void;
} & FormGroupProps;

export const IncludeClosedToggle = ({ label, includeClosed, onToggleIncludeClosed, ...groupProps }: Props) => {
  return (
    <FormGroup {...groupProps}>
      <FormControlLabel
        control={
          <Switch size="small" checked={includeClosed} onChange={(e) => onToggleIncludeClosed(e.target.checked)} />
        }
        label={label}
      />
    </FormGroup>
  );
};
