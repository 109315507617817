import React, { FC, useEffect, useRef } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

export type ValueUnits = 'dollars' | 'days' | 'payments' | 'tasks' | 'hoursToComplete' | 'none';

export type NotificationValueTextFieldProps = TextFieldProps & {
  valueUnits: ValueUnits | undefined;
  allowNegativeValues?: boolean;
};

export const NumberValueTextField: FC<NotificationValueTextFieldProps> = ({
  valueUnits,
  allowNegativeValues,
  onChange,
  ...otherProps
}) => {
  const externalInputProps = otherProps.InputProps || {};

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      e.preventDefault();
    };

    const inputElem = inputRef.current;
    if (inputElem) {
      inputElem.addEventListener('wheel', handleWheel);
    }

    // Cleanup
    return () => {
      if (inputElem) {
        inputElem.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!allowNegativeValues && Number(event.target.value) < 0) {
      onChange && onChange({ ...event, target: { ...event.target, value: '0' } });
    } else onChange && onChange(event);
  };

  switch (valueUnits) {
    case 'dollars':
      return (
        <TextField
          ref={inputRef}
          type={'number'}
          onChange={handleChange}
          {...otherProps}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            ...externalInputProps,
          }}
          inputProps={{
            min: allowNegativeValues ? undefined : 0,
            step: 0.01,
          }}
        />
      );
    case 'days':
      return (
        <TextField
          ref={inputRef}
          type={'number'}
          onChange={handleChange}
          {...otherProps}
          InputProps={{
            endAdornment: <InputAdornment position="end">days</InputAdornment>,
            ...externalInputProps,
          }}
        />
      );
    case 'payments':
      return (
        <TextField
          ref={inputRef}
          type={'number'}
          onChange={handleChange}
          {...otherProps}
          InputProps={{
            endAdornment: <InputAdornment position="end">monthly payment(s)</InputAdornment>,
            ...externalInputProps,
          }}
        />
      );
    case 'tasks':
      return (
        <TextField
          ref={inputRef}
          type={'number'}
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">task(s)</InputAdornment>,
            ...externalInputProps,
          }}
        />
      );
    case 'hoursToComplete':
      return (
        <TextField
          ref={inputRef}
          type={'number'}
          onChange={handleChange}
          {...otherProps}
          InputProps={{
            endAdornment: <InputAdornment position="end">hours to complete</InputAdornment>,
            ...externalInputProps,
          }}
        />
      );
    default:
      return <TextField ref={inputRef} type={'number'} onChange={handleChange} {...otherProps} />;
  }
};
