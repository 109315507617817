import React, { FormEvent, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import {
  ContactInfo,
  IPostalAddress,
  IServiceProvider,
  ServiceProviderType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { appTheme } from '../../../../../../AppTheme';
import { PhoneTextField } from '../../../../../_shared/texfields/PhoneTextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';

type Props = {
  serviceProvider: IServiceProvider;
  index: number;
  onSave: (serviceProvider: IServiceProvider, index: number) => void;
  onRemove: (index: number) => void;
};

export const ServiceProviderItem = ({ serviceProvider, index, onSave, onRemove }: Props) => {
  const { selectedProperty } = useProperty();
  const [dirty, setDirty] = useState<boolean>(false);
  const [updatedProvider, setUpdatedProvider] = useState(serviceProvider);

  const handleInputChange = (value: string, key: keyof IServiceProvider) => {
    setUpdatedProvider({ ...updatedProvider, [key]: value });
    setDirty(true);
  };

  const handlePostalAddressChange = (value: string, key: keyof IPostalAddress) => {
    setUpdatedProvider({
      ...updatedProvider,
      contactInfo: {
        ...updatedProvider.contactInfo,
        postalAddress: { ...(updatedProvider?.contactInfo?.postalAddress ?? {}), [key]: value },
      },
    });
    setDirty(true);
  };

  const handleContactInfoChange = (value: string, key: keyof ContactInfo) => {
    setUpdatedProvider({ ...updatedProvider, contactInfo: { ...updatedProvider.contactInfo, [key]: value } });
    setDirty(true);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSave(updatedProvider, index);
    setDirty(false);
  };

  const handleRemove = () => {
    onRemove(index);
  };

  if (serviceProvider) {
    return (
      <form onSubmit={onSubmit} autoComplete="off">
        <Grid
          container
          spacing={2}
          my={5}
          padding="1rem"
          border=".1rem solid gray"
          sx={{ borderRadius: appTheme.spacing(1) }}
        >
          <Grid item xs={6}>
            <TextField
              id="name"
              label="Name"
              type="text"
              defaultValue={serviceProvider.name}
              margin="dense"
              variant="standard"
              fullWidth
              onChange={(e) => handleInputChange(e.target.value, 'name')}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="dense" variant="standard">
              <InputLabel id="provider-type">Provider Type</InputLabel>
              <Select
                labelId="provider-type"
                id="providerType"
                defaultValue={serviceProvider.providerType}
                label="Provider Type"
                onChange={(e) => handleInputChange(e.target.value, 'providerType')}
              >
                <MenuItem value={ServiceProviderType.Phone}>Phone</MenuItem>
                <MenuItem value={ServiceProviderType.Gas}>Gas</MenuItem>
                <MenuItem value={ServiceProviderType.Water}>Water</MenuItem>
                <MenuItem value={ServiceProviderType.Electric}>Electric</MenuItem>
                <MenuItem value={ServiceProviderType.Cable}>Cable</MenuItem>
                <MenuItem value={ServiceProviderType.Insurance}>Insurance</MenuItem>
                <MenuItem value={ServiceProviderType.Other}>Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="accountNumber"
              label="Account Number"
              type="text"
              defaultValue={serviceProvider.accountNumber}
              margin="dense"
              variant="standard"
              fullWidth
              onChange={(e) => handleInputChange(e.target.value, 'accountNumber')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="description"
              label="Description"
              type="text"
              defaultValue={serviceProvider.description}
              margin="dense"
              variant="standard"
              fullWidth
              onChange={(e) => handleInputChange(e.target.value, 'description')}
            />
          </Grid>
          {serviceProvider.contactInfo && (
            <>
              <Grid item xs={6}>
                <TextField
                  id="streetAddress1"
                  label="Street Address 1"
                  type="text"
                  defaultValue={serviceProvider.contactInfo?.postalAddress?.streetAddress1}
                  margin="dense"
                  variant="standard"
                  fullWidth
                  onChange={(e) => handlePostalAddressChange(e.target.value, 'streetAddress1')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="streetAddress2"
                  label="Street Address 2"
                  type="text"
                  defaultValue={serviceProvider.contactInfo?.postalAddress?.streetAddress2}
                  margin="dense"
                  variant="standard"
                  fullWidth
                  onChange={(e) => handlePostalAddressChange(e.target.value, 'streetAddress2')}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="city"
                  label="City"
                  type="text"
                  defaultValue={serviceProvider.contactInfo?.postalAddress?.city}
                  margin="dense"
                  variant="standard"
                  fullWidth
                  onChange={(e) => handlePostalAddressChange(e.target.value, 'city')}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id="state"
                  label="State"
                  type="text"
                  defaultValue={serviceProvider.contactInfo?.postalAddress?.state}
                  margin="dense"
                  variant="standard"
                  fullWidth
                  onChange={(e) => handlePostalAddressChange(e.target.value, 'state')}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id="zip"
                  label="Zip Code"
                  type="number"
                  defaultValue={serviceProvider.contactInfo?.postalAddress?.zip}
                  margin="dense"
                  variant="standard"
                  fullWidth
                  onChange={(e) => handlePostalAddressChange(e.target.value, 'zip')}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  defaultValue={serviceProvider.contactInfo?.email}
                  margin="dense"
                  variant="standard"
                  fullWidth
                  onChange={(e) => handleContactInfoChange(e.target.value, 'email')}
                />
              </Grid>
              <Grid item xs={4}>
                <PhoneTextField
                  id="phone"
                  label="Phone"
                  type="tel"
                  defaultValue={serviceProvider.contactInfo?.phone}
                  margin="dense"
                  variant="standard"
                  fullWidth
                  onChange={(e) => handleContactInfoChange(e.target.value, 'phone')}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="website"
                  label="Website"
                  type="url"
                  defaultValue={serviceProvider.contactInfo?.website}
                  margin="dense"
                  variant="standard"
                  fullWidth
                  onChange={(e) => handleContactInfoChange(e.target.value, 'website')}
                />
              </Grid>
            </>
          )}
          <Grid item container xs={12} justifyContent="right">
            <Button variant="outlined" onClick={handleRemove} sx={{ mr: '1rem' }}>
              Remove Provider
            </Button>
            <LoadingButton type="submit" variant="contained" disabled={!dirty} loading={selectedProperty.submitting}>
              Update Provider
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    );
  } else return <></>;
};
