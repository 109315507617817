import Button from '@mui/material/Button';
import { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import React, { FC, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { NumberValueTextField } from '../../texfields/NumberValueTextField';
import { StatementView } from '../../statements/components/StatementView';
import { useDispatch } from 'react-redux';
import { getStatementByLeaseIdAction, useStatement } from '../../statements/redux/statementSlice';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { ReduxAccount } from '@monkeyjump-labs/cam-fe-shared/dist/types/propertyTypes';
import Typography from '@mui/material/Typography';
import { useHandleTransaction } from './useHandleTransaction';
import { LeaseDispatchType } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import {
  getAmountOwedToTenantAction,
  getAvailableDepositBalanceAction,
  getSingleLeaseAction,
  useLeases,
} from '../../leases/redux/leasesSlice';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import {
  AccountType,
  ISlimAccountRef,
  LinkedPaymentAccountType,
  PaymentType,
  StatementPeriodType,
  SubAccountType,
  SystemAccountType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLeaseTransaction } from '../../leases/redux/leaseTransactionSlice';
import { DialogLayout } from '../DialogLayout';
import { AccountAutocomplete } from '../../autocompletes/AccountAutocomplete';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export type PaymentDialogProps = {
  title: PaymentTitle;
  onClose: () => unknown;
  leaseId: string;
  assetId: string;
  leasesType: LeaseDispatchType;
} & Omit<DialogProps, 'onClose' | 'onSubmit'>;

export type PaymentTitle =
  | 'Accept Deposit'
  | 'Accept Payment'
  | 'Apply Charge'
  | 'Apply Credit'
  | 'Rectify Deposit'
  | 'Return Funds'
  | 'Log Returned Funds';

type SelectAccountProps = {
  descriptionPostfix?: string;
  accounts: ReduxAccount[];
};

export const LeasePaymentDialog: FC<PaymentDialogProps> = ({
  title,
  onClose,
  leaseId,
  assetId,
  leasesType,
  ...dialogProps
}) => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const property = useProperty();
  const lease = useLeases();
  const statement = useStatement();
  const leaseTransaction = useLeaseTransaction();
  const { handleTransaction } = useHandleTransaction();
  const [type, setType] = useState<PaymentType | undefined>();
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [paymentNo, setPaymentNo] = useState('');
  const [account, setAccount] = useState<ISlimAccountRef>();
  const [secondaryAccount, setSecondaryAccount] = useState<ISlimAccountRef>();
  const [revenueAccounts, setRevenueAccounts] = useState<ReduxAccount[] | undefined>(undefined);
  const [arAccounts, setArAccounts] = useState<ReduxAccount[]>();

  const statementPeriod = StatementPeriodType.Month;

  useEffect(() => {
    leaseId && dispatch(getSingleLeaseAction({ leaseId, openLeaseModal: false }));
    if (leaseId && title === 'Rectify Deposit') {
      dispatch(getAvailableDepositBalanceAction(leaseId));
    }
    if (leaseId && title === 'Return Funds') {
      dispatch(getAmountOwedToTenantAction(leaseId));
      setType(PaymentType.Check);
    }
    const currentDate = selectedContext?.currentDate ? toStandardDate(selectedContext.currentDate) : new Date();
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    leaseId &&
      dispatch(
        getStatementByLeaseIdAction({
          leaseId: leaseId,
          startDate: toReduxDate(startOfMonth),
          statementPeriodType: statementPeriod,
          isDepositStatement: title === 'Accept Deposit',
        }),
      );
  }, [leaseId]);

  useEffect(() => {
    if (leaseTransaction?.leaseTransaction.submitted) handleClose();
  }, [leaseTransaction?.leaseTransaction.submitted]);

  useEffect(() => {
    setRevenueAccounts(
      property.glAccounts.value?.filter(
        (a) => a.accountType === 'Revenue' && a.subAccountType === SubAccountType.RentalIncome,
      ),
    );
    setArAccounts(
      property.glAccounts.value?.filter(
        (a) =>
          a.accountType === AccountType.Assets &&
          a.subAccountType === SubAccountType.AccountsReceivable &&
          a.systemType !== SystemAccountType.AccountsReceivable &&
          a.systemType !== SystemAccountType.DepositsReceivable &&
          a.systemType !== SystemAccountType.StandardRentReceivables,
      ),
    );
  }, [property.glAccounts.value]);

  useEffect(() => {
    const draftLease = lease.draftLeases?.value?.find((x) => x.id === leaseId);
    if (title === 'Accept Deposit' && draftLease?.delinquency !== undefined) {
      setAmount(draftLease.delinquency.toFixed(2));
    }
  }, [leaseId]);

  const handleTypeChange = (event: SelectChangeEvent) => {
    if (event.target.value === PaymentType.Cash) {
      setDescription('Cash Payment');
      setType(PaymentType.Cash);
    } else if (event.target.value === PaymentType.BankTransfer) {
      setDescription('Bank Transfer');
      setType(PaymentType.BankTransfer);
    } else if (event.target.value === PaymentType.Check) {
      setDescription('Check Payment');
      setType(event.target.value);
    } else setDescription('');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleTransaction(
      leaseId,
      assetId,
      leasesType,
      type!,
      paymentNo,
      title,
      description,
      parseFloat(amount),
      account,
      secondaryAccount,
    );
  };

  const handleClose = () => {
    setType(undefined);
    setAmount('');
    setDescription('');
    setAccount(undefined);
    setSecondaryAccount(undefined);
    onClose();
  };

  const SelectPaymentType = () => {
    return (
      <FormControl fullWidth>
        <InputLabel id="payment-type-label">Payment Type</InputLabel>
        <Select
          required
          value={type}
          onChange={handleTypeChange}
          labelId="payment-type-label"
          id="payment-type"
          label="Payment Type"
        >
          {title !== 'Return Funds' && <MenuItem value={PaymentType.Cash}>Cash</MenuItem>}
          {title === 'Return Funds' &&
            lease.activeLease.loaded &&
            lease.activeLease.value?.primaryTenantHasBankAccount && (
              <MenuItem disabled value={PaymentType.BankTransfer}>
                Bank Transfer
              </MenuItem>
            )}
          <MenuItem value={PaymentType.Check}>Check</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const SelectAccount = ({ accounts, descriptionPostfix }: SelectAccountProps) => {
    if (accounts.length === 0) {
      return (
        <FormControl fullWidth>
          <InputLabel id="account-label">No Accounts Available For This Transaction</InputLabel>
          <Select />
        </FormControl>
      );
    }
    return (
      <FormControl fullWidth>
        <InputLabel id="account-label">Account</InputLabel>
        <Select
          required
          value={account?.id}
          onChange={(e) => {
            const selectedAccount = accounts && accounts.find((a) => a.id === e.target.value);
            setAccount(selectedAccount);
            selectedAccount &&
              setDescription(
                selectedAccount.accountName?.replace(/\sReceivables/, '') + ' ' + descriptionPostfix ?? '',
              );
          }}
          labelId="account-label"
          id="account"
          label="Account"
        >
          {accounts &&
            accounts.map((a) => (
              <MenuItem key={a.id} value={a.id}>
                {a.accountName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <DialogLayout
      sx={{ width: '100%' }}
      maxWidth="lg"
      PaperProps={{ sx: { width: '100%' } }}
      onClose={onClose}
      title={title}
      {...dialogProps}
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <StatementView
              statement={statement.selectedStatement.value}
              loading={statement.selectedStatement.loading}
              submitting={leaseTransaction?.leaseTransaction.submitting}
              skipTimeControls
              statementPeriod={statementPeriod}
            />
            {
              {
                'Accept Deposit': (
                  <>
                    <SelectPaymentType />
                    {type === PaymentType.Check && (
                      <TextField
                        required
                        hidden={type !== PaymentType.Check}
                        label={'Check Number'}
                        value={paymentNo}
                        onChange={(e) => setPaymentNo(e.target.value)}
                      />
                    )}
                  </>
                ),
                'Accept Payment': (
                  <>
                    <SelectPaymentType />
                    {type === PaymentType.Check && (
                      <TextField
                        required
                        hidden={type !== PaymentType.Check}
                        label={'Check Number'}
                        value={paymentNo}
                        onChange={(e) => setPaymentNo(e.target.value)}
                      />
                    )}
                  </>
                ),
                'Apply Charge': <SelectAccount accounts={revenueAccounts ?? []} descriptionPostfix={'Charge'} />,
                'Apply Credit': <SelectAccount accounts={arAccounts ?? []} descriptionPostfix={'Credit'} />,
                'Rectify Deposit': (
                  <>
                    <Typography>Deposit Funds Available: ${amount}</Typography>
                    <Typography variant={'body2'}>
                      *Rectify deposit will release deposit funds to return to tenant or apply charges towards.
                    </Typography>
                  </>
                ),
                'Return Funds': (
                  <>
                    <SelectPaymentType />
                    {type === PaymentType.Check && (
                      <TextField
                        required
                        hidden={type !== PaymentType.Check}
                        label={'Check Number'}
                        value={paymentNo}
                        onChange={(e) => setPaymentNo(e.target.value)}
                      />
                    )}
                    {title === 'Return Funds' && type === PaymentType.Check && (
                      <AccountAutocomplete
                        value={account}
                        propertyId={property.selectedProperty!.value!.id!}
                        onChange={setAccount}
                        accountType={AccountType.Assets}
                        accountFilter={(account) =>
                          account.accountName === 'Cash' ||
                          (account.linkedPaymentAccountType === LinkedPaymentAccountType.BankAccount ?? false)
                        }
                        renderInput={(params) => (
                          <TextField margin="dense" variant="standard" label="From Account" {...params} />
                        )}
                      />
                    )}
                    <Typography>Funds To Return: ${amount}</Typography>
                  </>
                ),
                'Log Returned Funds': (
                  <Box marginLeft={-2}>
                    <Grid container spacing={2}>
                      <Grid item xs={type === PaymentType.Check ? 6 : 12}>
                        <SelectPaymentType />
                      </Grid>
                      {type === PaymentType.Check && (
                        <Grid item xs={6}>
                          <TextField
                            required
                            fullWidth
                            margin={'none'}
                            hidden={type !== PaymentType.Check}
                            label={'Check Number'}
                            value={paymentNo}
                            onChange={(e) => setPaymentNo(e.target.value)}
                          />
                        </Grid>
                      )}
                      {type === PaymentType.Check && (
                        <Grid item xs={6}>
                          <AccountAutocomplete
                            propertyId={property.selectedProperty!.value!.id!}
                            value={account}
                            onChange={setAccount}
                            accountType={AccountType.Assets}
                            accountFilter={(account) =>
                              account.linkedPaymentAccountType === LinkedPaymentAccountType.BankAccount ?? false
                            }
                            renderInput={(params) => (
                              <TextField margin="dense" variant="standard" label="From Account" {...params} />
                            )}
                          />
                        </Grid>
                      )}
                      <Grid item xs={type === PaymentType.Check ? 6 : 12}>
                        <AccountAutocomplete
                          propertyId={property.selectedProperty!.value!.id!}
                          value={secondaryAccount}
                          onChange={setSecondaryAccount}
                          accountType={AccountType.Assets}
                          accountFilter={(account) => account.subAccountType === SubAccountType.AccountsReceivable}
                          renderInput={(params) => (
                            <TextField margin="dense" variant="standard" label="Rent Account" {...params} />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ),
              }[title]
            }
            {title !== 'Rectify Deposit' && title !== 'Return Funds' && (
              <>
                <TextField label="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
                <NumberValueTextField
                  required
                  valueUnits={'dollars'}
                  label="Amount"
                  value={amount}
                  type="number"
                  onChange={(e) => setAmount(e.target.value)}
                />
              </>
            )}
          </Stack>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <LoadingButton type="submit" variant={'contained'} loading={leaseTransaction?.leaseTransaction.submitting}>
              {title}
            </LoadingButton>
          </DialogActions>
        </form>
      </DialogContent>
    </DialogLayout>
  );
};
