import React from 'react';
import { StripedDataGrid } from '../../../datagrids/StripedDataGrid';
import { GridColDef, GridRenderCellParams, GridRowId } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import { RenderGridCellExpand } from '../../../datagrids/RenderGridCellExpand';
import CheckIcon from '@mui/icons-material/Check';
import { useNotifications } from '@monkeyjump-labs/cam-fe-shared/dist/redux/notifications/notificationSlice';
import {
  getNotificationConfigNotifiedUsers,
  getSupportedRoleUserOptions,
  ReduxNotificationConfiguration,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/notificationTypes';
import { OpenEditCell } from '../../../datagrids/OpenEditCell';

type Props = {
  notifications: ReduxNotificationConfiguration[];
  onOpen: () => void;
  onSelectNotification: (id: GridRowId) => void;
};

export const ConfigurationTable = ({ notifications, onOpen, onSelectNotification }: Props) => {
  const notificationConfig = useNotifications();

  const handleOpenConfigModal = (id: GridRowId) => {
    onSelectNotification(id);
    onOpen();
  };

  const getUsersToNotify = (value: string, row: ReduxNotificationConfiguration) => {
    let userString = '';
    const supportedRoles =
      notificationConfig.notificationSupportedRoles.value &&
      getSupportedRoleUserOptions(notificationConfig.notificationSupportedRoles.value.supportedRoles);
    const userValueArray = supportedRoles && getNotificationConfigNotifiedUsers(supportedRoles, row);
    if (userValueArray) {
      for (let i = 0; i < userValueArray.length; i++) {
        userString = userString + userValueArray[i].label + ', ';
      }
    }
    userString = userString.replace(/,\s*$/, '');
    return `${userString}`;
  };

  const getValueThreshold = (value?: number) => {
    if (value === 0) return '';
    else return value;
  };

  const columns: GridColDef<ReduxNotificationConfiguration>[] = [
    {
      field: 'code',
      type: 'string',
      headerName: 'Code',
      flex: 0.5,
    },
    {
      field: 'name',
      type: 'string',
      headerName: 'Name',
      flex: 1.25,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<ReduxNotificationConfiguration, string>) => {
        return <OpenEditCell onClick={() => handleOpenConfigModal(params.id)} {...params} />;
      },
    },
    {
      field: 'description',
      type: 'string',
      headerName: 'Description',
      flex: 1.5,
      renderCell: (params: GridRenderCellParams<ReduxNotificationConfiguration, string>) => {
        return <RenderGridCellExpand {...params} />;
      },
    },
    {
      field: 'valueThreshold',
      headerName: 'Value Threshold',
      flex: 0.75,
      align: 'center',
      valueGetter: getValueThreshold,
    },
    {
      field: 'notifiedUsers',
      type: 'string',
      headerName: 'Notified Users',
      align: 'center',
      flex: 0.75,
      valueGetter: getUsersToNotify,
      renderCell: (params: GridRenderCellParams<ReduxNotificationConfiguration, string>) => {
        return <RenderGridCellExpand {...params} />;
      },
    },
    {
      field: 'enabled',
      type: 'string',
      headerName: 'Enabled',
      align: 'center',
      flex: 0.5,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<ReduxNotificationConfiguration, boolean>) => {
        if (params.value == true) {
          return <CheckIcon />;
        } else return '';
      },
    },
  ];

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: 600,
        width: 1,
        '& .super-app-theme--inactive': {
          color: 'lightgray',
        },
      }}
    >
      <StripedDataGrid
        disableRowGrouping
        hideFooter
        sx={{ height: '100%', minHeight: 600 }}
        loading={notificationConfig.notificationConfiguration.loading}
        columns={columns}
        rows={notifications ?? []}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        getRowId={(params) => params.code}
        pageSizeOptions={[10]}
      />
    </Box>
  );
};
