import React, { FC, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { StatementPeriodType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { isPeriod, Period } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

export type StatementMonthSelectorProps = {
  periods?: Period[];
  selectedPeriod?: Period;
  onPeriodSelection?: (period: Period) => void;
  selectedPeriodType?: StatementPeriodType;
};

export const StatementPeriodSelector: FC<StatementMonthSelectorProps> = ({
  periods,
  selectedPeriod,
  onPeriodSelection,
  selectedPeriodType,
}) => {
  const [periodType, setPeriodType] = React.useState<StatementPeriodType | null>(
    selectedPeriodType ?? StatementPeriodType.Month,
  );

  useEffect(() => {
    setPeriodType(selectedPeriodType ?? StatementPeriodType.Month);
  }, [selectedPeriodType]);

  const handlePeriodSelection = (e: SelectChangeEvent) => {
    if (isPeriod(e.target.value)) onPeriodSelection && onPeriodSelection(e.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={`select-${periodType}-statement`}>Select {periodType}:</InputLabel>
      <Select
        labelId={`select-${periodType}-statement`}
        id={`select-${periodType}-statement`}
        value={selectedPeriod ?? ''}
        label={`Select${periodType}:`}
        onChange={(e) => handlePeriodSelection(e)}
      >
        {periods &&
          periods.map((period) => {
            return (
              <MenuItem key={period.valueOf()} value={period}>
                {period}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};
