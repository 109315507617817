import { all, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  generateReportAction,
  generateReportFailAction,
  generateReportSuccessAction,
  getAdditionalReportDataAction,
  getAdditionalReportDataFailAction,
  getAdditionalReportDataSuccessAction,
  getAvailableReportsAction,
  getAvailableReportsFailAction,
  getAvailableReportsSuccessAction,
} from './reportSlice';
import {
  GenerateReportHandlerResponse,
  GetAdditionalReportDataHandlerRequest,
  ListAvailableReportsHandlerResponse,
  ReportData,
  ReportRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { showErrorAction, showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';

function* getAvailableReports(action: PayloadAction<string>) {
  try {
    const response: ListAvailableReportsHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().reports_GetAvailableReports,
      action.payload,
    );
    if (response) {
      yield put(getAvailableReportsSuccessAction(response.toJSON().availableReports));
    } else {
      yield put(
        showToastMessageAction({ message: 'Something went wrong fetching available reports', severity: 'error' }),
      );
    }
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem fetching available reports' }));
    yield put(getAvailableReportsFailAction());
  }
}

function* generateReport(
  action: PayloadAction<{ propertyId: string; reportName: string; data: any; grouping?: string }>,
) {
  try {
    const request = new ReportRequest({
      reportName: action.payload.reportName,
      data: action.payload.data,
      grouping: action.payload.grouping,
    });
    const response: GenerateReportHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().reports_GenerateReport,
      action.payload.propertyId,
      request,
    );
    if (response) {
      yield put(generateReportSuccessAction(response.toJSON().data));
    } else {
      yield put(showToastMessageAction({ message: 'Something went wrong generating report', severity: 'error' }));
    }
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem generating report' }));
    yield put(generateReportFailAction());
  }
}

function* getAdditionalReportData(action: PayloadAction<{ propertyId: string; data: any }>) {
  try {
    const request = new GetAdditionalReportDataHandlerRequest(action.payload.data);
    const response: ReportData = yield apiCall(
      ApiClientSingleton.getInstance().reports_GetAdditionalReportData,
      action.payload.propertyId,
      request,
    );
    if (response) {
      yield put(getAdditionalReportDataSuccessAction(response.toJSON()));
    } else {
      yield put(
        showToastMessageAction({
          message: 'Something went wrong generating additional report',
          severity: 'error',
        }),
      );
    }
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem fetching additional report data' }));
    yield put(getAdditionalReportDataFailAction());
  }
}

export function* reportSaga() {
  yield all([
    takeLatest(generateReportAction.type, generateReport),
    takeLatest(getAvailableReportsAction.type, getAvailableReports),
    takeLatest(getAdditionalReportDataAction.type, getAdditionalReportData),
  ]);
}
