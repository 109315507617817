import React from 'react';
import { GridRenderCellParams, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { TaskCategoryAutocomplete } from '../../TaskCategoryAutocomplete';
import { ITaskCategory } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Typography from '@mui/material/Typography';

type CategoryCellProps = {
  isScheduled?: boolean;
} & GridRenderCellParams;

export const CategoryCell = ({ isScheduled, ...props }: CategoryCellProps) => {
  if (isScheduled) {
    if (!props.row.task.category) {
      return (
        <Typography variant={'body2'} fontStyle={'italic'} sx={{ color: 'gray' }}>
          Uncategorized
        </Typography>
      );
    } else return <>{props.row.task.category.name}</>;
  } else if (!props.value) {
    return (
      <Typography variant={'body2'} fontStyle={'italic'} sx={{ color: 'gray' }}>
        Uncategorized
      </Typography>
    );
  }
  return <>{props.value.name}</>;
};

type CategoryEditCellProps = {
  onChange: (value: ITaskCategory | null, taskId: string) => void;
  isScheduled?: boolean;
} & GridRenderEditCellParams;

export const CategoryEditCell = ({ onChange, isScheduled, ...props }: CategoryEditCellProps) => {
  const { id, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (value: ITaskCategory | null) => {
    onChange(value, props.row.id);
    apiRef.current.setEditCellValue({ id, field, value: value ?? undefined });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <TaskCategoryAutocomplete
      value={isScheduled ? props.row.task.category : props.value}
      onChange={(e) => handleChange(e)}
    />
  );
};
