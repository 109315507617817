import React, { FormEvent, useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { IVehicle } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useApplications } from '../../../../redux/applicationSlice';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';

type Props = {
  addVehicle: (type: string, newVehicle: IVehicle) => void;
  open: boolean;
  onClose: () => void;
};

const blankVehicle = {
  make: '',
  model: '',
  year: undefined,
};

export const AddVehicleDialog = ({ addVehicle, open, onClose }: Props) => {
  const { allApplications } = useApplications();
  const dispatch = useDispatch();
  const [dirty, setDirty] = useState(false);
  const [newVehicle, setNewVehicle] = useState<IVehicle>(blankVehicle);

  useEffect(() => {
    if (allApplications.submitted) handleClose();
  }, [allApplications.submitted]);

  const handleUpdateVehicle = (key: keyof IVehicle, value: string) => {
    setNewVehicle({ ...newVehicle, [key]: value });
    setDirty(true);
  };

  const handleClose = () => {
    onClose();
    setNewVehicle(blankVehicle);
  };

  const handleAddVehicle = (e: FormEvent) => {
    e.preventDefault();
    if (newVehicle.year) {
      if (
        newVehicle.year?.toString().length !== 4 ||
        newVehicle.year > new Date().getFullYear() ||
        newVehicle.year < 1950
      ) {
        dispatch(showToastMessageAction({ message: 'Please enter a valid year', severity: 'error' }));
      } else {
        setDirty(false);
        addVehicle('vehicles', newVehicle);
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleAddVehicle} autoComplete="off">
        <DialogTitle variant="h6">Add Vehicle</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                fullWidth
                required
                margin="dense"
                id="make"
                label="Make"
                type="text"
                variant="standard"
                value={newVehicle.make ? newVehicle.make : ''}
                onChange={(e) => handleUpdateVehicle('make', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                margin="dense"
                id="model"
                label="Model"
                type="text"
                variant="standard"
                value={newVehicle.model ? newVehicle.model : ''}
                onChange={(e) => handleUpdateVehicle('model', e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required
                margin="dense"
                id="color"
                label="Color"
                type="text"
                variant="standard"
                value={newVehicle.color ? newVehicle.color : ''}
                onChange={(e) => handleUpdateVehicle('color', e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required
                margin="dense"
                id="year"
                label="Year"
                type="number"
                variant="standard"
                value={newVehicle.year ? newVehicle.year : ''}
                onChange={(e) => handleUpdateVehicle('year', e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required
                margin="dense"
                id="licensePlate"
                label="License Plate"
                type="text"
                variant="standard"
                value={newVehicle.licensePlate ? newVehicle.licensePlate : ''}
                onChange={(e) => handleUpdateVehicle('licensePlate', e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <LoadingButton type="submit" variant="contained" disabled={!dirty} loading={allApplications.submitting}>
            Add Vehicle
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
