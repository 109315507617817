import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import React, { FC, useEffect } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { AssetType, IAssetNode } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export type AssetAutocompleteProps = {
  value: AssetOption | null;
  onChange: (value: AssetOption | null) => unknown;
  inputProps: TextFieldProps;
  assetFilter?: (asset: IAssetNode) => boolean;
} & Omit<
  AutocompleteProps<[string, string?, string?], false, false, false>,
  'options' | 'value' | 'onChange' | 'getOptionLabel' | 'renderInput'
>;

export type AssetOption = {
  propertyName: string;
  propertyId: string;
  buildingName?: string;
  buildingId?: string;
  unitName?: string;
  unitId?: string;
  id: string;
  node: IAssetNode;
};

export const AssetAutocomplete: FC<AssetAutocompleteProps> = (
  { value, onChange, inputProps, assetFilter },
  ...props
) => {
  const { allAssets } = useAssets();
  const [options, setOptions] = React.useState<AssetOption[]>([]);
  const [loading, setLoading] = React.useState(allAssets.loading);

  function* buildOptions(assets: IAssetNode[], ancestors: IAssetNode[] = []): Generator<AssetOption> {
    const options: AssetOption[] = [];
    for (const asset of assets) {
      if (typeof assetFilter !== 'undefined' && !assetFilter(asset)) continue;
      yield {
        id: asset.id!,
        propertyName: asset.assetNodeType === AssetType.RentalProperty ? asset.name! : ancestors[0].name!,
        propertyId: asset.assetNodeType === AssetType.RentalProperty ? asset.id! : ancestors[0].id!,
        buildingName: asset.assetNodeType === AssetType.Building ? asset.name : (ancestors[1]?.name ?? undefined),
        buildingId: asset.assetNodeType === AssetType.Building ? asset.id : (ancestors[1]?.id ?? undefined),
        unitName: asset.assetNodeType === AssetType.BuildingUnit ? asset.name : (ancestors[2]?.name ?? undefined),
        unitId: asset.assetNodeType === AssetType.BuildingUnit ? asset.id : (ancestors[2]?.id ?? undefined),
        node: asset,
      };
      for (const child of buildOptions(asset.children ?? [], [...ancestors, asset])) {
        yield child;
      }
    }
    return options;
  }

  useEffect(() => {
    setLoading(allAssets.loading || !allAssets.value);
    if (allAssets.value) {
      setOptions([...buildOptions(allAssets.value)]);
    }
  }, [allAssets.loading, allAssets.value]);

  const formatParentPath = (asset: AssetOption): string | undefined => {
    if (asset.node.assetNodeType === AssetType.BuildingUnit) {
      return `${asset.propertyName} > ${asset.buildingName}`;
    }
    if (asset.node.assetNodeType === AssetType.Building) {
      return `${asset.propertyName}`;
    }
    return undefined;
  };

  return loading ? (
    <Skeleton variant="rectangular" height={40} />
  ) : (
    <Autocomplete
      aria-label="asset-selector"
      value={value ?? null}
      options={options}
      getOptionLabel={(option) => option.unitName ?? option.buildingName ?? option.propertyName}
      renderOption={(props, option, { index }) => {
        return (
          <React.Fragment key={option.id}>
            {index > 0 && <Divider />}
            <ListItem {...props}>
              <Stack>
                {formatParentPath(option) ? (
                  <Typography variant={'body2'} fontStyle={'italic'}>
                    {formatParentPath(option)}
                  </Typography>
                ) : null}
                <Typography>{option.unitName ?? option.buildingName ?? option.propertyName}</Typography>
              </Stack>
            </ListItem>
          </React.Fragment>
        );
      }}
      renderInput={(params) => <TextField variant="standard" {...inputProps} {...params} label="Asset" />}
      onChange={(_, v) => onChange(v)}
      {...props}
    />
  );
};
