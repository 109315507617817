import React, { FC, useEffect, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { appTheme } from '../../../../../../../../AppTheme';
import { CustomizedDot } from './CustomizedDot';
import SquareIcon from '@mui/icons-material/Square';
import StarIcon from '@mui/icons-material/Star';
import { HistoricalFinancialData } from './formatHistoricalData';

type HistoricalFinancialMetricsProps = {
  financialMetrics: HistoricalFinancialData[];
};

export const HistoricalFinancialMetrics: FC<HistoricalFinancialMetricsProps> = ({ financialMetrics }) => {
  const [isHidden, setIsHidden] = useState({
    collections: false,
    delinquency: false,
    eviction: false,
  });
  const [dataVersion, setDataVersion] = useState(0);

  useEffect(() => {
    setDataVersion((value) => value + 1);
  }, [financialMetrics]);

  // Note: the signature type should be DataKey, but for some reason
  //       recharts isn't letting us import that type
  //       See issue: https://github.com/recharts/recharts/issues/3619
  const handleLegendClick = (dataKey?: string | unknown) => {
    switch (dataKey) {
      case 'Monthly Collections':
        setIsHidden({ ...isHidden, collections: !isHidden.collections });
        break;
      case 'Delinquency':
        setIsHidden({ ...isHidden, delinquency: !isHidden.delinquency });
        break;
      case 'Delinquency For Evicted Units':
        setIsHidden({ ...isHidden, eviction: !isHidden.eviction });
        break;
      default:
        return;
    }
  };

  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart
        width={350}
        height={350}
        data={financialMetrics}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        {/*<YAxis label={{ value: 'Dollars', angle: -90, position: 'insideLeft' }} />*/}
        <YAxis />
        <Tooltip />
        <Legend onClick={(e) => handleLegendClick(e.dataKey)} />
        <Line
          type="monotone"
          dataKey="Monthly Collections"
          stroke={appTheme.palette.secondary.dark}
          fill="#8884d8"
          hide={isHidden.collections}
          connectNulls
          key={`collections-${dataVersion}`}
        />
        <Line
          type="monotone"
          dataKey="Delinquency"
          stroke={appTheme.palette.warning.dark}
          fill="#8884d8"
          legendType={'square'}
          dot={<CustomizedDot icon={<SquareIcon color={'warning'} />} />}
          hide={isHidden.delinquency}
          connectNulls
          key={`delinquency-${dataVersion}`}
        />
        <Line
          type="monotone"
          dataKey="Delinquency For Evicted Units"
          stroke={appTheme.palette.error.main}
          fill="#8884d8"
          legendType={'star'}
          dot={<CustomizedDot icon={<StarIcon color={'error'} />} />}
          hide={isHidden.eviction}
          connectNulls
          key={`eviction-${dataVersion}`}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
