import React, { FC, useState } from 'react';
import { setShortCodeAction, useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { useDispatch } from 'react-redux';
import { ConfigurationLayout } from './ConfigurationLayout';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';

export const PropertyShortCodeConfiguration: FC = () => {
  const property = useProperty();
  const dispatch = useDispatch();
  const selectedPropertyShortCode = property.selectedProperty.value?.shortCode;
  const [propertyShortCode, setPropertyShortCode] = useState(selectedPropertyShortCode ?? '');

  const handleChangeShortCode = () => {
    property.selectedProperty?.value?.id &&
      dispatch(setShortCodeAction({ propertyId: property.selectedProperty.value.id, shortCode: propertyShortCode }));
  };
  return (
    <ConfigurationLayout title={'Property Short Code Configuration'}>
      <Typography variant={'subtitle2'}>
        This will be a shortened identifier for your property. Note that if you change this value, it may break
        connection to some links.
      </Typography>
      <Typography variant={'subtitle2'}>Your data will not be erased even if you change this value.</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: '1rem' }}>
        <Typography>Property Short Code</Typography>
        <Box sx={{ display: 'flex', flexGrow: 1 }}></Box>
        <TextField
          value={propertyShortCode ?? ''}
          sx={{ minWidth: '40%' }}
          variant={'outlined'}
          onChange={(e) => setPropertyShortCode(e.target.value)}
        />
        <Typography sx={{ pl: '.1rem' }} />
      </Box>
      <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
        <LoadingButton
          variant={'contained'}
          onClick={handleChangeShortCode}
          loading={property.selectedProperty.submitting}
        >
          Set Short Code
        </LoadingButton>
      </Box>
    </ConfigurationLayout>
  );
};
