import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createUnitAction, useUnit } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/unitSlice';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  IAddUnitHandlerRequest,
  IBuildingUnitDto,
  IUnitInfo,
  IUnitTemplate,
  UnitStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { StyledInfoBox } from '../../../_shared/styledComponents/StyledInfoBox';
import LoadingButton from '@mui/lab/LoadingButton';
import { UnitInformationForm } from '../../../unit/components/UnitInformationForm';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {
  listAvailableUnitTemplatesAction,
  useUnitTemplates,
} from '../../../_shared/unitTemplates/redux/unitTemplateSlice';

type Props = {
  buildingId: string | undefined;
  open: boolean;
  onClose: () => any;
};

export const AddUnit = ({ onClose, open, buildingId }: Props) => {
  const dispatch = useDispatch();
  const { selectedUnit } = useUnit();
  const { availableTemplates } = useUnitTemplates();
  const [templates, setTemplates] = useState<IUnitTemplate[]>([]);
  const [newUnit, setNewUnit] = useState<IAddUnitHandlerRequest>({
    name: '',
    buildingId: buildingId,
    info: {},
    status: UnitStatus.RentReady,
  });

  useEffect(() => {
    setNewUnit({ ...newUnit, buildingId: buildingId });
    if (buildingId && open) dispatch(listAvailableUnitTemplatesAction({ buildingId: buildingId }));
  }, [buildingId, open]);

  useEffect(() => {
    if (availableTemplates.loaded) {
      setTemplates(availableTemplates.value ?? []);
    }
  }, [availableTemplates.loaded]);

  useEffect(() => {
    if (selectedUnit.submitted) {
      setNewUnit({
        name: '',
        buildingId: buildingId,
        info: {},
        status: UnitStatus.RentReady,
      });
      onClose();
    }
  }, [selectedUnit.submitted]);

  const handleChangeField = (
    field: keyof IBuildingUnitDto | keyof IUnitInfo | keyof IAddUnitHandlerRequest,
    value: string,
  ) => {
    let updatedUnit = newUnit;

    if (field === 'name' || field === 'status') {
      updatedUnit = { ...updatedUnit, [field]: value };
    } else {
      updatedUnit = { ...updatedUnit, info: { ...updatedUnit.info, [field]: value } };
    }

    setNewUnit(updatedUnit);
  };

  const addUnit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(createUnitAction(newUnit));
  };

  const handleChangeTemplate = (e: SelectChangeEvent<{ value: string }>) => {
    const updatedValue = e.target.value as string | undefined;

    const template = templates.find((template) => template.id === updatedValue) ?? {
      info: { bedrooms: undefined, bathrooms: undefined, squareFootage: undefined },
    };

    const updatedUnit = {
      templateId: updatedValue,
      ...newUnit,
    };

    if (template.info?.bedrooms !== undefined || template.id === undefined)
      updatedUnit.info = { ...updatedUnit.info, bedrooms: template.info?.bedrooms };

    if (template.info?.bathrooms !== undefined || template.id === undefined)
      updatedUnit.info = { ...updatedUnit.info, bathrooms: template.info?.bathrooms };

    if (template.info?.squareFootage !== undefined || template.id === undefined)
      updatedUnit.info = { ...updatedUnit.info, squareFootage: template.info?.squareFootage };

    setNewUnit(updatedUnit);
  };

  return (
    <DialogLayout title={'Add Unit'} maxWidth="md" fullWidth={true} open={open} onClose={onClose}>
      <DialogContent>
        <form onSubmit={addUnit} autoComplete="off">
          <StyledInfoBox label={'Unit Info'}>
            {templates && templates.length > 0 && (
              <FormControl fullWidth>
                <InputLabel>Template</InputLabel>
                <Select value={undefined} label={'Template'} onChange={handleChangeTemplate}>
                  <MenuItem value={undefined}>None</MenuItem>
                  {templates.map((template) => (
                    <MenuItem key={template.id} value={template.id}>
                      {template.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <UnitInformationForm value={newUnit} onChange={handleChangeField} newUnit />
          </StyledInfoBox>
          <DialogActions sx={{ marginTop: '1rem' }}>
            <Button onClick={onClose}>Close Without Saving</Button>
            <LoadingButton type="submit" variant="contained" loading={selectedUnit.submitting}>
              Add Unit
            </LoadingButton>
          </DialogActions>
        </form>
      </DialogContent>
    </DialogLayout>
  );
};
