import React, { ReactSVGElement } from 'react';

type CustomizedDotProps = {
  large: boolean;
  icon: ReactSVGElement;
} & any;
//TODO: type props further; LineChartProps imports like it exists but then causes build to fail

export const CustomizedDot = (props: CustomizedDotProps) => {
  const { cx, cy, icon, large, payload } = props;
  if (payload.valuesAreNull) {
    return <></>;
  } else if (large)
    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={20} viewBox="0 0 1024 1024">
        {icon}
      </svg>
    );
  else
    return (
      <svg x={cx - 5} y={cy - 5} width={10} height={10} viewBox="0 0 1024 1024">
        {icon}
      </svg>
    );
};
