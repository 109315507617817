import React, { useEffect, useState } from 'react';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import { updateApplicationAction, uploadDocumentAction } from '../../../../redux/applicationSlice';
import {
  ApplicationType,
  IContactInfo,
  IEmployment,
  IPostalAddress,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { PhoneTextField } from '../../../../../_shared/texfields/PhoneTextField';
import Typography from '@mui/material/Typography';
import { FileUploadButton } from '../../../../../_shared/buttons/FileUploadButton';
import { useDispatch } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ReduxApplication } from '../../../../redux/applicationTypes';

type Props = {
  applicant: ReduxApplication;
  onUpdateEmployment: (key: keyof IEmployment, value: string | boolean) => void;
  onUpdateEmployerContact: (key: keyof IContactInfo, value: string) => void;
  onUpdateEmployerAddress: (key: keyof IPostalAddress, value: string) => void;
  onUpdateApplicant: (key: keyof ReduxApplication, value: string) => void;
  updateApplicantDetails: () => void;
  editingInWizard?: boolean;
};

export const EmploymentInfo = ({
  applicant,
  onUpdateEmployment,
  onUpdateEmployerContact,
  onUpdateEmployerAddress,
  onUpdateApplicant,
  updateApplicantDetails,
  editingInWizard,
}: Props) => {
  const dispatch = useDispatch();
  const [wageAmount, setWageAmount] = useState<number>(
    !(!applicant.annualIncome || applicant.annualIncome < 0) ? applicant.annualIncome / 12 : 0,
  );
  const [wageFrequency, setWageFrequency] = useState<string>('monthly');
  const [incomeError, setIncomeError] = useState(false);

  const handleUpload = (file: File) => {
    updateApplicantDetails();
    if (applicant.id && applicant.applicationType) {
      dispatch(
        updateApplicationAction({
          id: applicant.id,
          type: applicant.applicationType,
          body: applicant,
        }),
      );
    }
    if (applicant.id && applicant.rentalPropertyId && file) {
      dispatch(uploadDocumentAction({ applicationId: applicant.id, file }));
    }
  };

  const validateIncome = () => {
    if (wageAmount < 0 || wageAmount === null) {
      setIncomeError(true);
    } else {
      setIncomeError(false);
    }
  };

  const calculateAnnualIncome = (wages: number, frequency: string) => {
    switch (frequency) {
      case 'weekly':
        return wages * 52;
      case 'everyOtherWeek':
        return wages * 26;
      case 'biMonthly':
        return wages * 24;
      case 'monthly':
        return wages * 12;
      case 'yearly':
      default:
        return wages;
    }
  };

  useEffect(() => {
    const annualIncome = calculateAnnualIncome(wageAmount, wageFrequency);
    if (annualIncome !== applicant.annualIncome) {
      onUpdateApplicant('annualIncome', annualIncome.toString());
    }
  }, [wageFrequency, wageAmount]);

  return (
    <StyledInfoBox label={'Current Employment'}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            autoFocus
            fullWidth
            required={editingInWizard && applicant.applicationType !== ApplicationType.OtherAdult}
            margin="dense"
            id="companyName"
            label="Employer"
            type="text"
            variant="standard"
            value={applicant?.employer?.companyName ? applicant.employer.companyName : ''}
            onChange={(e) => {
              onUpdateEmployment('companyName', e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required={editingInWizard && applicant.applicationType !== ApplicationType.OtherAdult}
            margin="dense"
            id="position"
            label="Position"
            type="text"
            variant="standard"
            value={applicant?.employer?.position ? applicant.employer.position : ''}
            onChange={(e) => {
              onUpdateEmployment('position', e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required={editingInWizard && applicant.applicationType !== ApplicationType.OtherAdult}
            margin="dense"
            id="duration"
            label="Employment Duration"
            type="text"
            variant="standard"
            value={applicant?.employer?.duration ? applicant.employer.duration : ''}
            onChange={(e) => {
              onUpdateEmployment('duration', e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required={editingInWizard && applicant.applicationType !== ApplicationType.OtherAdult}
            margin="dense"
            id="employerStreetAddress1"
            label="Employer Street Address 1"
            type="text"
            variant="standard"
            value={
              applicant?.employer?.contactInfo?.postalAddress?.streetAddress1
                ? applicant.employer?.contactInfo.postalAddress.streetAddress1
                : ''
            }
            onChange={(e) => {
              onUpdateEmployerAddress('streetAddress1', e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="dense"
            id="employerStreetAddress2"
            label="Employer Street Address 2"
            type="text"
            variant="standard"
            value={
              applicant?.employer?.contactInfo?.postalAddress?.streetAddress2
                ? applicant.employer?.contactInfo.postalAddress.streetAddress2
                : ''
            }
            onChange={(e) => {
              onUpdateEmployerAddress('streetAddress2', e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required={editingInWizard && applicant.applicationType !== ApplicationType.OtherAdult}
            margin="dense"
            id="employerCity"
            label="Employer City"
            type="text"
            variant="standard"
            value={
              applicant?.employer?.contactInfo?.postalAddress?.city
                ? applicant.employer?.contactInfo.postalAddress.city
                : ''
            }
            onChange={(e) => {
              onUpdateEmployerAddress('city', e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required={editingInWizard && applicant.applicationType !== ApplicationType.OtherAdult}
            margin="dense"
            id="employerState"
            label="Employer State"
            type="text"
            variant="standard"
            value={
              applicant?.employer?.contactInfo?.postalAddress?.state
                ? applicant.employer?.contactInfo.postalAddress.state
                : ''
            }
            onChange={(e) => {
              onUpdateEmployerAddress('state', e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required={editingInWizard && applicant.applicationType !== ApplicationType.OtherAdult}
            margin="dense"
            id="employerZip"
            label="Employer Zip"
            type="text"
            variant="standard"
            value={
              applicant?.employer?.contactInfo?.postalAddress?.zip
                ? applicant.employer?.contactInfo.postalAddress.zip
                : ''
            }
            onChange={(e) => {
              onUpdateEmployerAddress('zip', e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            margin="dense"
            id="contactName"
            label="Contact Name"
            type="text"
            variant="standard"
            value={applicant?.employer?.contactName ? applicant.employer.contactName : ''}
            onChange={(e) => {
              onUpdateEmployment('contactName', e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneTextField
            fullWidth
            required={false}
            margin="dense"
            id="phone"
            label="Contact Phone"
            type="tel"
            variant="standard"
            value={applicant?.employer?.contactInfo?.phone ? applicant.employer.contactInfo.phone : ''}
            onChange={(e) => {
              onUpdateEmployerContact('phone', e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={applicant.employer?.consentToContact ? applicant.employer?.consentToContact : false}
                onChange={(e) => {
                  onUpdateEmployment('consentToContact', e.target.checked);
                }}
              />
            }
            label="Consent to Contact Employer"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required={editingInWizard && applicant.applicationType !== ApplicationType.OtherAdult}
            fullWidth
            onBlur={validateIncome}
            error={incomeError}
            helperText={incomeError ? 'Please enter a valid income amount' : ''}
            margin="dense"
            id="wageAmount"
            label="Wage Amount"
            type="number"
            variant="standard"
            value={wageAmount || ''}
            onChange={(e) => {
              setWageAmount(Number(e.target.value));
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth required={editingInWizard && applicant.applicationType !== ApplicationType.OtherAdult}>
            <InputLabel id="wage-frequency">Wage Frequency</InputLabel>
            <Select
              labelId="wage-frequency"
              id="wageFrequency"
              value={wageFrequency}
              label="Wage Frequency"
              onChange={(e) => {
                setWageFrequency(e.target.value);
              }}
            >
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="everyOtherWeek">Every Other Week</MenuItem>
              <MenuItem value="biMonthly">Bi-Monthly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FileUploadButton onUpload={handleUpload}>Upload Paystub</FileUploadButton>
        </Grid>
        {!editingInWizard && (
          <Grid item xs={12}>
            <Typography variant="body2">Note: Paystubs will appear under the documents tab</Typography>
          </Grid>
        )}
      </Grid>
    </StyledInfoBox>
  );
};
