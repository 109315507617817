import React from 'react';
import { getAssetTypeFromPathname } from '../../utils/getAssetTypeFromPathname';
import { useParams } from 'react-router-dom';
import { AssetParams } from '../../../../AppRouter';
import { AssetNotificationDataGrid } from './assetNotifications/AssetNotificationDataGrid';
import { UseParentNotificationConfig } from './notificationConfig/UseParentNotificationConfig';
import { NotificationsConfiguration } from './notificationConfig/NotificationsConfiguration';

export const AssetNotificationsTab = () => {
  const asset = getAssetTypeFromPathname();
  const { id } = useParams<AssetParams>();
  if (!id) return <></>;
  return (
    <>
      <AssetNotificationDataGrid assetType={asset} assetId={id} />
      {asset === 'property' ? <NotificationsConfiguration assetId={id} /> : <UseParentNotificationConfig />}
    </>
  );
};
