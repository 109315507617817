import React, { FC } from 'react';
import { ExpenseElementType, invoiceStatusMap, ReduxInvoice } from '../../redux/expenseData';
import {
  AccountType,
  AssetType,
  IContactPerson,
  ISlimAccountRef,
  IVendor,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import { setInvoiceSubmittingValueAction } from '../../redux/expenseSlice';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { VendorAutocomplete } from '../VendorAutocomplete';
import { ExpenseStatusSelect } from '../ExpenseStatusSelect';
import { AccountAutocomplete } from '../../../autocompletes/AccountAutocomplete';
import { NumberValueTextField } from '../../../texfields/NumberValueTextField';
import { AssociateElementAutocomplete } from '../AssociateElementAutocomplete';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { UserAutocomplete } from '../../../autocompletes/UserAutocomplete';
import { VendorContactAutocomplete } from '../VendorContactAutocomplete';
import Box from '@mui/material/Box';

type JobInvoiceFormProps = {
  value: ReduxInvoice;
  onChangeInvoice: (field: keyof ReduxInvoice, value: string | number | undefined) => void;
  onChangeVendor: (value: IVendor | undefined) => void;
  propertyId: string;
  expenseAccount?: ISlimAccountRef;
  onChangeExpenseAccount: (account: ISlimAccountRef) => void;
  editingMode?: boolean;
  onAddNewVendor: (name: string) => void;
  onAssociatePurchaseOrder: (association?: string, vendor?: IVendor, propertyAccount?: ISlimAccountRef) => void;
  onChangeVendorContact?: (value: IContactPerson | undefined) => void;
};
export const InvoiceForm: FC<JobInvoiceFormProps> = ({
  value,
  onChangeInvoice,
  onChangeExpenseAccount,
  onChangeVendor,
  onAddNewVendor,
  expenseAccount,
  propertyId,
  editingMode,
  onAssociatePurchaseOrder,
  onChangeVendorContact,
}) => {
  const dispatch = useDispatch();
  const handleEditingModeAddNewVendor = (name: string) => {
    dispatch(setInvoiceSubmittingValueAction({ ...value, vendor: { name: name } }));
    onAddNewVendor(name);
  };

  return (
    <Box width={'100%'}>
      <Grid container spacing={2}>
        <Grid item xs={editingMode ? 6 : 12}>
          <TextField
            fullWidth
            required
            label="Name"
            value={value.name}
            variant={'outlined'}
            onChange={(e) => onChangeInvoice('name', e.target.value)}
          />
        </Grid>
        {editingMode && (
          <Grid item xs={editingMode ? 6 : 12}>
            <ExpenseStatusSelect
              value={value.expenseInvoiceStatus}
              onChange={(e) => onChangeInvoice('expenseInvoiceStatus', e.target.value)}
              statusMap={invoiceStatusMap}
              type={ExpenseElementType.Invoice}
            />
          </Grid>
        )}
        <Grid item xs={editingMode ? 6 : 12}>
          <AssociateElementAutocomplete
            value={value.associatedPurchaseOrder}
            type={ExpenseElementType.Invoice}
            onChange={onAssociatePurchaseOrder}
            renderInput={(params) => (
              <TextField margin="dense" variant="outlined" label="Associated Purchase Order" {...params} />
            )}
          />
        </Grid>
        <Grid item xs={editingMode ? 6 : 12}>
          <VendorAutocomplete
            propertyId={propertyId}
            onChange={onChangeVendor}
            renderInput={(params) => <TextField margin="dense" variant="outlined" label="Vendor" {...params} />}
            onAddNewVendor={editingMode ? handleEditingModeAddNewVendor : onAddNewVendor}
            value={value.vendor?.name}
          />
        </Grid>
        {value.vendor && (
          <Grid item xs={editingMode ? 6 : 12}>
            <VendorContactAutocomplete
              vendor={value.vendor}
              onChange={onChangeVendorContact}
              renderInput={(params) => (
                <TextField margin="dense" variant="outlined" label="Vendor Contact" {...params} />
              )}
              value={value.vendorContact ?? null}
            />
          </Grid>
        )}
        <Grid item xs={editingMode ? 6 : 12}>
          <DatePicker
            label={'Date Received'}
            onChange={(date: Date | null) => {
              if (date) onChangeInvoice('dateReceived', toReduxDate(date));
              else onChangeInvoice('dateReceived', '');
            }}
            slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
            value={value.dateReceived ? toStandardDate(value.dateReceived) : null}
          />
        </Grid>
        <Grid item xs={editingMode ? 6 : 12}>
          <DatePicker
            label={'Due Date'}
            onChange={(date: Date | null) => {
              if (date) onChangeInvoice('dueDate', toReduxDate(date));
              else onChangeInvoice('dueDate', '');
            }}
            slotProps={{ textField: { fullWidth: true, required: true }, field: { clearable: true } }}
            value={value.dueDate ? toStandardDate(value.dueDate) : null}
          />
        </Grid>
        {editingMode && (
          <Grid item xs={6}>
            <UserAutocomplete
              value={value.approvedBy}
              assetId={propertyId}
              assetType={AssetType.RentalProperty}
              onChange={(value?: string) => {
                onChangeInvoice('approvedBy', value);
              }}
              renderInput={(params) => <TextField margin="dense" variant="standard" label="Approved By" {...params} />}
            />
          </Grid>
        )}
        <Grid item xs={editingMode ? 6 : 12}>
          <AccountAutocomplete
            value={expenseAccount}
            propertyId={propertyId}
            onChange={onChangeExpenseAccount}
            accountType={AccountType.Expenses}
            accountFilter={(account) => account.accountName !== '4000'}
            renderInput={(params) => (
              <TextField required margin="dense" variant="standard" label="Expense Account" {...params} />
            )}
          />
        </Grid>
        <Grid item xs={editingMode ? 6 : 12}>
          <NumberValueTextField
            fullWidth
            required
            valueUnits={'dollars'}
            label="Amount"
            value={value.invoiceAmount !== 0 ? value.invoiceAmount : ''}
            type="number"
            onChange={(e) => onChangeInvoice('invoiceAmount', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Description"
            value={value.description}
            variant={'outlined'}
            multiline
            rows={3}
            onChange={(e) => onChangeInvoice('description', e.target.value)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
