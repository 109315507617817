import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { IContactPerson, IVendor } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type VendorDetailsCellProps = {
  vendor?: IVendor;
  contact?: IContactPerson;
} & GridRenderCellParams;
export const VendorDetailsCell: FC<VendorDetailsCellProps> = ({ vendor, contact, ...params }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'vendor-details' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  if (!vendor) return <></>;
  const internalContact = contact ?? vendor.primaryContact;
  return (
    <>
      <Tooltip title={`${vendor.name}`}>
        <Chip aria-describedby={params.row.number} onClick={handleClick} label={vendor.name}></Chip>
      </Tooltip>
      <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: 999999 }} placement={'bottom-end'}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Paper
            sx={{
              p: 2,
              minWidth: '10rem',
            }}
          >
            <Stack>
              {internalContact && (
                <Typography>
                  Contact: {internalContact.firstName} {internalContact.lastName}
                </Typography>
              )}
              <Typography> {internalContact?.contactInfo?.phone}</Typography>
              <Typography> {internalContact?.contactInfo?.email}</Typography>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
