import React, { FC, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IAddVendorHandlerRequest } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { VendorInfoForm } from './VendorInfoForm';

export type AddVendorDialogProps = {
  open: boolean;
  submitting?: boolean;
  onClose: () => void;
  onSubmit: (values: Omit<IAddVendorHandlerRequest, 'propertyId'>) => void;
};

export const AddVendorDialog: FC<AddVendorDialogProps> = ({ open, submitting, onClose, onSubmit }) => {
  const [vendor] = useState<Omit<IAddVendorHandlerRequest, 'propertyId'>>({});
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Vendor</DialogTitle>
      <DialogContent>
        <VendorInfoForm value={vendor} onSubmit={onSubmit} submitting={submitting} newOrEdit={'new'} />
      </DialogContent>
    </Dialog>
  );
};
