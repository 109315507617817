import React, { FC } from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ExpenseElementType } from '../../../redux/expenseData';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';

export const NameCell: FC<
  GridRenderCellParams & {
    onOpenDrawer: (number?: string, type?: ExpenseElementType) => void;
    type: ExpenseElementType;
  }
> = ({ onOpenDrawer, type, ...params }) => {
  return (
    <Link sx={{ cursor: 'pointer' }} color="inherit">
      <Typography variant={'body2'} onClick={() => onOpenDrawer(params.row.number!, type)}>
        {params.value}
      </Typography>
    </Link>
  );
};
