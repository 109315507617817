import React, { useEffect, useState } from 'react';
import { setSelectedGroupingAction, useReports } from '../../../../redux/reportSlice';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';

export const CustomizeGroupings = () => {
  const dispatch = useDispatch();
  const { selectedReport, selectedGrouping } = useReports();
  const [groupingOption, setGroupingOption] = useState<string | undefined>(selectedGrouping?.value);

  useEffect(() => {
    setGroupingOption(selectedGrouping?.value);
  }, [selectedGrouping.value]);

  const handleGroupingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedGroupingAction((event.target as HTMLInputElement).value));
  };

  return (
    <>
      {selectedReport?.value?.groupings && selectedReport.value.groupings.length > 0 && (
        <Box pl={1}>
          <FormControl>
            <RadioGroup value={groupingOption} onChange={handleGroupingChange}>
              {selectedReport?.value?.groupings?.map((f) => (
                <FormControlLabel key={f} control={<Radio />} label={f} value={f} />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      )}
    </>
  );
};
