import React, { FC, FormEvent, useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers-pro';
import LoadingButton from '@mui/lab/LoadingButton';
import { addDays } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { RootState } from '../../../../../app/store';
import { initLoadable } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { DialogLayout } from '../../../../_shared/dialogs/DialogLayout';
import { exportGlEntriesAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/documents/documentSlice';

type ExportGLCodesDialogProps = {
  open: boolean;
  onClose: () => void;
  propertyId: string;
};

export const ExportGLEntriesDialog: FC<ExportGLCodesDialogProps> = ({ open, onClose, propertyId }) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const glCodes = useSelector((state: RootState) => state.property?.glAccounts || initLoadable([]));

  useEffect(() => {
    glCodes.submitted && handleClose();
  }, [glCodes.submitted]);

  const handleSetStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const handleSetEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  const exportGLCodes = (e: FormEvent) => {
    e.preventDefault();
    dispatch(
      exportGlEntriesAction({
        propertyId,
        startDate: toReduxDate(startDate!),
        endDate: toReduxDate(endDate!),
      }),
    );
  };

  const handleClose = () => {
    setStartDate(null);
    setEndDate(null);
    onClose();
  };

  return (
    <DialogLayout title={'Export GL Entries'} maxWidth="md" fullWidth={true} open={open} onClose={handleClose}>
      <DialogContent>
        <form onSubmit={exportGLCodes}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                label={'Start Date'}
                slotProps={{ field: { clearable: false }, textField: { required: true, fullWidth: true } }}
                onChange={(date) => date && handleSetStartDate(toStandardDate(date))}
                value={startDate}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label={'End Date'}
                minDate={startDate ? addDays(startDate, 1) : undefined}
                slotProps={{ field: { clearable: false }, textField: { required: true, fullWidth: true } }}
                onChange={(date) => date && handleSetEndDate(toStandardDate(date))}
                value={endDate}
              />
            </Grid>
            <Grid item xs={12}>
              <DialogActions sx={{ marginTop: '1rem' }}>
                <Button onClick={handleClose}>Close</Button>
                <LoadingButton type="submit" variant="contained" loading={glCodes.submitting}>
                  Export Entries
                </LoadingButton>
              </DialogActions>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </DialogLayout>
  );
};
