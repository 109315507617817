import React from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessIcon from '@mui/icons-material/Business';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';

export const NotFound = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <Container sx={{ position: 'relative' }}>
        <Stack spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ mt: '1rem' }}>
          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
            <Typography variant={'h3'} sx={{ color: theme.palette.secondary.dark }}>
              404: We can't find the page you are looking for. <ApartmentIcon fontSize={'inherit'} />
              <BusinessIcon fontSize={'inherit'} />
              <HouseOutlinedIcon fontSize={'inherit'} />
            </Typography>
          </Box>
          <Divider />
          <Typography variant={'h6'}>Try one of these popular Smart Management links:</Typography>
          <Link href={'/assets/property'}>Property Management Home</Link>
          <Link href={'/tenantPortal'}>Tenant Portal</Link>
          <Stack spacing={1} direction={'row'}>
            <Typography>Or</Typography>
            <Link sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
              Return to Previous Page
            </Link>
          </Stack>
        </Stack>
      </Container>
      <Box
        sx={{
          backgroundColor: theme.palette.grey[200],
          p: 6,
          position: 'absolute',
          bottom: '0rem',
          left: '0rem',
          right: '0rem',
        }}
        component="footer"
      >
        <Container>
          <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © Smart Management '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Container>
      </Box>{' '}
    </>
  );
};
