import React, { FC, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Add from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';
import { AddCategoryDialog } from './AddCategoryDialog';
import {
  getAllCategoriesAction,
  updateCategoryColorAction,
  useCategories,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/categorySlice';
import Box from '@mui/material/Box';
import { CategoryTable } from './CategoryTable';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { ITaskCategoryDto } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import LinearProgress from '@mui/material/LinearProgress';

export const Categories: FC = () => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const { allCategories } = useCategories();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [categories, setCategories] = useState<ITaskCategoryDto[]>([]);

  useEffect(() => {
    selectedContext.propertyId &&
      dispatch(getAllCategoriesAction({ propertyId: selectedContext.propertyId, includeDisabled: true }));
  }, [selectedContext.propertyId]);

  useEffect(() => {
    setCategories(allCategories.value ?? []);
  }, [allCategories.value]);

  const handleClose = () => {
    setAddDialogOpen(false);
  };

  const openAddDialog = () => {
    setAddDialogOpen(true);
  };

  const handleChangeCategory = (categoryId: string, color: string) => {
    const newCategories = categories.map((c) => (c.id === categoryId ? { ...c, color: color } : c));
    setCategories(newCategories);
  };

  const handleSubmitCategory = (categoryId: string) => {
    const category = categories.find((c) => c.id === categoryId);
    if (category && category.color) {
      dispatch(updateCategoryColorAction({ categoryId: categoryId, color: category.color }));
    }
  };

  return (
    <StyledInfoBox label={'Task Categories'}>
      <Grid container direction="column" sx={{ width: '100%' }}>
        <Grid item container>
          <Button variant="outlined" startIcon={<Add />} onClick={openAddDialog}>
            Add Category
          </Button>
        </Grid>
        <Grid item>
          <Box width="100%" textAlign="center" minHeight="30px" paddingTop={'24px'}>
            {allCategories.loading && <LinearProgress />}
          </Box>
          <CategoryTable
            categories={categories ?? []}
            onChangeCategoryColor={handleChangeCategory}
            onSubmitCategory={handleSubmitCategory}
          />
        </Grid>
        {selectedContext.propertyId && (
          <AddCategoryDialog propertyId={selectedContext.propertyId} open={addDialogOpen} onClose={handleClose} />
        )}
      </Grid>
    </StyledInfoBox>
  );
};
