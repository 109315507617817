import React, { FC, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Box from '@mui/material/Box';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  ICamAssociation,
  SimpleDocumentType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import Tooltip from '@mui/material/Tooltip';
import { PanoramaViewer } from './PanoramaViewer';

type ImageViewerProps = {
  association?: ICamAssociation;
  documents: ReduxDocument[];
  selectedIndex?: number;
  open: boolean;
  onClose: () => void;
};

export const ImageViewer: FC<ImageViewerProps> = ({ open, onClose, documents, selectedIndex, association }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(selectedIndex ?? 0);
  const [openPanorama, setOpenPanorama] = useState(false);

  useEffect(() => {
    setCurrentIndex(selectedIndex ?? 0);
  }, [documents, selectedIndex]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : documents.length - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < documents.length - 1 ? prevIndex + 1 : 0));
  };

  const displayDocument: ReduxDocument | undefined =
    documents[currentIndex].metadata?.documentType === SimpleDocumentType.Image360 &&
    documents[currentIndex]?.children &&
    documents[currentIndex].children[0]
      ? documents[currentIndex]?.children[0]
      : documents[currentIndex];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: documents.length > 1 ? 'space-between' : 'center',
          p: 1,
        }}
      >
        {documents.length > 1 && (
          <IconButton onClick={handlePrevious}>
            <ArrowBack />
          </IconButton>
        )}
        {
          <img
            src={displayDocument.uri}
            alt={documents[currentIndex].name}
            style={{ maxWidth: documents.length > 1 ? '90%' : '100%' }}
          />
        }
        {documents.length > 1 && (
          <IconButton onClick={handleNext}>
            <ArrowForward />
          </IconButton>
        )}
      </Box>
      <Box>
        <Typography variant="body2" align="center" flexWrap={'wrap'}>
          {documents[currentIndex].metadata?.title}
        </Typography>
        <Typography variant="body2" align="center" flexWrap={'wrap'}>
          {documents[currentIndex].metadata?.description}
        </Typography>
        {documents[currentIndex].metadata?.documentType === SimpleDocumentType.Image360 && (
          <Box display={'flex'} flexGrow={1} justifyContent={'center'}>
            <Tooltip title={'View in 360'}>
              <IconButton onClick={() => setOpenPanorama(true)}>
                <CameraswitchIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      {association && (
        <PanoramaViewer
          association={association}
          document={documents[currentIndex]}
          open={openPanorama}
          onClose={() => setOpenPanorama(false)}
        />
      )}
    </Dialog>
  );
};
