import React, { FC, useEffect, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useDispatch } from 'react-redux';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';

type AddressMapProps = {
  address: string;
};

export const AddressMap: FC<AddressMapProps> = ({ address }) => {
  const dispatch = useDispatch();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({ lat: 0, lng: 0 });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: key,
  });

  useEffect(() => {
    if (isLoaded && address) {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          if (results && results[0]) {
            const location = results[0].geometry.location;
            setCenter({
              lat: location.lat(),
              lng: location.lng(),
            });
            map && map.panTo(location);
          } else {
            dispatch(showToastMessageAction({ message: 'No map results found', severity: 'warning' }));
          }
        } else {
          dispatch(showToastMessageAction({ message: 'Geocoder failed due to: ' + status, severity: 'error' }));
        }
      });
    }
  }, [isLoaded, address, map]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      onLoad={(map) => setMap(map)}
      onUnmount={() => setMap(null)}
    >
      <Marker position={center} key={`${center.lat}-${center.lng}`} />
    </GoogleMap>
  ) : (
    <></>
  );
};
