import React, { FC } from 'react';
import Chip from '@mui/material/Chip';
import { IDetailSpecification } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Box from '@mui/material/Box';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { Ivy, Persimmon, Rose, Sapphire } from '../../../../../AppTheme';
import { getBrightness, hexToRgb } from '../../../utils/colorUtils';

type SpecChipProps = {
  specification: IDetailSpecification;
  deletable: boolean;
  onDeleteSpecification?: (key: string) => void;
};

const colors = [...Object.values(Sapphire), ...Object.values(Persimmon), ...Object.values(Rose), ...Object.values(Ivy)];

const hashString = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return hash;
};

const selectColor = (str: string, colorArray: string[]): string => {
  const hash = hashString(str);
  const index = Math.abs(hash) % colorArray.length;
  return colorArray[index];
};

export const SpecChip: FC<SpecChipProps> = ({ specification, deletable, onDeleteSpecification }) => {
  if (!specification || !specification.key) return <></>;
  const bgColor = selectColor(specification.key, colors);
  const rgb = hexToRgb(bgColor);
  let textColor = '#000'; //initialize text color as black

  if (rgb) {
    const brightness = getBrightness(rgb);
    if (brightness < 128) {
      textColor = '#FFF'; // switch to white if dark
    }
  }

  return (
    <Box sx={{ p: 1, ml: '0px !important' }}>
      {deletable ? (
        <Chip
          onDelete={onDeleteSpecification}
          icon={<LocalOfferOutlinedIcon style={{ color: textColor }} fontSize={'small'} />}
          label={specification.key + ': ' + specification.value}
          style={{ backgroundColor: bgColor, color: textColor }}
        ></Chip>
      ) : (
        <Chip
          icon={<LocalOfferOutlinedIcon style={{ color: textColor }} fontSize={'small'} />}
          label={specification.key + ': ' + specification.value}
          style={{ backgroundColor: bgColor, color: textColor }}
        ></Chip>
      )}
    </Box>
  );
};
