import React, { FormEvent, useEffect, useState } from 'react';
import { ApplicationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {
  createApplicationAction,
  resetApplicationSubmissionAction,
  useApplications,
} from '../../../redux/applicationSlice';
import { useDispatch } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import { StyledInfoBox } from '../../../../_shared/styledComponents/StyledInfoBox';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { phoneUtils } from '../../../../_shared/utils/phoneUtils';
import * as EmailValidator from 'email-validator';
import { PhoneTextField } from '../../../../_shared/texfields/PhoneTextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import LoadingButton from '@mui/lab/LoadingButton';
import { CreateApplicationData } from '../../../redux/applicationTypes';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

enum InputIdType {
  firstName = 'firstName',
  lastName = 'lastName',
  phone = 'phone',
  email = 'email',
  moveInDate = 'requestedMoveInDate',
  unitRequested = 'unitRequested',
}

const requiredApplicantFields = new Map([
  [
    ApplicationType.Primary,
    [
      InputIdType.firstName,
      InputIdType.lastName,
      InputIdType.email,
      InputIdType.phone,
      InputIdType.moveInDate,
      InputIdType.unitRequested,
    ],
  ],
  [ApplicationType.OtherAdult, [InputIdType.firstName, InputIdType.lastName, InputIdType.email, InputIdType.phone]],
  [ApplicationType.CoSigner, [InputIdType.firstName, InputIdType.lastName, InputIdType.email, InputIdType.phone]],
]);

export const blankApplicant: CreateApplicationData = {
  rentalPropertyId: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

type Props = {
  type: ApplicationType;
  propertyId: string | undefined;
  parentApplicationId?: string;
  onClose?: () => void;
  isWebView?: boolean;
};

export const AddApplicantForm = ({ type, propertyId, parentApplicationId, onClose, isWebView }: Props) => {
  const dispatch = useDispatch();
  const { allApplications } = useApplications();
  const [backgroundCheck, setBackgroundCheck] = useState(true);
  const [dirty, setDirty] = useState(false);
  const [comment, setComment] = useState('');

  const [newApplicant, setNewApplicant] = useState<CreateApplicationData>(blankApplicant);

  useEffect(() => {
    if (allApplications.submitted && onClose) {
      setNewApplicant(blankApplicant);
      setBackgroundCheck(true);
      setDirty(false);
      onClose();
    }
    dispatch(resetApplicationSubmissionAction());
  }, [allApplications.submitted]);

  const addNewApplicant = (e: FormEvent) => {
    e.preventDefault();
    const phoneIsValid = newApplicant.phone && phoneUtils.isValid(newApplicant.phone);
    const emailIsValid =
      newApplicant.email == '' || (newApplicant.email && EmailValidator.validate(newApplicant.email));
    if (!phoneIsValid) {
      dispatch(showToastMessageAction({ message: 'Please enter a valid phone number', severity: 'error' }));
    } else if (!emailIsValid) {
      dispatch(showToastMessageAction({ message: 'Please enter a valid email', severity: 'error' }));
    } else {
      const newApplication: CreateApplicationData = {
        ...newApplicant,
        rentalPropertyId: propertyId,
        parentApplicationId: parentApplicationId,
        firstName:
          newApplicant.firstName && newApplicant.firstName.charAt(0).toUpperCase() + newApplicant.firstName.slice(1),
        lastName:
          newApplicant.lastName && newApplicant.lastName.charAt(0).toUpperCase() + newApplicant.lastName.slice(1),
        comment: isWebView ? comment : undefined,
      };
      propertyId &&
        dispatch(
          createApplicationAction({ propertyId: propertyId, applicationType: type, body: newApplication, isWebView }),
        );
    }
  };

  const closeWithoutSaving = () => {
    setNewApplicant(blankApplicant);
    setBackgroundCheck(true);
    setDirty(false);
    onClose && onClose();
  };

  const handleUpdateApplicant = (key: keyof CreateApplicationData, value: string) => {
    setNewApplicant({ ...newApplicant, [key]: value });
    setDirty(true);
  };

  const handleBackgroundCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    //TODO: double check these are set right
    setBackgroundCheck(e.target.checked);
  };

  const isRequiredField = (inputId: InputIdType) => {
    const requiredFields = requiredApplicantFields.get(type);
    if (!requiredFields) return;
    return requiredFields.includes(inputId);
  };

  return (
    <form onSubmit={addNewApplicant} autoComplete="off">
      <Stack spacing={2}>
        <StyledInfoBox label={`Applicant Info`}>
          <Grid container spacing={2}>
            {isRequiredField(InputIdType.firstName) && (
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  fullWidth
                  required
                  margin="dense"
                  id={InputIdType.firstName}
                  label="First Name"
                  type="text"
                  variant="standard"
                  value={newApplicant.firstName}
                  onChange={(e) => handleUpdateApplicant('firstName', e.target.value)}
                />
              </Grid>
            )}
            {isRequiredField(InputIdType.lastName) && (
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  margin="dense"
                  id={InputIdType.lastName}
                  label="Last Name"
                  type="text"
                  variant="standard"
                  value={newApplicant.lastName}
                  onChange={(e) => handleUpdateApplicant('lastName', e.target.value)}
                />
              </Grid>
            )}
            {isRequiredField(InputIdType.phone) && (
              <Grid item xs={6}>
                <PhoneTextField
                  fullWidth
                  required
                  margin="dense"
                  id={InputIdType.phone}
                  label="Phone Number"
                  variant="standard"
                  value={newApplicant.phone}
                  onChange={(e) => handleUpdateApplicant('phone', e.target.value)}
                />
              </Grid>
            )}
            {isRequiredField(InputIdType.email) && (
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="dense"
                  id={InputIdType.email}
                  label="Email Address"
                  type="email"
                  variant="standard"
                  value={newApplicant.email}
                  onChange={(e) => handleUpdateApplicant('email', e.target.value)}
                />
              </Grid>
            )}
            {!isWebView && (
              <>
                {isRequiredField(InputIdType.moveInDate) && (
                  <Grid item xs={6}>
                    <DatePicker
                      label={'Desired Move-In Date'}
                      slotProps={{ field: { clearable: true }, textField: { fullWidth: true } }}
                      onChange={(date: Date | null) =>
                        handleUpdateApplicant('requestedMoveInDate', toReduxDate(date) ?? '')
                      }
                      value={newApplicant.requestedMoveInDate ? toStandardDate(newApplicant.requestedMoveInDate) : null}
                    />
                  </Grid>
                )}
                {isRequiredField(InputIdType.unitRequested) && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id={InputIdType.unitRequested}
                      label="Unit Requested"
                      type="text"
                      variant="standard"
                      value={newApplicant.unitRequested ? newApplicant.unitRequested : ''}
                      onChange={(e) => handleUpdateApplicant('unitRequested', e.target.value)}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </StyledInfoBox>
        {!isWebView && (
          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={backgroundCheck} onChange={handleBackgroundCheck} />}
                label="Background Check Required"
              />
            </FormGroup>
          </Box>
        )}
        {isWebView && (
          <TextField
            fullWidth
            margin="dense"
            id="comment"
            label="Include a comment (optional)"
            type="text"
            variant="outlined"
            multiline
            rows={3}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        )}
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          {onClose ? (
            <Button onClick={closeWithoutSaving} sx={{ mr: '1rem' }}>
              Close
            </Button>
          ) : (
            <></>
          )}
          <LoadingButton type="submit" variant="contained" disabled={!dirty} loading={allApplications.submitting}>
            {isWebView ? 'Apply' : 'Add Applicant'}
          </LoadingButton>
        </Box>
      </Stack>
    </form>
  );
};
