import React, { FormEvent, useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import { updateBuildingAction, useBuilding } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/buildingSlice';
import { IPostalAddress } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { StyledInfoBox } from '../../../_shared/styledComponents/StyledInfoBox';
import LoadingButton from '@mui/lab/LoadingButton';

export const BuildingInformation = () => {
  const dispatch = useDispatch();
  const { selectedBuilding } = useBuilding();
  const building = selectedBuilding.value;
  const [updatedMailingAddress, setUpdatedMailingAddress] = useState(building?.details?.mailingAddress);
  const [buildingName, setBuildingName] = useState(building?.name);
  const [dirty, setDirty] = useState<boolean>(false);

  const handleAddressChange = (value: string, key: keyof IPostalAddress) => {
    setUpdatedMailingAddress({ ...updatedMailingAddress, [key]: value });
    setDirty(true);
  };

  const updateBuilding = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    building?.id &&
      buildingName &&
      dispatch(
        updateBuildingAction({
          id: building?.id,
          body: {
            name: buildingName,
            details: {
              mailingAddress: updatedMailingAddress,
            },
          },
        }),
      );
    setDirty(false);
  };

  const handleNameChange = (value: string) => {
    setBuildingName(value);
    setDirty(true);
  };

  if (building) {
    return (
      <StyledInfoBox label={'Building Address Information'}>
        <form onSubmit={updateBuilding} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                autoFocus
                variant="standard"
                margin="dense"
                id="name"
                label="Building Name"
                type="text"
                defaultValue={buildingName}
                onChange={(e) => {
                  handleNameChange(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                autoFocus
                variant="standard"
                margin="dense"
                id="streetAddress1"
                label="Street Address 1"
                type="text"
                defaultValue={building.details?.mailingAddress?.streetAddress1}
                onChange={(e) => {
                  handleAddressChange(e.target.value, 'streetAddress1');
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                margin="dense"
                id="streetAddress2"
                label="Street Address 2"
                type="text"
                defaultValue={building.details?.mailingAddress?.streetAddress2}
                onChange={(e) => {
                  handleAddressChange(e.target.value, 'streetAddress2');
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="standard"
                margin="dense"
                id="city"
                label="City"
                type="text"
                defaultValue={building.details?.mailingAddress?.city}
                onChange={(e) => {
                  handleAddressChange(e.target.value, 'city');
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                variant="standard"
                margin="dense"
                id="state"
                label="State"
                type="text"
                defaultValue={building.details?.mailingAddress?.state}
                onChange={(e) => {
                  handleAddressChange(e.target.value, 'state');
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                variant="standard"
                margin="dense"
                id="zipCode"
                label="Zip Code"
                type="number"
                defaultValue={building.details?.mailingAddress?.zip}
                onChange={(e) => {
                  handleAddressChange(e.target.value, 'zip');
                }}
              />
            </Grid>
            <Grid item xs={8} display="flex" justifyContent="right">
              <LoadingButton type="submit" variant="contained" disabled={!dirty} loading={selectedBuilding.submitting}>
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </StyledInfoBox>
    );
  } else return <></>;
};
