import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { PhoneTextField } from '../../../../../_shared/texfields/PhoneTextField';
import {
  IContactInfo,
  IContactPerson,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ReduxApplication } from '../../../../redux/applicationTypes';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';

type Props = {
  editingApplicant: ReduxApplication;
  onUpdateEmergencyContact: (key: keyof IContactPerson, value?: string) => void;
  onUpdateEmergencyContactInfo: (key: keyof IContactInfo, value?: string) => void;
};

export const AddEmergencyContact = ({
  editingApplicant,
  onUpdateEmergencyContact,
  onUpdateEmergencyContactInfo,
}: Props) => {
  return (
    <StyledInfoBox label={'Emergency Contact Info'}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="dense"
            id="emergencyContactFirstName"
            label="Emergency Contact First Name"
            type="text"
            variant="standard"
            value={editingApplicant.emergencyContact?.firstName ? editingApplicant.emergencyContact.firstName : ''}
            onChange={(e) => onUpdateEmergencyContact('firstName', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required={!!editingApplicant.emergencyContact?.firstName}
            margin="dense"
            id="emergencyContactLastName"
            label="Emergency Contact Last Name"
            type="text"
            variant="standard"
            value={editingApplicant.emergencyContact?.lastName ? editingApplicant.emergencyContact.lastName : ''}
            onChange={(e) => onUpdateEmergencyContact('lastName', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required={!!editingApplicant.emergencyContact?.firstName}
            margin="dense"
            id="emergencyContactRelationship"
            label="Emergency Contact Relationship"
            type="text"
            variant="standard"
            value={
              editingApplicant.emergencyContact?.relationship ? editingApplicant.emergencyContact.relationship : ''
            }
            onChange={(e) => onUpdateEmergencyContact('relationship', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneTextField
            fullWidth
            required={!!editingApplicant.emergencyContact?.firstName}
            margin="dense"
            id="emergencyContactPhone"
            label="Emergency Contact Phone"
            variant="standard"
            value={
              editingApplicant.emergencyContact?.contactInfo?.phone
                ? editingApplicant.emergencyContact.contactInfo.phone
                : ''
            }
            onChange={(e) => onUpdateEmergencyContactInfo('phone', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required={!!editingApplicant.emergencyContact?.firstName}
            margin="dense"
            id="email"
            label="Emergency Contact Email"
            type="email"
            variant="standard"
            value={
              editingApplicant.emergencyContact?.contactInfo?.email
                ? editingApplicant.emergencyContact.contactInfo.email
                : ''
            }
            onChange={(e) => onUpdateEmergencyContactInfo('email', e.target.value)}
          />
        </Grid>
      </Grid>
    </StyledInfoBox>
  );
};
