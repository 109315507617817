import { Filter } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import {
  IQueryExpression,
  LeaseBillingStatus,
  LeaseClosedReason,
  LeaseDetailedStatus,
  LeaseProcessStatus,
  QueryGroupOperator,
  QueryOperator,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';

export function getSpecificLeaseStatusFilters(item: Filter<ReduxLease>): IQueryExpression {
  switch (item.value) {
    case LeaseDetailedStatus.Draft:
      return {
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.Draft,
          },
        ],
      };
      break;
    case LeaseDetailedStatus.DepositPaid:
      return {
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.DepositPaid,
          },
        ],
      };
      break;
    case LeaseDetailedStatus.PreLeased:
      return {
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.PreLeased,
          },
        ],
      };
      break;
    case LeaseDetailedStatus.Active:
      return {
        groupOperator: QueryGroupOperator.And,
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.Active,
          },
          {
            field: 'billingStatus',
            operator: QueryOperator.Ne,
            value: LeaseBillingStatus.PeriodToPeriod,
          },
          {
            field: 'closedReason',
            operator: QueryOperator.Ne,
            value: LeaseClosedReason.Evicted,
          },
          {
            field: 'billingStatus',
            operator: QueryOperator.Ne,
            value: LeaseBillingStatus.PeriodToPeriod,
          },
        ],
      };
      break;
    case LeaseDetailedStatus.ActiveMonthToMonthPendingEviction:
      return {
        groupOperator: QueryGroupOperator.And,
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.Active,
          },
          {
            field: 'billingStatus',
            operator: QueryOperator.Eq,
            value: LeaseBillingStatus.PeriodToPeriod,
          },
          {
            field: 'closedReason',
            operator: QueryOperator.Eq,
            value: LeaseClosedReason.Evicted,
          },
        ],
      };
      break;
    case LeaseDetailedStatus.ActiveMonthToMonth:
      return {
        groupOperator: QueryGroupOperator.And,
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.Active,
          },
          {
            field: 'billingStatus',
            operator: QueryOperator.Eq,
            value: LeaseBillingStatus.PeriodToPeriod,
          },
        ],
      };
      break;
    case LeaseDetailedStatus.ActivePendingEviction:
      return {
        groupOperator: QueryGroupOperator.And,
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.Active,
          },
          {
            field: 'closedReason',
            operator: QueryOperator.Eq,
            value: LeaseClosedReason.Evicted,
          },
        ],
      };
      break;
    case LeaseDetailedStatus.Cancelling:
      return {
        groupOperator: QueryGroupOperator.And,
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.Closing,
          },
          {
            field: 'closedReason',
            operator: QueryOperator.Eq,
            value: LeaseClosedReason.Cancelled,
          },
        ],
      };
      break;
    case LeaseDetailedStatus.MovedOutEviction:
      return {
        groupOperator: QueryGroupOperator.And,
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.Closing,
          },
          {
            field: 'closedReason',
            operator: QueryOperator.Eq,
            value: LeaseClosedReason.Evicted,
          },
        ],
      };
      break;
    case LeaseDetailedStatus.MovedOut:
      return {
        groupOperator: QueryGroupOperator.And,
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.Closing,
          },
          {
            field: 'closedReason',
            operator: QueryOperator.Ne,
            value: LeaseClosedReason.Cancelled,
          },
          {
            field: 'closedReason',
            operator: QueryOperator.Ne,
            value: LeaseClosedReason.Evicted,
          },
        ],
      };
      break;
    case LeaseDetailedStatus.ReturnFundsEvicted:
      return {
        groupOperator: QueryGroupOperator.And,
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.ClosingUnderReview,
          },
          {
            field: 'closedReason',
            operator: QueryOperator.Eq,
            value: LeaseClosedReason.Evicted,
          },
        ],
      };
      break;
    case LeaseDetailedStatus.ReturnFundsCancelling:
      return {
        groupOperator: QueryGroupOperator.And,
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.ClosingUnderReview,
          },
          {
            field: 'closedReason',
            operator: QueryOperator.Eq,
            value: LeaseClosedReason.Cancelled,
          },
        ],
      };
      break;
    case LeaseDetailedStatus.ReturnFundsThenClose:
      return {
        groupOperator: QueryGroupOperator.And,
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.ClosingUnderReview,
          },
          {
            field: 'closedReason',
            operator: QueryOperator.Ne,
            value: LeaseClosedReason.Evicted,
          },
          {
            field: 'closedReason',
            operator: QueryOperator.Ne,
            value: LeaseClosedReason.Cancelled,
          },
        ],
      };
      break;
    case LeaseDetailedStatus.ClosedEviction:
      return {
        groupOperator: QueryGroupOperator.And,
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.Closed,
          },
          {
            field: 'closedReason',
            operator: QueryOperator.Eq,
            value: LeaseClosedReason.Evicted,
          },
        ],
      };
      break;
    case LeaseDetailedStatus.Cancelled:
      return {
        groupOperator: QueryGroupOperator.And,
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.Closed,
          },
          {
            field: 'closedReason',
            operator: QueryOperator.Eq,
            value: LeaseClosedReason.Cancelled,
          },
        ],
      };
      break;
    case LeaseDetailedStatus.Closed:
      return {
        groupOperator: QueryGroupOperator.And,
        parameters: [
          {
            field: 'processStatus',
            operator: QueryOperator.Eq,
            value: LeaseProcessStatus.Closed,
          },
          {
            field: 'closedReason',
            operator: QueryOperator.Ne,
            value: LeaseClosedReason.Evicted,
          },
          {
            field: 'closedReason',
            operator: QueryOperator.Ne,
            value: LeaseClosedReason.Cancelled,
          },
        ],
      };
      break;
    default:
      return {
        parameters: [],
      };
  }
}
