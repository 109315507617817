import React, { FC } from 'react';
import { Checklist } from '../../../checklists/components/Checklist';
import {
  addChecklistItemAction,
  addCommentToTaskChecklistItemAction,
  completeChecklistItemAction,
  deleteChecklistItemAction,
  reorderChecklistAction,
  updateTaskChecklistAction,
  useTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { ReduxTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type TaskDescriptionProps = {
  task: ReduxTask;
  onSetTask: (key: keyof ReduxTask, value: string) => void;
};

export const TaskDescription: FC<TaskDescriptionProps> = ({ task, onSetTask }) => {
  const { selectedTask } = useTasks();
  const dispatch = useDispatch();

  const handleChecked = (id: string) => {
    if (!selectedTask.value?.id || !selectedTask.value.associatedId || !selectedTask.value.assetType) return;
    dispatch(
      completeChecklistItemAction({
        taskId: selectedTask.value?.id,
        itemId: id,
        associationType: selectedTask.value.assetType,
        associatedId: selectedTask.value.associatedId,
      }),
    );
  };

  const handleDeleteItem = (id: string) => {
    if (!selectedTask.value?.id || !selectedTask.value.assetType || !selectedTask.value.associatedId) return;
    dispatch(
      deleteChecklistItemAction({
        taskId: selectedTask.value?.id,
        itemId: id,
        associationType: selectedTask.value.assetType,
        associatedId: selectedTask.value.associatedId,
      }),
    );
  };

  const handleAddItem = (value: string) => {
    if (!selectedTask.value?.id || !selectedTask.value.assetType || !selectedTask.value.associatedId) return;
    dispatch(
      addChecklistItemAction({
        taskId: selectedTask.value.id,
        item: value,
        associationType: selectedTask.value.assetType,
        associatedId: selectedTask.value.associatedId,
      }),
    );
  };

  const handleReorderChecklist = (ids: string[]) => {
    if (!selectedTask.value?.id || !selectedTask.value.assetType || !selectedTask.value.associatedId) return;
    dispatch(
      reorderChecklistAction({
        taskId: selectedTask.value?.id,
        body: {
          associatedId: selectedTask.value.associatedId,
          associationType: selectedTask.value.assetType,
          items: ids,
        },
      }),
    );
  };

  const handleAddComment = (comment: string, itemId: string) => {
    selectedTask.value?.id &&
      dispatch(
        addCommentToTaskChecklistItemAction({
          taskId: selectedTask.value?.id,
          itemId: itemId,
          comment: comment,
        }),
      );
  };

  const handleUpdateChecklistItem = (itemId: string, newName?: string, newDescription?: string) => {
    selectedTask.value?.id &&
      dispatch(
        updateTaskChecklistAction({
          taskId: selectedTask.value.id,
          itemId: itemId,
          body: { name: newName, description: newDescription },
        }),
      );
  };

  return (
    <Stack spacing={2}>
      <TextField
        fullWidth
        label={'Description'}
        multiline
        minRows={2}
        variant={'outlined'}
        value={task?.description ? task.description : ''}
        onChange={(e) => onSetTask('description', e.target.value)}
      />
      <Checklist
        submitting={selectedTask.submitting}
        checklist={selectedTask.value?.checklist}
        onAddItem={handleAddItem}
        onChecked={handleChecked}
        onDeleteItem={handleDeleteItem}
        onReorderChecklist={handleReorderChecklist}
        onAddComment={handleAddComment}
        associationType={AssociationType.Task}
        associatedId={selectedTask.value?.id}
        onUpdateChecklistItem={handleUpdateChecklistItem}
        propertyId={selectedTask.value?.propertyId}
      />
    </Stack>
  );
};
