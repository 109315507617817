import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { useIcons } from '../icons/useIcons';

export const BackButton = () => {
  const navigate = useNavigate();
  const { ActionType, getActionIcon } = useIcons();
  return (
    <Link onClick={() => navigate(-1)} sx={{ cursor: 'pointer' }}>
      <Stack direction={'row'} alignItems={'center'}>
        {getActionIcon(ActionType.Back, { fontSize: 'small' })}
        <Typography>Back</Typography>
      </Stack>
    </Link>
  );
};
