import React, { FC, useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import { StripedDataGrid } from '../../../datagrids/StripedDataGrid';
import { getDetailGroupsAction, useDetails } from '@monkeyjump-labs/cam-fe-shared/dist/redux/details/detailsSlice';
import { DataGridPremiumProps, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import { numericFilters, stringFilters } from '../../../utils/filteringUtils';
import { GroupedExpenseItem } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { GridRowId } from '@mui/x-data-grid';
import { setExpandedRowIdsAction, unsetGroupedExpenseListAction } from '../../../expenses/redux/expenseSlice';
import { useDispatch } from 'react-redux';
import { DetailsTable, DetailsTableProps } from './DetailsTable';
import {
  mapAssetTypeFromDetailAssociationType,
  mapDetailAssociationTypeFromAssetType,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/detailTypes';

export const GroupedDetailsTable: FC<DetailsTableProps> = ({
  includeClosed,
  associationType,
  associatedId,
  onViewDetails,
  onOpenAddTask,
}) => {
  const dispatch = useDispatch();
  const { assetGroups, assetGroupingInfo } = useDetails();
  const getDetailPanelContent: DataGridPremiumProps['getDetailPanelContent'] = useCallback(
    ({ row }: GridRowParams<GroupedExpenseItem>) => {
      const detailAssociationType = row.associationType
        ? mapDetailAssociationTypeFromAssetType(row.associationType)
        : undefined;
      return (
        <>
          {detailAssociationType && (
            <Stack sx={{ py: 2, height: 1, boxSizing: 'border-box', backgroundColor: 'lightgray' }} direction="column">
              <Paper sx={{ flex: 1, mx: 'auto', width: '95%', p: 1 }}>
                <Stack direction="column" spacing={1}>
                  <DetailsTable
                    associationType={detailAssociationType}
                    associatedId={row.itemId!}
                    onViewDetails={onViewDetails}
                    includeClosed={includeClosed}
                    isAssetGroupsDetailPanel={true}
                    onOpenAddTask={onOpenAddTask}
                  />
                </Stack>
              </Paper>
            </Stack>
          )}
        </>
      );
    },
    [associationType, associatedId, includeClosed],
  );
  const getDetailPanelHeight = useCallback<NonNullable<DataGridPremiumProps['getDetailPanelHeight']>>(
    () => 'auto' as const,
    [],
  );

  useEffect(() => {
    const assetType = mapAssetTypeFromDetailAssociationType(associationType);
    assetType &&
      associatedId &&
      dispatch(
        getDetailGroupsAction({
          assetType: assetType,
          assetId: associatedId,
          includeClosed,
        }),
      );
  }, [includeClosed, associationType, associatedId]);
  const handleExpandedRowIdsChange = (ids: GridRowId[]) => {
    if (ids.length === 0) dispatch(setExpandedRowIdsAction([]));
    else if (ids.length > 0 && ids.length < assetGroupingInfo.expandedRowIds.length) {
      const unsetId = assetGroupingInfo.expandedRowIds.find((id) => !ids.includes(id));
      unsetId && dispatch(unsetGroupedExpenseListAction(unsetId));
    } else dispatch(setExpandedRowIdsAction(ids.map((id) => id.toString())));
  };

  const columns: GridColDef[] = [
    {
      field: 'itemName',
      type: 'string',
      headerName: 'Asset',
      flex: 1.25,
      filterOperators: stringFilters,
      renderCell: (params: GridRenderCellParams) => {
        return <strong>{params.value}</strong>;
      },
    },
    {
      field: 'totalCount',
      type: 'number',
      headerName: 'Count',
      flex: 1.25,
      filterOperators: numericFilters,
      renderHeader: () => {
        if (includeClosed) {
          return <>Total Details</>;
        } else return <>Total Open Details</>;
      },
      renderCell: (params: GridRenderCellParams) => {
        if (includeClosed) {
          return <>{params.row.totalCount}</>;
        } else
          return (
            <>
              {params.row.totalCount - params.row.openCount} / {params.row.totalCount}
            </>
          );
      },
    },
  ];
  return (
    <Box
      sx={{
        width: 1,
        '& .super-app-theme--inactive': {
          color: 'lightgray',
        },
      }}
    >
      <StripedDataGrid
        disableRowGrouping
        sx={{ minHeight: 500 }}
        autoHeight
        rows={assetGroups.value ?? []}
        loading={assetGroups.loading}
        columns={columns}
        getDetailPanelContent={getDetailPanelContent}
        getRowId={(row) => row.itemId}
        getRowClassName={(params) => {
          return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
        }}
        getDetailPanelHeight={getDetailPanelHeight}
        onDetailPanelExpandedRowIdsChange={handleExpandedRowIdsChange}
        hideFooter
      ></StripedDataGrid>
    </Box>
  );
};
