import React, { FC, FormEvent, useEffect, useState } from 'react';
import { ReduxInvoice } from '../../redux/expenseData';
import { useDispatch } from 'react-redux';
import { updateInvoiceAction, useExpenses } from '../../redux/expenseSlice';
import {
  IContactPerson,
  ISlimAccountRef,
  IVendor,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Stack from '@mui/material/Stack';
import { InvoiceForm } from './InvoiceForm';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

type ViewInvoiceDetailsProps = {
  invoice: ReduxInvoice;
  propertyId: string;
  jobId: string;
  onAddNewVendor: (name: string) => void;
};
export const ViewInvoiceDetails: FC<ViewInvoiceDetailsProps> = ({ invoice, onAddNewVendor, jobId, propertyId }) => {
  const dispatch = useDispatch();
  const { selectedInvoice } = useExpenses();
  const [editingInvoice, setEditingInvoice] = useState(selectedInvoice.submittingValue ?? invoice);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    setEditingInvoice(selectedInvoice.submittingValue ?? invoice);
    selectedInvoice.submittingValue && setDirty(true);
  }, [selectedInvoice.submittingValue, invoice]);

  const handleChangeInvoice = (field: keyof ReduxInvoice, value: string | number | undefined) => {
    setEditingInvoice({ ...editingInvoice, [field]: value });
    setDirty(true);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(updateInvoiceAction({ jobId: jobId, invoice: editingInvoice }));
    setDirty(false);
  };

  const handleChangeVendor = (value: IVendor | undefined) => {
    setEditingInvoice({ ...editingInvoice, vendor: value });
    setDirty(true);
  };

  const handleChangeVendorContact = (value: IContactPerson | undefined) => {
    setEditingInvoice({ ...editingInvoice, vendorContact: value });
    setDirty(true);
  };

  const handleChangeAssociation = (association?: string, vendor?: IVendor, propertyAccount?: ISlimAccountRef) => {
    setEditingInvoice({
      ...editingInvoice,
      associatedPurchaseOrder: association,
      vendor: vendor ?? editingInvoice.vendor,
      propertyAccount: propertyAccount ?? editingInvoice.propertyAccount,
    });
    setDirty(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <InvoiceForm
          value={editingInvoice}
          onChangeInvoice={handleChangeInvoice}
          onChangeVendor={handleChangeVendor}
          onChangeVendorContact={handleChangeVendorContact}
          propertyId={propertyId}
          expenseAccount={editingInvoice.propertyAccount}
          onChangeExpenseAccount={(account: ISlimAccountRef) => {
            setEditingInvoice({ ...editingInvoice, propertyAccount: account });
            setDirty(true);
          }}
          onAddNewVendor={onAddNewVendor}
          editingMode
          onAssociatePurchaseOrder={handleChangeAssociation}
        />
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
          <LoadingButton variant={'contained'} disabled={!dirty} type={'submit'} loading={selectedInvoice.submitting}>
            Save Changes
          </LoadingButton>
        </Box>
      </Stack>
    </form>
  );
};
