import React, { FC, useState } from 'react';
import { searchAllAssetsAction } from '../redux/searchSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  AssociationType,
  IBaseApplicationResponse,
  IBuilding,
  IRentalProperty,
  IRoom,
  ISlimUnit,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { SearchTypeButtonGroup } from './SearchTypeButtonGroup';
import Box from '@mui/material/Box';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { SearchAutocomplete } from './SearchAutocomplete';
import { ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { getSelectedTaskAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import {
  ReduxExpense,
  ReduxExpensePayment,
  ReduxInvoice,
  ReduxPurchaseOrder,
  ReduxQuote,
  ReduxWorkOrder,
} from '../../expenses/redux/expenseData';
import { SearchOption } from '../useSearchHook';

export type SearchResultItem =
  | IRentalProperty
  | IBuilding
  | ISlimUnit
  | IRoom
  | IBaseApplicationResponse
  | ReduxLease
  | ReduxExpense
  | null;

export type SearchChildItem = ReduxInvoice | ReduxExpensePayment | ReduxPurchaseOrder | ReduxQuote | ReduxWorkOrder;

type GlobalSearchProps = {
  searchTypes: AssociationType[] | null;
  options: string[];
  onSelectAssociation?: (associationType?: AssociationType, associatedId?: string) => void;
  global: boolean;
  placeholder?: string;
};

export const Search: FC<GlobalSearchProps> = ({ options, searchTypes, onSelectAssociation, global, placeholder }) => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const navigate = useNavigate();
  const [associationType, setAssociationType] = useState<AssociationType | undefined>();
  const [selectedValue, setSelectedValue] = useState<SearchOption | undefined>();
  const [limitToSelectedProperty, setLimitToSelectedProperty] = useState(false);
  const handleSetLimitToProperty = () => setLimitToSelectedProperty(!limitToSelectedProperty);

  const handleSetAssociationType = (a: AssociationType | undefined) => {
    setAssociationType(a);
  };

  const handleSearch = (includeClosed: boolean, searchText: string) => {
    if (associationType) {
      dispatch(
        searchAllAssetsAction({
          searchText: searchText,
          searchTypes: [associationType],
          includeClosed: includeClosed,
          propertyId: global && !limitToSelectedProperty ? undefined : selectedContext.propertyId,
        }),
      );
    } else {
      dispatch(
        searchAllAssetsAction({
          searchText: searchText,
          searchTypes: searchTypes,
          includeClosed: includeClosed,
          propertyId: global && !limitToSelectedProperty ? undefined : selectedContext.propertyId,
        }),
      );
    }
  };

  const handleSearchResultSelect = (value?: SearchOption) => {
    if (!value) {
      onSelectAssociation && onSelectAssociation(undefined, undefined);
      setSelectedValue(undefined);
    } else {
      if (global) {
        if (!value.assetUrl) return;
        if (value.assetType === AssociationType.Task) {
          value?.item?.id && dispatch(getSelectedTaskAction({ id: value.item.id, includeClosed: false }));
        }
        navigate(value.assetUrl);
      } else {
        //set the value in the autocomplete
        setSelectedValue(value);
        if (value.item?.id && onSelectAssociation) {
          //set the value in the association dialog if necessary
          onSelectAssociation(value.assetType, value.item.id);
          setSelectedValue(value);
        }
      }
    }
  };

  return (
    <Box display={'flex'} py={'1rem'}>
      <SearchTypeButtonGroup options={options} onChangeAssociationType={handleSetAssociationType} />
      <SearchAutocomplete
        selectedValue={selectedValue}
        onSearch={handleSearch}
        onSearchSelect={handleSearchResultSelect}
        global={global}
        limitToSelectedProperty={limitToSelectedProperty}
        onSetLimitToProperty={handleSetLimitToProperty}
        placeholder={placeholder}
      />
    </Box>
  );
};
