import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createRoomAction, useRoom } from '../../../room/redux/roomSlice';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { IRoom } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { StyledInfoBox } from '../../../_shared/styledComponents/StyledInfoBox';
import LoadingButton from '@mui/lab/LoadingButton';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';

type Props = {
  unitId: string | undefined;
  open: boolean;
  onClose: () => any;
};

export const AddRoom = ({ open, onClose, unitId }: Props) => {
  const dispatch = useDispatch();
  const { selectedRoom } = useRoom();
  const [newRoom, setNewRoom] = useState<IRoom>({
    name: '',
    buildingUnitId: unitId,
    details: {},
  });

  useEffect(() => setNewRoom({ ...newRoom, buildingUnitId: unitId }), [unitId]);

  useEffect(() => {
    if (selectedRoom.submitted) {
      setNewRoom({
        name: '',
        buildingUnitId: unitId,
        details: {},
      });
      onClose();
    }
  }, [selectedRoom.submitted]);

  const addRoom = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(createRoomAction(newRoom));
  };

  return (
    <DialogLayout title={'Add Room'} maxWidth="md" fullWidth={true} open={open} onClose={onClose}>
      <DialogContent>
        <form onSubmit={addRoom} autoComplete="off">
          <StyledInfoBox label={'Room Info'}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  fullWidth
                  required
                  margin="dense"
                  id="roomName"
                  label="Room Name"
                  type="text"
                  variant="standard"
                  value={newRoom.name}
                  onChange={(event) => {
                    setNewRoom({ ...newRoom, name: event.target.value });
                  }}
                />
              </Grid>
            </Grid>
          </StyledInfoBox>
          <Grid item xs={12}>
            <DialogActions sx={{ marginTop: '1rem' }}>
              <Button onClick={onClose}>Close</Button>
              <LoadingButton type="submit" variant="contained" loading={selectedRoom.submitting}>
                Add Room
              </LoadingButton>
            </DialogActions>
          </Grid>
        </form>
      </DialogContent>
    </DialogLayout>
  );
};
