import React, { FC, FormEvent, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import { TaskCategoryAutocomplete } from '../../../../../../_shared/tasks/components/TaskCategoryAutocomplete';
import { updateTaskAutomationAction, useSopTasks } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import { useDispatch } from 'react-redux';
import { ReduxSopTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { getUsersForAssetAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { AssetType, ITaskCategory } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import LoadingButton from '@mui/lab/LoadingButton';
import { FullForm } from '../../../../../../_shared/styledComponents/FullForm';
import { AssigneeAutocomplete, UserOption } from '../../../../../../_shared/tasks/components/AssigneeAutocomplete';

export const SopTaskDetails: FC = () => {
  const dispatch = useDispatch();
  const { selectedSopTask } = useSopTasks();
  const [editingValue, setEditingValue] = useState<ReduxSopTask | undefined>(selectedSopTask.value);
  const [assignee, setAssignee] = useState<UserOption | null>(null);
  const [dirty, setDirty] = useState(false);
  const propertyId = selectedSopTask.value?.propertyId;

  useEffect(() => {
    propertyId &&
      dispatch(
        getUsersForAssetAction({
          assetId: propertyId,
          associationType: AssetType.RentalProperty,
        }),
      );
  }, [propertyId]);

  useEffect(() => {
    setEditingValue(selectedSopTask.value);
    selectedSopTask.value?.defaultAssignee?.id &&
      setAssignee({
        id: selectedSopTask.value?.defaultAssignee?.id,
        name: `${selectedSopTask.value?.defaultAssignee?.firstName} ${selectedSopTask.value?.defaultAssignee?.lastName}`,
        roles: '',
      });
  }, [selectedSopTask.value]);

  const handleCategorySelection = (value: ITaskCategory | null) => {
    setEditingValue({ ...editingValue, category: value ?? undefined });
    setDirty(true);
  };

  const handleEditAutomation = (value: string, key: string) => {
    setEditingValue({ ...editingValue, [key]: value });
    setDirty(true);
  };

  const handleEditAssignee = (value: UserOption | null) => {
    setAssignee(value);
    setDirty(true);
  };

  const updateTaskAutomation = (e: FormEvent) => {
    e.preventDefault();
    editingValue &&
      selectedSopTask.value?.propertyId &&
      selectedSopTask.value?.id &&
      dispatch(
        updateTaskAutomationAction({
          configId: selectedSopTask.value?.id,
          propertyId: selectedSopTask.value.propertyId,
          body: {
            ...editingValue,
            category: { value: editingValue.category },
            defaultAssigneeId: { value: assignee?.id },
          },
        }),
      );
    setDirty(false);
  };

  return (
    <FullForm onSubmit={updateTaskAutomation}>
      <Grid marginTop={1} container spacing={2}>
        {!editingValue && <LinearProgress />}
        {editingValue && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                fullWidth
                required
                sx={{ margin: 0 }}
                variant={'outlined'}
                label={'Name'}
                value={editingValue.name}
                onChange={(e) => handleEditAutomation(e.target.value, 'name')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TaskCategoryAutocomplete onChange={handleCategorySelection} value={editingValue.category} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                multiline
                minRows={2}
                variant={'outlined'}
                label={'Description'}
                value={editingValue.description}
                onChange={(e) => handleEditAutomation(e.target.value, 'description')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AssigneeAutocomplete value={editingValue.defaultAssignee?.id} onValueChange={handleEditAssignee} />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right' }}>
              <LoadingButton
                variant={'contained'}
                type={'submit'}
                loading={selectedSopTask.submitting}
                disabled={!dirty}
              >
                Save
              </LoadingButton>
            </Grid>
          </>
        )}
      </Grid>
    </FullForm>
  );
};
