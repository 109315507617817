import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { StyledInfoBox } from '../../../../_shared/styledComponents/StyledInfoBox';
import { PhoneTextField } from '../../../../_shared/texfields/PhoneTextField';
import { ReduxListing } from '../../../redux/listingData';

type AgentInfoProps = {
  listing?: ReduxListing;
  onChange: (field: keyof ReduxListing, value: any) => void;
};
export const AgentInfo: FC<AgentInfoProps> = ({ listing, onChange }) => {
  return (
    <StyledInfoBox label={'Agent Info'}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            value={listing?.agent?.firstName ?? ''}
            label={'Agent First Name'}
            fullWidth
            onChange={(e) => onChange('agent', { ...listing?.agent, firstName: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={listing?.agent?.lastName ?? ''}
            label={'Agent Last Name'}
            fullWidth
            onChange={(e) => onChange('agent', { ...listing?.agent, lastName: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneTextField
            value={listing?.agent?.contactInfo?.phone ?? ''}
            label={'Agent Phone'}
            fullWidth
            onChange={(e) =>
              onChange('agent', {
                ...listing?.agent,
                contactInfo: { ...listing?.agent?.contactInfo, phone: e.target.value },
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={listing?.agent?.contactInfo?.email ?? ''}
            type={'email'}
            label={'Agent Email'}
            fullWidth
            onChange={(e) =>
              onChange('agent', {
                ...listing?.agent,
                contactInfo: { ...listing?.agent?.contactInfo, email: e.target.value },
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={listing?.brokerageName ?? ''}
            label={'Brokerage Name'}
            fullWidth
            onChange={(e) => onChange('brokerageName', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={listing?.brokerageInfo?.website ?? ''}
            label={'Brokerage Website'}
            fullWidth
            onChange={(e) =>
              onChange('brokerageInfo', {
                ...listing?.brokerageInfo,
                website: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
    </StyledInfoBox>
  );
};
