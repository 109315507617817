import React, { FC, HTMLAttributes } from 'react';
import { associationTypeMap, isLease } from '../searchUtils';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { LeaseStatusChip } from '../../leases/components/leaseTable/LeaseStatusChip';
import { SearchOption } from '../useSearchHook';

type SearchListItemProps = {
  option?: SearchOption;
} & HTMLAttributes<HTMLLIElement>;

export const SearchListItem: FC<SearchListItemProps> = ({ option, ...props }) => {
  return (
    <li {...props}>
      <Box sx={{ mr: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{option?.icon}</Box>
      <Stack sx={{ display: 'flex', flexGrow: 1 }}>
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
          <Typography>
            {option?.assetType && `${associationTypeMap.get(option?.assetType)}: `}
            {option?.label}
          </Typography>
          {option?.status && isLease(option?.item) ? (
            <LeaseStatusChip lease={option?.item}></LeaseStatusChip>
          ) : option?.status ? (
            <Chip label={option?.status} />
          ) : (
            <></>
          )}
        </Box>
        {option?.secondaryText && <Typography>{option.secondaryText}</Typography>}
        {option?.additionalInfo && <Typography variant={'subtitle2'}>{option.additionalInfo}</Typography>}
      </Stack>
    </li>
  );
};
