import React, { FC, FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  getSingleApplicationByIdAction,
  resetApplicationSubmissionAction,
  updateApplicationAction,
  useApplications,
} from '../../../redux/applicationSlice';
import Typography from '@mui/material/Typography';
import { NotFound } from '../../../../notFound/NotFound';
import {
  ApplicationStatus,
  ApplicationType,
  IContactInfo,
  IContactPerson,
  IEmployment,
  IPerson,
  IPet,
  IPostalAddress,
  IVehicle,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { WizardPrimaryForm } from './WizardPrimaryForm';
import { WizardOtherAdultForm } from './WizardOtherAdultForm';
import { WizardCosignerForm } from './WizardCosignerForm';
import { AddOtherAdultDialog } from '../applicantForms/otherAdults/AddOtherAdultDialog';
import { AddVehicleDialog } from '../applicantForms/otherInfo/AddVehicleDialog';
import { AddPetDialog } from '../applicantForms/otherInfo/AddPetDialog';
import { AddChildDialog } from '../applicantForms/otherInfo/AddChildDialog';
import { AddHousingHistoryDialog } from '../applicantForms/employmentAndHistory/AddHousingHistoryDialog';
import { ReduxHousingHistory } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { ReduxApplication } from '../../../redux/applicationTypes';
import Toolbar from '@mui/material/Toolbar';
import { appTheme } from '../../../../../AppTheme';
import { FullScreenLoading } from '../../../../global/loading/FullScreenLoading';
import { ReviewAndSubmit } from '../applicantForms/reviewAndSubmit/ReviewAndSubmit';

type Params = {
  id: string | undefined;
};

type ApplicationWizardProps = {
  isAuthTokenSet: boolean;
};

export const ApplicationWizard: FC<ApplicationWizardProps> = ({ isAuthTokenSet }) => {
  const dispatch = useDispatch();
  const applications = useApplications();
  const { id } = useParams<Params>();
  const [editingApplicant, setEditingApplicant] = useState<ReduxApplication | undefined>(undefined);
  const [openAddOtherAdult, setOpenAddOtherAdult] = useState(false);
  const [openAddChild, setOpenAddChild] = useState(false);
  const [openAddPet, setOpenAddPet] = useState(false);
  const [openAddVehicle, setOpenAddVehicle] = useState(false);
  const [openAddHousingHistory, setOpenAddHousingHistory] = useState(false);

  const handleOtherAdultOpen = () => {
    dispatch(resetApplicationSubmissionAction());
    setOpenAddOtherAdult(true);
  };

  const handleOtherAdultClose = () => {
    setOpenAddOtherAdult(false);
  };

  const handleAddChildOpen = () => {
    dispatch(resetApplicationSubmissionAction());
    setOpenAddChild(true);
  };

  const handleAddChildClose = () => {
    setOpenAddChild(false);
  };

  const handleAddPetOpen = () => {
    dispatch(resetApplicationSubmissionAction());
    setOpenAddPet(true);
  };

  const handleAddPetClose = () => {
    setOpenAddPet(false);
  };

  const handleAddVehicleOpen = () => {
    dispatch(resetApplicationSubmissionAction());
    setOpenAddVehicle(true);
  };

  const handleAddVehicleClose = () => {
    setOpenAddVehicle(false);
  };

  const handleAddHousingHistoryOpen = () => {
    dispatch(resetApplicationSubmissionAction());
    setOpenAddHousingHistory(true);
  };

  const handleAddHousingHistoryClose = () => {
    setOpenAddHousingHistory(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    id && dispatch(getSingleApplicationByIdAction(id));
  }, [id]);

  useEffect(() => {
    setEditingApplicant(applications.selectedApplication.value);
  }, [applications.selectedApplication?.value]);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const updateApplicantDetails = () => {
    editingApplicant &&
      editingApplicant.id &&
      editingApplicant.applicationType &&
      dispatch(
        updateApplicationAction({
          id: editingApplicant.id,
          type: editingApplicant.applicationType,
          body: editingApplicant,
        }),
      );
  };

  const editApplicantDetails = (e: FormEvent) => {
    e.preventDefault();
    if (editingApplicant) {
      updateApplicantDetails();
      handleNext();
    } else dispatch(showToastMessageAction({ message: 'Sorry, something went wrong :(', severity: 'error' }));
  };

  const handleUpdateApplicant = (key: keyof ReduxApplication, value?: string | boolean) => {
    editingApplicant && setEditingApplicant({ ...editingApplicant, [key]: value });
  };

  const handleUpdateContactInfo = (key: keyof IContactInfo, value?: string) => {
    setEditingApplicant({ ...editingApplicant, contactInfo: { ...editingApplicant?.contactInfo, [key]: value } });
  };

  const handleUpdateAddress = (key: keyof IPostalAddress, value?: string) => {
    setEditingApplicant({
      ...editingApplicant,
      contactInfo: {
        ...editingApplicant?.contactInfo,
        postalAddress: { ...editingApplicant?.contactInfo?.postalAddress, [key]: value },
      },
    });
  };
  const handleUpdateEmergencyContact = (key: keyof IContactPerson, value?: string) => {
    setEditingApplicant({
      ...editingApplicant,
      emergencyContact: { ...editingApplicant?.emergencyContact, [key]: value },
    });
  };

  const handleUpdateEmergencyContactInfo = (key: keyof IContactInfo, value?: string) => {
    setEditingApplicant({
      ...editingApplicant,
      emergencyContact: {
        ...editingApplicant?.emergencyContact,
        contactInfo: { ...editingApplicant?.emergencyContact?.contactInfo, [key]: value },
      },
    });
  };

  const handleUpdateEmployment = (key: keyof IEmployment, value?: string | boolean) => {
    setEditingApplicant({
      ...editingApplicant,
      employer: {
        ...editingApplicant?.employer,
        [key]: value,
      },
    });
  };

  const handleUpdateEmployerContact = (key: keyof IContactInfo, value?: string) => {
    setEditingApplicant({
      ...editingApplicant,
      employer: {
        ...editingApplicant?.employer,
        contactInfo: {
          ...editingApplicant?.employer?.contactInfo,
          [key]: value,
        },
      },
    });
  };

  const handleUpdateEmployerAddress = (key: keyof IPostalAddress, value: string | undefined) => {
    setEditingApplicant({
      ...editingApplicant,
      employer: {
        ...editingApplicant?.employer,
        contactInfo: {
          ...editingApplicant?.employer?.contactInfo,
          postalAddress: {
            ...editingApplicant?.employer?.contactInfo?.postalAddress,
            [key]: value,
          },
        },
      },
    });
  };

  const handleAddDependent = (type: string, newDependent: IPet | IPerson | IVehicle | ReduxHousingHistory) => {
    let updatedDependents;
    if (type === 'childrenInApartment') {
      if (editingApplicant?.childrenInApartment) {
        updatedDependents = [...editingApplicant?.childrenInApartment, newDependent];
      } else {
        updatedDependents = [newDependent];
      }
    } else if (type === 'pets') {
      if (editingApplicant?.pets) {
        updatedDependents = [...editingApplicant?.pets, newDependent];
      } else {
        updatedDependents = [newDependent];
      }
    } else if (type === 'vehicles') {
      if (editingApplicant?.vehicles) {
        updatedDependents = [...editingApplicant?.vehicles, newDependent];
      } else updatedDependents = [newDependent];
    } else if (type === 'housingHistory') {
      if (editingApplicant?.housingHistory) {
        updatedDependents = [...editingApplicant?.housingHistory, newDependent];
      } else updatedDependents = [newDependent];
    }
    editingApplicant?.id &&
      editingApplicant?.applicationType &&
      dispatch(
        updateApplicationAction({
          id: editingApplicant?.id,
          type: editingApplicant?.applicationType,
          body: { [type]: updatedDependents },
        }),
      );
  };

  if (applications.selectedApplication.loading) {
    return <FullScreenLoading />;
  }

  if (!id || !applications.selectedApplication.value || !editingApplicant) {
    return <NotFound />;
  }

  return (
    <>
      {!isAuthTokenSet && (
        <Toolbar sx={{ backgroundColor: appTheme.palette.primary.main, mb: '1rem' }}>
          <Typography variant={'h3'} color={'white'} fontWeight={500} fontSize={'1.5rem'}>
            Application Portal
          </Typography>
        </Toolbar>
      )}
      {editingApplicant.applicationStatus !== ApplicationStatus.UserEditing ? (
        <ReviewAndSubmit applicant={editingApplicant} />
      ) : editingApplicant.applicationType === ApplicationType.Primary ? (
        <WizardPrimaryForm
          editingApplicant={editingApplicant}
          updateApplicantDetails={updateApplicantDetails}
          onUpdateApplicant={handleUpdateApplicant}
          onUpdateAddress={handleUpdateAddress}
          onUpdateContactInfo={handleUpdateContactInfo}
          onNext={handleNext}
          onUpdateEmployerContact={handleUpdateEmployerContact}
          onBack={handleBack}
          onUpdateEmployment={handleUpdateEmployment}
          onUpdateEmployerAddress={handleUpdateEmployerAddress}
          editApplicantDetails={editApplicantDetails}
          activeStep={activeStep}
          onOpenOtherAdult={handleOtherAdultOpen}
          onOpenAddChild={handleAddChildOpen}
          onOpenAddVehicle={handleAddVehicleOpen}
          onOpenAddPet={handleAddPetOpen}
          onOpenAddHousingHistory={handleAddHousingHistoryOpen}
          onUpdateEmergencyContact={handleUpdateEmergencyContact}
          onUpdateEmergencyContactInfo={handleUpdateEmergencyContactInfo}
        />
      ) : editingApplicant.applicationType === ApplicationType.OtherAdult ? (
        <WizardOtherAdultForm
          editingApplicant={editingApplicant}
          updateApplicantDetails={updateApplicantDetails}
          onUpdateApplicant={handleUpdateApplicant}
          onUpdateAddress={handleUpdateAddress}
          onUpdateContactInfo={handleUpdateContactInfo}
          onNext={handleNext}
          onUpdateEmployerContact={handleUpdateEmployerContact}
          onUpdateEmployerAddress={handleUpdateEmployerAddress}
          onBack={handleBack}
          onUpdateEmployment={handleUpdateEmployment}
          editApplicantDetails={editApplicantDetails}
          activeStep={activeStep}
          onOpenAddVehicle={handleAddVehicleOpen}
          onOpenAddPet={handleAddPetOpen}
        />
      ) : (
        <WizardCosignerForm
          editingApplicant={editingApplicant}
          updateApplicantDetails={updateApplicantDetails}
          onUpdateApplicant={handleUpdateApplicant}
          onUpdateAddress={handleUpdateAddress}
          onUpdateContactInfo={handleUpdateContactInfo}
          onNext={handleNext}
          onUpdateEmployerContact={handleUpdateEmployerContact}
          onUpdateEmployerAddress={handleUpdateEmployerAddress}
          onBack={handleBack}
          onUpdateEmployment={handleUpdateEmployment}
          editApplicantDetails={editApplicantDetails}
          activeStep={activeStep}
          onOpenAddHousingHistory={handleAddHousingHistoryOpen}
        />
      )}
      <AddOtherAdultDialog open={openAddOtherAdult} onClose={handleOtherAdultClose} applicant={editingApplicant} />
      <AddVehicleDialog addVehicle={handleAddDependent} open={openAddVehicle} onClose={handleAddVehicleClose} />
      <AddPetDialog addPet={handleAddDependent} open={openAddPet} onClose={handleAddPetClose} />
      <AddChildDialog addChild={handleAddDependent} open={openAddChild} onClose={handleAddChildClose} />
      <AddHousingHistoryDialog
        addHousingHistory={handleAddDependent}
        open={openAddHousingHistory}
        onClose={handleAddHousingHistoryClose}
      />
    </>
  );
};
