import React, { FC, FormEvent } from 'react';
import {
  closeMoveTaskAction,
  moveTaskAction,
  useTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { AssetAutocomplete, AssetOption } from '../../autocompletes/AssetAutocomplete';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DialogLayout } from '../../dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { AssetType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const MoveTaskDialog: FC = () => {
  const dispatch = useDispatch();
  const {
    selectedContext: { propertyId: currentPropertyId },
  } = useAssets();
  const { moveTaskId } = useTasks();
  const [targetAsset, setTargetAsset] = React.useState<AssetOption | null>(null);

  const handleClose = () => {
    dispatch(closeMoveTaskAction());
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    moveTaskId &&
      targetAsset &&
      dispatch(
        moveTaskAction({
          taskId: moveTaskId,
          propertyId: targetAsset?.propertyId,
          buildingId: targetAsset?.buildingId,
          unitId: targetAsset?.unitId,
        }),
      );
  };

  return (
    <DialogLayout title={'Move Task'} onClose={handleClose} open={!!moveTaskId} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit}>
        <Box p={2}>
          <DialogTitle>Move task to another asset</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <AssetAutocomplete
                value={targetAsset}
                onChange={setTargetAsset}
                inputProps={{ required: true }}
                assetFilter={(node) => node.assetNodeType !== AssetType.RentalProperty || node.id === currentPropertyId}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained">
              Move
            </Button>
          </DialogActions>
        </Box>
      </form>
    </DialogLayout>
  );
};
