export const licenseFormats: { [state: string]: { regex: RegExp; formatString: string } } = {
  AL: { regex: /^(?:[0-9]{1,8})$/i, formatString: '1-8 Numeric' },
  AK: { regex: /^(?:[0-9]{1,7})$/i, formatString: '1-7 Numeric' },
  AZ: { regex: /^(?:[A-Z][0-9]{8}$|[0-9]{9})$/i, formatString: '1 Alpha + 8 Numeric or 9 Numeric' },
  AR: { regex: /^(?:[0-9]{4,9})$/i, formatString: '4-9 Numeric' },
  CA: { regex: /^(?:[A-Z][0-9]{7})$/i, formatString: '1 Alpha + 7 Numeric' },
  CO: {
    regex: /^(?:[0-9]{9}$|[A-Z][0-9]{3,6}$|[A-Z]{2}[0-9]{2,5})$/i,
    formatString: '9 Numeric or 1 Alpha + 3-6 Numeric or 2 Alpha + 2-5 Numeric',
  },
  CT: { regex: /^(?:[0-9]{9})$/i, formatString: '9 Numeric' },
  DE: { regex: /^(?:[0-9]{1,7})$/i, formatString: '1-7 Numeric' },
  DC: {
    regex: /^(?:[0-9]{7}$|[0-9]{9})$/i,
    formatString: '7 Numeric or 9 Numeric',
  },
  FL: { regex: /^(?:[A-Z][0-9]{12})$/i, formatString: '1 Alpha + 12 Numeric' },
  GA: { regex: /^(?:[0-9]{7,9})$/i, formatString: '7-9 Numeric' },
  HI: { regex: /^(?:[A-Z][0-9]{8}$|[0-9]{9})$/i, formatString: '1 Alpha + 8 Numeric or 9 Numeric' },
  ID: { regex: /^(?:[A-Z]{2}[0-9]{6}[A-Z]$|[0-9]{9})$/i, formatString: '2 Alpha + 6 Numeric + 1 Alpha or 9 Numeric' },
  IL: { regex: /^(?:[A-Z][0-9]{11}$|[A-Z][0-9]{12})$/i, formatString: '1 Alpha + 11 Numeric or 1 Alpha + 12 Numeric' },
  IN: {
    regex: /^(?:[A-Z][0-9]{9}$|[0-9]{9}$|[0-9]{10})$/i,
    formatString: '1 Alpha + 9 Numeric or 9 Numeric or 10 Numeric',
  },
  IA: {
    regex: /^(?:[0-9]{9}$|[0-9]{3}[A-Z]{2}[0-9]{4})$/i,
    formatString: '9 Numeric or 3 Numeric + 2 Alpha + 4 Numeric',
  },
  KS: {
    regex: /^(?:[A-Z][0-9][A-Z][0-9][A-Z]$|[A-Z][0-9]{8}$|[0-9]{9})$/i,
    formatString: '1 Alpha + 1 Numeric + 1 Alpha + 1 Numeric + 1 Alpha or 1 Alpha + 8 Numeric or 9 Numeric',
  },
  KY: {
    regex: /^(?:[A-Z][0-9]{8}$|[A-Z][0-9]{9}$|[0-9]{9})$/i,
    formatString: '1 Alpha + 8 Numeric or 1 Alpha + 9 Numeric or 9 Numeric',
  },
  LA: { regex: /^(?:[0-9]{1,9})$/i, formatString: '1-9 Numeric' },
  ME: {
    regex: /^(?:[0-9]{7}$|[0-9]{7}[A-Z]$|[0-9]{8})$/i,
    formatString: '7 Numeric or 7 Numeric + 1 Alpha or 8 Numeric',
  },
  MD: { regex: /^(?:[A-Z][0-9]{12})$/i, formatString: '1 Alpha + 12 Numeric' },
  MA: { regex: /^(?:[A-Z][0-9]{8}$|[0-9]{9})$/i, formatString: '1 Alpha + 8 Numeric or 9 Numeric' },
  MI: {
    regex: /^(?:[A-Z][0-9]{10}$|[A-Z][0-9]{12})$/i,
    formatString: '1 Alpha + 10 Numeric or 1 Alpha + 12 Numeric',
  },
  MN: { regex: /^(?:[A-Z][0-9]{12})$/i, formatString: '1 Alpha + 12 Numeric' },
  MS: { regex: /^(?:[0-9]{9})$/i, formatString: '9 Numeric' },
  MO: {
    regex: /^(?:[A-Z][0-9]{5,9}$|[A-Z][0-9]{6}R$|[0-9]{8}[A-Z]{2}$|[0-9]{9}[A-Z]$|[0-9]{9})$/i,
    formatString:
      '1 Alpha + 5-9 Numeric or 1 Alpha + 6 Numeric + R or 8 Numeric + 2 Alpha or 9 Numeric + 1 Alpha or 9 Numeric',
  },
  MT: {
    regex: /^(?:[A-Z][0-9]{8}$|[0-9]{13}$|[0-9]{9}$|[0-9]{14})$/i,
    formatString: '1 Alpha + 8 Numeric or 13 Numeric or 9 Numeric or 14 Numeric',
  },
  NE: { regex: /^(?:[A-Z][0-9]{6,8})$/i, formatString: '1 Alpha + 6-8 Numeric' },
  NV: {
    regex: /^(?:[0-9]{9}$|[0-9]{10}$|[0-9]{12}$|[X][0-9]{8})$/i,
    formatString: '9 Numeric or 10 Numeric or 12 Numeric or X + 8 Numeric',
  },
  NH: { regex: /^(?:[0-9]{2}[A-Z]{3}[0-9]{5})$/i, formatString: '2 Numeric + 3 Alpha + 5 Numeric' },
  NJ: { regex: /^(?:[A-Z][0-9]{14})$/i, formatString: '1 Alpha + 14 Numeric' },
  NM: { regex: /^(?:[0-9]{8}$|[0-9]{9})$/i, formatString: '8 Numeric or 9 Numeric' },
  NY: {
    regex: /^(?:[A-Z][0-9]{7}$|[A-Z][0-9]{18}$|[0-9]{8}$|[0-9]{9}$|[0-9]{16}$|[A-Z]{8})$/i,
    formatString: '1 Alpha + 7 Numeric or 1 Alpha + 18 Numeric or 8 Numeric or 9 Numeric or 16 Numeric or 8 Alpha',
  },
  NC: { regex: /^(?:[0-9]{1,12})$/i, formatString: '1-12 Numeric' },
  ND: { regex: /^(?:[A-Z]{3}[0-9]{6}$|[0-9]{9})$/i, formatString: '3 Alpha + 6 Numeric or 9 Numeric' },
  OH: {
    regex: /^(?:[A-Z][0-9]{4,8}$|[A-Z]{2}[0-9]{3,7}$|[0-9]{8})$/i,
    formatString: '1 Alpha + 4-8 Numeric or 2 Alpha + 3-7 Numeric or 8 Numeric',
  },
  OK: { regex: /^(?:[A-Z][0-9]{9}$|[0-9]{9})$/i, formatString: '1 Alpha + 9 Numeric or 9 Numeric' },
  OR: { regex: /^(?:[0-9]{1,9})$/i, formatString: '1-9 Numeric' },
  PA: { regex: /^(?:[0-9]{8})$/i, formatString: '8 Numeric' },
  RI: { regex: /^(?:[0-9]{7}$|[A-Z][0-9]{6})$/i, formatString: '7 Numeric or 1 Alpha + 6 Numeric' },
  SC: { regex: /^(?:[0-9]{5,11})$/i, formatString: '5-11 Numeric' },
  SD: { regex: /^(?:[0-9]{6,10}$|[0-9]{12})$/i, formatString: '6-10 Numeric or 12 Numeric' },
  TN: { regex: /^(?:[0-9]{7,9})$/i, formatString: '7-9 Numeric' },
  TX: { regex: /^(?:[0-9]{7,8})$/i, formatString: '7-8 Numeric' },
  UT: { regex: /^(?:[0-9]{4,10})$/i, formatString: '4-10 Numeric' },
  VT: { regex: /^(?:[0-9]{8}$|[0-9]{7}[A-Z])$/i, formatString: '8 Numeric or 7 Numeric + 1 Alpha' },
  VA: { regex: /^(?:[A-Z][0-9]{8,11}$|[0-9]{9})$/i, formatString: '1 Alpha + 8-11 Numeric or 9 Numeric' },
  WA: { regex: /^(?:[A-Z]{1,7}[A-Z0-9*]{5})$/i, formatString: '1-7 Alpha + 5 Alpha/Numeric' },
  WV: { regex: /^(?:[0-9]{7}$|[A-Z]{1,2}[0-9]{5,6})$/i, formatString: '7 Numeric or 1-2 Alpha + 5-6 Numeric' },
  WI: { regex: /^(?:[A-Z][0-9]{13})$/i, formatString: '1 Alpha + 13 Numeric' },
  WY: { regex: /^(?:[0-9]{9,10})$/i, formatString: '9-10 Numeric' },
};
