import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { appTheme } from '../../../../../../AppTheme';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { UnitDefaultDetailsDialog } from './UnitDefaultDetailsDialog';
import { AddUnitDefaultDialog } from './AddUnitDefaultDialog';
import {
  IUnitDetailOption,
  UnitDetailOptionType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  deleteUnitDetailOptionAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import LinearProgress from '@mui/material/LinearProgress';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import AddIcon from '@mui/icons-material/Add';

export const UnitSetupDefaults = () => {
  const { selectedProperty } = useProperty();
  const dispatch = useDispatch();
  const unitDetailOptions = selectedProperty.value?.unitDetailOptions;
  const [selectedDefault, setSelectedDefault] = useState<IUnitDetailOption | undefined>();
  const [openAddDefault, setOpenAddDefault] = useState(false);
  const handleCloseAddDefault = () => setOpenAddDefault(false);

  return (
    <StyledInfoBox label={'Unit Configuration Defaults'}>
      <Stack spacing={1}>
        <Box>
          <Button variant={'outlined'} onClick={() => setOpenAddDefault(true)} startIcon={<AddIcon />}>
            Add Default
          </Button>
        </Box>
        {!unitDetailOptions ||
          (unitDetailOptions.length === 0 && (
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
              <Typography variant={'h6'} fontStyle={'italic'} color={appTheme.palette.grey['800']}>
                No Unit Setup Defaults
              </Typography>
            </Box>
          ))}
        {selectedProperty.loading && (
          <Box sx={{ height: '.1rem' }}>
            <LinearProgress />
          </Box>
        )}
        <List sx={{ width: '100%', height: '90%', backgroundColor: 'background.paper', padding: '0rem' }}>
          {/*  group by category if category exists?*/}
          {unitDetailOptions?.map((config) => (
            <ListItem key={config.id} sx={{ display: 'flex', flexGrow: 1, paddingX: '0rem' }}>
              <Card sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
                <CardContent>
                  <Typography component="div">{config.name}</Typography>
                  <Typography variant="body2" color="text.secondary" component="div">
                    Default Value:{' '}
                    {config.type === UnitDetailOptionType.Boolean
                      ? config.defaultValue === 'true'
                        ? 'yes'
                        : 'no'
                      : config.defaultValue}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button variant={'outlined'} onClick={() => setSelectedDefault(config)}>
                    See Details
                  </Button>
                  <Tooltip title={'Delete Default'}>
                    <IconButton
                      onClick={() =>
                        selectedProperty.value?.id &&
                        config.id &&
                        dispatch(
                          deleteUnitDetailOptionAction({
                            id: selectedProperty.value?.id,
                            configId: config.id,
                          }),
                        )
                      }
                    >
                      {selectedProperty.submitting ? <CircularProgress size={15} /> : <DeleteIcon />}
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </Card>
            </ListItem>
          ))}
        </List>
      </Stack>
      <AddUnitDefaultDialog open={openAddDefault} onClose={handleCloseAddDefault} />
      <UnitDefaultDetailsDialog
        value={selectedDefault}
        open={selectedDefault !== undefined}
        onClose={() => setSelectedDefault(undefined)}
      />
    </StyledInfoBox>
  );
};
