import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { createBuildingAction, useBuilding } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/buildingSlice';
import { IBuilding } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { StyledInfoBox } from '../../../_shared/styledComponents/StyledInfoBox';
import LoadingButton from '@mui/lab/LoadingButton';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';
import { NumberValueTextField } from '../../../_shared/texfields/NumberValueTextField';
import { USStatesSelect } from '../../../_shared/texfields/USStateSelect';

type Props = {
  propertyId: string | undefined;
  open: boolean;
  onClose: () => any;
};

export const AddBuilding = ({ open, onClose, propertyId }: Props) => {
  const dispatch = useDispatch();
  const { selectedBuilding } = useBuilding();

  const [newBuilding, setNewBuilding] = useState<IBuilding>({
    name: '',
    rentalPropertyId: propertyId,
    details: {
      mailingAddress: {
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        zip: '',
      },
    },
  });

  useEffect(() => setNewBuilding({ ...newBuilding, rentalPropertyId: propertyId }), [propertyId]);

  useEffect(() => {
    if (selectedBuilding.submitted) {
      setNewBuilding({
        name: '',
        rentalPropertyId: propertyId,
        details: {
          mailingAddress: {
            streetAddress1: '',
            streetAddress2: '',
            city: '',
            state: '',
            zip: '',
          },
        },
      });
      onClose();
    }
  }, [selectedBuilding.submitted]);

  const handleName = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNewBuilding({ ...newBuilding, name: e.target.value });
  };

  const handleAddress = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, key: string) => {
    setNewBuilding({
      ...newBuilding,
      details: {
        ...newBuilding.details,
        mailingAddress: { ...(newBuilding.details?.mailingAddress ?? {}), [key]: e.target.value },
      },
    });
  };

  const addBuilding = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(createBuildingAction(newBuilding));
  };

  const handleSelectState = (state: string) => {
    setNewBuilding({
      ...newBuilding,
      details: {
        ...newBuilding.details,
        mailingAddress: { ...newBuilding.details?.mailingAddress, state },
      },
    });
  };

  return (
    <DialogLayout title={'Add Building'} maxWidth="md" fullWidth={true} open={open} onClose={onClose}>
      <DialogContent>
        <form onSubmit={addBuilding} autoComplete="off">
          <StyledInfoBox label={'Building Info'}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  fullWidth
                  required
                  margin="dense"
                  id="buildingName"
                  label="Building Name"
                  type="text"
                  variant="standard"
                  value={newBuilding.name}
                  onChange={handleName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  margin="dense"
                  id="streetAddress1"
                  label="Street Address 1"
                  type="text"
                  variant="standard"
                  onChange={(e) => {
                    handleAddress(e, 'streetAddress1');
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="dense"
                  id="streetAddress2"
                  label="Street Address 2"
                  type="text"
                  variant="standard"
                  onChange={(e) => {
                    handleAddress(e, 'streetAddress2');
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  margin="dense"
                  id="city"
                  label="City"
                  type="text"
                  variant="standard"
                  onChange={(e) => {
                    handleAddress(e, 'city');
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <USStatesSelect
                  value={newBuilding.details?.mailingAddress?.state ?? ''}
                  onChange={handleSelectState}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <NumberValueTextField
                  valueUnits={'none'}
                  fullWidth
                  required
                  margin="dense"
                  id="zip"
                  label="Zip"
                  variant="standard"
                  onChange={(e) => {
                    handleAddress(e, 'zip');
                  }}
                />
              </Grid>
            </Grid>
          </StyledInfoBox>
          <Grid item xs={12}>
            <DialogActions sx={{ marginTop: '1rem' }}>
              <Button onClick={onClose}>Close</Button>
              <LoadingButton type="submit" variant="contained" loading={selectedBuilding.submitting}>
                Add Building
              </LoadingButton>
            </DialogActions>
          </Grid>
        </form>
      </DialogContent>
    </DialogLayout>
  );
};
