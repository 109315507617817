import React, { FC, FormEvent, useEffect, useState } from 'react';
import {
  closedLeaseStatuses,
  leaseStatusMap,
  ReduxLease,
  ReduxLeaseTerm,
  ReduxTenant,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import {
  AssociationType,
  LeaseProcessStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import HouseOutlined from '@mui/icons-material/HouseOutlined';
import { DocumentList } from '../../../documents/components/DocumentList';
import { useDispatch } from 'react-redux';
import {
  addCommentToLeaseAction,
  addWatcherToLeaseAction,
  getSingleLeaseAction,
  removeWatcherFromLeaseAction,
  unsetSingleLeaseAction,
  updateCurrentLeaseTermsAction,
  updateLeaseAction,
  useLeases,
} from '../../redux/leasesSlice';
import { CommentList } from '../../../comments/CommentList';
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker } from '@mui/x-date-pickers-pro';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetRouteType } from '@monkeyjump-labs/cam-fe-shared/dist/types/assetTypes';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { addDays, addYears } from 'date-fns';
import { LeaseTermsEditor } from '../LeaseTermsEditor';
import { getAssetTypeFromPathname } from '../../../utils/getAssetTypeFromPathname';
import { TenantInfo } from '../../../tenants/TenantInfo';
import Box from '@mui/material/Box';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { LeasePaymentHistory } from './LeasePaymentHistory';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import { LeaseDialogTab } from '../../../tabs/TabTypeEnums';
import { BetaTab } from '../../../../global/Feature';
import { Emails } from '../../../communications/components/Emails';
import { SmsView } from '../../../communications/components/SmsView';
import { getLeaseTenantNames } from '../../../utils/getLeaseTenantNames';
import { useRouting } from '../../../utils/useRouting';
import {
  attachmentAddedToAssociationAction,
  useCommunication,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/communications/communicationSlice';
import { dialogChildIsOpenAction, useGlobal } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { WatcherAutocomplete } from '../../../tasks/components/WatcherAutocomplete';

type LeaseTabParams = {
  id: string;
  outerTab: string;
  innerTab: string;
  leaseId: string;
  tab: LeaseDialogTab;
};

export const LeaseDialog: FC = () => {
  const dispatch = useDispatch();
  const { activeLease } = useLeases();
  const { dialogChildIsOpen } = useGlobal();
  const { handleNavigateFromAssociationClose } = useRouting();
  const { selectedContext } = useAssets();
  const { attachmentAddedToAssociation } = useCommunication();
  const [currentTab, setCurrentTab] = useState(LeaseDialogTab.General);
  const [editingLease, setEditingLease] = useState<ReduxLease | undefined>(undefined);
  const { id, outerTab, innerTab, leaseId, tab } = useParams<LeaseTabParams>();
  const [assetType, setAssetType] = useState<AssetRouteType | undefined>();
  const navigate = useNavigate();
  const [dirty, setDirty] = useState(false);
  const [currentLeaseTermsDirty, setCurrentLeaseTermsDirty] = useState(false);
  const handleSetCurrentLeaseTermsDirty = () => setCurrentLeaseTermsDirty(true);
  const [tenantGroup, setTenantGroup] = useState(
    editingLease?.leaseTerms ? editingLease.leaseTerms[editingLease.leaseTerms.length - 1]?.tenantGroup : undefined,
  );

  useEffect(() => {
    setTenantGroup(
      editingLease?.leaseTerms ? editingLease.leaseTerms[editingLease.leaseTerms.length - 1]?.tenantGroup : undefined,
    );
  }, [editingLease?.leaseTerms && editingLease?.leaseTerms[editingLease?.leaseTerms?.length - 1]?.tenantGroup]);

  useEffect(() => {
    if (activeLease.loaded) {
      setEditingLease(activeLease.value);
      setAssetType(getAssetTypeFromPathname());
    }
  }, [activeLease.value]);

  useEffect(() => {
    leaseId && dispatch(getSingleLeaseAction({ leaseId, openLeaseModal: true }));
  }, [leaseId]);

  useEffect(() => {
    if (attachmentAddedToAssociation === AssociationType.Lease) {
      leaseId && dispatch(getSingleLeaseAction({ leaseId, openLeaseModal: true }));
      dispatch(attachmentAddedToAssociationAction(undefined));
    }
  }, [attachmentAddedToAssociation]);

  useEffect(() => {
    tab && setCurrentTab(tab);
  }, [tab]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: LeaseDialogTab) => {
    setCurrentTab(newValue);
    navigate(`/assets/${assetType}/${id}/${outerTab}/${innerTab}/lease/${leaseId}/${newValue}`);
  };

  const handleUpdateTenants = (tenants: ReduxTenant[]) => {
    activeLease.value &&
      id &&
      dispatch(
        updateLeaseAction({
          leaseType: 'single',
          assetId: id,
          body: {
            ...activeLease.value,
            leaseTerms:
              activeLease.value.leaseTerms?.map((term) => ({
                ...term,
                tenantGroup: { ...term.tenantGroup, tenants: tenants },
              })) ?? [],
          },
        }),
      );
  };

  const handleSetMoveOutDate = (date?: Date | null) => {
    setEditingLease({ ...editingLease, moveOutDate: date !== null ? toReduxDate(date) : undefined });
    setDirty(true);
  };

  const handleSetStartDate = (date: Date, selectedTerm: number) => {
    const endReduxDate = toReduxDate(addDays(addYears(new Date(date), 1), -1));
    const editedLeaseTerms = [...editingLease!.leaseTerms!];
    editedLeaseTerms[selectedTerm] = {
      ...editedLeaseTerms[selectedTerm],
      startDate: toReduxDate(date),
      endDate: endReduxDate,
    };
    setEditingLease({
      ...editingLease,
      leaseTerms: editedLeaseTerms,
    });
    setDirty(true);
  };

  const handleSetEndDate = (date: Date, selectedTerm: number) => {
    const editedLeaseTerms = [...editingLease!.leaseTerms!];
    editedLeaseTerms[selectedTerm] = {
      ...editedLeaseTerms[selectedTerm],
      endDate: toReduxDate(date),
    };
    setEditingLease({
      ...editingLease,
      leaseTerms: editedLeaseTerms,
    });
    setDirty(true);
  };

  const handleLeaseTermsChange = (leaseTerms: ReduxLeaseTerm[]) => {
    setEditingLease({ ...editingLease, leaseTerms: leaseTerms });
    setDirty(true);
  };

  const handleAddComment = (comment: string) => {
    if (editingLease?.id && id) {
      dispatch(
        addCommentToLeaseAction({
          propertyId: editingLease.propertyId!,
          leaseId: editingLease.id,
          comment,
          assetId: id,
        }),
      );
    }
  };

  const handleClose = () => {
    if (dialogChildIsOpen) {
      dispatch(dialogChildIsOpenAction(false));
    } else {
      dispatch(unsetSingleLeaseAction());
      setEditingLease(undefined);
      setDirty(false);
      handleNavigateFromAssociationClose();
    }
  };

  const handleChangeWatchers = (updatedWatchers: string[]) => {
    console.log('updatedWatchers', updatedWatchers);
    const watchers = editingLease?.watchers?.map((watcher) => watcher.id ?? '') ?? [];
    console.log('watchers', watchers);
    const addUser = updatedWatchers.filter((watcher) => !watchers.some((w) => w === watcher));
    if (addUser.length === 1) {
      dispatch(
        addWatcherToLeaseAction({
          leaseId: editingLease?.id ?? '',
          userId: addUser[0],
        }),
      );
    }

    const removeUser = watchers.filter((watcher) => !updatedWatchers.some((w) => w === watcher));
    console.log('removeUser', removeUser);
    if (removeUser.length === 1) {
      dispatch(
        removeWatcherFromLeaseAction({
          leaseId: editingLease?.id ?? '',
          userId: removeUser[0],
        }),
      );
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!editingLease || !id) return;
    if (currentLeaseTermsDirty) {
      dispatch(
        updateCurrentLeaseTermsAction({
          leaseType: 'single',
          body: editingLease,
          assetId: id,
        }),
      );
    } else {
      dispatch(
        updateLeaseAction({
          leaseType: 'single',
          body: editingLease,
          assetId: id,
        }),
      );
    }
    setDirty(false);
    setCurrentLeaseTermsDirty(false);
  };

  const tenantNames = editingLease && getLeaseTenantNames(editingLease);
  const assetPath = `${editingLease?.buildingName} / ${editingLease?.unitName}`;

  return (
    <DialogLayout
      title={tenantNames?.primaryTenantName ? `Lease: ${tenantNames?.primaryTenantName}` : `Lease`}
      subtitle={assetPath}
      fullWidth
      fullScreen
      maxWidth={'lg'}
      // PaperProps={{ sx: { height: '60%', minHeight: 480 } }}
      open={!!activeLease.value && !!editingLease && activeLease.open}
      onClose={handleClose}
    >
      <DialogContent sx={{ mt: '.1rem' }}>
        <Tabs value={currentTab} onChange={(e, v) => handleTabChange(e, v)}>
          <Tab value={LeaseDialogTab.General} label="General Information" />
          <Tab value={LeaseDialogTab.Rent} label="Rent Information" />
          <Tab value={LeaseDialogTab.Tenant} label="Tenant Info" />
          <Tab value={LeaseDialogTab.Documents} label="Documents" />
          <Tab value={LeaseDialogTab.Comments} label="Comments" />
          <BetaTab flag="Feature.Communications" label="Communications" value={LeaseDialogTab.Communications} />
          <Tab value={LeaseDialogTab.PaymentHistory} label="Payment History" />
        </Tabs>
        {activeLease.loading && <LinearProgress variant="indeterminate" />}
        {
          {
            general: (
              <form onSubmit={handleSubmit} autoComplete="off">
                <StyledInfoBox label={'General Information'}>
                  <Grid container spacing={2}>
                    <Grid container item xs={4} alignItems="flex-end">
                      <Chip
                        icon={<HouseOutlined />}
                        sx={{ width: '100%', justifyContent: 'space-between', color: 'white' }}
                        color={'primary'}
                        label={`Status: ${
                          editingLease?.detailedStatus ? leaseStatusMap.get(editingLease?.detailedStatus) : ''
                        }`}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Unit"
                        value={editingLease?.unitName ? editingLease.unitName : ''}
                        inputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Building"
                        value={editingLease?.buildingName ? editingLease.buildingName : ''}
                        inputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DatePicker
                        label={editingLease?.moveOutDate ? 'Move Out Date' : 'Set Move Out Date'}
                        onChange={(date) => handleSetMoveOutDate(date)}
                        value={editingLease?.moveOutDate ? toStandardDate(editingLease?.moveOutDate) : null}
                        disabled={
                          editingLease?.processStatus &&
                          editingLease.detailedStatus != undefined &&
                          closedLeaseStatuses.includes(editingLease.detailedStatus)
                        }
                        slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                        minDate={new Date(selectedContext.currentDate ?? '')}
                        referenceDate={new Date(selectedContext.currentDate ?? '')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexGow: 1,
                          justifyContent: 'right',
                        }}
                      >
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          disabled={!dirty}
                          loading={activeLease.submitting}
                        >
                          Save
                        </LoadingButton>
                      </Box>
                    </Grid>
                  </Grid>
                </StyledInfoBox>
                <StyledInfoBox label={'Watchers'}>
                  <WatcherAutocomplete
                    setWatchers={(updateWatchers) => handleChangeWatchers(updateWatchers)}
                    watchers={editingLease?.watchers?.map((x) => x.id!) ?? []}
                  />
                </StyledInfoBox>
              </form>
            ),
            rent: (
              <form onSubmit={handleSubmit} autoComplete="off">
                <StyledInfoBox label={'Rent Information'}>
                  <LeaseTermsEditor
                    value={editingLease?.leaseTerms ?? []}
                    onChange={handleLeaseTermsChange}
                    editable
                    isNew={
                      editingLease?.processStatus === LeaseProcessStatus.PreLeased ||
                      editingLease?.processStatus === LeaseProcessStatus.Draft ||
                      editingLease?.processStatus === LeaseProcessStatus.DepositPaid
                    }
                    onSetStartDate={handleSetStartDate}
                    onSetEndDate={handleSetEndDate}
                    onSetCurrentLeaseTermsDirty={handleSetCurrentLeaseTermsDirty}
                  />
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexGow: 1,
                        justifyContent: 'right',
                      }}
                    >
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        disabled={!dirty}
                        loading={activeLease.submitting}
                      >
                        Save
                      </LoadingButton>
                    </Box>
                  </Grid>
                </StyledInfoBox>
              </form>
            ),
            tenant: editingLease?.id && id && (
              <TenantInfo
                leaseId={editingLease?.id}
                tenants={tenantGroup?.tenants ?? []}
                onUpdateTenants={handleUpdateTenants}
                leasesType={'single'}
                assetId={id}
              />
            ),
            documents: (
              <StyledInfoBox label={'Documents'}>
                <Typography variant={'body2'} mb={'1rem'}>
                  *Uploading a signed lease? Use the action button in the table instead of uploading here.
                </Typography>
                <DocumentList
                  propertyId={editingLease?.propertyId}
                  association={{
                    associatedId: editingLease?.id,
                    associationType: AssociationType.Lease,
                  }}
                  value={editingLease?.documents ?? []}
                  uploading={activeLease.submitting}
                />
              </StyledInfoBox>
            ),
            comments: (
              <StyledInfoBox label={'Comments'}>
                <CommentList
                  comments={editingLease?.comments ?? []}
                  onAddComment={handleAddComment}
                  loading={activeLease.submitting}
                />
              </StyledInfoBox>
            ),
            communications: (
              <>
                <Emails
                  associationInfo={{
                    associatedId: editingLease?.id,
                    associationType: AssociationType.Lease,
                    emailAddresses:
                      tenantGroup?.tenants.filter((t) => t.contactInfo?.email).map((t) => t.contactInfo!.email!) ?? [],
                    label: editingLease?.unitName ?? 'Lease',
                  }}
                />
                <SmsView associationType={AssociationType.Lease} associationId={editingLease?.id} />
              </>
            ),
            paymentHistory: <LeasePaymentHistory />,
          }[currentTab || 'general']
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </DialogLayout>
  );
};
