import React, { useState } from 'react';
import {
  setPropertyEmailAddressAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { ConfigurationLayout } from './ConfigurationLayout';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch } from 'react-redux';

export const PropertyEmailAddressConfiguration = () => {
  const property = useProperty();
  const dispatch = useDispatch();
  const selectedPropertyEmail = property.selectedProperty.value?.details?.email;
  const [propertyEmail, setPropertyEmail] = useState(selectedPropertyEmail ?? '');

  const handleSubmitEmail = () => {
    property.selectedProperty?.value?.id &&
      dispatch(setPropertyEmailAddressAction({ propertyId: property.selectedProperty.value.id, email: propertyEmail }));
  };

  return (
    <ConfigurationLayout title={'Property Email Configuration'}>
      <Typography variant={'subtitle2'}>
        This will be the email address used for all correspondence through the Smart Management platform.
      </Typography>
      <Typography variant={'subtitle2'}>
        Once it is set, you must contact Smart Management to update the address.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: '1rem' }}>
        <Typography>Property Email Address</Typography>
        <Box sx={{ display: 'flex', flexGrow: 1 }}></Box>
        <TextField
          value={propertyEmail ?? ''}
          sx={{ minWidth: '40%' }}
          variant={'outlined'}
          InputProps={{ readOnly: selectedPropertyEmail !== undefined && selectedPropertyEmail !== null }}
          onChange={(e) => setPropertyEmail(e.target.value)}
        />
        <Typography sx={{ pl: '.1rem' }}> @mail.smartmanagement.com</Typography>
      </Box>
      {!selectedPropertyEmail ? (
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
          <LoadingButton
            variant={'contained'}
            onClick={handleSubmitEmail}
            loading={property.selectedProperty.submitting}
          >
            Submit Email Address
          </LoadingButton>
        </Box>
      ) : (
        <></>
      )}
    </ConfigurationLayout>
  );
};
