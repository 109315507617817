import React, { useEffect, useState } from 'react';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import { ConfigurationTable } from './ConfigurationTable';
import { EditConfigModal } from './EditConfigModal';
import { GridRowId } from '@mui/x-data-grid-premium';
import {
  getNotificationConfigurationByAssetIdAction,
  getSupportedRolesAction,
  resetGlobalNotificationSubmissionAction,
  useNotifications,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/notifications/notificationSlice';
import { useDispatch } from 'react-redux';
import { NotFound } from '../../../../notFound/NotFound';
import { getAutomationsByPropertyIdAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import { ReduxNotificationConfiguration } from '@monkeyjump-labs/cam-fe-shared/dist/types/notificationTypes';

type Props = {
  assetId: string | undefined;
};

export const NotificationsConfiguration = ({ assetId }: Props) => {
  const dispatch = useDispatch();
  const notifications = useNotifications();
  const [open, setOpen] = useState(false);
  const [selectedNotificationConfig, setSelectedNotificationConfig] = useState<
    ReduxNotificationConfiguration | undefined
  >(undefined);
  const [notificationsConfig, setNotificationsConfig] = useState<ReduxNotificationConfiguration[]>(
    notifications.notificationConfiguration?.value ?? [],
  );

  useEffect(() => {
    assetId && dispatch(getNotificationConfigurationByAssetIdAction(assetId));
    dispatch(getSupportedRolesAction());
  }, [assetId]);

  useEffect(() => {
    notifications.notificationConfiguration.value &&
      setNotificationsConfig(notifications.notificationConfiguration.value);
  }, [notifications.notificationConfiguration.value]);

  const handleOpen = () => {
    setOpen(true);
    assetId &&
      dispatch(
        getAutomationsByPropertyIdAction({
          id: assetId,
          page: 0,
          pageSize: 50,
          associationType: undefined,
          associatedId: undefined,
          includePropertyTasks: undefined,
        }),
      );
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedNotificationConfig(undefined);
    dispatch(resetGlobalNotificationSubmissionAction());
  };

  const handleSelectNotification = (id: GridRowId) => {
    const selectedConfig = notificationsConfig && notificationsConfig.find((config) => config.code === id);
    setSelectedNotificationConfig(selectedConfig);
  };

  if (!notificationsConfig) {
    return (
      <StyledInfoBox label={'Notification Configurations'}>
        <NotFound />
      </StyledInfoBox>
    );
  }

  return (
    <StyledInfoBox label={'Notification Configurations'}>
      <ConfigurationTable
        notifications={notificationsConfig}
        onOpen={handleOpen}
        onSelectNotification={handleSelectNotification}
      />
      {selectedNotificationConfig ? (
        <EditConfigModal open={open} onClose={handleClose} notification={selectedNotificationConfig} />
      ) : (
        <></>
      )}
    </StyledInfoBox>
  );
};
