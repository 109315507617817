import React, { FC, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Loading } from './Loading';
import Box from '@mui/material/Box';

const loadingMessages = [
  'Loading...',
  '🏢 Gathering property information',
  'Assembling your virtual properties',
  '📈 Analyzing leases',
  'Getting ready for you',
  '☕ Almost there...',
];

const FadeInTypography = styled(Typography)({
  transition: 'all 1s ease-in-out',
});

export const FullScreenLoading: FC = () => {
  const [showText, setShowText] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState(0);
  useEffect(() => {
    setTimeout(() => {
      setShowText(true);
    }, 1500);
  }, []);
  useEffect(() => {
    if (!showText) return;
    if (loadingMessage !== loadingMessages.length - 1) {
      setTimeout(() => {
        setLoadingMessage((loadingMessage + 1) % loadingMessages.length);
      }, 1500);
    }
  }, [loadingMessage, showText]);
  return (
    <Container
      maxWidth={false}
      sx={{
        height: '100vh',
        width: '100vw',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      disableGutters
    >
      <Stack alignItems="center" sx={{ width: '100%', justifyContent: 'center' }}>
        <Box display={'flex'} flexGrow={1} justifyContent={'center'}>
          <Loading loading={true} variation={'primary'} size={'large'} />
        </Box>
        <Container
          sx={{
            width: '100%',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loadingMessages.map((x) => (
            <FadeInTypography
              key={x}
              variant="h5"
              sx={{
                position: 'absolute',
                top: showText ? 48 : 0,
                opacity: showText && x === loadingMessages[loadingMessage] ? 1 : 0,
                height: 0,
              }}
            >
              {x}
            </FadeInTypography>
          ))}
        </Container>
      </Stack>
    </Container>
  );
};
