import React, { useEffect, useState } from 'react';
import { PieChartData } from '../Metrics';
import Grid from '@mui/material/Grid';
import { CustomLegend } from './occupancyPieChart/CustomChartComponents';
import { useFormatPieData } from './occupancyPieChart/useFormatPieData';
import { useOccupancyMetrics } from '@monkeyjump-labs/cam-fe-shared/dist/redux/metrics/metricsSlice';
import { OccupancyMetrics } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { makeRequired } from '../../../../../../../_shared/utils/makeRequired';
import { OccupancyPieChart } from './occupancyPieChart/OccupancyPieChart';
import { KpiDisplay } from './KpiDisplay';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';

export const CurrentOccupancyMetrics = () => {
  const { currentPropertyMetrics } = useOccupancyMetrics();
  const { formatInnerPieData, formatSecondPieData, formatThirdPieData, formatFourthPieData } = useFormatPieData(
    makeRequired(currentPropertyMetrics.value?.occupancyMetrics ?? new OccupancyMetrics(), 0),
  );
  const [firstPieData, setFirstPieData] = useState<PieChartData[]>([]);
  const [secondPieData, setSecondPieData] = useState<PieChartData[]>([]);
  const [thirdPieData, setThirdPieData] = useState<PieChartData[]>([]);
  const [fourthPieData, setFourthPieData] = useState<PieChartData[]>([]);

  const occupiedUnits = currentPropertyMetrics.value?.occupancyMetrics?.occupiedUnits ?? 0;
  const rentableUnits = currentPropertyMetrics.value?.occupancyMetrics?.rentableUnits ?? 0;

  const [pieFocus, setPieFocus] = useState<number>(0);
  const [legendValues, setLegendValues] = useState<string[]>(['Occupied', 'Vacant']);

  const sliderMarks = [
    { value: 0, label: 'Occupancy' },
    {
      value: 1,
      label: 'Unit/Financial Status',
    },
    {
      value: 2,
      label: 'Move-Outs',
    },
    {
      value: 3,
      label: 'Pre-Leasing',
    },
  ];

  const handleSetPieFocus = (event: Event, newValue: number | number[]) => {
    setPieFocus(newValue as number);
    switch (newValue) {
      case 0:
        setLegendValues(['Occupied', 'Vacant']);
        break;
      case 1:
        setLegendValues([
          'Fully Paid',
          'Delinquent',
          'Eviction',
          'Under Construction',
          'Rent Ready',
          'Not Rent Ready',
          'Unavailable',
        ]);
        break;
      case 2:
        setLegendValues(['Move-Out Scheduled']);
        break;
      case 3:
        setLegendValues(['Pre-Leased']);
        break;
    }
  };

  useEffect(() => {
    setFirstPieData(formatInnerPieData());
    setSecondPieData(formatSecondPieData());
    setThirdPieData(formatThirdPieData());
    setFourthPieData(formatFourthPieData());
  }, [currentPropertyMetrics.value?.occupancyMetrics]);

  return (
    <div>
      <Grid container>
        <Grid item md={3} xs={12}>
          <Box sx={{ width: 10, height: 100, mt: '1rem' }}>
            <Slider
              aria-label={'pie-chart-focus'}
              orientation={'vertical'}
              defaultValue={0}
              marks={sliderMarks}
              size={'small'}
              min={0}
              max={3}
              onChange={handleSetPieFocus}
              track={false}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <OccupancyPieChart
            firstPieData={firstPieData}
            secondPieData={secondPieData}
            thirdPieData={thirdPieData}
            fourthPieData={fourthPieData}
            pieFocus={pieFocus}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomLegend currentValues={legendValues} />
        </Grid>
      </Grid>
      <Grid container spacing={2} paddingY={'1rem'} paddingX={'.2rem'}>
        <Grid item sm={6} xs={12}>
          <KpiDisplay
            title={'Current Occupancy'}
            kpi={`${occupiedUnits}`}
            tooltip={`${occupiedUnits}/${rentableUnits} rentable units`}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <KpiDisplay
            title={'Anticipated Occupancy'}
            kpi={currentPropertyMetrics.value?.forecastOccupancyMetrics?.anticipatedOccupancy ?? 0}
            tooltip={`${
              currentPropertyMetrics.value?.forecastOccupancyMetrics?.anticipatedOccupancy ?? 0
            }/${rentableUnits} rentable units`}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <KpiDisplay
            title={'Pre-Leased'}
            kpi={currentPropertyMetrics.value?.occupancyMetrics?.preLeaseUnits ?? 0}
            tooltip={`${
              currentPropertyMetrics.value?.occupancyMetrics?.preLeaseUnits ?? 0
            }/${rentableUnits} rentable units`}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <KpiDisplay
            title={'Moving Out'}
            kpi={currentPropertyMetrics.value?.occupancyMetrics?.moveOutsScheduled ?? 0}
            tooltip={`${
              currentPropertyMetrics.value?.occupancyMetrics?.moveOutsScheduled ?? 0
            }/${occupiedUnits} occupied units`}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <KpiDisplay
            title={'Under Eviction'}
            kpi={currentPropertyMetrics.value?.occupancyMetrics?.unitsUnderEviction ?? 0}
            tooltip={`${
              currentPropertyMetrics.value?.occupancyMetrics?.unitsUnderEviction ?? 0
            }/${occupiedUnits} occupied units`}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <KpiDisplay
            title={'Current Economic Occupancy Min'}
            kpi={currentPropertyMetrics.value?.forecastOccupancyMetrics?.currentEconomicOccupancyMin ?? 0}
            tooltip={`${
              currentPropertyMetrics.value?.forecastOccupancyMetrics?.currentEconomicOccupancyMin ?? 0
            }/${rentableUnits} rentable units`}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <KpiDisplay
            title={'Current Economic Occupancy Max'}
            kpi={currentPropertyMetrics.value?.forecastOccupancyMetrics?.currentEconomicOccupancyMax ?? 0}
            tooltip={`${
              currentPropertyMetrics.value?.forecastOccupancyMetrics?.currentEconomicOccupancyMax ?? 0
            }/${rentableUnits} rentable units`}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <KpiDisplay
            title={'Anticipated Economic Occupancy'}
            kpi={currentPropertyMetrics.value?.forecastOccupancyMetrics?.anticipatedEconomicOccupancy ?? 0}
            tooltip={`${
              currentPropertyMetrics.value?.forecastOccupancyMetrics?.anticipatedEconomicOccupancy ?? 0
            }/${rentableUnits} rentable units`}
          />
        </Grid>
      </Grid>
    </div>
  );
};
