import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { CSSObject } from '@mui/material/styles';
import { useIcons } from '../../icons/useIcons';
import { DocumentInformation } from './DocumentInformation';
import {
  AssociationType,
  SimpleDocumentType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { LoadingIconButton } from '../../buttons/LoadingIconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DraggableComponent } from '../../dragAndDrop/DraggableComponent';
import { getFileExtension } from '../../utils/getFileExtension';

type PhotoCardProps = {
  listingId?: string;
  loading?: boolean;
  document: ReduxDocument;
  onRemoveDocument: (documentId: string) => void;
  index: number;
};

export const ReorderableDocumentCard: FC<PhotoCardProps> = ({
  document,
  onRemoveDocument,
  index,
  listingId,
  loading,
}) => {
  const { ActionType, getActionIcon } = useIcons();
  const displayDocument: ReduxDocument | undefined =
    document?.metadata?.documentType === SimpleDocumentType.Image360 && document?.children && document.children[0]
      ? document?.children[0]
      : document;
  const docType = getFileExtension(displayDocument.name);

  const getItemStyle = (): CSSObject => ({
    width: '100%',
    border: '.1rem solid black',
  });

  return (
    <DraggableComponent id={document.id!} index={index} customItemStyle={getItemStyle}>
      <Stack sx={{ padding: 1 }}>
        <Box display={'flex'} flexGrow={1} justifyContent={'space-between'}>
          {index === 0 ? (
            <Typography fontStyle={'italic'}>
              **If the listing does not have a YouTube video link, this will be the primary image.**
            </Typography>
          ) : (
            <div></div>
          )}
          <LoadingIconButton
            icon={getActionIcon(ActionType.Delete)}
            loading={loading ?? false}
            label={'Delete'}
            onClick={() => document.id && onRemoveDocument(document.id)}
          />
        </Box>
        <DocumentInformation
          displayDocument={displayDocument}
          docType={docType}
          document={document}
          association={{ associationType: AssociationType.Listing, associatedId: listingId }}
        />
      </Stack>
    </DraggableComponent>
  );
};
