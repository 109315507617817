import React, { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { endpointIsntIntegratedAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { appTheme } from '../../AppTheme';

type NotIntegratedProps = {
  children: ReactNode;
};

export const NotIntegrated: FC<NotIntegratedProps> = ({ children }) => {
  const dispatch = useDispatch();

  const styles = {
    border: '.2rem solid',
    padding: '-.2rem',
    borderColor: appTheme.palette.warning.main,
  };

  return (
    <Box onClick={() => dispatch(endpointIsntIntegratedAction())} sx={styles}>
      {children}
    </Box>
  );
};
