import React, { FC, useEffect, useState } from 'react';
import { ReduxDetailObservation, ReduxFollowUpNote } from '@monkeyjump-labs/cam-fe-shared/dist/types/detailTypes';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { CommentList } from '../../../comments/CommentList';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { tryFormatDate } from '../../../utils/TryFormatDate';
import { DocumentList } from '../../../documents/components/DocumentList';
import {
  updateDetailObservationAction,
  useDetails,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/details/detailsSlice';
import {
  AssociationChildType,
  AssociationType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useIcons } from '../../../icons/useIcons';
import { CSSObject } from '@mui/material/styles';
import { ExpandMore } from '../../../styledComponents/ExpandMore';
import { DraggableComponent } from '../../../dragAndDrop/DraggableComponent';
import { selectedItemBorder } from '../../../../../AppTheme';

export type DetailsObservationContentProps = {
  propertyId?: string;
  observation: ReduxDetailObservation;
  onAddFollowUpNote: (observationId: number, note: string) => void;
  onDeleteObservation: (observationId: number) => void;
  isEditingMode?: boolean;
  index: number;
  isSelected?: boolean;
};

export const DetailsObservationItem: FC<DetailsObservationContentProps> = ({
  propertyId,
  observation,
  onAddFollowUpNote,
  onDeleteObservation,
  isEditingMode,
  index,
  isSelected,
}) => {
  const dispatch = useDispatch();
  const { getActionIcon, ActionType } = useIcons();
  const [expandedObservations, setExpandedObservations] = useState<string[]>([]);
  const [followUpNotes, setFollowUpNotes] = useState<ReduxFollowUpNote[]>(observation.followUpNotes ?? []);
  const { selectedDetail } = useDetails();
  const [editingDescription, setEditingDescription] = useState(observation.description ?? '');
  const [dirty, setDirty] = useState(false);
  useEffect(() => {
    setFollowUpNotes(observation.followUpNotes ?? []);
  }, [observation.followUpNotes]);

  const onExpandObservation = (id: string) => {
    if (expandedObservations.includes(id)) {
      setExpandedObservations(expandedObservations.filter((i) => i !== id));
    } else {
      setExpandedObservations([...expandedObservations, id]);
    }
  };

  const getItemStyle = (): CSSObject => ({
    width: '100%',
    border: isSelected ? selectedItemBorder : '.1rem solid black',
  });

  const handleUpdateDescription = () => {
    if (!selectedDetail.value?.id || observation.id === undefined || editingDescription === observation.description) {
      setEditingDescription(observation.description ?? '');
      setDirty(false);
      return;
    }
    dispatch(
      updateDetailObservationAction({
        detailId: selectedDetail.value?.id,
        observationId: observation.id,
        description: editingDescription,
      }),
    );
    setDirty(false);
  };

  return (
    <DraggableComponent id={observation.id!.toString()} index={index} customItemStyle={getItemStyle}>
      <CardContent>
        <Stack>
          <TextField
            value={editingDescription}
            label={'Observation'}
            onChange={(e) => {
              setEditingDescription(e.target.value);
              setDirty(true);
            }}
            fullWidth
            multiline
            rows={3}
          />
          <Box display={'flex'} flexGrow={1} justifyContent={'space-between'}>
            <Typography>
              {observation.createdByName ? observation.createdByName + ' ' : ''}
              {observation.createdOnLabel ? ' — ' + tryFormatDate(observation.createdOnLabel) : ''}
            </Typography>
            <Box>
              <Tooltip title={'Update Description'}>
                <span>
                  <IconButton onClick={handleUpdateDescription} disabled={!dirty}>
                    {getActionIcon(ActionType.Save)}
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={'Remove Observation'}>
                <IconButton onClick={() => onDeleteObservation(observation.id ?? -1)}>
                  {getActionIcon(ActionType.Delete)}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Stack>
      </CardContent>
      {isEditingMode && (
        <>
          <CardActions>
            <Stack direction={'row'} width={'100%'} justifyContent={'right'}>
              <ExpandMore
                expand={expandedObservations.includes(observation.description!)}
                onClick={() => onExpandObservation(observation.description!)}
                aria-expanded={expandedObservations.includes(observation.description!)}
              />
            </Stack>
          </CardActions>
          <Collapse in={expandedObservations.includes(observation.description!)}>
            <CardContent>
              <Divider />
              <Box sx={{ p: 1 }}>
                <Typography sx={{ py: 1, fontStyle: 'italic' }}>
                  {observation.attachments?.length === 0 ? 'No Attached Files' : 'Attached Files'}
                </Typography>
                <DocumentList
                  propertyId={propertyId}
                  value={observation.attachments ?? []}
                  association={{
                    associatedId: selectedDetail.value?.id,
                    associationType: AssociationType.Detail,
                    associationChildNumber: observation.id?.toString(),
                    associationChildType: AssociationChildType.Observation,
                  }}
                  uploading={selectedDetail.submitting}
                />
              </Box>
              <Divider />
              <Box sx={{ p: 1 }}>
                <Typography sx={{ pt: 1, fontStyle: 'italic' }}>Follow-Up Comments</Typography>
                <CommentList
                  comments={followUpNotes}
                  onAddComment={(note) => onAddFollowUpNote(observation.id !== undefined ? observation.id : -1, note)}
                  loading={selectedDetail.submitting}
                />
              </Box>
            </CardContent>
          </Collapse>
        </>
      )}
    </DraggableComponent>
  );
};
