import React, { useEffect, useState } from 'react';
import { IVehicle } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { StripedDataGrid } from '../../../../../_shared/datagrids/StripedDataGrid';
import { GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid-premium';
import { randomId } from '@mui/x-data-grid-generator';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  vehicles: IVehicle[];
  onRemoveVehicle: (updatedVehicles: IVehicle[]) => void;
};

type TableVehicle = IVehicle & { id: string };

export const VehicleGrid = ({ vehicles, onRemoveVehicle }: Props) => {
  const [tableVehicles, setTableVehicles] = useState<TableVehicle[]>([]);

  useEffect(() => {
    const newVehicles = vehicles.map((vehicle) => {
      return { ...vehicle, id: randomId() };
    });
    setTableVehicles(newVehicles);
  }, [vehicles]);

  const deleteVehicle = (id: GridRowId) => () => {
    const updatedVehicles = tableVehicles.filter((vehicle) => vehicle.id !== id);
    onRemoveVehicle(updatedVehicles);
    setTableVehicles(updatedVehicles);
  };

  const columns: GridColDef[] = [
    {
      field: 'make',
      headerName: 'Make',
      sortable: false,
      flex: 0.75,
      editable: false,
    },
    {
      field: 'model',
      headerName: 'Model',
      sortable: false,
      flex: 0.75,
      editable: false,
    },
    {
      field: 'color',
      headerName: 'Color',
      sortable: false,
      flex: 0.75,
      editable: false,
    },
    {
      field: 'year',
      headerName: 'Year',
      sortable: false,
      flex: 0.75,
      editable: false,
    },
    {
      field: 'licensePlate',
      headerName: 'License Plate',
      sortable: false,
      flex: 0.75,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      type: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title={'Remove Vehicle'}>
                <DeleteIcon />
              </Tooltip>
            }
            key="delete"
            label="Remove Pet"
            onClick={deleteVehicle(params.row.id)}
            color="inherit"
            showInMenu={undefined}
          />,
        ];
      },
    },
  ];

  return <StripedDataGrid rows={tableVehicles} columns={columns} disableRowGrouping hideFooter></StripedDataGrid>;
};
