import { IOccupancyMetrics } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { PieChartData } from '../../Metrics';
import { appTheme, Persimmon } from '../../../../../../../../../AppTheme';

export const pieColors = {
  Occupied: appTheme.palette.primary.dark,
  Vacant: appTheme.palette.primary.light,
  'Fully Paid': appTheme.palette.success.light,
  Delinquent: appTheme.palette.secondary.main,
  Eviction: appTheme.palette.error.main,
  'Move-Out Scheduled': appTheme.palette.error.light,
  'Pre-Leased': appTheme.palette.primary.main,
  remainder: appTheme.palette.grey['300'],
  'Under Construction': Persimmon[300],
  'Rent Ready': Persimmon[700],
  'Not Rent Ready': appTheme.palette.secondary.light,
  Unavailable: appTheme.palette.grey['500'],
};

export const useFormatPieData = (occupancyMetrics: Required<IOccupancyMetrics>) => {
  const vacantUnits = occupancyMetrics.rentableUnits - occupancyMetrics.occupiedUnits;

  const formatInnerPieData = (): PieChartData[] => {
    const innerPieData = [
      {
        name: 'Occupied',
        value: occupancyMetrics.occupiedUnits,
        color: pieColors.Occupied,
      },
      {
        name: 'Vacant',
        value: vacantUnits,
        color: pieColors.Vacant,
      },
    ];
    return innerPieData;
  };

  const formatSecondPieData = (): PieChartData[] => {
    const secondPieData = [
      {
        name: 'Fully Paid',
        value: occupancyMetrics.occupiedAndFullyPaidUnits,
        color: pieColors['Fully Paid'],
      },
      {
        name: 'Delinquent',
        value: occupancyMetrics.occupiedDelinquentUnits,
        color: pieColors.Delinquent,
      },
      {
        name: 'Eviction',
        value: occupancyMetrics.unitsUnderEviction,
        color: pieColors.Eviction,
      },
      {
        name: 'Rent Ready',
        value: occupancyMetrics.rentReadyVacantUnits,
        color: pieColors['Rent Ready'],
      },
      {
        name: 'Not Rent Ready',
        value: occupancyMetrics.notRentReadyVacantUnits,
        color: pieColors['Not Rent Ready'],
      },
      {
        name: 'Under Construction',
        value: occupancyMetrics.underConstructionVacantUnits,
        color: pieColors['Under Construction'],
      },
      {
        name: 'Unavailable',
        value: occupancyMetrics.unavailableVacantUnits,
        color: pieColors.Unavailable,
      },
    ];
    return secondPieData;
  };

  const formatThirdPieData = (): PieChartData[] => {
    const thirdPieData: PieChartData[] = [
      {
        name: 'Move-Out Scheduled',
        value: occupancyMetrics.occupiedPaidMoveOutScheduled,
        color: pieColors['Move-Out Scheduled'],
      },
      {
        name: 'remainder',
        value: occupancyMetrics.occupiedAndFullyPaidUnits - occupancyMetrics.occupiedPaidMoveOutScheduled,
        color: pieColors.remainder,
      },
      {
        name: 'Move-Out Scheduled',
        value: occupancyMetrics.occupiedDelinquentMoveOutScheduled,
        color: pieColors['Move-Out Scheduled'],
      },
      {
        name: 'remainder',
        value: occupancyMetrics.occupiedDelinquentUnits - occupancyMetrics.occupiedDelinquentMoveOutScheduled,
        color: pieColors.remainder,
      },
      {
        name: 'Move-Out Scheduled',
        value: occupancyMetrics.occupiedEvictionMoveOutScheduled,
        color: pieColors['Move-Out Scheduled'],
      },
      {
        name: 'remainder',
        value: occupancyMetrics.unitsUnderEviction - occupancyMetrics.occupiedEvictionMoveOutScheduled,
        color: pieColors.remainder,
      },
      {
        name: 'remainder',
        value: vacantUnits,
        color: pieColors.remainder,
      },
    ];
    return thirdPieData;
  };

  const formatFourthPieData = (): PieChartData[] => {
    const fourthPieData: PieChartData[] = [
      {
        name: 'Pre-Leased',
        value: occupancyMetrics.occupiedFullyPaidPreLeased,
        color: pieColors['Pre-Leased'],
      },
      {
        name: 'remainder',
        value: occupancyMetrics.occupiedAndFullyPaidUnits - occupancyMetrics.occupiedFullyPaidPreLeased,
        color: pieColors.remainder,
      },
      {
        name: 'Pre-Leased',
        value: occupancyMetrics.occupiedDelinquentPreLeased,
        color: pieColors['Pre-Leased'],
      },
      {
        name: 'remainder',
        value: occupancyMetrics.occupiedDelinquentUnits - occupancyMetrics.occupiedDelinquentPreLeased,
        color: pieColors.remainder,
      },
      {
        name: 'Pre-Leased',
        value: occupancyMetrics.occupiedEvictionPreLeased,
        color: pieColors['Pre-Leased'],
      },
      {
        name: 'remainder',
        value: occupancyMetrics.unitsUnderEviction - occupancyMetrics.occupiedEvictionPreLeased,
        color: pieColors.remainder,
      },
      {
        name: 'Pre-Leased',
        value: occupancyMetrics.rentReadyPreLeasedUnits,
        color: pieColors['Pre-Leased'],
      },
      {
        name: 'remainder',
        value: occupancyMetrics.rentReadyVacantUnits - occupancyMetrics.rentReadyPreLeasedUnits,
        color: pieColors.remainder,
      },
      {
        name: 'Pre-Leased',
        value: occupancyMetrics.notRentReadyPreLeasedUnits,
        color: pieColors['Pre-Leased'],
      },
      {
        name: 'remainder',
        value: occupancyMetrics.notRentReadyVacantUnits - occupancyMetrics.notRentReadyPreLeasedUnits,
        color: pieColors.remainder,
      },
      {
        name: 'Pre-Leased',
        value: occupancyMetrics.underConstructionPreLeasedUnits,
        color: pieColors['Pre-Leased'],
      },
      {
        name: 'remainder',
        value: occupancyMetrics.underConstructionVacantUnits - occupancyMetrics.underConstructionPreLeasedUnits,
        color: pieColors.remainder,
      },
      {
        name: 'Pre-Leased',
        value: occupancyMetrics.unavailablePreLeasedUnits,
        color: pieColors['Pre-Leased'],
      },
      {
        name: 'remainder',
        value: occupancyMetrics.unavailableVacantUnits - occupancyMetrics.unavailablePreLeasedUnits,
        color: pieColors.remainder,
      },
    ];
    return fourthPieData;
  };

  return { formatInnerPieData, formatSecondPieData, formatThirdPieData, formatFourthPieData };
};
