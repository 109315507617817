import React from 'react';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import { useParams } from 'react-router-dom';
import { getAssetTypeFromPathname } from '../../utils/getAssetTypeFromPathname';
import { LeasesTable } from './leaseTable/LeasesTable';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';

export const CurrentLeasesTab = () => {
  const { id } = useParams();
  const { selectedContext } = useAssets();
  const asset = getAssetTypeFromPathname();
  if (!id || !selectedContext.propertyId) return <></>;
  return (
    <StyledInfoBox label={asset === 'unit' ? 'Unit Status & Occupancy' : 'Current Leases'}>
      <LeasesTable propertyId={selectedContext.propertyId} assetId={id} leasesType={'all'} />
    </StyledInfoBox>
  );
};
