import {
  AssociationChildType,
  AssociationType,
  CamTaskStatus,
  ICamAssociation,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { associationChildMap } from '../expenses/redux/expenseData';
import { getAssetTypeFromPathname } from '../utils/getAssetTypeFromPathname';
import {
  ApplicationDialogTab,
  AssetTab,
  DetailsTab,
  ExpenseDialogTab,
  LeaseDialogTab,
  OverviewTab,
  ScheduledTaskTab,
  SopTaskTab,
  TaskDialogTab,
  VendorTab,
} from '../tabs/TabTypeEnums';
import {
  endpointIsntIntegratedAction,
  setBackUrlAction,
  showToastMessageAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addAssociationToEmailAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/communications/communicationSlice';
import { setAssociationSubmittingAction } from '../search/redux/searchSlice';
import { addAssociationToDetailAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/details/detailsSlice';
import { addAssociationToPurchaseOrderLineItemAction } from '../expenses/redux/expenseSlice';
import { associationTypeMap } from '../search/searchUtils';

export const useAssociations = () => {
  const { outerTab, innerTab, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleGetLabel = (associations: ICamAssociation[]): string => {
    if (associations.length > 1) {
      return 'Multi-Linked';
    }
    for (const association of associations) {
      if (association.associationType === AssociationType.Expense) {
        return association.associationChildType
          ? (associationChildMap.get(association.associationChildType) ?? '')
          : 'Expense';
      }
      if (association.associationType && associationTypeMap.has(association.associationType)) {
        return associationTypeMap.get(association.associationType) ?? '';
      }
    }
    return '';
  };

  const isTaskChip = (associations: ICamAssociation[]) => {
    const task = associations.find((x) => x.associationType === AssociationType.Task);
    return !!task;
  };

  const getChipColor = (associations: ICamAssociation[]): 'success' | 'secondary' | 'info' | 'default' => {
    let allSuccess = true;
    let anySecondary = false;
    let anySecondaryLight = false;
    for (const x of associations) {
      if (x.status === 'unassigned') {
        anySecondaryLight = true;
        break;
      }
      if (x.status === 'unprioritized') {
        anySecondary = true;
        break;
      }
      if (x.status !== CamTaskStatus.ConfirmedCompleted) {
        allSuccess = false;
      }
    }

    if (anySecondaryLight) {
      return 'info';
    } else if (anySecondary) {
      return 'secondary';
    } else if (allSuccess) {
      return 'success';
    } else {
      return 'default';
    }
  };

  const handleLink = (
    isEmail: boolean,
    association?: AssociationType,
    associationId?: string,
    childAssociation?: AssociationChildType,
    childNumber?: string,
    outerTab?: string,
    innerTab?: string,
    id?: string,
  ) => {
    const asset = getAssetTypeFromPathname();
    switch (association) {
      case AssociationType.EmailThread:
        return `/assets/${asset}/${id}/${AssetTab.Overview}/${OverviewTab.Communications}`;
      case AssociationType.Notification:
        return `/assets/${asset}/${id}/${AssetTab.Overview}/${OverviewTab.Notifications}`;
      case AssociationType.Detail:
        return `/assets/${asset}/${id}/${outerTab}/${innerTab}/detail/${associationId}/${DetailsTab.Associations}`;
      case AssociationType.Lease:
        return `/assets/${asset}/${id}/${outerTab}/${innerTab}/lease/${associationId}/${
          isEmail ? LeaseDialogTab.Communications : LeaseDialogTab.General
        }`;
      case AssociationType.Application:
        return `/assets/${asset}/${id}/${outerTab}/${innerTab}/application/${associationId}/${
          isEmail ? ApplicationDialogTab.Communications : ApplicationDialogTab.Info
        }`;
      case AssociationType.Task:
        return `/assets/${asset}/${id}/${outerTab}/${innerTab}/task/${associationId}/${TaskDialogTab.Info}`;
      case AssociationType.ScheduledTask:
        return `/assets/${asset}/${id}/${outerTab}/${innerTab}/scheduledTask/${associationId}/${ScheduledTaskTab.Info}`;
      case AssociationType.TaskConfiguration:
        return `/assets/${asset}/${id}/${outerTab}/${innerTab}/sopTask/${associationId}/${SopTaskTab.Task}`;
      case AssociationType.Vendor:
        return `/assets/${asset}/${id}/${outerTab}/${innerTab}/vendor/${associationId}/${VendorTab.Info}`;
      case AssociationType.JournalEntry:
        return `/assets/${asset}/${id}/${outerTab}/${innerTab}/selectedIncome/${associationId}`;
      case AssociationType.Expense:
        let link = `/assets/${asset}/${id}/${outerTab}/${innerTab}/expense/${associationId}`;
        switch (childAssociation) {
          case AssociationChildType.Payment:
            link = link.concat(`/${ExpenseDialogTab.Payments}/${childNumber}`);
            break;
          case AssociationChildType.WorkOrder:
            link = link.concat(`/${ExpenseDialogTab.WorkOrders}/${childNumber}`);
            break;
          case AssociationChildType.Invoice:
            link = link.concat(`/${ExpenseDialogTab.Invoices}/${childNumber}`);
            break;
          case AssociationChildType.PurchaseOrder:
            link = link.concat(`/${ExpenseDialogTab.PurchaseOrders}/${childNumber}`);
            break;
          case AssociationChildType.Quote:
            link = link.concat(`/${ExpenseDialogTab.Quotes}/${childNumber}`);
            break;
          default:
            break;
        }
        return link;
      default:
        return '';
    }
  };

  const handleListItemClick = (a: ICamAssociation, isEmail: boolean) => {
    const link: string = handleLink(
      isEmail,
      a.associationType,
      a.associatedId,
      a.associationChildType,
      a.associationChildNumber,
      outerTab,
      innerTab,
      id,
    );
    dispatch(setBackUrlAction(location.pathname));
    navigate(link);
  };

  const handleAddAssociation = (
    parentId: string,
    parentType: AssociationType,
    associatedId: string,
    associationType: AssociationType,
    associationChildType?: AssociationChildType,
    childNumber?: string,
    poInfo?: {
      poNumber: string;
      lineItemNo: string;
      changeOrderNo?: string;
    },
  ) => {
    if (!associationType || !associatedId || !parentId) {
      dispatch(showToastMessageAction({ message: 'Conditions not met for adding association', severity: 'error' }));
    } else {
      switch (parentType) {
        case AssociationType.EmailThread:
          dispatch(
            addAssociationToEmailAction({
              id: parentId,
              associatedId: associatedId,
              associationType: associationType,
              associationChildType: associationChildType,
              associationChildNumber: childNumber,
            }),
          );
          dispatch(setAssociationSubmittingAction());
          break;
        case AssociationType.Detail:
          dispatch(
            addAssociationToDetailAction({
              detailId: parentId,
              associateWithId: associatedId,
              associateWithType: associationType,
              childNumber: childNumber,
              childAssociationType: associationChildType,
            }),
          );
          dispatch(setAssociationSubmittingAction());
          break;
        case AssociationType.ExpensePurchaseOrder:
          if (!poInfo?.poNumber || !poInfo.lineItemNo) {
            dispatch(
              showToastMessageAction({
                message: 'Conditions not met for adding PO association',
                severity: 'error',
              }),
            );
          } else {
            dispatch(setAssociationSubmittingAction());
            dispatch(
              addAssociationToPurchaseOrderLineItemAction({
                associatedId: associatedId,
                associationType: associationType,
                lineItemNumber: poInfo?.lineItemNo,
                changeOrderId: poInfo?.changeOrderNo,
                purchaseOrderNumber: poInfo?.poNumber,
                expenseId: parentId,
              }),
            );
          }
          break;
        default:
          dispatch(endpointIsntIntegratedAction());
      }
    }
  };

  return {
    handleGetLabel,
    handleGetChipColor: getChipColor,
    handleLink,
    isTaskChip,
    handleListItemClick,
    handleAddAssociation,
  };
};
