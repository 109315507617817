import React, { FC } from 'react';
import { StripedDataGrid } from '../../datagrids/StripedDataGrid';
import { JournalEntryStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  DataGridPremiumProps,
  GRID_AGGREGATION_FUNCTIONS,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridAggregationFunction,
  GridColDef,
} from '@mui/x-data-grid-premium';
import { ReduxEntry, ReduxStatement } from '../redux/statementTypes';
import { ToolbarWithAction } from '../../datagrids/ToolbarWithAction';
import Button from '@mui/material/Button';
import { useIcons } from '../../icons/useIcons';
import { TableRow } from '../useStatementConfig';

export type StatementDataGridProps = {
  tableRows: TableRow[];
  columns: GridColDef<ReduxEntry>[];
  statement: ReduxStatement | undefined;
  loading: boolean;
  highlightedJournalEntryId?: string;
  onBulkDeposit?: () => void;
  selectedJEIds?: string[];
};

export const StatementDataGrid: FC<StatementDataGridProps> = ({
  tableRows,
  statement,
  loading,
  columns,
  highlightedJournalEntryId,
  onBulkDeposit,
  selectedJEIds,
}) => {
  const { getActionIcon, ActionType } = useIcons();

  const total: GridAggregationFunction<{ amount: number; status: JournalEntryStatus }, number> = {
    label: 'total',
    getCellValue: ({ row }) => ({ amount: row.pending ?? row.posted, status: row.status }),
    apply: ({ values }) => {
      const filteredValues = values.filter((v) => v && v.status !== JournalEntryStatus.Archived);
      return (
        filteredValues.reduce((accumulator, currentValue) => accumulator + currentValue!.amount, 0) +
        (statement?.previousTotalBalance ?? 0)
      );
    },
  };

  const pendingTotal: GridAggregationFunction<{ amount: number; status: JournalEntryStatus }, number> = {
    label: 'pendingTotal',
    getCellValue: ({ row }) => ({ amount: row.pending ?? 0, status: row.status }),
    apply: ({ values }) => {
      const filteredValues = values.filter((v) => v && v.status !== JournalEntryStatus.Archived);
      return (
        filteredValues.reduce((accumulator, currentValue) => accumulator + currentValue!.amount, 0) +
        (statement?.previousPendingBalance ?? 0)
      );
    },
  };

  const postedTotal: GridAggregationFunction<{ amount: number; status: JournalEntryStatus }, number> = {
    label: 'postedTotal',
    getCellValue: ({ row }) => ({ amount: row.posted ?? 0, status: row.status }),
    apply: ({ values }) => {
      const filteredValues = values.filter((v) => v && v.status !== JournalEntryStatus.Archived);
      return (
        filteredValues.reduce((accumulator, currentValue) => accumulator + currentValue!.amount, 0) +
        (statement?.previousBalance?.amount ?? 0)
      );
    },
  };

  const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) => row.path;

  const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
    headerName: '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    valueFormatter: (row, value, column) => {
      if (column.field === GRID_TREE_DATA_GROUPING_FIELD) return '';
    },
  };

  //determine if the grouping column should be shown
  const groupedDataPresent = () => {
    return !tableRows.every((row) => !row.path || row.path.length < 2);
  };

  return (
    <StripedDataGrid
      sx={{
        '.MuiDataGrid-aggregationColumnHeaderLabel': {
          display: 'none',
        },
      }}
      autoHeight
      rows={tableRows}
      columns={columns}
      getRowClassName={(params) => {
        let className = params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
        if (params.row.status === JournalEntryStatus.Archived) {
          className += ' archived';
        }
        if (params.row.journalEntryId && params.row.journalEntryId === highlightedJournalEntryId) {
          className += ' highlighted';
        }
        return className;
      }}
      loading={loading}
      initialState={{
        aggregation: {
          model: {
            pending: 'pendingTotal',
            posted: 'postedTotal',
            total: 'total',
            description: 'size',
          },
        },
      }}
      aggregationFunctions={{
        ...GRID_AGGREGATION_FUNCTIONS,
        total,
        pendingTotal,
        postedTotal,
      }}
      checkboxSelection={statement?.isBankAccountStatement}
      slots={{
        toolbar: statement?.isBankAccountStatement ? ToolbarWithAction : null,
      }}
      slotProps={{
        toolbar: {
          title: 'Bulk Deposit',
          action: (
            <Button
              variant={'contained'}
              onClick={() => onBulkDeposit && onBulkDeposit()}
              startIcon={getActionIcon(ActionType.PostEntry)}
              disabled={selectedJEIds?.length === 0}
            >
              Bulk Deposit
            </Button>
          ),
          hideFilters: true,
          hideExport: true,
        },
      }}
      treeData={groupedDataPresent()}
      getTreeDataPath={getTreeDataPath}
      groupingColDef={groupingColDef}
      getAggregationPosition={(groupNode) => (groupNode.depth === -1 ? 'footer' : null)}
    />
  );
};
