import { format } from 'date-fns';
import { isReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

export const tryFormatDate = (value: string) => {
  if (isReduxDate(value)) return format(toStandardDate(value), 'MM/dd/yyyy');
  if (value === null || value === undefined) {
    return '';
  }
  return format(new Date(Date.parse(value)), 'MM/dd/yyyy');
};

export const tryFormatIsoDate = (value: string) => {
  if (value === null || value === undefined) {
    return '';
  }
  return format(new Date(Date.parse(value)), 'yyyy-MM-dd');
};

export const isoToDate = (iso?: string) => (iso ? new Date(iso) : undefined);
