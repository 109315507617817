import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import { NumericFormat } from 'react-number-format';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export type StatementPreviousBalanceProps = {
  isTenantPortalView?: boolean;
  previousBalance?: number;
  previousPendingBalance?: number;
  previousTotalBalance?: number;
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 700,
  },
});

export const StatementPreviousBalance: FC<StatementPreviousBalanceProps> = ({
  isTenantPortalView,
  previousBalance,
  previousPendingBalance,
  previousTotalBalance,
}) => {
  const balanceField = (label: string, value: number | undefined, isBold?: boolean) => {
    return (
      <Stack direction={'row'} spacing={1}>
        <Typography variant={'body2'} fontWeight={isBold ? 700 : 400}>
          {label}{' '}
        </Typography>
        <Typography variant={'body2'} fontWeight={isBold ? 700 : 400}>
          <NumericFormat
            value={value ?? NaN}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale
          />
        </Typography>
      </Stack>
    );
  };

  return isTenantPortalView ? (
    <>{balanceField('Previous Total Balance:', previousTotalBalance, !isTenantPortalView)}</>
  ) : (
    <CustomWidthTooltip
      title={
        <Stack>
          {!isTenantPortalView && balanceField('Previous Balance:', previousBalance)}
          {!isTenantPortalView && balanceField('Previous Pending Balance:', previousPendingBalance)}
          {balanceField('Previous Total Balance:', previousTotalBalance, !isTenantPortalView)}
        </Stack>
      }
      placement={'bottom-start'}
    >
      {balanceField('Prior Statement Total Balance:', previousTotalBalance, !isTenantPortalView)}
    </CustomWidthTooltip>
  );
};
