import React, { FC, FormEvent, useEffect, useState } from 'react';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { IAddExpenseEstimateHandlerRequest } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import TextField from '@mui/material/TextField';
import { NumberValueTextField } from '../../../texfields/NumberValueTextField';
import { useDispatch } from 'react-redux';
import { addEstimateToSelectedExpenseAction, useExpenses } from '../../redux/expenseSlice';

type AddEstimateDialogProps = {
  onClose: () => void;
} & Omit<DialogProps, 'onClose'>;
export const AddEstimateDialog: FC<AddEstimateDialogProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { selectedExpense } = useExpenses();
  const [newEstimate, setNewEstimate] = useState<IAddExpenseEstimateHandlerRequest>({});
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    selectedExpense.value?.id &&
      selectedExpense.value?.propertyId &&
      dispatch(
        addEstimateToSelectedExpenseAction({
          propertyId: selectedExpense.value?.propertyId,
          expenseId: selectedExpense.value.id,
          body: newEstimate,
        }),
      );
  };

  const handleClose = () => {
    setNewEstimate({});
    onClose();
  };

  useEffect(() => {
    selectedExpense.submitted && handleClose();
  }, [selectedExpense.submitted]);
  return (
    <DialogLayout title={'Add Estimate'} onClose={handleClose} open={open}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            fullWidth
            label={'Name'}
            variant={'outlined'}
            value={newEstimate.name ?? ''}
            required
            onChange={(e) => setNewEstimate({ ...newEstimate, name: e.target.value })}
          />
          <NumberValueTextField
            valueUnits={'dollars'}
            fullWidth
            label={'Amount'}
            variant={'outlined'}
            value={newEstimate.amount ?? ''}
            required
            onChange={(e) => setNewEstimate({ ...newEstimate, amount: Number(e.target.value) })}
          />
          <TextField
            fullWidth
            multiline
            rows={3}
            label={'Notes'}
            variant={'outlined'}
            value={newEstimate.note ?? ''}
            onChange={(e) => setNewEstimate({ ...newEstimate, note: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <LoadingButton variant={'contained'} type={'submit'}>
            Add Estimate
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};
