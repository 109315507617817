import React, { FC, useEffect, useState } from 'react';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { AccountStatement } from '../AccountStatement';
import { useNavigate, useParams } from 'react-router-dom';
import { clearStatementAction, useStatement } from '../../redux/statementSlice';
import { useDispatch } from 'react-redux';
import { ReduxEntry } from '../../redux/statementTypes';
import { dialogChildIsOpenAction, useGlobal } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';

type ControlledBankAccountStatementViewProps = {
  linkedGlAccountId?: string;
  open?: boolean;
  onClose?: () => void;
  isPopupStatement?: boolean;
  highlightedStatementRow?: ReduxEntry;
};

type UncontrolledBankAccountStatementViewProps = {
  linkedGlAccountId?: never;
  isPopupStatement?: never;
  highlightedStatementRow?: never;
  open?: never;
  onClose?: never;
};

type BankAccountStatementViewProps =
  | ControlledBankAccountStatementViewProps
  | UncontrolledBankAccountStatementViewProps;

export const AccountStatementViewDialog: FC<BankAccountStatementViewProps> = ({
  linkedGlAccountId,
  open,
  onClose,
  isPopupStatement,
  highlightedStatementRow,
}) => {
  const dispatch = useDispatch();
  const { selectedStatement, popupStatement } = useStatement();
  const { dialogChildIsOpen } = useGlobal();
  const navigate = useNavigate();
  const { id, outerTab, innerTab, accountId } = useParams();
  const openAccountStatement = open !== undefined ? open : true;
  const [internalAccountId, setInternalAccountId] = useState<string | undefined>();
  const [accountName, setAccountName] = useState<string | undefined>();

  useEffect(() => {
    setInternalAccountId(linkedGlAccountId ?? accountId);
  }, [linkedGlAccountId, accountId]);

  useEffect(() => {
    if (isPopupStatement) {
      setAccountName(popupStatement.value?.account?.name);
    } else setAccountName(selectedStatement.value?.account?.name);
  }, [selectedStatement.value?.account?.name, popupStatement.value?.account?.name]);

  const handleClose = () => {
    if (dialogChildIsOpen && !isPopupStatement) {
      dispatch(dialogChildIsOpenAction(false));
    } else {
      if (onClose) {
        onClose();
      } else {
        dispatch(clearStatementAction());
        navigate(`/assets/property/${id}/${outerTab}/${innerTab}`);
      }
    }
  };

  return (
    <DialogLayout
      title={accountName ? `Statement for ${accountName}` : 'Statement'}
      onClose={handleClose}
      open={openAccountStatement}
      fullWidth
      maxWidth={'xl'}
    >
      <DialogContent>
        <Box pt={3}>
          {internalAccountId ? (
            <AccountStatement
              statementAccountId={internalAccountId}
              isPopupStatement={isPopupStatement}
              highlightedStatementRow={highlightedStatementRow}
            />
          ) : (
            <></>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </DialogLayout>
  );
};
