import React, { FC, useState } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { appTheme } from '../../../../../../../../AppTheme';
import { useMediaQuery } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

type KpiDisplayProps = {
  title: string;
  kpi: number | string;
  dollarAmount?: boolean;
  tooltip?: React.ReactNode;
};

export const KpiDisplay: FC<KpiDisplayProps> = ({ title, kpi, dollarAmount, tooltip }) => {
  const [hover, setHover] = useState(false);
  const breakpointMd = useMediaQuery(appTheme.breakpoints.up('lg'));

  const kpiPaperStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '100%',
    paddingY: '1rem',
  };

  const kpiBoxStyles = {
    marginBottom: '1rem',
  };

  const kpiMetricStyles = {
    fontWeight: breakpointMd ? appTheme.typography.h3 : appTheme.typography.h4,
    color: appTheme.palette.primary.dark,
  };

  const kpiTitleStyles = {
    fontWeight: appTheme.typography.body2,
    color: appTheme.palette.primary.dark,
    textAlign: 'center',
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <Paper
            sx={kpiPaperStyles}
            elevation={hover ? 10 : 3}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Box sx={kpiBoxStyles}>
              <Typography sx={kpiMetricStyles}>
                {dollarAmount && '$'}
                {kpi}
              </Typography>
            </Box>
            <Typography sx={kpiTitleStyles}>{title}</Typography>
          </Paper>
        </Tooltip>
      ) : (
        <Paper
          sx={kpiPaperStyles}
          elevation={hover ? 10 : 3}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Box sx={kpiBoxStyles}>
            <Typography sx={kpiMetricStyles}>
              {dollarAmount && '$'}
              {kpi}
            </Typography>
          </Box>
          <Typography sx={kpiTitleStyles}>{title}</Typography>
        </Paper>
      )}
    </>
  );
};
