import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  return (
    <IconButton {...props}>
      <ExpandMoreIcon />
    </IconButton>
  );
})(({ expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
}));
