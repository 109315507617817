import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ListingDetail } from './ListingDetail';
import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import StraightenIcon from '@mui/icons-material/Straighten';
import PetsIcon from '@mui/icons-material/Pets';
import { DogSizeAllowed } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { DetailList } from './DetailList';
import { tryFormatDate } from '../../../../_shared/utils/TryFormatDate';
import { currencyFormatter } from '../../../../_shared/utils/currencyFormatter';
import { ReduxListing } from '../../../redux/listingData';

type ListingDetailsProps = {
  listing: ReduxListing;
  detailed?: boolean;
};

const handleFormatTerms = (listing: ReduxListing) => {
  const terms = [];
  listing.price && terms.push(`Rent: ${currencyFormatter.format(listing.price)}`);
  listing.deposit && terms.push(`Deposit: ${currencyFormatter.format(listing.deposit)}`);
  listing.fees?.map((fee) => terms.push(`${fee.description}: ${currencyFormatter.format(fee.amount ?? 0)}`));
  return terms;
};

const handleFormatAppliances = (listing: ReduxListing) => {
  const appliances = [];
  listing.washer && appliances.push('Washer');
  listing.dryer && appliances.push('Dryer');
  listing.dishwasher && appliances.push('Dishwasher');
  return appliances;
};

const handleFormatAmenities = (listing: ReduxListing) => {
  const amenities = [];
  listing.furnished && amenities.push('Is Furnished');
  listing.otherAmenities?.map((amenity) => amenities.push(amenity));
  return amenities;
};

export const ListingDetails: FC<ListingDetailsProps> = ({ listing, detailed }) => {
  const amenities = handleFormatAmenities(listing);
  const appliances = handleFormatAppliances(listing);
  const terms = handleFormatTerms(listing);

  return (
    <Stack spacing={2}>
      <Box py={1}>
        <Typography variant={'h3'} fontStyle={'italic'} color={'primary'}>
          Rent ${listing.price}
        </Typography>
      </Box>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <ListingDetail icon={<BedIcon />}>
          <Typography>{listing.bedrooms} bed</Typography>
        </ListingDetail>
        <ListingDetail icon={<BathtubIcon />}>
          <Typography>{listing.bathrooms} bath</Typography>
        </ListingDetail>
        <ListingDetail icon={<StraightenIcon />}>
          <Typography>{listing.squareFootage} sqft.</Typography>
        </ListingDetail>
      </Stack>
      <ListingDetail icon={<PetsIcon />}>
        {listing.maxDogSizeAllowed !== DogSizeAllowed.None && (
          <Typography>Dog Size Allowed: {listing.maxDogSizeAllowed}</Typography>
        )}
        {listing.allowsCats && <Typography>Allows Cats</Typography>}
        {listing.maxDogSizeAllowed === DogSizeAllowed.None && listing.allowsCats === false && (
          <Typography>No Pets Allowed</Typography>
        )}
      </ListingDetail>
      <ListingDetail direction={'row'}>
        <Typography sx={{ marginRight: 1 }}>Available: </Typography>
        <Typography fontWeight={700}>{listing.dateAvailable ? tryFormatDate(listing.dateAvailable) : 'N/A'}</Typography>
      </ListingDetail>
      {detailed && <DetailList details={terms} title={'Terms'} />}
      {amenities.length > 0 && detailed && <DetailList details={amenities} title={'Amenities'} />}
      {appliances.length > 0 && detailed && <DetailList details={appliances} title={'Appliances'} />}
    </Stack>
  );
};
