import React, { useState } from 'react';
import { ApplicantTable } from '../../applicantTable/ApplicantTable';
import Box from '@mui/material/Box';
import { GridColumnVisibilityModel, GridRowId } from '@mui/x-data-grid-premium';
import Stack from '@mui/material/Stack';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { SendApplication } from '../../applicationDialogs/SendApplication';
import { useNavigate } from 'react-router-dom';
import { ReduxApplication } from '../../../../redux/applicationTypes';
import { AssetTab, LeasingTab } from '../../../../../_shared/tabs/TabTypeEnums';

type Props = {
  rows: ReduxApplication[];
  applicant: ReduxApplication;
  columnVisibility: GridColumnVisibilityModel;
  editingInWizard?: boolean;
  onOpen: () => void;
};

export const OtherAdults = ({ rows, columnVisibility, editingInWizard, onOpen, applicant }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openSendApplication, setOpenSendApplication] = useState(false);
  const [selectedRow, setSelectedRow] = useState<ReduxApplication | undefined>(undefined);

  const handleOpenEditOtherAdult = (id: GridRowId) => {
    const row = rows?.find((row) => row.id === id);
    if (row) {
      navigate(
        `/assets/property/${applicant.rentalPropertyId}/${AssetTab.Leasing}/${LeasingTab.Applications}/application/${row.id}/info`,
      );
    } else {
      dispatch(showToastMessageAction({ message: 'Sorry, something went wrong :(', severity: 'error' }));
    }
  };

  const handleSendApplication = (id: GridRowId) => () => {
    const row = rows?.find((row) => row.id === id);
    setSelectedRow(row);
    setOpenSendApplication(true);
  };

  const handleCloseSendApplication = () => {
    setOpenSendApplication(false);
  };

  return (
    <StyledInfoBox label={'Other Adults'}>
      <Stack spacing={2}>
        <Box sx={{ mb: '.5rem' }}>
          <Button variant="outlined" onClick={onOpen}>
            Add Other Adult
          </Button>
        </Box>
        <Box sx={{ height: 400, width: '100%' }}>
          <ApplicantTable
            rows={rows}
            onOpenEditApplicant={handleOpenEditOtherAdult}
            onSendApplication={handleSendApplication}
            columnVisibility={columnVisibility}
            checkboxSelection={false}
            editingInWizard={editingInWizard}
            disableColumnFilter={true}
            pagination={false}
          />
        </Box>
        {selectedRow && (
          <SendApplication open={openSendApplication} onClose={handleCloseSendApplication} applicant={selectedRow} />
        )}
      </Stack>
    </StyledInfoBox>
  );
};
