import React from 'react';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import Stack from '@mui/material/Stack';
import { UnitTemplateTable } from './UnitTemplateTable';
import { useBuilding } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/buildingSlice';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import LoadingButton from '@mui/lab/LoadingButton';
import { useUnitTemplates } from '../redux/unitTemplateSlice';
import { AddUnitTemplateDialog } from './AddUnitTemplateDialog';
import Box from '@mui/material/Box';
import { getAssetTypeFromPathname } from '../../utils/getAssetTypeFromPathname';

export const UnitTemplateManagement = () => {
  const { selectedProperty } = useProperty();
  const { selectedBuilding } = useBuilding();
  const { unitTemplates } = useUnitTemplates();
  const [isAddDialogOpen, setIsAddDialogOpen] = React.useState(false);

  const assetType = getAssetTypeFromPathname();
  const propertyId = selectedBuilding.value?.rentalPropertyId ?? selectedProperty.value?.id;
  const buildingId = assetType === 'building' ? selectedBuilding.value?.id : undefined;

  const closeDialog = () => {
    setIsAddDialogOpen(false);
  };

  return (
    <StyledInfoBox label={'Unit Template Management'}>
      <Stack direction={'column'}>
        <Box sx={{ display: 'flex' }} pb={1}>
          <LoadingButton variant={'contained'} loading={unitTemplates.loading} onClick={() => setIsAddDialogOpen(true)}>
            Add Unit Template
          </LoadingButton>
        </Box>
        {propertyId && <UnitTemplateTable propertyId={propertyId} buildingId={buildingId} />}
      </Stack>
      {propertyId && (
        <AddUnitTemplateDialog
          open={isAddDialogOpen}
          onClose={closeDialog}
          propertyId={propertyId}
          buildingId={buildingId}
        />
      )}
    </StyledInfoBox>
  );
};
