import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { ReduxEmail, ReduxEmailThread } from '@monkeyjump-labs/cam-fe-shared/dist/types/communicationTypes';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

type EmailParticipantsCellProps = {
  handleClick: (id: string) => void;
  isThread: boolean;
} & GridRenderCellParams<ReduxEmailThread | ReduxEmail>;

export const isEmailThread = (e: ReduxEmailThread | ReduxEmail): e is ReduxEmailThread => {
  return e !== null && 'emails' in e;
};

export const EmailParticipantsCell: FC<EmailParticipantsCellProps> = ({ handleClick, isThread, ...params }) => {
  const email = isEmailThread(params.row) ? params.row.emails[0] : params.row;
  const participants = email.participants ?? [];
  const incoming = email.incoming ?? [];
  const from = email.from ?? [];
  const ccs = email.cc ?? [];
  const to = email.to ?? [];
  return (
    <Box sx={isThread ? [styles.container, styles.isThread] : { overflow: 'hidden', height: '100%' }}>
      <Tooltip title={participants?.join(', ')}>
        <Box sx={{ overflow: 'hidden' }}>
          {isThread ? (
            incoming ? (
              <Link sx={{ cursor: 'pointer' }} color="inherit">
                <Typography
                  fontWeight={!email.isRead ? 'bold' : ''}
                  variant={'body2'}
                  onClick={() => handleClick(params.row.id!)}
                >
                  {from}
                </Typography>
              </Link>
            ) : (
              <Link sx={{ cursor: 'pointer' }} color="inherit">
                <Typography variant={'body2'} onClick={() => handleClick(params.row.id!)}>
                  {to[0]}
                </Typography>
              </Link>
            )
          ) : (
            <>
              {incoming ? (
                <Typography
                  fontWeight={!email.isRead ? 'bold' : ''}
                  variant={'body2'}
                  onClick={() => handleClick(params.row.id!)}
                >
                  {from}
                </Typography>
              ) : (
                <></>
              )}
              {to.length > 0 ? (
                <Typography variant={'body2'} onClick={() => handleClick(params.row.id!)}>
                  {to.join(', ')}
                </Typography>
              ) : (
                <></>
              )}
              {ccs.length > 0 ? (
                <Typography sx={{ fontStyle: 'italic' }} variant={'body2'} onClick={() => handleClick(params.row.id!)}>
                  {ccs.join(', ')}
                </Typography>
              ) : (
                <></>
              )}
            </>
          )}
        </Box>
      </Tooltip>
      <Box sx={{ display: 'flex', flexGrow: 1 }}></Box>
    </Box>
  );
};

const styles = {
  container: { overflow: 'hidden', height: '100%' },
  isThread: { display: 'flex', alignItems: 'center' },
};
