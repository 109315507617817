import React, { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { DefaultSelection } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import CheckIcon from '@mui/icons-material/Check';
import { ReduxMtAccount } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

type BankAccountStatusProps = {
  acct: ReduxMtAccount;
  onSetDefaultAcct: (acctId: string, defaultSelection: DefaultSelection) => void;
  isPropertyList: boolean;
};

export const BankAccountStatus: FC<BankAccountStatusProps> = ({ acct, onSetDefaultAcct, isPropertyList }) => {
  return (
    <>
      <Tooltip
        title={
          acct.defaultSelection?.includes(DefaultSelection.General)
            ? 'Default Payment Account'
            : 'Set as Default Payment Account'
        }
      >
        <Chip
          label="Payment"
          color={acct.defaultSelection?.includes(DefaultSelection.General) ? 'primary' : 'default'}
          onClick={
            acct.defaultSelection?.includes(DefaultSelection.General)
              ? undefined
              : () => onSetDefaultAcct(acct.mtAccountId!, DefaultSelection.General)
          }
          avatar={
            <Avatar>
              <CheckIcon />
            </Avatar>
          }
        />
      </Tooltip>
      {isPropertyList && (
        <Tooltip
          title={
            acct.defaultSelection?.includes(DefaultSelection.Deposit)
              ? 'Default Deposit Account'
              : 'Set as Default Deposit Account'
          }
        >
          <Chip
            label="Deposit"
            onClick={
              acct.defaultSelection?.includes(DefaultSelection.Deposit)
                ? undefined
                : () => onSetDefaultAcct(acct.mtAccountId!, DefaultSelection.Deposit)
            }
            color={acct.defaultSelection?.includes(DefaultSelection.Deposit) ? 'primary' : 'default'}
            avatar={
              <Avatar>
                <CheckIcon />
              </Avatar>
            }
          />
        </Tooltip>
      )}
    </>
  );
};
