import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import {
  createTaskFromConfigWithAssociationSuccessAction,
  createTaskSuccessAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import {
  IAddPropertyHandlerResponse,
  IAddBuildingHandlerResponse,
  IBuildingUnitDto,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { PayloadAction } from '@reduxjs/toolkit';
import { AssetTab, OverviewTab, TaskDialogTab } from '../../areas/_shared/tabs/TabTypeEnums';
import {
  addScheduledTaskSuccessAction,
  createScheduledTaskFromSopTaskSuccessAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/scheduledTaskSlice';
import { setVendorOnSubmittingValueAction } from '../../areas/_shared/expenses/redux/expenseSlice';
import {
  createPropertySuccessAction,
  deletePropertySuccessAction,
  restorePropertySuccessAction,
  createSimulatedPropertySuccessAction,
  addVendorForPropertySuccessAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { createBuildingSuccessAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/buildingSlice';
import { createUnitSuccessAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/unitSlice';
import { ReduxVendor } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

function* routeToNewTask(action: PayloadAction<{ parentPath?: string; id: string }>) {
  console.log('routeToNewTask');
  yield action.payload.parentPath &&
    put(push(action.payload.parentPath.concat(`/task/${action.payload.id}/${TaskDialogTab.Info}`)));
}

function* routeToNewScheduledTask(action: PayloadAction<{ parentPath?: string; id: string }>) {
  console.log('routeToNewTask');

  yield action.payload.parentPath &&
    put(push(action.payload.parentPath.concat(`/scheduledTask/${action.payload.id}/${TaskDialogTab.Info}`)));
}

function* routeToNewSimulatedProperty(action: PayloadAction<{ newId: string }>) {
  yield put(push(`/assets/property/${action.payload.newId}/${AssetTab.Overview}/${OverviewTab.Metrics}`));
}

function* routeToNewProperty(action: PayloadAction<IAddPropertyHandlerResponse>) {
  yield put(push(`/assets/property/${action.payload.id}/${AssetTab.Overview}/${OverviewTab.Metrics}`));
}

function* routeToNewBuilding(action: PayloadAction<IAddBuildingHandlerResponse>) {
  yield put(push(`/assets/building/${action.payload.id}/${AssetTab.Overview}/${OverviewTab.Metrics}`));
}

function* routeToNewUnit(action: PayloadAction<IBuildingUnitDto>) {
  yield put(push(`/assets/unit/${action.payload.id}/${AssetTab.Overview}/${OverviewTab.Metrics}`));
}

function* routeOnAssetDeletion() {
  yield put(push('/'));
}

function* setVendorOnSubmittingValue(action: PayloadAction<ReduxVendor>) {
  yield put(setVendorOnSubmittingValueAction(action.payload));
}

export function* routingSagas() {
  yield all([
    takeLatest(createTaskFromConfigWithAssociationSuccessAction.type, routeToNewTask),
    takeLatest(createTaskSuccessAction.type, routeToNewTask),
    takeLatest(addScheduledTaskSuccessAction.type, routeToNewScheduledTask),
    takeLatest(createScheduledTaskFromSopTaskSuccessAction.type, routeToNewScheduledTask),
    takeLatest(createPropertySuccessAction.type, routeToNewProperty),
    takeLatest(createSimulatedPropertySuccessAction.type, routeToNewSimulatedProperty),
    takeLatest(deletePropertySuccessAction.type, routeOnAssetDeletion),
    takeLatest(restorePropertySuccessAction.type, routeOnAssetDeletion),
    takeLatest(addVendorForPropertySuccessAction.type, setVendorOnSubmittingValue),
    takeLatest(createBuildingSuccessAction.type, routeToNewBuilding),
    takeLatest(createUnitSuccessAction.type, routeToNewUnit),
  ]);
}
