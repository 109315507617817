import React from 'react';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import Stack from '@mui/material/Stack';
import { EmailTemplateTable } from './EmailTemplateTable';
import { AddEmailTemplateDialog } from './AddEmailTemplateDialog';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEmailTemplates } from '../redux/emailTemplateSlice';

export const EmailTemplateManagement = () => {
  const { selectedProperty } = useProperty();
  const { emailTemplates } = useEmailTemplates();
  const [isAddDialogOpen, setIsAddDialogOpen] = React.useState(false);

  const propertyId = selectedProperty.value?.id;

  const closeDialog = () => {
    setIsAddDialogOpen(false);
  };

  return (
    <StyledInfoBox label={'Email Template Management'}>
      <Stack direction={'column'}>
        <Box sx={{ display: 'flex' }} pb={1}>
          <LoadingButton
            variant={'contained'}
            loading={emailTemplates.submitting}
            onClick={() => setIsAddDialogOpen(true)}
          >
            Add Email Template
          </LoadingButton>
        </Box>
        {selectedProperty.value?.id && <EmailTemplateTable propertyId={selectedProperty.value.id} />}
      </Stack>
      {propertyId && <AddEmailTemplateDialog open={isAddDialogOpen} onClose={closeDialog} propertyId={propertyId} />}
    </StyledInfoBox>
  );
};
