import React, { FC, FormEvent, useEffect, useState } from 'react';
import { PaymentForm } from './PaymentForm';
import { ReduxExpensePayment } from '../../redux/expenseData';
import {
  IContactPerson,
  ISlimAccountRef,
  IVendor,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { markPaymentVoidAction, updatePaymentAction, useExpenses } from '../../redux/expenseSlice';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material';

type ViewPaymentDetailsProps = {
  payment: ReduxExpensePayment;
  propertyId: string;
  jobId: string;
  onAddNewVendor: (name: string) => void;
};

export const ViewPaymentDetails: FC<ViewPaymentDetailsProps> = ({ payment, propertyId, jobId, onAddNewVendor }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { selectedPayment } = useExpenses();
  const [editingPayment, setEditingPayment] = useState<ReduxExpensePayment>(selectedPayment.submittingValue ?? payment);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    setEditingPayment(selectedPayment.submittingValue ?? payment);
    selectedPayment.submittingValue && setDirty(true);
  }, [selectedPayment.submittingValue, payment]);
  const handleChangePayment = <TKey extends keyof ReduxExpensePayment>(
    field: TKey,
    value: ReduxExpensePayment[TKey],
  ) => {
    setEditingPayment({ ...editingPayment, [field]: value });
    setDirty(true);
  };
  const handleChangeVendor = (value: IVendor | undefined) => {
    setEditingPayment({ ...editingPayment, vendor: value });
    setDirty(true);
  };

  const handleChangeVendorContact = (value: IContactPerson | undefined) => {
    setEditingPayment({ ...editingPayment, vendorContact: value });
    setDirty(true);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(updatePaymentAction({ jobId: jobId, payment: editingPayment }));
    setDirty(false);
  };

  const handleChangeAssociation = (association?: string, vendor?: IVendor, propertyAccount?: ISlimAccountRef) => {
    const updatedVendor = association === undefined ? undefined : (vendor ?? editingPayment.vendor);
    setEditingPayment({
      ...editingPayment,
      associatedInvoice: association,
      vendor: updatedVendor,
      expenseAccount: propertyAccount ?? editingPayment.expenseAccount,
    });
    setDirty(true);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <PaymentForm
          value={editingPayment}
          onChangePayment={handleChangePayment}
          onChangeVendor={handleChangeVendor}
          onChangeVendorContact={handleChangeVendorContact}
          propertyId={propertyId}
          editingMode
          onAddNewVendor={onAddNewVendor}
          onAssociateInvoice={handleChangeAssociation}
        />
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
          <Button
            variant={'contained'}
            sx={{
              mr: '.5rem',
              color: 'white',
              backgroundColor: theme.palette.error.main,
              '&:hover': { backgroundColor: theme.palette.error.light },
            }}
            onClick={() =>
              editingPayment.number &&
              dispatch(
                markPaymentVoidAction({
                  jobId,
                  paymentNumber: editingPayment.number,
                }),
              )
            }
          >
            Mark As Void
          </Button>
          <LoadingButton variant={'contained'} disabled={!dirty} type={'submit'} loading={selectedPayment.submitting}>
            Save Changes
          </LoadingButton>
        </Box>
      </Stack>
    </form>
  );
};
