import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import { ReduxUserNotification } from '@monkeyjump-labs/cam-fe-shared/dist/types/notificationTypes';
import { useDispatch } from 'react-redux';
import { markNotificationAsReadAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/notifications/notificationSlice';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { format } from 'date-fns';
import { toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

type Props = {
  notification: ReduxUserNotification;
  onClose: () => void;
};

export const NotificationMenuItem = ({ notification, onClose }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isClearing, setIsClearing] = useState(false);

  const handleClick = (id?: string) => {
    navigate(`/assets/property/${notification.propertyId}/overview/notifications?selected=${id}`);
    onClose();
  };

  const handleClear = () => {
    setIsClearing(true);
    notification.id &&
      dispatch(markNotificationAsReadAction({ notificationId: notification.id, removeFromRedux: true }));
  };

  const formattedDate = notification.createdAt && format(toStandardDate(notification.createdAt), 'h:mm a, MMMM dd');

  return (
    <MenuItem key={notification.id} divider sx={{ width: '100%' }}>
      <Stack flexDirection={'row'} sx={{ width: '100%' }}>
        <Box
          sx={{ width: '50%', justifyContent: 'left' }}
          onClick={() => {
            handleClick(notification.id);
          }}
        >
          <Typography variant="body1" noWrap>
            {notification.description}
          </Typography>
        </Box>
        <Stack
          sx={{ width: '70%', justifyContent: 'right' }}
          onClick={() => {
            handleClick(notification.id);
          }}
        >
          <Box>
            <Typography align="right" sx={{ whiteSpace: 'normal' }} variant="body2">
              {notification.detailedDescription}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
            <Typography variant="body2" fontStyle="italic">
              {formattedDate ? formattedDate : ''}
            </Typography>
          </Box>
        </Stack>
        <Box sx={{ display: 'flex', width: '5%', justifyContent: 'right' }}>
          <IconButton disabled={isClearing} aria-label="Clear" onClick={handleClear} sx={{ mr: '-1rem' }}>
            <Tooltip title={'Clear'}>
              {isClearing ? (
                <CircularProgress sx={(theme) => ({ color: theme.palette.text.disabled })} size={20} />
              ) : (
                <CheckCircleIcon />
              )}
            </Tooltip>
          </IconButton>
        </Box>
      </Stack>
    </MenuItem>
  );
};
