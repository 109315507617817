import React, { FC, useEffect, useState } from 'react';
import {
  getAutomationsByPropertyIdAction,
  getSingleAutomationByPropertyIdAction,
  useSopTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import { useDispatch } from 'react-redux';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { SopTaskList } from '../../../../property/components/propertyTabs/propertyInfoTabs/sopTaskTemplates/components/SopTaskList';
import { SopTaskDetailsReadonly } from '../../../../property/components/propertyTabs/propertyInfoTabs/sopTaskTemplates/components/SopTaskDetailsReadonly';
import { ReduxSopTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import {
  createTaskFromAutomationAction,
  createTaskFromConfigWithDetailAssociationAction,
  createTaskFromConfigWithEmailThreadAssociationAction,
  createTaskFromConfigWithPurchaseOrderLineItemAssociationAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import {
  AssetType,
  AssociationType,
  IAssociation,
  TaskSection,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { AssociateWithCurrentLeaseToggle } from '../AssociateWithCurrentLeaseToggle';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import {
  endpointIsntIntegratedAction,
  setBackUrlAction,
  showToastMessageAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useParams } from 'react-router-dom';
import { AssetParams } from '../../../../../AppRouter';
import { getAssetTypeFromPathname } from '../../../utils/getAssetTypeFromPathname';

type AddTaskFromAutomationProps = {
  onClose: () => void;
  associatedId: string;
  associationType?: AssetType;
  createdFrom?: AssociationType;
  includeClosed?: boolean;
  localSopTaskAssociation?: IAssociation;
  AssetInfo?: { assetId?: string; assetType?: string };
  poLineItemInfo?: {
    poNumber?: string;
    lineItemNumber?: string;
    changeOrderId?: string;
  };
  taskSection?: TaskSection;
};

export const AddTaskFromSopTask: FC<AddTaskFromAutomationProps> = ({
  onClose,
  associatedId,
  includeClosed,
  createdFrom,
  associationType,
  localSopTaskAssociation,
  AssetInfo,
  poLineItemInfo,
  taskSection,
}) => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const { sopTasks } = useSopTasks();
  const [pageSize, setPageSize] = useState(10);
  const [showDetails, setShowDetails] = useState(false);
  const [associateWithCurrentLease, setAssociateWithCurrentLease] = useState(false);
  const { id, outerTab, innerTab } = useParams<AssetParams>();
  const [includePropertyConfigs, setIncludePropertyConfigs] = useState(false);
  const page = 0;

  const parentPath = `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}`;

  useEffect(() => {
    selectedContext.propertyId &&
      dispatch(
        getAutomationsByPropertyIdAction({
          id: selectedContext.propertyId,
          page: page,
          pageSize: pageSize,
          associationType: localSopTaskAssociation?.associationType,
          associatedId: localSopTaskAssociation?.associatedId,
          includePropertyTasks: localSopTaskAssociation !== undefined ? includePropertyConfigs : false,
        }),
      );
  }, [page, pageSize, includePropertyConfigs]);

  const handleShowDetails = (automation: ReduxSopTask) => {
    automation.propertyId &&
      automation.id &&
      dispatch(getSingleAutomationByPropertyIdAction({ propertyId: automation.propertyId, configId: automation.id }));
    setShowDetails(true);
  };

  const handleAddTaskFromAutomation = (id: string) => {
    if (!selectedContext.propertyId) return;
    switch (createdFrom) {
      case AssociationType.Detail:
        dispatch(
          createTaskFromConfigWithDetailAssociationAction({
            configId: id,
            detailId: associatedId,
            body: {
              propertyId: selectedContext.propertyId,
              buildingId: selectedContext.buildingId,
              unitId: selectedContext.unitId,
              associateWithCurrentLease: false,
              taskSection: taskSection,
            },
            parentPath: parentPath,
          }),
        );
        dispatch(setBackUrlAction(location.pathname));
        break;
      case AssociationType.ExpensePurchaseOrder:
        poLineItemInfo?.lineItemNumber &&
          poLineItemInfo.poNumber &&
          dispatch(
            createTaskFromConfigWithPurchaseOrderLineItemAssociationAction({
              configId: id,
              expenseId: associatedId,
              purchaseOrderNumber: poLineItemInfo?.poNumber,
              changeOrderId: poLineItemInfo.changeOrderId,
              lineItemNumber: poLineItemInfo?.lineItemNumber,
              body: {
                propertyId: selectedContext.propertyId,
                buildingId: AssetInfo?.assetType === AssetType.Building ? AssetInfo.assetId : undefined,
                unitId: AssetInfo?.assetType === AssetType.BuildingUnit ? AssetInfo.assetId : undefined,
                associateWithCurrentLease: false,
                taskSection: taskSection,
              },
              parentPath: parentPath,
            }),
          );
        dispatch(setBackUrlAction(location.pathname));
        break;
      case AssociationType.EmailThread:
        if (!AssetInfo?.assetType || !AssetInfo?.assetId) {
          dispatch(
            showToastMessageAction({
              message: 'You must select an asset to associate this task with',
              severity: 'warning',
            }),
          );
        } else {
          dispatch(
            createTaskFromConfigWithEmailThreadAssociationAction({
              configId: id,
              emailThreadId: associatedId,
              body: {
                propertyId: selectedContext.propertyId,
                buildingId: AssetInfo?.assetType === AssetType.Building ? AssetInfo.assetId : undefined,
                unitId: AssetInfo?.assetType === AssetType.BuildingUnit ? AssetInfo.assetId : undefined,
                associateWithCurrentLease: false,
                taskSection: taskSection,
              },
              parentPath: parentPath,
            }),
          );
          dispatch(setBackUrlAction(location.pathname));
        }
        break;
      case undefined:
        associationType &&
          dispatch(
            createTaskFromAutomationAction({
              configId: id,
              associatedId: associatedId,
              associationType: associationType,
              includeClosed: includeClosed ?? false,
              body: {
                propertyId: selectedContext.propertyId,
                buildingId: selectedContext.buildingId,
                unitId: selectedContext.unitId,
                associateWithCurrentLease: associateWithCurrentLease,
                taskSection: taskSection,
              },
            }),
          );
        break;
      default:
        dispatch(endpointIsntIntegratedAction());
    }
  };

  return (
    <>
      {showDetails && selectedContext.propertyId ? (
        <DialogContent>
          <SopTaskDetailsReadonly onHideAutomationDetails={() => setShowDetails(false)} />
        </DialogContent>
      ) : (
        <DialogContent>
          <Stack>
            <SopTaskList
              sopTasks={sopTasks.value ?? []}
              isLoading={sopTasks.loading}
              isSubmitting={sopTasks.submitting}
              onEditAutomation={handleShowDetails}
              isInAddTaskDialog={true}
              page={page}
              pageSize={pageSize}
              onAddTaskFromAutomation={handleAddTaskFromAutomation}
              includePropertyConfigs={includePropertyConfigs}
              toggleIncludePropertyConfigs={() => setIncludePropertyConfigs(!includePropertyConfigs)}
            />
            <Box sx={{ paddingX: '1rem' }}>
              <AssociateWithCurrentLeaseToggle
                value={associateWithCurrentLease}
                onChange={() => setAssociateWithCurrentLease(!associateWithCurrentLease)}
              />
            </Box>
            <DialogActions>
              <Button onClick={onClose}>Close</Button>
              <LoadingButton
                loading={sopTasks.loading}
                onClick={() => setPageSize(pageSize + 10)}
                variant={'contained'}
              >
                See More
              </LoadingButton>
            </DialogActions>
          </Stack>
        </DialogContent>
      )}
    </>
  );
};
