import React, { FC, useState } from 'react';
import { useExpenses } from '../../redux/expenseSlice';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { SimpleCostAnalysis } from './SimpleCostAnalysis';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import { NotIntegrated } from '../../../../global/NotIntegrated';
import { DetailedCostAnalysis } from './DetailedCostAnalysis';
import { Feature } from '../../../../global/Feature';

export const ExpenseAnalysis: FC = () => {
  const { selectedExpense } = useExpenses();
  const [showDetailedAnalysis, setShowDetailedAnalysis] = useState(false);

  return (
    <Stack spacing={1}>
      <Divider />
      <Box display={'flex'} flexGrow={1} justifyContent={'space-between'}>
        <TextField
          variant={'outlined'}
          value={selectedExpense.value?.expenseStatus ?? ''}
          label={'Expense Status'}
          InputProps={{ readOnly: true }}
        />
        <Box display={'flex'} alignItems={'center'}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={showDetailedAnalysis}
                  onChange={(e) => setShowDetailedAnalysis(e.target.checked)}
                />
              }
              label="Show Detailed Analysis"
            />
          </FormGroup>
        </Box>
      </Box>
      <StyledInfoBox label={'Cost Analysis'}>
        {showDetailedAnalysis ? <DetailedCostAnalysis /> : <SimpleCostAnalysis />}
      </StyledInfoBox>
      <Feature flag={'Feature.LaborAnalysis'}>
        <NotIntegrated>
          <StyledInfoBox label={'Internal Labor Analysis'}></StyledInfoBox>
        </NotIntegrated>
      </Feature>
    </Stack>
  );
};
