import React, { FC } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

type MediaObjectProps = {
  onMediaObjectSelection: (id: string) => void;
  id: string;
  initials?: string;
  primaryText?: string;
  secondaryText1?: string;
  secondaryText2?: string;
  anyUnread?: boolean;
  isSelected: boolean;
};

export const MediaObject: FC<MediaObjectProps> = ({
  onMediaObjectSelection,
  id,
  initials,
  primaryText,
  secondaryText1,
  secondaryText2,
  anyUnread,
  isSelected,
}) => {
  const theme = useTheme();
  const unreadWeight = { fontWeight: anyUnread ? 'bold' : 'normal' };

  return (
    <ListItem
      alignItems="flex-start"
      onClick={() => onMediaObjectSelection(id)}
      sx={(theme) => ({
        backgroundColor: isSelected ? theme.palette.grey['300'] : 'inherit',
        '&:hover': { backgroundColor: theme.palette.grey['100'] },
      })}
    >
      <ListItemAvatar>
        <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>{initials ?? ''}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={unreadWeight}
        secondaryTypographyProps={unreadWeight}
        primary={primaryText}
        secondary={
          <React.Fragment>
            <Typography
              sx={{ ...unreadWeight, display: 'inline' }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              {secondaryText1}
              <br />
            </Typography>
            {secondaryText2 ? `${secondaryText2.substring(0, 60)} Unread Messages` : ''}
          </React.Fragment>
        }
      />
    </ListItem>
  );
};
