import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { ChildGrid } from './ChildGrid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import { ReduxPerson } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { ReduxApplication } from '../../../../redux/applicationTypes';

type Props = {
  applicant: ReduxApplication;
  onRemoveChild: (updatedDependents: ReduxApplication) => void;
  onOpen?: () => void;
};

export const Children = ({ applicant, onRemoveChild, onOpen }: Props) => {
  const [applicantChildren, setApplicantChildren] = useState<ReduxPerson[]>(applicant?.childrenInApartment ?? []);

  useEffect(() => {
    setApplicantChildren(applicant?.childrenInApartment ?? []);
  }, [applicant?.childrenInApartment]);

  const removeChild = (updatedChildren: ReduxPerson[]) => {
    setApplicantChildren(updatedChildren);
    onRemoveChild({ childrenInApartment: updatedChildren });
  };

  return (
    <StyledInfoBox label={'Children'}>
      <Stack>
        <Box sx={{ mb: '.5rem' }}>
          <Button variant="outlined" onClick={onOpen}>
            Add Child
          </Button>
        </Box>
        <Box sx={{ height: 200, width: '100%' }}>
          <ChildGrid applicantChildren={applicantChildren} onRemoveChild={removeChild} />
        </Box>
      </Stack>
    </StyledInfoBox>
  );
};
