import React, { FC, FormEvent, useEffect, useState } from 'react';
import {
  blankInvoice,
  blankPayment,
  blankPurchaseOrder,
  blankQuote,
  blankWorkOrder,
  ExpenseElementType,
  ReduxExpensePayment,
  ReduxInvoice,
  ReduxPurchaseOrder,
  ReduxQuote,
  ReduxWorkOrder,
  updateEditingPurchaseOrderLineItem,
} from '../../redux/expenseData';
import {
  IContactPerson,
  IExpensePurchaseOrderItem,
  ISlimAccountRef,
  IVendor,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  addInvoiceAction,
  addPaymentAction,
  addPurchaseOrderAction,
  addQuoteAction,
  addWorkOrderAction,
  resetExpenseSubmissionAction,
  setInvoiceSubmittingValueAction,
  setPaymentSubmittingValueAction,
  setPurchaseOrderSubmittingValueAction,
  setQuoteSubmittingValueAction,
  useExpenses,
} from '../../redux/expenseSlice';
import { useDispatch } from 'react-redux';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContent from '@mui/material/DialogContent';
import { PaymentForm } from '../forms/PaymentForm';
import { DialogProps } from '@mui/material/Dialog';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import { InvoiceForm } from '../forms/InvoiceForm';
import { PurchaseOrderForm } from '../forms/PurchaseOrderForm';
import { QuoteForm } from '../forms/QuoteForm';
import { WorkOrderForm } from '../forms/WorkOrderForm';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { updateEditingRows } from '../../../utils/updateEditingRows';

export type AddJobElementFormProps = {
  onClose: () => void;
  onAddNewVendor: (name: string) => void;
  type?: ExpenseElementType;
  downstreamDetails?: AddExpenseElementAssociation;
} & Omit<DialogProps, 'onClose'>;

export type AddExpenseElementAssociation = {
  association?: string;
  vendor?: IVendor;
  propertyAccount?: ISlimAccountRef;
};

export const AddExpenseElementDialog: FC<AddJobElementFormProps> = ({
  type,
  open,
  onAddNewVendor,
  onClose,
  downstreamDetails,
}) => {
  const dispatch = useDispatch();
  const { selectedExpense, selectedPayment, selectedInvoice, selectedPurchaseOrder, selectedWorkOrder, selectedQuote } =
    useExpenses();
  const [propertyAccount, setPropertyAccount] = useState<ISlimAccountRef>();
  const [newPayment, setNewPayment] = useState<ReduxExpensePayment>(selectedPayment.submittingValue ?? blankPayment);
  const [newInvoice, setNewInvoice] = useState<ReduxInvoice>(selectedInvoice.submittingValue ?? blankPayment);
  const [newPurchaseOrder, setNewPurchaseOrder] = useState<ReduxPurchaseOrder>(
    selectedPurchaseOrder.submittingValue ?? blankPurchaseOrder,
  );
  const [newWorkOrder, setNewWorkOrder] = useState<ReduxWorkOrder>(selectedWorkOrder.submittingValue ?? blankWorkOrder);
  const [newQuote, setNewQuote] = useState<ReduxQuote>(selectedQuote.submittingValue ?? blankQuote);
  const [title, setTitle] = useState('');
  const jobId = selectedExpense.value?.id;
  const propertyId = selectedExpense.value?.propertyId;
  const buildingId = selectedExpense.value?.buildingId;
  const [wideModal, setWideModal] = useState(false);
  const gridRef = useGridApiRef();
  const loading =
    selectedPayment.submitting ||
    selectedInvoice.submitting ||
    selectedQuote.submitting ||
    selectedWorkOrder.submitting ||
    selectedPurchaseOrder.submitting;

  useEffect(() => {
    if (
      selectedPayment.submitted ||
      selectedInvoice.submitted ||
      selectedQuote.submitted ||
      selectedWorkOrder.submitted ||
      selectedPurchaseOrder.submitted
    )
      handleClose();
  }, [
    selectedPayment.submitted,
    selectedInvoice.submitted,
    selectedQuote.submitted,
    selectedWorkOrder.submitted,
    selectedPurchaseOrder.submitted,
  ]);

  useEffect(() => {
    if (open) {
      setPropertyAccount(downstreamDetails?.propertyAccount);
      switch (type) {
        case ExpenseElementType.Payment:
          setNewPayment(
            selectedPayment.submittingValue ?? {
              ...blankPayment,
              associatedInvoice: downstreamDetails?.association,
              vendor: downstreamDetails?.vendor,
              expenseAccount: downstreamDetails?.propertyAccount,
            },
          );
          setTitle('Add Payment');
          break;
        case ExpenseElementType.Invoice:
          setNewInvoice(
            selectedInvoice.submittingValue ?? {
              ...blankInvoice,
              associatedPurchaseOrder: downstreamDetails?.association,
              vendor: downstreamDetails?.vendor,
            },
          );
          setPropertyAccount(downstreamDetails?.propertyAccount);
          setTitle('Add Invoice');
          break;
        case ExpenseElementType.PurchaseOrder:
          setNewPurchaseOrder(selectedPurchaseOrder.submittingValue ?? blankPurchaseOrder);
          setTitle('Add Purchase Order');
          break;
        case ExpenseElementType.WorkOrder:
          setNewWorkOrder(selectedWorkOrder.submittingValue ?? blankWorkOrder);
          setTitle('Add Work Order');
          break;
        case ExpenseElementType.Quote:
          setNewQuote(
            selectedQuote.submittingValue ?? {
              ...blankQuote,
              associatedWorkOrder: downstreamDetails?.association,
              vendor: downstreamDetails?.vendor,
            },
          );
          setTitle('Add Quote');
          break;
        default:
          break;
      }
    }
  }, [open]);
  const handleChangeInvoice = <TKey extends keyof ReduxInvoice>(field: TKey, value: ReduxInvoice[TKey]) => {
    setNewInvoice({ ...newInvoice, [field]: value });
  };

  const handleChangePayment = <TKey extends keyof ReduxExpensePayment>(
    field: TKey,
    value: ReduxExpensePayment[TKey],
  ) => {
    setNewPayment({ ...newPayment, [field]: value });
  };

  const handleChangeQuote = <TKey extends keyof ReduxQuote>(field: TKey, value: ReduxQuote[TKey]) => {
    setNewQuote({ ...newQuote, [field]: value });
  };

  const handleChangePurchaseOrder = <T extends keyof ReduxPurchaseOrder>(field: T, value: ReduxPurchaseOrder[T]) => {
    setNewPurchaseOrder({ ...newPurchaseOrder, [field]: value });
  };

  const handleChangeWorkOrder = <TKey extends keyof ReduxWorkOrder>(field: TKey, value: ReduxWorkOrder[TKey]) => {
    setNewWorkOrder({ ...newWorkOrder, [field]: value });
  };

  const handleChangeVendor = (value: IVendor | undefined) => {
    switch (type) {
      case ExpenseElementType.Payment:
        setNewPayment({ ...newPayment, vendor: value });
        break;
      case ExpenseElementType.Invoice:
        setNewInvoice({ ...newInvoice, vendor: value });
        break;
      case ExpenseElementType.PurchaseOrder:
        setNewPurchaseOrder({ ...newPurchaseOrder, vendor: value });
        break;
      case ExpenseElementType.Quote:
        setNewQuote({ ...newQuote, vendor: value });
        break;
      default:
        break;
    }
  };

  const handleChangeVendorContact = (value: IContactPerson | undefined) => {
    switch (type) {
      case ExpenseElementType.Payment:
        setNewPayment({ ...newPayment, vendorContact: value });
        break;
      case ExpenseElementType.Invoice:
        setNewInvoice({ ...newInvoice, vendorContact: value });
        break;
      case ExpenseElementType.PurchaseOrder:
        setNewPurchaseOrder({ ...newPurchaseOrder, vendorContact: value });
        break;
      case ExpenseElementType.Quote:
        setNewQuote({ ...newQuote, vendorContact: value });
        break;
    }
  };

  const handleChangeAssociation = (association?: string, vendor?: IVendor, newAccount?: ISlimAccountRef) => {
    switch (type) {
      case ExpenseElementType.Payment:
        setNewPayment({
          ...newPayment,
          associatedInvoice: association,
          vendor: vendor ?? newPayment.vendor,
          expenseAccount: newAccount,
        });
        break;
      case ExpenseElementType.Invoice:
        setNewInvoice({
          ...newInvoice,
          associatedPurchaseOrder: association,
          vendor: vendor ?? newInvoice.vendor,
        });
        break;
      case ExpenseElementType.Quote:
        setNewQuote({
          ...newQuote,
          associatedWorkOrder: association,
          vendor: vendor ?? newQuote.vendor,
        });
        break;
      default:
        break;
    }
    newAccount && setPropertyAccount(newAccount);
  };

  const handlePurchaseOrderQuoteAssociation = (associations?: string[]) => {
    setNewPurchaseOrder({
      ...newPurchaseOrder,
      associatedQuotes: associations,
    });
  };

  const handleChangeExpenseAccount = (account: ISlimAccountRef) => setPropertyAccount(account);
  const handleClose = () => {
    onClose();
    setNewPayment(blankPayment);
    setNewInvoice(blankInvoice);
    setNewQuote(blankQuote);
    setNewPurchaseOrder(blankPurchaseOrder);
    setNewWorkOrder(blankWorkOrder);
    setPropertyAccount(undefined);
    setWideModal(false);
    dispatch(resetExpenseSubmissionAction());
  };

  const handleSetNewSubmittingValue = (name: string) => {
    onAddNewVendor(name);
    switch (type) {
      case ExpenseElementType.Payment:
        dispatch(setPaymentSubmittingValueAction({ ...newPayment, vendor: { ...newPayment.vendor, name: name } }));
        break;
      case ExpenseElementType.Invoice:
        dispatch(setInvoiceSubmittingValueAction({ ...newInvoice, vendor: { ...newInvoice.vendor, name: name } }));
        break;
      case ExpenseElementType.PurchaseOrder:
        dispatch(
          setPurchaseOrderSubmittingValueAction({
            ...newPurchaseOrder,
            vendor: { ...newPurchaseOrder.vendor, name: name },
          }),
        );
        break;
      case ExpenseElementType.Quote:
        dispatch(setQuoteSubmittingValueAction({ ...newQuote, vendor: { ...newQuote.vendor, name: name } }));
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!jobId || !propertyId) return;
    switch (type) {
      case ExpenseElementType.Payment:
        dispatch(
          addPaymentAction({
            jobId: jobId,
            propertyId: propertyId,
            buildingId: buildingId,
            body: {
              ...newPayment,
              paymentSource: newPayment.payment,
              propertyAccountId: newPayment.propertyAccount?.id,
              expenseAccountId: newPayment.expenseAccount?.id,
            },
          }),
        );
        break;
      case ExpenseElementType.Invoice:
        dispatch(
          addInvoiceAction({
            jobId,
            propertyId,
            buildingId,
            body: { ...newInvoice, propertyAccount: propertyAccount ?? undefined },
          }),
        );
        break;
      case ExpenseElementType.Quote:
        dispatch(
          addQuoteAction({
            jobId,
            propertyId,
            body: { ...newQuote, propertyAccount: propertyAccount ?? undefined },
          }),
        );
        break;
      case ExpenseElementType.PurchaseOrder:
        const updatedPurchaseOrder = updateEditingRows<ReduxPurchaseOrder, IExpensePurchaseOrderItem>(
          gridRef,
          newPurchaseOrder,
          'items',
          updateEditingPurchaseOrderLineItem,
        );
        dispatch(
          addPurchaseOrderAction({
            jobId,
            propertyId,
            body: {
              ...updatedPurchaseOrder,
              propertyAccount: propertyAccount ?? undefined,
              items: updatedPurchaseOrder.items?.filter((i) => i.name !== undefined),
            },
          }),
        );
        break;
      case ExpenseElementType.WorkOrder:
        dispatch(
          addWorkOrderAction({
            jobId,
            propertyId,
            body: { ...newWorkOrder, propertyAccountId: propertyAccount?.id },
          }),
        );
        break;
      default:
        break;
    }
  };
  return (
    <DialogLayout
      title={title}
      onClose={handleClose}
      open={open}
      style={{ zIndex: '1000' }}
      maxWidth={wideModal ? 'md' : 'sm'}
    >
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {
            {
              payment: (
                <PaymentForm
                  value={newPayment}
                  onChangePayment={handleChangePayment}
                  onChangeVendor={handleChangeVendor}
                  propertyId={propertyId!}
                  onAddNewVendor={handleSetNewSubmittingValue}
                  onAssociateInvoice={handleChangeAssociation}
                  onChangeVendorContact={handleChangeVendorContact}
                />
              ),
              invoice: (
                <InvoiceForm
                  value={newInvoice}
                  onChangeInvoice={handleChangeInvoice}
                  onChangeVendor={handleChangeVendor}
                  propertyId={propertyId!}
                  onChangeExpenseAccount={handleChangeExpenseAccount}
                  expenseAccount={propertyAccount}
                  onAddNewVendor={handleSetNewSubmittingValue}
                  onAssociatePurchaseOrder={handleChangeAssociation}
                  onChangeVendorContact={handleChangeVendorContact}
                />
              ),
              purchaseOrder: (
                <PurchaseOrderForm
                  value={newPurchaseOrder}
                  onChangePurchaseOrder={handleChangePurchaseOrder}
                  onChangeVendor={handleChangeVendor}
                  propertyId={propertyId!}
                  onChangeExpenseAccount={handleChangeExpenseAccount}
                  expenseAccount={propertyAccount}
                  onAddNewVendor={handleSetNewSubmittingValue}
                  onAssociateQuote={handlePurchaseOrderQuoteAssociation}
                  onChangeVendorContact={handleChangeVendorContact}
                  onWidenModal={() => setWideModal(!wideModal)}
                  gridApiRef={gridRef}
                />
              ),
              quote: (
                <QuoteForm
                  value={newQuote}
                  onChangeQuote={handleChangeQuote}
                  onChangeVendor={handleChangeVendor}
                  propertyId={propertyId!}
                  onChangeExpenseAccount={handleChangeExpenseAccount}
                  expenseAccount={propertyAccount}
                  onAddNewVendor={handleSetNewSubmittingValue}
                  onAssociateWorkOrder={handleChangeAssociation}
                  onChangeVendorContact={handleChangeVendorContact}
                />
              ),
              workOrder: (
                <WorkOrderForm
                  value={newWorkOrder}
                  onChangeWorkOrder={handleChangeWorkOrder}
                  propertyId={propertyId!}
                  expenseAccount={propertyAccount}
                  onChangeExpenseAccount={handleChangeExpenseAccount}
                />
              ),
              job: <></>,
            }[type ?? ExpenseElementType.Expense]
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <LoadingButton variant={'contained'} type={'submit'} loading={loading}>
            {title}
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};
