import React, { FC, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { SelectChangeEvent } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { getRouteForAssociation } from '../../../utils/associationTypeUtils';
import Launch from '@mui/icons-material/Launch';
import { TaskCategoryAutocomplete } from '../TaskCategoryAutocomplete';
import { AssigneeAutocomplete, UserOption } from '../AssigneeAutocomplete';
import { TaskStatusSelect } from '../TaskStatusSelect';
import { DatePicker } from '@mui/x-date-pickers-pro';
import {
  ReduxTask,
  ReduxTimeConfiguration,
  ReduxTimeTracking,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import {
  AssetType,
  IListUnitOpenLeasesHandlerSingleResponse,
  ITaskCategory,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import Stack from '@mui/material/Stack';
import { ScheduleTimeConfiguration } from '../scheduledTasks/ScheduleTimeConfiguration';
import { OpenLeasesForUnitSelect } from '../../../../unit/components/OpenLeasesForUnitSelect';
import { useDispatch } from 'react-redux';
import { getUnitOpenLeasesAction, useUnit } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/unitSlice';
import { toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { WatcherAutocomplete } from '../WatcherAutocomplete';

type TaskInfoProps = {
  task: ReduxTask;
  onCategorySelection: (value: ITaskCategory | null) => void;
  onAssigneeSelection: (value: UserOption | null | undefined) => void;
  onStatusSelection: (e: SelectChangeEvent) => void;
  onSetTask: (key: keyof ReduxTask, value: string) => void;
  onSetTaskTimeConfiguration: (config?: ReduxTimeConfiguration) => void;
  onSetTaskTimeTracking: (tracking: ReduxTimeTracking) => void;
  onRemoveScheduling: () => void;
  onLeaseSelection: (v?: string) => void;
  onUpdateWatchers: (watchers: string[]) => void;
};

export const TaskInfo: FC<TaskInfoProps> = ({
  task,
  onSetTask,
  onSetTaskTimeConfiguration,
  onSetTaskTimeTracking,
  onAssigneeSelection,
  onCategorySelection,
  onStatusSelection,
  onRemoveScheduling,
  onLeaseSelection,
  onUpdateWatchers,
}) => {
  const dispatch = useDispatch();
  const { selectedUnitOpenLeases } = useUnit();
  const [actualStartDate, setActualStartDate] = useState<Date | null>(
    toStandardDate(task?.taskTimeTracking?.actualStartDateTime) ?? null,
  );
  const [actualEndDate, setActualEndDate] = useState<Date | null>(
    toStandardDate(task?.taskTimeTracking?.actualEndDateTime) ?? null,
  );
  const watchers = task.watchers?.filter((watcher) => !!watcher?.id).map((watcher) => watcher.id!) ?? [];

  useEffect(() => {
    setActualStartDate(toStandardDate(task?.taskTimeTracking?.actualStartDateTime) ?? null);
  }, [task?.taskTimeTracking?.actualEndDateTime]);

  useEffect(() => {
    setActualEndDate(toStandardDate(task?.taskTimeTracking?.actualEndDateTime) ?? null);
  }, [task?.taskTimeTracking?.actualEndDateTime]);

  useEffect(() => {
    task.associatedId &&
      task.assetType === AssetType.BuildingUnit &&
      dispatch(getUnitOpenLeasesAction(task.associatedId));
  }, [task]);

  const [associatedLease, setAssociatedLease] = useState<IListUnitOpenLeasesHandlerSingleResponse | undefined>(
    selectedUnitOpenLeases.value?.results?.find((l) => l.leaseId === task.visibleToLeaseId),
  );

  useEffect(() => {
    setAssociatedLease(selectedUnitOpenLeases.value?.results?.find((l) => l.leaseId === task.visibleToLeaseId));
  }, [selectedUnitOpenLeases.value?.results]);

  const handleLeaseSelection = (value: IListUnitOpenLeasesHandlerSingleResponse | null) => {
    setAssociatedLease(value ?? undefined);
    onLeaseSelection(value?.leaseId);
  };

  return (
    <Stack spacing={2}>
      <StyledInfoBox label={'Info'}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              variant={'outlined'}
              label={'Task Name'}
              sx={{ margin: 0 }}
              value={task?.name ? task.name : ''}
              onChange={(e) => onSetTask('name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TaskCategoryAutocomplete onChange={onCategorySelection} value={task?.category} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TaskStatusSelect value={task?.status ? task.status : ''} onChange={(e) => onStatusSelection(e)} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id={'test'}
              fullWidth
              disabled
              label={'Associated Asset'}
              value={task?.assetName ?? ''}
              variant="standard"
              InputProps={{
                endAdornment: task?.assetType && task?.associatedId && (
                  <InputAdornment position="end">
                    <Tooltip title={task.assetPath ? 'Go to Associated Asset' : ''}>
                      <IconButton href={getRouteForAssociation(task.assetType, task.associatedId)}>
                        <Launch />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <AssigneeAutocomplete onValueChange={onAssigneeSelection} value={task?.assignee} />
          </Grid>
          {task.assetType === AssetType.BuildingUnit && (
            <Grid item xs={12} sm={4}>
              <OpenLeasesForUnitSelect onChange={handleLeaseSelection} value={associatedLease} />
            </Grid>
          )}
        </Grid>
      </StyledInfoBox>
      <StyledInfoBox label={'Time Tracking'}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Tooltip
              title={
                task?.taskTimeTracking?.actualStartDateTime
                  ? ''
                  : 'Actual Start Date set when status updated to Started'
              }
            >
              <DatePicker
                label="Actual Start Date"
                readOnly
                value={actualStartDate}
                slotProps={{ textField: { fullWidth: true } }}
                onChange={() => {}}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Tooltip
              title={
                task?.taskTimeTracking?.actualStartDateTime
                  ? ''
                  : 'Actual End Date set when status updated to Completed'
              }
            >
              <DatePicker
                label="Actual End Date"
                readOnly
                value={actualEndDate}
                slotProps={{ textField: { fullWidth: true } }}
                onChange={() => {}}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type={'number'}
              label={'Anticipated Hours'}
              value={task?.taskTimeTracking?.anticipatedManHours ? task.taskTimeTracking.anticipatedManHours : ''}
              onChange={(e) =>
                onSetTaskTimeTracking({
                  ...task.taskTimeTracking,
                  anticipatedManHours: Number(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type={'number'}
              label={'Actual Hours'}
              value={task?.taskTimeTracking?.actualManHours ? task.taskTimeTracking.actualManHours : ''}
              onChange={(e) =>
                onSetTaskTimeTracking({
                  ...task.taskTimeTracking,
                  actualManHours: Number(e.target.value),
                })
              }
            />
          </Grid>
        </Grid>
      </StyledInfoBox>
      <StyledInfoBox label={'Scheduling'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ScheduleTimeConfiguration
              timeConfiguration={task.timeConfiguration}
              onSetTimeConfiguration={onSetTaskTimeConfiguration}
              editingMode
              onRemoveScheduling={onRemoveScheduling}
              isNotScheduledTask
            />
          </Grid>
        </Grid>
      </StyledInfoBox>
      <StyledInfoBox label={'Watchers'}>
        <WatcherAutocomplete setWatchers={(updateWatchers) => onUpdateWatchers(updateWatchers)} watchers={watchers} />
      </StyledInfoBox>
    </Stack>
  );
};
