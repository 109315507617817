import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getBuildingByIdAction, useBuilding } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/buildingSlice';
import { AssetParams } from '../../../AppRouter';
import { OuterTabsLayout } from '../../global/layout/OuterTabsLayout';
import { AssetType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const BuildingTabs = () => {
  const building = useBuilding();
  const { id } = useParams<AssetParams>();
  const dispatch = useDispatch();

  useEffect(() => {
    id && dispatch(getBuildingByIdAction(id));
  }, [id]);

  return (
    <OuterTabsLayout
      assetType={AssetType.Building}
      loading={building.selectedBuilding.loading}
      value={building.selectedBuilding.value}
    />
  );
};
