import React, { FormEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { addTenantAction, closeNewTenantDialogAction, useLeases } from '../../redux/leasesSlice';
import { ReduxTenant } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import LoadingButton from '@mui/lab/LoadingButton';
import { DialogLayout } from '../../../dialogs/DialogLayout';

export const AddTenantDialog = () => {
  const dispatch = useDispatch();
  const { newTenant: reduxNewTenant, editingLeaseId, editingLeaseType } = useLeases();
  const property = useProperty();

  const [newTenant, setNewTenant] = useState<ReduxTenant | undefined>(reduxNewTenant.submittingValue);

  useEffect(() => {
    setNewTenant(reduxNewTenant.submittingValue);
  }, [reduxNewTenant]);

  function handleFieldChange<TKey extends keyof ReduxTenant>(field: TKey, value: ReduxTenant[TKey]) {
    setNewTenant({ ...newTenant, [field]: value });
  }

  const handleAddTenant = (e: FormEvent) => {
    e.preventDefault();
    if (editingLeaseId && newTenant && property.selectedProperty.value?.id && editingLeaseType) {
      dispatch(
        addTenantAction({
          leaseId: editingLeaseId,
          assetId: property.selectedProperty.value?.id,
          leaseType: editingLeaseType,
          tenant: newTenant,
        }),
      );
    }
  };

  const handleClose = () => dispatch(closeNewTenantDialogAction());

  useEffect(() => {
    if (reduxNewTenant.submitted) handleClose();
  }, [reduxNewTenant.submitted]);

  return (
    <DialogLayout title={'Add Tenant To Lease'} open={!!reduxNewTenant.submittingValue} onClose={handleClose}>
      <form onSubmit={handleAddTenant} autoComplete="off">
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="firstName"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => handleFieldChange('firstName', e.target.value)}
          />
          <TextField
            required
            margin="dense"
            id="lastName"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => handleFieldChange('lastName', e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="contained" type="submit" loading={reduxNewTenant.submitting}>
            Add Tenant
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};
