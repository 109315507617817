import React, { FC } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import ReactInputMask, { Props } from 'react-input-mask';

type PhoneTextFieldProps = {
  includeHelperText?: boolean;
} & Omit<TextFieldProps, 'value' | 'onChange' | 'type' | 'pattern' | 'onBlur'> &
  Omit<Props, 'mask'>;

export const PhoneTextField: FC<PhoneTextFieldProps> = ({
  onChange,
  required,
  value,
  onBlur,
  disabled,
  includeHelperText,
  ...otherProps
}) => {
  return (
    <ReactInputMask mask="999-999-9999" onChange={onChange} value={value} disabled={disabled} onBlur={onBlur}>
      <TextField
        {...otherProps}
        helperText={(includeHelperText || typeof includeHelperText === 'undefined') && 'format: xxx-xxx-xxxx'}
        inputProps={{ pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}' }}
        type="tel"
        required={required}
      />
    </ReactInputMask>
  );
};
