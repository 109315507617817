import { initLoadable, Loadable } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { BankAccountType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';

export interface BankAccountsState {
  plaidLinkToken: Loadable<string>;
  plaidLinkTokenUpdate: Loadable<string>;
  plaidProcessorToken: Loadable<string>;
}

const initialState: BankAccountsState = {
  plaidLinkToken: initLoadable<string>(undefined),
  plaidLinkTokenUpdate: initLoadable<string>(undefined),
  plaidProcessorToken: initLoadable<string>(undefined),
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const bankAccountsSlice = createSlice({
  name: 'bankAccounts',
  initialState,
  reducers: {
    createPropertyBankAccountAction: (
      state,
      action: PayloadAction<{
        publicToken: string;
        accountId: string;
        propertyId: string;
        mask: string;
        accountName: string;
        type: string;
        subType: string;
        verificationStatus?: string;
      }>,
    ) => {
      state.plaidProcessorToken.loading = true;
      state.plaidProcessorToken.loaded = false;
    },
    createTenantBankAccountAction: (
      state,
      action: PayloadAction<{
        publicToken: string;
        accountId: string;
        userId: string;
        mask: string;
        accountName: string;
        type: string;
        subType: string;
        verificationStatus?: string;
      }>,
    ) => {
      state.plaidProcessorToken.loading = true;
      state.plaidProcessorToken.loaded = false;
    },
    createBankAccountSuccessAction: (state) => {
      state.plaidProcessorToken.loading = false;
      state.plaidProcessorToken.loaded = true;
    },
    createPlaidProcessorTokenFailAction: (state) => {
      state.plaidProcessorToken.loading = false;
      state.plaidProcessorToken.loaded = false;
    },
    clearPlaidLinkTokenAction: (state) => {
      state.plaidLinkToken.value = undefined;
    },

    getPlaidLinkTokenAction: (
      state,
      action: PayloadAction<{ bankAccountType: BankAccountType; userOrPropertyId: string }>,
    ) => {
      state.plaidLinkToken.loading = true;
      state.plaidLinkToken.loaded = false;
    },
    updatePlaidLinkTokenAction: (
      state,
      action: PayloadAction<{ bankAccountType: BankAccountType; userOrPropertyId: string; accessToken: string }>,
    ) => {
      state.plaidLinkTokenUpdate.loading = true;
      state.plaidLinkTokenUpdate.loaded = false;
    },
    updatePlaidLinkTokenSuccessAction: (
      state,
      action: PayloadAction<{
        plaidLinkToken: string;
      }>,
    ) => {
      state.plaidLinkTokenUpdate.loaded = true;
      state.plaidLinkTokenUpdate.value = action.payload.plaidLinkToken;
    },
    getPlaidLinkTokenSuccessAction: (
      state,
      action: PayloadAction<{
        plaidLinkToken: string;
      }>,
    ) => {
      state.plaidLinkToken.loading = false;
      state.plaidLinkToken.loaded = true;
      state.plaidLinkToken.value = action.payload.plaidLinkToken;
    },
    getPlaidLinkTokenFailAction: (state) => {
      state.plaidLinkToken.loading = false;
      state.plaidLinkToken.loaded = false;
    },
    updatePlaidLinkTokenFailAction: (state) => {
      state.plaidLinkTokenUpdate.loading = false;
      state.plaidLinkTokenUpdate.loaded = false;
    },
    plaidRefreshSuccessfulAction: (state, action: PayloadAction<{ mtAccountId: string; propertyId?: string }>) => {},
    plaidRevalidateFinishAction: (state) => {
      state.plaidLinkTokenUpdate.loading = false;
    },
  },
});

export const {
  getPlaidLinkTokenAction,
  updatePlaidLinkTokenAction,
  createPropertyBankAccountAction,
  createTenantBankAccountAction,
  createPlaidProcessorTokenFailAction,
  createBankAccountSuccessAction,
  getPlaidLinkTokenFailAction,
  updatePlaidLinkTokenFailAction,
  getPlaidLinkTokenSuccessAction,
  updatePlaidLinkTokenSuccessAction,
  clearPlaidLinkTokenAction,
  plaidRefreshSuccessfulAction,
  plaidRevalidateFinishAction,
} = bankAccountsSlice.actions;

export function useBankAccounts(): BankAccountsState;
export function useBankAccounts<T>(selector: (state: BankAccountsState) => T): T;
export function useBankAccounts<T>(selector?: (state: BankAccountsState) => T) {
  return useSelector((rootState: RootState) => (selector ? selector(rootState.bankAccounts) : rootState.bankAccounts));
}
