import React, { FC, FormEvent, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { ITaskCategory } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { createCategoryAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/categorySlice';
import Stack from '@mui/material/Stack';
import { DialogLayout } from '../../../../../_shared/dialogs/DialogLayout';

type AddCategoryDialogProps = {
  propertyId: string;
  open: boolean;
  onClose: () => any;
};

export const AddCategoryDialog: FC<AddCategoryDialogProps> = ({ propertyId, open, onClose }) => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState<ITaskCategory>({
    id: '',
    propertyId: propertyId,
    name: '',
    isEnabled: true,
  });

  const handleClose = () => {
    setCategory({
      id: '',
      propertyId: propertyId,
      name: '',
      isEnabled: true,
    });
    onClose();
  };

  const addCategory = (e: FormEvent) => {
    e.preventDefault();
    dispatch(createCategoryAction(category));
    handleClose();
  };

  return (
    <DialogLayout title={'New Category'} maxWidth="md" fullWidth={true} open={open} onClose={handleClose}>
      <DialogContent>
        <form onSubmit={addCategory} autoComplete={'off'}>
          <Stack spacing={2}>
            <TextField
              autoFocus
              fullWidth
              required
              margin="dense"
              id="name"
              label="Category Name"
              type="text"
              variant="standard"
              value={category.name}
              onChange={(e) => {
                setCategory({ ...category, name: e.target.value });
              }}
            />
            <DialogActions sx={{ marginTop: '1rem' }}>
              <Button onClick={onClose}>Close</Button>
              <Button type="submit" variant="contained">
                Add Category
              </Button>
            </DialogActions>
          </Stack>
        </form>
      </DialogContent>
    </DialogLayout>
  );
};
