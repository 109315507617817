import React, { FC } from 'react';
import {
  LeaseDetailedStatus,
  SignedStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { appTheme } from '../../../../../AppTheme';
import { leaseStatusMap, ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

type LeaseStatusChipProps = {
  lease: ReduxLease;
};

const getLeaseStatus = (detailedStatus?: LeaseDetailedStatus, lease?: ReduxLease) => {
  if (detailedStatus === undefined || lease === undefined) return 'Unknown';
  if (detailedStatus === LeaseDetailedStatus.ReturnFundsThenClose) {
    if (lease.hasPendingTransactions) {
      return 'Funds Processing';
    } else if ((lease.delinquency ?? 0) >= 0) {
      return 'Ready for Close';
    } else {
      return 'Rectify Deposit';
    }
  } else {
    const isSigned = lease.leaseTerms?.some((t) => t.signedStatus === SignedStatus.Signed) ?? false;
    let label = detailedStatus ? leaseStatusMap.get(detailedStatus) : '';
    if (label === undefined) console.log(detailedStatus);
    if (detailedStatus === LeaseDetailedStatus.Draft && isSigned) {
      label = 'Signed';
    } else if (detailedStatus === LeaseDetailedStatus.DepositPaid && isSigned) {
      label = 'Signed & Paid';
    }
    if (lease.hasPendingTransactions) {
      const detailedLabel = `${label} (Funds Processing)`;
      return (
        <Tooltip title={detailedLabel}>
          <span>{detailedLabel}</span>
        </Tooltip>
      );
    }
    return label;
  }
};

export const LeaseStatusChip: FC<LeaseStatusChipProps> = ({ lease }) => {
  return (
    <Chip
      sx={
        lease.detailedStatus === LeaseDetailedStatus.ReturnFundsThenClose
          ? {
              backgroundColor: appTheme.palette.warning.main,
              color: appTheme.palette.background.default,
            }
          : lease.detailedStatus === LeaseDetailedStatus.Active
            ? {
                backgroundColor: appTheme.palette.primary.light,
              }
            : lease.detailedStatus === LeaseDetailedStatus.ActivePendingEviction ||
                lease.detailedStatus === LeaseDetailedStatus.MovedOutEviction ||
                lease.detailedStatus === LeaseDetailedStatus.ActiveMonthToMonthPendingEviction ||
                lease.detailedStatus === LeaseDetailedStatus.ClosedEviction ||
                lease.detailedStatus === LeaseDetailedStatus.ReturnFundsEvicted ||
                lease.detailedStatus === LeaseDetailedStatus.ReturnFundsCancelling
              ? {
                  backgroundColor: appTheme.palette.error.main,
                  color: appTheme.palette.background.default,
                  opacity: lease.detailedStatus === LeaseDetailedStatus.ClosedEviction ? '50%' : '100%',
                }
              : {}
      }
      label={getLeaseStatus(lease.detailedStatus, lease)}
    />
  );
};
