import React, { FC, useEffect, useState } from 'react';
import { DrawerBreadcrumb, DrawerLayout, DrawerNavItem } from '../../../../../../global/layout/DrawerLayout';
import {
  getSingleAutomationByPropertyIdAction,
  setSelectedSopTaskAction,
  useSopTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import Box from '@mui/material/Box';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DetailsTab, SopTaskTab } from '../../../../../../_shared/tabs/TabTypeEnums';
import { getAssetTypeFromPathname } from '../../../../../../_shared/utils/getAssetTypeFromPathname';
import { SopTaskDetails } from './SopTaskDetails';
import { SopTaskChecklist } from './SopTaskChecklist';
import { StyledInfoBox } from '../../../../../../_shared/styledComponents/StyledInfoBox';
import { DocumentList } from '../../../../../../_shared/documents/components/DocumentList';
import {
  AssociationChildType,
  AssociationType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useRouting } from '../../../../../../_shared/utils/useRouting';
import { useDetails } from '@monkeyjump-labs/cam-fe-shared/dist/redux/details/detailsSlice';
import { TaskAssociationList } from '../../../../../../_shared/tasks/components/taskDialogs/TaskAssociationList';
import { useIcons } from '../../../../../../_shared/icons/useIcons';

export const SopTaskDrawer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAssociationIcon, getTabIcon } = useIcons();
  const { selectedSopTask } = useSopTasks();
  const { selectedDetail } = useDetails();
  const { handleNavigateFromAssociationClose } = useRouting();
  const { id, outerTab, innerTab, sopTaskId } = useParams();
  const [tabValue, setTabValue] = useState<string>(SopTaskTab.Task);
  const [searchParams] = useSearchParams();
  const [breadCrumb, setBreadCrumb] = useState<DrawerBreadcrumb | undefined>();

  useEffect(() => {
    sopTaskId && id && dispatch(getSingleAutomationByPropertyIdAction({ propertyId: id, configId: sopTaskId }));
  }, [sopTaskId, id]);

  const handleCloseDrawer = () => {
    dispatch(setSelectedSopTaskAction(undefined));
    handleNavigateFromAssociationClose();
  };

  useEffect(() => {
    const returnType = searchParams.get('returnType');
    const returnId = searchParams.get('returnId');
    const label = searchParams.get('returnName');

    if (returnType && returnId) {
      if (returnType === 'Detail')
        setBreadCrumb({
          id: returnId,
          label: `Detail: ${label}`,
          onClick: () =>
            navigate(
              `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/detail/${returnId}/${
                DetailsTab.Associations
              }`,
            ),
        });
    } else {
      setBreadCrumb(undefined);
    }
  }, [searchParams]);

  const tabs: DrawerNavItem[] = [
    {
      icon: getAssociationIcon(AssociationType.Task),
      label: 'Task',
      value: SopTaskTab.Task,
    },
    {
      icon: getAssociationIcon(AssociationChildType.ChecklistItem),
      label: 'Checklist',
      value: SopTaskTab.Checklist,
    },
    {
      icon: getTabIcon('document'),
      label: 'Documents',
      value: SopTaskTab.Documents,
    },
  ];

  const getTitle = (): string => {
    const titleBeginning = selectedDetail.value ? `Local SOP Task` : `SOP Task`;
    const titleEnd = selectedSopTask.value?.name ? `: ${selectedSopTask.value?.name}` : ``;
    return titleBeginning + titleEnd;
  };

  return (
    <DrawerLayout
      title={getTitle()}
      subTitle={selectedSopTask.value?.propertyName}
      open={!!selectedSopTask.value}
      onCloseDrawer={handleCloseDrawer}
      breadcrumbs={breadCrumb ? [breadCrumb] : undefined}
      tabs={tabs}
      tabValue={tabValue}
      onTabChange={(newValue: string) => {
        navigate(
          `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/sopTask/${sopTaskId}/${newValue}`,
        );
        setTabValue(newValue);
      }}
    >
      <Box sx={{ width: '100%', p: 1 }}>
        {
          {
            info: (
              <>
                <StyledInfoBox label={'Task'}>
                  <SopTaskDetails />
                </StyledInfoBox>
                <TaskAssociationList
                  associationType={AssociationType.TaskConfiguration}
                  id={selectedSopTask.value?.id}
                />
              </>
            ),
            checklist: (
              <StyledInfoBox label={'Checklist'}>
                <SopTaskChecklist />
              </StyledInfoBox>
            ),
            documents: (
              <StyledInfoBox label={'Documents'}>
                <DocumentList
                  propertyId={selectedSopTask.value?.propertyId}
                  association={{
                    associatedId: selectedSopTask.value?.id,
                    associationType: AssociationType.TaskConfiguration,
                  }}
                  value={selectedSopTask.value?.documents ?? []}
                />
              </StyledInfoBox>
            ),
          }[tabValue || SopTaskTab.Task]
        }
      </Box>
    </DrawerLayout>
  );
};
