import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NotFound } from '../../../notFound/NotFound';
import Grid from '@mui/material/Grid';
import { ListingDetails } from '../shared/details/ListingDetails';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import { AddressMap } from '../../../_shared/maps/AddressMap';
import Divider from '@mui/material/Divider';
import { AgentInfo } from './AgentInfo';
import { getListingAction, useListings } from '../../redux/listingSlice';
import LinearProgress from '@mui/material/LinearProgress';
import { handleFormatAddress } from '../shared/ListingCard';
import { AddApplicantDialog } from '../../../property/components/applications/applicationDialogs/AddApplicantDialog';
import Button from '@mui/material/Button';
import { ListingsHeader } from '../../../global/components/header/ListingsHeader';
import { PhotoGrid } from './PhotoGrid';
import { SimpleDocumentType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { BackButton } from '../../../_shared/buttons/BackButton';

type AvailableListingProps = {
  isPropertyView?: boolean;
};

export const AvailableListing: FC<AvailableListingProps> = ({ isPropertyView }) => {
  const { listingId, propertyId } = useParams();
  const dispatch = useDispatch();
  const { selectedListing } = useListings();
  const listing = selectedListing.value;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isPropertyView) {
      listingId && dispatch(getListingAction({ listingId, isWebView: isPropertyView ? false : true }));
    }
  }, [listingId]);

  const youtubeData: ReduxDocument | undefined = listing?.youtubeUrl
    ? { uri: listing.youtubeUrl, metadata: { documentType: SimpleDocumentType.Video, title: 'Youtube Link' } }
    : undefined;

  const photos = listing?.photos ? (youtubeData ? [youtubeData, ...listing.photos] : listing.photos) : [];

  return selectedListing.loading ? (
    <LinearProgress />
  ) : listing ? (
    <>
      {!isPropertyView && <ListingsHeader />}
      <Grid container spacing={1}>
        {!isPropertyView && (
          <Grid item xs={12}>
            <Box sx={{ px: 5 }}>
              <BackButton />
            </Box>
          </Grid>
        )}
        {!isPropertyView && <Grid item xs={2}></Grid>}
        <Grid item xs={isPropertyView ? 12 : 8} style={styles.imageContainer}>
          <Paper sx={styles.paper}>
            <PhotoGrid photos={photos} />
          </Paper>
        </Grid>
        {!isPropertyView && <Grid item xs={2}></Grid>} {!isPropertyView && <Grid item xs={2}></Grid>}
        <Grid item xs={isPropertyView ? 5 : 3}>
          <Paper sx={styles.paper}>
            <ListingDetails listing={listing} detailed={true} />
            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
              <Button variant={'contained'} onClick={() => setOpen(true)} disabled={isPropertyView}>
                Apply Now
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={isPropertyView ? 7 : 5}>
          <Paper sx={styles.paper}>
            <Stack spacing={1}>
              <Typography variant={'h3'} color={'primary.main'}>
                {handleFormatAddress(listing.address)}
              </Typography>
              {/*<Typography variant={'h4'}>Snappy summary here?</Typography>*/}
              <Typography>{listing.description}</Typography>
              <Divider />
              {listing.agent && listing.agent.firstName && listing.agent.lastName && listing.agent.contactInfo && (
                <AgentInfo listing={listing} />
              )}
            </Stack>
            <Box sx={styles.box}>
              <AddressMap address={handleFormatAddress(listing.address)} />
            </Box>
          </Paper>
        </Grid>
        <AddApplicantDialog propertyId={propertyId} open={open} onClose={() => setOpen(false)} isWebView={true} />
      </Grid>
    </>
  ) : (
    <NotFound />
  );
};

const styles = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    p: 1,
    height: '100%',
  },
  box: {
    border: '.1px solid black',
    height: '100%',
    minHeight: '25rem',
    mt: 3,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover' as CSSProperties['objectFit'],
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
};
