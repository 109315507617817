import React from 'react';
import { useApplications } from '../../../../redux/applicationSlice';
import { DocumentList } from '../../../../../_shared/documents/components/DocumentList';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const ApplicationDocuments = () => {
  const { selectedApplication } = useApplications();

  return (
    <StyledInfoBox label={'Documents'}>
      <DocumentList
        propertyId={selectedApplication.value?.rentalPropertyId}
        association={{
          associatedId: selectedApplication.value?.id,
          associationType: AssociationType.Application,
        }}
        value={selectedApplication.value?.documents ?? []}
        uploading={selectedApplication.submitting}
      />
    </StyledInfoBox>
  );
};
