import React, { useEffect } from 'react';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { useDispatch } from 'react-redux';
import { addListingFromUnitsAction, getListingByUnitIdAction, useListings } from '../../redux/listingSlice';
import { ListingTab } from '../../../_shared/tabs/TabTypeEnums';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { DrawerNavItem } from '../../../global/layout/DrawerLayout';
import Face6Icon from '@mui/icons-material/Face6';
import { useIcons } from '../../../_shared/icons/useIcons';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import TabContext from '@mui/lab/TabContext';
import { useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import { ListingTabs } from './listingTabs/ListingTabs';

export const UnitListing = () => {
  const theme = useTheme();
  const { getTabIcon } = useIcons();
  const { selectedContext } = useAssets();
  const { selectedListing } = useListings();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = React.useState<ListingTab>(ListingTab.Info);

  const t: DrawerNavItem[] = [
    {
      icon: getTabIcon('info'),
      label: 'Info',
      value: ListingTab.Info,
    },
    {
      icon: getTabIcon('photo'),
      label: 'Photo Gallery',
      value: ListingTab.Photos,
    },
    {
      icon: <Face6Icon />,
      label: 'Agent',
      value: ListingTab.Agent,
    },
    {
      icon: getTabIcon('webView'),
      label: 'Web View',
      value: ListingTab.WebView,
    },
  ];

  useEffect(() => {
    selectedContext.unitId && dispatch(getListingByUnitIdAction(selectedContext.unitId));
  }, [selectedContext.unitId]);

  const onTabChange = (newValue: string) => {
    setSelectedTab(newValue as ListingTab);
  };
  return selectedListing.value ? (
    <TabContext value={selectedTab}>
      <Box border={'.1rem solid gray'} p={1} borderRadius={2}>
        <Tabs
          onChange={(e, v) => onTabChange(v)}
          variant="scrollable"
          scrollButtons="auto"
          aria-label={`unit-advertise-tabs`}
          value={selectedTab}
        >
          {t.map((tab) => (
            <Tooltip title={tab.label} key={tab.value}>
              <Tab
                icon={tab.icon}
                value={tab.value}
                aria-selected={selectedTab === tab.value}
                sx={{
                  backgroundColor: selectedTab === tab.value ? theme.palette.secondary.light : 'inherit',
                  borderTopLeftRadius: 7,
                  borderTopRightRadius: 7,
                }}
              />
            </Tooltip>
          ))}
        </Tabs>
        <Divider />
        <ListingTabs tab={selectedTab} />
      </Box>
    </TabContext>
  ) : selectedListing.loading ? (
    <LinearProgress />
  ) : (
    <Box>
      <Button
        variant={'outlined'}
        startIcon={<AddIcon />}
        onClick={() => selectedContext.unitId && dispatch(addListingFromUnitsAction(selectedContext.unitId))}
      >
        Add Unit Listing
      </Button>
    </Box>
  );
};
