import React, { FC, FormEvent, useEffect, useState } from 'react';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import { FullForm } from '../../../styledComponents/FullForm';
import { useDispatch } from 'react-redux';
import {
  addDetailAction,
  addSubDetailAction,
  resetDetailSubmissionAction,
  useDetails,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/details/detailsSlice';
import DialogContent from '@mui/material/DialogContent';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { ReduxDetail } from '@monkeyjump-labs/cam-fe-shared/dist/types/detailTypes';
import { DetailAssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { DetailInfoForm } from '../DetailInfoForm';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';

export enum DetailsAddDialogType {
  AddDetail,
  AddSubDetail,
}

export type DetailsDialogProps = {
  associationType: DetailAssociationType;
  associatedId: string;
  type: DetailsAddDialogType;
  onClose: () => void;
  open: boolean;
  parentId?: string;
};

export const AddDetailDialog: FC<DetailsDialogProps> = ({
  associationType,
  associatedId,
  type,
  onClose,
  open,
  parentId,
}) => {
  const dispatch = useDispatch();
  const { details, selectedDetail } = useDetails();
  const { selectedContext } = useAssets();
  const [editingDetail, setEditingDetail] = useState<ReduxDetail>({ name: '', description: '', parentId: parentId });
  const title = type === DetailsAddDialogType.AddDetail ? 'Add New Detail' : 'Add Component';

  useEffect(() => {
    if (type === DetailsAddDialogType.AddSubDetail) {
      if (selectedDetail.submitted) {
        handleClose();
        dispatch(resetDetailSubmissionAction());
      }
    } else if (type === DetailsAddDialogType.AddDetail) {
      if (details.submitted) {
        handleClose();
        dispatch(resetDetailSubmissionAction());
      }
    }
  }, [selectedDetail.submitted, details.submitted]);

  const onUpdateDetails = (updatedDetail: ReduxDetail) => {
    setEditingDetail({ ...updatedDetail });
  };

  const handleClose = () => {
    setEditingDetail({ name: '', description: '' });
    onClose();
  };

  const onAddSpecification = (key: string, value: string) => {
    const updatedSpecifications = editingDetail.specifications ? [...editingDetail.specifications] : [];
    updatedSpecifications.push({ key: key, value: value });
    setEditingDetail({ ...editingDetail, specifications: updatedSpecifications });
  };

  const onDeleteSpecification = (key: string) => {
    const updatedSpecifications = editingDetail.specifications?.filter((x) => x.key !== key);
    setEditingDetail({ ...editingDetail, specifications: updatedSpecifications });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!editingDetail) return;
    if (type === DetailsAddDialogType.AddSubDetail) {
      if (!selectedDetail.value || !parentId) return;
      dispatch(
        addSubDetailAction({
          detailId: parentId,
          body: editingDetail,
        }),
      );
    } else {
      selectedContext.propertyId &&
        dispatch(
          addDetailAction({
            associationType: associationType,
            associatedId: associatedId,
            body: editingDetail,
          }),
        );
    }
  };

  return (
    <DialogLayout title={title} open={open} onClose={handleClose}>
      <FullForm onSubmit={onSubmit}>
        <DialogContent>
          <DetailInfoForm
            detail={editingDetail}
            onEditDetail={onUpdateDetails}
            onDeleteSpecification={onDeleteSpecification}
            onAddSpecification={onAddSpecification}
            loading={type === DetailsAddDialogType.AddDetail ? details.submitting : selectedDetail.submitting}
            isSubDetail={type === DetailsAddDialogType.AddSubDetail}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            variant={'contained'}
            type={'submit'}
            loading={type === DetailsAddDialogType.AddDetail ? details.submitting : selectedDetail.submitting}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </FullForm>
    </DialogLayout>
  );
};
