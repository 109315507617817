import React, { FC, FormEvent, useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch } from 'react-redux';
import {
  IUnitDetailOption,
  UnitDetailOptionType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { addUnitDetailOptionAction, useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { DialogLayout } from '../../../../../_shared/dialogs/DialogLayout';

type AddUnitDefaultDialogProps = {
  open: boolean;
  onClose: () => void;
};

const blankUnitDefault: IUnitDetailOption = {
  name: '',
  category: '',
  defaultValue: 'false',
  type: UnitDetailOptionType.Boolean,
};

export const AddUnitDefaultDialog: FC<AddUnitDefaultDialogProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { selectedProperty } = useProperty();
  const [newUnitDefault, setNewUnitDefault] = useState<IUnitDetailOption>(blankUnitDefault);

  useEffect(() => {
    selectedProperty.submitted && handleClose();
  }, [selectedProperty.submitted]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    selectedProperty.value?.id &&
      dispatch(
        addUnitDetailOptionAction({
          id: selectedProperty.value?.id,
          body: {
            unitDetailOption: newUnitDefault,
          },
        }),
      );
  };

  const handleClose = () => {
    setNewUnitDefault(blankUnitDefault);
    onClose();
  };

  const handleUpdateType = (type: UnitDetailOptionType) => {
    if (type === UnitDetailOptionType.Boolean) {
      setNewUnitDefault({ ...newUnitDefault, type: type, defaultValue: 'false' });
    } else setNewUnitDefault({ ...newUnitDefault, type: type, defaultValue: '' });
  };

  const handleUpdateDefaultValue = (checked: boolean) => {
    setNewUnitDefault({ ...newUnitDefault, defaultValue: checked ? 'true' : 'false' });
  };

  return (
    <DialogLayout title={'Add Unit Configuration Default'} open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                fullWidth
                required
                label={'Name'}
                value={newUnitDefault.name}
                onChange={(e) => setNewUnitDefault({ ...newUnitDefault, name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={'Category'}
                value={newUnitDefault.category}
                onChange={(e) => setNewUnitDefault({ ...newUnitDefault, category: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/*Select for Type*/}
              <FormControl fullWidth>
                <InputLabel id="select-type">Type</InputLabel>
                <Select
                  required
                  labelId="select-type"
                  id="type"
                  value={newUnitDefault.type ?? ''}
                  label="Type"
                  onChange={(e) => handleUpdateType(e.target.value as UnitDetailOptionType)}
                >
                  <MenuItem value={UnitDetailOptionType.Boolean}>Yes/No</MenuItem>
                  <MenuItem value={UnitDetailOptionType.Integer}>Number</MenuItem>
                  <MenuItem value={UnitDetailOptionType.SingleLine}>Short Description</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item container xs={12} sm={6} alignContent={'center'}>
              {/*Default Value (options depend on type)*/}
              {newUnitDefault.type === UnitDetailOptionType.Boolean ? (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={newUnitDefault?.defaultValue === 'false' ? false : true}
                        onChange={(e) => handleUpdateDefaultValue(e.target.checked)}
                      />
                    }
                    label="Yes"
                  />
                </FormGroup>
              ) : (
                <TextField
                  required
                  fullWidth
                  label={'Default Value'}
                  type={'number'}
                  value={newUnitDefault?.defaultValue}
                  onChange={(e) =>
                    setNewUnitDefault({
                      ...newUnitDefault,
                      defaultValue: e.target.value,
                    })
                  }
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <LoadingButton variant={'contained'} loading={selectedProperty.submitting} type={'submit'}>
            Add Default
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};
