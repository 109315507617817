import React, { FC, useState } from 'react';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { ImageViewer } from '../../../_shared/documents/components/ImageViewer';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import {
  AssociationType,
  SimpleDocumentType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { drawerChildIsOpenAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useParams } from 'react-router-dom';
import { getDocumentUri } from '../../../_shared/utils/getDocumentUri';
import { Box } from '@mui/material';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';

type PhotoGridProps = {
  photos: ReduxDocument[];
};

type GridItemProps = {
  item: ReduxDocument;
  handleOpen: (index: number) => void;
};

const PrimaryImage = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
`;

const ExtraImage = styled(PrimaryImage)`
  width: 20%;
`;

const PrimaryGridItem = ({ item, handleOpen }: GridItemProps) => {
  switch (item.metadata?.documentType) {
    case SimpleDocumentType.Video:
      const embedUrl = item.uri?.replace('watch?v=', 'embed/') + '?rel=0';
      return (
        <div style={{ aspectRatio: '16 / 9' }}>
          <iframe
            width="100%"
            height="100%"
            src={`${embedUrl}`}
            frameBorder="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      );
    case SimpleDocumentType.Image360:
      return (
        <Box sx={{ position: 'relative' }} onClick={() => handleOpen(0)}>
          <PrimaryImage src={getDocumentUri(item)} alt={`${item.metadata?.title}`} onClick={() => handleOpen(0)} />
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              fontSize: '1.5em',
              opacity: 1,
              transition: 'opacity 0.5s ease',
            }}
          >
            <CameraswitchIcon fontSize={'large'} />
          </Box>
        </Box>
      );
    case SimpleDocumentType.Image:
    default:
      return <PrimaryImage src={getDocumentUri(item)} alt={`${item.metadata?.title}`} onClick={() => handleOpen(0)} />;
  }
};

export const PhotoGrid: FC<PhotoGridProps> = ({ photos }) => {
  const { listingId } = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleOpen = (index: number) => {
    setSelectedIndex(index);
    setOpen(true);
    dispatch(drawerChildIsOpenAction(true));
  };
  const handleClose = () => {
    setSelectedIndex(0);
    setOpen(false);
    dispatch(drawerChildIsOpenAction(false));
  };

  if (photos.length === 0) {
    return <PrimaryImage src={''} alt={`No Image Available`} />;
  }
  return (
    <Stack spacing={1}>
      <PrimaryGridItem item={photos[0]} handleOpen={handleOpen} />
      {photos.length > 1 && (
        <Stack direction={'row'} spacing={1} sx={{ overflow: 'hidden' }}>
          {photos.slice(1).map((photo, index) => (
            <ExtraImage
              key={photo.id}
              src={getDocumentUri(photo)}
              alt={photo.metadata?.title ?? `Image ${index + 1}`}
              onClick={() =>
                handleOpen(
                  photos[0] && photos[0].metadata?.documentType === SimpleDocumentType.Video ? index : index + 1,
                )
              }
            />
          ))}
        </Stack>
      )}
      <Typography variant={'body2'} fontStyle={'italic'}>
        {photos.length} images in gallery
      </Typography>
      <ImageViewer
        documents={photos.filter((x) => x.metadata?.documentType !== SimpleDocumentType.Video)}
        selectedIndex={selectedIndex}
        open={open}
        onClose={handleClose}
        association={{ associatedId: listingId, associationType: AssociationType.Listing }}
      />
    </Stack>
  );
};
