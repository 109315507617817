import React, { ReactNode } from 'react';
import {
  CamTaskStatus,
  GetGroupedTasksHandlerTasksGroupBy,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useDispatch } from 'react-redux';
import { getSelectedTaskAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { ReduxTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';

type TaskNameCellProps = {
  forceColor?: boolean;
  groupingType: GetGroupedTasksHandlerTasksGroupBy | undefined;
  includeClosed: boolean;
} & GridRenderCellParams<ReduxTask, string>;

export const TaskNameCell = ({ groupingType, includeClosed, forceColor, ...props }: TaskNameCellProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch(getSelectedTaskAction({ id: props.row.id!, includeClosed: includeClosed }));
    navigate(`task/${props.row.id}/info`);
  };

  const renderTypography = (children: ReactNode, tooltip = '') => (
    <Tooltip title={tooltip}>
      <Typography variant={'body2'} onClick={handleClick}>
        {children}
      </Typography>
    </Tooltip>
  );
  const getTooltipTitle = () => {
    if (
      (!groupingType && !props.row.taskPriority) ||
      (groupingType === GetGroupedTasksHandlerTasksGroupBy.Asset && !props.row.taskPriority) ||
      (groupingType === GetGroupedTasksHandlerTasksGroupBy.Category && !props.row.categoryPriority) ||
      (groupingType === GetGroupedTasksHandlerTasksGroupBy.Assignee && !props.row.assigneePriority)
    ) {
      return 'unprioritized';
    } else if (!props.row.assignee) {
      return 'unassigned';
    }
    return '';
  };

  if (
    props.row.status &&
    !forceColor &&
    [
      CamTaskStatus.Archived,
      CamTaskStatus.ConfirmedCompleted,
      CamTaskStatus.Completed,
      CamTaskStatus.Abandoned,
    ].includes(props.row.status)
  ) {
    return renderTypography(props.value);
  }

  const tooltipTitle = getTooltipTitle();

  return (
    <Link sx={{ cursor: 'pointer' }} color="inherit">
      {renderTypography(props.value, tooltipTitle)}
    </Link>
  );
};
