import React, { useEffect } from 'react';
import { DrawerLayout, DrawerNavItem } from '../../../global/layout/DrawerLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getListingAction, useListings } from '../../redux/listingSlice';
import { NotFound } from '../../../notFound/NotFound';
import LinearProgress from '@mui/material/LinearProgress';
import { getAssetTypeFromPathname } from '../../../_shared/utils/getAssetTypeFromPathname';
import { ListingTab } from '../../../_shared/tabs/TabTypeEnums';
import Face6Icon from '@mui/icons-material/Face6';
import { useIcons } from '../../../_shared/icons/useIcons';
import WebIcon from '@mui/icons-material/Web';
import { ListingTabs } from './listingTabs/ListingTabs';
import { handleFormatAddress } from '../shared/ListingCard';

export const ListingDrawer = () => {
  const { id, outerTab, innerTab, listingId, tab } = useParams();
  const { getTabIcon } = useIcons();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedListing } = useListings();
  const listing = selectedListing.value;

  const handleDrawerClose = () => {
    navigate(`/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}`);
  };

  useEffect(() => {
    listingId && dispatch(getListingAction({ listingId }));
  }, [listingId]);

  const tabs: DrawerNavItem[] = [
    {
      icon: getTabIcon('info'),
      label: 'Info',
      value: ListingTab.Info,
    },
    {
      icon: getTabIcon('photo'),
      label: 'Photo Gallery',
      value: ListingTab.Photos,
    },
    {
      icon: <Face6Icon />,
      label: 'Agent',
      value: ListingTab.Agent,
    },
    {
      icon: <WebIcon />,
      label: 'Web View',
      value: ListingTab.WebView,
    },
  ];

  return selectedListing.loading ? (
    <LinearProgress />
  ) : listing ? (
    <DrawerLayout
      title={handleFormatAddress(listing.address)}
      subTitle={`${listing.bedrooms} Beds | ${listing.bathrooms} Baths | ${listing.squareFootage} Sqft`}
      open={true}
      onCloseDrawer={handleDrawerClose}
      tabValue={tab}
      tabs={tabs}
      onTabChange={(newValue: string) =>
        navigate(`/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/listing/${listingId}/${newValue}`)
      }
    >
      <ListingTabs tab={tab} />
    </DrawerLayout>
  ) : (
    <NotFound />
  );
};
