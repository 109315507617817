import React, { FC } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { PaymentType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { SelectChangeEvent } from '@mui/material';

export type LeasePaymentTypeSelectorProps = {
  value: PaymentType | undefined;
  onChange: (event: SelectChangeEvent<PaymentType | undefined>) => void;
  supportedPaymentTypes: PaymentType[];
};

export const LeasePaymentTypeSelector: FC<LeasePaymentTypeSelectorProps> = ({
  value,
  onChange,
  supportedPaymentTypes,
}) => {
  return (
    <FormControl>
      <InputLabel id="payment-type-label">Payment Type</InputLabel>
      <Select
        required
        value={value}
        onChange={onChange}
        labelId="payment-type-label"
        id="payment-type"
        label="Payment Type"
      >
        {supportedPaymentTypes.includes(PaymentType.Cash) && <MenuItem value={PaymentType.Cash}>Cash</MenuItem>}
        {supportedPaymentTypes.includes(PaymentType.BankTransfer) && (
          <MenuItem disabled value={PaymentType.BankTransfer}>
            Bank Transfer
          </MenuItem>
        )}
        {supportedPaymentTypes.includes(PaymentType.Check) && <MenuItem value={PaymentType.Check}>Check</MenuItem>}
      </Select>
    </FormControl>
  );
};
