import React, { FC, useEffect } from 'react';
import Autocomplete, { AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete';
import {
  BusinessProviderBusinessType,
  IContactInfo,
  IVendor,
  ListVendorHandlerRequest,
  QueryExpression,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useDispatch } from 'react-redux';
import { AutocompleteChangeReason } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

export type VendorAutocompleteProps = {
  propertyId: string;
  onChange: (value: IVendor | undefined) => void;
  /**
   * The name of the vendor;
   */
  value?: string;
  onAddNewVendor: (name: string) => void;
} & Omit<
  AutocompleteProps<VendorOption | string, false, false, true>,
  'options' | 'value' | 'onChange' | 'getOptionLabel'
>;

interface VendorOption {
  id?: string;
  name?: string | undefined;
  isArchived?: boolean;
  propertyId?: string;
  contactInfo?: IContactInfo;
  contactPerson?: string | undefined;
  type?: BusinessProviderBusinessType;
  inputValue?: string;
}

const filter = createFilterOptions<VendorOption | string>();
export const VendorAutocomplete: FC<VendorAutocompleteProps> = ({
  propertyId,
  onChange,
  value,
  onAddNewVendor,
  ...autocompleteProps
}) => {
  const dispatch = useDispatch();
  const [vendorOptions, setVendorOptions] = React.useState<VendorOption[]>([]);
  const [internalValue, setInternalValue] = React.useState<VendorOption | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const response = await ApiClientSingleton.getInstance().properties_SearchVendors(
          propertyId,
          new ListVendorHandlerRequest({ query: new QueryExpression({}) }),
        );
        if (response) {
          setVendorOptions(response.toJSON().results ?? []);
          if (value) {
            const vendor = response.results?.find((x) => x.name === value);
            if (vendor) {
              setInternalValue(vendor.toJSON());
            }
          } else setInternalValue(undefined);
          setLoading(false);
        }
      } catch {
        setLoading(false);
        dispatch(showToastMessageAction({ message: 'Error loading vendors', severity: 'error' }));
      }
    })();
  }, [propertyId, value]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: VendorOption | string | null,
    reason: AutocompleteChangeReason,
  ) => {
    event.preventDefault();
    if (!newValue || reason !== 'selectOption') {
      setVendorOptions([]);
      onChange(undefined);
      return;
    }
    if (typeof newValue === 'string') {
      setTimeout(() => {
        onAddNewVendor(newValue);
        //this opens the drawer to add a new vendor
      });
    } else if (newValue && newValue.inputValue) {
      onAddNewVendor(newValue.inputValue);
    } else onChange(newValue);
    const foundInternalValue = vendorOptions.find((x) => x.name === value);
    setInternalValue(foundInternalValue);
  };

  return (
    <>
      <Autocomplete
        clearOnBlur
        options={vendorOptions}
        loading={loading}
        onChange={handleChange}
        value={internalValue ?? ''}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Regular option
          return `${option.name}`;
        }}
        {...autocompleteProps}
        renderOption={(props, option) => {
          if (typeof option !== 'string') {
            return (
              <ListItem {...props}>
                <Stack>
                  {option.name && <Typography>{option.name}</Typography>}
                  {option.contactPerson && <Typography>Contact: {option.contactPerson}</Typography>}
                  {option.contactInfo?.phone && (
                    <Typography variant={'subtitle2'}>{option.contactInfo.phone}</Typography>
                  )}
                  {option.contactInfo?.email && (
                    <Typography variant={'subtitle2'}>{option.contactInfo.email}</Typography>
                  )}
                  <Divider
                    sx={{
                      mt: '.2rem',
                    }}
                  />
                </Stack>
              </ListItem>
            );
          } else return <ListItem> {option} </ListItem>;
        }}
        freeSolo
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              name: `Add New Vendor: "${params.inputValue}"`,
              inputValue: params.inputValue,
            });
          }

          return filtered;
        }}
      />
    </>
  );
};
