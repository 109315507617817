import React, { FormEvent, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Gender } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { ReduxPerson, toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { DatePicker } from '@mui/x-date-pickers-pro';
import LoadingButton from '@mui/lab/LoadingButton';
import { useApplications } from '../../../../redux/applicationSlice';
import DialogActions from '@mui/material/DialogActions';

type Props = {
  addChild: (type: string, newChild: ReduxPerson) => void;
  open: boolean;
  onClose: () => void;
};

const blankChild: ReduxPerson = {
  firstName: '',
  lastName: '',
  birthday: undefined,
  gender: Gender.PreferNotToSay,
};

export const AddChildDialog = ({ addChild, open, onClose }: Props) => {
  const { allApplications } = useApplications();
  const [dirty, setDirty] = useState(false);
  const [newChild, setNewChild] = useState<ReduxPerson>(blankChild);

  useEffect(() => {
    if (allApplications.submitted) handleClose();
  }, [allApplications.submitted]);

  const handleUpdateChild = (key: keyof ReduxPerson, value: string) => {
    setNewChild({ ...newChild, [key]: value });
    setDirty(true);
  };

  const handleClose = () => {
    onClose();
    setNewChild(blankChild);
  };

  const handleAddChild = (e: FormEvent) => {
    e.preventDefault();
    setDirty(false);
    addChild('childrenInApartment', newChild);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleAddChild} autoComplete="off">
        <DialogTitle variant="h6">Add Child</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                fullWidth
                required
                margin="dense"
                id="firstName"
                label="First Name"
                type="text"
                variant="standard"
                value={newChild.firstName ? newChild.firstName : ''}
                onChange={(e) => handleUpdateChild('firstName', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                margin="dense"
                id="lastName"
                label="Last Name"
                type="text"
                variant="standard"
                value={newChild.lastName ? newChild.lastName : ''}
                onChange={(e) => handleUpdateChild('lastName', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label={'Birthday'}
                slotProps={{ field: { clearable: true }, textField: { required: true, fullWidth: true } }}
                onChange={(date) => handleUpdateChild('birthday', toReduxDate(date) ?? '')}
                value={newChild.birthday ? toStandardDate(newChild.birthday) : null}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="select-gender">Gender</InputLabel>
                <Select
                  labelId="select-gender"
                  id="gender"
                  value={newChild.gender}
                  label="Gender"
                  onChange={(e) => handleUpdateChild('gender', e.target.value)}
                >
                  <MenuItem value={Gender.PreferNotToSay}>Prefer Not To Say</MenuItem>
                  <MenuItem value={Gender.Other}>Other ('Not Implemented')</MenuItem>
                  <MenuItem value={Gender.Male}>Male</MenuItem>
                  <MenuItem value={Gender.Female}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <LoadingButton type="submit" variant="contained" disabled={!dirty} loading={allApplications.submitting}>
            Add Child
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
