import React, { FC } from 'react';
import { DialogLayout } from '../../../../_shared/dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { AssetTab, FinancialsTab } from '../../../../_shared/tabs/TabTypeEnums';
import { DialogProps } from '@mui/material/Dialog';
import { useNavigate, useParams } from 'react-router-dom';
import { DefaultSelection } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';

type BankAccountNeededDialogProps = {
  onClose: () => void;
} & Omit<DialogProps, 'onClose'>;

export const BankAccountNeededDialog: FC<BankAccountNeededDialogProps> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const property = useProperty();

  const propertyHasAccountsButNotDefaultSelection = property.selectedProperty.value?.rentalBillingInfo?.find(
    (acct) =>
      !acct.bankAccount?.defaultSelection?.includes(DefaultSelection.General) ||
      !acct.bankAccount?.defaultSelection?.includes(DefaultSelection.Deposit),
  );
  const propertyDoesNotHaveCompletedProcessAccount = property.selectedProperty.value?.rentalBillingInfo?.some(
    (acct) => acct,
  );
  const propertyHasNoAccounts = !property.selectedProperty.value?.rentalBillingInfo?.length;

  return (
    <DialogLayout title={'Bank Account Needed'} onClose={onClose} open={open}>
      <DialogContent>
        {propertyHasNoAccounts ? (
          <Typography>
            Before a lease can be created, a bank account needs to be set up to take payments and deposits.
          </Typography>
        ) : propertyDoesNotHaveCompletedProcessAccount ? (
          <Typography>Before a lease can be created, the property bank account process must be completed.</Typography>
        ) : propertyHasAccountsButNotDefaultSelection ? (
          <Typography>
            Before a lease can be created, a default account must be selected for both Payments and Deposits.
          </Typography>
        ) : null}
        {(propertyDoesNotHaveCompletedProcessAccount || propertyHasAccountsButNotDefaultSelection) && (
          <Box mt={1}>
            <Typography variant={'subtitle2'}>
              Please visit the Bank Accounts section of the Financials tab to check the onboarding status and default
              selection of your accounts.
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button>Close</Button>
        <Button
          variant={'contained'}
          onClick={() => navigate(`/assets/property/${id}/${AssetTab.Financials}/${FinancialsTab.Accounts}`)}
        >
          Go To Bank Accounts
        </Button>
      </DialogActions>
    </DialogLayout>
  );
};
