import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

export const AssetTabSkeleton = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} flexGrow={1} mt={'-1rem'} mx={'1rem'}>
      <Skeleton variant={'text'} animation={'wave'} sx={{ fontSize: '3rem' }} />
      <Skeleton variant={'rectangular'} animation={'wave'} width={'100%'} height={'100vh'} />
    </Box>
  );
};
