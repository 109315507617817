import React, { FC, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { ICamAssociation } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Dialog from '@mui/material/Dialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

type PDFProps = {
  document: ReduxDocument;
  association?: ICamAssociation;
  open: boolean;
  onClose: () => void;
};

export const PdfViewer: FC<PDFProps> = ({ document, association, onClose, open }) => {
  const { selectedContext } = useAssets();
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [file, setFile] = useState<Blob>();
  const [loading, setLoading] = useState(true);
  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    (async () => {
      if (selectedContext.propertyId && association?.associationType && association?.associatedId && document.id) {
        setLoading(true);
        const fileResponse = await ApiClientSingleton.getInstance().documents_DownloadFile(
          selectedContext.propertyId,
          association.associationType,
          association.associatedId,
          document.id,
          association.associationChildType,
          association.associationChildNumber,
        );
        setFile(fileResponse.data);
        setLoading(false);
      }
    })();
  }, []);

  function onDocumentLoadSuccess({ numPages: nextNumPages }: { numPages: number }) {
    setNumPages(nextNumPages);
  }

  function onPageLoadSuccess() {
    setPageWidth(window.innerWidth);
    setLoading(false);
  }

  // Go to next page
  const goToNextPage = () => {
    if (numPages > pageNumber) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const goToPreviousPage = () => {
    if (pageNumber > 1) setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'xl'}>
      <Stack p={3}>
        <Stack direction={'row'}>
          <IconButton onClick={goToPreviousPage}>
            <ChevronLeftIcon />
          </IconButton>
          <p>
            Page {pageNumber} of {numPages}
          </p>
          <IconButton onClick={goToNextPage}>
            <ChevronRightIcon />
          </IconButton>
        </Stack>
        {loading ? (
          <LinearProgress />
        ) : (
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              key={pageNumber}
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              onLoadSuccess={onPageLoadSuccess}
              onRenderError={() => setLoading(false)}
              width={Math.max(pageWidth * 0.5, 390)}
            />
          </Document>
        )}
      </Stack>
    </Dialog>
  );
};
