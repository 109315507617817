import React, { FC, useEffect, useState } from 'react';
import {
  AssetType,
  GetGroupedTasksHandlerTasksGroupBy,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import { GroupedTaskTable } from './taskTables/GroupedTaskTable';
import { IncludeClosedToggle } from './IncludeClosedToggle';
import { getAllCategoriesAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/categorySlice';
import { useDispatch } from 'react-redux';
import { toggleIsGroupedAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { getUsersForAssetAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import { useParams } from 'react-router-dom';
import { AssetParams } from '../../../../AppRouter';

export const ToDoList: FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<AssetParams>();
  const assetId = id;
  const {
    selectedContext: { propertyId },
  } = useAssets();
  const [groupingType, setGroupingType] = useState<GetGroupedTasksHandlerTasksGroupBy>(
    GetGroupedTasksHandlerTasksGroupBy.Assignee,
  );
  const [includeClosed, setIncludeClosed] = useState(false);

  useEffect(() => {
    propertyId && dispatch(getAllCategoriesAction({ propertyId, includeDisabled: false }));
    assetId && dispatch(getUsersForAssetAction({ assetId: assetId, associationType: AssetType.RentalProperty }));
  }, [propertyId, assetId]);

  useEffect(() => {
    dispatch(toggleIsGroupedAction({ isGrouped: true, groupedBy: groupingType }));
  }, [groupingType]);

  const handleGroupingTypeChange = (e: SelectChangeEvent) => {
    e.target.value === 'category'
      ? setGroupingType(GetGroupedTasksHandlerTasksGroupBy.Category)
      : setGroupingType(GetGroupedTasksHandlerTasksGroupBy.Assignee);
  };

  const handleToggleIncludeClosed = (checked: boolean) => {
    setIncludeClosed(checked);
  };
  if (!assetId) return <></>;
  return (
    <StyledInfoBox label={'To Do List'}>
      <Stack spacing={2}>
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }}>
          <FormGroup>
            <FormControl>
              <InputLabel id="group-tasks-by">Group By:</InputLabel>
              <Select
                labelId="group-tasks-by-label"
                id="group-tasks-by"
                label="Group By:"
                value={groupingType === GetGroupedTasksHandlerTasksGroupBy.Category ? 'category' : 'assignee'}
                onChange={(e) => handleGroupingTypeChange(e)}
              >
                <MenuItem value={'assignee'}>Assignee</MenuItem>
                <MenuItem value={'category'}>Category</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
          <IncludeClosedToggle
            label={'Show Completed Tasks'}
            includeClosed={includeClosed}
            onToggleIncludeClosed={handleToggleIncludeClosed}
          />
        </Box>
        <GroupedTaskTable
          groupingType={groupingType}
          associationType={AssetType.RentalProperty}
          assetId={assetId}
          includeClosed={includeClosed}
        />
      </Stack>
    </StyledInfoBox>
  );
};
