import React, { FC, useState } from 'react';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { ReduxDetail } from '@monkeyjump-labs/cam-fe-shared/dist/types/detailTypes';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { SpecChip } from './detailsTables/SpecChip';

export type DetailSpecificationContentProps = {
  detail: ReduxDetail;
  onDeleteSpecification: (key: string) => void;
  onAddSpecification: (key: string, value: string) => void;
  isEditingMode: boolean;
};

export const DetailSpecificationsContent: FC<DetailSpecificationContentProps> = ({
  detail,
  onDeleteSpecification,
  onAddSpecification,
  isEditingMode,
}) => {
  const [newSpecification, setNewSpecification] = useState<{ key: string; value: string }>({ key: '', value: '' });

  const addSpecification = () => {
    if (newSpecification.key === '' || newSpecification.value === '' || !detail) return;

    onAddSpecification(newSpecification.key, newSpecification.value);
    setNewSpecification({ key: '', value: '' });
  };
  return (
    <StyledInfoBox label={'Specifications'}>
      {!isEditingMode && (
        <>
          <Typography variant={'subtitle2'} fontStyle={'italic'}>
            *Enter each spec and click + to add
          </Typography>
        </>
      )}
      <Stack spacing={2} direction={'row'} flexWrap={'wrap'}>
        {detail.specifications?.map((specification) => {
          return (
            <SpecChip
              key={specification.key}
              specification={specification}
              deletable={true}
              onDeleteSpecification={() => specification.key && onDeleteSpecification(specification.key)}
            />
          );
        })}
      </Stack>
      <Stack direction={'row'} spacing={'1rem'} justifyContent={'space-between'} alignItems={'center'}>
        <TextField
          fullWidth
          label={'Name'}
          value={newSpecification.key}
          onChange={(e) => {
            setNewSpecification({ ...newSpecification, key: e.target.value });
          }}
        />
        <TextField
          fullWidth
          label={'Value'}
          value={newSpecification.value}
          onChange={(e) => {
            setNewSpecification({ ...newSpecification, value: e.target.value });
          }}
        />
        <Tooltip title={'Add Spec'}>
          <IconButton onClick={addSpecification}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </StyledInfoBox>
  );
};
