import React, { FC } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';

type ShowChildAssetsToggleProps = {
  value: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const ShowChildAssetsToggle: FC<ShowChildAssetsToggleProps> = ({ value, onChange }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch size="small" checked={value} onChange={onChange} />}
        label="Show Child Assets"
      />
    </FormGroup>
  );
};
