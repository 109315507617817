import React, { FC } from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { ReduxMtAccount } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { DefaultSelection } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type AccountOptionsSelect = {
  accounts?: ReduxMtAccount[];
  onSetSelectedAccount: (name?: string) => void;
  value?: ReduxMtAccount;
};

export const AccountOptionsSelect: FC<AccountOptionsSelect> = ({ accounts, onSetSelectedAccount, value }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="select-bank-account">Select Payment Account</InputLabel>
      <Select
        labelId="select-bank-account"
        id="bankAccounts"
        value={value?.name ?? ''}
        label="Select Payment Account"
        onChange={(e) => onSetSelectedAccount(e.target.value)}
      >
        {accounts?.map((account) => {
          return (
            <MenuItem key={account.mtAccountId} value={account.name}>
              {account.defaultSelection === DefaultSelection.General
                ? `${account.name} (Default Account)`
                : account.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
