import React from 'react';
import { useDispatch } from 'react-redux';
import {
  updatePropertyConfigurationAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CreditCardPaymentBehavior } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ConfigurationLayout } from './ConfigurationLayout';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

export const CreditCardPaymentConfiguration = () => {
  const dispatch = useDispatch();
  const { selectedProperty } = useProperty();

  const handleCreditCardPaymentConfigurationChange = (e: SelectChangeEvent<CreditCardPaymentBehavior>) => {
    selectedProperty.value?.id &&
      dispatch(
        updatePropertyConfigurationAction({
          id: selectedProperty.value.id,
          config: {
            ...selectedProperty.value.configuration,
            creditCardPaymentBehavior: e.target.value as CreditCardPaymentBehavior,
          },
        }),
      );
  };

  return (
    <ConfigurationLayout title={'Credit Card Payments'}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: '1rem' }}>
        <Typography id={'credit-card-payment'}>Allow Credit Card Payments</Typography>
        <Select
          labelId={'credit-card-payment'}
          value={selectedProperty.value?.configuration?.creditCardPaymentBehavior ?? CreditCardPaymentBehavior.Disabled}
          onChange={(e) => handleCreditCardPaymentConfigurationChange(e)}
          sx={{ minWidth: '60%' }}
        >
          <MenuItem value={CreditCardPaymentBehavior.Enabled}>Credit Card Payments Enabled</MenuItem>
          <MenuItem value={CreditCardPaymentBehavior.Disabled}>Credit Card Payments Disabled</MenuItem>
        </Select>
      </Box>
    </ConfigurationLayout>
  );
};
