import React, { FC, useEffect, useState } from 'react';
import { Drawer, DrawerProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { BottomNavLayout } from './BottomNavLayout';
import { drawerChildIsOpenAction, useGlobal } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useDispatch } from 'react-redux';

export type DrawerNavItem = {
  icon: JSX.Element;
  label: string;
  value: string;
  hidden?: boolean;
};

type DrawerLayoutProps = {
  title: string;
  subTitle?: string;
  open: boolean;
  onCloseDrawer: () => void;
  children: JSX.Element;
  tabValue?: string;
  onTabChange?: (newValue: string) => void;
  tabs?: DrawerNavItem[];
  breadcrumbs?: DrawerBreadcrumb[];
  error?: boolean;
} & DrawerProps;

export type DrawerBreadcrumb = {
  id: string;
  label: string;
  onClick: (() => void) | undefined;
};

export const DrawerLayout: FC<DrawerLayoutProps> = ({
  title,
  subTitle,
  open,
  onCloseDrawer,
  children,
  tabValue,
  onTabChange,
  tabs,
  breadcrumbs,
  error,
  ...props
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const { drawerChildIsOpen } = useGlobal();

  const handleClose = () => {
    onCloseDrawer();
    setExpanded(false);
  };

  useEffect(() => {
    //drawer isn't closing on escape without event listener
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (!drawerChildIsOpen) handleClose();
        else dispatch(drawerChildIsOpenAction(false));
      }
    };
    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [drawerChildIsOpen]);

  return (
    <Drawer
      PaperProps={{
        style: {
          width: expanded ? '80%' : '40%',
          display: open ? 'block' : 'none',
        },
      }}
      variant="permanent"
      anchor="right"
      open={open}
      onClose={handleClose}
      {...props}
    >
      <Box display="flex" flexDirection="column" height="100vh" width={'100%'}>
        <Paper
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: error ? theme.palette.error.main : theme.palette.primary.light,
            minHeight: '4rem',
            alignItems: 'center',
            position: 'sticky',
            top: 0,
            borderRadius: 0,
            zIndex: 9999,
          }}
          elevation={2}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', py: '.25rem' }}>
            <IconButton onClick={() => setExpanded(!expanded)}>
              {expanded ? (
                <KeyboardDoubleArrowRightIcon sx={{ color: error ? 'white' : 'inherit' }} />
              ) : (
                <KeyboardDoubleArrowLeftIcon sx={{ color: error ? 'white' : 'inherit' }} />
              )}
            </IconButton>
            <Stack>
              <Typography variant={'h4'} color={error ? 'white' : 'inherit'}>
                {title}
              </Typography>
              {subTitle && (
                <Typography color={error ? 'white' : 'inherit'} variant={'subtitle2'}>
                  {subTitle}
                </Typography>
              )}
              {breadcrumbs && (
                <Stack direction={'row'}>
                  {breadcrumbs.map((p) => (
                    <>
                      {p !== breadcrumbs[0] ? (
                        <KeyboardDoubleArrowRightIcon sx={{ mx: 0.5, color: error ? 'white' : 'inherit' }} />
                      ) : (
                        ''
                      )}
                      {p.onClick ? (
                        <Link
                          key={p.id}
                          sx={{ cursor: 'pointer', color: error ? 'white' : 'inherit' }}
                          onClick={p.onClick}
                        >
                          {p.label}
                        </Link>
                      ) : (
                        <Typography color={error ? 'white' : 'inherit'} key={p.id}>
                          {p.label}
                        </Typography>
                      )}
                    </>
                  ))}
                </Stack>
              )}
            </Stack>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: error ? 'white' : 'inherit' }} />
          </IconButton>
        </Paper>
        <Box sx={{ display: 'flex', flexGrow: 1, width: '100%' }}>{children}</Box>
        {onTabChange && tabs && tabValue ? (
          <BottomNavLayout tabValue={tabValue} onTabChange={onTabChange} tabs={tabs} />
        ) : (
          <></>
        )}
      </Box>
    </Drawer>
  );
};
