import React, { useEffect, useState } from 'react';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import { IPet } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { PetsGrid } from './PetsGrid';
import { ReduxApplication } from '../../../../redux/applicationTypes';

type Props = {
  applicant: ReduxApplication;
  onRemovePet: (updatedDependents: ReduxApplication) => void;
  onOpen: () => void;
};

export const Pets = ({ applicant, onRemovePet, onOpen }: Props) => {
  const [applicantPets, setApplicantPets] = useState(applicant.pets ?? []);

  useEffect(() => {
    setApplicantPets(applicant?.pets ?? []);
  }, [applicant?.pets]);

  const removePet = (updatedPets: IPet[]) => {
    setApplicantPets(updatedPets);
    onRemovePet({ pets: updatedPets });
  };

  return (
    <StyledInfoBox label={'Pets'}>
      <Stack>
        <Box sx={{ mb: '.5rem' }}>
          <Button variant="outlined" onClick={onOpen}>
            Add Pet
          </Button>
        </Box>
        <Box sx={{ height: 200, width: '100%' }}>
          <PetsGrid pets={applicantPets} onRemovePet={removePet} />
        </Box>
      </Stack>
    </StyledInfoBox>
  );
};
