import { PayloadAction } from '@reduxjs/toolkit';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import {
  AddUnitTemplateHandlerRequest,
  AddUnitTemplateHandlerResponse,
  IUnitInfo,
  ListUnitTemplatesHandlerRequest,
  ListUnitTemplatesHandlerResponse,
  UpdateUnitTemplateHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { all, put, race, take, takeLatest } from 'redux-saga/effects';
import {
  addUnitTemplateAction,
  addUnitTemplateSuccessAction,
  deleteUnitTemplateAction,
  deleteUnitTemplateFailAction,
  deleteUnitTemplateSuccessAction,
  listAvailableUnitTemplatesAction,
  listAvailableUnitTemplatesFailAction,
  listAvailableUnitTemplatesSuccessAction,
  listUnitTemplatesAction,
  listUnitTemplatesFailAction,
  listUnitTemplatesSuccessAction,
  resetUnitTemplateSubmissionAction,
  updateUnitTemplateAction,
  updateUnitTemplateFailAction,
  updateUnitTemplateSuccessAction,
} from './unitTemplateSlice';
import {
  cancelConfirmDialogAction,
  okConfirmDialogAction,
  showConfirmDialogAction,
  showErrorAction,
  showToastMessageAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';

function* listUnitTemplates(
  action: PayloadAction<{ propertyId: string; buildingId?: string; page: number; pageSize: number }>,
) {
  try {
    const parameters = action.payload.buildingId ? [{ field: 'buildingId', value: action.payload.buildingId }] : [];

    const response: ListUnitTemplatesHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().templates_ListTemplates,
      action.payload.propertyId,
      new ListUnitTemplatesHandlerRequest({
        query: {
          page: action.payload.page,
          pageSize: action.payload.pageSize,
          parameters: parameters,
        },
      }),
    );

    yield put(listUnitTemplatesSuccessAction(response.toJSON()));
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to load unit templates' }));
    yield put(listUnitTemplatesFailAction());
  }
}

function* listAvailableUnitTemplates(action: PayloadAction<{ buildingId: string }>) {
  try {
    const response: ListUnitTemplatesHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().templates_ListAvailableTemplates,
      action.payload.buildingId,
      true,
    );

    yield put(listAvailableUnitTemplatesSuccessAction(response.toJSON()));
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to load available unit templates' }));
    yield put(listAvailableUnitTemplatesFailAction());
  }
}

function* updateUnitTemplate(action: PayloadAction<{ id: string; name?: string; info?: IUnitInfo }>) {
  try {
    const body = new UpdateUnitTemplateHandlerRequest({ name: action.payload.name, info: action.payload.info });
    yield apiCall(ApiClientSingleton.getInstance().templates_UpdateTemplate, action.payload.id, body);

    yield put(showToastMessageAction({ message: 'Unit template updated' }));
    yield put(updateUnitTemplateSuccessAction(action.payload));
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to update unit template' }));
    yield put(updateUnitTemplateFailAction());
  }
}

function* deleteUnitTemplate(action: PayloadAction<{ id: string }>) {
  try {
    yield put(
      showConfirmDialogAction({
        title: 'Are you sure?',
        message: 'This will delete the unit template.',
        okText: 'Yes',
        cancelText: 'No',
      }),
    );
    const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
    if (yes) {
      yield apiCall(ApiClientSingleton.getInstance().templates_DeleteTemplate, action.payload.id);

      yield put(showToastMessageAction({ message: 'Unit template deleted' }));
      yield put(deleteUnitTemplateSuccessAction(action.payload));
    } else {
      yield put(resetUnitTemplateSubmissionAction());
    }
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to delete unit template' }));
    yield put(deleteUnitTemplateFailAction());
  }
}

function* addUnitTemplate(
  action: PayloadAction<{ propertyId: string; buildingId?: string; name: string; info?: IUnitInfo }>,
) {
  try {
    const body = new AddUnitTemplateHandlerRequest({
      buildingId: action.payload.buildingId,
      name: action.payload.name,
      info: action.payload.info,
    });
    const newTemplate: AddUnitTemplateHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().templates_AddTemplate,
      action.payload.propertyId,
      body,
    );
    yield put(showToastMessageAction({ message: 'Unit template added' }));
    yield put(
      addUnitTemplateSuccessAction({
        id: newTemplate.toJSON().id,
        name: action.payload.name,
        info: action.payload.info,
      }),
    );
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to add unit template' }));
    yield put(resetUnitTemplateSubmissionAction());
  }
}

export function* unitTemplateSaga() {
  yield all([
    takeLatest(listUnitTemplatesAction.type, listUnitTemplates),
    takeLatest(listAvailableUnitTemplatesAction.type, listAvailableUnitTemplates),
    takeLatest(updateUnitTemplateAction.type, updateUnitTemplate),
    takeLatest(deleteUnitTemplateAction.type, deleteUnitTemplate),
    takeLatest(addUnitTemplateAction.type, addUnitTemplate),
  ]);
}
