import React, { FC } from 'react';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';

type SplitButtonProps = {
  mainActionTitle: string;
  secondaryActionTitle: string;
  onMainAction: () => void;
  onSecondaryAction: () => void;
  tertiaryActionTitle?: string;
  onTertiaryAction?: () => void;
};

export const SplitButton: FC<SplitButtonProps> = ({
  onMainAction,
  mainActionTitle,
  secondaryActionTitle,
  onSecondaryAction,
  tertiaryActionTitle,
  onTertiaryAction,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };
  return (
    <Box>
      <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button">
        <Button variant={'outlined'} onClick={onMainAction} startIcon={<Add />}>
          {mainActionTitle}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 10,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem key={secondaryActionTitle} onClick={onSecondaryAction}>
                    {secondaryActionTitle}
                  </MenuItem>
                  {tertiaryActionTitle && onTertiaryAction ? (
                    <MenuItem key={tertiaryActionTitle} onClick={onTertiaryAction}>
                      {tertiaryActionTitle}
                    </MenuItem>
                  ) : (
                    <MenuItem key={mainActionTitle} onClick={onMainAction}>
                      {mainActionTitle}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
