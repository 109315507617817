import React, { FC, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemText from '@mui/material/ListItemText';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';

type NewChecklistProps = {
  checklist: string[] | undefined;
  onAddItem: (value: string) => void;
  onDeleteItem: (index: number) => void;
};

export const NewChecklist: FC<NewChecklistProps> = ({ checklist, onDeleteItem, onAddItem }) => {
  const [newItem, setNewItem] = useState<string>('');

  const handleAddItem = () => {
    onAddItem(newItem);
    setNewItem('');
  };

  return (
    <>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {checklist?.map((value, index) => {
          const labelId = `checkbox-list-label-${value}`;
          return (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton edge="end" aria-label="edit" onClick={() => onDeleteItem(index)}>
                  {/*TODO: Loading button?*/}
                  <DeleteIcon />
                </IconButton>
              }
              disablePadding
            >
              {/*TODO: Loading button?*/}
              <ListItemText id={labelId} primary={value} primaryTypographyProps={{ style: { whiteSpace: 'normal' } }} />
            </ListItem>
          );
        })}
        <ListItem
          secondaryAction={
            <IconButton edge="end" aria-label="edit" onClick={handleAddItem}>
              {/*TODO: Loading button?*/}
              <AddCircleIcon />
            </IconButton>
          }
        >
          <TextField
            value={newItem}
            multiline
            label={'Add Item to Checklist'}
            fullWidth
            onChange={(e) => setNewItem(e.target.value)}
          />
        </ListItem>
      </List>
    </>
  );
};
