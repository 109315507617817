import React, { FC, FormEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { EditScheduledTaskTabs } from './EditScheduledTaskTabs';
import { useScheduledTasks } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/scheduledTaskSlice';
import { ReduxScheduledTask, ReduxTimeConfiguration } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { IBaseTask, ITaskCategory } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { UserOption } from '../AssigneeAutocomplete';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { useTimeConfigurationChecks } from '../useTimeConfigurationChecks';
import { FullForm } from '../../../styledComponents/FullForm';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { resetSubmissionAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/scheduledTaskSlice';

type EditScheduledTaskProps = {
  onClose: () => void;
  onUpdateScheduledTask: (task: ReduxScheduledTask, taskId: string) => void;
  currentTab: string;
};

export const EditScheduledTask: FC<EditScheduledTaskProps> = ({ onClose, onUpdateScheduledTask, currentTab }) => {
  const dispatch = useDispatch();
  const { selectedScheduledTask } = useScheduledTasks();
  const usersForAsset = useUser((u) => u.usersForAsset);
  const [editingScheduledTask, setEditingScheduledTask] = useState<ReduxScheduledTask | undefined>(
    selectedScheduledTask.value,
  );
  const [dirty, setDirty] = useState(false);
  const { checkTimeConfiguration } = useTimeConfigurationChecks();
  const handleClose = () => {
    setDirty(false);
    onClose();
  };

  useEffect(() => {
    if (selectedScheduledTask.submitted) {
      setDirty(false);
      dispatch(resetSubmissionAction());
    }
  }, [selectedScheduledTask.submitted]);

  useEffect(() => {
    setEditingScheduledTask(selectedScheduledTask.value);
  }, [selectedScheduledTask.value]);

  const handleUpdateCategory = (value: ITaskCategory | null) => {
    setEditingScheduledTask({
      ...editingScheduledTask,
      task: { ...editingScheduledTask?.task, category: value ?? undefined },
    });
    setDirty(true);
  };

  const handleUpdateAssignee = (value: UserOption | null | undefined) => {
    if (value === null || value === undefined) {
      setEditingScheduledTask({
        ...editingScheduledTask,
        task: { ...editingScheduledTask?.task, assignee: undefined },
      });
    }
    value &&
      usersForAsset.value?.map((user) => {
        user.id === value?.id &&
          setEditingScheduledTask({
            ...editingScheduledTask,
            task: { ...editingScheduledTask?.task, assignee: { ...user } },
          });
      });

    setDirty(true);
  };

  const updateScheduledTask = () => {
    if (!selectedScheduledTask.value?.id || !editingScheduledTask) return;
    onUpdateScheduledTask(editingScheduledTask, selectedScheduledTask.value.id);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!editingScheduledTask?.timeConfiguration) return;
    checkTimeConfiguration(editingScheduledTask?.timeConfiguration, updateScheduledTask);
    setDirty(false);
  };

  const handleChangeDetails = (field: keyof IBaseTask, value: string) => {
    setEditingScheduledTask({ ...editingScheduledTask, task: { ...editingScheduledTask?.task, [field]: value } });
    setDirty(true);
  };

  const handleChangeTimeConfig = (config: ReduxTimeConfiguration) => {
    setEditingScheduledTask({ ...editingScheduledTask, timeConfiguration: config });
    setDirty(true);
  };

  return (
    <FullForm onSubmit={handleSubmit}>
      {editingScheduledTask?.task && editingScheduledTask?.timeConfiguration && (
        <EditScheduledTaskTabs
          currentTab={currentTab}
          scheduledTask={editingScheduledTask}
          onChangeTaskDetails={handleChangeDetails}
          onChangeTimeConfiguration={handleChangeTimeConfig}
          onAssigneeSelection={handleUpdateAssignee}
          onCategorySelection={handleUpdateCategory}
          onChangeScheduledDays={(days: number) => {
            setDirty(true);
            setEditingScheduledTask({ ...editingScheduledTask, daysPriorToStartDateToConvert: days });
          }}
          onChangeLeaseAssociation={() => {
            setDirty(true);
            setEditingScheduledTask({
              ...editingScheduledTask,
              associateWithCurrentLease: !editingScheduledTask?.associateWithCurrentLease,
            });
          }}
        />
      )}
      {currentTab !== 'documents' && (
        <Box sx={{ display: 'flex', justifyContent: 'right', p: 1 }}>
          <Button onClick={handleClose} sx={{ mr: 1 }}>
            Close
          </Button>
          <LoadingButton
            type={'submit'}
            variant={'contained'}
            disabled={!dirty}
            loading={selectedScheduledTask.submitting}
          >
            Save Changes
          </LoadingButton>
        </Box>
      )}
    </FullForm>
  );
};
