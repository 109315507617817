import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { ReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

type TimeTravelDisplayProps = {
  propertyDate: ReduxDate;
};

export const TimeTravelDateDisplay = ({ propertyDate }: TimeTravelDisplayProps) => {
  const formattedDate = format(toStandardDate(propertyDate), 'ccc PP');

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography fontWeight={'bold'} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
        {formattedDate}
      </Typography>
    </Box>
  );
};
