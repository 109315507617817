import React, { FC } from 'react';
import Paper from '@mui/material/Paper';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { DrawerNavItem } from './DrawerLayout';

type BottomNavLayoutProps = {
  tabValue: string;
  onTabChange: (newValue: string) => void;
  tabs: DrawerNavItem[];
};

export const BottomNavLayout: FC<BottomNavLayoutProps> = ({ tabs, tabValue, onTabChange }) => {
  return (
    <Paper sx={{ position: 'sticky', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        value={tabValue}
        onChange={(event, newValue) => {
          onTabChange(newValue);
        }}
      >
        {tabs.map((tab) => {
          if (!tab.hidden)
            return (
              <Tooltip title={tab.label} key={tab.value}>
                <BottomNavigationAction label={tab.label} value={tab.value} icon={tab.icon} />
              </Tooltip>
            );
        })}
      </BottomNavigation>
    </Paper>
  );
};
