import React, { FC } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

type Props = {
  label?: string;
  value: string;
  onChange: (state: string) => void;
  required?: boolean;
};
export const USStatesSelect: FC<Props> = ({ value, onChange, label, required }) => {
  const states = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ];

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="state-label">{label ?? 'State'}</InputLabel>
      <Select
        labelId="state-label"
        id="state-select"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label={label ?? 'State'}
        required={required ?? false}
      >
        {states.map((state, index) => (
          <MenuItem key={index} value={state}>
            {state}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
