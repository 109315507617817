import React, { FC } from 'react';
import { DragDropContext, DragUpdate, Droppable, DropResult } from '@hello-pangea/dnd';
import Stack from '@mui/material/Stack';

type DroppableComponentProps = {
  children: React.ReactNode;
  droppableId: string;
  onDragEnd: (sourceIndex: number, destinationIndex: number, id: string) => void;
  onDragUpdate: (destinationIndex: number, id: string) => void;
};

export const DroppableComponent: FC<DroppableComponentProps> = ({ children, droppableId, onDragEnd, onDragUpdate }) => {
  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
      return; // No operation needed if dropped outside or in the same place
    }
    onDragEnd(source.index, destination.index, result.draggableId);
  };

  const handleDragUpdate = (update: DragUpdate) => {
    const { draggableId, destination } = update;
    if (!destination) return;
    onDragUpdate(destination.index, draggableId);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={handleDragUpdate}>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <Stack
            sx={{ width: '100%', backgroundColor: 'background.paper' }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {children}
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
};
