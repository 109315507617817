import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import {
  IReportColumn,
  ReportColumnType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { currencyFormatter } from '../../../../../_shared/utils/currencyFormatter';
import { tryFormatDate } from '../../../../../_shared/utils/TryFormatDate';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import {
  clearAdditionalReportDataAction,
  getAdditionalReportDataAction,
  useReports,
} from '../../../../redux/reportSlice';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { useAssociations } from '../../../../../_shared/datagrids/useAssociations';

type ReportDataCellProps = GridRenderCellParams & {
  column: IReportColumn;
  idColumnName: string | undefined;
  valueForGroupingColumn: string | undefined;
  columFetchData: any;
  reportFetchData: any;
};

export const ReportDataCell: FC<ReportDataCellProps> = ({
  column,
  idColumnName,
  valueForGroupingColumn,
  columFetchData,
  reportFetchData,
  ...params
}) => {
  const dispatch = useDispatch();
  const { handleListItemClick } = useAssociations();
  const { selectedReport } = useReports();
  const { selectedContext } = useAssets();

  const cell = params.row[column.name as string];
  const cellAssociation = cell ? cell.association : null;
  const cellFetchData = cell ? cell.fetchData : null;

  const formattedValue = () => {
    let value = params.value ?? null;
    if (params.value && params.value.value) {
      //grouping cell situation
      value = params.value.value;
    }
    if (idColumnName && column.name?.endsWith('Id')) {
      value = valueForGroupingColumn;
    }
    if (column.type === ReportColumnType.Currency) {
      return value !== undefined && value !== null ? currencyFormatter.format(value) : '';
    } else if (column.type === ReportColumnType.Date) {
      return value ? tryFormatDate(value) : '';
    } else {
      return value ?? '';
    }
  };

  const value = formattedValue();

  const combineFetchData = (columnFetchData: any, reportFetchData: any, additionalInfoFetchData: any): object => {
    const combinedData: { [key: string]: any } = {};

    // Helper function to check if a value is null or undefined
    const isNotNull = (value?: any) => value !== null && value !== undefined;

    for (const key in columnFetchData) {
      if (isNotNull(columnFetchData[key])) {
        combinedData[key] = columnFetchData[key];
      }
    }

    for (const key in reportFetchData) {
      if (isNotNull(reportFetchData[key])) {
        combinedData[key] = reportFetchData[key];
      }
    }

    for (const key in additionalInfoFetchData) {
      if (isNotNull(additionalInfoFetchData[key])) {
        combinedData[key] = additionalInfoFetchData[key];
      }
    }

    return combinedData;
  };

  const handleClick = () => {
    if (cellFetchData) {
      const data = combineFetchData(columFetchData, reportFetchData, cellFetchData);
      selectedContext.propertyId &&
        selectedReport.value?.name &&
        dispatch(
          getAdditionalReportDataAction({
            propertyId: selectedContext.propertyId,
            data: data ?? {},
          }),
        );
    }
    if (cellAssociation) {
      handleListItemClick(cell.association, false);
      dispatch(clearAdditionalReportDataAction());
    }
  };

  if (cellFetchData || cellAssociation)
    return (
      <Link sx={{ cursor: 'pointer' }} color="inherit">
        <Typography variant="body2" onClick={handleClick}>
          {value}
        </Typography>
      </Link>
    );
  return <>{value}</>;
};
