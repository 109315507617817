import React, { FC, useEffect, useState } from 'react';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Handlebars from 'handlebars';
import { previewData } from '../redux/emailTemplateData';
import { ReduxEmailTemplate } from '@monkeyjump-labs/cam-fe-shared/dist/types/emailTemplateTypes';

type EmailTemplatePreviewProps = {
  emailTemplate: ReduxEmailTemplate;
};

export const EmailTemplatePreview: FC<EmailTemplatePreviewProps> = ({ emailTemplate }) => {
  const [bodyPreview, setBodyPreview] = useState('');
  const [subjectPreview, setSubjectPreview] = useState('');

  useEffect(() => {
    if (!isValidTemplate(emailTemplate.body ?? '')) return;

    const templateCompiler = Handlebars.compile(emailTemplate.body ?? '');
    const compiledTemplate = templateCompiler(previewData);

    // Replace new lines with <br> tags to preserve formatting in the preview
    const formattedBody = compiledTemplate.replace(/\n/g, '<br>');
    setBodyPreview(formattedBody);
  }, [emailTemplate.body, previewData]);

  useEffect(() => {
    if (!isValidTemplate(emailTemplate.subject ?? '')) return;
    const templateCompiler = Handlebars.compile(emailTemplate.subject ?? '');
    setSubjectPreview(templateCompiler(previewData));
  }, [emailTemplate.subject]);

  const isValidTemplate = (template: string) => {
    //check for even number of open and closed braces
    const openBraces = (template.match(/{{/g) || []).length;
    const closeBraces = (template.match(/}}/g) || []).length;

    // Check for invalid characters between handlebars
    const validChars = /{{(?:[^{}]*?)[^a-zA-Z{}]+(?:[^{}]*?)}}/;
    const hasInvalidChars = validChars.test(template);

    //check for empty braces
    const hasEmptyBraces = /{{\s*}}/.test(template);

    return openBraces === closeBraces && !hasEmptyBraces && !hasInvalidChars;
  };

  return (
    <StyledInfoBox label={'Preview'}>
      <Box display={'flex'} flexDirection={'row'} paddingBottom={2}>
        <Box width={'5rem'}>
          <Typography fontStyle={'italic'} style={{ marginRight: 10 }}>
            Subject:
          </Typography>
        </Box>
        <Box maxWidth={'85%'}>
          <Typography>{subjectPreview}</Typography>
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'row'} flexGrow={1}>
        <Box width={'5rem'}>
          <Typography fontStyle={'italic'} style={{ marginRight: 10 }}>
            Body:
          </Typography>
        </Box>
        <Box maxWidth={'85%'}>
          <Typography dangerouslySetInnerHTML={{ __html: bodyPreview }} />
        </Box>
      </Box>
    </StyledInfoBox>
  );
};
