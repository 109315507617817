import React from 'react';
import { GridRowModel } from '@mui/x-data-grid-premium';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sendEmailAction, useApplications } from '../../../redux/applicationSlice';
import { ReduxApplication } from '../../../redux/applicationTypes';
import LoadingButton from '@mui/lab/LoadingButton';
import { DialogLayout } from '../../../../_shared/dialogs/DialogLayout';

type Props = {
  open: boolean;
  onClose: () => void;
  applicant: GridRowModel<ReduxApplication>;
};

export const SendApplication = ({ open, onClose, applicant }: Props) => {
  const navigate = useNavigate();
  const applications = useApplications();
  const dispatch = useDispatch();
  if (applicant) {
    return (
      <DialogLayout
        title={`Send Application for: ${applicant.firstName + ' ' + applicant.lastName}`}
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={onClose}
      >
        <DialogContent>
          <DialogContentText>Content TBD, include checkbox for 'Send To BackGround Check'</DialogContentText>
          <DialogActions sx={{ marginTop: '1rem' }}>
            <Button onClick={onClose}>Close</Button>
            <LoadingButton
              variant="contained"
              loading={applications.emailSubmitting.submitting}
              onClick={() =>
                dispatch(
                  sendEmailAction({
                    rentalPropertyId: applicant.rentalPropertyId!,
                    applicationId: applicant.id!,
                  }),
                )
              }
            >
              Email Application
            </LoadingButton>
            <Button variant="contained" onClick={() => navigate(`/apply/${applicant.id}`)}>
              Online Application
            </Button>
          </DialogActions>
        </DialogContent>
      </DialogLayout>
    );
  } else return <></>;
};
