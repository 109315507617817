import {
  Filter,
  initInteractable,
  initLoadable,
  initPageableCollection,
  Interactable,
  Loadable,
  PageableCollection,
  SortDirection,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import {
  GroupedJobLists,
  ReduxExpense,
  ReduxExpenseEstimate,
  ReduxExpensePayment,
  ReduxInvoice,
  ReduxPurchaseOrder,
  ReduxQuote,
  ReduxWorkOrder,
} from './expenseData';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import {
  AssetType,
  AssociationType,
  ExpenseInvoiceStatus,
  ExpensePaymentExpensePaymentStatus,
  ExpensePurchaseOrderStatus,
  ExpenseQuoteStatus,
  IAddExpenseEstimateHandlerRequest,
  IAddExpenseHandlerRequest,
  IAddExpensePaymentHandlerRequest,
  IAddExpenseWorkOrderHandlerRequest,
  ICamAssociation,
  IExpensePurchaseOrderItem,
  IGroupedExpenseItem,
  IUpdateExpenseStatusHandlerRequest,
  IVendorResponse,
  WorkOrderStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export type ExpenseState = {
  expenses: PageableCollection<ReduxExpense>;
  assetGroups: Loadable<IGroupedExpenseItem[]>;
  groupedExpenseLists: GroupedJobLists;
  selectedExpense: Interactable<ReduxExpense>;
  selectedPayment: Interactable<ReduxExpensePayment>;
  selectedWorkOrder: Interactable<ReduxWorkOrder>;
  selectedPurchaseOrder: Interactable<ReduxPurchaseOrder>;
  selectedQuote: Interactable<ReduxQuote>;
  selectedInvoice: Interactable<ReduxInvoice>;
  groupingInfo: {
    isGrouped: boolean;
    expandedRowIds: string[];
  };
};

const initialState: ExpenseState = {
  expenses: initPageableCollection<ReduxExpense>(),
  assetGroups: initLoadable<IGroupedExpenseItem[]>(),
  groupedExpenseLists: {},
  selectedExpense: initInteractable<ReduxExpense>(),
  selectedPayment: initInteractable<ReduxExpensePayment>(),
  selectedInvoice: initInteractable<ReduxInvoice>(),
  selectedQuote: initInteractable<ReduxQuote>(),
  selectedPurchaseOrder: initInteractable<ReduxPurchaseOrder>(),
  selectedWorkOrder: initInteractable<ReduxWorkOrder>(),
  groupingInfo: {
    isGrouped: false,
    expandedRowIds: [],
  },
};
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const expenseSlice = createSlice({
  name: 'expenses',
  initialState: initialState,
  reducers: {
    getExpensesAction: (
      state,
      action: PayloadAction<{
        propertyId: string;
        assetId: string;
        assetType: AssetType;
        includeClosed: boolean;
        page: number;
        pageSize: number;
        sortDirection: SortDirection | undefined;
        sortBy: keyof ReduxExpense | undefined;
        filters: Filter<ReduxExpense>[] | undefined;
      }>,
    ) => {
      state.expenses.loading = true;
      state.expenses.loaded = false;
    },
    getExpensesSuccessAction: (state, action: PayloadAction<{ results: ReduxExpense[]; totalCount: number }>) => {
      state.expenses.loading = false;
      state.expenses.loaded = true;
      state.expenses.value = action.payload.results;
      state.expenses.totalCount = action.payload.totalCount;
    },
    getExpensesFailAction: (state) => {
      state.expenses.loading = false;
      state.expenses.loaded = false;
    },
    getExpenseGroupsAction: (
      state,
      action: PayloadAction<{ assetType: AssetType; assetId: string; includeClosed: boolean }>,
    ) => {
      state.assetGroups.loading = true;
      state.assetGroups.loaded = false;
    },
    getExpenseGroupsSuccessAction: (state, action: PayloadAction<IGroupedExpenseItem[]>) => {
      state.assetGroups.loaded = true;
      state.assetGroups.loading = false;
      state.assetGroups.value = action.payload;
    },
    getExpenseGroupsFailAction: (state) => {
      state.assetGroups.loading = false;
      state.assetGroups.loaded = false;
    },
    getExpenseGroupByAssetIdAction: (
      state,
      action: PayloadAction<{
        associationId: string;
        associationType: AssetType;
        includeClosed: boolean;
        page: number;
        pageSize: number;
      }>,
    ) => {
      if (!state.groupedExpenseLists[action.payload.associationId]) {
        state.groupedExpenseLists[action.payload.associationId] = initPageableCollection<ReduxExpense>();
      }
      state.groupedExpenseLists[action.payload.associationId].loading = true;
      state.groupedExpenseLists[action.payload.associationId].loaded = false;
      state.groupedExpenseLists[action.payload.associationId].page = action.payload.page;
      state.groupedExpenseLists[action.payload.associationId].pageSize = action.payload.pageSize;
      state.groupedExpenseLists[action.payload.associationId].associationType = action.payload.associationType;
    },
    getExpenseGroupByAssetIdSuccessAction: (
      state,
      action: PayloadAction<{ id: string; body: { results: ReduxExpense[]; totalCount: number } }>,
    ) => {
      if (!state.groupedExpenseLists[action.payload.id]) {
        state.groupedExpenseLists[action.payload.id] = initPageableCollection<ReduxExpense>();
      }
      state.groupedExpenseLists[action.payload.id].loading = false;
      state.groupedExpenseLists[action.payload.id].loaded = true;
      state.groupedExpenseLists = {
        ...state.groupedExpenseLists,
        [action.payload.id]: {
          ...state.groupedExpenseLists[action.payload.id],
          value: action.payload.body.results,
          totalCount: action.payload.body.totalCount,
        },
      };
    },
    getExpenseGroupByAssetIdFailAction: (state, action: PayloadAction<{ id: string }>) => {
      if (!state.groupedExpenseLists[action.payload.id]) {
        state.groupedExpenseLists[action.payload.id] = initPageableCollection<ReduxExpense>();
      }
      state.groupedExpenseLists[action.payload.id].loading = false;
      state.groupedExpenseLists[action.payload.id].loaded = false;
      state.groupedExpenseLists[action.payload.id].value = undefined;
    },
    toggleIsGroupedAction: (state, action: PayloadAction<boolean>) => {
      state.groupingInfo.isGrouped = action.payload;
    },
    setExpandedRowIdsAction: (state, action: PayloadAction<string[]>) => {
      state.groupingInfo.expandedRowIds = action.payload;
    },
    unsetExpandedRowIdsAction: (state) => {
      state.groupingInfo.expandedRowIds = [];
    },
    unsetGroupedExpenseListAction: (state, action: PayloadAction<string>) => {
      delete state.groupedExpenseLists[action.payload];
    },
    createExpenseAction: (
      state,
      action: PayloadAction<{
        propertyId: string;
        body: IAddExpenseHandlerRequest;
      }>,
    ) => {
      state.expenses.submitting = true;
      state.expenses.submitted = false;
    },
    createExpenseSuccessAction: (state) => {
      state.expenses.submitting = false;
      state.expenses.submitted = true;
    },
    createExpenseFailAction: (state) => {
      state.expenses.submitting = false;
      state.expenses.submitted = false;
    },
    updateExpenseAction: (state, action: PayloadAction<{ id: string; body: ReduxExpense }>) => {
      state.selectedExpense.submitting = true;
      state.selectedExpense.submitted = false;
    },
    updateExpenseSuccessAction: (state) => {
      state.selectedExpense.submitted = true;
      state.selectedExpense.submitting = false;
    },
    updateExpenseFailAction: (state) => {
      state.selectedExpense.submitting = false;
      state.selectedExpense.submitted = false;
    },
    updateExpenseStatusAction: (
      state,
      action: PayloadAction<{ id: string; body: IUpdateExpenseStatusHandlerRequest }>,
    ) => {
      state.selectedExpense.submitting = true;
      state.selectedExpense.submitted = false;
    },
    updateExpenseStatusSuccessAction: (state) => {
      state.selectedExpense.submitted = true;
      state.selectedExpense.submitting = false;
    },
    updateExpenseStatusFailAction: (state) => {
      state.selectedExpense.submitting = false;
      state.selectedExpense.submitted = false;
    },
    resetExpenseSubmissionAction: (state) => {
      state.selectedExpense.submitted = false;
      state.selectedPayment.submitted = false;
      state.selectedWorkOrder.submitted = false;
      state.selectedInvoice.submitted = false;
      state.selectedPurchaseOrder.submitted = false;
      state.selectedQuote.submitted = false;
    },
    getSelectedExpenseByIdAction: (state, action: PayloadAction<string>) => {
      state.selectedExpense.loading = true;
      state.selectedExpense.loaded = false;
    },
    getSelectedExpenseByIdFailAction: (state) => {
      state.selectedExpense.loading = false;
      state.selectedExpense.loaded = false;
    },
    setSelectedExpenseAction: (state, action: PayloadAction<ReduxExpense | undefined>) => {
      state.selectedExpense.value = action.payload;
      state.selectedExpense.loaded = true;
      state.selectedExpense.loading = false;
      if (state.selectedPayment.value !== undefined) {
        state.selectedPayment.value = action.payload?.payments?.find(
          (x) => x.number === state.selectedPayment.value?.number,
        );
      } else if (state.selectedInvoice.value !== undefined) {
        state.selectedInvoice.value = action.payload?.invoices?.find((x) => x.id === state.selectedInvoice.value?.id);
      } else if (state.selectedQuote.value !== undefined) {
        state.selectedQuote.value = action.payload?.quotes?.find((x) => x.id === state.selectedQuote.value?.id);
      } else if (state.selectedWorkOrder.value !== undefined) {
        state.selectedWorkOrder.value = action.payload?.workOrders?.find(
          (x) => x.id === state.selectedWorkOrder.value?.id,
        );
      } else if (state.selectedPurchaseOrder.value !== undefined) {
        state.selectedPurchaseOrder.value = action.payload?.purchaseOrders?.find(
          (x) => x.id === state.selectedPurchaseOrder.value?.id,
        );
      }
    },
    setSelectedPaymentAction: (state, action: PayloadAction<ReduxExpensePayment | undefined>) => {
      state.selectedPayment.value = action.payload;
    },
    setSelectedQuoteAction: (state, action: PayloadAction<ReduxQuote | undefined>) => {
      state.selectedQuote.value = action.payload;
    },
    setSelectedWorkOrderAction: (state, action: PayloadAction<ReduxWorkOrder | undefined>) => {
      state.selectedWorkOrder.value = action.payload;
    },
    setSelectedPurchaseOrderAction: (state, action: PayloadAction<ReduxPurchaseOrder | undefined>) => {
      state.selectedPurchaseOrder.value = action.payload;
    },
    setSelectedInvoiceAction: (state, action: PayloadAction<ReduxInvoice | undefined>) => {
      state.selectedInvoice.value = action.payload;
    },
    unsetSelectedJobElementsAction: (state) => {
      state.selectedInvoice.value = undefined;
      state.selectedPurchaseOrder.value = undefined;
      state.selectedPayment.value = undefined;
      state.selectedWorkOrder.value = undefined;
      state.selectedQuote.value = undefined;
    },
    setPaymentSubmittingValueAction: (state, action: PayloadAction<ReduxExpensePayment | undefined>) => {
      state.selectedPayment.submittingValue = action.payload;
    },
    setInvoiceSubmittingValueAction: (state, action: PayloadAction<ReduxInvoice | undefined>) => {
      state.selectedInvoice.submittingValue = action.payload;
    },
    setQuoteSubmittingValueAction: (state, action: PayloadAction<ReduxQuote | undefined>) => {
      state.selectedQuote.submittingValue = action.payload;
    },
    setWorkOrderSubmittingValueAction: (state, action: PayloadAction<ReduxWorkOrder | undefined>) => {
      state.selectedWorkOrder.submittingValue = action.payload;
    },
    setPurchaseOrderSubmittingValueAction: (state, action: PayloadAction<ReduxPurchaseOrder | undefined>) => {
      state.selectedPurchaseOrder.submittingValue = action.payload;
    },
    setVendorOnSubmittingValueAction: (state, action: PayloadAction<IVendorResponse>) => {
      if (state.selectedPayment.submittingValue !== undefined) {
        state.selectedPayment.submittingValue = {
          ...state.selectedPayment.submittingValue,
          vendor: action.payload,
        };
      }
      if (state.selectedQuote.submittingValue !== undefined) {
        state.selectedQuote.submittingValue = { ...state.selectedQuote.submittingValue, vendor: action.payload };
      }
      if (state.selectedInvoice.submittingValue !== undefined) {
        state.selectedInvoice.submittingValue = {
          ...state.selectedInvoice.submittingValue,
          vendor: action.payload,
        };
      }
      if (state.selectedPurchaseOrder.submittingValue !== undefined) {
        state.selectedPurchaseOrder.submittingValue = {
          ...state.selectedPurchaseOrder.submittingValue,
          vendor: action.payload,
        };
      }
    },
    addPaymentAction: (
      state,
      action: PayloadAction<{
        jobId: string;
        propertyId: string;
        buildingId?: string;
        body: Omit<IAddExpensePaymentHandlerRequest, 'datePaid'> & { datePaid?: string };
      }>,
    ) => {
      state.selectedPayment.submitting = true;
      state.selectedPayment.submitted = false;
    },
    addPaymentSuccessAction: (state) => {
      state.selectedPayment.submitting = false;
      state.selectedPayment.submitted = true;
    },
    addPaymentFailAction: (state) => {
      state.selectedPayment.submitting = false;
      state.selectedPayment.submitted = false;
    },
    updatePaymentAction: (state, action: PayloadAction<{ jobId: string; payment: ReduxExpensePayment }>) => {
      state.selectedPayment.submitting = true;
      state.selectedPayment.submitted = false;
    },
    updatePaymentSuccessAction: (state) => {
      state.selectedPayment.submitting = false;
      state.selectedPayment.submitted = true;
    },
    updatePaymentFailAction: (state) => {
      state.selectedPayment.submitting = false;
      state.selectedPayment.submitted = false;
    },
    markPaymentVoidAction: (state, action: PayloadAction<{ jobId: string; paymentNumber: string }>) => {
      state.selectedPayment.submitting = true;
      state.selectedPayment.submitted = false;
    },
    unVoidPaymentAction: (state, action: PayloadAction<{ jobId: string; paymentNumber: string }>) => {
      state.selectedPayment.submitting = true;
      state.selectedPayment.submitted = false;
    },
    markPaymentVoidSuccessAction: (state, action: PayloadAction<{ paymentNumber: string }>) => {
      state.selectedPayment.submitting = false;
      state.selectedPayment.submitted = true;
    },
    markPaymentVoidFailAction: (state) => {
      state.selectedPayment.submitting = false;
      state.selectedPayment.submitted = false;
    },
    addDocumentToPaymentAction: (
      state,
      action: PayloadAction<{ jobId: string; paymentNumber: string; file: File }>,
    ) => {
      state.selectedPayment.submitted = false;
      state.selectedPayment.submitting = true;
    },
    addDocumentToPaymentSuccessAction: (state) => {
      state.selectedPayment.submitted = true;
      state.selectedPayment.submitting = false;
    },
    addDocumentToPaymentFailAction: (state) => {
      state.selectedPayment.submitted = false;
      state.selectedPayment.submitting = false;
    },
    addCommentToPaymentAction: (
      state,
      action: PayloadAction<{ jobId: string; paymentNumber: string; comment: string }>,
    ) => {
      state.selectedPayment.submitted = false;
      state.selectedPayment.submitting = true;
    },
    addCommentToPaymentSuccessAction: (state) => {
      state.selectedPayment.submitted = true;
      state.selectedPayment.submitting = false;
    },
    addCommentToPaymentFailAction: (state) => {
      state.selectedPayment.submitted = false;
      state.selectedPayment.submitting = false;
    },
    addInvoiceAction: (
      state,
      action: PayloadAction<{ jobId: string; propertyId: string; buildingId?: string; body: ReduxInvoice }>,
    ) => {
      state.selectedInvoice.submitted = false;
      state.selectedInvoice.submitting = true;
    },
    addInvoiceSuccessAction: (state) => {
      state.selectedInvoice.submitted = true;
      state.selectedInvoice.submitting = false;
    },
    addInvoiceFailAction: (state) => {
      state.selectedInvoice.submitting = false;
      state.selectedInvoice.submitted = false;
    },
    updateInvoiceAction: (state, action: PayloadAction<{ jobId: string; invoice: ReduxInvoice }>) => {
      state.selectedInvoice.submitted = false;
      state.selectedInvoice.submitting = true;
    },
    updateInvoiceSuccessAction: (state) => {
      state.selectedInvoice.submitted = true;
      state.selectedInvoice.submitting = false;
    },
    updateInvoiceFailAction: (state) => {
      state.selectedInvoice.submitting = false;
      state.selectedInvoice.submitted = false;
    },
    voidInvoiceAction: (state, action: PayloadAction<{ jobId: string; invoiceNumber: string }>) => {
      state.selectedInvoice.submitted = false;
      state.selectedInvoice.submitting = true;
    },
    unVoidInvoiceAction: (state, action: PayloadAction<{ jobId: string; invoiceNumber: string }>) => {
      state.selectedInvoice.submitted = false;
      state.selectedInvoice.submitting = true;
    },
    invoiceUpdateSuccessAction: (state) => {
      state.selectedInvoice.submitted = true;
      state.selectedInvoice.submitting = false;
    },
    removeInvoiceFailAction: (state) => {
      state.selectedInvoice.submitted = false;
      state.selectedInvoice.submitting = false;
    },
    addDocToInvoiceAction: (state, action: PayloadAction<{ jobId: string; invoiceNumber: string; file: File }>) => {
      state.selectedInvoice.submitted = false;
      state.selectedInvoice.submitting = true;
    },
    addDocToInvoiceSuccessAction: (state) => {
      state.selectedInvoice.submitted = true;
      state.selectedInvoice.submitting = false;
    },
    addDocToInvoiceFailAction: (state) => {
      state.selectedInvoice.submitted = false;
      state.selectedInvoice.submitting = false;
    },
    addCommentToInvoiceAction: (
      state,
      action: PayloadAction<{ jobId: string; invoiceNumber: string; comment: string }>,
    ) => {
      state.selectedInvoice.submitted = false;
      state.selectedInvoice.submitting = true;
    },
    addCommentToInvoiceSuccessAction: (state) => {
      state.selectedInvoice.submitted = true;
      state.selectedInvoice.submitting = false;
    },
    addCommentToInvoiceFailAction: (state) => {
      state.selectedInvoice.submitted = false;
      state.selectedInvoice.submitting = false;
    },
    addPurchaseOrderAction: (
      state,
      action: PayloadAction<{ jobId: string; propertyId: string; body: ReduxPurchaseOrder }>,
    ) => {
      state.selectedPurchaseOrder.submitting = true;
      state.selectedPurchaseOrder.submitted = false;
    },
    addPurchaseOrderSuccessAction: (state) => {
      state.selectedPurchaseOrder.submitting = false;
      state.selectedPurchaseOrder.submitted = true;
    },
    addPurchaseOrderFailAction: (state) => {
      state.selectedPurchaseOrder.submitting = false;
      state.selectedPurchaseOrder.submitted = false;
    },
    updatePurchaseOrderAction: (
      state,
      action: PayloadAction<{
        jobId: string;
        purchaseOrder: ReduxPurchaseOrder;
      }>,
    ) => {
      state.selectedPurchaseOrder.submitting = true;
      state.selectedPurchaseOrder.submitted = false;
    },
    updatePurchaseOrderSuccessAction: (state) => {
      state.selectedPurchaseOrder.submitting = false;
      state.selectedPurchaseOrder.submitted = true;
    },
    updatePurchaseOrderFailAction: (state) => {
      state.selectedPurchaseOrder.submitting = false;
      state.selectedPurchaseOrder.submitted = false;
    },
    removePurchaseOrderAction: (state, action: PayloadAction<{ jobId: string; poNumber: string }>) => {
      state.selectedPurchaseOrder.submitting = true;
      state.selectedPurchaseOrder.submitted = false;
    },
    removePurchaseOrderSuccessAction: (state) => {
      state.selectedPurchaseOrder.submitting = false;
      state.selectedPurchaseOrder.submitted = true;
    },
    removePurchaseOrderFailAction: (state) => {
      state.selectedPurchaseOrder.submitting = false;
      state.selectedPurchaseOrder.submitted = false;
    },
    addDocToPurchaseOrderAction: (
      state,
      action: PayloadAction<{
        jobId: string;
        poNumber: string;
        file: File;
      }>,
    ) => {
      state.selectedPurchaseOrder.submitting = true;
      state.selectedPurchaseOrder.submitted = false;
    },
    addDocToPurchaseOrderSuccessAction: (state) => {
      state.selectedPurchaseOrder.submitting = false;
      state.selectedPurchaseOrder.submitted = true;
    },
    addDocToPurchaseOrderFailAction: (state) => {
      state.selectedPurchaseOrder.submitting = false;
      state.selectedPurchaseOrder.submitted = false;
    },
    addCommentToPurchaseOrderAction: (
      state,
      action: PayloadAction<{ jobId: string; poNumber: string; comment: string }>,
    ) => {
      state.selectedPurchaseOrder.submitted = false;
      state.selectedPurchaseOrder.submitting = true;
    },
    addCommentToPurchaseOrderSuccessAction: (state) => {
      state.selectedPurchaseOrder.submitted = true;
      state.selectedPurchaseOrder.submitting = false;
    },
    addCommentToPurchaseOrderFailAction: (state) => {
      state.selectedPurchaseOrder.submitted = false;
      state.selectedPurchaseOrder.submitting = false;
    },
    addAdjustmentToPurchaseOrderAction: (
      state,
      action: PayloadAction<{
        expenseId: string;
        poNumber: string;
        note?: string;
        amount?: number;
        isItemized: boolean;
        items?: IExpensePurchaseOrderItem[] | undefined;
      }>,
    ) => {
      state.selectedPurchaseOrder.submitted = false;
      state.selectedPurchaseOrder.submitting = true;
    },
    addAdjustmentToPurchaseOrderSuccessAction: (state) => {
      state.selectedPurchaseOrder.submitted = true;
      state.selectedPurchaseOrder.submitting = false;
    },
    addAdjustmentToPurchaseOrderFailAction: (state) => {
      state.selectedPurchaseOrder.submitted = false;
      state.selectedPurchaseOrder.submitting = false;
    },
    addWorkOrderAction: (
      state,
      action: PayloadAction<{ jobId: string; propertyId: string; body: IAddExpenseWorkOrderHandlerRequest }>,
    ) => {
      state.selectedWorkOrder.submitting = true;
      state.selectedWorkOrder.submitted = false;
    },
    addWorkOrderSuccessAction: (state) => {
      state.selectedWorkOrder.submitting = false;
      state.selectedWorkOrder.submitted = true;
    },
    addWorkOrderFailAction: (state) => {
      state.selectedWorkOrder.submitting = false;
      state.selectedWorkOrder.submitted = false;
    },
    updateWorkOrderAction: (state, action: PayloadAction<{ jobId: string; workOrder: ReduxWorkOrder }>) => {
      state.selectedWorkOrder.submitting = true;
      state.selectedWorkOrder.submitted = false;
    },
    updateWorkOrderSuccessAction: (state) => {
      state.selectedWorkOrder.submitting = false;
      state.selectedWorkOrder.submitted = true;
    },
    updateWorkOrderFailAction: (state) => {
      state.selectedWorkOrder.submitting = false;
      state.selectedWorkOrder.submitted = false;
    },
    removeWorkOrderAction: (state, action: PayloadAction<{ jobId: string; woNumber: string }>) => {
      state.selectedWorkOrder.submitting = true;
      state.selectedWorkOrder.submitted = false;
    },
    removeWorkOrderSuccessAction: (state) => {
      state.selectedWorkOrder.submitting = false;
      state.selectedWorkOrder.submitted = true;
    },
    removeWorkOrderFailAction: (state) => {
      state.selectedWorkOrder.submitting = false;
      state.selectedWorkOrder.submitted = false;
    },
    addDocToWorkOrderAction: (state, action: PayloadAction<{ jobId: string; woNumber: string; file: File }>) => {
      state.selectedWorkOrder.submitting = true;
      state.selectedWorkOrder.submitted = false;
    },
    addDocToWorkOrderSuccessAction: (state) => {
      state.selectedWorkOrder.submitting = false;
      state.selectedWorkOrder.submitted = true;
    },
    addDocToWorkOrderFailAction: (state) => {
      state.selectedWorkOrder.submitting = false;
      state.selectedWorkOrder.submitted = false;
    },
    addCommentToWorkOrderAction: (
      state,
      action: PayloadAction<{ jobId: string; woNumber: string; comment: string }>,
    ) => {
      state.selectedWorkOrder.submitted = false;
      state.selectedWorkOrder.submitting = true;
    },
    addCommentToWorkOrderSuccessAction: (state) => {
      state.selectedWorkOrder.submitted = true;
      state.selectedWorkOrder.submitting = false;
    },
    addCommentToWorkOrderFailAction: (state) => {
      state.selectedWorkOrder.submitted = false;
      state.selectedWorkOrder.submitting = false;
    },
    //ONE MORE//
    addQuoteAction: (state, action: PayloadAction<{ jobId: string; propertyId: string; body: ReduxQuote }>) => {
      state.selectedQuote.submitting = true;
      state.selectedQuote.submitted = false;
    },
    addQuoteSuccessAction: (state) => {
      state.selectedQuote.submitting = false;
      state.selectedQuote.submitted = true;
    },
    addQuoteFailAction: (state) => {
      state.selectedQuote.submitting = false;
      state.selectedQuote.submitted = false;
    },
    updateQuoteAction: (state, action: PayloadAction<{ jobId: string; quote: ReduxQuote }>) => {
      state.selectedQuote.submitting = true;
      state.selectedQuote.submitted = false;
    },
    updateQuoteSuccessAction: (state) => {
      state.selectedQuote.submitting = false;
      state.selectedQuote.submitted = true;
    },
    updateQuoteFailAction: (state) => {
      state.selectedQuote.submitting = false;
      state.selectedQuote.submitted = false;
    },
    removeQuoteAction: (state, action: PayloadAction<{ jobId: string; quoteNumber: string }>) => {
      state.selectedQuote.submitting = true;
      state.selectedQuote.submitted = false;
    },
    removeQuoteSuccessAction: (state) => {
      state.selectedQuote.submitting = false;
      state.selectedQuote.submitted = true;
    },
    removeQuoteFailAction: (state) => {
      state.selectedQuote.submitting = false;
      state.selectedQuote.submitted = false;
    },
    addDocToQuoteAction: (state, action: PayloadAction<{ jobId: string; quoteNumber: string; file: File }>) => {
      state.selectedQuote.submitting = true;
      state.selectedQuote.submitted = false;
    },
    addDocToQuoteSuccessAction: (state) => {
      state.selectedQuote.submitting = false;
      state.selectedQuote.submitted = true;
    },
    addDocToQuoteFailAction: (state) => {
      state.selectedQuote.submitting = false;
      state.selectedQuote.submitted = false;
    },
    addCommentToQuoteAction: (
      state,
      action: PayloadAction<{ jobId: string; quoteNumber: string; comment: string }>,
    ) => {
      state.selectedQuote.submitted = false;
      state.selectedQuote.submitting = true;
    },
    addCommentToQuoteSuccessAction: (state) => {
      state.selectedQuote.submitted = true;
      state.selectedQuote.submitting = false;
    },
    addCommentToQuoteFailAction: (state) => {
      state.selectedQuote.submitted = false;
      state.selectedQuote.submitting = false;
    },
    addAttachmentToSelectedExpenseAction: (state) => {
      state.selectedQuote.submitted = false;
      state.selectedQuote.submitting = true;
    },
    addAttachmentToSelectedExpenseSuccessAction: (state) => {
      state.selectedQuote.submitted = true;
      state.selectedQuote.submitting = false;
    },
    addAttachmentToSelectedExpenseFailAction: (state) => {
      state.selectedQuote.submitted = false;
      state.selectedQuote.submitting = false;
    },
    addEstimateToSelectedExpenseAction: (
      state,
      action: PayloadAction<{ propertyId: string; expenseId: string; body: IAddExpenseEstimateHandlerRequest }>,
    ) => {
      state.selectedExpense.submitted = false;
      state.selectedExpense.submitting = true;
    },
    addEstimateToSelectedExpenseSuccessAction: (state) => {
      state.selectedExpense.submitted = true;
      state.selectedExpense.submitting = false;
    },
    addEstimateToSelectedExpenseFailAction: (state) => {
      state.selectedExpense.submitted = false;
      state.selectedExpense.submitting = false;
    },
    deleteEstimateFromExpenseAction: (
      state,
      action: PayloadAction<{
        expenseId: string;
        estimateNumber: string;
      }>,
    ) => {
      state.selectedExpense.submitted = false;
      state.selectedExpense.submitting = true;
    },
    deleteEstimateFromExpenseSuccessAction: (state) => {
      state.selectedExpense.submitted = true;
      state.selectedExpense.submitting = false;
    },
    deleteEstimateFromExpenseFailAction: (state) => {
      state.selectedExpense.submitted = false;
      state.selectedExpense.submitting = false;
    },
    updateEstimateAction: (state, action: PayloadAction<{ expenseId: string; body: ReduxExpenseEstimate }>) => {
      state.selectedExpense.submitted = false;
      state.selectedExpense.submitting = true;
    },
    updateEstimateSuccessAction: (state) => {
      state.selectedExpense.submitted = true;
      state.selectedExpense.submitting = false;
    },
    updateEstimateFailAction: (state) => {
      state.selectedExpense.submitted = false;
      state.selectedExpense.submitting = false;
    },
    updateExpensePaymentStatusAction: (
      state,
      action: PayloadAction<{ expenseId: string; number: string; status: ExpensePaymentExpensePaymentStatus }>,
    ) => {
      state.selectedPayment.submitting = true;
      state.selectedPayment.submitted = false;
    },
    updateExpensePaymentStatusSuccessAction: (state) => {
      state.selectedPayment.submitting = false;
      state.selectedPayment.submitted = true;
    },
    updateExpensePaymentStatusFailAction: (state) => {
      state.selectedPayment.submitting = false;
      state.selectedPayment.submitted = false;
    },
    updateInvoiceStatusAction: (
      state,
      action: PayloadAction<{ expenseId: string; number: string; status: ExpenseInvoiceStatus }>,
    ) => {
      state.selectedInvoice.submitting = true;
      state.selectedInvoice.submitted = false;
    },
    updateInvoiceStatusSuccessAction: (state) => {
      state.selectedInvoice.submitting = false;
      state.selectedInvoice.submitted = true;
    },
    updateInvoiceStatusFailAction: (state) => {
      state.selectedInvoice.submitting = false;
      state.selectedInvoice.submitted = false;
    },
    updatePurchaseOrderStatusAction: (
      state,
      action: PayloadAction<{ expenseId: string; number: string; status: ExpensePurchaseOrderStatus }>,
    ) => {
      state.selectedPurchaseOrder.submitting = true;
      state.selectedPurchaseOrder.submitted = false;
    },
    updatePurchaseOrderStatusSuccessAction: (state) => {
      state.selectedPurchaseOrder.submitting = false;
      state.selectedPurchaseOrder.submitted = true;
    },
    updatePurchaseOrderStatusFailAction: (state) => {
      state.selectedPurchaseOrder.submitting = false;
      state.selectedPurchaseOrder.submitted = false;
    },
    updateQuoteStatusAction: (
      state,
      action: PayloadAction<{ expenseId: string; number: string; status: ExpenseQuoteStatus }>,
    ) => {
      state.selectedQuote.submitting = true;
      state.selectedQuote.submitted = false;
    },
    updateQuoteStatusSuccessAction: (state) => {
      state.selectedQuote.submitting = false;
      state.selectedQuote.submitted = true;
    },
    updateQuoteStatusFailAction: (state) => {
      state.selectedQuote.submitting = false;
      state.selectedQuote.submitted = false;
    },
    updateWorkOrderStatusAction: (
      state,
      action: PayloadAction<{ expenseId: string; number: string; status: WorkOrderStatus }>,
    ) => {
      state.selectedWorkOrder.submitting = true;
      state.selectedWorkOrder.submitted = false;
    },
    updateWorkOrderStatusSuccessAction: (state) => {
      state.selectedWorkOrder.submitting = false;
      state.selectedWorkOrder.submitted = true;
    },
    updateWorkOrderStatusFailAction: (state) => {
      state.selectedWorkOrder.submitting = false;
      state.selectedWorkOrder.submitted = false;
    },
    updateChangeOrderAction: (
      state,
      action: PayloadAction<{
        expenseId: string;
        poNumber: string;
        changeOrderId: string;
        amount?: number;
        items?: IExpensePurchaseOrderItem[];
        note?: string;
      }>,
    ) => {
      state.selectedWorkOrder.submitting = true;
      state.selectedWorkOrder.submitted = false;
    },
    updateChangeOrderSuccessAction: (state) => {
      state.selectedWorkOrder.submitting = false;
      state.selectedWorkOrder.submitted = true;
    },
    updateChangeOrderFailAction: (state) => {
      state.selectedWorkOrder.submitting = false;
      state.selectedWorkOrder.submitted = false;
    },
    addAssociationToPurchaseOrderLineItemAction: (
      state,
      action: PayloadAction<{
        expenseId: string;
        purchaseOrderNumber: string;
        lineItemNumber: string;
        changeOrderId?: string;
        associatedId: string;
        associationType: AssociationType;
      }>,
    ) => {
      state.selectedPurchaseOrder.submitted = false;
      state.selectedPurchaseOrder.submitting = true;
    },
    addAssociationToPurchaseOrderLineItemSuccessAction: (state) => {
      state.selectedPurchaseOrder.submitted = true;
      state.selectedPurchaseOrder.submitting = false;
    },
    addAssociationToPurchaseOrderLineItemFailAction: (state) => {
      state.selectedPurchaseOrder.submitted = false;
      state.selectedPurchaseOrder.submitting = false;
    },
    removeAssociationFromPurchaseOrderLineItemAction: (
      state,
      action: PayloadAction<{
        expenseId: string;
        purchaseOrderNumber: string;
        changeOrderId?: string;
        lineItemNumber: string;
        association: ICamAssociation;
      }>,
    ) => {
      state.selectedPurchaseOrder.submitted = false;
      state.selectedPurchaseOrder.submitting = true;
    },
    removeAssociationFromPurchaseOrderLineItemSuccessAction: (state) => {
      state.selectedPurchaseOrder.submitted = true;
      state.selectedPurchaseOrder.submitting = false;
    },
    removeAssociationFromPurchaseOrderLineItemFailAction: (state) => {
      state.selectedPurchaseOrder.submitted = false;
      state.selectedPurchaseOrder.submitting = false;
    },
    newExpenseArrivedAction: (state, action: PayloadAction<ReduxExpense>) => {
      //if the expense list contains the expense, update it
      if (state.expenses.value?.find((x) => x.id === action.payload.id)) {
        state.expenses.value = state.expenses.value?.map((x) => (x.id === action.payload.id ? action.payload : x));
      }
      //if any of the groupedExpenseLists contains the expense, update it
      Object.keys(state.groupedExpenseLists).forEach((key) => {
        const group = state.groupedExpenseLists[key];
        group.value?.forEach((expense, index) => {
          if (expense.id === action.payload.id && group.value && group.value[index]) {
            group.value[index] = action.payload;
          }
        });
      });
      //if the selectedExpense or any selected children matches, update it
      if (state.selectedExpense.value?.id === action.payload.id) {
        state.selectedExpense.value = action.payload;
      }
      const matchingPayment = action.payload.payments?.find((x) => x.number === state.selectedPayment.value?.number);
      if (matchingPayment) {
        state.selectedPayment.value = matchingPayment;
      }
      const matchingInvoice = action.payload.invoices?.find((x) => x.id === state.selectedInvoice.value?.id);
      if (matchingInvoice) {
        state.selectedInvoice.value = matchingInvoice;
      }
      const matchingPurchaseOrder = action.payload.purchaseOrders?.find(
        (x) => x.id === state.selectedPurchaseOrder.value?.id,
      );
      if (matchingPurchaseOrder) {
        state.selectedPurchaseOrder.value = matchingPurchaseOrder;
      }
      const matchingWorkOrder = action.payload.workOrders?.find((x) => x.id === state.selectedWorkOrder.value?.id);
      if (matchingWorkOrder) {
        state.selectedWorkOrder.value = matchingWorkOrder;
      }
      const matchingQuote = action.payload.quotes?.find((x) => x.id === state.selectedQuote.value?.id);
      if (matchingQuote) {
        state.selectedQuote.value = matchingQuote;
      }
    },
    addWatcherToExpenseAction: (state, action: PayloadAction<{ expenseId: string; userId: string }>) => {
      state.selectedExpense.submitting = true;
      state.selectedExpense.submitted = false;
    },
    removeWatcherFromExpenseAction: (state, action: PayloadAction<{ expenseId: string; userId: string }>) => {
      state.selectedExpense.submitting = true;
      state.selectedExpense.submitted = false;
    },
    watcherExpenseSuccessAction: (state) => {
      state.selectedExpense.submitting = false;
      state.selectedExpense.submitted = true;
    },
    watcherExpenseFailAction: (state) => {
      state.selectedExpense.submitting = false;
      state.selectedExpense.submitted = false;
    },
  },
});

export const {
  getExpensesAction,
  getExpensesSuccessAction,
  getExpensesFailAction,
  createExpenseAction,
  getExpenseGroupByAssetIdFailAction,
  getExpenseGroupByAssetIdSuccessAction,
  getExpenseGroupsSuccessAction,
  getExpenseGroupsFailAction,
  getExpenseGroupByAssetIdAction,
  getExpenseGroupsAction,
  toggleIsGroupedAction,
  setExpandedRowIdsAction,
  unsetGroupedExpenseListAction,
  unsetExpandedRowIdsAction,
  createExpenseFailAction,
  createExpenseSuccessAction,
  updateExpenseStatusFailAction,
  updateExpenseStatusSuccessAction,
  updateExpenseAction,
  updateExpenseSuccessAction,
  updateExpenseFailAction,
  updateExpenseStatusAction,
  addPaymentSuccessAction,
  addPaymentAction,
  addPaymentFailAction,
  updatePaymentSuccessAction,
  updatePaymentFailAction,
  updatePaymentAction,
  markPaymentVoidFailAction,
  markPaymentVoidSuccessAction,
  markPaymentVoidAction,
  unVoidPaymentAction,
  resetExpenseSubmissionAction,
  getSelectedExpenseByIdAction,
  getSelectedExpenseByIdFailAction,
  setSelectedInvoiceAction,
  setSelectedPurchaseOrderAction,
  setSelectedPaymentAction,
  setSelectedWorkOrderAction,
  setSelectedQuoteAction,
  setSelectedExpenseAction,
  addDocumentToPaymentSuccessAction,
  addDocumentToPaymentFailAction,
  addDocumentToPaymentAction,
  unsetSelectedJobElementsAction,
  setPaymentSubmittingValueAction,
  setVendorOnSubmittingValueAction,
  addCommentToQuoteFailAction,
  addCommentToQuoteSuccessAction,
  addCommentToWorkOrderFailAction,
  addCommentToInvoiceAction,
  addCommentToInvoiceFailAction,
  addCommentToInvoiceSuccessAction,
  addCommentToPaymentAction,
  addCommentToPaymentFailAction,
  addCommentToPaymentSuccessAction,
  addCommentToPurchaseOrderAction,
  addCommentToPurchaseOrderSuccessAction,
  addCommentToQuoteAction,
  addCommentToWorkOrderAction,
  addCommentToWorkOrderSuccessAction,
  addDocToInvoiceFailAction,
  addCommentToPurchaseOrderFailAction,
  addDocToInvoiceAction,
  addDocToInvoiceSuccessAction,
  addDocToPurchaseOrderAction,
  addDocToPurchaseOrderFailAction,
  addDocToPurchaseOrderSuccessAction,
  addDocToQuoteAction,
  addDocToQuoteFailAction,
  addDocToQuoteSuccessAction,
  addDocToWorkOrderAction,
  addDocToWorkOrderFailAction,
  addDocToWorkOrderSuccessAction,
  addInvoiceAction,
  addInvoiceFailAction,
  addInvoiceSuccessAction,
  addQuoteAction,
  addPurchaseOrderSuccessAction,
  addQuoteFailAction,
  addQuoteSuccessAction,
  addPurchaseOrderFailAction,
  addPurchaseOrderAction,
  addWorkOrderAction,
  addWorkOrderFailAction,
  addWorkOrderSuccessAction,
  removeInvoiceFailAction,
  voidInvoiceAction,
  unVoidInvoiceAction,
  invoiceUpdateSuccessAction,
  removePurchaseOrderAction,
  removePurchaseOrderFailAction,
  removePurchaseOrderSuccessAction,
  removeQuoteAction,
  removeQuoteFailAction,
  removeWorkOrderFailAction,
  removeQuoteSuccessAction,
  removeWorkOrderSuccessAction,
  removeWorkOrderAction,
  setInvoiceSubmittingValueAction,
  setPurchaseOrderSubmittingValueAction,
  setQuoteSubmittingValueAction,
  setWorkOrderSubmittingValueAction,
  updateInvoiceAction,
  updateInvoiceFailAction,
  updateInvoiceSuccessAction,
  updatePurchaseOrderAction,
  updatePurchaseOrderFailAction,
  updatePurchaseOrderSuccessAction,
  updateQuoteAction,
  updateQuoteSuccessAction,
  updateWorkOrderFailAction,
  updateWorkOrderSuccessAction,
  updateQuoteFailAction,
  updateWorkOrderAction,
  addAttachmentToSelectedExpenseAction,
  addAttachmentToSelectedExpenseSuccessAction,
  addAttachmentToSelectedExpenseFailAction,
  addAdjustmentToPurchaseOrderAction,
  addAdjustmentToPurchaseOrderSuccessAction,
  addAdjustmentToPurchaseOrderFailAction,
  addEstimateToSelectedExpenseAction,
  addEstimateToSelectedExpenseFailAction,
  addEstimateToSelectedExpenseSuccessAction,
  deleteEstimateFromExpenseAction,
  deleteEstimateFromExpenseFailAction,
  deleteEstimateFromExpenseSuccessAction,
  updateEstimateAction,
  updateEstimateFailAction,
  updateEstimateSuccessAction,
  updateQuoteStatusFailAction,
  updatePurchaseOrderStatusFailAction,
  updateExpensePaymentStatusAction,
  updateExpensePaymentStatusFailAction,
  updateExpensePaymentStatusSuccessAction,
  updateInvoiceStatusAction,
  updateInvoiceStatusFailAction,
  updateInvoiceStatusSuccessAction,
  updatePurchaseOrderStatusSuccessAction,
  updatePurchaseOrderStatusAction,
  updateQuoteStatusAction,
  updateQuoteStatusSuccessAction,
  updateWorkOrderStatusAction,
  updateWorkOrderStatusFailAction,
  updateWorkOrderStatusSuccessAction,
  updateChangeOrderAction,
  updateChangeOrderSuccessAction,
  updateChangeOrderFailAction,
  addAssociationToPurchaseOrderLineItemAction,
  addAssociationToPurchaseOrderLineItemSuccessAction,
  addAssociationToPurchaseOrderLineItemFailAction,
  removeAssociationFromPurchaseOrderLineItemAction,
  removeAssociationFromPurchaseOrderLineItemSuccessAction,
  removeAssociationFromPurchaseOrderLineItemFailAction,
  newExpenseArrivedAction,
  addWatcherToExpenseAction,
  removeWatcherFromExpenseAction,
  watcherExpenseFailAction,
  watcherExpenseSuccessAction,
} = expenseSlice.actions;

export const useExpenses = () => useSelector((r: RootState) => r.expenses);
