import React, { FC, useEffect, useState } from 'react';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import { ExpenseElementType } from '../../redux/expenseData';
import { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  updateInvoiceAction,
  updatePaymentAction,
  updatePurchaseOrderAction,
  updateQuoteAction,
  useExpenses,
} from '../../redux/expenseSlice';
import { AssociateElementAutocomplete } from '../AssociateElementAutocomplete';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { AssociateElementMultipleAutocomplete } from '../AssociateElementMultipleAutocomplete';

type AssociateElementDialogProps = {
  selectedNumber?: string;
  type: ExpenseElementType;
  onClose: () => void;
} & Omit<DialogProps, 'onClose'>;

export const AssociateElementDialog: FC<AssociateElementDialogProps> = ({ type, onClose, open, selectedNumber }) => {
  const { selectedExpense, selectedInvoice, selectedPurchaseOrder, selectedWorkOrder, selectedQuote, selectedPayment } =
    useExpenses();
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [associationValue, setAssociationValue] = useState<string | undefined>();
  const [purchaseOrderAssociations, setPurchaseOrderAssociations] = useState<string[]>([]);
  useEffect(() => {
    if (
      selectedPayment.submitted ||
      selectedInvoice.submitted ||
      selectedQuote.submitted ||
      selectedWorkOrder.submitted ||
      selectedPurchaseOrder.submitted
    )
      handleClose();
  }, [
    selectedPayment.submitted,
    selectedInvoice.submitted,
    selectedQuote.submitted,
    selectedWorkOrder.submitted,
    selectedPurchaseOrder.submitted,
  ]);

  useEffect(() => {
    switch (type) {
      case ExpenseElementType.WorkOrder:
        break;
      case ExpenseElementType.Quote:
        const selectedQuote = selectedExpense.value?.quotes?.find((x) => x.number === selectedNumber);
        setAssociationValue(selectedQuote?.associatedWorkOrder);
        setTitle('Associate Work Order');
        break;
      case ExpenseElementType.PurchaseOrder:
        const selectedPurchaseOrder = selectedExpense.value?.purchaseOrders?.find((x) => x.number === selectedNumber);
        setPurchaseOrderAssociations(selectedPurchaseOrder?.associatedQuotes ?? []);
        setTitle('Associate Quotes');
        break;
      case ExpenseElementType.Payment:
        const selectedPayment = selectedExpense.value?.payments?.find((x) => x.number === selectedNumber);
        setAssociationValue(selectedPayment?.associatedInvoice);
        setTitle('Associate Invoice');
        break;
      case ExpenseElementType.Invoice:
        const selectedInvoice = selectedExpense.value?.invoices?.find((x) => x.number === selectedNumber);
        setAssociationValue(selectedInvoice?.associatedPurchaseOrder);
        setTitle('Associate Purchase Order');
        break;
      default:
        break;
    }
  }, [type, open]);

  const handleClose = () => {
    setAssociationValue(undefined);
    onClose();
  };

  const handleAddAssociation = () => {
    switch (type) {
      case ExpenseElementType.WorkOrder:
        break;
      case ExpenseElementType.Quote:
        const selectedQuote = selectedExpense.value?.quotes?.find((x) => x.number === selectedNumber);
        const updatedQuote = { ...selectedQuote, associatedWorkOrder: associationValue };
        selectedExpense.value?.id &&
          updatedQuote &&
          dispatch(updateQuoteAction({ jobId: selectedExpense.value?.id, quote: updatedQuote }));
        break;
      case ExpenseElementType.PurchaseOrder:
        const selectedPurchaseOrder = selectedExpense.value?.purchaseOrders?.find((x) => x.number === selectedNumber);
        const updatedPurchaseOrder = { ...selectedPurchaseOrder, associatedQuotes: purchaseOrderAssociations };
        selectedExpense.value?.id &&
          updatedPurchaseOrder &&
          dispatch(
            updatePurchaseOrderAction({ jobId: selectedExpense.value?.id, purchaseOrder: updatedPurchaseOrder }),
          );
        break;
      case ExpenseElementType.Payment:
        const selectedPayment = selectedExpense.value?.payments?.find((x) => x.number === selectedNumber);
        const updatedPayment = { ...selectedPayment, associatedInvoice: associationValue };
        selectedExpense.value?.id &&
          updatedPayment &&
          dispatch(updatePaymentAction({ jobId: selectedExpense.value?.id, payment: updatedPayment }));
        break;
      case ExpenseElementType.Invoice:
        const selectedInvoice = selectedExpense.value?.invoices?.find((x) => x.number === selectedNumber);
        const updatedInvoice = { ...selectedInvoice, associatedPurchaseOrder: associationValue };
        selectedExpense.value?.id &&
          updatedInvoice &&
          dispatch(updateInvoiceAction({ jobId: selectedExpense.value?.id, invoice: updatedInvoice }));
        break;
      default:
        break;
    }
  };

  return (
    <DialogLayout title={title} onClose={handleClose} open={open}>
      <DialogContent>
        {type === ExpenseElementType.PurchaseOrder ? (
          <AssociateElementMultipleAutocomplete
            onChangeAssociations={(value?: string[]) =>
              value ? setPurchaseOrderAssociations(value) : setPurchaseOrderAssociations([])
            }
            value={purchaseOrderAssociations}
          />
        ) : (
          <AssociateElementAutocomplete
            type={type}
            value={associationValue}
            onChange={(value?: string) => setAssociationValue(value)}
            renderInput={(params) => <TextField margin="dense" variant="outlined" label="Options" {...params} />}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <LoadingButton variant={'contained'} onClick={handleAddAssociation}>
          {title}
        </LoadingButton>
      </DialogActions>
    </DialogLayout>
  );
};
