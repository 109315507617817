export type RoomDataType = {
  id: string;
  name: string;
  squareFootage: number | undefined;
  tenant: string;
  managedBy: string;
};

export const roomData: RoomDataType = {
  id: '2',
  name: 'Master Bedroom',
  squareFootage: 150,
  tenant: 'Jim Smith',
  managedBy: 'Pamela Smith',
};
