import { initSubmittable, Submittable } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import {
  IAcceptDisbursementHandlerRequest,
  IAcceptPaymentHandlerRequest,
  IApplyChargeHandlerRequest,
  IApplyCreditHandlerRequest,
  IApplyPaymentHandlerRequest,
  IDepositAcceptedHandlerRequest,
  IDisburseDepositHandlerRequest,
  IReconcileDepositHandlerRequest,
  IReturnFundsHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LeaseDispatchType } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';

export type LeaseTransaction =
  | IReturnFundsHandlerRequest
  | IReconcileDepositHandlerRequest
  | IApplyCreditHandlerRequest
  | IApplyChargeHandlerRequest
  | IAcceptPaymentHandlerRequest
  | IDepositAcceptedHandlerRequest
  | IAcceptDisbursementHandlerRequest
  | IDisburseDepositHandlerRequest;

export interface LeaseTransactionState {
  leaseTransaction: Submittable<LeaseTransaction>;
}

export const initialLeaseTransactionState: LeaseTransactionState = {
  leaseTransaction: initSubmittable<LeaseTransaction>(),
};
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const leaseTransactionSlice = createSlice({
  name: 'leaseTransaction',
  initialState: initialLeaseTransactionState,
  reducers: {
    acceptLeaseDepositAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        propertyId: string;
        body: IDepositAcceptedHandlerRequest;
      }>,
    ) => {
      state.leaseTransaction.submitting = true;
      state.leaseTransaction.submitted = false;
      state.leaseTransaction.submittingValue = action.payload.body;
    },
    acceptLeasePaymentAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        assetId: string;
        leaseType: LeaseDispatchType;
        body: IAcceptPaymentHandlerRequest;
      }>,
    ) => {
      state.leaseTransaction.submitting = true;
      state.leaseTransaction.submitted = false;
      state.leaseTransaction.submittingValue = action.payload.body;
    },
    applyLeaseChargeAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        assetId: string;
        leaseType: LeaseDispatchType;
        body: IApplyChargeHandlerRequest;
      }>,
    ) => {
      state.leaseTransaction.submitting = true;
      state.leaseTransaction.submitted = false;
      state.leaseTransaction.submittingValue = action.payload.body;
    },
    applyLeaseCreditAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        assetId: string;
        leaseType: LeaseDispatchType;
        body: IApplyCreditHandlerRequest;
      }>,
    ) => {
      state.leaseTransaction.submitting = true;
      state.leaseTransaction.submitted = false;
      state.leaseTransaction.submittingValue = action.payload.body;
    },
    rectifyDepositAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        assetId: string;
        leaseType: LeaseDispatchType;
      }>,
    ) => {
      state.leaseTransaction.submitting = true;
      state.leaseTransaction.submitted = false;
    },
    successRectifyDepositAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        assetId: string;
        leaseType: LeaseDispatchType;
        body: IReconcileDepositHandlerRequest;
      }>,
    ) => {
      state.leaseTransaction.submitting = true;
      state.leaseTransaction.submitted = false;
      state.leaseTransaction.submittingValue = action.payload.body;
    },
    returnLeaseFundsAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        assetId: string;
        leaseType: LeaseDispatchType;
        body: IReturnFundsHandlerRequest;
      }>,
    ) => {
      state.leaseTransaction.submitting = true;
      state.leaseTransaction.submitted = false;
      state.leaseTransaction.submittingValue = action.payload.body;
    },
    acceptDisbursementAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        assetId: string;
        leaseType: LeaseDispatchType;
        body: IAcceptDisbursementHandlerRequest;
      }>,
    ) => {
      state.leaseTransaction.submitting = true;
      state.leaseTransaction.submitted = false;
      state.leaseTransaction.submittingValue = action.payload.body;
    },
    disburseDepositAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        assetId: string;
        leaseType: LeaseDispatchType;
        body: IDisburseDepositHandlerRequest;
      }>,
    ) => {
      state.leaseTransaction.submitting = true;
      state.leaseTransaction.submitted = false;
      state.leaseTransaction.submittingValue = action.payload.body;
    },
    logPaymentAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        assetId: string;
        leaseType: LeaseDispatchType;
        body: IApplyPaymentHandlerRequest;
      }>,
    ) => {
      state.leaseTransaction.submitting = true;
      state.leaseTransaction.submitted = false;
      state.leaseTransaction.submittingValue = action.payload.body;
    },
    leaseTransactionSuccessAction: (state) => {
      state.leaseTransaction.submitting = false;
      state.leaseTransaction.submitted = true;
    },
    leaseTransactionFailAction: (state) => {
      state.leaseTransaction.submitting = false;
      state.leaseTransaction.submitted = false;
    },
    resetLeaseTransactionSubmissionAction: (state) => {
      state.leaseTransaction.submitted = false;
    },
  },
});

export const {
  acceptLeaseDepositAction,
  applyLeaseChargeAction,
  acceptLeasePaymentAction,
  returnLeaseFundsAction,
  acceptDisbursementAction,
  disburseDepositAction,
  applyLeaseCreditAction,
  rectifyDepositAction,
  logPaymentAction,
  leaseTransactionFailAction,
  leaseTransactionSuccessAction,
  resetLeaseTransactionSubmissionAction,
} = leaseTransactionSlice.actions;

export const useLeaseTransaction = () => useSelector((state: RootState) => state.leaseTransactions);
