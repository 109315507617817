import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import React, { FC } from 'react';
import Stack, { StackProps } from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { FileUploadButton } from '../../buttons/FileUploadButton';
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { ImagePreviewComponent } from './ImagePreviewComponent';
import { getFileExtension } from '../../utils/getFileExtension';

export type SingleDocumentProps = {
  label?: string;
  onUpload?: (file: File) => any;
  value?: ReduxDocument;
  uploading?: boolean;
  onDelete?: () => any;
};
export const SingleDocument: FC<SingleDocumentProps & StackProps> = ({
  label,
  onDelete,
  value,
  onUpload,
  uploading,
  sx,
  ...stackProps
}) => {
  const docType = getFileExtension(value?.name);
  return (
    <FormControl margin="dense" sx={{ width: '100%' }}>
      {value && label && (
        <InputLabel shrink variant="standard">
          {label}
        </InputLabel>
      )}
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', ...sx }}
        {...stackProps}
      >
        {value ? (
          <ImagePreviewComponent imageUrl={value.uri} docType={docType}>
            <Link overflow="hidden" textOverflow="ellipsis" href={value.uri} download={value.name}>
              {value.name}
            </Link>
          </ImagePreviewComponent>
        ) : (
          onUpload && (
            <FileUploadButton variant="outlined" fullWidth onUpload={onUpload} loading={uploading}>
              Upload File
            </FileUploadButton>
          )
        )}
        {onDelete && value && (
          <IconButton sx={{ justifySelf: 'flex-end' }} edge="end" aria-label="delete" onClick={onDelete}>
            <Delete />
          </IconButton>
        )}
      </Stack>
    </FormControl>
  );
};
