import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { getAssetTypeFromPathname } from '../../../_shared/utils/getAssetTypeFromPathname';
import { AddListingDialog } from './AddListingDialog';
import { StyledInfoBox } from '../../../_shared/styledComponents/StyledInfoBox';
import { PropertyBuildingListingTabs } from './PropertyBuildingListingTab';
import { SplitButton } from '../../../_shared/buttons/SplitButton';

export enum AddListingType {
  FromTemplate = 'From Template',
  FromUnits = 'From Units',
  Generic = 'Generic',
}

export const ListListings: FC = () => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [addListingType, setAddListingType] = React.useState<AddListingType>(AddListingType.FromTemplate);

  const handleOpenDialog = (listingType: AddListingType) => {
    setAddListingType(listingType);
    setOpenDialog(true);
  };

  return (
    <StyledInfoBox label={'Advertise Listings'}>
      <Stack spacing={2}>
        {getAssetTypeFromPathname() === 'building' && (
          <SplitButton
            mainActionTitle={`Add Generic Listing`}
            secondaryActionTitle={`Add From Unit Template`}
            onMainAction={() => handleOpenDialog(AddListingType.Generic)}
            onSecondaryAction={() => handleOpenDialog(AddListingType.FromTemplate)}
          />
        )}
        <Box maxWidth={'100%'}>
          <PropertyBuildingListingTabs />
        </Box>
        <AddListingDialog open={openDialog} onClose={() => setOpenDialog(false)} addListingType={addListingType} />
      </Stack>
    </StyledInfoBox>
  );
};
