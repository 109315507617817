import React, { FC, useEffect } from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { SopTaskList } from './SopTaskList';
import { AddNewSopTask } from './AddNewSopTask';
import { useSopTasks } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import { ReduxSopTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { DialogLayout } from '../../../../../../_shared/dialogs/DialogLayout';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type AutomationsDialogProps = {
  open: boolean;
  onClose: () => void;
  showAddAutomation: boolean;
  onShowAddAutomation: () => void;
  onHideAddAutomation: () => void;
  onShowEditAutomation: (automation: ReduxSopTask) => void;
  isListInDialog: boolean;
  propertyId: string;
  page: number;
  pageSize: number;
  associatedId?: string;
  associationType?: AssociationType;
} & Omit<DialogProps, 'open' | 'onClose'>;

export const SopTaskDialog: FC<AutomationsDialogProps> = ({
  open,
  onClose,
  showAddAutomation,
  onShowAddAutomation,
  onHideAddAutomation,
  onShowEditAutomation,
  isListInDialog,
  propertyId,
  page,
  pageSize,
  associatedId,
  associationType,
  ...dialogProps
}) => {
  const { sopTasks } = useSopTasks();

  useEffect(() => {
    sopTasks.submitted && !isListInDialog && onClose();
  }, [sopTasks.submitted]);

  return (
    <DialogLayout
      title={showAddAutomation ? 'Add New SOP Task' : 'SOP Tasks'}
      {...dialogProps}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{ sx: { height: '60%', minHeight: 480 } }}
    >
      {showAddAutomation ? (
        <AddNewSopTask
          onHideAddAutomation={onHideAddAutomation}
          isListInDialog={isListInDialog}
          onCloseMainDialog={onClose}
          propertyId={propertyId}
          page={page}
          pageSize={pageSize}
          associatedId={associatedId}
          associationType={associationType}
        />
      ) : (
        <SopTaskList
          sopTasks={sopTasks.value ?? []}
          isLoading={sopTasks.loading}
          isSubmitting={sopTasks.submitting}
          onAddAutomation={onShowAddAutomation}
          onEditAutomation={onShowEditAutomation}
          isInAutomationDialog
          page={page}
          pageSize={pageSize}
        />
      )}
    </DialogLayout>
  );
};
