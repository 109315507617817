import React from 'react';
import {
  ICellData,
  IReportColumn,
  IReportData,
  IReportNode,
  ReportColumnType,
  ReportType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { GridColDef, GridColType } from '@mui/x-data-grid-premium';
import { tryFormatDate } from '../../../../../_shared/utils/TryFormatDate';
import { GridAggregationModel } from '@mui/x-data-grid-premium/hooks/features/aggregation/gridAggregationInterfaces';
import { currencyFormatter } from '../../../../../_shared/utils/currencyFormatter';
import { ReportDataCell } from './ReportDataCell';
import { findIdColumnName } from '../../../../../_shared/utils/datagridUtils';

export const reportTypeMap = new Map([[ReportType.Accounting, 'Accounting']]);

export interface FormattedRow {
  id: number;

  [key: string]: any;
}

export type CellDataWithAdditionalTypeOption = Omit<ICellData, 'value'> & {
  value: number | string | boolean | Date | undefined;
};

export function convertVariableToDisplayName(input?: string): string {
  if (!input) return '';

  const result = input.replace(/([A-Z])/g, ' $1').trim();
  return result.charAt(0).toUpperCase() + result.slice(1);
}

function convertColumnType(type?: ReportColumnType): GridColType {
  switch (type) {
    case ReportColumnType.String:
      return 'string';
    case ReportColumnType.Currency:
    case ReportColumnType.Decimal:
    case ReportColumnType.Integer:
      return 'number';
    case ReportColumnType.Date:
      return 'date';
    case ReportColumnType.Boolean:
      return 'boolean';
    default:
      return 'string';
  }
}

export const useFormatReportData = () => {
  function findIdColumnValue(rows?: IReportNode[], idColumnName?: string): string | undefined {
    if (!rows || !idColumnName) return undefined;
    const valueRow = rows.find((r) => (r.columnValues ? r.columnValues[idColumnName ?? ''] : ''));
    return valueRow?.columnValues ? valueRow?.columnValues[idColumnName ?? '']?.value : undefined;
  }

  const handleFormatColumns = (data?: IReportData): GridColDef[] => {
    const formattedColumns: GridColDef[] = [];
    const idColumnName = findIdColumnName(data?.columns?.map((c) => c.name ?? '') ?? []);
    const valueForGroupingColumn = findIdColumnValue(data?.rows ?? [], idColumnName ?? '');

    if (!data?.columns) return formattedColumns;

    data.columns.forEach((c) => {
      if (c.name === idColumnName) return;
      formattedColumns.push({
        headerName: convertVariableToDisplayName(
          c.name?.endsWith('Id') && idColumnName ? idColumnName : (c.name ?? ''),
        ),
        type: convertColumnType(c.type),
        field: c.name ?? '',
        flex: 1,
        display: 'flex',
        groupingValueGetter: (value?: ICellData) => (value ? value.value : null),
        valueFormatter:
          c.type === ReportColumnType.Currency
            ? (value) => {
                const displayValue = value ?? null;
                return displayValue !== undefined && displayValue !== null
                  ? currencyFormatter.format(displayValue)
                  : '';
              }
            : c.type === ReportColumnType.Date
              ? (value) => {
                  const displayValue = value ?? null;
                  return displayValue ? tryFormatDate(displayValue) : '';
                }
              : (value) => {
                  const displayValue = value ?? null;
                  return displayValue ?? '';
                },
        valueGetter: (value: ICellData) => (value ? value.value : null),
        renderCell: (params) => {
          return (
            <ReportDataCell
              {...params}
              valueForGroupingColumn={valueForGroupingColumn}
              column={c}
              idColumnName={idColumnName}
              columFetchData={c.fetchData}
              reportFetchData={data.fetchData}
            />
          );
        },
      });
    });

    return formattedColumns;
  };

  const formatCellValue = (value: any, type?: ReportColumnType): string | number => {
    switch (type) {
      case ReportColumnType.Integer:
      case ReportColumnType.Decimal:
      case ReportColumnType.Currency:
        return Number(value);
      default:
        return String(value);
    }
  };

  const handleFormatRows = (data?: IReportData): FormattedRow[] => {
    if (!data) return [];
    const formattedData: FormattedRow[] = [];
    const rows = data.rows;
    const columns = data.columns;

    rows?.forEach((x) => {
      const transformedRow: FormattedRow = { id: Math.floor(Math.random() * 1000000) };

      for (const key in x.columnValues) {
        const column = columns?.find((c) => c.name === key);

        const valueObject: CellDataWithAdditionalTypeOption = {
          value: formatCellValue(x.columnValues[key].value, column?.type),
          fetchData: x.columnValues[key].fetchData ?? undefined,
          association: x.columnValues[key].association ?? undefined,
        };
        transformedRow[key] = valueObject;
      }
      formattedData.push(transformedRow);
    });
    return formattedData;
  };

  const handleAggregationInitialization = (columns?: IReportColumn[]): GridAggregationModel => {
    const model: GridAggregationModel = {};

    columns?.forEach((x) => {
      if (x.type === ReportColumnType.Currency && x.name) {
        model[x.name] = 'sum';
      }
    });
    return model;
  };

  return {
    handleFormatColumns,
    handleFormatRows,
    handleAggregationInitialization,
    findIdColumnValue,
  };
};
