import React, { FC } from 'react';
import { ReduxCreditCard } from '@monkeyjump-labs/cam-fe-shared/dist/types/propertyTypes';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import { useIcons } from '../../../../../_shared/icons/useIcons';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { tryFormatDate } from '../../../../../_shared/utils/TryFormatDate';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { LoadingIconButton } from '../../../../../_shared/buttons/LoadingIconButton';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';

type CreditCardListProps = {
  cards: ReduxCreditCard[];
  onOpenUpdate: (cardId: string) => void;
  onRemoveCard: (cardId: string) => void;
  onViewStatement: (ccId: string) => void;
  loading?: boolean;
};
export const CreditCardList: FC<CreditCardListProps> = ({
  cards,
  onRemoveCard,
  onOpenUpdate,
  onViewStatement,
  loading,
}) => {
  const { ActionType, getActionIcon } = useIcons();
  const { selectedContext } = useAssets();
  return (
    <List>
      {cards.map((card) => {
        return (
          <ListItem key={card.id}>
            <Card sx={{ display: 'flex', flexGrow: 1 }}>
              <CardContent sx={{ display: 'flex', flexGrow: 1 }}>
                <Stack>
                  <Stack direction={'row'} spacing={1}>
                    <Link sx={{ cursor: 'pointer' }} color="inherit">
                      <Typography onClick={() => card.id && onOpenUpdate(card.id)}>
                        {card.nameOnCard ?? 'Credit Card'}
                      </Typography>
                    </Link>
                    <Typography>(***{card.cardNumberLastFour})</Typography>
                  </Stack>
                  <Typography
                    sx={{
                      color:
                        selectedContext.currentDate &&
                        card.expirationDate &&
                        selectedContext?.currentDate > card?.expirationDate
                          ? 'error.main'
                          : 'inherit',
                    }}
                  >
                    Exp: {card.expirationDate && tryFormatDate(card.expirationDate)}
                  </Typography>
                </Stack>
              </CardContent>
              <Divider sx={{ ml: 1 }} orientation="vertical" flexItem />
              <CardActions>
                <Tooltip title={'View Statement'}>
                  <IconButton onClick={() => card.id && onViewStatement(card.id)}>
                    {getActionIcon(ActionType.Statement)}
                  </IconButton>
                </Tooltip>
                <LoadingIconButton
                  loading={loading ?? false}
                  onClick={() => card.id && onRemoveCard(card.id)}
                  icon={getActionIcon(ActionType.Delete)}
                  label={'Remove Card'}
                />
              </CardActions>
            </Card>
          </ListItem>
        );
      })}
    </List>
  );
};
