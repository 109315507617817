import React, { FC } from 'react';
import { useUnit } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/unitSlice';
import Autocomplete from '@mui/material/Autocomplete';
import { IListUnitOpenLeasesHandlerSingleResponse } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { tryFormatDate } from '../../_shared/utils/TryFormatDate';
import Typography from '@mui/material/Typography';

type OpenLeasesForUnitSelectProps = {
  value?: IListUnitOpenLeasesHandlerSingleResponse;
  onChange: (v: IListUnitOpenLeasesHandlerSingleResponse | null) => void;
};

export const OpenLeasesForUnitSelect: FC<OpenLeasesForUnitSelectProps> = ({ onChange, value }) => {
  const { selectedUnitOpenLeases } = useUnit();

  const handleChange = (e: React.SyntheticEvent, v: IListUnitOpenLeasesHandlerSingleResponse | null) => {
    onChange(v);
  };

  const handleOptionLabel = (option: IListUnitOpenLeasesHandlerSingleResponse) => {
    const startDate = option.startDate ? tryFormatDate(option.startDate) : '';
    const endDate = option.endDate ? tryFormatDate(option.endDate) : '';
    return `${option.primaryTenant} (${startDate} - ${endDate})`;
  };

  if (!selectedUnitOpenLeases.value?.results || selectedUnitOpenLeases.value?.results.length === 0)
    return <Typography fontStyle={'italic'}>*There are no open leases associated with this unit.</Typography>;

  return (
    <>
      <FormControl fullWidth>
        <Autocomplete
          id="associated-lease"
          aria-label="associated-lease"
          value={value ?? null}
          options={selectedUnitOpenLeases.value?.results ?? []}
          getOptionLabel={(option: IListUnitOpenLeasesHandlerSingleResponse) => handleOptionLabel(option)}
          renderInput={(params) => <TextField {...params} variant="standard" label="Associated Lease" />}
          onChange={handleChange}
        />
      </FormControl>
    </>
  );
};
