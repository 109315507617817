import React, { FC, FormEvent, useEffect, useState } from 'react';
import { ReduxWorkOrder } from '../../redux/expenseData';
import { useDispatch } from 'react-redux';
import { updateWorkOrderAction, useExpenses } from '../../redux/expenseSlice';
import Stack from '@mui/material/Stack';
import { WorkOrderForm } from './WorkOrderForm';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { ISlimAccountRef } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type ViewWorkOrderDetailsProps = {
  workOrder: ReduxWorkOrder;
  propertyId: string;
  jobId: string;
};

export const ViewWorkOrderDetails: FC<ViewWorkOrderDetailsProps> = ({ workOrder, propertyId, jobId }) => {
  const dispatch = useDispatch();
  const { selectedWorkOrder } = useExpenses();
  const [dirty, setDirty] = useState(false);
  const [editingWorkOrder, setEditingWorkOrder] = useState(selectedWorkOrder.submittingValue ?? workOrder);

  useEffect(() => {
    setEditingWorkOrder(selectedWorkOrder.submittingValue ?? workOrder);
    selectedWorkOrder.submittingValue && setDirty(true);
  }, [selectedWorkOrder.submittingValue, workOrder]);

  const handleChangeWorkOrder = (field: keyof ReduxWorkOrder, value: string) => {
    setEditingWorkOrder({ ...editingWorkOrder, [field]: value });
    setDirty(true);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(updateWorkOrderAction({ jobId: jobId, workOrder: editingWorkOrder }));
    setDirty(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <WorkOrderForm
          value={editingWorkOrder}
          onChangeWorkOrder={handleChangeWorkOrder}
          propertyId={propertyId}
          expenseAccount={editingWorkOrder.propertyAccount}
          onChangeExpenseAccount={(account: ISlimAccountRef) => {
            setDirty(true);
            setEditingWorkOrder({ ...editingWorkOrder, propertyAccount: account });
          }}
          editingMode
        />
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
          <LoadingButton variant={'contained'} disabled={!dirty} type={'submit'} loading={selectedWorkOrder.submitting}>
            Save Changes
          </LoadingButton>
        </Box>
      </Stack>
    </form>
  );
};
