import React, { useEffect, useState } from 'react';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export type UnitOption = {
  id: string;
  label: string;
};

export type UnitBuildingAutocompleteProps = {
  value: UnitOption | null;
  onValueChange: (value: UnitOption | null) => void;
  required?: boolean;
};

export const UnitBuildingAutocomplete = ({ value, onValueChange, required }: UnitBuildingAutocompleteProps) => {
  const [unitOptions, setUnitOptions] = useState<UnitOption[]>([]);
  const property = useProperty();

  useEffect(() => {
    const selectedProperty = property.selectedProperty.value;

    if (selectedProperty?.buildings) {
      const options: UnitOption[] = selectedProperty.buildings
        ?.flatMap((building) => {
          return building.units?.map((unit) => {
            return {
              id: unit.id!,
              label: `🏠${building.name} 🚪${unit.name}`,
            };
          });
        })
        .filter((x): x is UnitOption => !!x)
        .sort((a, b) => a.label.localeCompare(b.label));

      setUnitOptions(options);
    } else {
      setUnitOptions([]);
    }
  }, [property.selectedProperty.value]);

  return (
    <Autocomplete
      id="unitIdRequested"
      options={unitOptions}
      value={value}
      filterSelectedOptions
      multiple={false}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      onChange={(event, newValue) => {
        onValueChange(newValue || null);
      }}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={'Select Building Unit'} required={required} />
      )}
    />
  );
};
