import { all, put, race, take, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  addAdjustmentToPurchaseOrderAction,
  addAdjustmentToPurchaseOrderFailAction,
  addAdjustmentToPurchaseOrderSuccessAction,
  addAssociationToPurchaseOrderLineItemAction,
  addAssociationToPurchaseOrderLineItemFailAction,
  addAssociationToPurchaseOrderLineItemSuccessAction,
  addCommentToInvoiceAction,
  addCommentToInvoiceFailAction,
  addCommentToInvoiceSuccessAction,
  addCommentToPaymentAction,
  addCommentToPaymentFailAction,
  addCommentToPaymentSuccessAction,
  addCommentToPurchaseOrderAction,
  addCommentToPurchaseOrderFailAction,
  addCommentToPurchaseOrderSuccessAction,
  addCommentToQuoteAction,
  addCommentToQuoteFailAction,
  addCommentToQuoteSuccessAction,
  addCommentToWorkOrderAction,
  addCommentToWorkOrderFailAction,
  addCommentToWorkOrderSuccessAction,
  addDocToInvoiceAction,
  addDocToInvoiceFailAction,
  addDocToInvoiceSuccessAction,
  addDocToPurchaseOrderAction,
  addDocToPurchaseOrderFailAction,
  addDocToPurchaseOrderSuccessAction,
  addDocToQuoteAction,
  addDocToQuoteFailAction,
  addDocToQuoteSuccessAction,
  addDocToWorkOrderAction,
  addDocToWorkOrderFailAction,
  addDocToWorkOrderSuccessAction,
  addDocumentToPaymentAction,
  addDocumentToPaymentFailAction,
  addDocumentToPaymentSuccessAction,
  addEstimateToSelectedExpenseAction,
  addEstimateToSelectedExpenseFailAction,
  addEstimateToSelectedExpenseSuccessAction,
  addInvoiceAction,
  addInvoiceFailAction,
  addInvoiceSuccessAction,
  addPaymentAction,
  addPaymentFailAction,
  addPaymentSuccessAction,
  addPurchaseOrderAction,
  addPurchaseOrderFailAction,
  addPurchaseOrderSuccessAction,
  addQuoteAction,
  addQuoteFailAction,
  addQuoteSuccessAction,
  addWorkOrderAction,
  addWorkOrderFailAction,
  addWorkOrderSuccessAction,
  createExpenseAction,
  createExpenseFailAction,
  createExpenseSuccessAction,
  deleteEstimateFromExpenseAction,
  deleteEstimateFromExpenseFailAction,
  getExpenseGroupByAssetIdAction,
  getExpenseGroupByAssetIdFailAction,
  getExpenseGroupByAssetIdSuccessAction,
  getExpenseGroupsAction,
  getExpenseGroupsFailAction,
  getExpenseGroupsSuccessAction,
  getExpensesAction,
  getExpensesFailAction,
  getExpensesSuccessAction,
  getSelectedExpenseByIdAction,
  getSelectedExpenseByIdFailAction,
  markPaymentVoidAction,
  markPaymentVoidFailAction,
  markPaymentVoidSuccessAction,
  voidInvoiceAction,
  removeAssociationFromPurchaseOrderLineItemFailAction,
  removeAssociationFromPurchaseOrderLineItemSuccessAction,
  removeInvoiceFailAction,
  invoiceUpdateSuccessAction,
  removePurchaseOrderAction,
  removePurchaseOrderFailAction,
  removePurchaseOrderSuccessAction,
  removeQuoteAction,
  removeQuoteFailAction,
  removeQuoteSuccessAction,
  removeWorkOrderAction,
  removeWorkOrderFailAction,
  removeWorkOrderSuccessAction,
  setInvoiceSubmittingValueAction,
  setPaymentSubmittingValueAction,
  setPurchaseOrderSubmittingValueAction,
  setQuoteSubmittingValueAction,
  setSelectedExpenseAction,
  setWorkOrderSubmittingValueAction,
  updateChangeOrderAction,
  updateChangeOrderFailAction,
  updateChangeOrderSuccessAction,
  updateEstimateAction,
  updateEstimateFailAction,
  updateEstimateSuccessAction,
  updateExpenseAction,
  updateExpenseFailAction,
  updateExpensePaymentStatusAction,
  updateExpensePaymentStatusFailAction,
  updateExpensePaymentStatusSuccessAction,
  updateExpenseStatusAction,
  updateExpenseStatusFailAction,
  updateExpenseStatusSuccessAction,
  updateExpenseSuccessAction,
  updateInvoiceAction,
  updateInvoiceFailAction,
  updateInvoiceStatusAction,
  updateInvoiceStatusFailAction,
  updateInvoiceStatusSuccessAction,
  updateInvoiceSuccessAction,
  updatePaymentAction,
  updatePaymentFailAction,
  updatePaymentSuccessAction,
  updatePurchaseOrderAction,
  updatePurchaseOrderFailAction,
  updatePurchaseOrderStatusAction,
  updatePurchaseOrderStatusFailAction,
  updatePurchaseOrderStatusSuccessAction,
  updatePurchaseOrderSuccessAction,
  updateQuoteAction,
  updateQuoteFailAction,
  updateQuoteStatusAction,
  updateQuoteStatusFailAction,
  updateQuoteStatusSuccessAction,
  updateQuoteSuccessAction,
  updateWorkOrderAction,
  updateWorkOrderFailAction,
  updateWorkOrderStatusAction,
  updateWorkOrderStatusFailAction,
  updateWorkOrderStatusSuccessAction,
  updateWorkOrderSuccessAction,
  unVoidInvoiceAction,
  unVoidPaymentAction,
  removeAssociationFromPurchaseOrderLineItemAction,
  addWatcherToExpenseAction,
  removeWatcherFromExpenseAction,
  watcherExpenseSuccessAction,
  watcherExpenseFailAction,
} from './expenseSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  cancelConfirmDialogAction,
  okConfirmDialogAction,
  showConfirmDialogAction,
  showErrorAction,
  showToastMessageAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import {
  AddAssociationToPurchaseOrderLineItemHandlerRequest,
  AddExpenseChangeOrderToPurchaseOrderHandlerRequest,
  AddExpenseEstimateHandlerRequest,
  AddExpenseHandlerRequest,
  AddExpenseInvoiceHandlerRequest,
  AddExpensePaymentHandlerRequest,
  AddExpensePurchaseOrderHandlerRequest,
  AddExpenseQuoteHandlerRequest,
  AddExpenseWorkOrderHandlerRequest,
  AssetType,
  AssociationType,
  CamAssociation,
  ExpenseInvoiceStatus,
  ExpensePaymentExpensePaymentStatus,
  ExpensePurchaseOrderStatus,
  ExpenseQuoteStatus,
  ExpenseResponse,
  GetExpenseHandlerResponse,
  GetGroupedExpensesHandlerExpensesGroupBy,
  GetGroupedExpensesHandlerResponse,
  IAddExpenseEstimateHandlerRequest,
  IAddExpenseHandlerRequest,
  IAddExpensePaymentHandlerRequest,
  IAddExpenseWorkOrderHandlerRequest,
  ICamAssociation,
  IExpensePurchaseOrderItem,
  IExpenseResponse,
  IPaginatedQueryExpression,
  IUpdateExpenseInvoiceHandlerRequest,
  IUpdateExpensePaymentHandlerRequest,
  IUpdateExpenseStatusHandlerRequest,
  IUpdatePurchaseOrderHandlerRequest,
  IUpdateQuoteHandlerRequest,
  ListExpensesForAssetHandlerResponse,
  ListExpensesHandlerRequest,
  ListExpensesHandlerResponse,
  ListSortDirection,
  Optional_ContactPerson,
  Optional_String,
  Optional_Vendor,
  PaginatedQueryExpression,
  QueryExpression,
  QueryOperator,
  QueryParameter,
  UpdateEstimateHandlerRequest,
  UpdateExpenseChangeOrderHandlerRequest,
  UpdateExpenseHandlerRequest,
  UpdateExpenseInvoiceHandlerRequest,
  UpdateExpensePaymentHandlerRequest,
  UpdateExpensePaymentStatusHandlerRequest,
  UpdateExpenseStatusHandlerRequest,
  UpdateInvoiceStatusHandlerRequest,
  UpdatePurchaseOrderHandlerRequest,
  UpdatePurchaseOrderStatusHandlerRequest,
  UpdateQuoteHandlerRequest,
  UpdateQuoteStatusHandlerRequest,
  UpdateWorkOrderHandlerRequest,
  UpdateWorkOrderStatusHandlerRequest,
  WorkOrderStatus,
  RemoveUserWatchHandlerRequest,
  AddUserWatchHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { Filter, SortDirection } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import {
  mapApiEstimate,
  mapApiExpense,
  mapApiExpensePayment,
  mapApiInvoice,
  mapApiPurchaseOrder,
  mapApiQuote,
  mapApiWorkOrder,
  mapReduxExpense,
  ReduxExpense,
  ReduxExpenseEstimate,
  ReduxExpensePayment,
  ReduxInvoice,
  ReduxPurchaseOrder,
  ReduxQuote,
  ReduxWorkOrder,
} from './expenseData';
import { convertToQueryExpression } from '../../utils/filteringUtils';
import {
  resetAssociationSubmissionAction,
  setAssociationSubmittingSuccessAction,
} from '../../search/redux/searchSlice';
import { toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

function* getJobs(
  action: PayloadAction<{
    propertyId: string;
    assetId: string;
    assetType: AssetType;
    includeClosed: boolean;
    page: number;
    pageSize: number;
    sortDirection: SortDirection | undefined;
    sortBy: keyof ReduxExpense | undefined;
    filters: Filter<ReduxExpense>[] | undefined;
  }>,
) {
  try {
    let formattedFilters: QueryExpression | undefined =
      action.payload.filters && convertToQueryExpression(action.payload.filters);
    const assetQuery = new QueryParameter({
      field: 'associatedId',
      operator: QueryOperator.Eq,
      value: action.payload.assetId,
    });
    if (formattedFilters) {
      assetQuery && formattedFilters?.parameters?.push(assetQuery);
    } else formattedFilters = new QueryExpression({ parameters: [assetQuery] });
    const query: IPaginatedQueryExpression = {
      ...formattedFilters,
      page: action.payload.page,
      pageSize: action.payload.pageSize,
      orderBy: action.payload.sortBy ? [action.payload.sortBy.toString()] : undefined,
      orderDirection: action.payload.sortDirection as ListSortDirection,
    };
    const response: ListExpensesHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().expenses_SearchExpense,
      new ListExpensesHandlerRequest({
        query: new PaginatedQueryExpression(query),
        propertyId: action.payload.propertyId,
        includeClosed: action.payload.includeClosed,
      }),
    );
    if (response) {
      const jobs = response.toJSON().results.map((x: IExpenseResponse) => mapReduxExpense(x));
      yield put(getExpensesSuccessAction({ results: jobs, totalCount: response.totalCount ?? 0 }));
    } else {
      yield put(
        showToastMessageAction({ message: 'Something went wrong getting jobs for property', severity: 'error' }),
      );
    }
  } catch (error: any) {
    yield put(getExpensesFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting jobs for property' }));
  }
}

function* getJobGroups(action: PayloadAction<{ assetType: AssetType; assetId: string; includeClosed: boolean }>) {
  try {
    const response: GetGroupedExpensesHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().expenses_GetGroupedExpenses,
      action.payload.assetType,
      action.payload.assetId,
      GetGroupedExpensesHandlerExpensesGroupBy.Asset,
      action.payload.includeClosed,
      undefined,
    );
    if (response) {
      yield put(getExpenseGroupsSuccessAction(response.toJSON().results));
    }
  } catch (error: any) {
    yield put(getExpenseGroupsFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting expense groups' }));
  }
}

function* getJobGroupByAssetId(
  action: PayloadAction<{
    associationId: string;
    associationType: AssetType;
    includeClosed: boolean;
    page: number;
    pageSize: number;
  }>,
) {
  try {
    const response: ListExpensesForAssetHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().expenses_ListExpensesForAsset,
      action.payload.associationType,
      action.payload.associationId,
      action.payload.includeClosed,
      action.payload.page,
      action.payload.pageSize,
    );
    if (response) {
      yield put(
        getExpenseGroupByAssetIdSuccessAction({
          id: action.payload.associationId,
          body: response.toJSON(),
        }),
      );
    }
  } catch (error: any) {
    yield put(getExpenseGroupByAssetIdFailAction({ id: action.payload.associationId }));
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting jobs grouped by asset' }));
  }
}

function* getSelectedJobById(action: PayloadAction<string>) {
  try {
    const response: GetExpenseHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().expenses_GetExpense,
      action.payload,
    );
    if (response) yield put(setSelectedExpenseAction(mapReduxExpense(response.toJSON().expenseDto)));
    else {
      yield put(
        showToastMessageAction({
          message: 'Something went wrong getting selected expense',
          severity: 'error',
        }),
      );
    }
  } catch (error: any) {
    yield put(getSelectedExpenseByIdFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting selected expense' }));
  }
}

function* createJob(action: PayloadAction<{ propertyId: string; body: IAddExpenseHandlerRequest }>) {
  try {
    const response: ExpenseResponse = yield apiCall(
      ApiClientSingleton.getInstance().expenses_CreateExpense,
      new AddExpenseHandlerRequest({ ...action.payload.body, propertyId: action.payload.propertyId }),
    );
    if (response) {
      yield put(showToastMessageAction({ message: 'Expense created successfully!', severity: 'success' }));
      yield put(createExpenseSuccessAction());
    } else yield put(showToastMessageAction({ message: 'Something went wrong creating expense', severity: 'error' }));
  } catch (error: any) {
    yield put(createExpenseFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem creating expense' }));
  }
}

function* updateJob(action: PayloadAction<{ id: string; body: ReduxExpense }>) {
  try {
    const body = new UpdateExpenseHandlerRequest(mapApiExpense(action.payload.body));
    yield apiCall(ApiClientSingleton.getInstance().expenses_UpdateExpense, action.payload.id, body);
    yield put(showToastMessageAction({ message: 'Expense updated successfully', severity: 'success' }));
    yield put(updateExpenseSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating expense' }));
    yield put(updateExpenseFailAction());
  }
}

function* updateJobStatus(action: PayloadAction<{ id: string; body: IUpdateExpenseStatusHandlerRequest }>) {
  try {
    const body = new UpdateExpenseStatusHandlerRequest(action.payload.body);
    yield apiCall(ApiClientSingleton.getInstance().expenses_UpdateExpenseStatus, action.payload.id, body);
    yield put(showToastMessageAction({ message: 'Expense status updated successfully', severity: 'success' }));
    yield put(updateExpenseStatusSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating expense status' }));
    yield put(updateExpenseStatusFailAction());
  }
}

function* addPayment(
  action: PayloadAction<{
    jobId: string;
    propertyId: string;
    buildingId?: string;
    body: Omit<IAddExpensePaymentHandlerRequest, 'datePaid'> & { datePaid?: string };
  }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_AddPayment,
      action.payload.jobId,
      new AddExpensePaymentHandlerRequest({
        ...action.payload.body,
        propertyId: action.payload.propertyId,
        buildingId: action.payload.buildingId,
        datePaid: action.payload.body.datePaid ? toStandardDate(action.payload.body.datePaid) : undefined,
      }),
    );
    yield put(addPaymentSuccessAction());
    yield put(showToastMessageAction({ message: 'Payment added successfully!', severity: 'success' }));
    yield put(setPaymentSubmittingValueAction(undefined));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding payment to expense' }));
    yield put(addPaymentFailAction());
  }
}

function* updatePayment(action: PayloadAction<{ jobId: string; payment: ReduxExpensePayment }>) {
  try {
    const payment = mapApiExpensePayment(action.payload.payment);
    const paymentWithOptionalAssociation: IUpdateExpensePaymentHandlerRequest = {
      ...payment,
      associatedInvoice: new Optional_String({ value: action.payload.payment.associatedInvoice }),
      vendor: new Optional_Vendor({ value: action.payload.payment.vendor }),
      vendorContact: new Optional_ContactPerson({ value: action.payload.payment.vendorContact }),
      expensePaymentStatus: action.payload.payment.status,
    };
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UpdatePayment,
      action.payload.jobId,
      new UpdateExpensePaymentHandlerRequest(paymentWithOptionalAssociation),
    );
    yield put(updatePaymentSuccessAction());
    yield put(showToastMessageAction({ message: 'Payment updated successfully!', severity: 'success' }));
    yield put(setPaymentSubmittingValueAction(undefined));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating payment' }));
    yield put(updatePaymentFailAction());
  }
}

function* voidPayment(action: PayloadAction<{ jobId: string; paymentNumber: string }>) {
  try {
    yield put(showConfirmDialogAction({ message: 'Are you sure you want to void this payment?' }));
    const { yes } = yield race({ yes: take(okConfirmDialogAction), no: take(cancelConfirmDialogAction) });
    if (yes) {
      yield apiCall(
        ApiClientSingleton.getInstance().expenses_VoidPayment,
        action.payload.jobId,
        action.payload.paymentNumber,
      );
      yield put(markPaymentVoidSuccessAction({ paymentNumber: action.payload.paymentNumber }));
      yield put(showToastMessageAction({ message: 'Payment voided successfully', severity: 'success' }));
    } else yield put(markPaymentVoidFailAction());
  } catch (error: any) {
    yield put(markPaymentVoidFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem voiding payment' }));
  }
}
function* unVoidPayment(action: PayloadAction<{ jobId: string; paymentNumber: string }>) {
  try {
    yield put(showConfirmDialogAction({ message: 'Are you sure you want to undo payment void?' }));
    const { yes } = yield race({ yes: take(okConfirmDialogAction), no: take(cancelConfirmDialogAction) });
    if (yes) {
      yield apiCall(
        ApiClientSingleton.getInstance().expenses_UnVoidPayment,
        action.payload.jobId,
        action.payload.paymentNumber,
      );
      yield put(markPaymentVoidSuccessAction({ paymentNumber: action.payload.paymentNumber }));
      yield put(showToastMessageAction({ message: 'Payment un-voided successfully', severity: 'success' }));
    } else yield put(markPaymentVoidFailAction());
  } catch (error: any) {
    yield put(markPaymentVoidFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem un-voiding payment' }));
  }
}

function* addDocumentToPayment(action: PayloadAction<{ jobId: string; paymentNumber: string; file: File }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UploadDocumentToPayment,
      action.payload.jobId,
      action.payload.paymentNumber,
      {
        data: action.payload.file,
        fileName: action.payload.file.name,
      },
    );
    yield put(showToastMessageAction({ message: 'Document uploaded successfully!', severity: 'success' }));
    yield put(addDocumentToPaymentSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem uploading document to payment' }));
    yield put(addDocumentToPaymentFailAction());
  }
}

function* addCommentToPayment(action: PayloadAction<{ jobId: string; paymentNumber: string; comment: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_AddCommentToPayment,
      action.payload.jobId,
      action.payload.paymentNumber,
      action.payload.comment,
    );
    yield put(showToastMessageAction({ message: 'Comment added successfully', severity: 'success' }));
    yield put(addCommentToPaymentSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding comment to payment' }));
    yield put(addCommentToPaymentFailAction());
  }
}

function* addInvoice(
  action: PayloadAction<{ jobId: string; propertyId: string; buildingId?: string; body: ReduxInvoice }>,
) {
  try {
    console.log('the body is: ', action.payload.body);
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_AddInvoice,
      action.payload.jobId,
      new AddExpenseInvoiceHandlerRequest({
        ...mapApiInvoice(action.payload.body),
        accountId: action.payload.body.propertyAccount?.id,
        propertyId: action.payload.propertyId,
        buildingId: action.payload.buildingId,
      }),
    );
    yield put(showToastMessageAction({ message: 'Invoice added successfully!', severity: 'success' }));
    yield put(setInvoiceSubmittingValueAction(undefined));
    yield put(addInvoiceSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding invoice to expense' }));
    yield put(addInvoiceFailAction());
  }
}

function* updateInvoice(action: PayloadAction<{ jobId: string; invoice: ReduxInvoice }>) {
  try {
    const invoice = mapApiInvoice(action.payload.invoice);
    const invoiceWithOptionalAssociation: IUpdateExpenseInvoiceHandlerRequest = {
      ...invoice,
      vendor: new Optional_Vendor({ value: action.payload.invoice.vendor }),
      vendorContact: new Optional_ContactPerson({ value: action.payload.invoice.vendorContact }),
      associatedPurchaseOrder: new Optional_String({ value: action.payload.invoice.associatedPurchaseOrder }),
      propertyAccountId: invoice.propertyAccount?.id,
    };
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UpdateInvoice,
      action.payload.jobId,
      new UpdateExpenseInvoiceHandlerRequest(invoiceWithOptionalAssociation),
    );
    yield put(showToastMessageAction({ message: 'Invoice updated successfully!', severity: 'success' }));
    yield put(setInvoiceSubmittingValueAction(undefined));
    yield put(updateInvoiceSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating invoice' }));
    yield put(updateInvoiceFailAction());
  }
}

function* voidInvoice(action: PayloadAction<{ jobId: string; invoiceNumber: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_VoidInvoice,
      action.payload.jobId,
      action.payload.invoiceNumber,
    );
    yield put(showToastMessageAction({ message: 'Invoice voided successfully', severity: 'success' }));
    yield put(invoiceUpdateSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem voiding invoice' }));
    yield put(removeInvoiceFailAction());
  }
}

function* unVoidInvoice(action: PayloadAction<{ jobId: string; invoiceNumber: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UnVoidInvoice,
      action.payload.jobId,
      action.payload.invoiceNumber,
    );
    yield put(showToastMessageAction({ message: 'Invoice rolled back successfully', severity: 'success' }));
    yield put(invoiceUpdateSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem rolling back invoice' }));
    yield put(removeInvoiceFailAction());
  }
}

function* addDocToInvoice(action: PayloadAction<{ jobId: string; invoiceNumber: string; file: File }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UploadDocToInvoice,
      action.payload.jobId,
      action.payload.invoiceNumber,
      {
        data: action.payload.file,
        fileName: action.payload.file.name,
      },
    );
    yield put(showToastMessageAction({ message: 'Document uploaded successfully!', severity: 'success' }));
    yield put(addDocToInvoiceSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem uploading document to invoice' }));
    yield put(addDocToInvoiceFailAction());
  }
}

function* addCommentToInvoice(action: PayloadAction<{ jobId: string; invoiceNumber: string; comment: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_AddCommentToInvoice,
      action.payload.jobId,
      action.payload.invoiceNumber,
      action.payload.comment,
    );
    yield put(showToastMessageAction({ message: 'Comment added successfully', severity: 'success' }));
    yield put(addCommentToInvoiceSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding comment to invoice' }));
    yield put(addCommentToInvoiceFailAction());
  }
}

function* addPurchaseOrder(action: PayloadAction<{ jobId: string; propertyId: string; body: ReduxPurchaseOrder }>) {
  try {
    const requestBody = new AddExpensePurchaseOrderHandlerRequest({
      ...mapApiPurchaseOrder(action.payload.body),
      amount: action.payload.body.isItemized ? undefined : action.payload.body.amount,
      items: action.payload.body.isItemized ? action.payload.body.items : undefined,
      propertyId: action.payload.propertyId,
    });
    yield apiCall(ApiClientSingleton.getInstance().expenses_AddPurchaseOrder, action.payload.jobId, requestBody);
    yield put(showToastMessageAction({ message: 'Purchase order added successfully!', severity: 'success' }));
    yield put(setPurchaseOrderSubmittingValueAction(undefined));
    yield put(addPurchaseOrderSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding purchase order to expense' }));
    yield put(addPurchaseOrderFailAction());
  }
}

function* updatePurchaseOrder(action: PayloadAction<{ jobId: string; purchaseOrder: ReduxPurchaseOrder }>) {
  try {
    const po = mapApiPurchaseOrder(action.payload.purchaseOrder);
    const poWithOptionalAssociation: IUpdatePurchaseOrderHandlerRequest = {
      ...po,
      status: action.payload.purchaseOrder.purchaseOrderStatus,
      vendor: new Optional_Vendor({ value: action.payload.purchaseOrder.vendor }),
      vendorContact: new Optional_ContactPerson({ value: action.payload.purchaseOrder.vendorContact }),
    };
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UpdatePurchaseOrder,
      action.payload.jobId,
      new UpdatePurchaseOrderHandlerRequest(poWithOptionalAssociation),
    );
    yield put(showToastMessageAction({ message: 'Purchase order updated successfully!', severity: 'success' }));
    yield put(setPurchaseOrderSubmittingValueAction(undefined));
    yield put(updatePurchaseOrderSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating purchase order' }));
    yield put(updatePurchaseOrderFailAction());
  }
}

function* removePurchaseOrder(action: PayloadAction<{ jobId: string; poNumber: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_DeletePurchaseOrder,
      action.payload.jobId,
      action.payload.poNumber,
    );
    yield put(removePurchaseOrderSuccessAction());
    yield put(showToastMessageAction({ message: 'Purchase order removed successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem removing purchase order' }));
    yield put(removePurchaseOrderFailAction());
  }
}

function* addDocToPurchaseOrder(action: PayloadAction<{ jobId: string; poNumber: string; file: File }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UploadDocToPurchaseOrder,
      action.payload.jobId,
      action.payload.poNumber,
      {
        data: action.payload.file,
        fileName: action.payload.file.name,
      },
    );
    yield put(showToastMessageAction({ message: 'Document uploaded successfully!', severity: 'success' }));
    yield put(addDocToPurchaseOrderSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem uploading document to purchase order' }));
    yield put(addDocToPurchaseOrderFailAction());
  }
}

function* addCommentToPurchaseOrder(action: PayloadAction<{ jobId: string; poNumber: string; comment: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_AddCommentToPurchaseOrder,
      action.payload.jobId,
      action.payload.poNumber,
      action.payload.comment,
    );
    yield put(showToastMessageAction({ message: 'Comment added successfully', severity: 'success' }));
    yield put(addCommentToPurchaseOrderSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding comment to purchase order' }));
    yield put(addCommentToPurchaseOrderFailAction());
  }
}

function* adjustPurchaseOrderAmount(
  action: PayloadAction<{
    expenseId: string;
    poNumber: string;
    note?: string;
    amount?: number;
    isItemized: boolean;
    items?: IExpensePurchaseOrderItem[] | undefined;
  }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_AddChangeOrderToPurchaseOrder,
      action.payload.expenseId,
      action.payload.poNumber,
      new AddExpenseChangeOrderToPurchaseOrderHandlerRequest({
        note: action.payload.note,
        amount: action.payload.amount,
        isItemized: action.payload.isItemized,
        items: action.payload.items,
      }),
    );
    yield put(addAdjustmentToPurchaseOrderSuccessAction());
    yield put(showToastMessageAction({ message: 'PO adjustment added successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(addAdjustmentToPurchaseOrderFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adjusting PO' }));
  }
}

function* addWorkOrder(
  action: PayloadAction<{ jobId: string; propertyId: string; body: IAddExpenseWorkOrderHandlerRequest }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_AddWorkOrder,
      action.payload.jobId,
      new AddExpenseWorkOrderHandlerRequest({ ...action.payload.body, propertyId: action.payload.propertyId }),
    );
    yield put(showToastMessageAction({ message: 'Work order added successfully!', severity: 'success' }));
    yield put(setWorkOrderSubmittingValueAction(undefined));
    yield put(addWorkOrderSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding work order to expense' }));
    yield put(addWorkOrderFailAction());
  }
}

function* updateWorkOrder(action: PayloadAction<{ jobId: string; workOrder: ReduxWorkOrder }>) {
  try {
    const wo = mapApiWorkOrder(action.payload.workOrder);
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UpdateWorkOrder,
      action.payload.jobId,
      new UpdateWorkOrderHandlerRequest({ workOrder: wo }),
    );
    yield put(showToastMessageAction({ message: 'Work order updated successfully!', severity: 'success' }));
    yield put(setWorkOrderSubmittingValueAction(undefined));
    yield put(updateWorkOrderSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating work order' }));
    yield put(updateWorkOrderFailAction());
  }
}

function* removeWorkOrder(action: PayloadAction<{ jobId: string; woNumber: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_DeleteWorkOrder,
      action.payload.jobId,
      action.payload.woNumber,
    );
    yield put(showToastMessageAction({ message: 'Work order removed successfully', severity: 'success' }));
    yield put(removeWorkOrderSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem removing work order' }));
    yield put(removeWorkOrderFailAction());
  }
}

function* addDocToWorkOrder(action: PayloadAction<{ jobId: string; woNumber: string; file: File }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UploadDocToWorkOrder,
      action.payload.jobId,
      action.payload.woNumber,
      {
        data: action.payload.file,
        fileName: action.payload.file.name,
      },
    );
    yield put(showToastMessageAction({ message: 'Document uploaded successfully!', severity: 'success' }));
    yield put(addDocToWorkOrderSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem uploading document to work order' }));
    yield put(addDocToWorkOrderFailAction());
  }
}

function* addCommentToWorkOrder(action: PayloadAction<{ jobId: string; woNumber: string; comment: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_AddCommentToWorkOrder,
      action.payload.jobId,
      action.payload.woNumber,
      action.payload.comment,
    );
    yield put(showToastMessageAction({ message: 'Comment added successfully', severity: 'success' }));
    yield put(addCommentToWorkOrderSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding comment to work order' }));
    yield put(addCommentToWorkOrderFailAction());
  }
}

function* addQuote(action: PayloadAction<{ jobId: string; propertyId: string; body: ReduxQuote }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_AddQuote,
      action.payload.jobId,
      new AddExpenseQuoteHandlerRequest({
        ...mapApiQuote(action.payload.body),
        propertyId: action.payload.propertyId,
      }),
    );
    yield put(showToastMessageAction({ message: 'Quote added successfully!', severity: 'success' }));
    yield put(setQuoteSubmittingValueAction(undefined));
    yield put(addQuoteSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding quote to expense' }));
    yield put(addQuoteFailAction());
  }
}

function* updateQuote(action: PayloadAction<{ jobId: string; quote: ReduxQuote }>) {
  try {
    const quote = mapApiQuote(action.payload.quote);
    const quoteWithOptionalAssociation: IUpdateQuoteHandlerRequest = {
      ...quote,
      associatedWorkOrder: new Optional_String({ value: action.payload.quote.associatedWorkOrder }),
      status: action.payload.quote.expenseQuoteStatus,
      vendor: new Optional_Vendor({ value: action.payload.quote.vendor }),
      vendorContact: new Optional_ContactPerson({ value: action.payload.quote.vendorContact }),
    };
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UpdateQuote,
      action.payload.jobId,
      new UpdateQuoteHandlerRequest(quoteWithOptionalAssociation),
    );
    yield put(showToastMessageAction({ message: 'Quote updated successfully!', severity: 'success' }));
    yield put(setQuoteSubmittingValueAction(undefined));
    yield put(updateQuoteSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating quote' }));
    yield put(updateQuoteFailAction());
  }
}

function* removeQuote(action: PayloadAction<{ jobId: string; quoteNumber: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_DeleteQuote,
      action.payload.jobId,
      action.payload.quoteNumber,
    );
    yield put(removeQuoteSuccessAction());
    yield put(showToastMessageAction({ message: 'Quote removed successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem removing quote' }));
    yield put(removeQuoteFailAction());
  }
}

function* addDocToQuote(action: PayloadAction<{ jobId: string; quoteNumber: string; file: File }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UploadDocToQuote,
      action.payload.jobId,
      action.payload.quoteNumber,
      {
        data: action.payload.file,
        fileName: action.payload.file.name,
      },
    );
    yield put(showToastMessageAction({ message: 'Document uploaded successfully!', severity: 'success' }));
    yield put(addDocToQuoteSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem uploading document to quote' }));
    yield put(addDocToQuoteFailAction());
  }
}

function* addCommentToQuote(action: PayloadAction<{ jobId: string; quoteNumber: string; comment: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_AddCommentToQuote,
      action.payload.jobId,
      action.payload.quoteNumber,
      action.payload.comment,
    );
    yield put(showToastMessageAction({ message: 'Comment added successfully', severity: 'success' }));
    yield put(addCommentToQuoteSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding comment to quote' }));
    yield put(addCommentToQuoteFailAction());
  }
}

function* addEstimate(
  action: PayloadAction<{ propertyId: string; expenseId: string; body: IAddExpenseEstimateHandlerRequest }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_AddEstimate,
      action.payload.expenseId,
      new AddExpenseEstimateHandlerRequest({ ...action.payload.body, propertyId: action.payload.propertyId }),
    );
    yield put(showToastMessageAction({ message: 'Estimate added successfully', severity: 'success' }));
    yield put(addEstimateToSelectedExpenseSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding estimate to expense' }));
    yield put(addEstimateToSelectedExpenseFailAction());
  }
}

function* deleteEstimate(action: PayloadAction<{ expenseId: string; estimateNumber: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_DeleteEstimate,
      action.payload.expenseId,
      action.payload.estimateNumber,
    );
    yield put(deleteEstimateFromExpenseFailAction());
    yield put(showToastMessageAction({ message: 'Estimate removed successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem removing estimate from expense' }));
    yield put(deleteEstimateFromExpenseFailAction());
  }
}

function* updateEstimate(action: PayloadAction<{ expenseId: string; body: ReduxExpenseEstimate }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UpdateEstimate,
      action.payload.expenseId,
      new UpdateEstimateHandlerRequest({ estimate: mapApiEstimate(action.payload.body) }),
    );
    yield put(showToastMessageAction({ message: 'Estimate updated successfully', severity: 'success' }));
    yield put(updateEstimateSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating estimate' }));
    yield put(updateEstimateFailAction());
  }
}

function* updateExpensePaymentStatus(
  action: PayloadAction<{ expenseId: string; number: string; status: ExpensePaymentExpensePaymentStatus }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UpdateExpensePaymentStatus,
      action.payload.expenseId,
      new UpdateExpensePaymentStatusHandlerRequest({
        number: action.payload.number,
        status: action.payload.status,
      }),
    );
    yield put(updateExpensePaymentStatusSuccessAction());
    yield put(showToastMessageAction({ message: 'Payment status updated successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(updateExpensePaymentStatusFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating payment status' }));
  }
}

function* updateInvoiceStatus(
  action: PayloadAction<{ expenseId: string; number: string; status: ExpenseInvoiceStatus }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UpdateInvoiceStatus,
      action.payload.expenseId,
      new UpdateInvoiceStatusHandlerRequest({ number: action.payload.number, status: action.payload.status }),
    );
    yield put(updateInvoiceStatusSuccessAction());
    yield put(showToastMessageAction({ message: 'Invoice status updated successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(updateInvoiceStatusFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating invoice status' }));
  }
}

function* updatePurchaseOrderStatus(
  action: PayloadAction<{ expenseId: string; number: string; status: ExpensePurchaseOrderStatus }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UpdatePurchaseOrderStatus,
      action.payload.expenseId,
      new UpdatePurchaseOrderStatusHandlerRequest({ number: action.payload.number, status: action.payload.status }),
    );
    yield put(updatePurchaseOrderStatusSuccessAction());
    yield put(showToastMessageAction({ message: 'Purchase order status updated successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(updatePurchaseOrderStatusFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating purchase order status' }));
  }
}

function* updateQuoteStatus(action: PayloadAction<{ expenseId: string; number: string; status: ExpenseQuoteStatus }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UpdateQuoteStatus,
      action.payload.expenseId,
      new UpdateQuoteStatusHandlerRequest({ number: action.payload.number, status: action.payload.status }),
    );
    yield put(updateQuoteStatusSuccessAction());
    yield put(showToastMessageAction({ message: 'Quote status updated successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(updateQuoteStatusFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating quote status' }));
  }
}

function* updateWorkOrderStatus(action: PayloadAction<{ expenseId: string; number: string; status: WorkOrderStatus }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UpdateWorkOrderStatus,
      action.payload.expenseId,
      new UpdateWorkOrderStatusHandlerRequest({ number: action.payload.number, status: action.payload.status }),
    );
    yield put(updateWorkOrderStatusSuccessAction());
    yield put(showToastMessageAction({ message: 'Work order status updated successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(updateWorkOrderStatusFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating work order status' }));
  }
}

function* updateChangeOrder(
  action: PayloadAction<{
    expenseId: string;
    poNumber: string;
    changeOrderId: string;
    amount?: number;
    items?: IExpensePurchaseOrderItem[];
    note?: string;
  }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_UpdateChangeOrder,
      action.payload.expenseId,
      action.payload.poNumber,
      action.payload.changeOrderId,
      new UpdateExpenseChangeOrderHandlerRequest({
        amount: action.payload.amount,
        items: action.payload.items,
        note: action.payload.note,
      }),
    );
    yield put(updateChangeOrderSuccessAction());
    yield put(showToastMessageAction({ message: 'Change order updated successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(updateChangeOrderFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating change order' }));
  }
}

function* addAssociationToPurchaseOrderLineItem(
  action: PayloadAction<{
    expenseId: string;
    purchaseOrderNumber: string;
    lineItemNumber: string;
    changeOrderId?: string;
    associatedId: string;
    associationType: AssociationType;
  }>,
) {
  try {
    if (action.payload.changeOrderId) {
      yield apiCall(
        ApiClientSingleton.getInstance().expenses_AddAssociationToChangeOrderLineItem,
        action.payload.expenseId,
        action.payload.purchaseOrderNumber,
        action.payload.changeOrderId,
        action.payload.lineItemNumber,
        new AddAssociationToPurchaseOrderLineItemHandlerRequest({
          associatedId: action.payload.associatedId,
          associationType: action.payload.associationType,
        }),
      );
    } else {
      yield apiCall(
        ApiClientSingleton.getInstance().expenses_AddAssociationToPurchaseOrderLineItem,
        action.payload.expenseId,
        action.payload.purchaseOrderNumber,
        action.payload.lineItemNumber,
        new AddAssociationToPurchaseOrderLineItemHandlerRequest({
          associatedId: action.payload.associatedId,
          associationType: action.payload.associationType,
        }),
      );
    }
    yield put(addAssociationToPurchaseOrderLineItemSuccessAction());
    yield put(setAssociationSubmittingSuccessAction());
    yield put(showToastMessageAction({ message: 'Association added to PO line item', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding association to PO line item' }));
    yield put(resetAssociationSubmissionAction());
    yield put(addAssociationToPurchaseOrderLineItemFailAction());
  }
}

function* removeAssociationFromPurchaseOrderLineItem(
  action: PayloadAction<{
    expenseId: string;
    purchaseOrderNumber: string;
    changeOrderId?: string;
    lineItemNumber: string;
    association: ICamAssociation;
  }>,
) {
  try {
    const body = new CamAssociation(action.payload.association);
    if (action.payload.changeOrderId) {
      yield apiCall(
        ApiClientSingleton.getInstance().expenses_RemoveAssociationFromChangeOrderLineItem,
        action.payload.expenseId,
        action.payload.purchaseOrderNumber,
        action.payload.changeOrderId,
        action.payload.lineItemNumber,
        body,
      );
    } else {
      yield apiCall(
        ApiClientSingleton.getInstance().expenses_RemoveAssociationFromPurchaseOrderLineItem,
        action.payload.expenseId,
        action.payload.purchaseOrderNumber,
        action.payload.lineItemNumber,
        body,
      );
    }
    yield put(removeAssociationFromPurchaseOrderLineItemSuccessAction());
    yield put(showToastMessageAction({ message: 'Association removed from PO line item', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem removing association from PO line item' }));
    yield put(removeAssociationFromPurchaseOrderLineItemFailAction());
  }
}

function* addWatcherToExpense(action: PayloadAction<{ expenseId: string; userId: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_AddWatch,
      action.payload.expenseId,
      new AddUserWatchHandlerRequest({ userId: action.payload.userId }),
    );
    yield put(watcherExpenseSuccessAction());
    yield put(showToastMessageAction({ message: 'Watcher added to expense', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding watcher to expense' }));
    yield put(watcherExpenseFailAction());
  }
}

function* removeWatcherFromExpense(action: PayloadAction<{ expenseId: string; userId: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().expenses_RemoveWatch,
      action.payload.expenseId,
      new RemoveUserWatchHandlerRequest({ userId: action.payload.userId }),
    );
    yield put(watcherExpenseSuccessAction());
    yield put(showToastMessageAction({ message: 'Watcher removed from expense', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem removing watcher from expense' }));
    yield put(watcherExpenseFailAction());
  }
}

export function* expenseSaga() {
  yield all([
    takeLatest(getExpensesAction.type, getJobs),
    takeLatest(getExpenseGroupsAction.type, getJobGroups),
    takeEvery(getExpenseGroupByAssetIdAction.type, getJobGroupByAssetId),
    takeLatest(getSelectedExpenseByIdAction.type, getSelectedJobById),
    takeLatest(createExpenseAction.type, createJob),
    takeLatest(updateExpenseAction.type, updateJob),
    takeLatest(updateExpenseStatusAction.type, updateJobStatus),
    takeLatest(addPaymentAction.type, addPayment),
    takeLatest(updatePaymentAction.type, updatePayment),
    takeLatest(markPaymentVoidAction.type, voidPayment),
    takeLatest(unVoidPaymentAction.type, unVoidPayment),
    takeLatest(addDocumentToPaymentAction.type, addDocumentToPayment),
    takeLatest(addCommentToPaymentAction.type, addCommentToPayment),
    takeLatest(addInvoiceAction.type, addInvoice),
    takeLatest(updateInvoiceAction.type, updateInvoice),
    takeLatest(voidInvoiceAction.type, voidInvoice),
    takeLatest(unVoidInvoiceAction.type, unVoidInvoice),
    takeLatest(addDocToInvoiceAction.type, addDocToInvoice),
    takeLatest(addCommentToInvoiceAction.type, addCommentToInvoice),
    takeLatest(addPurchaseOrderAction.type, addPurchaseOrder),
    takeLatest(updatePurchaseOrderAction.type, updatePurchaseOrder),
    takeLatest(removePurchaseOrderAction.type, removePurchaseOrder),
    takeLatest(addDocToPurchaseOrderAction.type, addDocToPurchaseOrder),
    takeLatest(addCommentToPurchaseOrderAction.type, addCommentToPurchaseOrder),
    takeLatest(addWorkOrderAction.type, addWorkOrder),
    takeLatest(updateWorkOrderAction.type, updateWorkOrder),
    takeLatest(removeWorkOrderAction.type, removeWorkOrder),
    takeLatest(addDocToWorkOrderAction.type, addDocToWorkOrder),
    takeLatest(addCommentToWorkOrderAction.type, addCommentToWorkOrder),
    takeLatest(addQuoteAction.type, addQuote),
    takeLatest(updateQuoteAction.type, updateQuote),
    takeLatest(removeQuoteAction.type, removeQuote),
    takeLatest(addDocToQuoteAction.type, addDocToQuote),
    takeLatest(addCommentToQuoteAction.type, addCommentToQuote),
    takeLatest(addAdjustmentToPurchaseOrderAction.type, adjustPurchaseOrderAmount),
    takeLatest(addEstimateToSelectedExpenseAction.type, addEstimate),
    takeLatest(deleteEstimateFromExpenseAction.type, deleteEstimate),
    takeLatest(updateEstimateAction.type, updateEstimate),
    takeLatest(updateExpensePaymentStatusAction.type, updateExpensePaymentStatus),
    takeLatest(updateInvoiceStatusAction.type, updateInvoiceStatus),
    takeLatest(updatePurchaseOrderStatusAction.type, updatePurchaseOrderStatus),
    takeLatest(updateQuoteStatusAction.type, updateQuoteStatus),
    takeLatest(updateWorkOrderStatusAction.type, updateWorkOrderStatus),
    takeLatest(updateChangeOrderAction.type, updateChangeOrder),
    takeLatest(addAssociationToPurchaseOrderLineItemAction.type, addAssociationToPurchaseOrderLineItem),
    takeLatest(removeAssociationFromPurchaseOrderLineItemAction.type, removeAssociationFromPurchaseOrderLineItem),
    takeLatest(addWatcherToExpenseAction.type, addWatcherToExpense),
    takeLatest(removeWatcherFromExpenseAction.type, removeWatcherFromExpense),
  ]);
}
