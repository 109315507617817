import { GridRowParams } from '@mui/x-data-grid-premium';
import { ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { PaymentTitle } from '../../../dialogs/paymentDialog/LeasePaymentDialog';
import { LeaseDetailedStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type PaymentHistoryMenuProps = {
  handlePaymentOptions: (paymentId: string, propertyId: string, title: PaymentTitle) => void;
} & GridRowParams<ReduxLease>;

export const PaymentOptionsButton = ({ handlePaymentOptions, ...props }: PaymentHistoryMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleClickOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenPaymentDialog = (title: PaymentTitle) => {
    handlePaymentOptions(props.row.id!, props.row.propertyId!, title);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip key="paymentOptionsAction" title={'Transaction Options'}>
        <IconButton
          onClick={handleClickOpenMenu}
          id="transaction-options-menu-button"
          aria-controls={openMenu ? 'transaction-options-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
        >
          <CreditCardIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="transaction-options-menu"
        aria-labelledby="transaction-options-menu-button"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {props.row.detailedStatus !== LeaseDetailedStatus.ActivePendingEviction && (
          <MenuItem onClick={() => handleOpenPaymentDialog('Accept Payment')}>Accept Payment</MenuItem>
        )}
        {props.row.detailedStatus !== LeaseDetailedStatus.Closed &&
          props.row.detailedStatus !== LeaseDetailedStatus.ClosedEviction && (
            <MenuItem onClick={() => handleOpenPaymentDialog('Apply Charge')}>Apply Charge</MenuItem>
          )}
        {props.row.detailedStatus !== LeaseDetailedStatus.Closed &&
          props.row.detailedStatus !== LeaseDetailedStatus.ClosedEviction && (
            <MenuItem onClick={() => handleOpenPaymentDialog('Apply Credit')}>Apply Credit</MenuItem>
          )}
        {props.row.detailedStatus !== LeaseDetailedStatus.Closed &&
          props.row.detailedStatus !== LeaseDetailedStatus.ClosedEviction && (
            <MenuItem onClick={() => handleOpenPaymentDialog('Log Returned Funds')}>Log Returned Funds</MenuItem>
          )}
      </Menu>
    </>
  );
};
