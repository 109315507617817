import React, { FC } from 'react';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { DialogLayout } from '../../dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import { createDocumentLink } from '../../paymentProcessing/authorizeNet/helpers';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import {
  DocumentUploadStatus,
  ICamAssociation,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { TokenSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/tokenSingleton';
import { PanoData } from '@photo-sphere-viewer/core';
import { ImageProcessing } from './ImageProcessing';
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';

type PanoramaViewerProps = {
  association: ICamAssociation;
  document: ReduxDocument;
  open: boolean;
  onClose: () => void;
};

export const PanoramaViewer: FC<PanoramaViewerProps> = ({ document, open, onClose, association }) => {
  const { selectedContext } = useAssets();
  const { metadata } = document;
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  const img = new Image();
  img.src = document.uri && document.uri !== 'about:blank' ? document.uri : '';

  img.onload = () => {
    const width = img.width;
    const height = img.height;
    setHeight(height);
    setWidth(width);
  };

  const fullWidth = Math.max(img.width, img.height * 2);
  const fullHeight = Math.round(fullWidth / 2);
  const croppedX = Math.round((fullWidth - img.width) / 2);
  const croppedY = Math.round((fullHeight - img.height) / 2);

  const panoData: PanoData = {
    fullWidth: fullWidth,
    fullHeight: fullHeight,
    croppedWidth: width,
    croppedHeight: height,
    croppedX: croppedX,
    croppedY: croppedY,
  };

  const title =
    metadata && metadata?.title
      ? metadata.title
      : document.children && document.children[0] && document.children[0].name
        ? document.children[0].name
        : '360 View';

  const handleClose = () => {
    onClose();
    setWidth(0);
    setHeight(0);
  };

  return (
    <DialogLayout fullScreen title={title} onClose={handleClose} open={open}>
      <DialogContent>
        {document.uri === 'about:blank' && (
          <ImageProcessing
            failMessage={
              document.status === DocumentUploadStatus.Failed
                ? (document.metadata?.systemMessage ?? 'Something went wrong')
                : undefined
            }
          />
        )}
        {document.uri !== 'about:blank' && height > 0 && width > 0 && (
          <ReactPhotoSphereViewer
            src={createDocumentLink(selectedContext.propertyId, association, document.id)}
            requestHeaders={{ Authorization: `Bearer ${TokenSingleton.getAccessToken()}` }}
            panoData={panoData}
            height={'calc(100vh - 4rem)'}
            width={'100%'}
          />
        )}
      </DialogContent>
    </DialogLayout>
  );
};
