import React, { useEffect, useState } from 'react';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { useDispatch } from 'react-redux';
import { CurrentFinancialMetrics } from './currentMetrics/CurrentFinancialMetrics';
import { CurrentOccupancyMetrics } from './currentMetrics/CurrentOccupancyMetrics';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import { StyledInfoBox } from '../../../../../../_shared/styledComponents/StyledInfoBox';
import { HistoricalFinancialMetrics } from './historicalMetrics/HistoricalFinancialMetrics';
import { HistoricalOccupancyMetrics } from './historicalMetrics/HistoricalOccupancyMetrics';
import { HistoricalLeaseStatusMetrics } from './historicalMetrics/HistoricalLeaseStatusMetrics';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { addDays, addMonths, eachDayOfInterval, parse } from 'date-fns';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {
  getCurrentFinancialMetricsByPropertyIdAction,
  getCurrentOccupancyMetricsByPropertyIdAction,
  getHistoricalFinancialMetricsByPropertyIdAction,
  getHistoricalOccupancyMetricsByPropertyIdAction,
  useFinancialMetrics,
  useOccupancyMetrics,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/metrics/metricsSlice';
import { toReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import {
  HistoricalFinancialData,
  HistoricalLeaseStatusData,
  HistoricalOccupancyData,
} from './historicalMetrics/formatHistoricalData';
import { tryFormatIsoDate } from '../../../../../../_shared/utils/TryFormatDate';
import {
  IFinancialMetrics,
  IOccupancyMetrics,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { makeRequired } from '../../../../../../_shared/utils/makeRequired';
import { CurrentLeaseStatusMetrics } from './currentMetrics/CurrentLeaseStatusMetrics';

export type PieChartData = {
  name?: string;
  value?: number;
  color?: string;
};

export const Metrics = () => {
  const dispatch = useDispatch();
  const { historicalOccupancyMetrics } = useOccupancyMetrics();
  const { historicalFinancialMetrics } = useFinancialMetrics();
  const { selectedProperty } = useProperty();
  const [showHistorical, setShowHistorical] = useState(false);
  const [historicalFinancialData, setHistoricalFinancialData] = useState<HistoricalFinancialData[]>([]);
  const [historicalOccupancyData, setHistoricalOccupancyData] = useState<HistoricalOccupancyData[]>([]);
  const [historicalLeaseStatusData, setHistoricalLeaseStatusData] = useState<HistoricalLeaseStatusData[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(
    selectedProperty.value?.currentPropertyDate
      ? addMonths(new Date(selectedProperty.value.currentPropertyDate), -3)
      : new Date(),
  );
  const [endDate, setEndDate] = useState<Date | null>(
    parse(selectedProperty.value?.currentPropertyDate ?? toReduxDate(new Date()), 'yyyy-MM-dd', new Date()),
  );
  const start = startDate ? new Date(startDate) : new Date();
  const end = endDate ? new Date(endDate) : addDays(start, 1);
  let dateArray: string[] = [];
  try {
    dateArray = eachDayOfInterval({ start, end }).map((date) => tryFormatIsoDate(date.toDateString()));
  } catch {
    console.warn('Error in Metrics.tsx: dateArray');
  }

  useEffect(() => {
    const newEndDate = parse(
      selectedProperty.value?.currentPropertyDate ?? toReduxDate(new Date()),
      'yyyy-MM-dd',
      new Date(),
    );
    setEndDate(newEndDate);
    setStartDate(addMonths(new Date(newEndDate), -3));
  }, [selectedProperty.value?.currentPropertyDate]);

  useEffect(() => {
    if (!selectedProperty.value?.id) return;
    dispatch(getCurrentOccupancyMetricsByPropertyIdAction(selectedProperty.value?.id));
    dispatch(getCurrentFinancialMetricsByPropertyIdAction(selectedProperty.value?.id));
  }, [selectedProperty.value?.id]);

  useEffect(() => {
    if (!selectedProperty.value?.id) return;
    dispatch(
      getHistoricalOccupancyMetricsByPropertyIdAction({
        propertyId: selectedProperty.value.id,
        body: {
          rangeStart: toReduxDate(startDate) ?? undefined,
          rangeEnd: toReduxDate(endDate) ?? undefined,
        },
      }),
    );
    dispatch(
      getHistoricalFinancialMetricsByPropertyIdAction({
        propertyId: selectedProperty.value.id,
        body: {
          rangeStart: toReduxDate(startDate) ?? undefined,
          rangeEnd: toReduxDate(endDate) ?? undefined,
        },
      }),
    );
  }, [startDate, endDate]);

  const formatChartData = () => {
    const requiredOccupancyMetrics: { [key: string]: Required<IOccupancyMetrics> } = {};
    const requiredFinancialMetrics: { [key: string]: Required<IFinancialMetrics> } = {};

    historicalFinancialMetrics.value?.dailyMetrics &&
      Object.entries(historicalFinancialMetrics.value?.dailyMetrics).forEach(
        ([key, value]) => (requiredFinancialMetrics[key] = makeRequired(value, 0)),
      );

    historicalOccupancyMetrics.value?.dailyMetrics &&
      Object.entries(historicalOccupancyMetrics.value?.dailyMetrics).forEach(
        ([key, value]) => (requiredOccupancyMetrics[key] = makeRequired(value, 0)),
      );

    setHistoricalFinancialData(HistoricalFinancialData.createTableData(dateArray, requiredFinancialMetrics));

    setHistoricalOccupancyData(HistoricalOccupancyData.createTableData(dateArray, requiredOccupancyMetrics));

    setHistoricalLeaseStatusData(HistoricalLeaseStatusData.createTableData(dateArray, requiredOccupancyMetrics));
  };

  useEffect(formatChartData, [historicalFinancialMetrics, historicalOccupancyMetrics]);

  const handleSwitchToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowHistorical(event.target.checked);
  };

  return (
    <Stack>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: showHistorical ? 'space-between' : 'right',
          alignItems: 'center',
          minHeight: '3.6rem',
        }}
      >
        {showHistorical && (
          <>
            <DatePicker
              onChange={(date) => setStartDate(date)}
              value={startDate}
              label={'After'}
              slotProps={{ textField: { size: 'small', sx: { px: '1rem' } } }}
              maxDate={new Date(selectedProperty.value?.currentPropertyDate ?? '')}
            />
            <Typography sx={{ pt: '2rem' }}>to</Typography>
            <DatePicker
              onChange={(date) => setEndDate(date)}
              value={endDate}
              label={'Before'}
              slotProps={{ textField: { size: 'small', sx: { px: '1rem' } } }}
              maxDate={parse(
                selectedProperty.value?.currentPropertyDate ?? toReduxDate(new Date()),
                'yyyy-MM-dd',
                new Date(),
              )}
              minDate={startDate ? addDays(new Date(startDate), 1) : undefined}
            />
          </>
        )}
        <FormGroup>
          <FormControlLabel
            control={<Switch size="small" checked={showHistorical} onChange={handleSwitchToggle} />}
            label="Show Historical Data"
          />
        </FormGroup>
      </Box>
      <StyledInfoBox label={'Financial Metrics'}>
        {showHistorical ? (
          <HistoricalFinancialMetrics financialMetrics={historicalFinancialData} />
        ) : (
          <CurrentFinancialMetrics />
        )}
      </StyledInfoBox>
      <StyledInfoBox label={'Occupancy Metrics'}>
        {showHistorical ? (
          <HistoricalOccupancyMetrics occupancyData={historicalOccupancyData} />
        ) : (
          <CurrentOccupancyMetrics />
        )}
      </StyledInfoBox>
      <StyledInfoBox label={'Lease Status Metrics'}>
        {showHistorical ? (
          <HistoricalLeaseStatusMetrics leaseStatusData={historicalLeaseStatusData} />
        ) : (
          <CurrentLeaseStatusMetrics />
        )}
      </StyledInfoBox>
    </Stack>
  );
};
