import React, { FC, FormEvent, useState } from 'react';
import { IUnitTemplate } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { DialogLayout } from '../../dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch } from 'react-redux';
import { addUnitTemplateAction } from '../redux/unitTemplateSlice';
import { UnitTemplateInfoForm } from './UnitTemplateInfoForm';

export type AddUnitTemplateDialogProps = {
  open: boolean;
  onClose: () => void;
  propertyId: string;
  buildingId?: string;
};

export const AddUnitTemplateDialog: FC<AddUnitTemplateDialogProps> = ({ open, onClose, propertyId, buildingId }) => {
  const dispatch = useDispatch();
  const [templateToAdd, setTemplateToAdd] = useState<IUnitTemplate>({});

  const handleClose = () => {
    onClose();
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    templateToAdd.name &&
      dispatch(
        addUnitTemplateAction({
          propertyId: propertyId,
          buildingId: buildingId,
          name: templateToAdd.name,
          info: templateToAdd.info,
        }),
      );
    onClose();
  };

  return (
    <DialogLayout title={'Add Unit Template'} onClose={handleClose} open={open}>
      <DialogContent>
        <form onSubmit={onSubmit} autoComplete="off">
          <UnitTemplateInfoForm
            editingTemplate={templateToAdd}
            onEditTemplate={(template) => setTemplateToAdd(template)}
          />
        </form>
      </DialogContent>
    </DialogLayout>
  );
};
