import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { okToastMessageAction, useGlobal } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { appTheme } from '../../../../AppTheme';
import { ErrorDialog } from './ErrorDialog';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const GlobalSnackbar = () => {
  const global = useGlobal();
  const dispatch = useDispatch();
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const handleCloseErrorDialog = () => setDetailsDialogOpen(false);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway' && global.toast.severity === 'error') {
      return;
    }
    dispatch(okToastMessageAction());
  };

  return (
    <div>
      <ErrorDialog open={detailsDialogOpen} onClose={handleCloseErrorDialog} />
      <Snackbar
        open={global.toast.isVisible}
        autoHideDuration={global.toast.severity === 'error' ? 10000 : 4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={global.toast.severity} sx={{ width: '100%' }}>
          <Stack direction={'row'} spacing={4}>
            <Typography>{global.toast.message}</Typography>
            {global.toast.additionalDetails && (
              <Button
                sx={{
                  lineHeight: 0,
                  '&:hover': { backgroundColor: appTheme.palette.error.light, color: 'white' },
                }}
                color="inherit"
                size="small"
                onClick={() => setDetailsDialogOpen(true)}
              >
                DETAILS
              </Button>
            )}
          </Stack>
        </Alert>
      </Snackbar>
    </div>
  );
};
