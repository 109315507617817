import React, { FC } from 'react';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { LeaseTermsEditor } from '../../../_shared/leases/components/LeaseTermsEditor';
import { ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import DialogContentText from '@mui/material/DialogContentText';

type LeaseDetailsDialogProps = {
  open: boolean;
  onClose: () => void;
  selectedLease?: ReduxLease;
};

export const LeaseDetailsDialog: FC<LeaseDetailsDialogProps> = ({ open, onClose, selectedLease }) => {
  return (
    <DialogLayout
      onClose={onClose}
      title={'Lease Terms Details'}
      open={open}
      aria-labelledby="lease-terms-details-title"
    >
      <DialogContent>
        {selectedLease?.leaseTerms ? (
          <LeaseTermsEditor value={selectedLease.leaseTerms} />
        ) : (
          <DialogContentText>Could not find lease terms.</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} variant={'outlined'}>
          Back
        </Button>
      </DialogActions>
    </DialogLayout>
  );
};
