import { Grid, Stack } from '@mui/material';
import React, { FC } from 'react';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import { IUnitTemplate } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

export type UnitTemplateInfoFormProps = {
  editingTemplate: IUnitTemplate;
  onEditTemplate: (template: IUnitTemplate) => void;
};

export const UnitTemplateInfoForm: FC<UnitTemplateInfoFormProps> = ({ editingTemplate, onEditTemplate }) => {
  return (
    <Stack>
      <StyledInfoBox label={'Info'}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              fullWidth
              required
              variant={'outlined'}
              label={'Name'}
              value={editingTemplate.name ?? ''}
              onChange={(e) => onEditTemplate({ ...editingTemplate, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              variant={'outlined'}
              label={'Bedrooms'}
              type={'number'}
              value={editingTemplate.info?.bedrooms ?? 0}
              onChange={(e) =>
                onEditTemplate({
                  ...editingTemplate,
                  info: { ...editingTemplate.info, bedrooms: Number(e.target.value) },
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              variant={'outlined'}
              label={'Bathrooms'}
              type={'number'}
              value={editingTemplate.info?.bathrooms ?? 0}
              onChange={(e) =>
                onEditTemplate({
                  ...editingTemplate,
                  info: { ...editingTemplate.info, bathrooms: Number(e.target.value) },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              variant={'outlined'}
              label={'Square Footage'}
              type={'number'}
              value={editingTemplate.info?.squareFootage ?? 0}
              onChange={(e) =>
                onEditTemplate({
                  ...editingTemplate,
                  info: { ...editingTemplate.info, squareFootage: Number(e.target.value) },
                })
              }
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <LoadingButton type={'submit'} variant={'contained'} loading={false} disabled={false}>
            Save
          </LoadingButton>
        </Box>
      </StyledInfoBox>
    </Stack>
  );
};
