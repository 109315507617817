import React, { FC, useEffect, useState } from 'react';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import Button from '@mui/material/Button';
import { useIcons } from '../../../../../_shared/icons/useIcons';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { DialogLayout } from '../../../../../_shared/dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import Typography from '@mui/material/Typography';
import {
  addCreditCardAction,
  removeCreditCardAction,
  updateCreditCardAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { useDispatch } from 'react-redux';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { CreditCardList } from './CreditCardList';

type CreditCardsProps = {
  onViewStatement: (linkedAccountId?: string) => void;
};
export const CreditCards: FC<CreditCardsProps> = ({ onViewStatement }) => {
  const dispatch = useDispatch();
  const { getActionIcon, ActionType } = useIcons();
  const { selectedProperty } = useProperty();
  const [open, setOpen] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);
  const [nameOnCard, setNameOnCard] = useState('');
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    selectedProperty.submitted && handleClose();
  }, [selectedProperty.submitted]);

  const handleClose = () => {
    setOpen(false);
    setCardNumber('');
    setExpirationDate(null);
    setNameOnCard('');
    setDirty(false);
  };

  const handleViewStatement = (ccId: string) => {
    const card = selectedProperty.value?.creditCards?.find((card) => card.creditCard.id === ccId);
    if (selectedProperty?.value) {
      const linkedAccountId = card?.linkedGlAccount?.id;
      onViewStatement && onViewStatement(linkedAccountId);
    }
  };

  const handleOpenEdit = (cardId: string) => {
    const card = selectedProperty.value?.creditCards?.find((card) => card.creditCard.id === cardId);
    if (card) {
      setCardNumber(card.creditCard.cardNumberLastFour ?? '');
      setExpirationDate(card.creditCard.expirationDate ? toStandardDate(card.creditCard.expirationDate) : null);
      setNameOnCard(card.creditCard.nameOnCard ?? '');
      setSelectedCard(cardId);
      setOpen(true);
    }
  };

  const handleDelete = (cardId: string) => {
    selectedProperty.value?.id &&
      dispatch(removeCreditCardAction({ propertyId: selectedProperty.value.id, cardId: cardId }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!selectedProperty.value?.id || !expirationDate) return;
    if (selectedCard) {
      dispatch(
        updateCreditCardAction({
          propertyId: selectedProperty.value.id,
          ccId: selectedCard,
          body: {
            expirationDate: toReduxDate(expirationDate),
            nameOnCard: nameOnCard,
          },
        }),
      );
    } else
      dispatch(
        addCreditCardAction({
          propertyId: selectedProperty.value.id,
          body: {
            cardNumberLastFour: cardNumber,
            expirationDate: toReduxDate(expirationDate),
            nameOnCard: nameOnCard,
          },
        }),
      );
  };

  return (
    <StyledInfoBox label={'Credit Cards'}>
      <Stack spacing={2}>
        <Box>
          <Button variant={'outlined'} startIcon={getActionIcon(ActionType.Add)} onClick={() => setOpen(true)}>
            Add Credit Card
          </Button>
        </Box>
        {selectedProperty.value?.creditCards ? (
          <CreditCardList
            cards={selectedProperty.value.creditCards.map((card) => card.creditCard)}
            onOpenUpdate={handleOpenEdit}
            onRemoveCard={handleDelete}
            loading={selectedProperty.submitting}
            onViewStatement={handleViewStatement}
          />
        ) : (
          <Typography>No credit cards added</Typography>
        )}
      </Stack>
      <DialogLayout title={selectedCard ? 'Update Card' : 'Add Credit Card'} onClose={handleClose} open={open}>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Stack spacing={2}>
              <TextField
                label={'Card Number (Last 4)'}
                helperText={selectedCard ? 'Cannot update card number' : 'Last 4 digits of the card number'}
                required
                inputProps={{ maxLength: 4 }}
                InputProps={{ readOnly: !!selectedCard }}
                type={'number'}
                value={cardNumber}
                onChange={(e) => {
                  if (e.target.value.length > 4) return;
                  setCardNumber(e.target.value);
                  setDirty(true);
                }}
              />
              <DatePicker
                label={'Expiration Date'}
                slotProps={{ textField: { required: true } }}
                value={expirationDate}
                onChange={(date) => {
                  setExpirationDate(date);
                  setDirty(true);
                }}
              />
              <TextField
                label={'Name on Card'}
                required
                value={nameOnCard}
                onChange={(e) => {
                  setNameOnCard(e.target.value);
                  setDirty(true);
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <LoadingButton
              variant={'contained'}
              type={'submit'}
              loading={selectedProperty.submitting}
              disabled={!dirty}
            >
              {selectedCard ? 'Update Card' : 'Add Credit Card'}
            </LoadingButton>
          </DialogActions>
        </form>
      </DialogLayout>
    </StyledInfoBox>
  );
};
