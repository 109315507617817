import React, { FC } from 'react';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import Popper from '@mui/material/Popper';

type SearchMenuPopperProps = {
  anchorRef: React.RefObject<HTMLDivElement>;
  open: boolean;
  includeClosed: boolean;
  onChangeIncludeClosed: () => void;
  onClose: (event: Event) => void;
  global: boolean;
  limitToSelectedProperty: boolean;
  onSetLimitToProperty: () => void;
};

export const SearchMenuPopper: FC<SearchMenuPopperProps> = ({
  anchorRef,
  open,
  includeClosed,
  onClose,
  onChangeIncludeClosed,
  global,
  limitToSelectedProperty,
  onSetLimitToProperty,
}) => {
  return (
    <Popper
      sx={{
        zIndex: 999999,
      }}
      open={open}
      anchorEl={anchorRef?.current}
      role={undefined}
      transition
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList id="autocomplete-options-menu" autoFocusItem>
                <MenuItem
                  key={'includeClosed'}
                  onClick={() => {
                    onChangeIncludeClosed();
                  }}
                >
                  Include Closed <Box>{includeClosed && <CheckIcon />}</Box>
                </MenuItem>
                {global && (
                  <MenuItem key={'selectedProperty'} onClick={() => onSetLimitToProperty()}>
                    Limit to Selected Property <Box>{limitToSelectedProperty && <CheckIcon />}</Box>
                  </MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
