import {
  IListing,
  PropertyAdvertisingDto,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  mapReduxDocument,
  ReduxDate,
  ReduxDocument,
  toReduxDate,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

export type ReduxPropertyAdvertisingDto = Omit<PropertyAdvertisingDto, 'logo'> & {
  logo: ReduxDocument;
};

export type ReduxListing = Omit<IListing, 'photos' | 'dateAvailable'> & {
  dateAvailable?: ReduxDate;
  photos?: ReduxDocument[];
};

export const mapReduxListing = (listing: IListing): ReduxListing => {
  return {
    ...listing,
    dateAvailable: listing.dateAvailable ? toReduxDate(listing.dateAvailable) : undefined,
    photos: listing.photos ? listing.photos.map((photo) => mapReduxDocument(photo)) : [],
  };
};
