import React, { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';

type AuthorizedProps = {
  trueUser?: boolean;
  role?: string | string[];
  children?: React.ReactNode;
};

export const Authorized: FC<AuthorizedProps> = ({ children, trueUser, role }) => {
  const { isAuthenticated } = useAuth0();
  const userInfo = useUser((u) => (trueUser ? u.currentUser.value?.trueUser : u.currentUser.value));

  if (isAuthenticated) {
    if (!role) return <>{children}</>;
    if (
      userInfo &&
      (userInfo.roles?.some((r) => r === role) ||
        (Array.isArray(role) && userInfo.roles?.some((r) => role.includes(r))))
    ) {
      return <>{children}</>;
    }
  }
  return <></>;
};
