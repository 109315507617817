import React, { FC } from 'react';
import { useTenant } from '../../redux/tenantSlice';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { ListItem } from '@mui/material';
import Card from '@mui/material/Card';
import { TaskStatusMap } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { tryFormatDate } from '../../../_shared/utils/TryFormatDate';

type MaintenanceRequestListProps = {
  onOpenEdit: (requestId: string) => void;
};

export const MaintenanceRequestList: FC<MaintenanceRequestListProps> = ({ onOpenEdit }) => {
  const { maintenanceRequests } = useTenant();

  return (
    <Box>
      {!maintenanceRequests.value ||
        (maintenanceRequests.value.length === 0 && <Typography>You have no maintenance requests</Typography>)}
      <List sx={{ width: '100%', height: '90%', backgroundColor: 'background.paper' }}>
        {maintenanceRequests.value?.map((request) => {
          return (
            <ListItem key={request.id} sx={{ display: 'flex', flexGrow: 1 }}>
              <Card sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
                <CardContent>
                  <Typography>{request.name}</Typography>
                  {request.timeConfiguration && (
                    <Typography>
                      {`Anticipated Start Date: 
                      ${tryFormatDate(request.timeConfiguration?.startDateTime ?? '')}`}
                    </Typography>
                  )}
                  <Typography variant={'body2'} fontStyle={'italic'}>
                    Status: {request.status ? TaskStatusMap.get(request.status) : 'Unknown'}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Tooltip title={'View Request Details'}>
                    <IconButton onClick={() => request.id && onOpenEdit(request.id)}>
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </Card>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};
