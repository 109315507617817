import React, { FC, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { SearchMenuIcon } from './SearchMenuIcon';
import { AutocompleteRenderInputParams } from '@mui/material';
import { SearchMenuPopper } from './SearchMenuPopper';
import { useSearchHook } from '../useSearchHook';

type SearchAutocompleteTextFieldProps = {
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  includeClosed: boolean;
  onChangeIncludeClosed: () => void;
  global: boolean;
  limitToSelectedProperty: boolean;
  onSetLimitToProperty: () => void;
  placeholder?: string;
} & AutocompleteRenderInputParams;

export const SearchAutocompleteTextField: FC<SearchAutocompleteTextFieldProps> = ({
  onSearch,
  includeClosed,
  onChangeIncludeClosed,
  global,
  onSetLimitToProperty,
  limitToSelectedProperty,
  placeholder,
  ...params
}) => {
  const { searchStyles } = useSearchHook();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const handleChangeIncludeClosed = () => {
    onChangeIncludeClosed();
    setOpen(false);
  };

  const handleChangeLimitToProperty = () => {
    onSetLimitToProperty();
    setOpen(false);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <TextField
        {...params}
        placeholder={placeholder}
        inputProps={{ ...params.inputProps, 'aria-label': 'search' }}
        onChange={onSearch}
        variant={'outlined'}
        size={'small'}
        sx={searchStyles}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <SearchMenuIcon
              onSelect={() => {
                setOpen((prevOpen) => !prevOpen);
              }}
              anchorRef={anchorRef}
              badgeContent={includeClosed ? 'IC' : undefined}
              secondaryBadgeContent={limitToSelectedProperty ? 'P' : undefined}
            />
          ),
        }}
      />
      <SearchMenuPopper
        includeClosed={includeClosed}
        onChangeIncludeClosed={handleChangeIncludeClosed}
        onClose={handleClose}
        open={open}
        anchorRef={anchorRef}
        global={global}
        limitToSelectedProperty={limitToSelectedProperty}
        onSetLimitToProperty={handleChangeLimitToProperty}
      />
    </>
  );
};
