import React, { FC, useEffect } from 'react';
import { ReduxTimeConfiguration } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import Grid from '@mui/material/Grid';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers-pro';

type ReadOnlyTimeConfigurationProps = {
  timeConfiguration: ReduxTimeConfiguration;
};

export const ReadOnlyTimeConfiguration: FC<ReadOnlyTimeConfigurationProps> = ({ timeConfiguration }) => {
  const { isAllDay, startDateTime, endDateTime } = timeConfiguration;
  const [startDate, setStartDate] = React.useState<Date | null>(startDateTime ? new Date(startDateTime) : null);
  const [endDate, setEndDate] = React.useState<Date | null>(endDateTime ? new Date(endDateTime) : null);
  useEffect(() => {
    setStartDate(startDateTime ? new Date(startDateTime) : null);
  }, [startDateTime]);
  useEffect(() => {
    setEndDate(endDateTime ? new Date(endDateTime) : null);
  }, [endDateTime]);

  return (
    <Grid container spacing={2}>
      {isAllDay ? (
        <Grid item xs={12}>
          <DatePicker
            label={'Anticipated Start Date'}
            readOnly
            onChange={() => {}}
            value={startDate}
            slotProps={{ textField: { fullWidth: true, required: true } }}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <DateTimePicker
              label={'Scheduled Start'}
              readOnly={true}
              onChange={() => {}}
              value={startDate}
              slotProps={{ textField: { fullWidth: true, required: true } }}
            />
          </Grid>
          <Grid item xs={12}>
            <DateTimePicker
              label={'Scheduled End'}
              readOnly={true}
              onChange={() => {}}
              value={endDate}
              slotProps={{ textField: { fullWidth: true, required: true } }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
