import React, { FormEvent, useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import {
  addWatcherToApplicationAction,
  getSingleApplicationByIdAction,
  removeWatcherFromApplicationAction,
  resetApplicationSubmissionAction,
  unsetSelectedApplication,
  updateApplicationAction,
  useApplications,
} from '../../../redux/applicationSlice';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { ApplicantInfo } from '../applicantForms/applicantInfo/ApplicantInfo';
import { ApplicationComments } from '../applicantForms/comments/ApplicationComments';
import { OtherAdults } from '../applicantForms/otherAdults/OtherAdults';
import { Cosigner } from '../applicantForms/cosigner/Cosigner';
import { ApplicationDocuments } from '../applicantForms/documents/ApplicationDocuments';
import { BackgroundCheck } from '../applicantForms/backgroundCheck/BackgroundCheck';
import {
  ApplicationType,
  AssociationType,
  IContactInfo,
  IContactPerson,
  IEmployment,
  IPerson,
  IPet,
  IPostalAddress,
  IVehicle,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { OtherInfo } from '../applicantForms/otherInfo/OtherInfo';
import { useDispatch } from 'react-redux';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { AddOtherAdultDialog } from '../applicantForms/otherAdults/AddOtherAdultDialog';
import { AddVehicleDialog } from '../applicantForms/otherInfo/AddVehicleDialog';
import { AddPetDialog } from '../applicantForms/otherInfo/AddPetDialog';
import { AddChildDialog } from '../applicantForms/otherInfo/AddChildDialog';
import { AddCosignerDialog } from '../applicantForms/cosigner/AddCosignerDialog';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate, useParams } from 'react-router-dom';
import { AddHousingHistoryDialog } from '../applicantForms/employmentAndHistory/AddHousingHistoryDialog';
import { EmploymentAndHistory } from '../applicantForms/employmentAndHistory/EmploymentAndHistory';
import { ReduxHousingHistory } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { ReduxApplication } from '../../../redux/applicationTypes';
import LoadingButton from '@mui/lab/LoadingButton';
import { getAssetTypeFromPathname } from '../../../../_shared/utils/getAssetTypeFromPathname';
import { DialogLayout } from '../../../../_shared/dialogs/DialogLayout';
import { ApplicationDialogTab } from '../../../../_shared/tabs/TabTypeEnums';
import { Emails } from '../../../../_shared/communications/components/Emails';
import { SmsView } from '../../../../_shared/communications/components/SmsView';
import { useRouting } from '../../../../_shared/utils/useRouting';
import {
  attachmentAddedToAssociationAction,
  useCommunication,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/communications/communicationSlice';
import { StyledInfoBox } from '../../../../_shared/styledComponents/StyledInfoBox';
import { WatcherAutocomplete } from '../../../../_shared/tasks/components/WatcherAutocomplete';

type ApplicationTabParams = {
  id: string;
  outerTab: string;
  innerTab: string;
  applicantId: string;
  tab: ApplicationDialogTab;
};

export const ApplicationDialog = () => {
  const dispatch = useDispatch();
  const applications = useApplications();
  const navigate = useNavigate();
  const { attachmentAddedToAssociation } = useCommunication();
  const { handleNavigateFromAssociationClose } = useRouting();
  const { id, innerTab, outerTab, applicantId, tab } = useParams<ApplicationTabParams>();
  const [tabValue, setTabValue] = React.useState(tab ?? ApplicationDialogTab.Info);
  const [dirty, setDirty] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [editingApplicant, setEditingApplicant] = useState<ReduxApplication | undefined>(undefined);
  const columnVisibility = { unitRequested: false, requestedMoveInDate: false, dateSubmitted: false };
  const [openAddOtherAdult, setOpenAddOtherAdult] = useState(false);
  const [openAddCosigner, setOpenAddCosigner] = useState(false);
  const [openAddChild, setOpenAddChild] = useState(false);
  const [openAddPet, setOpenAddPet] = useState(false);
  const [openAddHousingHistory, setOpenAddHousingHistory] = useState(false);
  const [openAddVehicle, setOpenAddVehicle] = useState(false);

  useEffect(() => {
    applicantId && dispatch(getSingleApplicationByIdAction(applicantId));
  }, [applicantId]);

  useEffect(() => {
    if (applications.selectedApplication?.value) setEditingApplicant(applications.selectedApplication.value);
  }, [applications.selectedApplication.value]);

  useEffect(() => {
    if (applications.selectedApplication.value?.id !== editingApplicant?.id) {
      setTabValue(tab ?? ApplicationDialogTab.Info);
      setEditingApplicant(applications.selectedApplication.value);
    }
  }, [applications.selectedApplication.value]);

  useEffect(() => {
    setEditingApplicant(applications.selectedApplication.value);
    setDirty(false);
    setIsValid(true);
    if (applications.selectedApplication.value?.id) {
      navigate(`/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/application/${applicantId}/${tab}`);
    }
  }, [tab]);

  useEffect(() => {
    if (attachmentAddedToAssociation === AssociationType.Application) {
      applications.selectedApplication.value?.id &&
        dispatch(getSingleApplicationByIdAction(applications.selectedApplication.value?.id));
      dispatch(attachmentAddedToAssociationAction(undefined));
    }
  }, [attachmentAddedToAssociation]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: ApplicationDialogTab) => {
    setTabValue(newValue);
    if (dirty) {
      editingApplicant &&
        editingApplicant.id &&
        editingApplicant.applicationType &&
        dispatch(
          updateApplicationAction({
            id: editingApplicant.id,
            type: editingApplicant.applicationType,
            body: editingApplicant,
            propertyId: id,
          }),
        );
      setDirty(false);
    }
  };

  const handleClose = () => {
    dispatch(unsetSelectedApplication());
    handleNavigateFromAssociationClose();
  };

  const handleOtherAdultOpen = () => {
    dispatch(resetApplicationSubmissionAction());
    setOpenAddOtherAdult(true);
  };

  const handleOtherAdultClose = () => {
    setOpenAddOtherAdult(false);
  };

  const handleAddCosignerOpen = () => {
    dispatch(resetApplicationSubmissionAction());
    setOpenAddCosigner(true);
  };

  const handleAddCosignerClose = () => {
    setOpenAddCosigner(false);
  };

  const handleAddChildOpen = () => {
    dispatch(resetApplicationSubmissionAction());
    setOpenAddChild(true);
  };

  const handleAddChildClose = () => {
    setOpenAddChild(false);
  };

  const handleAddPetOpen = () => {
    dispatch(resetApplicationSubmissionAction());
    setOpenAddPet(true);
  };

  const handleAddPetClose = () => {
    setOpenAddPet(false);
  };

  const handleAddVehicleOpen = () => {
    dispatch(resetApplicationSubmissionAction());
    setOpenAddVehicle(true);
  };

  const handleAddVehicleClose = () => {
    setOpenAddVehicle(false);
  };

  const handleAddHousingHistoryOpen = () => {
    dispatch(resetApplicationSubmissionAction());
    setOpenAddHousingHistory(true);
  };

  const handleAddHousingHistoryClose = () => {
    setOpenAddHousingHistory(false);
  };

  const updateApplicantDetails = () => {
    if (dirty) {
      editingApplicant &&
        editingApplicant.id &&
        editingApplicant.applicationType &&
        dispatch(
          updateApplicationAction({
            id: editingApplicant.id,
            type: editingApplicant.applicationType,
            body: editingApplicant,
            propertyId: id,
          }),
        );
    }
    setDirty(false);
  };

  const submitApplicantDetails = (e: FormEvent) => {
    e.preventDefault();
    updateApplicantDetails();
  };

  const handleUpdateApplicant = (key: keyof ReduxApplication, value?: string | boolean) => {
    setEditingApplicant({ ...editingApplicant, [key]: value });
    setDirty(true);
  };

  const handleUpdateContactInfo = (key: keyof IContactInfo, value?: string) => {
    setEditingApplicant({ ...editingApplicant, contactInfo: { ...editingApplicant?.contactInfo, [key]: value } });
    setDirty(true);
  };

  const handleUpdateAddress = (key: keyof IPostalAddress, value?: string) => {
    setEditingApplicant({
      ...editingApplicant,
      contactInfo: {
        ...editingApplicant?.contactInfo,
        postalAddress: { ...editingApplicant?.contactInfo?.postalAddress, [key]: value },
      },
    });
    setDirty(true);
  };

  const handleUpdateEmergencyContact = (key: keyof IContactPerson, value?: string) => {
    setEditingApplicant({
      ...editingApplicant,
      emergencyContact: { ...editingApplicant?.emergencyContact, [key]: value },
    });
    setDirty(true);
  };

  const handleUpdateEmergencyContactInfo = (key: keyof IContactInfo, value?: string) => {
    setEditingApplicant({
      ...editingApplicant,
      emergencyContact: {
        ...editingApplicant?.emergencyContact,
        contactInfo: { ...editingApplicant?.emergencyContact?.contactInfo, [key]: value },
      },
    });
    setDirty(true);
  };

  const handleUpdateEmployment = (key: keyof IEmployment, value: string | boolean) => {
    setEditingApplicant({
      ...editingApplicant,
      employer: {
        ...editingApplicant?.employer,
        [key]: value,
      },
    });
    setDirty(true);
  };

  const handleUpdateEmployerContact = (key: keyof IContactInfo, value: string) => {
    setEditingApplicant({
      ...editingApplicant,
      employer: {
        ...editingApplicant?.employer,
        contactInfo: {
          ...editingApplicant?.employer?.contactInfo,
          [key]: value,
        },
      },
    });
    setDirty(true);
  };

  const handleUpdateEmployerAddress = (key: keyof IPostalAddress, value: string) => {
    setEditingApplicant({
      ...editingApplicant,
      employer: {
        ...editingApplicant?.employer,
        contactInfo: {
          ...editingApplicant?.employer?.contactInfo,
          postalAddress: {
            ...editingApplicant?.employer?.contactInfo?.postalAddress,
            [key]: value,
          },
        },
      },
    });
    setDirty(true);
  };

  const handleAddDependent = (type: string, newDependent: IPet | IPerson | IVehicle | ReduxHousingHistory) => {
    let updatedDependents;
    if (type === 'childrenInApartment') {
      if (editingApplicant?.childrenInApartment) {
        updatedDependents = [...editingApplicant?.childrenInApartment, newDependent];
      } else {
        updatedDependents = [newDependent];
      }
    } else if (type === 'pets') {
      if (editingApplicant?.pets) {
        updatedDependents = [...editingApplicant?.pets, newDependent];
      } else {
        updatedDependents = [newDependent];
      }
    } else if (type === 'vehicles') {
      if (editingApplicant?.vehicles) {
        updatedDependents = [...editingApplicant?.vehicles, newDependent];
      } else updatedDependents = [newDependent];
    } else if (type === 'housingHistory') {
      if (editingApplicant?.housingHistory) {
        updatedDependents = [...editingApplicant?.housingHistory, newDependent];
      } else updatedDependents = [newDependent];
    }
    editingApplicant?.id &&
      editingApplicant?.applicationType &&
      dispatch(
        updateApplicationAction({
          id: editingApplicant?.id,
          type: editingApplicant?.applicationType,
          body: { [type]: updatedDependents },
        }),
      );
  };

  const handleUpdateWatchers = (updatedWatchers: string[]) => {
    const watchers = editingApplicant?.watchers?.map((x) => x.id!) ?? [];
    const addUser = updatedWatchers.filter((watcher) => !watchers.some((w) => w === watcher));
    if (addUser.length === 1) {
      dispatch(
        addWatcherToApplicationAction({
          applicationId: editingApplicant?.id ?? '',
          userId: addUser[0],
        }),
      );
    }
    const removeUser = watchers.filter((watcher) => !updatedWatchers.some((w) => w === watcher));
    if (removeUser.length === 1) {
      dispatch(
        removeWatcherFromApplicationAction({
          applicationId: editingApplicant?.id ?? '',
          userId: removeUser[0],
        }),
      );
    }
  };

  const applicantName = `${editingApplicant?.firstName} ${editingApplicant?.lastName}`;
  const applicantType =
    editingApplicant?.applicationType === ApplicationType.CoSigner
      ? 'Cosigner Application'
      : editingApplicant?.applicationType === ApplicationType.OtherAdult
        ? `Other Adult Application`
        : `Primary Application`;

  //TODO: how to breadcrumb in this dialog when clicking into otherAdult or Cosigner applicant tables

  if (editingApplicant?.id) {
    return (
      <DialogLayout
        title={`${applicantType}: ${applicantName}`}
        maxWidth="lg"
        fullWidth
        fullScreen
        open={applications.selectedApplication.value ? true : false}
        onClose={handleClose}
      >
        <DialogContent sx={{ mt: '.1rem' }}>
          <TabContext value={tabValue}>
            <form onSubmit={submitApplicantDetails} autoComplete="off">
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleTabChange} aria-label="application tabs" variant="scrollable">
                    <Tab label="Applicant" value="info" />
                    {editingApplicant.applicationType === ApplicationType.Primary
                      ? [
                          <Tab key="otherAdults" label="Other Adults" value={ApplicationDialogTab.OtherAdults} />,
                          <Tab key="cosigner" label="Cosigner" value={ApplicationDialogTab.CoSigner} />,
                          <Tab key="personal" label="Other Info" value={ApplicationDialogTab.Personal} />,
                          <Tab key="history" label="Employment/Housing" value={ApplicationDialogTab.History} />,
                        ]
                      : editingApplicant.applicationType === ApplicationType.OtherAdult
                        ? [
                            <Tab key="personal" label="Other Info" value={ApplicationDialogTab.Personal} />,
                            <Tab key="history" label="Employment" value={ApplicationDialogTab.History} />,
                          ]
                        : [<Tab key="history" label="Employment/Housing" value={ApplicationDialogTab.History} />]}
                    <Tab label="Documents" value={ApplicationDialogTab.Documents} />
                    <Tab label="Background Check" value={ApplicationDialogTab.BackgroundCheck} />
                    <Tab label="Communication" value={ApplicationDialogTab.Communications} />
                    <Tab label="Comments" value={ApplicationDialogTab.Comments} />
                  </TabList>
                </Box>
                {applications.selectedApplication.loading && <LinearProgress variant="indeterminate" />}
                <TabPanel value={ApplicationDialogTab.Info}>
                  <ApplicantInfo
                    editingApplicant={editingApplicant}
                    onUpdateApplicant={handleUpdateApplicant}
                    onUpdateContactInfo={handleUpdateContactInfo}
                    onUpdateAddress={handleUpdateAddress}
                    onUpdateEmergencyContact={handleUpdateEmergencyContact}
                    onUpdateEmergencyContactInfo={handleUpdateEmergencyContactInfo}
                    updateApplicantDetails={updateApplicantDetails}
                  />
                  <StyledInfoBox label={'Watchers'}>
                    <WatcherAutocomplete
                      watchers={editingApplicant.watchers?.map((x) => x.id!) ?? []}
                      setWatchers={(updatedWatchers) => handleUpdateWatchers(updatedWatchers)}
                    />
                  </StyledInfoBox>
                </TabPanel>
                {editingApplicant.applicationType === ApplicationType.Primary ? (
                  <>
                    {editingApplicant.id && applications.otherAdultApplications ? (
                      <TabPanel value="otherAdults">
                        <OtherAdults
                          rows={applications.otherAdultApplications[editingApplicant.id] ?? []}
                          applicant={editingApplicant}
                          columnVisibility={columnVisibility}
                          onOpen={handleOtherAdultOpen}
                        />
                      </TabPanel>
                    ) : (
                      <TabPanel value={ApplicationDialogTab.OtherAdults}>
                        <OtherAdults
                          rows={[]}
                          applicant={editingApplicant}
                          columnVisibility={columnVisibility}
                          onOpen={handleOtherAdultOpen}
                        />
                      </TabPanel>
                    )}
                    {editingApplicant.id && applications.cosignerApplications ? (
                      <TabPanel value={ApplicationDialogTab.CoSigner}>
                        <Cosigner
                          rows={applications.cosignerApplications[editingApplicant.id]}
                          applicant={editingApplicant}
                          columnVisibility={columnVisibility}
                          onOpen={handleAddCosignerOpen}
                        />
                      </TabPanel>
                    ) : (
                      <TabPanel value={ApplicationDialogTab.CoSigner}>
                        <Cosigner
                          rows={[]}
                          applicant={editingApplicant}
                          columnVisibility={columnVisibility}
                          onOpen={handleAddCosignerOpen}
                        />
                      </TabPanel>
                    )}
                    <TabPanel value={ApplicationDialogTab.Personal}>
                      <OtherInfo
                        applicant={editingApplicant}
                        onOpenAddPet={handleAddPetOpen}
                        onOpenAddVehicle={handleAddVehicleOpen}
                        onOpenAddChild={handleAddChildOpen}
                      />
                    </TabPanel>
                  </>
                ) : editingApplicant.applicationType === ApplicationType.OtherAdult ? (
                  <TabPanel value={ApplicationDialogTab.Personal}>
                    <OtherInfo
                      applicant={editingApplicant}
                      onOpenAddPet={handleAddPetOpen}
                      onOpenAddVehicle={handleAddVehicleOpen}
                      onOpenAddChild={handleAddChildOpen}
                    />
                  </TabPanel>
                ) : (
                  <></>
                )}
                <TabPanel value={ApplicationDialogTab.History}>
                  <EmploymentAndHistory
                    applicant={editingApplicant}
                    onUpdateEmployment={handleUpdateEmployment}
                    onUpdateEmployerContact={handleUpdateEmployerContact}
                    onUpdateEmployerAddress={handleUpdateEmployerAddress}
                    onUpdateApplicant={handleUpdateApplicant}
                    onOpenAddHousingHistory={handleAddHousingHistoryOpen}
                    updateApplicantDetails={updateApplicantDetails}
                  />
                </TabPanel>
                <TabPanel value={ApplicationDialogTab.Documents}>
                  <ApplicationDocuments />
                </TabPanel>
                <TabPanel value={ApplicationDialogTab.BackgroundCheck}>
                  <BackgroundCheck />
                </TabPanel>
              </Box>
              {tabValue !== ApplicationDialogTab.Comments && tabValue !== ApplicationDialogTab.Communications && (
                <Box sx={{ display: 'flex', justifyContent: 'right', mb: '-2rem', mt: '.5rem' }}>
                  <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    {tabValue === ApplicationDialogTab.OtherAdults ||
                    tabValue === ApplicationDialogTab.CoSigner ||
                    tabValue === ApplicationDialogTab.Personal ? (
                      <></>
                    ) : (
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        disabled={!dirty || !isValid}
                        loading={applications.allApplications.submitting}
                      >
                        Save Changes
                      </LoadingButton>
                    )}
                  </DialogActions>
                </Box>
              )}
            </form>
            <TabPanel value={ApplicationDialogTab.Comments}>
              <ApplicationComments applicant={editingApplicant} />
            </TabPanel>
            <TabPanel value={ApplicationDialogTab.Communications}>
              <Emails
                associationInfo={{
                  associationType: AssociationType.Application,
                  associatedId: editingApplicant?.id,
                  emailAddresses: applications.selectedApplication.value?.contactInfo?.email
                    ? [applications.selectedApplication.value.contactInfo.email]
                    : [],
                  label: `${editingApplicant.firstName} ${editingApplicant.lastName}`,
                }}
              />
              <SmsView associationType={AssociationType.Application} associationId={editingApplicant.id} />
            </TabPanel>
          </TabContext>
        </DialogContent>
        <AddOtherAdultDialog
          open={openAddOtherAdult}
          onClose={handleOtherAdultClose}
          applicant={applications.selectedApplication.value}
        />
        <AddVehicleDialog addVehicle={handleAddDependent} open={openAddVehicle} onClose={handleAddVehicleClose} />
        <AddPetDialog addPet={handleAddDependent} open={openAddPet} onClose={handleAddPetClose} />
        <AddChildDialog addChild={handleAddDependent} open={openAddChild} onClose={handleAddChildClose} />
        <AddHousingHistoryDialog
          addHousingHistory={handleAddDependent}
          open={openAddHousingHistory}
          onClose={handleAddHousingHistoryClose}
        />
        <AddCosignerDialog
          open={openAddCosigner}
          onClose={handleAddCosignerClose}
          applicant={applications.selectedApplication.value}
        />
      </DialogLayout>
    );
  } else return <></>;
};
