import React, { FC } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { FilterSelect } from './FilterSelect';
import { ListingParameter, useListingFilters } from '../../../redux/useListingFilters';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

type ListingsSearchProps = {
  onClearFilters: () => void;
  onSetFilter: (filters: ListingParameter[]) => void;
  isPropertyPreview?: boolean;
  isPropertyView?: boolean;
};

export const ListingsSearch: FC<ListingsSearchProps> = ({
  onClearFilters,
  onSetFilter,
  isPropertyPreview,
  isPropertyView,
}) => {
  const theme = useTheme();
  const { filters, propertyFilters } = useListingFilters();
  const [clearFilter, setClearFilter] = React.useState<boolean>(false);

  const handleClearFilter = () => {
    setClearFilter(true);
    setTimeout(() => setClearFilter(false), 100);
    onClearFilters();
  };

  return (
    <Toolbar
      sx={{
        height: '4rem',
        backgroundColor: theme.palette.primary.light,
        width: '100%',
        borderRadius: theme.shape.borderRadius,
      }}
    >
      <Stack spacing={1} direction={'row'} sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Stack spacing={1} direction={'row'}>
          {isPropertyView &&
            propertyFilters.map((filter) => (
              <Box sx={{ minWidth: 150 }} key={filter.label}>
                <FilterSelect
                  value={filter.label}
                  onSetFilter={onSetFilter}
                  clearFilter={clearFilter}
                  disabled={isPropertyPreview}
                />
              </Box>
            ))}
          {filters.map((filter) => (
            <Box sx={{ minWidth: 150 }} key={filter.label}>
              <FilterSelect
                value={filter.label}
                onSetFilter={onSetFilter}
                clearFilter={clearFilter}
                disabled={isPropertyPreview}
              />
            </Box>
          ))}
          <Box display={'flex'} alignItems={'center'}>
            <Tooltip title={'Clear Filters'}>
              <span>
                <IconButton onClick={handleClearFilter} disabled={isPropertyPreview}>
                  <ClearIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Stack>
      </Stack>
    </Toolbar>
  );
};
