import { alpha, styled, Theme } from '@mui/material/styles';

import { appTheme } from '../../../AppTheme';
import { DataGridPremium, gridClasses } from '@mui/x-data-grid-premium';

const ODD_OPACITY = 0.2;

const styles = {
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: appTheme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(appTheme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(appTheme.palette.primary.main, ODD_OPACITY + appTheme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          appTheme.palette.primary.main,
          ODD_OPACITY + appTheme.palette.action.selectedOpacity + appTheme.palette.action.hoverOpacity,
        ),
        [`& .${gridClasses.row}.highlighted`]: {
          backgroundColor: appTheme.palette.primary.main,
        },
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(appTheme.palette.primary.main, ODD_OPACITY + appTheme.palette.action.selectedOpacity),
        },
      },
    },
  },
  [`& .${gridClasses.row}.groupingRow`]: {
    backgroundColor: alpha(appTheme.palette.primary.light, 0.5),
  },
  [`& .${gridClasses.row}.inactive`]: {
    color: appTheme.palette.grey[700],
  },
  [`& .${gridClasses.row}.highlighted`]: {
    backgroundColor: appTheme.palette.warning.main,
  },
  [`& .${gridClasses.row}.incoming`]: {
    backgroundColor: alpha(appTheme.palette.primary.main, ODD_OPACITY),
  },
  [`& .${gridClasses.row}.outgoing`]: {
    backgroundColor: appTheme.palette.background,
  },
  [`& .${gridClasses.cell}.groupingRowCell`]: {
    overflow: 'visible !important',
  },
  [`& .${gridClasses.row}.archived`]: {
    color: appTheme.palette.error.main,
    textDecoration: 'line-through',
  },
  [`& .${gridClasses.row}.aggregation`]: {
    color: 'red',
  },
};

export const StripedDataGrid = styled(DataGridPremium)(({}) => styles);

export const styledFilterPanelProps = {
  filterFormProps: {
    logicOperatorInputProps: {
      variant: 'outlined',
      size: 'small',
      sx: { mt: 'auto' },
    },
    columnInputProps: {
      variant: 'outlined',
      size: 'small',
      sx: { mt: 'auto' },
    },
    operatorInputProps: {
      variant: 'outlined',
      size: 'small',
      sx: { mt: 'auto' },
    },
    valueInputProps: {
      InputComponentProps: {
        variant: 'outlined',
        size: 'small',
        margin: 'none',
      },
    },
  },
  sx: {
    // Customize inputs using css selectors
    '& .MuiDataGrid-filterForm': { p: 2 },
    '& .MuiDataGrid-filterForm:nth-child(even)': {
      backgroundColor: (theme: Theme) => (theme.palette.mode === 'dark' ? '#444' : '#f5f5f5'),
    },
    '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
    '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
    '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
    '& .MuiDataGrid-filterFormValueInput': { width: 200 },
  },
};
