import React, { FC } from 'react';
import { DrawerLayout } from '../../../../../global/layout/DrawerLayout';
import { DialogProps } from '@mui/material/Dialog';
import { CustomizeReport } from './CustomizeReport';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

type ReportDrawerProps = {
  onClose: () => void;
} & Omit<DialogProps, 'onClose'>;

export const ReportDrawer: FC<ReportDrawerProps> = ({ open, onClose }) => {
  return (
    <DrawerLayout title={'Customize Report'} open={open} onCloseDrawer={onClose}>
      <Stack spacing={2} width={'100%'}>
        <Box m={1}>
          <CustomizeReport />
        </Box>
      </Stack>
    </DrawerLayout>
  );
};
