import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useDispatch } from 'react-redux';
import {
  acceptInviteAction,
  getInvitationsForUserAction,
  useUser,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import {
  IGetInvitationsHandlerSingleResponse,
  ISlimClaim,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { Claim, Claims } from '@monkeyjump-labs/cam-fe-shared/dist/types/constants';
import { useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export const AcceptInvitations = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const { invitations, currentUser } = useUser((u) => u);
  const [search] = useSearchParams();
  const [userHasTenantClaims, setUserHasTenantClaims] = useState(false);
  const [userHasPropertyClaims, setUserHasPropertyClaims] = useState(false);

  useEffect(() => {
    dispatch(getInvitationsForUserAction());
  }, [currentUser]);

  useEffect(() => {
    if (isAuthenticated) {
      if (search.get('inviteCode')) {
        dispatch(acceptInviteAction(search.get('inviteCode') as string));
      }
    }
  }, []);

  const matchUserClaims = (userClaims: ISlimClaim[]) => {
    const hasTenantClaims = userClaims?.find((claim) => claim.type === Claims.Tenant);
    const hasPropertyClaims = userClaims?.find(
      (claim) =>
        claim.type === Claims.BillingOwner ||
        claim.type === Claims.Admin ||
        claim.type === Claims.OwnerInvestor ||
        claim.type === Claims.Employee ||
        claim.type === Claims.ExternalVendor ||
        claim.type === Claims.PropertyManager,
    );
    if (hasTenantClaims) setUserHasTenantClaims(true);
    if (hasPropertyClaims) setUserHasPropertyClaims(true);
  };

  useEffect(() => {
    currentUser.value?.claims && matchUserClaims(currentUser.value?.claims);
  }, [currentUser.value?.claims]);

  return (
    <Grid container sx={{ height: 'calc(100vh - 96px)' }} alignItems={'center'} justifyContent={'center'}>
      <Paper sx={{ position: 'relative', height: 320, minWidth: 450, padding: '1rem' }}>
        <Box height={'100%'} padding={2}>
          <Stack height={'100%'} alignItems={'center'} justifyContent={'center'} spacing={2}>
            {invitations.submitted && <Typography variant="h4"> Successfully accepted invitation </Typography>}
            {invitations.loading ? (
              <LinearProgress sx={{ width: '100%' }} variant="indeterminate" />
            ) : (
              <Typography variant="h4">
                {!invitations.value || invitations.value?.results?.length === 0
                  ? 'No Pending Invitations'
                  : 'Pending Invitations'}
              </Typography>
            )}
            <List>
              {invitations.value?.results?.map((invite: IGetInvitationsHandlerSingleResponse) => (
                <ListItem
                  key={invite.id}
                  secondaryAction={
                    <Tooltip title={'Accept Invitation'}>
                      <IconButton edge="end" onClick={() => invite.id && dispatch(acceptInviteAction(invite.id))}>
                        {invitations.submitting ? <CircularProgress size={'1rem'} /> : <CheckIcon />}
                      </IconButton>
                    </Tooltip>
                  }
                  disableGutters
                >
                  <ListItemIcon>
                    <ApartmentIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={invite.unitPath}
                    secondary={
                      invite.claim
                        ? `Invitation Type: ${Object.keys(Claims).find((key) => Claims[key as Claim] === invite.claim)}`
                        : ''
                    }
                  ></ListItemText>
                </ListItem>
              ))}
            </List>
            {(!invitations.value || invitations.value?.results?.length === 0) && userHasPropertyClaims && (
              <Link href={'/assets/property'}>Property Management Home</Link>
            )}
            {(!invitations.value || invitations.value?.results?.length === 0) && userHasTenantClaims && (
              <Link href={'/tenantPortal'}>Tenant Portal</Link>
            )}
          </Stack>
        </Box>
      </Paper>
    </Grid>
  );
};
