import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { LoadingIconButton } from '../../../_shared/buttons/LoadingIconButton';
import {
  AssociationType,
  ListingStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LanguageIcon from '@mui/icons-material/Language';
import { updateListingPromotionAction, updateListingStatusAction, useListings } from '../../redux/listingSlice';
import { useIcons } from '../../../_shared/icons/useIcons';
import { useDispatch } from 'react-redux';
import { ReduxListing } from '../../redux/listingData';

type ListingStatusBarProps = {
  listing: ReduxListing;
};

export const ListingStatusBar: FC<ListingStatusBarProps> = ({ listing }) => {
  const { selectedListing } = useListings();
  const { getAssociationIcon } = useIcons();
  const dispatch = useDispatch();

  const handleUnpublish = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (listing.status === ListingStatus.Unpublished) return;
    listing.id && dispatch(updateListingStatusAction({ id: listing.id, status: ListingStatus.Unpublished }));
  };

  const handlePublishInternally = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (listing.status === ListingStatus.PublishedInternally) {
      handleUnpublish(e);
      return;
    }
    //TODO: validation
    listing.id && dispatch(updateListingStatusAction({ id: listing.id, status: ListingStatus.PublishedInternally }));
  };

  const handlePublishExternally = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (listing.status === ListingStatus.PublishedExternally) {
      handlePublishInternally(e);
      return;
    }
    //TODO: validation
    listing.id && dispatch(updateListingStatusAction({ id: listing.id, status: ListingStatus.PublishedExternally }));
  };

  const handleTogglePromote = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    listing.id && dispatch(updateListingPromotionAction({ id: listing.id, promote: !listing.isPromoted }));
  };

  return (
    <Box display={'flex'} flexGrow={1} justifyContent={'flex-end'}>
      <LoadingIconButton
        loading={selectedListing.submitting}
        icon={<LightbulbIcon />}
        label={
          listing.status === ListingStatus.PublishedInternally || listing.status === ListingStatus.PublishedExternally
            ? 'Published Internally'
            : 'Publish Internally'
        }
        color={
          listing.status === ListingStatus.PublishedInternally || listing.status === ListingStatus.PublishedExternally
            ? 'success'
            : 'default'
        }
        size={'small'}
        onClick={handlePublishInternally}
      />
      <LoadingIconButton
        loading={selectedListing.submitting}
        icon={<LanguageIcon />}
        label={listing.status === ListingStatus.PublishedExternally ? 'Published Externally' : 'Publish Externally'}
        color={listing.status === ListingStatus.PublishedExternally ? 'success' : 'default'}
        size={'small'}
        onClick={handlePublishExternally}
      />
      <LoadingIconButton
        loading={selectedListing.submitting}
        icon={getAssociationIcon(AssociationType.Expense)}
        label={
          listing.status !== ListingStatus.PublishedExternally
            ? 'Must Publish Externally Before Promoting'
            : listing.isPromoted
              ? 'Promoted'
              : 'Promote'
        }
        color={listing.isPromoted === true ? 'success' : 'default'}
        size={'small'}
        disabled={listing.status !== ListingStatus.PublishedExternally}
        onClick={handleTogglePromote}
      />
    </Box>
  );
};
