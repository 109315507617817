import React, { FC, PropsWithChildren } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { history, store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';

const container = document.getElementById('root')!;
const root = createRoot(container);
ApiClientSingleton.initialize(store, process.env.REACT_APP_API_URL!);

const CAMAuth0Provider: FC<PropsWithChildren<any>> = ({ children }) => {
  const navigate = useNavigate();

  const handleRedirectCallback = (appState: any) => {
    const redirectUri = appState.redirectUri;
    const search = appState.search;
    if (redirectUri) {
      navigate(redirectUri + (search ?? ''));
    }
  };
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID!}
      authorizationParams={{
        scope: 'email profile',
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
      }}
      useRefreshTokens={true}
      onRedirectCallback={handleRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID!,
  });

  root.render(
    <React.StrictMode>
      <LDProvider>
        <Provider store={store}>
          <Router history={history}>
            <CAMAuth0Provider>
              <App />
            </CAMAuth0Provider>
          </Router>
        </Provider>
      </LDProvider>
    </React.StrictMode>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
