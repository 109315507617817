import { IUnitInfo, ListingStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  initInteractable,
  initInteractableCollection,
  initLoadable,
  Interactable,
  InteractableCollection,
  Loadable,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { useSelector } from 'react-redux';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { ReduxListing, ReduxPropertyAdvertisingDto } from './listingData';

export interface ListingSlice {
  listings: InteractableCollection<ReduxListing>;
  selectedListing: Interactable<ReduxListing>;
  propertyAdvertisingDetails: Loadable<ReduxPropertyAdvertisingDto>;
}

const initialState: ListingSlice = {
  listings: initInteractableCollection<ReduxListing>(),
  selectedListing: initInteractable<ReduxListing>(),
  propertyAdvertisingDetails: initLoadable<ReduxPropertyAdvertisingDto>(),
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const listingSlice = createSlice({
  name: 'advertising',
  initialState: initialState,
  reducers: {
    listListingsAction: (
      state,
      action: PayloadAction<{
        propertyId: string;
        parameters: { field: string; value: string }[];
        isWebView?: boolean;
      }>,
    ) => {
      state.listings.loading = true;
      state.listings.loaded = false;
    },
    listListingsSuccessAction: (state, action: PayloadAction<ReduxListing[]>) => {
      state.listings.loading = false;
      state.listings.loaded = true;
      state.listings.value = action.payload;
      //  state.listings.totalCount = action.payload.totalCount;
    },
    listListingsFailAction: (state) => {
      state.listings.loading = false;
      state.listings.loaded = true;
    },
    getListingAction: (state, action: PayloadAction<{ listingId: string; isWebView?: boolean }>) => {
      state.selectedListing.loading = true;
      state.selectedListing.loaded = false;
    },
    getListingByUnitIdAction: (state, action: PayloadAction<string>) => {
      state.selectedListing.loading = true;
      state.selectedListing.loaded = false;
    },
    getListingSuccessAction: (state, action: PayloadAction<ReduxListing | undefined>) => {
      state.selectedListing.loading = false;
      state.selectedListing.loaded = true;
      state.selectedListing.value = action.payload;
    },
    getListingFailAction: (state) => {
      state.selectedListing.loading = false;
      state.selectedListing.loaded = true;
    },
    addListingFromTemplateAction: (state, action: PayloadAction<string>) => {
      state.listings.submitting = true;
      state.listings.submitted = false;
    },
    addListingFromUnitsAction: (state, action: PayloadAction<string>) => {
      state.listings.submitting = true;
      state.listings.submitted = false;
    },
    addGenericListingAction: (state, action: PayloadAction<{ buildingId: string; info: IUnitInfo }>) => {
      state.listings.submitting = true;
      state.listings.submitted = false;
    },
    addListingSuccessAction: (state, action: PayloadAction<ReduxListing>) => {
      state.listings.submitting = false;
      state.listings.submitted = true;
      state.listings.value = state.listings.value ? [...state.listings.value, action.payload] : [action.payload];
      state.selectedListing.value = action.payload;
    },
    addListingFailAction: (state) => {
      state.listings.submitting = false;
      state.listings.submitted = false;
    },
    updateListingAction: (state, action: PayloadAction<{ id: string; body: ReduxListing }>) => {
      state.selectedListing.submitting = true;
      state.selectedListing.submitted = false;
    },
    updateListingSuccessAction: (state, action: PayloadAction<ReduxListing>) => {
      state.selectedListing.submitting = false;
      state.selectedListing.submitted = true;
      state.selectedListing.value = action.payload;
      if (state.listings.value) {
        const index = state.listings.value.findIndex((listing) => listing.id === action.payload.id);
        state.listings.value[index] = action.payload;
      }
    },
    updateListingFailAction: (state) => {
      state.selectedListing.submitting = false;
      state.selectedListing.submitted = false;
    },
    deleteListingAction: (state, action: PayloadAction<string>) => {
      state.listings.submitting = true;
      state.listings.submitted = false;
    },
    deleteListingSuccessAction: (state, action: PayloadAction<string>) => {
      state.listings.submitting = false;
      state.listings.submitted = true;
      state.listings.value = state.listings.value?.filter((listing) => listing.id !== action.payload);
    },
    deleteListingFailAction: (state) => {
      state.listings.submitting = false;
      state.listings.submitted = false;
    },
    getPropertyAdvertisingDetailsAction: (state, action: PayloadAction<string>) => {
      state.propertyAdvertisingDetails.loading = true;
      state.propertyAdvertisingDetails.loaded = false;
    },
    getPropertyAdvertisingDetailsSuccessAction: (state, action: PayloadAction<ReduxPropertyAdvertisingDto>) => {
      state.propertyAdvertisingDetails.loading = false;
      state.propertyAdvertisingDetails.loaded = true;
      state.propertyAdvertisingDetails.value = action.payload;
    },
    getPropertyAdvertisingDetailsFailAction: (state) => {
      state.propertyAdvertisingDetails.loading = false;
      state.propertyAdvertisingDetails.loaded = true;
    },
    addPhotoAction: (state, action: PayloadAction<{ id: string; photo: File }>) => {
      state.selectedListing.submitting = true;
      state.selectedListing.submitted = false;
    },
    addPhotoSuccessAction: (state, action: PayloadAction<{ id: string; photo: ReduxDocument }>) => {
      state.selectedListing.submitting = false;
      state.selectedListing.submitted = true;
      if (state.selectedListing.value) {
        if (!state.selectedListing.value.photos) {
          state.selectedListing.value.photos = [];
        }
        state.selectedListing.value.photos.push(action.payload.photo);
      }
      if (state.listings.value) {
        const index = state.listings.value.findIndex((listing) => listing.id === action.payload.id);
        if (index !== -1) {
          const listingToUpdate = state.listings.value[index];
          if (!listingToUpdate.photos) {
            listingToUpdate.photos = [];
          }
          listingToUpdate.photos.push(action.payload.photo);
        }
      }
    },
    addPhotoFailAction: (state) => {
      state.selectedListing.submitting = false;
      state.selectedListing.submitted = false;
    },
    removePhotoAction: (state, action: PayloadAction<{ id: string; photoId: string }>) => {
      state.selectedListing.submitting = true;
      state.selectedListing.submitted = false;
    },
    removePhotoSuccessAction: (state, action: PayloadAction<{ listingId: string; photoId: string }>) => {
      state.selectedListing.submitting = false;
      state.selectedListing.submitted = true;
      state.selectedListing.value = state.selectedListing.value?.photos
        ? {
            ...state.selectedListing.value,
            photos: state.selectedListing.value.photos.filter((photo) => photo.id !== action.payload.photoId),
          }
        : undefined;
      if (state.listings.value) {
        const index = state.listings.value.findIndex((listing) => listing.id === action.payload.listingId);
        if (index !== -1) {
          const listingToUpdate = state.listings.value[index];
          if (!listingToUpdate.photos) {
            listingToUpdate.photos = [];
            listingToUpdate.photos.filter((photo) => photo.id !== action.payload.photoId);
          }
        }
      }
    },
    removePhotoFailAction: (state) => {
      state.selectedListing.submitting = false;
      state.selectedListing.submitted = false;
    },
    reorderPhotoAction: (state, action: PayloadAction<{ id: string; photoIds: string[] }>) => {
      state.selectedListing.submitting = true;
      state.selectedListing.submitted = false;
    },
    reorderPhotoSuccessAction: (state, action: PayloadAction<{ id: string; photoIds: string[] }>) => {
      state.selectedListing.submitting = false;
      state.selectedListing.submitted = true;
      const oldOrderedPhotos = state.selectedListing.value?.photos;
      state.selectedListing.value = oldOrderedPhotos
        ? {
            ...state.selectedListing.value,
            photos: action.payload.photoIds.map((id) => oldOrderedPhotos.find((photo) => photo.id === id)!),
          }
        : undefined;
      if (state.listings.value) {
        const index = state.listings.value.findIndex((listing) => listing.id === action.payload.id);
        if (index !== -1 && state.listings.value[index].photos) {
          const photosArray = state.listings.value[index].photos!;
          const newListingOrder = action.payload.photoIds
            .map((id) => photosArray.find((photo) => photo.id === id))
            .filter((photo): photo is ReduxDocument => photo !== undefined);
          state.listings.value[index].photos = newListingOrder;
        }
      }
    },
    reorderPhotoFailAction: (state) => {
      state.selectedListing.submitting = false;
      state.selectedListing.submitted = false;
    },
    updateListingPhotoMetadataSuccessAction: (
      state,
      action: PayloadAction<{ listingId: string; documentId: string; title?: string; description?: string }>,
    ) => {
      const listing = state.selectedListing.value;
      if (listing && listing.photos) {
        const photoIndex = listing.photos.findIndex((photo) => photo.id === action.payload.documentId);
        if (photoIndex !== -1) {
          const updatedPhoto = {
            ...listing.photos[photoIndex],
            metadata: {
              ...listing.photos[photoIndex].metadata,
              title: action.payload.title,
              description: action.payload.description,
            },
          };
          const updatedPhotos = [
            ...listing.photos.slice(0, photoIndex),
            updatedPhoto,
            ...listing.photos.slice(photoIndex + 1),
          ];
          state.selectedListing.value = {
            ...listing,
            photos: updatedPhotos,
          };
        }
      }
    },
    updateListingStatusAction: (state, action: PayloadAction<{ id: string; status: ListingStatus }>) => {
      state.selectedListing.submitted = false;
      state.selectedListing.submitting = true;
    },
    updateListingStatusSuccessAction: (state, action: PayloadAction<{ id: string; status: ListingStatus }>) => {
      state.selectedListing.submitted = true;
      state.selectedListing.submitting = false;
      if (state.selectedListing.value) {
        state.selectedListing.value.status = action.payload.status;
      }
      if (state.listings.value) {
        const index = state.listings.value.findIndex((listing) => listing.id === action.payload.id);
        state.listings.value[index].status = action.payload.status;
      }
    },
    updateListingStatusFailAction: (state) => {
      state.selectedListing.submitted = false;
      state.selectedListing.submitting = false;
    },
    updateListingPromotionAction: (state, action: PayloadAction<{ id: string; promote: boolean }>) => {
      state.selectedListing.submitted = false;
      state.selectedListing.submitting = true;
    },
    updateListingPromotionSuccessAction: (state, action: PayloadAction<{ id: string; promote: boolean }>) => {
      state.selectedListing.submitted = true;
      state.selectedListing.submitting = false;
      if (state.selectedListing.value) {
        state.selectedListing.value.isPromoted = action.payload.promote;
      }
      if (state.listings.value) {
        const index = state.listings.value.findIndex((listing) => listing.id === action.payload.id);
        state.listings.value[index].isPromoted = action.payload.promote;
      }
    },
    updateListingPromotionFailAction: (state) => {
      state.selectedListing.submitted = false;
      state.selectedListing.submitting = false;
    },
  },
});

export const {
  listListingsAction,
  listListingsSuccessAction,
  listListingsFailAction,
  getListingAction,
  getListingByUnitIdAction,
  getListingSuccessAction,
  getListingFailAction,
  addListingFromTemplateAction,
  addListingFromUnitsAction,
  addListingSuccessAction,
  addListingFailAction,
  addGenericListingAction,
  deleteListingFailAction,
  deleteListingSuccessAction,
  updateListingFailAction,
  updateListingSuccessAction,
  deleteListingAction,
  updateListingAction,
  getPropertyAdvertisingDetailsFailAction,
  getPropertyAdvertisingDetailsSuccessAction,
  getPropertyAdvertisingDetailsAction,
  updateListingStatusAction,
  updateListingPromotionAction,
  updateListingPromotionFailAction,
  updateListingPromotionSuccessAction,
  updateListingStatusFailAction,
  updateListingStatusSuccessAction,
  addPhotoAction,
  addPhotoSuccessAction,
  removePhotoSuccessAction,
  reorderPhotoSuccessAction,
  removePhotoFailAction,
  addPhotoFailAction,
  reorderPhotoFailAction,
  removePhotoAction,
  reorderPhotoAction,
  updateListingPhotoMetadataSuccessAction,
} = listingSlice.actions;

export const useListings = () => useSelector((state: RootState) => state.listings);
