import React from 'react';
import { BankAccounts } from './BankAccounts';
import { CreditCards } from './CreditCards';
import Stack from '@mui/material/Stack';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetParams } from '../../../../../../AppRouter';

export const ExternalAccounts = () => {
  const navigate = useNavigate();
  const { id, innerTab, outerTab } = useParams<AssetParams>();

  const handleViewStatement = (linkedAccountId?: string) => {
    navigate(`/assets/property/${id}/${outerTab}/${innerTab}/statement/${linkedAccountId}`);
  };

  return (
    <Stack spacing={2}>
      <BankAccounts onViewStatement={handleViewStatement} />
      <CreditCards onViewStatement={handleViewStatement} />
    </Stack>
  );
};
