import React, { FC, useEffect, useState } from 'react';
import { StatementPeriodType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { clearSelectedStatementLineAction, clearStatementAction, useStatement } from '../redux/statementSlice';
import { useStatementConfig } from '../useStatementConfig';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { Period, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { DateRange } from '@mui/x-date-pickers-pro';
import { StatementType } from './LeaseStatement';
import { getMonth } from 'date-fns';
import { StatementTimeSelectorProps } from './StatementTimeSelector';
import Stack from '@mui/material/Stack';
import { StatementView } from './StatementView';
import { ReduxEntry } from '../redux/statementTypes';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AssetTab, FinancialsTab } from '../../tabs/TabTypeEnums';

type AccountStatementProps = {
  statementAccountId?: string;
  isPopupStatement?: boolean;
  highlightedStatementRow?: ReduxEntry;
};

export const AccountStatement: FC<AccountStatementProps> = ({
  isPopupStatement,
  statementAccountId,
  highlightedStatementRow,
}) => {
  const {
    selectedContext: { propertyId },
  } = useAssets();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const { handleFetchAccountStatementPeriods, handleFetchAccountStatement, handleRollbackDeposit, handleMarkAsPosted } =
    useStatementConfig();
  const { selectedStatement, statementPeriods, popupStatement, popupPeriods } = useStatement();
  const [periodValue, setPeriodValue] = useState<Period>();
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);
  const [customDateRange, setCustomDateRange] = React.useState<DateRange<Date> | undefined>(undefined);
  const [selectedPeriodType, setSelectedPeriodType] = React.useState<StatementPeriodType>(StatementPeriodType.Month);
  const [statementType, setStatementType] = React.useState<StatementType>(isPopupStatement ? 'relative' : 'current');
  const [selectedStatementLine, setSelectedStatementLine] = useState<ReduxEntry | undefined>(undefined);

  useEffect(() => {
    setSelectedStatementLine(highlightedStatementRow);
  }, [highlightedStatementRow]);
  //fetch periods when periodType changes
  useEffect(() => {
    handleFetchAccountStatementPeriods(statementAccountId, selectedPeriodType, propertyId, isPopupStatement);
  }, [propertyId, selectedPeriodType, statementAccountId]);

  //if our periods change, default to the first period unless popup, then default to the statement period that contains the highlighted row
  useEffect(() => {
    if (statementPeriods.value) setPeriodValue(statementPeriods.value[0]);
  }, [statementPeriods.value]);

  useEffect(() => {
    if (isPopupStatement && popupPeriods.value) {
      if (highlightedStatementRow) {
        //find the month of the highlighted statement row and then set the value
        const month = highlightedStatementRow.date ? getMonth(toStandardDate(highlightedStatementRow.date)) : undefined;
        if (month) {
          const period = popupPeriods.value.find((p) => getMonth(toStandardDate(p)) === month);
          if (period) {
            setPeriodValue(period);
          }
        }
      } else setPeriodValue(popupPeriods.value[0]);
    }
  }, [popupPeriods.value]);

  //refetch statement when periodValue, custom dates, or includeArchived changes
  useEffect(() => {
    handleFetchAccountStatement(
      customDateRange,
      selectedPeriodType,
      statementType,
      periodValue,
      statementAccountId,
      propertyId,
      includeArchived,
      isPopupStatement,
    );
  }, [includeArchived, periodValue, statementType, customDateRange, accountId, statementAccountId]);

  useEffect(() => {
    if (popupStatement.value && popupStatement.value.account?.subType === 'BankAccount') {
      setIncludeArchived(true);
    } else if (selectedStatement.value && selectedStatement.value.account?.subType === 'BankAccount') {
      setIncludeArchived(true);
    }
  }, [selectedStatement.value?.account?.subType, popupStatement.value?.account?.subType]);

  const handleChangeIncludeArchived = () => {
    setIncludeArchived(!includeArchived);
  };

  function onRollbackDeposit(rowId: string) {
    handleRollbackDeposit(rowId, propertyId, isPopupStatement);
  }

  function onMarkAsPosted(dateDeposited: Date | null, selectedJeId: string | undefined) {
    handleMarkAsPosted(dateDeposited, selectedJeId, propertyId, isPopupStatement);
  }

  const timeSettings: StatementTimeSelectorProps | undefined = {
    dateRange: customDateRange,
    setDateRange: setCustomDateRange,
    selectedPeriodType,
    setSelectedPeriodType,
    onPeriodSelection: setPeriodValue,
    periodValue,
    statementType,
    setStatementType,
    periods: isPopupStatement ? popupPeriods.value : statementPeriods.value,
  };

  return (
    <Stack spacing={3}>
      <StatementView
        fullView
        statement={isPopupStatement ? popupStatement.value : selectedStatement.value}
        loading={isPopupStatement ? popupStatement.loading : selectedStatement.loading}
        submitting={isPopupStatement ? popupStatement.submitting : selectedStatement.submitting}
        showActions
        includeArchived={includeArchived}
        onChangeIncludeArchived={handleChangeIncludeArchived}
        statementPeriod={selectedPeriodType}
        periodSettings={undefined}
        timeSettings={timeSettings!}
        onRollbackDeposit={onRollbackDeposit}
        onMarkAsPosted={onMarkAsPosted}
        onRefreshData={() =>
          handleFetchAccountStatement(
            customDateRange,
            selectedPeriodType,
            statementType,
            periodValue,
            statementAccountId,
            propertyId,
            includeArchived,
            isPopupStatement,
          )
        }
        highlightedJournalEntryId={selectedStatementLine?.journalEntryId}
        isPopupStatement={isPopupStatement}
        showAssociationChip={!isPopupStatement}
        onOpenPopupStatement={(statementLine, subAccountId) => {
          dispatch(clearSelectedStatementLineAction());
          dispatch(clearStatementAction());
          setSelectedStatementLine(statementLine);
          navigate(
            `/assets/property/${propertyId}/${AssetTab.Financials}/${FinancialsTab.Journal}/statement/${subAccountId}`,
          );
        }}
      />
    </Stack>
  );
};
