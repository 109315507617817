import React from 'react';
import { getAssetTypeFromPathname } from '../utils/getAssetTypeFromPathname';
import { UnitSetupDefaults } from '../../property/components/propertyTabs/propertyInfoTabs/unitConfiguration/UnitSetupDefaults';

export const AssetSetupTab = () => {
  const asset = getAssetTypeFromPathname();
  if (asset === 'property') {
    return <UnitSetupDefaults />;
  } else return <></>;
};
