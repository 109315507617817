import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled } from '@mui/material/styles';

type DetailListProps = {
  details: string[];
  title: string;
};

export const DetailList: FC<DetailListProps> = ({ details, title }) => {
  return (
    <Stack>
      <Typography variant={'h6'} color={'primary.main'}>
        {title.toUpperCase()}
      </Typography>
      <List dense>
        {details.map((d) => (
          <ListItem key={d}>
            <ListItemIcon>
              <Dot />
            </ListItemIcon>
            <ListItemText>{d}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

const Dot = styled('span')({
  height: '5px',
  width: '5px',
  backgroundColor: '#000', // Change the color as needed
  borderRadius: '50%',
  display: 'inline-block',
  marginLeft: '30px',
});
