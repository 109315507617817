import React, { FC } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { INotificationSetting } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type NotificationDropdownProps = {
  notificationSetting: INotificationSetting;
  setNotificationSetting: (value: INotificationSetting) => void;
  label: string;
};

export const NotificationDropdown: FC<NotificationDropdownProps> = ({
  notificationSetting,
  setNotificationSetting,
  label,
}) => {
  return (
    <Accordion elevation={0} sx={{ border: '.1rem solid gray' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${label}-content`} id={`${label}-header`}>
        {label}
      </AccordionSummary>
      <AccordionDetails>
        <List>
          <ListItem>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    size={'small'}
                    checked={notificationSetting.byInternalNotification}
                    onChange={() => {
                      setNotificationSetting({
                        ...notificationSetting,
                        byInternalNotification: !notificationSetting.byInternalNotification,
                      });
                    }}
                  />
                }
                label={'In-App Alerts'}
              />
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    size={'small'}
                    checked={notificationSetting.byEmail}
                    onChange={() => {
                      setNotificationSetting({ ...notificationSetting, byEmail: !notificationSetting.byEmail });
                    }}
                  />
                }
                label={'Email Alerts'}
              />
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    size={'small'}
                    checked={notificationSetting.byPushNotification}
                    onChange={() => {
                      setNotificationSetting({
                        ...notificationSetting,
                        byPushNotification: !notificationSetting.byPushNotification,
                      });
                    }}
                  />
                }
                label={'Push Notifications'}
              />
            </FormControl>
          </ListItem>
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
