import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { initInteractable, Interactable } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { RootState } from '../../../app/store';
import { RoomDataType } from './roomData';
import { IAddRoomHandlerRequest } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export interface RoomState {
  selectedRoom: Interactable<RoomDataType>;
}

const initialState: RoomState = {
  selectedRoom: initInteractable<RoomDataType>(undefined),
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    getRoomByIdAction: (state, action: PayloadAction<string>) => {
      state.selectedRoom.loading = true;
      state.selectedRoom.loaded = false;
    },
    getRoomByIdSuccessAction: (state, action: PayloadAction<RoomDataType>) => {
      state.selectedRoom.loaded = true;
      state.selectedRoom.loading = false;
      state.selectedRoom.value = action.payload;
    },
    getRoomByIdFailAction: (state) => {
      state.selectedRoom.loaded = true;
      state.selectedRoom.loading = false;
      state.selectedRoom.value = undefined;
    },
    createRoomAction: (state, action: PayloadAction<IAddRoomHandlerRequest>) => {
      state.selectedRoom.submitting = true;
      state.selectedRoom.submitted = false;
    },
    createRoomSuccessAction: (state, action: PayloadAction<RoomDataType>) => {
      state.selectedRoom.value = action.payload;
      state.selectedRoom.submitting = false;
      state.selectedRoom.submitted = true;
      state.selectedRoom.submittingValue = undefined;
    },
    createRoomFailAction: (state) => {
      state.selectedRoom.submitting = false;
      state.selectedRoom.submitted = false;
    },
    cancelCreateRoomAction: (state) => {
      state.selectedRoom.submitting = false;
      state.selectedRoom.submitted = false;
    },
    cancelCreateRoomConfirmedAction: (state) => {
      state.selectedRoom.submitting = false;
      state.selectedRoom.submitted = false;
    },
    deleteRoomAction: (state, action: PayloadAction<string>) => {
      state.selectedRoom.submitting = true;
      state.selectedRoom.submitted = false;
    },
    deleteRoomSuccessAction: (state) => {
      state.selectedRoom.submitting = false;
      state.selectedRoom.submitted = true;
    },
    deleteRoomFailAction: (state) => {
      state.selectedRoom.submitting = false;
      state.selectedRoom.submitted = false;
    },
    updateRoomAction: (state, action: PayloadAction<RoomDataType>) => {
      state.selectedRoom.submitting = true;
      state.selectedRoom.submitted = false;
      state.selectedRoom.submittingValue = action.payload;
    },
    updateRoomSuccessAction: (state, action: PayloadAction<RoomDataType>) => {
      state.selectedRoom.value = action.payload;
      state.selectedRoom.submitting = false;
      state.selectedRoom.submitted = true;
      state.selectedRoom.submittingValue = undefined;
    },
    updateRoomFailAction: (state) => {
      state.selectedRoom.submitting = false;
      state.selectedRoom.submitted = false;
    },
    cancelUpdateRoomAction: (state) => {
      state.selectedRoom.submitting = false;
      state.selectedRoom.submitted = false;
    },
    cancelUpdateRoomConfirmedAction: (state) => {
      state.selectedRoom.submitting = false;
      state.selectedRoom.submitted = false;
    },
  },
});
/* eslint-enable @typescript-eslint/no-unused-vars */

export const {
  getRoomByIdAction,
  getRoomByIdSuccessAction,
  getRoomByIdFailAction,
  createRoomAction,
  createRoomFailAction,
  createRoomSuccessAction,
  deleteRoomAction,
  deleteRoomFailAction,
  deleteRoomSuccessAction,
  updateRoomAction,
  updateRoomFailAction,
  updateRoomSuccessAction,
  cancelCreateRoomAction,
  cancelCreateRoomConfirmedAction,
  cancelUpdateRoomAction,
  cancelUpdateRoomConfirmedAction,
} = roomSlice.actions;

export const useRoom = () => useSelector((state: RootState) => state.room);
