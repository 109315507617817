import {
  initLoadable,
  initPageableCollection,
  Loadable,
  PageableCollection,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IListAvailableUnitTemplatesHandlerResponse,
  IListUnitTemplatesHandlerResponse,
  IUnitInfo,
  IUnitTemplate,
  UnitInfo,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { RootState } from '../../../../app/store';
import { useSelector } from 'react-redux';

export interface UnitTemplateSlice {
  unitTemplates: PageableCollection<IUnitTemplate>;
  availableTemplates: Loadable<IUnitTemplate[]>;
}

const initialState: UnitTemplateSlice = {
  unitTemplates: initPageableCollection<IUnitTemplate>(),
  availableTemplates: initLoadable<IUnitTemplate[]>(),
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const unitTemplateSlice = createSlice({
  name: 'unitTemplate',
  initialState: initialState,
  reducers: {
    listUnitTemplatesAction: (
      state,
      action: PayloadAction<{ propertyId: string; buildingId?: string; page: number; pageSize: number }>,
    ) => {
      state.unitTemplates.loading = true;
      state.unitTemplates.loaded = false;
    },
    listUnitTemplatesSuccessAction: (state, action: PayloadAction<IListUnitTemplatesHandlerResponse>) => {
      state.unitTemplates.loading = false;
      state.unitTemplates.loaded = true;
      state.unitTemplates.value = action.payload.templates;
      state.unitTemplates.totalCount = action.payload.totalCount;
    },
    listUnitTemplatesFailAction: (state) => {
      state.unitTemplates.loading = false;
      state.unitTemplates.loaded = true;
    },
    listAvailableUnitTemplatesAction: (state, action: PayloadAction<{ buildingId: string }>) => {
      state.availableTemplates.loading = true;
      state.availableTemplates.loaded = false;
    },
    listAvailableUnitTemplatesSuccessAction: (
      state,
      action: PayloadAction<IListAvailableUnitTemplatesHandlerResponse>,
    ) => {
      state.availableTemplates.loading = false;
      state.availableTemplates.loaded = true;
      state.availableTemplates.value = action.payload.templates;
    },
    listAvailableUnitTemplatesFailAction: (state) => {
      state.availableTemplates.loading = false;
      state.availableTemplates.loaded = true;
    },
    updateUnitTemplateAction: (state, action: PayloadAction<{ id: string; name?: string; info?: IUnitInfo }>) => {
      state.unitTemplates.loading = true;
      state.unitTemplates.loaded = false;
    },
    updateUnitTemplateSuccessAction: (
      state,
      action: PayloadAction<{ id: string; name?: string; info?: IUnitInfo }>,
    ) => {
      state.unitTemplates.loading = false;
      state.unitTemplates.loaded = true;

      const template = state.unitTemplates.value?.find((t) => t.id === action.payload.id);
      if (template) {
        template.name = action.payload.name;
        template.info = action.payload.info;
      }
    },
    updateUnitTemplateFailAction: (state) => {
      state.unitTemplates.loading = false;
      state.unitTemplates.loaded = true;
    },
    deleteUnitTemplateAction: (state, action: PayloadAction<{ id: string }>) => {
      state.unitTemplates.loading = true;
      state.unitTemplates.loaded = false;
    },
    deleteUnitTemplateSuccessAction: (state, action: PayloadAction<{ id: string }>) => {
      state.unitTemplates.loading = false;
      state.unitTemplates.loaded = true;
      state.unitTemplates.value = state.unitTemplates.value?.filter((t) => t.id !== action.payload.id);
    },
    deleteUnitTemplateFailAction: (state) => {
      state.unitTemplates.loading = false;
      state.unitTemplates.loaded = true;
    },
    resetUnitTemplateSubmissionAction: (state) => {
      state.unitTemplates.loading = false;
      state.unitTemplates.loaded = false;
    },
    addUnitTemplateAction: (
      state,
      action: PayloadAction<{ propertyId: string; buildingId?: string; name: string; info?: IUnitInfo }>,
    ) => {
      state.unitTemplates.loading = true;
      state.unitTemplates.loaded = false;
    },
    addUnitTemplateSuccessAction: (state, action: PayloadAction<{ id: string; name: string; info?: IUnitInfo }>) => {
      state.unitTemplates.loading = false;
      state.unitTemplates.loaded = true;

      state.unitTemplates.value?.push({
        id: action.payload.id,
        name: action.payload.name,
        info: action.payload.info ?? new UnitInfo(),
      });
    },
    addUnitTemplateFailAction: (state) => {
      state.unitTemplates.loading = false;
      state.unitTemplates.loaded = true;
    },
  },
});

export const {
  listUnitTemplatesAction,
  listUnitTemplatesSuccessAction,
  listUnitTemplatesFailAction,
  listAvailableUnitTemplatesAction,
  listAvailableUnitTemplatesSuccessAction,
  listAvailableUnitTemplatesFailAction,
  updateUnitTemplateAction,
  updateUnitTemplateSuccessAction,
  updateUnitTemplateFailAction,
  deleteUnitTemplateAction,
  deleteUnitTemplateSuccessAction,
  deleteUnitTemplateFailAction,
  resetUnitTemplateSubmissionAction,
  addUnitTemplateAction,
  addUnitTemplateSuccessAction,
  addUnitTemplateFailAction,
} = unitTemplateSlice.actions;

export const useUnitTemplates = () => useSelector((r: RootState) => r.unitTemplates);
