import React, { FC, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { hideAssetTreeAction, useGlobal } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {
  AssetType,
  IGetBuildingHandlerResponse,
  IGetUnitHandlerResponse,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Link from '@mui/material/Link';
import { appTheme } from '../../../AppTheme';
import { isBuilding, isUnit } from '@monkeyjump-labs/cam-fe-shared/dist/types/assetTypes';
import Typography from '@mui/material/Typography';
import { Feature } from '../Feature';
import { TimeTravel } from '../../property/components/timeTravel/TimeTravel';
import { toReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Box from '@mui/material/Box';
import { AssetTab } from '../../_shared/tabs/TabTypeEnums';
import { useTabConfig } from '../../_shared/tabs/useTabConfig';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { ReduxRentalProperty } from '@monkeyjump-labs/cam-fe-shared/dist/types/propertyTypes';

type AssetHeaderProps = {
  assetType: AssetType;
  value: ReduxRentalProperty | IGetBuildingHandlerResponse | IGetUnitHandlerResponse;
};

export const OuterTabsHeader: FC<AssetHeaderProps> = ({ assetType, value }) => {
  const { hideAssetTree } = useGlobal();
  const { findDefaultTab } = useTabConfig();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  //the tooltip needs a key to force it to re-render when the assetTree is hidden or reappears, or it gets wonky
  const [tooltipKey, setTooltipKey] = useState(Date.now());
  const color = selectedContext.isSimulated ? appTheme.palette.warning.main : appTheme.palette.primary.main;
  const fontSize = '1.5rem';
  const propertyName = isBuilding(value) || isUnit(value) ? `${value?.propertyName}` : undefined;
  const buildingName = isUnit(value) ? `${value?.buildingName}` : undefined;
  const handleNavigateToParentAsset = (assetType: AssetType, assetId?: string) => {
    if (!assetId) return;
    const assetRouteType =
      assetType === AssetType.Building ? 'building' : assetType === AssetType.BuildingUnit ? 'unit' : 'property';
    const innerTab = findDefaultTab(assetType, AssetTab.Overview);
    navigate(`/assets/${assetRouteType}/${assetId}/${AssetTab.Overview}/${innerTab}`);
  };

  const linkStyle = {
    cursor: 'pointer',
    marginRight: '.5rem',
    fontWeight: 400,
    fontSize: fontSize,
    color: color,
    '&:not(:last-child)::after': {
      content: '"/"',
      display: 'inline-block',
      ml: '.5rem',
    },
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'stretch', alignItems: 'center', minHeight: '3rem' }}>
      <Tooltip title={hideAssetTree ? 'Show Asset Tree' : 'Hide Asset Tree'} key={tooltipKey}>
        <IconButton
          onClick={() => {
            dispatch(hideAssetTreeAction(!hideAssetTree));
            setTooltipKey(Date.now());
          }}
        >
          {hideAssetTree ? <KeyboardDoubleArrowRightIcon /> : <KeyboardDoubleArrowLeftIcon />}
        </IconButton>
      </Tooltip>
      {assetType !== AssetType.RentalProperty && (
        <Link
          underline={'hover'}
          sx={linkStyle}
          onClick={() => {
            if (isUnit(value)) handleNavigateToParentAsset(AssetType.RentalProperty, value?.propertyId);
            if (isBuilding(value)) handleNavigateToParentAsset(AssetType.RentalProperty, value?.rentalPropertyId);
          }}
        >
          {propertyName}
        </Link>
      )}
      {assetType === AssetType.BuildingUnit && (
        <Link
          underline={'hover'}
          sx={linkStyle}
          onClick={() => isUnit(value) && handleNavigateToParentAsset(AssetType.Building, value?.buildingId)}
        >
          {buildingName}
        </Link>
      )}
      <Typography
        variant="h1"
        sx={{
          color: color,
          marginRight: '1rem',
          fontSize: fontSize,
        }}
      >
        {value.name}
      </Typography>
      {assetType === AssetType.RentalProperty &&
        selectedContext.isSimulated &&
        selectedContext.propertyId &&
        selectedContext.currentDate && (
          <Feature flag={'CAM.Feature.TimeTravel'}>
            <TimeTravel
              propertyId={selectedContext.propertyId}
              propertyDate={toReduxDate(selectedContext.currentDate)}
            />
          </Feature>
        )}
    </Box>
  );
};
