import React, { FormEvent, useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import {
  IContactInfo,
  IPostalAddress,
  IServiceProvider,
  ServiceProviderType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Button from '@mui/material/Button';
import { PhoneTextField } from '../../../../../_shared/texfields/PhoneTextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { DialogLayout } from '../../../../../_shared/dialogs/DialogLayout';

type Props = {
  onAdd: (newServiceProvider: IServiceProvider) => void;
  onClose: () => void;
  open: boolean;
};

export const AddServiceProvider = ({ onAdd, onClose, open }: Props) => {
  const { selectedProperty } = useProperty();
  const [newServiceProvider, setNewServiceProvider] = useState<IServiceProvider>({
    name: '',
    accountNumber: '',
    providerType: ServiceProviderType.Phone,
    description: '',
    contactInfo: {
      postalAddress: {
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        zip: '',
      },
      email: '',
      phone: '',
      website: '',
    },
  });

  useEffect(() => {
    if (selectedProperty.submitted) {
      onClose();
    }
  }, [selectedProperty.submitted]);

  const handleInputChange = (value: string, key: keyof IServiceProvider) => {
    setNewServiceProvider({ ...newServiceProvider, [key]: value });
  };

  const handlePostalAddressChange = (value: string, key: keyof IPostalAddress) => {
    setNewServiceProvider({
      ...newServiceProvider,
      contactInfo: {
        ...newServiceProvider.contactInfo,
        postalAddress: { ...(newServiceProvider?.contactInfo?.postalAddress ?? {}), [key]: value },
      },
    });
  };

  const handleContactInfoChange = (value: string, key: keyof IContactInfo) => {
    setNewServiceProvider({ ...newServiceProvider, contactInfo: { ...newServiceProvider.contactInfo, [key]: value } });
  };

  const addProvider = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onAdd(newServiceProvider);
    setNewServiceProvider({
      name: '',
      accountNumber: '',
      providerType: ServiceProviderType.Phone,
      description: '',
      contactInfo: {
        postalAddress: {
          streetAddress1: '',
          streetAddress2: '',
          city: '',
          state: '',
          zip: '',
        },
        email: '',
        phone: '',
        website: '',
      },
    });
  };

  return (
    <DialogLayout title={'Add Service Provider'} maxWidth="md" fullWidth={true} open={open} onClose={onClose}>
      <DialogContent>
        <form onSubmit={addProvider} autoComplete="off">
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                id="name"
                label="Name"
                type="text"
                margin="dense"
                variant="standard"
                fullWidth
                value={newServiceProvider.name}
                onChange={(e) => {
                  handleInputChange(e.target.value, 'name');
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl required fullWidth margin="dense" variant="standard">
                <InputLabel id="provider-type">Provider Type</InputLabel>
                <Select
                  labelId="provider-type"
                  id="providerType"
                  label="Provider Type"
                  value={newServiceProvider.providerType}
                  onChange={(e) => handleInputChange(e.target.value, 'providerType')}
                >
                  <MenuItem value={ServiceProviderType.Phone}>Phone</MenuItem>
                  <MenuItem value={ServiceProviderType.Gas}>Gas</MenuItem>
                  <MenuItem value={ServiceProviderType.Water}>Water</MenuItem>
                  <MenuItem value={ServiceProviderType.Electric}>Electric</MenuItem>
                  <MenuItem value={ServiceProviderType.Cable}>Cable</MenuItem>
                  <MenuItem value={ServiceProviderType.Insurance}>Insurance</MenuItem>
                  <MenuItem value={ServiceProviderType.Other}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="accountNumber"
                label="Account Number"
                type="text"
                margin="dense"
                variant="standard"
                fullWidth
                value={newServiceProvider.accountNumber}
                onChange={(e) => handleInputChange(e.target.value, 'accountNumber')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="description"
                label="Description"
                type="text"
                margin="dense"
                variant="standard"
                fullWidth
                value={newServiceProvider.description}
                onChange={(e) => handleInputChange(e.target.value, 'description')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                id="streetAddress1"
                label="Street Address 1"
                type="text"
                margin="dense"
                variant="standard"
                fullWidth
                value={newServiceProvider.contactInfo?.postalAddress?.streetAddress1}
                onChange={(e) => handlePostalAddressChange(e.target.value, 'streetAddress1')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="streetAddress2"
                label="Street Address 2"
                type="text"
                margin="dense"
                variant="standard"
                fullWidth
                value={newServiceProvider.contactInfo?.postalAddress?.streetAddress2}
                onChange={(e) => handlePostalAddressChange(e.target.value, 'streetAddress2')}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                required
                id="city"
                label="City"
                type="text"
                margin="dense"
                variant="standard"
                fullWidth
                value={newServiceProvider.contactInfo?.postalAddress?.city}
                onChange={(e) => handlePostalAddressChange(e.target.value, 'city')}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                required
                id="state"
                label="State"
                type="text"
                margin="dense"
                variant="standard"
                fullWidth
                value={newServiceProvider.contactInfo?.postalAddress?.state}
                onChange={(e) => handlePostalAddressChange(e.target.value, 'state')}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                required
                id="zip"
                label="Zip Code"
                type="number"
                margin="dense"
                variant="standard"
                fullWidth
                value={newServiceProvider.contactInfo?.postalAddress?.zip}
                onChange={(e) => handlePostalAddressChange(e.target.value, 'zip')}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="email"
                label="Email"
                type="email"
                margin="dense"
                variant="standard"
                fullWidth
                value={newServiceProvider.contactInfo?.email}
                onChange={(e) => handleContactInfoChange(e.target.value, 'email')}
              />
            </Grid>
            <Grid item xs={4}>
              <PhoneTextField
                id="phone"
                label="Phone"
                type="tel"
                margin="dense"
                variant="standard"
                fullWidth
                value={newServiceProvider.contactInfo?.phone}
                onChange={(e) => handleContactInfoChange(e.target.value, 'phone')}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="website"
                label="Website"
                type="url"
                margin="dense"
                variant="standard"
                fullWidth
                value={newServiceProvider.contactInfo?.website}
                onChange={(e) => handleContactInfoChange(e.target.value, 'website')}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <LoadingButton type="submit" variant="contained" loading={selectedProperty.submitting}>
                  Add Provider
                </LoadingButton>
              </DialogActions>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </DialogLayout>
  );
};
