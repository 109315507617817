import React, { FC, useCallback, useEffect } from 'react';
import { ExpenseTable, JobsTableProps } from './ExpenseTable';
import { useDispatch } from 'react-redux';
import {
  getExpenseGroupsAction,
  setExpandedRowIdsAction,
  unsetGroupedExpenseListAction,
  useExpenses,
} from '../redux/expenseSlice';
import { DataGridPremiumProps, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import { numericFilters, stringFilters } from '../../utils/filteringUtils';
import { GridRowId } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { StripedDataGrid } from '../../datagrids/StripedDataGrid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { GroupedExpenseItem } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const GroupedExpensesTable: FC<JobsTableProps> = ({ includeClosed, onSetSelectedJob, assetType, assetId }) => {
  const dispatch = useDispatch();
  const { assetGroups, groupingInfo } = useExpenses();
  const getDetailPanelContent: DataGridPremiumProps['getDetailPanelContent'] = useCallback(
    ({ row }: GridRowParams<GroupedExpenseItem>) => (
      <Stack sx={{ py: 2, height: 1, boxSizing: 'border-box', backgroundColor: 'lightgray' }} direction="column">
        <Paper sx={{ flex: 1, mx: 'auto', width: '95%', p: 1 }}>
          <Stack direction="column" spacing={1}>
            <ExpenseTable
              assetId={row.itemId!}
              assetType={row.associationType ?? assetType}
              includeClosed={includeClosed}
              onSetSelectedJob={onSetSelectedJob}
              isDetailPanel
            />
          </Stack>
        </Paper>
      </Stack>
    ),
    [assetType, assetId, includeClosed],
  );
  const getDetailPanelHeight = useCallback<NonNullable<DataGridPremiumProps['getDetailPanelHeight']>>(
    () => 'auto' as const,
    [],
  );
  useEffect(() => {
    dispatch(getExpenseGroupsAction({ assetType, assetId, includeClosed }));
  }, [includeClosed, assetType, assetId]);

  const handleExpandedRowIdsChange = (ids: GridRowId[]) => {
    if (ids.length === 0) dispatch(setExpandedRowIdsAction([]));
    else if (ids.length > 0 && ids.length < groupingInfo.expandedRowIds.length) {
      const unsetId = groupingInfo.expandedRowIds.find((id) => !ids.includes(id));
      unsetId && dispatch(unsetGroupedExpenseListAction(unsetId));
    } else dispatch(setExpandedRowIdsAction(ids.map((id) => id.toString())));
  };
  const columns: GridColDef[] = [
    {
      field: 'itemName',
      type: 'string',
      headerName: 'Asset',
      flex: 1.25,
      filterOperators: stringFilters,
      renderCell: (params: GridRenderCellParams) => {
        return <strong>{params.value}</strong>;
      },
    },
    {
      field: 'totalCount',
      type: 'number',
      headerName: 'Count',
      flex: 1.25,
      filterOperators: numericFilters,
      renderHeader: () => {
        if (includeClosed) {
          return <>Total Expenses</>;
        } else return <>Total Open Expenses</>;
      },
      renderCell: (params: GridRenderCellParams) => {
        if (includeClosed) {
          return <>{params.row.totalCount}</>;
        } else
          return (
            <>
              {params.row.totalCount - params.row.openCount} / {params.row.totalCount}
            </>
          );
      },
    },
  ];
  return (
    <Box
      sx={{
        width: 1,
        '& .super-app-theme--inactive': {
          color: 'lightgray',
        },
      }}
    >
      <StripedDataGrid
        disableRowGrouping
        sx={{ minHeight: 500 }}
        autoHeight
        rows={assetGroups.value ?? []}
        loading={assetGroups.loading}
        columns={columns}
        getDetailPanelContent={getDetailPanelContent}
        getRowId={(row) => row.itemId}
        getRowClassName={(params) => {
          return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
        }}
        getDetailPanelHeight={getDetailPanelHeight}
        onDetailPanelExpandedRowIdsChange={handleExpandedRowIdsChange}
        hideFooter
      ></StripedDataGrid>
    </Box>
  );
};
