import { SearchResultItem } from './components/Search';
import {
  AssociationChildType,
  AssociationType,
  IApplicationDto,
  IBuilding,
  IRentalProperty,
  IRoom,
  ISlimBuilding,
  ISlimUnit,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ReduxTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { ReduxExpense } from '../expenses/redux/expenseData';

export const isAsset = (item: SearchResultItem): item is IRentalProperty | IBuilding | ISlimUnit | IRoom => {
  return item?.hasOwnProperty('name') ?? false;
};
export const isLease = (item: SearchResultItem): item is ReduxLease => {
  return item !== null && 'leaseTerms' in item && 'buildingName' in item;
};
export const isApplication = (item: SearchResultItem | null): item is IApplicationDto => {
  return item !== null && 'firstName' in item && 'lastName' in item;
};

export const isTask = (item: SearchResultItem | null): item is ReduxTask => {
  return item !== null && 'category' in item && 'description' in item;
};

export const isBuilding = (item: SearchResultItem | null): item is ISlimBuilding => {
  return item !== null && 'propertyName' in item;
};
export const isUnit = (item: SearchResultItem | null): item is ISlimUnit => {
  return item !== null && 'buildingName' in item;
};

export const isExpense = (item: SearchResultItem): item is ReduxExpense => {
  return item !== null && 'expenseStatus' in item;
};

export const isAssociationType = (type: AssociationType | AssociationChildType): type is AssociationType => {
  if (
    type !== AssociationChildType.Quote &&
    type !== AssociationChildType.Payment &&
    type !== AssociationChildType.PurchaseOrder &&
    type !== AssociationChildType.WorkOrder &&
    type !== AssociationChildType.Invoice
  )
    return true;
  else return false;
};

export const isChildAssociationType = (type: AssociationType | AssociationChildType): type is AssociationChildType => {
  if (
    type === AssociationChildType.Quote ||
    type === AssociationChildType.Payment ||
    type === AssociationChildType.PurchaseOrder ||
    type === AssociationChildType.WorkOrder ||
    type === AssociationChildType.Invoice
  )
    return true;
  else return false;
};

type AssociationMapType = [AssociationChildType | AssociationType, string];

const associationTypeArray: AssociationMapType[] = [
  [AssociationType.RentalProperty, 'Property'],
  [AssociationType.Building, 'Building'],
  [AssociationType.BuildingUnit, 'Unit'],
  [AssociationType.Lease, 'Lease'],
  [AssociationType.Task, 'Task'],
  [AssociationType.Room, 'Room'],
  [AssociationType.Application, 'Application'],
  [AssociationType.Expense, 'Expense'],
  [AssociationType.Detail, 'Detail'],
  [AssociationType.ExpenseInvoice, 'Invoice'],
  [AssociationType.ExpensePayment, 'Payment'],
  [AssociationType.ExpenseQuote, 'Quote'],
  [AssociationType.ExpensePurchaseOrder, 'Purchase Order'],
  [AssociationType.ExpenseWorkOrder, 'Work Order'],
  [AssociationType.ScheduledTask, 'Scheduled Task'],
  [AssociationType.TaskConfiguration, 'SOP Task'],
  [AssociationType.Vendor, 'Vendor'],
  [AssociationChildType.Invoice, 'Invoice'],
  [AssociationChildType.Payment, 'Payment'],
  [AssociationChildType.Quote, 'Quote'],
  [AssociationChildType.PurchaseOrder, 'Purchase Order'],
  [AssociationChildType.WorkOrder, 'Work Order'],
  [AssociationType.EmailThread, 'Email'],
  [AssociationType.Notification, 'Notification'],
  [AssociationType.JournalEntry, 'Income'],
];

export const associationTypeMap = new Map(associationTypeArray);
