import React, { FC } from 'react';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { appTheme } from '../../../../../../../../AppTheme';
import { HistoricalOccupancyData } from './formatHistoricalData';

type HistoricalOccupancyMetricsProps = {
  occupancyData: HistoricalOccupancyData[];
};

export const HistoricalOccupancyMetrics: FC<HistoricalOccupancyMetricsProps> = ({ occupancyData }) => {
  return (
    <ResponsiveContainer width="100%" height={350}>
      <AreaChart
        width={350}
        height={350}
        data={occupancyData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Area
          type="monotone"
          dataKey="Fully Paid"
          stackId="1"
          stroke={appTheme.palette.success.main}
          fill={appTheme.palette.success.main}
        />
        <Area
          type="monotone"
          dataKey="Delinquent"
          stackId="1"
          stroke={appTheme.palette.warning.main}
          fill={appTheme.palette.warning.main}
        />
        <Area
          type="monotone"
          dataKey="Eviction"
          stackId="1"
          stroke={appTheme.palette.error.main}
          fill={appTheme.palette.error.main}
        />
        <Area
          type="monotone"
          dataKey="Vacant"
          stackId="1"
          stroke={appTheme.palette.grey['600']}
          fill={appTheme.palette.grey['600']}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
