import React, { FC, useEffect, useState } from 'react';
import { ExpandableCard } from '../../../_shared/styledComponents/ExpandableCard';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { listTenantTasksAction, setSelectedMaintenanceRequestAction, useTenant } from '../../redux/tenantSlice';
import { MaintenanceRequestList } from './MaintenanceRequestList';
import { AddMaintenanceRequestDialog } from './AddMaintenanceRequestDialog';
import { EditMaintenanceRequestDialog } from './EditMaintenanceRequestDialog';
import { Feature } from '../../../global/Feature';
import Skeleton from '@mui/material/Skeleton';

type MaintenanceRequestCardProps = {
  focus: boolean;
  selectedLeaseId?: string;
};

export const MaintenanceRequestCard: FC<MaintenanceRequestCardProps> = ({ focus, selectedLeaseId }) => {
  const dispatch = useDispatch();
  const { maintenanceRequests } = useTenant();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const handleCloseAdd = () => setOpenAdd(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const handleOpenEdit = (requestId: string) => {
    dispatch(setSelectedMaintenanceRequestAction(requestId));
    setOpenEdit(true);
  };

  useEffect(() => {
    selectedLeaseId && dispatch(listTenantTasksAction({ leaseId: selectedLeaseId }));
  }, [selectedLeaseId]);
  if (maintenanceRequests.loading)
    return <Skeleton variant={'rectangular'} animation={'wave'} width={'100%'} height={250} />;
  return (
    <Feature flag={'CAM.Feature.MaintenanceRequests'}>
      <ExpandableCard
        title={'Maintenance Requests'}
        subtitle={`Total Requests: ${maintenanceRequests?.value?.length ?? 0}`}
        focus={focus}
      >
        {selectedLeaseId ? (
          <>
            <CardContent sx={{ flexGrow: 1 }}>
              <MaintenanceRequestList onOpenEdit={handleOpenEdit} />
            </CardContent>
            <CardActions sx={{ justifyContent: 'right' }}>
              <Button variant={'contained'} onClick={() => setOpenAdd(true)}>
                Make a Request
              </Button>
            </CardActions>
          </>
        ) : (
          <></>
        )}
        <AddMaintenanceRequestDialog open={openAdd} onClose={handleCloseAdd} selectedLeaseId={selectedLeaseId} />
        <EditMaintenanceRequestDialog open={openEdit} onClose={handleCloseEdit} selectedLeaseId={selectedLeaseId} />
      </ExpandableCard>
    </Feature>
  );
};
