import { put, takeLatest } from 'redux-saga/effects';
import { getBalancesAction, getBalancesFailAction, getBalancesSuccessAction } from './accountSlice';
import { IGetHistoricalBalancesHandlerResponse } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { showErrorAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { ReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

function* getHistoricalAccountBalances(action: PayloadAction<{ accountId: string; start: ReduxDate; end: ReduxDate }>) {
  try {
    const response: IGetHistoricalBalancesHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().accounts_GetHistoricalBalances,
      action.payload.accountId,
      action.payload.start,
      action.payload.end,
    );
    yield put(getBalancesSuccessAction(response));
  } catch (error) {
    yield put(getBalancesFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to get account balances' }));
  }
}

export function* accountSaga() {
  yield takeLatest(getBalancesAction.type, getHistoricalAccountBalances);
}
