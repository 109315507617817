import React, { FC, useEffect } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import { EmailThreadDetailView } from '../EmailThreadDetailView';
import { assignReplyTypes, stripThreadId } from '@monkeyjump-labs/cam-fe-shared/dist/utils/emailUtils';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { EmailType, ReduxEmailThread } from '@monkeyjump-labs/cam-fe-shared/dist/types/communicationTypes';
import {
  setNewTaskFromAssociationValuesAction,
  createTaskFromEmailUsingAIAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import {
  addWatcherToEmailThreadAction,
  getEmailByIdAction,
  removeWatcherFromEmailThreadAction,
  setDetailedEmailInfoAction,
  setOpenEmailDialogAction,
  unselectEmailThreadAction,
  useCommunication,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/communications/communicationSlice';
import { WatcherAutocomplete } from '../../../tasks/components/WatcherAutocomplete';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useRouting } from '../../../utils/useRouting';

type ViewEmailThreadParams = {
  id: string;
  outerTab: string;
  innerTab: string;
  emailThreadId: string;
};

export const ViewEmailDialog: FC = () => {
  const dispatch = useDispatch();
  const { selectedEmailThread } = useCommunication();
  const { emailThreadId } = useParams<ViewEmailThreadParams>();
  const { handleNavigateFromAssociationClose } = useRouting();
  const selectedEmail: ReduxEmailThread | undefined = selectedEmailThread.value;
  const watchers =
    selectedEmailThread.value?.watchers?.filter((watcher) => !!watcher?.id)?.map((watcher) => watcher.id!) ?? [];

  useEffect(() => {
    if (emailThreadId) dispatch(getEmailByIdAction(emailThreadId));
  }, [emailThreadId]);

  const handleClose = () => {
    dispatch(unselectEmailThreadAction());
    handleNavigateFromAssociationClose();
  };

  const handleReply = (emailThread: ReduxEmailThread) => {
    const replyEmailAddresses = assignReplyTypes(emailThread, 'recipient');
    const ccEmailAddresses = assignReplyTypes(emailThread, 'cc');
    dispatch(setDetailedEmailInfoAction({ recipients: replyEmailAddresses, cc: ccEmailAddresses }));
    dispatch(setOpenEmailDialogAction(EmailType.Reply));
  };

  const handleOpenAddTask = (id: string, fromAI?: boolean) => {
    if (selectedEmail) {
      if (fromAI) {
        dispatch(createTaskFromEmailUsingAIAction({ email: selectedEmail.emails[0], emailThreadId: id }));
      } else
        dispatch(
          setNewTaskFromAssociationValuesAction({
            id: id,
            valueAssociationType: AssociationType.EmailThread,
            name: selectedEmail.emails[0].subject ? stripThreadId(selectedEmail.emails[0].subject) : '',
            description: selectedEmail.emails[0].body ?? '',
          }),
        );
    }

    handleClose();
  };

  const handleUpdateWatchersForThread = (updatedWatchers: string[]) => {
    if (!selectedEmailThread.value?.id) return;

    const watchers = selectedEmailThread.value?.watchers?.map((watcher) => watcher.id ?? '') ?? [];
    const addedWatchers = updatedWatchers.filter((watcher) => !watchers.includes(watcher));

    if (addedWatchers.length === 1)
      dispatch(
        addWatcherToEmailThreadAction({
          threadId: selectedEmailThread.value?.id,
          userId: addedWatchers[0],
        }),
      );

    const removedWatchers = watchers.filter((watcher) => !updatedWatchers.includes(watcher));
    if (removedWatchers.length === 1)
      dispatch(
        removeWatcherFromEmailThreadAction({
          threadId: selectedEmailThread.value?.id,
          userId: removedWatchers[0],
        }),
      );
  };

  return (
    <DialogLayout open={!!selectedEmail} onClose={handleClose} title={'Email Detail'} maxWidth={'lg'}>
      <DialogContent>
        <Stack sx={{ minHeight: 200, pt: '1rem' }} spacing={2}>
          <Typography>
            Participants:{' '}
            {selectedEmail && selectedEmail.emails[0].participants
              ? (selectedEmail.emails[0].participants.map((x) => x).join(', ') ?? '')
              : ''}
          </Typography>
          <Typography>Subject: {stripThreadId(selectedEmail?.subject ?? '')}</Typography>
          {selectedEmail && (
            <EmailThreadDetailView
              row={selectedEmail}
              onReply={handleReply}
              inModal
              onOpenAddTask={handleOpenAddTask}
            />
          )}
          <WatcherAutocomplete watchers={watchers} setWatchers={handleUpdateWatchersForThread} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button variant={'contained'} onClick={() => selectedEmail && handleReply(selectedEmail)}>
          Reply
        </Button>
      </DialogActions>
    </DialogLayout>
  );
};
