import React, { useEffect, useState } from 'react';
import { FilterOption } from './FilterOption';
import Grid from '@mui/material/Grid';
import { useReports } from '../../../../redux/reportSlice';

export const CustomizeFilters = () => {
  const { selectedFilters } = useReports();
  const [filterOptions, setFilterOptions] = useState(selectedFilters.value ?? []);

  useEffect(() => {
    setFilterOptions(selectedFilters.value ?? []);
  }, [selectedFilters.value]);

  return (
    <Grid container spacing={1} p={1}>
      {filterOptions?.map((f) => (
        <Grid key={f.name} item xs={6}>
          <FilterOption value={f} />
        </Grid>
      ))}
    </Grid>
  );
};
