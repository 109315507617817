import React, { FC, useState } from 'react';
import { DialogLayout } from '../../dialogs/DialogLayout';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { AccountAutocomplete } from '../../autocompletes/AccountAutocomplete';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import TextField from '@mui/material/TextField';
import {
  ISlimAccountRef,
  LinkedPaymentAccountType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type ChangeDepositAccountPopperProps = {
  open: boolean;
  onChangeDepositClose: () => void;
  currentDepositAccountId?: string;
  onChangeDepositAccount: (account?: ISlimAccountRef) => void;
};

export const ChangeDepositAccountPopper: FC<ChangeDepositAccountPopperProps> = ({
  onChangeDepositClose,
  onChangeDepositAccount,
  open,
  currentDepositAccountId,
}) => {
  const { selectedContext } = useAssets();
  const [newDepositAccount, setNewDepositAccount] = useState<ISlimAccountRef>();

  const handleDepositAccountChange = (value: ISlimAccountRef) => {
    setNewDepositAccount(value);
  };

  const handleClose = () => {
    setNewDepositAccount(undefined);
    onChangeDepositClose();
  };

  return (
    <DialogLayout title={'Change Deposit Account'} id="change-deposit" open={open} onClose={handleClose}>
      <Stack spacing={2} p={1} pt={2}>
        <AccountAutocomplete
          propertyId={selectedContext.propertyId!}
          value={newDepositAccount}
          onChange={handleDepositAccountChange}
          renderInput={(params) => <TextField label={'Deposit Account'} {...params} />}
          filterOptions={(options) =>
            options.filter(
              (option) =>
                option.linkedPaymentAccountType === LinkedPaymentAccountType.BankAccount &&
                option.id !== currentDepositAccountId,
            )
          }
        />
        <Box display={'flex'} flexGrow={1} justifyContent={'right'}>
          <Button
            variant={'contained'}
            onClick={() => onChangeDepositAccount(newDepositAccount)}
            disabled={!newDepositAccount}
          >
            Change Deposit Account
          </Button>
        </Box>
      </Stack>
    </DialogLayout>
  );
};
