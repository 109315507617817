import React, { FC, useEffect, useState } from 'react';
import { clearSearchAction, useSearch } from '../redux/searchSlice';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch } from 'react-redux';
import { SearchListItem } from './SearchListItem';
import { SearchAutocompleteTextField } from './SearchAutocompleteTextField';
import { SearchOption, useSearchHook } from '../useSearchHook';
import Divider from '@mui/material/Divider';

type SearchAutocompleteProps = {
  selectedValue?: SearchOption | undefined;
  onSearch: (includeClosed: boolean, searchText: string) => void;
  onSearchSelect: (value?: SearchOption) => void;
  global: boolean;
  limitToSelectedProperty: boolean;
  onSetLimitToProperty: () => void;
  placeholder?: string;
};

export const SearchAutocomplete: FC<SearchAutocompleteProps> = ({
  onSearch,
  onSearchSelect,
  selectedValue,
  global,
  limitToSelectedProperty,
  onSetLimitToProperty,
  placeholder,
}) => {
  const dispatch = useDispatch();
  const search = useSearch();
  const [options, setOptions] = React.useState<SearchOption[]>([]);
  const [includeClosed, setIncludeClosed] = useState(false);
  const { fillOptions } = useSearchHook();
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof event?.target?.value !== 'string') {
      setOptions([]);
      return;
    }
    onSearch(includeClosed, event.target.value);
  };

  useEffect(() => {
    if (search.searchAllAssetsResults.loaded) {
      setOptions(fillOptions(search.searchAllAssetsResults.value, global));
    }
  }, [search.searchAllAssetsResults.value]);

  return (
    <Autocomplete
      fullWidth
      freeSolo
      blurOnSelect={true}
      clearOnBlur={true}
      clearOnEscape={true}
      selectOnFocus={true}
      onClose={() => dispatch(clearSearchAction())}
      value={selectedValue ?? ''}
      renderInput={(params) => (
        <SearchAutocompleteTextField
          includeClosed={includeClosed}
          onChangeIncludeClosed={() => setIncludeClosed(!includeClosed)}
          onSearch={handleSearch}
          global={global}
          limitToSelectedProperty={limitToSelectedProperty}
          onSetLimitToProperty={onSetLimitToProperty}
          placeholder={placeholder}
          {...params}
        />
      )}
      filterOptions={() => options}
      options={options}
      onChange={(event, value, reason) => {
        if (!value || reason !== 'selectOption') {
          setOptions([]);
          return;
        }
        if (typeof value === 'string') {
          return;
        }
        onSearchSelect(value);
      }}
      renderOption={(props, option, { index }) => (
        <React.Fragment key={option.item?.id}>
          {index > 0 && <Divider />}
          <SearchListItem {...props} option={option} />
        </React.Fragment>
      )}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Regular option
        return `${option.label}`;
      }}
      isOptionEqualToValue={(option, value) => {
        return option.item?.id === value.item?.id;
      }}
    />
  );
};
