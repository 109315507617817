import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import {
  AssociationType,
  IApplicationDto,
  IExpenseSubItemSearchDto,
  IRentalProperty,
  IRoom,
  IScheduledTask,
  ISlimBuilding,
  ISlimUnit,
  IVendor,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  initLoadable,
  initSubmittable,
  Loadable,
  Submittable,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { ReduxExpense } from '../../expenses/redux/expenseData';
import { ReduxDetail } from '@monkeyjump-labs/cam-fe-shared/dist/types/detailTypes';
import { ReduxSopTask, ReduxTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';

export interface ISearchAllAssetsResult {
  properties: IRentalProperty[];
  buildings: ISlimBuilding[];
  units: ISlimUnit[];
  rooms: IRoom[];
  applications: IApplicationDto[];
  leases: ReduxLease[];
  tasks: ReduxTask[];
  expenses: ReduxExpense[];
  details: ReduxDetail[];
  expenseSubItems: IExpenseSubItemSearchDto[];
  scheduledTasks: IScheduledTask[];
  sopTasks: ReduxSopTask[];
  vendors: IVendor[];
}

export interface AssociationSearchParentInfo {
  id: string;
  type: AssociationType;
  poInfo?: {
    poNumber: string;
    lineItemNo: string;
    changeOrderNo?: string;
  };
}

export interface SearchState {
  searchAllAssetsResults: Loadable<ISearchAllAssetsResult>;
  associationSearchParent: Submittable<AssociationSearchParentInfo>;
}

const initialState: SearchState = {
  searchAllAssetsResults: initLoadable<ISearchAllAssetsResult>({
    properties: [],
    buildings: [],
    rooms: [],
    units: [],
    applications: [],
    leases: [],
    tasks: [],
    expenses: [],
    details: [],
    expenseSubItems: [],
    scheduledTasks: [],
    sopTasks: [],
    vendors: [],
  }),
  associationSearchParent: initSubmittable<AssociationSearchParentInfo>(),
};
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const searchSlice = createSlice({
  name: 'search',
  initialState: initialState,
  reducers: {
    searchAllAssetsAction: (
      state,
      action: PayloadAction<{
        searchText: string;
        searchTypes: AssociationType[] | null;
        includeClosed: boolean;
        propertyId?: string;
      }>,
    ) => {
      state.searchAllAssetsResults.loading = true;
      state.searchAllAssetsResults.loaded = false;
    },
    searchAllAssetsSuccessAction: (state, action: PayloadAction<ISearchAllAssetsResult>) => {
      state.searchAllAssetsResults.loaded = true;
      state.searchAllAssetsResults.loading = false;
      state.searchAllAssetsResults.value = action.payload;
    },
    searchAllAssetsFailAction: (state) => {
      state.searchAllAssetsResults.loaded = false;
      state.searchAllAssetsResults.loading = false;
    },
    clearSearchAction: (state) => {
      state.searchAllAssetsResults.value = undefined;
    },
    setAssociationSearchParentAction: (state, action: PayloadAction<AssociationSearchParentInfo | undefined>) => {
      state.associationSearchParent.submittingValue = action.payload;
    },
    setAssociationSubmittingAction: (state) => {
      state.associationSearchParent.submitting = true;
      state.associationSearchParent.submitted = false;
    },
    setAssociationSubmittingSuccessAction: (state) => {
      state.associationSearchParent.submitting = false;
      state.associationSearchParent.submitted = true;
    },
    resetAssociationSubmissionAction: (state) => {
      state.associationSearchParent.submitting = false;
      state.associationSearchParent.submitted = false;
    },
  },
});

export const {
  searchAllAssetsAction,
  searchAllAssetsSuccessAction,
  searchAllAssetsFailAction,
  setAssociationSearchParentAction,
  clearSearchAction,
  setAssociationSubmittingAction,
  setAssociationSubmittingSuccessAction,
  resetAssociationSubmissionAction,
} = searchSlice.actions;

export const useSearch = () => useSelector((state: RootState) => state.search);
