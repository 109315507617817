import React from 'react';
import { PropertyAddressInfo } from '../propertyInfo/PropertyAddressInfo';
import { SiteManagerInfo } from '../propertyInfo/SiteManagerInfo';
import { ServiceProvidersList } from '../propertyInfo/ServiceProvidersList';
import Stack from '@mui/material/Stack';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';

export const PropertyInfo = () => {
  const property = useProperty();
  if (!property.selectedProperty.value) return <></>;

  return (
    <Stack spacing={2}>
      <PropertyAddressInfo property={property.selectedProperty.value} />
      <SiteManagerInfo property={property.selectedProperty.value} />
      <ServiceProvidersList property={property.selectedProperty.value} />
    </Stack>
  );
};
