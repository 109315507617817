import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { appTheme } from '../../../../../../../../../AppTheme';
import { CustomTooltip } from './CustomChartComponents';
import { PieChartData } from '../../Metrics';

type OccupancyPieChartProps = {
  firstPieData: PieChartData[];
  secondPieData: PieChartData[];
  thirdPieData: PieChartData[];
  fourthPieData: PieChartData[];
  pieFocus: number;
};

export const OccupancyPieChart = ({
  firstPieData,
  secondPieData,
  thirdPieData,
  fourthPieData,
  pieFocus,
}: OccupancyPieChartProps) => {
  return (
    <ResponsiveContainer width={'100%'} height={350}>
      <PieChart width={400} height={350}>
        <Pie
          data={firstPieData}
          dataKey="value"
          cx="50%"
          cy="50%"
          outerRadius={pieFocus === 0 ? 90 : 30}
          fill={appTheme.palette.secondary.main}
        >
          {' '}
          {firstPieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Pie
          data={secondPieData}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={pieFocus !== 0 ? 30 : 90}
          outerRadius={pieFocus === 0 || pieFocus === 1 ? 110 : 50}
        >
          {secondPieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Pie
          data={thirdPieData}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={pieFocus === 2 || pieFocus === 3 ? 50 : 110}
          outerRadius={pieFocus === 3 ? 70 : 130}
        >
          {thirdPieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Pie
          data={fourthPieData}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={pieFocus === 3 ? 70 : 130}
          outerRadius={150}
        >
          {fourthPieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};
