import React, { FC, useEffect, useState } from 'react';
import { ReduxDetail } from '@monkeyjump-labs/cam-fe-shared/dist/types/detailTypes';
import { DetailsObservationItem } from './DetailsObservationItem';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { DroppableComponent } from '../../../dragAndDrop/DroppableComponent';

export type DetailsObservationsListProps = {
  detail: ReduxDetail;
  onAddFollowUpNote: (observationId: number, note: string) => void;
  onInitializeObservation: (description: string) => void;
  onDeleteObservation: (observationId: number) => void;
  isEditingMode?: boolean;
  onReorderObservations: (observationIds: number[]) => void;
};

export const DetailsObservationList: FC<DetailsObservationsListProps> = ({
  detail,
  onAddFollowUpNote,
  onInitializeObservation,
  onDeleteObservation,
  isEditingMode,
  onReorderObservations,
}) => {
  const dispatch = useDispatch();
  const [newObservationDescription, setNewObservationDescription] = useState<string>('');
  const [localObservations, setLocalObservations] = useState(detail.observations);
  const highlightedSelectedObservationId = new URLSearchParams(location.search).get('selectedObservation');

  useEffect(() => {
    setLocalObservations(detail.observations);
  }, [detail.observations]);

  const initializeObservation = () => {
    if (newObservationDescription !== '') {
      onInitializeObservation(newObservationDescription);
      setNewObservationDescription('');
    } else
      dispatch(
        showToastMessageAction({
          message: 'You must enter a description before submitting',
          severity: 'warning',
        }),
      );
  };

  const handleDragEnd = (sourceIndex: number, destinationIndex: number, id: string) => {
    if (!detail.observations) return;
    const newDocIds: number[] = detail.observations?.map((c) => c.id!);
    newDocIds.splice(sourceIndex, 1);
    newDocIds.splice(destinationIndex, 0, Number(id));
    onReorderObservations(newDocIds);
  };

  const handleDragUpdate = (destinationIndex: number, id: string) => {
    if (!localObservations) return;
    const insertItem = [...localObservations].find((item) => item.id === Number(id));
    if (!insertItem) return;
    const filteredObservationList = localObservations.filter((item) => item.id !== Number(id));
    filteredObservationList.splice(destinationIndex, 0, insertItem);
    setLocalObservations(filteredObservationList);
  };

  return (
    <StyledInfoBox label={'Observations'}>
      <Stack spacing={1}>
        <TextField
          fullWidth
          multiline
          rows={3}
          required
          label={'Description'}
          value={newObservationDescription}
          onChange={(e) => {
            setNewObservationDescription(e.target.value);
          }}
        />
        <Box display={'flex'} flexGrow={1} justifyContent={'right'}>
          <LoadingButton variant={'outlined'} loading={false} onClick={initializeObservation} startIcon={<AddIcon />}>
            Observation
          </LoadingButton>
        </Box>
      </Stack>
      <DroppableComponent
        droppableId={'droppable-observation-list'}
        onDragEnd={handleDragEnd}
        onDragUpdate={handleDragUpdate}
      >
        {detail.observations?.map((observation, index) => {
          return (
            <DetailsObservationItem
              key={observation.id}
              observation={observation}
              onAddFollowUpNote={onAddFollowUpNote}
              onDeleteObservation={onDeleteObservation}
              isEditingMode={isEditingMode}
              propertyId={detail.propertyId}
              index={index}
              isSelected={
                highlightedSelectedObservationId !== undefined &&
                highlightedSelectedObservationId === observation.id?.toString()
              }
            />
          );
        })}
      </DroppableComponent>
    </StyledInfoBox>
  );
};
