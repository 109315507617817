import React, { FC, FormEvent, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContentText from '@mui/material/DialogContentText';
import { useDispatch } from 'react-redux';
import { inviteTenantAction, useLeases } from '../leases/redux/leasesSlice';
import { LeaseDispatchType, ReduxTenant } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { DialogLayout } from '../dialogs/DialogLayout';

type InviteTenantDialogProps = {
  open: boolean;
  onClose: () => void;
  leaseId: string;
  tenant?: ReduxTenant;
  assetId: string;
  leasesType: LeaseDispatchType;
};

export const InviteTenantDialog: FC<InviteTenantDialogProps> = ({
  open,
  onClose,
  leaseId,
  tenant,
  assetId,
  leasesType,
}) => {
  const dispatch = useDispatch();
  const { activeLease } = useLeases();
  const [tenantEmail, setTenantEmail] = useState<string>(tenant?.contactInfo?.email ? tenant.contactInfo.email : '');

  useEffect(() => {
    tenant?.contactInfo?.email && setTenantEmail(tenant?.contactInfo?.email);
  }, [tenant]);

  useEffect(() => {
    activeLease.submitted && onClose();
  }, [activeLease.submitted]);

  const handleInviteTenant = (e: FormEvent) => {
    e.preventDefault();
    tenant?.id &&
      dispatch(
        inviteTenantAction({
          leaseId: leaseId,
          email: tenantEmail,
          tenantId: tenant.id,
          assetId: assetId,
          leaseType: leasesType,
        }),
      );
  };

  return (
    <DialogLayout title={'Invite Tenant'} open={open} onClose={onClose}>
      <form onSubmit={handleInviteTenant} autoComplete="off">
        <DialogContent>
          <DialogContentText>Invite tenant to access this lease through the tenant portal.</DialogContentText>
          <Box sx={{ marginTop: 1 }}>
            <Stack spacing={1}>
              <TextField
                value={tenantEmail ?? ''}
                label={'Email'}
                type={'email'}
                required
                onChange={(e) => setTenantEmail(e.target.value)}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <LoadingButton variant="contained" color="primary" type="submit" loading={activeLease.submitting}>
            Invite Tenant
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};
