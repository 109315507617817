import Box from '@mui/material/Box';
import React, { FC, useRef, useState } from 'react';
import { Popover } from '@mui/material';
import { ColorPicker } from '../../../../../_shared/ColorPicker';
import LoadingButton from '@mui/lab/LoadingButton';

type ColorCellProps = {
  color?: string;
  onChange: (color: string) => void;
  onSubmit: () => void;
};

export const ColorCell: FC<ColorCellProps> = ({ color, onChange, onSubmit }) => {
  const [showPicker, setShowPicker] = useState(false);
  const ref = useRef();
  return (
    <>
      <Box
        ref={ref}
        onClick={() => setShowPicker(!showPicker)}
        style={{
          backgroundColor: color,
          border: !color ? '1px solid black' : 'none',
          width: 25,
          height: 25,
          borderRadius: 50,
        }}
      />
      {showPicker && (
        <Popover
          open={showPicker}
          onClose={() => setShowPicker(false)}
          anchorEl={ref.current}
          // Add additional positioning properties if needed
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ColorPicker color={color ?? ''} onChange={onChange} />
          <Box display={'flex'} justifyContent={'center'} p={1}>
            <LoadingButton
              onClick={() => {
                onSubmit();
                setShowPicker(false);
              }}
              loading={false}
              loadingPosition="start"
              variant="contained"
              color="primary"
            >
              Update
            </LoadingButton>
          </Box>
        </Popover>
      )}
    </>
  );
};
