import React from 'react';
import Stack from '@mui/material/Stack';
import { PropertyEmailAddressConfiguration } from '../configuration/PropertyEmailAddressConfiguration';
import { TenantAccessConfiguration } from '../configuration/TenantAccessConfiguration';
import { CreditCardPaymentConfiguration } from '../configuration/CreditCardPaymentConfiguration';
import { PhoneNumberConfiguration } from '../configuration/PhoneNumberConfiguration';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import { PropertyShortCodeConfiguration } from '../configuration/PropertyShortCodeConfiguration';

export const PropertyConfiguration = () => {
  return (
    <StyledInfoBox label={'Configuration'}>
      <Stack spacing={3}>
        <PropertyShortCodeConfiguration />
        <PropertyEmailAddressConfiguration />
        <TenantAccessConfiguration />
        <CreditCardPaymentConfiguration />
        <PhoneNumberConfiguration />
      </Stack>
    </StyledInfoBox>
  );
};
