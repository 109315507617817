import React, { useState } from 'react';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';

export const UseParentNotificationConfig = () => {
  const [useParentConfig, setUseParentConfig] = useState(true);

  const handleEnableToggle = () => {
    setUseParentConfig(true);
  };

  return (
    <StyledInfoBox label={'Notification Configuration'}>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={useParentConfig} onChange={handleEnableToggle} />}
            label="Use Parent Asset Notification Configuration"
          />
        </FormGroup>
      </Box>
    </StyledInfoBox>
  );
};
