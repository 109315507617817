import React, { useEffect } from 'react';
import { getPropertyByIdAction, useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedContextAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { AssetParams } from '../../../AppRouter';
import { OuterTabsLayout } from '../../global/layout/OuterTabsLayout';
import { AssetType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const PropertyTabs = () => {
  const property = useProperty();
  const { id } = useParams<AssetParams>();
  const dispatch = useDispatch();

  useEffect(() => {
    id && dispatch(getPropertyByIdAction(id));
    dispatch(setSelectedContextAction({ propertyId: id }));
  }, [id]);

  return (
    <OuterTabsLayout
      assetType={AssetType.RentalProperty}
      loading={property.selectedProperty.loading}
      value={property.selectedProperty.value}
    />
  );
};
