import React, { FC, PropsWithChildren, useState } from 'react';
import { imageExtensionTypes } from './DocumentList';
import ReactDOM from 'react-dom';

type ImageViewComponentProps = PropsWithChildren<{
  imageUrl?: string;
  docType?: string;
}>;

export const ImagePreviewComponent: FC<ImageViewComponentProps> = ({ imageUrl, docType, children }) => {
  const [hover, setHover] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const updatePosition = (e: React.MouseEvent<HTMLDivElement>) => {
    const xOffset = 15;
    const yOffset = 15;
    setTop(e.clientY + yOffset);
    setLeft(e.clientX + xOffset);
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    setHover(true);
    updatePosition(e);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    updatePosition(e);
  };

  if (!imageUrl || !docType || !imageExtensionTypes.includes(docType)) return <>{children}</>;

  const imagePreview = hover
    ? ReactDOM.createPortal(
        <img
          alt={'photo-preview'}
          src={imageUrl}
          style={{
            position: 'fixed',
            maxHeight: 100,
            maxWidth: 100,
            left: left,
            top: top,
            zIndex: 9999,
          }}
        />,
        document.body,
      )
    : null;

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove}>
      {imagePreview}
      {children}
    </div>
  );
};
