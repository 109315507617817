import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { ReportDrawer } from './ReportDrawer';
import { convertVariableToDisplayName } from './useFormatReportData';
import Typography from '@mui/material/Typography';
import { generateReportAction, useReports } from '../../../../redux/reportSlice';
import { useDispatch } from 'react-redux';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { ReportsDataGrid } from './ReportsDataGrid';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ReportFilterType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const ReportDisplay = () => {
  const dispatch = useDispatch();
  const { generatedReport, selectedReport, selectedFilters, selectedGrouping } = useReports();
  const { selectedContext } = useAssets();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    generateReport();
  }, [selectedFilters.value, selectedGrouping.value]);

  const generateReport = () => {
    const filters = selectedFilters.value?.reduce((a, v) => ({ ...a, [v.name!]: v.value }), {});
    selectedReport.value?.name &&
      selectedContext.propertyId &&
      dispatch(
        generateReportAction({
          propertyId: selectedContext.propertyId,
          reportName: selectedReport.value?.name,
          data: filters,
          grouping: selectedGrouping?.value,
        }),
      );
  };

  return (
    <Stack spacing={2} sx={{ mt: 1 }}>
      <Box display={'flex'} flexGrow={1} justifyContent={'space-between'}>
        <Typography variant={'h4'}>Report: {convertVariableToDisplayName(selectedReport.value?.name)}</Typography>
        <Stack direction={'row'}>
          <IconButton onClick={generateReport}>
            <RefreshIcon />
          </IconButton>
          <Button variant={'contained'} onClick={() => setOpen(true)}>
            Customize
          </Button>
        </Stack>
      </Box>
      <Stack direction={'column'}>
        {selectedFilters.value?.map((f) => (
          <Stack key={f.name} direction={'row'}>
            <Typography>{`${f.label}: ${
              f.type === ReportFilterType.Date ? (toStandardDate(toReduxDate(f.value))?.toDateString() ?? '') : f.value
            }`}</Typography>
          </Stack>
        ))}
        {generatedReport.value?.details?.map((d) => (
          <Stack key={d.name} direction={'row'}>
            <Typography>{`${d.name}: ${
              toReduxDate(d.value) ? toStandardDate(toReduxDate(d.value))?.toDateString() : d.value
            }`}</Typography>
          </Stack>
        ))}
      </Stack>
      <Box sx={{ minHeight: 350 }}>
        <ReportsDataGrid type={'standard'} />
      </Box>
      <ReportDrawer onClose={() => setOpen(false)} open={open} />
    </Stack>
  );
};
