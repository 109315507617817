import { all, put, race, take, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  addDocumentToEmailTemplateAction,
  addEmailTemplateAction,
  addEmailTemplateFailAction,
  addEmailTemplateSuccessAction,
  deleteEmailTemplateAction,
  deleteEmailTemplateFailAction,
  deleteEmailTemplateSuccessAction,
  failUploadDocumentForEmailTemplateAction,
  fetchKeysAction,
  fetchKeysFailAction,
  fetchKeysSuccessAction,
  listEmailTemplatesAction,
  listEmailTemplatesFailAction,
  listEmailTemplatesSuccessAction,
  removeDocumentFromEmailTemplateAction,
  removeDocumentFromEmailTemplateFailAction,
  removeDocumentFromEmailTemplateSuccessAction,
  resetEmailTemplateSubmissionAction,
  successUploadDocumentForEmailTemplateAction,
  updateEmailTemplateAction,
  updateEmailTemplateFailAction,
  updateEmailTemplateSuccessAction,
} from './emailTemplateSlice';
import {
  AddDocumentToEmailTemplateHandlerResponse,
  AddEmailTemplateHandlerRequest,
  AddEmailTemplateHandlerResponse,
  ListEmailTemplateKeywordsHandlerResponse,
  ListEmailTemplatesHandlerResponse,
  PaginatedQueryExpression,
  UpdateEmailTemplateHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import {
  cancelConfirmDialogAction,
  okConfirmDialogAction,
  showConfirmDialogAction,
  showErrorAction,
  showToastMessageAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { ReduxEmailTemplate } from '@monkeyjump-labs/cam-fe-shared/dist/types/emailTemplateTypes';

function* listEmailTemplates(action: PayloadAction<{ propertyId: string; page: number; pageSize: number }>) {
  try {
    const query = {
      page: action.payload.page,
      pageSize: action.payload.pageSize,
    };

    const response: ListEmailTemplatesHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().emailTemplates_ListTemplates,
      action.payload.propertyId,
      PaginatedQueryExpression.fromJS(query),
    );

    yield put(listEmailTemplatesSuccessAction(response.toJSON()));
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to load email templates' }));
    yield put(listEmailTemplatesFailAction());
  }
}

function* addEmailTemplate(action: PayloadAction<{ propertyId: string; emailTemplate: ReduxEmailTemplate }>) {
  try {
    const body = new AddEmailTemplateHandlerRequest(action.payload.emailTemplate);
    const response: AddEmailTemplateHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().emailTemplates_AddTemplate,
      action.payload.propertyId,
      body,
    );
    yield put(showToastMessageAction({ message: 'Email template added' }));
    yield put(
      addEmailTemplateSuccessAction({ newTemplate: { ...action.payload.emailTemplate, id: response.toJSON().id } }),
    );
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to add email template' }));
    yield put(addEmailTemplateFailAction());
  }
}

function* deleteEmailTemplate(action: PayloadAction<{ id: string }>) {
  try {
    yield put(
      showConfirmDialogAction({
        title: 'Are you sure?',
        message: 'This will delete the email template.',
        okText: 'Yes',
        cancelText: 'No',
      }),
    );
    const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
    if (yes) {
      yield apiCall(ApiClientSingleton.getInstance().emailTemplates_DeleteTemplate, action.payload.id);
      yield put(deleteEmailTemplateSuccessAction({ id: action.payload.id }));
    } else {
      yield put(resetEmailTemplateSubmissionAction());
    }
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to delete email template' }));
    yield put(deleteEmailTemplateFailAction());
  }
}

function* updateEmailTemplate(action: PayloadAction<{ id: string; emailTemplate: ReduxEmailTemplate }>) {
  try {
    const body = new UpdateEmailTemplateHandlerRequest(action.payload.emailTemplate);
    yield apiCall(ApiClientSingleton.getInstance().emailTemplates_UpdateTemplate, action.payload.id, body);
    yield put(showToastMessageAction({ message: 'Email template updated' }));
    yield put(
      updateEmailTemplateSuccessAction({ updatedTemplate: { ...action.payload.emailTemplate, id: action.payload.id } }),
    );
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to update email template' }));
    yield put(updateEmailTemplateFailAction());
  }
}

function* fetchKeys(action: PayloadAction<string>) {
  try {
    const response: ListEmailTemplateKeywordsHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().emailTemplates_ListKeywords,
      action.payload,
    );
    yield put(fetchKeysSuccessAction(response.toJSON().keywords));
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to fetch keys' }));
    yield put(fetchKeysFailAction());
  }
}

function* uploadDocumentToEmailTemplate(action: PayloadAction<{ id: string; file: File }>) {
  try {
    const response: AddDocumentToEmailTemplateHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().emailTemplates_UploadDocument,
      action.payload.id,
      {
        data: action.payload.file,
        fileName: action.payload.file.name,
      },
    );
    console.log('resonse: ', response.toJSON());
    yield put(
      successUploadDocumentForEmailTemplateAction({ id: action.payload.id, document: response.toJSON().document }),
    );
    yield put(showToastMessageAction({ message: 'Document uploaded successfully!', severity: 'success' }));
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem uploading document to email template' }));
    yield put(failUploadDocumentForEmailTemplateAction());
  }
}

function* removeDocumentFromEmailTemplate(action: PayloadAction<{ id: string; documentId: string }>) {
  try {
    yield put(
      showConfirmDialogAction({
        message: 'Are you sure you want to delete this document?',
        okText: 'Yes',
        cancelText: 'No',
      }),
    );
    const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
    if (yes) {
      yield apiCall(
        ApiClientSingleton.getInstance().emailTemplates_RemoveDocument,
        action.payload.id,
        action.payload.documentId,
      );
      yield put(showToastMessageAction({ message: 'Document removed successfully!', severity: 'success' }));
      yield put(
        removeDocumentFromEmailTemplateSuccessAction({ id: action.payload.id, documentId: action.payload.documentId }),
      );
    } else {
      yield put(removeDocumentFromEmailTemplateFailAction());
    }
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem removing document from email template' }));
    yield put(removeDocumentFromEmailTemplateFailAction());
  }
}

export function* emailTemplateSaga() {
  yield all([
    takeLatest(listEmailTemplatesAction.type, listEmailTemplates),
    takeLatest(addEmailTemplateAction.type, addEmailTemplate),
    takeLatest(deleteEmailTemplateAction.type, deleteEmailTemplate),
    takeLatest(updateEmailTemplateAction.type, updateEmailTemplate),
    takeLatest(fetchKeysAction.type, fetchKeys),
    takeLatest(addDocumentToEmailTemplateAction.type, uploadDocumentToEmailTemplate),
    takeLatest(removeDocumentFromEmailTemplateAction.type, removeDocumentFromEmailTemplate),
  ]);
}
