import React, { FC } from 'react';
import { ExpandableCard } from '../../../_shared/styledComponents/ExpandableCard';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useTenant } from '../../redux/tenantSlice';
import { ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { LeaseDetailedStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Skeleton from '@mui/material/Skeleton';
import { useLeases } from '../../../_shared/leases/redux/leasesSlice';
import { currencyFormatter } from '../../../_shared/utils/currencyFormatter';
import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

type DepositCardProps = {
  onOpenPayDeposit: () => void;
  selectedLease?: ReduxLease;
  selectedLeaseStatus?: LeaseDetailedStatus;
  focus: boolean;
  propertyHasPaymentsEnabled: boolean;
};

export const DepositCard: FC<DepositCardProps> = ({
  onOpenPayDeposit,
  selectedLease,
  focus,
  propertyHasPaymentsEnabled,
}) => {
  const { leases } = useTenant();
  const { leaseDepositBalance } = useLeases();
  const hasDepositBalance = (leaseDepositBalance.value ?? 0) > 0;
  const theme = useTheme();

  let depositAmount = 0;
  if (selectedLease?.leaseTerms != null && selectedLease.leaseTerms[0].rent != null)
    depositAmount = selectedLease.leaseTerms[0].rent.deposit ?? 0;

  if (leases.loading || leaseDepositBalance.loading)
    return <Skeleton variant={'rectangular'} animation={'wave'} width={'100%'} height={250} />;
  return (
    <ExpandableCard
      title={`Deposit of ${currencyFormatter.format(depositAmount)}`}
      subtitle={hasDepositBalance ? 'Please pay deposit balance' : 'Fully Paid'}
      focus={focus}
      borderColor={hasDepositBalance ? theme.palette.warning.main : undefined}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        {!selectedLease ? (
          <Typography>No Lease Selected</Typography>
        ) : (
          <Stack spacing={2}>
            <Typography>Balance: {currencyFormatter.format(leaseDepositBalance.value!)}</Typography>
          </Stack>
        )}
      </CardContent>
      {selectedLease ? (
        <CardActions sx={{ justifyContent: 'right' }}>
          <Tooltip
            title={propertyHasPaymentsEnabled ? '' : 'You cannot currently make payments via the payment portal.'}
          >
            <span>
              <Button
                onClick={onOpenPayDeposit}
                variant={'contained'}
                color={'secondary'}
                disabled={leaseDepositBalance.value! == 0 || !propertyHasPaymentsEnabled}
              >
                Pay Deposit
              </Button>
            </span>
          </Tooltip>
        </CardActions>
      ) : (
        <></>
      )}
    </ExpandableCard>
  );
};
