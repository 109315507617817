import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { updateSubmitApplicationAction, useApplications } from '../../../../redux/applicationSlice';
import { Loading } from '../../../../../global/loading/Loading';
import { ReduxApplication } from '../../../../redux/applicationTypes';
import Button from '@mui/material/Button';
import { ApplicationStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type Props = {
  applicant: ReduxApplication;
};

export const ReviewAndSubmit = ({ applicant }: Props) => {
  const applications = useApplications();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    applicant.id && dispatch(updateSubmitApplicationAction(applicant.id));
  };

  if (applications.selectedApplication.submitting) {
    return <Loading loading={applications.selectedApplication?.submitting} size={'large'} />;
  } else if (
    applicant.applicationStatus === ApplicationStatus.UserEditing &&
    !applications.selectedApplication.submitted
  ) {
    return (
      <Box>
        <Typography>Review and Submit</Typography>
        <Button variant="contained" onClick={handleSubmit}>
          Submit Application
        </Button>
      </Box>
    );
  } else if (
    applicant.applicationStatus === ApplicationStatus.Denied ||
    applicant.applicationStatus === ApplicationStatus.Withdrawn
  ) {
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '1rem' }}>
          <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
            Your application is no longer editable online.
          </Typography>
        </Box>
      </>
    );
  }
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: '1rem' }}>
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Congrats! Your application has been submitted!
        </Typography>
      </Box>
    </>
  );
};
