import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import { ServiceProviderItem } from './ServiceProviderItem';
import {
  confirmRemoveServiceProviderAction,
  updateAddServiceProviderAction,
  updatePropertyServiceProviderAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { AddServiceProvider } from './AddServiceProvider';
import { IServiceProvider } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Box from '@mui/material/Box';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import Button from '@mui/material/Button';
import { ReduxRentalProperty } from '@monkeyjump-labs/cam-fe-shared/dist/types/propertyTypes';

type Props = {
  property: ReduxRentalProperty;
};

export const ServiceProvidersList = ({ property }: Props) => {
  const dispatch = useDispatch();
  const [editedPropertyProviders, setEditedPropertyProviders] = useState<IServiceProvider[] | undefined>(undefined);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleAddProvider = (providerToAdd: IServiceProvider) => {
    if (!editedPropertyProviders) return;
    const updatedServiceProviders = [...editedPropertyProviders];
    updatedServiceProviders.push(providerToAdd);
    setEditedPropertyProviders(updatedServiceProviders);
    property.id &&
      dispatch(
        updateAddServiceProviderAction({
          propertyId: property.id,
          details: { serviceProviders: updatedServiceProviders },
        }),
      );
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    property.details && setEditedPropertyProviders(property.details.serviceProviders);
  }, [property]);

  const handleSave = (updatedProvider: IServiceProvider, updatedIndex: number) => {
    if (!editedPropertyProviders) return;
    const updatedProviders = editedPropertyProviders.map((provider, index) => {
      if (updatedIndex === index) return updatedProvider;
      return provider;
    });
    setEditedPropertyProviders(updatedProviders);
    property.id &&
      dispatch(
        updatePropertyServiceProviderAction({
          propertyId: property.id,
          details: { serviceProviders: updatedProviders },
        }),
      );
  };

  const handleRemove = (removalIndex: number) => {
    if (!editedPropertyProviders) return;
    const updatedProviders = editedPropertyProviders.filter((provider, index) => index != removalIndex);
    property.id &&
      dispatch(
        confirmRemoveServiceProviderAction({
          propertyId: property.id,
          details: { serviceProviders: updatedProviders },
        }),
      );
  };

  if (property.details && property.details.serviceProviders) {
    return (
      <StyledInfoBox label={'Service Providers'}>
        <Stack>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="outlined" onClick={handleOpen}>
              Add Provider
            </Button>
          </Box>
          <AddServiceProvider onClose={handleClose} onAdd={handleAddProvider} open={open} />
          {property.details.serviceProviders.map((serviceProvider, index) => {
            return (
              <ServiceProviderItem
                serviceProvider={serviceProvider}
                key={serviceProvider.name}
                index={index}
                onSave={handleSave}
                onRemove={handleRemove}
              />
            );
          })}
        </Stack>
      </StyledInfoBox>
    );
  } else return <></>;
};
