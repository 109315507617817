import Button from '@mui/material/Button';
import { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import React, { FC, FormEvent, useEffect } from 'react';
import { inviteUserAction, useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NewUser } from './UserAccessTable';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import { PhoneTextField } from '../../../../../_shared/texfields/PhoneTextField';
import { DialogLayout } from '../../../../../_shared/dialogs/DialogLayout';
import { convertAccessLevelUriToReadable } from '../../../../../auth/AuthorizedClaims';

type AddUserDialogProps = {
  newUser: NewUser | undefined;
  onEditNewUser: (key: string, value: string) => void;
  resetNewUser: () => void;
  includeTenants: boolean;
  page: number;
  pageSize: number;
} & Omit<DialogProps, 'open'>;

export const AddUserDialog: FC<AddUserDialogProps> = ({
  newUser,
  onEditNewUser,
  resetNewUser,
  includeTenants,
  page,
  pageSize,
}) => {
  const dispatch = useDispatch();
  const { id: propertyId } = useParams();
  const userRoles = useProperty().userRoles;
  const users = useProperty().users;

  useEffect(() => {
    if (userRoles.value && newUser && !newUser.accessLevel) {
      const employeeRole = userRoles.value.find((role) => role.toLowerCase().endsWith('employee'));
      employeeRole && onEditNewUser('accessLevel', employeeRole);
    }
  }, [userRoles, newUser]);

  useEffect(() => {
    if (users.submitted) {
      resetNewUser();
    }
  }, [users.submitted]);

  const handleAddUser = (e: FormEvent) => {
    e.preventDefault();
    if (newUser?.email && newUser?.accessLevel) {
      dispatch(
        inviteUserAction({
          propertyId: propertyId!,
          includeTenants: includeTenants,
          page: page,
          pageSize: pageSize,
          body: {
            email: newUser.email,
            claim: newUser.accessLevel,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            phoneNumber: newUser.phoneNumber,
          },
        }),
      );
    }
  };

  return (
    <DialogLayout title={'Add User to Property'} open={!!newUser} onBackdropClick={resetNewUser} onClose={resetNewUser}>
      <form onSubmit={handleAddUser} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                value={newUser?.firstName}
                fullWidth
                label={'First Name'}
                type={'text'}
                required
                onChange={(e) => onEditNewUser('firstName', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={newUser?.lastName}
                fullWidth
                label={'Last Name'}
                type={'text'}
                required
                onChange={(e) => onEditNewUser('lastName', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <PhoneTextField
                value={newUser?.phoneNumber}
                fullWidth
                label={'Phone Number'}
                type={'tel'}
                onChange={(e) => onEditNewUser('phoneNumber', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={newUser?.email}
                fullWidth
                label={'Email'}
                type={'email'}
                required
                onChange={(e) => onEditNewUser('email', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="access-level-label">Access Level</InputLabel>
                <Select
                  label={'Access Level'}
                  labelId={'access-level-label'}
                  value={newUser?.accessLevel ?? ''}
                  required
                  onChange={(e) => onEditNewUser('accessLevel', e.target.value)}
                >
                  {userRoles.value?.map((r) => (
                    <MenuItem key={r} value={r}>
                      {convertAccessLevelUriToReadable(r)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetNewUser}>Close</Button>
          <LoadingButton variant="contained" color="primary" type="submit" loading={users.submitting}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};
