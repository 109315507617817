import React, { FC } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

type DriversLicenseNumberInputProps = {
  pattern?: { regex: RegExp; formatString: string };
} & Omit<TextFieldProps, 'type' | 'pattern'>;

export const DriversLicenseNumberInput: FC<DriversLicenseNumberInputProps> = ({
  onChange,
  value,
  onBlur,
  disabled,
  pattern,
  error,
  ...otherProps
}) => {
  return (
    <TextField
      {...otherProps}
      error={error}
      helperText={pattern ? pattern.formatString : 'Please select a state first'}
      inputProps={{ pattern: pattern?.regex?.source }}
      type="text"
      required={pattern ? true : false}
      onChange={onChange}
      value={value}
      disabled={disabled}
      onBlur={onBlur}
    />
  );
};
