import React, { FC, FormEvent, useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  IUnitDetailOption,
  UnitDetailOptionType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useDispatch } from 'react-redux';
import {
  updateUnitDetailOptionAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { DialogLayout } from '../../../../../_shared/dialogs/DialogLayout';

type UnitDefaultDetailsDialogProps = {
  open: boolean;
  onClose: () => void;
  value?: IUnitDetailOption;
};

export const UnitDefaultDetailsDialog: FC<UnitDefaultDetailsDialogProps> = ({ open, onClose, value }) => {
  const dispatch = useDispatch();
  const { selectedProperty } = useProperty();
  const [dirty, setDirty] = useState(false);
  const [editingValue, setEditingValue] = useState(value);

  useEffect(() => {
    setEditingValue(value);
  }, [value]);

  useEffect(() => {
    selectedProperty.submitted && handleClose();
  }, [selectedProperty.submitted]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!editingValue) return;
    selectedProperty.value?.id &&
      editingValue.id &&
      dispatch(
        updateUnitDetailOptionAction({
          id: selectedProperty.value?.id,
          configId: editingValue?.id,
          body: {
            unitDetailOption: editingValue,
          },
        }),
      );
  };

  const handleUpdateValue = (field: string, value: string) => {
    setDirty(true);
    setEditingValue({ ...editingValue, [field]: value });
  };

  const handleUpdateType = (type: UnitDetailOptionType) => {
    setDirty(true);
    if (type === UnitDetailOptionType.Boolean) {
      setEditingValue({ ...editingValue, type: type, defaultValue: 'false' });
    } else setEditingValue({ ...editingValue, type: type, defaultValue: '' });
  };

  const handleUpdateDefaultValue = (checked: boolean) => {
    setDirty(true);
    setEditingValue({ ...editingValue, defaultValue: checked ? 'true' : 'false' });
  };

  const handleClose = () => {
    setDirty(false);
    onClose();
  };

  return (
    <DialogLayout title={'Unit Default Details'} open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                fullWidth
                required
                label={'Name'}
                value={editingValue?.name}
                onChange={(e) => handleUpdateValue('name', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={'Category'}
                value={editingValue?.category}
                onChange={(e) => handleUpdateValue('category', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/*Select for Type*/}
              <FormControl fullWidth>
                <InputLabel id="select-type">Type</InputLabel>
                <Select
                  required
                  labelId="select-type"
                  id="type"
                  value={editingValue?.type ?? ''}
                  label="Type"
                  onChange={(e) => handleUpdateType(e.target.value as UnitDetailOptionType)}
                >
                  <MenuItem value={UnitDetailOptionType.Boolean}>Yes/No</MenuItem>
                  <MenuItem value={UnitDetailOptionType.Integer}>Amount</MenuItem>
                  <MenuItem value={UnitDetailOptionType.SingleLine}>Short Description</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item container xs={12} sm={6} alignContent={'center'}>
              {/*Default Value (options depend on type)*/}
              {editingValue?.type === UnitDetailOptionType.Boolean ? (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={editingValue?.defaultValue === 'true'}
                        onChange={(e) => handleUpdateDefaultValue(e.target.checked)}
                      />
                    }
                    label="Yes"
                  />
                </FormGroup>
              ) : (
                <TextField
                  required
                  fullWidth
                  label={'Default Value'}
                  type={editingValue?.type === UnitDetailOptionType.Integer ? 'number' : 'text'}
                  value={editingValue?.defaultValue}
                  onChange={(e) => handleUpdateValue('defaultValue', e.target.value)}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <LoadingButton variant={'contained'} disabled={!dirty} type={'submit'}>
            Update Default
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};
