import React, { FC, useEffect } from 'react';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';
import { AddListingType } from './ListListings';
import { UnitBuildingAutocomplete, UnitOption } from '../../../_shared/autocompletes/UnitBuildingAutocomplete';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import {
  addGenericListingAction,
  addListingFromTemplateAction,
  addListingFromUnitsAction,
  useListings,
} from '../../redux/listingSlice';
import { TemplateOption, UnitTemplateSelect } from './UnitTemplateSelect';
import { IUnitInfo } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ListingInfoForm } from './ListingInfoForm';

type AddListingDialogProps = {
  open: boolean;
  onClose: () => void;
  addListingType: AddListingType;
};

export const AddListingDialog: FC<AddListingDialogProps> = ({ open, onClose, addListingType }) => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const { listings } = useListings();
  const [unitSelected, setUnitSelected] = React.useState<UnitOption | null>(null);
  const [templateSelected, setTemplateSelected] = React.useState<TemplateOption | null>(null);
  const [genericListingInfo, setGenericListingInfo] = React.useState<IUnitInfo>({});

  useEffect(() => {
    listings.submitted && handleClose();
  }, [listings.submitted]);

  useEffect(() => {
    open &&
      addListingType === AddListingType.Generic &&
      setGenericListingInfo({ bedrooms: 0, bathrooms: 0, squareFootage: 0 });
  }, [open]);

  const handleUnitSelectionChange = (value: UnitOption | null) => {
    setUnitSelected(value);
  };
  const handleTemplateSelectionChange = (value: TemplateOption | null) => {
    setTemplateSelected(value);
  };
  const handleClose = () => {
    setUnitSelected(null);
    setTemplateSelected(null);
    onClose();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (addListingType === AddListingType.FromTemplate) {
      templateSelected?.id && dispatch(addListingFromTemplateAction(templateSelected.id));
    }
    if (addListingType === AddListingType.FromUnits) {
      selectedContext.propertyId && unitSelected?.id && dispatch(addListingFromUnitsAction(unitSelected?.id));
    }
    if (addListingType === AddListingType.Generic) {
      selectedContext.buildingId &&
        dispatch(
          addGenericListingAction({
            buildingId: selectedContext.buildingId,
            info: genericListingInfo,
          }),
        );
    }
  };

  return (
    <DialogLayout title={'Add Listing'} onClose={handleClose} open={open}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {addListingType === AddListingType.FromTemplate ? (
            <UnitTemplateSelect value={templateSelected} onValueChange={handleTemplateSelectionChange} required />
          ) : addListingType === AddListingType.FromUnits ? (
            <UnitBuildingAutocomplete
              required={unitSelected === null}
              value={unitSelected ?? null}
              onValueChange={handleUnitSelectionChange}
            />
          ) : (
            <ListingInfoForm editingListing={genericListingInfo} onEditListing={setGenericListingInfo} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!unitSelected && !templateSelected && addListingType !== AddListingType.Generic}
          >
            Add
          </Button>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};
