import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import { useIcons } from '../../../../_shared/icons/useIcons';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import { updateListingAction, useListings } from '../../../redux/listingSlice';

export const OtherAmenities: FC = () => {
  const dispatch = useDispatch();
  const { selectedListing } = useListings();
  const otherAmenities = selectedListing.value?.otherAmenities || [];
  const { ActionType, getActionIcon } = useIcons();
  const [newAmenity, setNewAmenity] = React.useState<string>('');

  const removeAmenity = (index: number) => {
    const newAmenities = otherAmenities.filter((amenity, i) => i !== index);
    selectedListing.value?.id &&
      dispatch(
        updateListingAction({
          id: selectedListing.value.id,
          body: { ...selectedListing.value, otherAmenities: newAmenities },
        }),
      );
  };

  const addAmenity = () => {
    selectedListing.value?.id &&
      dispatch(
        updateListingAction({
          id: selectedListing.value.id,
          body: { ...selectedListing.value, otherAmenities: [...otherAmenities, newAmenity] },
        }),
      );
    setNewAmenity('');
  };

  return (
    <Stack spacing={1}>
      {otherAmenities.map((amenity, index) => (
        <Stack direction={'row'} key={index}>
          <TextField fullWidth variant={'outlined'} type="text" value={amenity} />
          <IconButton onClick={() => removeAmenity(index)}>{getActionIcon(ActionType.Delete)}</IconButton>
        </Stack>
      ))}
      <Stack direction={'row'} spacing={1}>
        <TextField
          fullWidth
          label={'Amenity'}
          variant={'standard'}
          type="text"
          value={newAmenity}
          onChange={(e) => setNewAmenity(e.target.value)}
        />
        <IconButton onClick={addAmenity}>{getActionIcon(ActionType.Add)}</IconButton>
      </Stack>
    </Stack>
  );
};
