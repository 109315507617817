import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { getUsersForAssetAction, useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import {
  AssetType,
  IGetUsersForAssetHandlerUserRoleInfo,
  ISlimUser,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { AutocompleteInputChangeReason } from '@mui/material/Autocomplete/Autocomplete';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AssetParams } from '../../../../AppRouter';
import { getAssetTypeFromPathname } from '../../utils/getAssetTypeFromPathname';

export type UserOption = {
  id: string;
  name: string;
  roles: string;
};

export type AssigneeAutocompleteProps = {
  onValueChange: (value: UserOption | null) => void;
  value?: ISlimUser | string | null | undefined;
  size?: 'small' | 'medium' | undefined;
} & Omit<
  AutocompleteProps<UserOption, undefined, undefined, undefined>,
  'value' | 'options' | 'renderInput' | 'getOptionLabel' | 'size'
>;

export const formatOptions = (users: IGetUsersForAssetHandlerUserRoleInfo[]): UserOption[] => {
  return users.map((user) => {
    let roleString = '';
    if (user.roleNames) {
      for (const key in user.roleNames) {
        roleString += key + ' ';
      }
    }
    return {
      id: user.id ?? '',
      name: user.firstName + ' ' + user.lastName,
      roles: roleString,
    };
  });
};

export const handleOptionLabel = (option: UserOption) => {
  if (!option) {
    return '';
  } else if (!option.roles) {
    return option.name;
  } else return option.name + ', ' + option.roles;
};

export const AssigneeAutocomplete: FC<AssigneeAutocompleteProps> = ({
  onValueChange,
  size,
  value,
  ...autocompleteProps
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<UserOption[]>([]);
  const [optionValue, setOptionValue] = useState<UserOption | null | undefined>();
  const [user, setUser] = useState<ISlimUser | null>();
  const usersForAsset = useUser((u) => u.usersForAsset);
  const { id } = useParams<AssetParams>();
  const assetType =
    getAssetTypeFromPathname() === 'unit'
      ? AssetType.BuildingUnit
      : getAssetTypeFromPathname() === 'building'
        ? AssetType.Building
        : AssetType.RentalProperty;

  useEffect(() => {
    if (typeof value === 'string') {
      setUser(options.find((u) => u.id === value));
    } else {
      setUser(value);
    }
  }, [value, options]);

  useEffect(() => {
    if (!usersForAsset.value) {
      id && assetType && dispatch(getUsersForAssetAction({ assetId: id, associationType: assetType }));
    }
    const options: UserOption[] | undefined = formatOptions(usersForAsset.value ?? []);
    setOptions(options ?? []);
  }, [usersForAsset]);

  useEffect(() => {
    options?.forEach((option) => option.id === user?.id && setOptionValue(option));
  }, [user, options]);

  const handleReset = (event: SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'clear') {
      setOptionValue(null);
    } else setOptionValue(optionValue);
  };

  return (
    <Autocomplete
      fullWidth
      size={size}
      options={options}
      onChange={(event, value) => {
        onValueChange(value);
      }}
      onInputChange={(event, value, reason) => handleReset(event, value, reason)}
      renderInput={(params) => {
        return <TextField {...params} variant="outlined" label="Assignees" />;
      }}
      value={optionValue ?? null}
      getOptionLabel={(option: UserOption) => handleOptionLabel(option)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      {...autocompleteProps}
    />
  );
};
