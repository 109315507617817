import React, { FC } from 'react';
import {
  IAddUnitHandlerRequest,
  IBuildingUnitDto,
  IUnitInfo,
  UnitStatusDto,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Grid from '@mui/material/Grid';
import { UnitStatusSelect } from './unitTabs/unitInfoTabs/UnitStatusSelect';
import TextField from '@mui/material/TextField';
import { Feature } from '../../global/Feature';
import { NumberValueTextField } from '../../_shared/texfields/NumberValueTextField';

type UnitInformationFormProps = {
  value: IBuildingUnitDto | IAddUnitHandlerRequest;
  onChange: (field: keyof IBuildingUnitDto | keyof IUnitInfo, value: string) => void;
  onChangeStatus?: (status: string) => void;
  newUnit?: boolean;
};

export const UnitInformationForm: FC<UnitInformationFormProps> = ({ value, onChange, onChangeStatus, newUnit }) => {
  const handleChangeStatus = (newValue: string) => {
    if (onChangeStatus) {
      onChangeStatus(newValue);
    } else onChange('status', newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          required
          fullWidth
          id={'name'}
          label={'Name'}
          type={'text'}
          onChange={(e) => onChange('name', e.target.value)}
          value={value.name ?? ''}
        />
      </Grid>
      <Feature flag={'Feature.Unit.UnitDetails'}>
        <>
          <Grid item xs={6}>
            <UnitStatusSelect
              onChange={handleChangeStatus}
              value={value.status as UnitStatusDto}
              fullWidth
              newUnit={newUnit}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberValueTextField
              required
              value={value.info?.rentPrice ?? ''}
              onChange={(e) => onChange('rentPrice', e.target.value)}
              type={'number'}
              valueUnits={'dollars'}
              label={'Rent Price'}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <NumberValueTextField
              value={value.info?.deposit ?? ''}
              onChange={(e) => onChange('deposit', e.target.value)}
              type={'number'}
              valueUnits={'dollars'}
              label={'Deposit'}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <NumberValueTextField
              value={value.info?.squareFootage ?? ''}
              onChange={(e) => onChange('squareFootage', e.target.value)}
              type={'number'}
              label={'Total Square Feet'}
              valueUnits={undefined}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <NumberValueTextField
              value={value.info?.bedrooms ?? ''}
              onChange={(e) => onChange('bedrooms', e.target.value)}
              type={'number'}
              valueUnits={undefined}
              label={'Number of Bedrooms'}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <NumberValueTextField
              value={value.info?.bathrooms ?? ''}
              valueUnits={undefined}
              onChange={(e) => onChange('bathrooms', e.target.value)}
              type={'number'}
              label={'Number of Bathrooms'}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={value.info?.description ?? ''}
              onChange={(e) => onChange('description', e.target.value)}
              type={'text'}
              label={'Description'}
              variant={'outlined'}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          {!newUnit && (
            <Grid item xs={12}>
              {/*this shouldn't be public-facing; ok here?*/}
              <TextField
                value={value.info?.notes ?? ''}
                onChange={(e) => onChange('notes', e.target.value)}
                type={'number'}
                label={'Notes'}
                variant={'outlined'}
                fullWidth
                multiline
                rows={3}
              />
            </Grid>
          )}
        </>
      </Feature>
    </Grid>
  );
};
