import React from 'react';
import { RoomDataType } from '../redux/roomData';
import { useDispatch } from 'react-redux';
import { cancelUpdateRoomAction, updateRoomAction } from '../redux/roomSlice';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { NumberValueTextField } from '../../_shared/texfields/NumberValueTextField';

type Props = {
  room: RoomDataType;
  onClose: () => any;
  open: boolean;
};
export const EditRoom = ({ room, onClose, open }: Props) => {
  const { id, name, squareFootage, tenant, managedBy } = room;
  const dispatch = useDispatch();
  const [updateRoomName, setUpdateRoomName] = React.useState(name);
  const [updateSquareFootage, setUpdateSquareFootage] = React.useState(squareFootage);
  const [updateCurrentTenant, setUpdateCurrentTenant] = React.useState(tenant);
  const [updateManager, setUpdateManager] = React.useState(managedBy);

  const updateRoom = () => {
    dispatch(
      updateRoomAction({
        id: id,
        name: updateRoomName,
        squareFootage: updateSquareFootage,
        tenant: updateCurrentTenant,
        managedBy: updateManager,
      }),
    );
    onClose();
  };

  const handleCancel = () => {
    dispatch(cancelUpdateRoomAction());
  };

  return (
    <Dialog maxWidth="md" fullWidth={true} open={open}>
      <DialogTitle color="secondary">Edit {name}</DialogTitle>
      <DialogContent>
        <Grid container justifyContent="space-between">
          <Grid item xs={5}>
            <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="roomName"
              label="Room Name"
              type="text"
              defaultValue={name}
              variant="standard"
              onChange={(event) => {
                setUpdateRoomName(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <NumberValueTextField
              valueUnits={undefined}
              autoFocus
              fullWidth
              margin="dense"
              id="squareFootage"
              label="Square Footage"
              type="number"
              defaultValue={squareFootage}
              variant="standard"
              onChange={(event) => {
                setUpdateSquareFootage(Number(event.target.value));
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="currentTenant"
              label="Current Tenant"
              type="text"
              defaultValue={tenant}
              variant="standard"
              onChange={(event) => {
                setUpdateCurrentTenant(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="managedBy"
              label="Managed By"
              type="text"
              defaultValue={managedBy}
              variant="standard"
              onChange={(event) => {
                setUpdateManager(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DialogActions sx={{ marginTop: '1rem' }}>
              <Button onClick={handleCancel}>Close</Button>
              <Button onClick={updateRoom} variant="contained">
                Update Room
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
