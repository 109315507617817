import LogRocket from 'logrocket';
import React, { FC, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import setupLogRocketReact from 'logrocket-react';

const allowedUsers = ['brian.r.fast@gmail.com', 'jonathan.dexter@monkeyjumplabs.com', 'matthew.c.carlin@gmail.com'];

export const Logger: FC = () => {
  const { user } = useAuth0();
  useEffect(() => {
    if (user?.email && allowedUsers.includes(user.email)) {
      LogRocket.init('mjl/smartmanagement');
      LogRocket.identify(user.email, user);
      setupLogRocketReact(LogRocket);
    }
  }, [user]);
  return <></>;
};
