import { IGetTenantLeaseHandlerResponse } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { toReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { mapReduxLeaseTerm, ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';

export function mapReduxTenantLease(lease: IGetTenantLeaseHandlerResponse): ReduxLease {
  return {
    ...lease,
    startDate: typeof lease.startDate === 'string' ? toReduxDate(lease.startDate) : undefined,
    endDate: typeof lease.endDate === 'string' ? toReduxDate(lease.endDate) : undefined,
    moveOutDate: toReduxDate(lease.moveOutDate),
    leaseTerms: lease.leaseTerms?.map(mapReduxLeaseTerm) ?? [],
  };
}

export type DayOfTheMonthOption =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28;
