import React, { useState } from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { StaticDatePicker } from '@mui/x-date-pickers-pro';
import Tooltip from '@mui/material/Tooltip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { TimeTravelDateDisplay } from './TimeTravelDateDisplay';
import { addDays, addYears } from 'date-fns';
import { setSimulatedTimeAction, useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { useDispatch } from 'react-redux';
import { ReduxDate, toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

type TimeTravelProps = {
  propertyId: string;
  propertyDate: ReduxDate;
};

export const TimeTravel = ({ propertyId, propertyDate }: TimeTravelProps) => {
  const property = useProperty();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [timeTravelDate, setTimeTravelDate] = useState<Date | null>(null);

  const handleSetDate = (date: Date | null) => {
    setTimeTravelDate(date);
  };

  const handleSaveTimeTravel = () => {
    const timeTravelDateOnly = timeTravelDate && toReduxDate(timeTravelDate);
    timeTravelDateOnly &&
      dispatch(setSimulatedTimeAction({ rentalPropertyId: propertyId, simulationDate: timeTravelDateOnly }));
  };

  const minDate = addDays(toStandardDate(propertyDate), 1);

  return (
    <>
      <IconButton
        size={'large'}
        onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
        id="time-travel-button"
        aria-controls={open ? 'time-travel-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-label={'adjust-simulated-property-time'}
      >
        <Tooltip title={'Time Travel'}>
          <AccessTimeIcon />
        </Tooltip>
      </IconButton>
      <Menu
        id="time-travel-menu"
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'time-travel-button',
        }}
      >
        <StaticDatePicker
          maxDate={addYears(minDate, 1)}
          minDate={minDate}
          referenceDate={minDate}
          onChange={(date) => handleSetDate(date)}
          value={timeTravelDate}
          slotProps={{
            actionBar: {
              sx: {
                display: 'none',
              },
            },
          }}
        />
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
          <LoadingButton
            variant={'contained'}
            onClick={handleSaveTimeTravel}
            loading={property.selectedProperty.loading}
            disabled={!timeTravelDate}
          >
            Save
          </LoadingButton>
        </Box>
      </Menu>
      <TimeTravelDateDisplay propertyDate={propertyDate} />
    </>
  );
};
