import React from 'react';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import Typography from '@mui/material/Typography';
import { AssigneeAutocomplete, UserOption } from '../../AssigneeAutocomplete';
import { IGetUsersForAssetHandlerUserRoleInfo } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';

type AssigneeCellProps = {
  isScheduled?: boolean;
} & GridRenderCellParams;

export const AssigneeCell = ({ isScheduled, ...props }: AssigneeCellProps) => {
  if (isScheduled) {
    if (!props.row.task.assignee) {
      return (
        <Typography variant={'body2'} fontStyle={'italic'} sx={{ color: 'gray' }}>
          Unassigned
        </Typography>
      );
    } else return <>{props.row.task.assignee.firstName + ' ' + props.row.task.assignee.lastName}</>;
  } else if (!props.value) {
    return (
      <Typography variant={'body2'} fontStyle={'italic'} sx={{ color: 'gray' }}>
        Unassigned
      </Typography>
    );
  }
  return <>{props.value.firstName + ' ' + props.value.lastName}</>;
};

type AssigneeEditCellProps = {
  onChange: (newValue: IGetUsersForAssetHandlerUserRoleInfo | undefined, taskId: string) => void;
  isScheduled?: boolean;
} & GridRenderCellParams;

export const AssigneeEditCell = ({ onChange, isScheduled, ...props }: AssigneeEditCellProps) => {
  const usersForAsset = useUser((u) => u.usersForAsset.value);
  const apiRef = useGridApiContext();
  const { id, value, field } = props;

  const handleAssigneeChange = (value: UserOption | null | undefined) => {
    let newValue: IGetUsersForAssetHandlerUserRoleInfo | undefined;
    if (value === null || value === undefined) {
      newValue = undefined;
    } else
      usersForAsset?.map((user) => {
        if (user.id === value?.id) {
          newValue = user;
        }
      });
    onChange(newValue, props.row.id);
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <AssigneeAutocomplete
      value={isScheduled ? props.row.task.assignee : value}
      onValueChange={handleAssigneeChange}
      size={'small'}
    />
  );
};
