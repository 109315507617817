import React, { FC } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { NotificationsMenu } from '../../../_shared/notifications/components/userNotifications/NotificationsMenu';
import Grid from '@mui/material/Grid';
import { Authorized } from '../../../auth/Authorized';
import { Search } from '../../../_shared/search/components/Search';
import { UserMenu } from './UserMenu';
import { appRoutes } from '../../../../AppRouter';
import Link from '@mui/material/Link';
import { useSearchHook } from '../../../_shared/search/useSearchHook';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { Claims } from '@monkeyjump-labs/cam-fe-shared/dist/types/constants';

export type HeaderProps = {
  isAuthTokenSet: boolean;
};

export const Header: FC<HeaderProps> = ({ isAuthTokenSet }) => {
  const { globalStringOptions } = useSearchHook();
  const { value: currentUser } = useUser((u) => u.currentUser);
  const onlyHasTenantClaims = currentUser?.claims?.every((c) => c.type === Claims.Tenant) ?? false;
  const homeRoute = onlyHasTenantClaims ? '/' + appRoutes.tenantPortal : appRoutes.home;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ height: '4.25rem' }}>
        <Toolbar>
          <Grid sx={{ minHeight: '100%' }} container direction="row" alignItems="center" alignContent="center">
            <Link href={homeRoute}>
              <img src={'/smartManagementLogo.png'} alt={'smart management logo'} style={{ paddingTop: '.5rem' }} />
            </Link>
            <Grid item flexGrow={1} />
            <Grid item xs={5}>
              {!onlyHasTenantClaims && <Search options={globalStringOptions} global={true} searchTypes={null} />}
            </Grid>
            <Grid item flexGrow={1} />
            <Grid item container xs={2} justifyContent="flex-end">
              {!onlyHasTenantClaims && (
                <Authorized>
                  <NotificationsMenu isAuthTokenSet={isAuthTokenSet} />
                </Authorized>
              )}
              <UserMenu onlyHasTenantClaims={onlyHasTenantClaims} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
