import { ReduxCalendarEvent } from './calendarData';
import { Interactable, initInteractable } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AssetType,
  IListEventsForAssetHandlerRequest,
  AssociationType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';

export type CalendarState = {
  events: Interactable<ReduxCalendarEvent[]>;
};

const initialState: CalendarState = {
  events: initInteractable<ReduxCalendarEvent[]>(),
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEventsAction: (
      state,
      action: PayloadAction<{ assetId: string; assetType: AssetType; body: IListEventsForAssetHandlerRequest }>,
    ) => {
      state.events.loading = true;
      state.events.loaded = false;
    },
    getEventsSuccessAction: (state, action: PayloadAction<ReduxCalendarEvent[]>) => {
      state.events.loading = false;
      state.events.loaded = true;
      state.events.value = action.payload;
    },
    getEventsFailAction: (state) => {
      state.events.loading = false;
      state.events.loaded = false;
    },
    updateEventDateRangeAction: (
      state,
      action: PayloadAction<{
        id: string;
        associationType: AssociationType;
        start: string;
        end?: string;
      }>,
    ) => {
      state.events.submitted = false;
      state.events.submitting = true;
    },
    updateEventDateRangeSuccessAction: (state) => {
      state.events.submitted = true;
      state.events.submitting = false;
    },
    updateEventDateRangeFailAction: (state) => {
      state.events.submitted = false;
      state.events.submitting = false;
    },
  },
});

export const {
  getEventsAction,
  getEventsSuccessAction,
  getEventsFailAction,
  updateEventDateRangeSuccessAction,
  updateEventDateRangeFailAction,
  updateEventDateRangeAction,
} = calendarSlice.actions;

export const useCalendar = () => useSelector((state: RootState) => state.calendar);
