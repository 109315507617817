import React, { FC, useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { DialogProps } from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';
import {
  downloadTemplateAction,
  useDocuments,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/documents/documentSlice';

type BulkAddAssetsDialog = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
};

export const BulkAddAssetsDialog: FC<BulkAddAssetsDialog> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { importExport } = useDocuments();
  const [numberOfBuildings, setNumberOfBuildings] = useState(0);

  useEffect(() => {
    importExport.submitted && handleClose();
  }, [importExport.submitted]);

  const handleClose = () => {
    setNumberOfBuildings(0);
    onClose();
  };

  const handleDownloadTemplate = () => {
    dispatch(downloadTemplateAction(numberOfBuildings));
  };

  return (
    <DialogLayout
      title={'Download Import Spreadsheet'}
      maxWidth="sm"
      fullWidth={true}
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Stack spacing={2} sx={{ mt: '1rem' }}>
          <DialogContentText>
            Select the number of buildings associated with this property and Smart Management will generate an Excel
            spreadsheet you can download to your machine. Once completed, select 'Upload Assets' from the Add Property
            menu to import your entries.
          </DialogContentText>
          <TextField
            value={numberOfBuildings}
            onChange={(e) => setNumberOfBuildings(Number(e.target.value))}
            label={'Number of Buildings'}
            type={'number'}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ marginTop: '1rem' }}>
        <Button onClick={handleClose}>Close</Button>
        <LoadingButton
          variant="contained"
          disabled={numberOfBuildings <= 0}
          onClick={handleDownloadTemplate}
          loading={importExport.submitting}
        >
          Download Spreadsheet
        </LoadingButton>
      </DialogActions>
    </DialogLayout>
  );
};
