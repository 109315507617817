import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { StatementView } from '../../../../../_shared/statements/components/StatementView';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import { Beta } from '../../../../../global/Feature';
import { appTheme } from '../../../../../../AppTheme';
import { DialogLayout } from '../../../../../_shared/dialogs/DialogLayout';
import { useDispatch } from 'react-redux';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {
  getPlatformPeriodsAction,
  getPlatformStatementAction,
  useStatement,
} from '../../../../../_shared/statements/redux/statementSlice';
import { AccountStatement } from '../../../../../_shared/statements/components/AccountStatement';

export const SoftwareBilling = () => {
  const [open, setOpen] = useState(false);
  const { selectedStatement, statementPeriods } = useStatement();
  const [selectedPeriod, setSelectedPeriod] = useState<string>();
  const {
    selectedContext: { propertyId },
  } = useAssets();
  const dispatch = useDispatch();
  useEffect(() => {
    if (propertyId) dispatch(getPlatformPeriodsAction({ propertyId }));
  }, [propertyId]);
  useEffect(() => {
    if (!selectedPeriod && (statementPeriods.value?.length ?? 0) > 0) {
      setSelectedPeriod(statementPeriods.value![0]!);
    }
  }, [statementPeriods]);
  useEffect(() => {
    if (selectedPeriod && propertyId) {
      dispatch(
        getPlatformStatementAction({
          monthYear: selectedPeriod,
          propertyId,
        }),
      );
    }
  }, [selectedPeriod, propertyId]);
  const handleClose = () => setOpen(false);

  const handlePeriodChanged = (event: SelectChangeEvent) => {
    setSelectedPeriod(event.target.value as string);
  };

  return (
    <Beta flag={'CAM.Feature.Payments.PropertyInvoicing'}>
      <Stack spacing={2}>
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', p: '.5rem' }}>
          <Typography variant={'h5'} sx={{ color: appTheme.palette.secondary.main }}>
            Current Statement
          </Typography>{' '}
          <FormControl>
            <InputLabel>Period</InputLabel>
            <Select
              required
              sx={{ minWidth: 240 }}
              value={selectedPeriod ?? ''}
              onChange={handlePeriodChanged}
              labelId="payment-type-label"
              id="payment-type"
              label="Payment Type"
            >
              {statementPeriods.value?.map((period) => (
                <MenuItem key={period} value={period}>
                  {period}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/*<Button variant={'outlined'} onClick={() => setOpen(true)}>*/}
          {/*  Show Payment History*/}
          {/*</Button>*/}
        </Box>
        <Divider />
        <StatementView
          statement={selectedStatement.value}
          loading={selectedStatement.loading}
          submitting={selectedStatement.submitting}
          skipTimeControls
          isPlatformStatement
        />
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right', p: '.5rem' }}>
          <Stack spacing={2}>
            <Typography fontStyle={'italic'}>Payment scheduled for **autoPay date**</Typography>
            <Button variant={'outlined'}>Manage Payment Information</Button>
          </Stack>
        </Box>
        <DialogLayout title={'Payment History'} open={open} fullWidth onClose={handleClose}>
          <DialogContent sx={{ mt: '1rem' }}>
            <AccountStatement />
          </DialogContent>
        </DialogLayout>
      </Stack>
    </Beta>
  );
};
