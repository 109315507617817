import React, { FC, useEffect, useState } from 'react';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { Search } from '../../../search/components/Search';
import {
  AssociationChildType,
  AssociationType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ExpenseElementType } from '../../../expenses/redux/expenseData';
import TextField from '@mui/material/TextField';
import { ExpenseChildrenSelect } from '../../../expenses/components/ExpenseChildrenSelect';
import { useSearchHook } from '../../../search/useSearchHook';
import { useDispatch } from 'react-redux';
import {
  resetAssociationSubmissionAction,
  setAssociationSearchParentAction,
  useSearch,
} from '../../../search/redux/searchSlice';
import { useAssociations } from '../../../datagrids/useAssociations';

export const SearchAssociationsDialog: FC = () => {
  const dispatch = useDispatch();
  const { associationSearchParent } = useSearch();
  const {
    searchStyles,
    purchaseOrderAssociationTypeOptions,
    nonAssetAssociationTypeOptions,
    detailAssociationTypeOptions,
    detailStringOptions,
    nonAssetStringOptions,
    purchaseOrderButtonOptions,
  } = useSearchHook();
  const { handleAddAssociation } = useAssociations();
  const [associatedId, setAssociatedId] = useState<string | undefined>();
  const [associationType, setAssociationType] = useState<AssociationType | undefined>();
  const [associationChildType, setAssociationChildType] = useState<AssociationChildType | undefined>();
  const [childNumber, setChildNumber] = useState<string | undefined>();

  const searchTypes =
    associationSearchParent?.submittingValue?.type === AssociationType.ExpensePurchaseOrder
      ? purchaseOrderAssociationTypeOptions
      : associationSearchParent?.submittingValue?.type === AssociationType.EmailThread
        ? nonAssetAssociationTypeOptions
        : associationSearchParent?.submittingValue?.type === AssociationType.Detail
          ? detailAssociationTypeOptions
          : [];
  const options =
    associationSearchParent?.submittingValue?.type === AssociationType.ExpensePurchaseOrder
      ? purchaseOrderButtonOptions
      : associationSearchParent?.submittingValue?.type === AssociationType.EmailThread
        ? nonAssetStringOptions
        : associationSearchParent?.submittingValue?.type === AssociationType.Detail
          ? detailStringOptions
          : [];
  const handleClose = () => {
    setAssociatedId(undefined);
    setAssociationType(undefined);
    setChildNumber(undefined);
    setAssociationChildType(undefined);
    dispatch(resetAssociationSubmissionAction());
    dispatch(setAssociationSearchParentAction(undefined));
  };

  useEffect(() => {
    associationSearchParent.submitted && handleClose();
  }, [associationSearchParent.submitted]);

  const handleSelectChildElement = (association: string, childType?: ExpenseElementType) => {
    const type =
      childType === ExpenseElementType.Payment
        ? AssociationChildType.Payment
        : childType === ExpenseElementType.Invoice
          ? AssociationChildType.Invoice
          : childType === ExpenseElementType.PurchaseOrder
            ? AssociationChildType.PurchaseOrder
            : childType === ExpenseElementType.WorkOrder
              ? AssociationChildType.WorkOrder
              : AssociationChildType.Quote;
    setChildNumber(association);
    setAssociationChildType(type);
  };

  return (
    <DialogLayout
      title={'Add Association'}
      onClose={handleClose}
      open={associationSearchParent.submittingValue !== undefined}
      maxWidth={'md'}
    >
      <DialogContent>
        <Search
          searchTypes={searchTypes}
          options={options}
          global={false}
          onSelectAssociation={(type?: AssociationType, id?: string) => {
            setAssociatedId(id);
            setAssociationType(type);
          }}
        />
        {associationType === AssociationType.Expense && (
          <ExpenseChildrenSelect
            jobId={associatedId}
            onChange={handleSelectChildElement}
            renderInput={(params) => (
              <TextField
                {...params}
                size={'small'}
                margin="dense"
                variant="outlined"
                label="Select Expense Element to Associate"
                sx={searchStyles}
              />
            )}
            value={childNumber}
          />
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant={'contained'}
          onClick={() =>
            associationSearchParent?.submittingValue?.id &&
            associationType &&
            associatedId &&
            handleAddAssociation(
              associationSearchParent?.submittingValue?.id,
              associationSearchParent?.submittingValue?.type,
              associatedId,
              associationType,
              associationChildType,
              childNumber,
              associationSearchParent?.submittingValue?.poInfo,
            )
          }
          disabled={
            associationType === AssociationType.Expense
              ? !childNumber || !associationChildType
              : !associationType || !associatedId
          }
          loading={associationSearchParent.submitting}
        >
          Add Association
        </LoadingButton>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </DialogLayout>
  );
};
