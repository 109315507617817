import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers-pro';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DialogLayout } from '../../dialogs/DialogLayout';

type MarkDepositedPopperProps = {
  open: boolean;
  onDepositClose: () => void;
  dateDeposited: Date | null;
  setDateDeposited: (date: Date | null) => void;
  onMarkAsPosted: () => void;
};

export const MarkDepositedPopper: FC<MarkDepositedPopperProps> = ({
  open,
  onMarkAsPosted,
  setDateDeposited,
  dateDeposited,
  onDepositClose,
}) => {
  return (
    <DialogLayout title={'Mark Deposited'} id="deposit-menu" open={open} onClose={onDepositClose}>
      <Stack spacing={2} p={1} pt={2}>
        <DatePicker
          label={'Date Deposited'}
          value={dateDeposited}
          onChange={(date) => {
            setDateDeposited(date);
          }}
        />
        <Box display={'flex'} flexGrow={1} justifyContent={'right'}>
          <Button variant={'contained'} onClick={onMarkAsPosted}>
            Mark Deposited
          </Button>
        </Box>
      </Stack>
    </DialogLayout>
  );
};
