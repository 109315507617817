import React from 'react';
import { useDispatch } from 'react-redux';
import {
  requestTwilioPhoneNumberAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { ConfigurationLayout } from './ConfigurationLayout';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

export const PhoneNumberConfiguration = () => {
  const dispatch = useDispatch();
  const { selectedProperty } = useProperty();
  const currentPhoneNumber = selectedProperty.value?.details?.twilioPhone;

  const handleRequestPhone = () => {
    if (selectedProperty.value?.id) dispatch(requestTwilioPhoneNumberAction({ propertyId: selectedProperty.value.id }));
  };
  return (
    <ConfigurationLayout title={'Phone Number'}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: '1rem' }}>
        <Typography>Property Phone Number</Typography>
        <Box sx={{ display: 'flex', flexGrow: 1 }}></Box>
        <Typography>{currentPhoneNumber ?? 'Phone not yet configured'}</Typography>
        {!currentPhoneNumber && (
          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
            <LoadingButton
              disabled={!!selectedProperty.value?.details?.lastTwilioPhoneRequest}
              variant={'contained'}
              onClick={handleRequestPhone}
              loading={selectedProperty.submitting}
            >
              Request Phone Number
            </LoadingButton>
          </Box>
        )}
      </Box>
    </ConfigurationLayout>
  );
};
