import { ICalendarEvent } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { formatISO, isDate } from 'date-fns';

export type ReduxCalendarEvent = Omit<ICalendarEvent, 'start' | 'end'> & {
  start: string;
  end: string;
};

export function mapReduxCalendarEvent(calendarEvent: ICalendarEvent): ReduxCalendarEvent {
  return {
    ...calendarEvent,
    start: isDate(calendarEvent.start) ? formatISO(calendarEvent.start!) : (calendarEvent.start as any)?.toString(),
    end: isDate(calendarEvent.end) ? formatISO(calendarEvent.end!) : (calendarEvent.end as any)?.toString(),
  };
}
