import React, { useState } from 'react';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LeaseStatement } from '../../../statements/components/LeaseStatement';
import { tryFormatDate } from '../../../utils/TryFormatDate';
import { useLeases } from '../../redux/leasesSlice';
import Box from '@mui/material/Box';
import { AccountStatementViewDialog } from '../../../statements/components/accountStatementDialog/AccountStatementViewDialog';
import { ReduxEntry } from '../../../statements/redux/statementTypes';

export const LeasePaymentHistory = () => {
  const { activeLease } = useLeases();
  const startDate = activeLease.value?.startDate && tryFormatDate(activeLease.value?.startDate);
  const [openPopupStatement, setOpenPopupStatement] = useState(false);
  const [selectedStatementLine, setSelectedStatementLine] = useState<ReduxEntry | undefined>(undefined);
  const [linkedAccountId, setLinkedAccountId] = useState<string | undefined>(undefined);

  const handleOpenPopupStatement = (statementLine?: ReduxEntry, subAccountId?: string) => {
    setSelectedStatementLine(statementLine);
    if (subAccountId) setLinkedAccountId(subAccountId);
    else setLinkedAccountId(statementLine?.depositAccount?.id);
    setOpenPopupStatement(true);
  };

  return (
    <StyledInfoBox label={'Payment History'}>
      <Stack spacing={2}>
        <Typography>
          <Box fontWeight="bold" display="inline">
            Lease Start Date
          </Box>
          : {startDate}
        </Typography>
        <LeaseStatement
          leaseId={activeLease.value?.id}
          showPeriodSelector
          showActions={true}
          onOpenPopupStatement={handleOpenPopupStatement}
        />
      </Stack>
      <AccountStatementViewDialog
        open={openPopupStatement}
        onClose={() => setOpenPopupStatement(false)}
        isPopupStatement
        linkedGlAccountId={linkedAccountId}
        highlightedStatementRow={selectedStatementLine}
      />
    </StyledInfoBox>
  );
};
