import { all, put, race, take, takeLatest, select } from 'redux-saga/effects';
import {
  addCommentToLeaseAction,
  addTenantAction,
  addTenantFailAction,
  addTenantSuccessAction,
  addWatcherToLeaseAction,
  cancelInviteTenantAction,
  cancelInviteTenantFailAction,
  cancelInviteTenantSuccessAction,
  cancelPreLeaseAction,
  cancelRemoveDocumentAction,
  cancelUpdateLeaseAction,
  cancelUpdateLeaseConfirmedAction,
  closeNewTenantDialogAction,
  createLeaseAction,
  createLeaseFailAction,
  createLeaseSuccessAction,
  failAddCommentToLeaseAction,
  failGetSingleLeaseAction,
  failRemoveDocumentAction,
  getAllLeasesByAssetIdAction,
  getAllLeasesByAssetIdFailAction,
  getAllLeasesByAssetIdSuccessAction,
  getAmountOwedToTenantAction,
  getAmountOwedToTenantFailAction,
  getAmountOwedToTenantSuccessAction,
  getAvailableDepositBalanceAction,
  getAvailableDepositBalanceFailAction,
  getAvailableDepositBalanceSuccessAction,
  getDepositBankAccountBalanceAction,
  getDepositBankAccountBalanceFailAction,
  getDepositBankAccountBalanceSuccessAction,
  getDraftLeasesForPropertyIdAction,
  getDraftLeasesForPropertyIdFailAction,
  getDraftLeasesForPropertyIdSuccessAction,
  getLeaseDepositBalanceAction,
  getLeaseDepositBalanceFailAction,
  getLeaseDepositBalanceSuccessAction,
  getLeaseRenewalStartDateFailAction,
  getLeaseRenewalStartDateSuccessAction,
  getRenewalLeasesForPropertyIdAction,
  getRenewalLeasesForPropertyIdFailAction,
  getRenewalLeasesForPropertyIdSuccessAction,
  getSingleLeaseAction,
  getTenantInvitationStatusAction,
  getTenantInvitationStatusFailAction,
  getTenantInvitationStatusSuccessAction,
  inviteTenantAction,
  inviteTenantFailAction,
  inviteTenantSuccessAction,
  markLeaseAsActiveAction,
  markLeaseAsClosedAction,
  markLeaseAsEvictionAction,
  markLeaseAsMovedOutAction,
  markLeaseAsPreleasedAction,
  markLeaseSignedAction,
  openLeaseModalAction,
  quickEditLeaseAction,
  removeDocumentAction,
  removeTenantAccessAction,
  removeTenantAccessFailAction,
  removeTenantAccessSuccessAction,
  removeWatcherFromLeaseAction,
  renewLeaseAction,
  renewLeaseFailAction,
  renewLeaseSuccessAction,
  rollbackLeaseToActiveAction,
  setLeaseTableFiltersAction,
  setLeaseTableSortAction,
  successAddCommentToLeaseAction,
  successGetSingleLeaseAction,
  successRemoveDocumentAction,
  toggleLeaseIncludeClosedStatusesAction,
  updateCurrentLeaseTermsAction,
  updateLeaseAction,
  updateLeaseFailAction,
  updateLeaseStatusFailAction,
  updateLeaseStatusSuccessAction,
  updateLeaseSuccessAction,
  uploadDocumentAction,
  uploadSignedLeaseAction,
  watcherActionFailAction,
  watcherActionSuccessAction,
} from './leasesSlice';
import {
  cancelConfirmDialogAction,
  cancelFileUploadAction,
  fileUploadedAction,
  fileUploadingAction,
  okConfirmDialogAction,
  showConfirmDialogAction,
  showErrorAction,
  showToastMessageAction,
  showUploadDialogAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import {
  AddDocumentToLeaseHandlerResponse,
  AddTenantHandlerRequest,
  ApplicationStatus,
  ApplicationType,
  CreateLeaseHandlerRequest,
  CreateLeaseHandlerResponse,
  GetLeaseHandlerResponse,
  GetRenewedLeaseStartDateResponse,
  GetTenantInvitationStatusesHandlerResponse,
  IManualQueryParameter_DateOnly,
  InviteTenantHandlerRequest,
  InviteTenantHandlerResponse,
  LeaseClosedReason,
  LeaseProcessStatus,
  ListLeasesHandlerRequest,
  ListLeasesHandlerResponse,
  ListRenewableLeasesHandlerRequest,
  ListRenewableLeasesHandlerResponse,
  ListSortDirection,
  Optional_DateOnly,
  QueryExpression,
  QueryGroupOperator,
  QueryOperator,
  RenewLeaseHandlerRequest,
  ReturnFundsThenCloseHandlerRequest,
  SignLeaseHandlerRequest,
  UpdateLeaseHandlerRequest,
  AddUserWatchHandlerRequest,
  RemoveUserWatchHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  LeaseDispatchType,
  mapReduxLease,
  mapReduxTermToApi,
  ReduxDraftLease,
  ReduxLease,
  ReduxLeaseTerm,
  ReduxTenant,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import {
  failUploadedDocumentAction,
  getApplicationsByPropertyIdAction,
  refreshApplicationsAction,
  successUploadedDocumentAction,
  updateApplicationStatusAction,
} from '../../../property/redux/applicationSlice';
import { Filter, SortDirection } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { RootState } from '../../../../app/store';
import { convertToQueryExpression } from '../../utils/filteringUtils';
import {
  mapApiDocument,
  mapReduxDocument,
  ReduxDocument,
  toReduxDate,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { getUnitStatusAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/unitSlice';

function* getAllLeasesByAssetId(
  action: PayloadAction<{ propertyId: string; assetId: string; page?: number; pageSize?: number }>,
) {
  try {
    const tenantName: string | undefined = yield select(
      (r: RootState) => r.leases.allLeases.additionalFilters?.tenantName,
    );
    const startDate: IManualQueryParameter_DateOnly | undefined = yield select(
      (r: RootState) => r.leases.allLeases.additionalFilters?.startDate,
    );
    const endDate: IManualQueryParameter_DateOnly | undefined = yield select(
      (r: RootState) => r.leases.allLeases.additionalFilters?.endDate,
    );
    const filters: Filter<ReduxLease>[] = yield select((r: RootState) => r.leases.allLeases.filters);
    const formattedFilters: QueryExpression = convertToQueryExpression(filters);
    const includeClosedStatuses: boolean = yield select((r: RootState) => r.leases.includeClosedStatuses);
    const page: number = yield select((r: RootState) => r.leases.allLeases.page);
    const pageSize: number = yield select((r: RootState) => r.leases.allLeases.pageSize);
    const sortBy: string | undefined = yield select((r: RootState) => r.leases.allLeases.sortBy);
    const sortDirection: SortDirection = yield select((r: RootState) => r.leases.allLeases.sortDirection);
    const assetIdSearch = {
      groupOperator: QueryGroupOperator.Or,
      parameters: [
        {
          field: 'propertyId',
          operator: QueryOperator.Eq,
          value: action.payload.assetId,
        },
        {
          field: 'unitId',
          operator: QueryOperator.Eq,
          value: action.payload.assetId,
        },
        {
          field: 'buildingId',
          operator: QueryOperator.Eq,
          value: action.payload.assetId,
        },
      ],
    };

    const removeNonActiveLeases = {
      groupOperator: QueryGroupOperator.And,
      parameters: [
        {
          field: 'processStatus',
          operator: QueryOperator.Ne,
          value: LeaseProcessStatus.Draft,
        },
        {
          field: 'processStatus',
          operator: QueryOperator.Ne,
          value: LeaseProcessStatus.DepositPaid,
        },
      ],
      subExpressions: [assetIdSearch],
    };

    const leases: ListLeasesHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().properties_SearchLeases,
      action.payload.propertyId,
      new ListLeasesHandlerRequest({
        tenant: tenantName,
        startDate,
        endDate,
        query: {
          ...formattedFilters,
          subExpressions: [...(formattedFilters.subExpressions ?? []), removeNonActiveLeases],
          page: action.payload.page ?? page,
          pageSize: action.payload.pageSize ?? pageSize,
          orderBy: sortBy ? [sortBy] : undefined,
          orderDirection: sortDirection as ListSortDirection,
        },
        includeClosed: includeClosedStatuses,
        includeCancelled: true,
      }),
    );
    yield put(
      getAllLeasesByAssetIdSuccessAction({
        leases: leases.toJSON().results?.map(mapReduxLease),
        totalCount: leases.totalCount ?? 0,
      }),
    );
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not get leases' }));
    yield put(getAllLeasesByAssetIdFailAction());
  }
}

function* toggleLeaseIncludeClosedStatuses(
  action: PayloadAction<{ includeClosedStatuses: boolean; propertyId: string; assetId: string }>,
) {
  try {
    yield put(
      getAllLeasesByAssetIdAction({
        propertyId: action.payload.propertyId,
        assetId: action.payload.assetId,
      }),
    );
  } catch {
    yield put(showToastMessageAction({ message: 'Could not toggle include closed leases', severity: 'error' }));
  }
}

function* getRenewalLeasesForPropertyId(
  action: PayloadAction<{ propertyId: string; page?: number; pageSize?: number }>,
) {
  try {
    const page: number = yield select((r: RootState) => r.leases.renewalLeases.page);
    const pageSize: number = yield select((r: RootState) => r.leases.renewalLeases.pageSize);

    const leases: ListRenewableLeasesHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().properties_GetLeasesForRenewal,
      action.payload.propertyId,
      new ListRenewableLeasesHandlerRequest({
        page: page,
        pageSize: pageSize,
      }),
    );
    yield put(
      getRenewalLeasesForPropertyIdSuccessAction({
        leases: leases.toJSON().results?.map(mapReduxLease),
        totalCount: leases.totalCount ?? 0,
      }),
    );
  } catch (e) {
    yield put(showErrorAction({ error: e, fallbackMessage: 'could not get renewal leases' }));
    yield put(getRenewalLeasesForPropertyIdFailAction());
  }
}

function* getDraftLeasesByPropertyId(action: PayloadAction<{ propertyId: string; page?: number; pageSize?: number }>) {
  const tenantName: string = yield select((r: RootState) => r.leases.draftLeases.additionalFilters?.tenantName);
  const startDate: IManualQueryParameter_DateOnly | undefined = yield select(
    (r: RootState) => r.leases.allLeases.additionalFilters?.startDate,
  );
  const endDate: IManualQueryParameter_DateOnly | undefined = yield select(
    (r: RootState) => r.leases.allLeases.additionalFilters?.endDate,
  );
  const filters: Filter<ReduxLease>[] = yield select((r: RootState) => r.leases.draftLeases.filters);
  const formattedFilters: QueryExpression = convertToQueryExpression(filters);
  const page: number = yield select((r: RootState) => r.leases.draftLeases.page);
  const pageSize: number = yield select((r: RootState) => r.leases.draftLeases.pageSize);
  const sortBy: string | undefined = yield select((r: RootState) => r.leases.draftLeases.sortBy);
  const sortDirection: SortDirection = yield select((r: RootState) => r.leases.draftLeases.sortDirection);

  try {
    const draftLeaseSearch = {
      groupOperator: QueryGroupOperator.Or,
      parameters: [
        {
          field: 'processStatus',
          operator: QueryOperator.Eq,
          value: LeaseProcessStatus.Draft,
        },
        {
          field: 'processStatus',
          operator: QueryOperator.Eq,
          value: LeaseProcessStatus.DepositPaid,
        },
      ],
      subExpressions: [
        {
          groupOperator: QueryGroupOperator.And,
          parameters: [
            {
              field: 'processStatus',
              operator: QueryOperator.Eq,
              value: LeaseProcessStatus.Closing,
            },
            {
              field: 'closedReason',
              operator: QueryOperator.Eq,
              value: LeaseClosedReason.Cancelled,
            },
          ],
        },
      ],
    };
    const leases: ListLeasesHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().properties_SearchLeases,
      action.payload.propertyId,
      new ListLeasesHandlerRequest({
        query: {
          groupOperator: QueryGroupOperator.And,
          parameters: [],
          subExpressions: [
            {
              ...formattedFilters,
              subExpressions: [...(formattedFilters.subExpressions ?? []), draftLeaseSearch],
            },
          ],
          page: action.payload.page ?? page,
          pageSize: action.payload.pageSize ?? pageSize,
          orderBy: sortBy ? [sortBy] : undefined,
          orderDirection: sortDirection as ListSortDirection,
        },
        includeCancelled: true,
        tenant: tenantName,
        startDate,
        endDate,
      }),
    );
    yield put(
      getDraftLeasesForPropertyIdSuccessAction({
        leases: leases.toJSON().results?.map(mapReduxLease),
        totalCount: leases.totalCount ?? 0,
      }),
    );
  } catch (e) {
    yield put(showErrorAction({ error: e, fallbackMessage: 'could not get leases under review' }));
    yield put(getDraftLeasesForPropertyIdFailAction());
  }
}

function* filterAndSortLeaseTable(
  action: PayloadAction<{
    propertyId: string;
    assetId: string;
    leaseType: LeaseDispatchType;
    filters?: Filter<ReduxLease>[];
    sortBy?: keyof ReduxLease;
    sortDirection?: SortDirection;
  }>,
) {
  try {
    if (action.payload.leaseType === 'all') {
      yield put(
        getAllLeasesByAssetIdAction({ propertyId: action.payload.propertyId, assetId: action.payload.assetId }),
      );
    } else if (action.payload.leaseType === 'draft') {
      yield put(getDraftLeasesForPropertyIdAction({ propertyId: action.payload.assetId }));
    } else if (action.payload.leaseType === 'renewal') {
      yield put(getRenewalLeasesForPropertyIdAction({ propertyId: action.payload.assetId }));
    }
  } catch {
    yield put(showToastMessageAction({ message: 'unable to set filters properly', severity: 'error' }));
  }
}

function* createLease(action: PayloadAction<ReduxDraftLease>) {
  try {
    const request = CreateLeaseHandlerRequest.fromJS(action.payload);
    const lease: CreateLeaseHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().lease_CreateFromApplications,
      request,
    );
    yield put(createLeaseSuccessAction(mapReduxLease(lease.toJSON())));
    yield put(showToastMessageAction({ message: 'New lease created successfully!', severity: 'success' }));
    yield put(
      getApplicationsByPropertyIdAction({
        rentalPropertyId: action.payload.propertyId,
        applicationType: ApplicationType.Primary,
      }),
    );
    yield put(getDraftLeasesForPropertyIdAction({ propertyId: action.payload.propertyId! }));
    // TODO: Navigate to new lease
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not create lease' }));
    yield put(createLeaseFailAction());
  }
}

function* getLeaseRenewalStartDate(action: PayloadAction<{ lease: ReduxLease; editType: LeaseDispatchType }>) {
  try {
    const response: GetRenewedLeaseStartDateResponse = yield action.payload.lease.id &&
      apiCall(ApiClientSingleton.getInstance().lease_GetLeaseStartDate, action.payload.lease.id);
    if (response.leaseStartDate) {
      yield put(getLeaseRenewalStartDateSuccessAction(toReduxDate(response.leaseStartDate)));
    } else {
      yield put(showToastMessageAction({ message: 'Whoops! Something went wrong :(', severity: 'error' }));
    }
  } catch (e: any) {
    yield put(getLeaseRenewalStartDateFailAction());
    yield put(showErrorAction({ error: e, fallbackMessage: 'could not get lease renewal start date' }));
  }
}

function* cancelUpdateLease() {
  yield put(
    showConfirmDialogAction({
      message: 'If you close without saving, any edits you have made will be lost.',
      okText: 'Close without saving',
      cancelText: 'continue to update Lease',
    }),
  );
  const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
  if (yes) {
    yield put(cancelUpdateLeaseConfirmedAction());
  }
}

export function* refreshLeaseTables(assetId: string, leaseType?: LeaseDispatchType | undefined) {
  const currentPropertyId: string = yield select((r: RootState) => r.property.selectedProperty.value?.id);
  const allLeases: ReduxLease[] = yield select((r: RootState) => r.leases.allLeases.value);
  const editingLeaseType: string = yield select((r: RootState) => r.leases.editingLeaseType);
  const unitId: string = yield select((r: RootState) => r.assets.selectedContext.unitId);
  if (unitId) {
    yield put(getUnitStatusAction({ id: unitId }));
  }
  if (leaseType && leaseType !== 'single') {
    if (leaseType === 'draft' && assetId) {
      yield put(getDraftLeasesForPropertyIdAction({ propertyId: currentPropertyId }));
    } else if (leaseType === 'renewal' && assetId) {
      yield put(getRenewalLeasesForPropertyIdAction({ propertyId: currentPropertyId }));
    } else if (leaseType === 'all' && allLeases[0]?.propertyId && assetId) {
      yield put(getAllLeasesByAssetIdAction({ propertyId: allLeases[0]?.propertyId, assetId }));
    }
  } else if (!leaseType || leaseType === 'single') {
    if (editingLeaseType === 'draft' && assetId) {
      yield put(getDraftLeasesForPropertyIdAction({ propertyId: currentPropertyId }));
    } else if (editingLeaseType === 'renewal') {
      yield put(getRenewalLeasesForPropertyIdAction({ propertyId: currentPropertyId }));
    } else if (editingLeaseType === 'all' && allLeases[0]?.propertyId && assetId) {
      yield put(getAllLeasesByAssetIdAction({ propertyId: allLeases[0].propertyId, assetId }));
    }
  } else {
    showToastMessageAction({ message: 'unable to refresh lease table properly', severity: 'warning' });
  }
}

function* cancelPreLease(
  action: PayloadAction<{
    leaseId: string;
    propertyId: string;
    applicationIds: string[];
    newStatus: ApplicationStatus;
    assetId: string;
    leasesType: LeaseDispatchType;
  }>,
) {
  try {
    yield all(
      action.payload.applicationIds.map((id) =>
        put(
          updateApplicationStatusAction({
            id: id,
            applicationStatus: action.payload.newStatus,
            rentalPropertyId: action.payload.propertyId,
          }),
        ),
      ),
    );
    yield apiCall(ApiClientSingleton.getInstance().lease_CancelLease, action.payload.leaseId);
    yield put(showToastMessageAction({ message: 'Pre-lease cancelled successfully!', severity: 'success' }));
    yield refreshLeaseTables(action.payload.assetId, action.payload.leasesType);
    yield put(refreshApplicationsAction());
    yield put(updateLeaseStatusSuccessAction());
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem cancelling pre-lease' }));
    yield put(updateLeaseStatusFailAction());
  }
}

function* renewLease(
  action: PayloadAction<{ leaseType: LeaseDispatchType; assetId: string; leaseId: string; body: ReduxLeaseTerm }>,
) {
  try {
    const request = new RenewLeaseHandlerRequest({
      terms: mapReduxTermToApi(action.payload.body),
    });
    yield apiCall(ApiClientSingleton.getInstance().lease_RenewLease, action.payload.leaseId, request);
    yield put(renewLeaseSuccessAction());
    yield put(showToastMessageAction({ message: 'Lease updated successfully!', severity: 'success' }));
    yield refreshLeaseTables(action.payload.assetId, action.payload.leaseType);
  } catch (error) {
    yield put(renewLeaseFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem renewing lease' }));
  }
}

function* addTenant(
  action: PayloadAction<{ leaseId: string; assetId: string; leaseType: LeaseDispatchType; tenant: ReduxTenant }>,
) {
  try {
    const request = new AddTenantHandlerRequest({
      newTenant: {
        ...action.payload.tenant,
        birthday: action.payload.tenant.birthday && toReduxDate(action.payload.tenant.birthday),
      },
    });
    yield apiCall(ApiClientSingleton.getInstance().lease_AddTenant, action.payload.leaseId, request);
    yield put(showToastMessageAction({ message: 'Tenant added successfully!', severity: 'success' }));
    yield put(addTenantSuccessAction());
    yield put(closeNewTenantDialogAction());
    if (action.payload.leaseType === 'single') {
      yield put(getSingleLeaseAction({ leaseId: action.payload.leaseId, openLeaseModal: true }));
    }
    yield refreshLeaseTables(action.payload.assetId, action.payload.leaseType);
  } catch (error) {
    yield put(addTenantFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem adding tenant' }));
  }
}

function* inviteTenant(
  action: PayloadAction<{
    leaseId: string;
    tenantId: string;
    email: string;
    assetId: string;
    leaseType: LeaseDispatchType;
  }>,
) {
  try {
    const response: InviteTenantHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().lease_InviteTenant,
      action.payload.leaseId,
      action.payload.tenantId,
      new InviteTenantHandlerRequest({ email: action.payload.email }),
    );
    yield put(showToastMessageAction({ message: 'Tenant invited successfully', severity: 'success' }));
    yield put(
      inviteTenantSuccessAction({
        tenantId: action.payload.tenantId,
        email: action.payload.email,
        invitationId: response?.invitationId,
      }),
    );
  } catch (error: any) {
    yield put(showErrorAction({ error: error, fallbackMessage: 'Problem inviting tenant' }));
    yield put(inviteTenantFailAction());
  }
}

function* cancelTenantInvite(
  action: PayloadAction<{ leaseId: string; invitationId: string; assetId: string; leaseType: LeaseDispatchType }>,
) {
  try {
    yield put(
      showConfirmDialogAction({
        message: 'Are you sure you want to cancel this invite?',
        okText: 'Cancel Invite',
        cancelText: 'Go Back',
      }),
    );
    const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
    if (yes) {
      yield apiCall(
        ApiClientSingleton.getInstance().properties_CancelTenantInvitation,
        action.payload.assetId,
        action.payload.invitationId,
      );
      yield put(showToastMessageAction({ message: 'Invitation cancelled successfully', severity: 'success' }));
      yield put(cancelInviteTenantSuccessAction(action.payload.invitationId));
    }
  } catch (error: any) {
    yield put(showErrorAction({ error: error, fallbackMessage: 'Problem cancelling invite' }));
    yield put(cancelInviteTenantFailAction());
  }
}

function* removeTenantAccess(
  action: PayloadAction<{
    tenantId: string;
    assetId: string;
    leaseId: string;
    leaseType: LeaseDispatchType;
  }>,
) {
  try {
    yield put(
      showConfirmDialogAction({
        message: 'Are you sure you want to remove access for this tenant?',
        okText: 'Remove Access',
        cancelText: 'Go Back',
      }),
    );
    const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
    if (yes) {
      yield apiCall(
        ApiClientSingleton.getInstance().lease_RevokeTenantAccess,
        action.payload.leaseId,
        action.payload.tenantId,
      );
      yield put(showToastMessageAction({ message: 'Access removed successfully', severity: 'success' }));
      yield put(removeTenantAccessSuccessAction({ tenantId: action.payload.tenantId }));
    }
  } catch (error: any) {
    yield put(showErrorAction({ error: error, fallbackMessage: 'Problem removing access' }));
    yield put(removeTenantAccessFailAction());
  }
}

function* getTenantInvitationStatus(action: PayloadAction<string>) {
  try {
    const response: GetTenantInvitationStatusesHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().lease_GetTenantInvitationStatuses,
      action.payload,
    );
    if (response.results) {
      yield put(getTenantInvitationStatusSuccessAction(response.toJSON().results));
    }
  } catch (error) {
    yield put(getTenantInvitationStatusFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Could not get tenant invitation statuses' }));
  }
}

function* uploadSignedLease(action: PayloadAction<{ leaseId: string; propertyId: string }>) {
  try {
    yield put(
      showUploadDialogAction({
        title: 'Upload a signed lease',
        message: 'Please upload a signed lease for this lease.',
        cancelText: 'Cancel',
      }),
    );
    const { file }: { file: PayloadAction<File> } = yield race({
      file: take(fileUploadingAction.type),
      cancel: take(cancelFileUploadAction.type),
    });
    const uploadResponse: AddDocumentToLeaseHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().lease_UploadDocument,
      action.payload.leaseId,
      {
        data: file.payload,
        fileName: file.payload.name,
      },
    );
    yield put(fileUploadedAction());
    yield put(markLeaseSignedAction({ ...action.payload, document: mapReduxDocument(uploadResponse.document!) }));
    yield put(showToastMessageAction({ message: 'Lease uploaded successfully' }));
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem uploading signed lease' }));
  }
}

function* uploadDocument(action: PayloadAction<{ propertyId: string; leaseId: string; file: File; assetId: string }>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().lease_UploadDocument, action.payload.leaseId, {
      data: action.payload.file,
      fileName: action.payload.file.name,
    });
    yield put(successUploadedDocumentAction());
    yield put(showToastMessageAction({ message: 'Document uploaded successfully!', severity: 'success' }));
    yield put(getSingleLeaseAction({ leaseId: action.payload.leaseId, openLeaseModal: false }));
    yield refreshLeaseTables(action.payload.assetId);
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem uploading document' }));
    yield put(failUploadedDocumentAction());
  }
}

function* removeDocument(action: PayloadAction<{ leaseId: string; documentId: string; assetId: string }>) {
  try {
    yield put(
      showConfirmDialogAction({
        message: 'Are you sure you want to delete this document?',
        okText: 'Yes',
        cancelText: 'No',
      }),
    );
    const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
    if (yes) {
      yield apiCall(
        ApiClientSingleton.getInstance().lease_RemoveDocument,
        action.payload.leaseId,
        action.payload.documentId,
      );
      yield put(showToastMessageAction({ message: 'Document deleted successfully!', severity: 'success' }));
      yield put(successRemoveDocumentAction({ documentId: action.payload.documentId }));
      yield refreshLeaseTables(action.payload.assetId);
    } else {
      yield put(cancelRemoveDocumentAction());
    }
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem deleting document' }));
    yield put(failRemoveDocumentAction());
  }
}

function* addComment(action: PayloadAction<{ propertyId: string; leaseId: string; comment: string; assetId: string }>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().lease_AddComment, action.payload.leaseId, action.payload.comment);
    yield put(successAddCommentToLeaseAction());
    yield put(showToastMessageAction({ message: 'Comment added successfully!', severity: 'success' }));
    yield put(getSingleLeaseAction({ leaseId: action.payload.leaseId, openLeaseModal: true }));
    yield refreshLeaseTables(action.payload.assetId);
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem adding comment' }));
    yield put(failAddCommentToLeaseAction());
  }
}

function* getSingleLease(action: PayloadAction<{ leaseId: string; open: boolean }>) {
  try {
    const lease: GetLeaseHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().lease_GetLease,
      action.payload.leaseId,
    );
    const mappedLease = mapReduxLease(lease.toJSON());
    if (action.payload.open) yield put(openLeaseModalAction(true));
    yield put(successGetSingleLeaseAction(mappedLease));
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem getting lease' }));
    yield put(failGetSingleLeaseAction());
  }
}

function* markAsSigned(action: PayloadAction<{ leaseId: string; propertyId: string; document: ReduxDocument }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().lease_SignLease,
      action.payload.leaseId,
      SignLeaseHandlerRequest.fromJS({ signedDocument: mapApiDocument(action.payload.document) }),
    );
    yield put(getDraftLeasesForPropertyIdAction({ propertyId: action.payload.propertyId }));
    yield put(getRenewalLeasesForPropertyIdAction({ propertyId: action.payload.propertyId }));
    yield put(updateLeaseStatusSuccessAction());
  } catch (e) {
    yield put(showErrorAction({ error: e, fallbackMessage: 'problem marking lease signed' }));
    yield put(updateLeaseStatusFailAction());
  }
}

function* getAvailableDepositBalance(action: PayloadAction<string>) {
  try {
    const response: number = yield apiCall(
      ApiClientSingleton.getInstance().lease_GetAvailableDepositBalance,
      action.payload,
    );
    yield put(getAvailableDepositBalanceSuccessAction(response));
  } catch (e) {
    yield put(getAvailableDepositBalanceFailAction());
    yield put(showErrorAction({ error: e, fallbackMessage: 'problem getting available deposit balance' }));
  }
}

function* getLeaseDepositBalance(action: PayloadAction<string>) {
  try {
    const response: number = yield apiCall(
      ApiClientSingleton.getInstance().lease_GetLeaseDepositBalance,
      action.payload,
    );
    yield put(getLeaseDepositBalanceSuccessAction(response));
  } catch (e) {
    yield put(getLeaseDepositBalanceFailAction());
    yield put(showErrorAction({ error: e, fallbackMessage: 'problem getting lease deposit balance' }));
  }
}

function* getDepositBankAccountBalance(action: PayloadAction<string>) {
  try {
    const response: number = yield apiCall(
      ApiClientSingleton.getInstance().lease_GetDepositBankAccountBalance,
      action.payload,
    );
    yield put(getDepositBankAccountBalanceSuccessAction(response));
  } catch (e) {
    yield put(getDepositBankAccountBalanceFailAction());
    yield put(showErrorAction({ error: e, fallbackMessage: 'problem getting deposit bank account balance' }));
  }
}

function* getAmountOwedToTenant(action: PayloadAction<string>) {
  try {
    const response: number = yield apiCall(
      ApiClientSingleton.getInstance().lease_GetAmountOwedToTenant,
      action.payload,
    );
    yield put(getAmountOwedToTenantSuccessAction(response));
  } catch (e) {
    yield put(getAmountOwedToTenantFailAction());
    yield put(showErrorAction({ error: e, fallbackMessage: 'problem getting balance owed to tenant' }));
  }
}

function* markLeaseAsPreleased(action: PayloadAction<{ leaseId: string; propertyId: string }>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().lease_PlaceLease, action.payload.leaseId);
    yield put(getDraftLeasesForPropertyIdAction({ propertyId: action.payload.propertyId }));
    yield put(showToastMessageAction({ message: 'Lease pre-leased successfully', severity: 'success' }));
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem marking lease as pre-leased' }));
  }
}

function* markLeaseAsActive(action: PayloadAction<{ leaseId: string; assetId: string; leaseType: LeaseDispatchType }>) {
  try {
    yield put(
      showConfirmDialogAction({
        title: 'Give Keys',
        message: 'This will activate the lease and begin billing the tenant(s).',
        okText: 'Yes',
        cancelText: 'No',
      }),
    );
    const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
    if (yes) {
      yield apiCall(ApiClientSingleton.getInstance().lease_ActivateLease, action.payload.leaseId);
      yield refreshLeaseTables(action.payload.assetId, action.payload.leaseType);
      yield put(showToastMessageAction({ message: 'Lease activated successfully', severity: 'success' }));
      yield put(updateLeaseStatusSuccessAction());
    }
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not mark as active' }));
    yield put(updateLeaseStatusFailAction());
  }
}

function* markLeaseAsEviction(
  action: PayloadAction<{ leaseId: string; assetId: string; leaseType: LeaseDispatchType }>,
) {
  try {
    yield put(
      showConfirmDialogAction({
        title: 'Begin Eviction Process',
        message: 'Are you sure you want to begin eviction for this user?',
        okText: 'Begin Eviction',
        cancelText: 'Cancel',
      }),
    );
    const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
    if (yes) {
      yield apiCall(ApiClientSingleton.getInstance().lease_BeginEviction, action.payload.leaseId);
      yield put(showToastMessageAction({ message: 'Eviction process initiated', severity: 'success' }));
      yield refreshLeaseTables(action.payload.assetId, action.payload.leaseType);
      yield put(updateLeaseStatusSuccessAction());
    }
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem initiating eviction process' }));
    yield put(updateLeaseStatusFailAction());
  }
}

function* markLeaseAsMovedOut(
  action: PayloadAction<{ leaseId: string; assetId: string; leaseType: LeaseDispatchType }>,
) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().lease_MoveOut, action.payload.leaseId);
    yield put(showToastMessageAction({ message: 'Lease successfully moved out', severity: 'success' }));
    yield refreshLeaseTables(action.payload.assetId, action.payload.leaseType);
    yield put(updateLeaseStatusSuccessAction());
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem marking lease as moved out' }));
    yield put(updateLeaseStatusFailAction());
  }
}

function* rollbackLeaseToActive(
  action: PayloadAction<{ leaseId: string; assetId: string; leaseType: LeaseDispatchType }>,
) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().lease_RollbackToActive, action.payload.leaseId);
    yield put(
      showToastMessageAction({ message: 'Lease successfully rolled back to previous status', severity: 'success' }),
    );
    yield refreshLeaseTables(action.payload.assetId, action.payload.leaseType);
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem rolling back lease to active status' }));
  }
}

function* markLeaseAsClosed(
  action: PayloadAction<{
    leaseId: string;
    assetId: string;
    leaseType: LeaseDispatchType;
    body: {
      reason: string;
      transferRemainingDepositFunds?: boolean;
      returnDepositAccountNo?: string | undefined;
      returnDepositCheckNo?: string | undefined;
      returnOutstandingCreditAccountNo?: string | undefined;
      returnOutstandingCreditCheckNo?: string | undefined;
    };
  }>,
) {
  try {
    const request: ReturnFundsThenCloseHandlerRequest = ReturnFundsThenCloseHandlerRequest.fromJS(action.payload.body);
    yield apiCall(ApiClientSingleton.getInstance().lease_ReturnFundsThenClose, action.payload.leaseId, request);
    yield put(showToastMessageAction({ message: 'Lease successfully closed', severity: 'success' }));
    yield put(updateLeaseStatusSuccessAction());
    yield refreshLeaseTables(action.payload.assetId, action.payload.leaseType);
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem marking lease closed' }));
    yield put(updateLeaseStatusFailAction());
  }
}

function* updateLease(action: PayloadAction<{ leaseType: LeaseDispatchType; assetId: string; body: ReduxLease }>) {
  try {
    if (!action.payload.body.id) {
      yield put(
        showToastMessageAction({
          message: 'Could not update lease - missing lease ID',
          severity: 'warning',
        }),
      );
      return;
    }
    const newTenants = action.payload.body.leaseTerms
      ? action.payload.body.leaseTerms[(action.payload.body.leaseTerms?.length ?? 1) - 1]?.tenantGroup?.tenants
      : undefined;
    const request = new UpdateLeaseHandlerRequest({
      ...action.payload.body,
      moveOutDate: new Optional_DateOnly({
        value: action.payload.body.moveOutDate,
      }),
      tenants: newTenants,
      leaseTerms: action.payload.body.leaseTerms?.map(mapReduxTermToApi),
    });
    yield apiCall(ApiClientSingleton.getInstance().lease_UpdateLease, action.payload.body.id, request);
    yield put(showToastMessageAction({ message: 'Lease updated successfully!', severity: 'success' }));
    yield put(updateLeaseSuccessAction());
    yield put(getSingleLeaseAction({ leaseId: action.payload.body.id, openLeaseModal: false }));
    yield refreshLeaseTables(action.payload.assetId, action.payload.leaseType);
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem updating lease' }));
    yield put(updateLeaseFailAction());
  }
}

function* updateCurrentLeaseTerms(
  action: PayloadAction<{ leaseType: LeaseDispatchType; assetId: string; body: ReduxLease }>,
) {
  yield put(
    showConfirmDialogAction({
      title: 'Editing Current Lease Terms',
      message:
        'If you edit the current lease terms, this may invalidate the signed lease document. Are you sure you want to continue?',
      okText: 'Edit Lease Terms',
      cancelText: 'Cancel',
    }),
  );
  const { yes, no } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
  if (yes) {
    yield put(updateLeaseAction(action.payload));
  }
  if (no) {
    //clear submitting and edited values
    yield put(updateLeaseStatusFailAction());
    yield action.payload.body.id &&
      put(getSingleLeaseAction({ leaseId: action.payload.body.id, openLeaseModal: false }));
  }
}

function* addWatcherToLease(action: PayloadAction<{ leaseId: string; userId: string }>) {
  try {
    const updatedLease: GetLeaseHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().lease_AddWatch,
      action.payload.leaseId,
      new AddUserWatchHandlerRequest({ userId: action.payload.userId }),
    );
    yield put(watcherActionSuccessAction(mapReduxLease(updatedLease.toJSON())));
    yield put(showToastMessageAction({ message: 'Watcher added successfully!', severity: 'success' }));
  } catch (error) {
    yield put(watcherActionFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem adding watcher to lease' }));
  }
}

function* removeWatcherFromLease(action: PayloadAction<{ leaseId: string; userId: string }>) {
  try {
    const updatedLease: GetLeaseHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().lease_RemoveWatch,
      action.payload.leaseId,
      new RemoveUserWatchHandlerRequest({ userId: action.payload.userId }),
    );
    yield put(watcherActionSuccessAction(mapReduxLease(updatedLease.toJSON())));
    yield put(showToastMessageAction({ message: 'Watcher removed successfully!', severity: 'success' }));
  } catch (error) {
    yield put(watcherActionFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem removing watcher from lease' }));
  }
}

export function* leasesSagas() {
  yield all([
    takeLatest(getAllLeasesByAssetIdAction.type, getAllLeasesByAssetId),
    takeLatest(toggleLeaseIncludeClosedStatusesAction.type, toggleLeaseIncludeClosedStatuses),
    takeLatest(getRenewalLeasesForPropertyIdAction.type, getRenewalLeasesForPropertyId),
    takeLatest(getDraftLeasesForPropertyIdAction.type, getDraftLeasesByPropertyId),
    takeLatest(setLeaseTableFiltersAction.type, filterAndSortLeaseTable),
    takeLatest(setLeaseTableSortAction.type, filterAndSortLeaseTable),
    takeLatest(getSingleLeaseAction.type, getSingleLease),
    takeLatest(createLeaseAction.type, createLease),
    takeLatest(quickEditLeaseAction.type, getLeaseRenewalStartDate),
    takeLatest(renewLeaseAction.type, renewLease),
    takeLatest(cancelPreLeaseAction.type, cancelPreLease),
    takeLatest(cancelUpdateLeaseAction.type, cancelUpdateLease),
    takeLatest(addTenantAction.type, addTenant),
    takeLatest(inviteTenantAction.type, inviteTenant),
    takeLatest(cancelInviteTenantAction.type, cancelTenantInvite),
    takeLatest(removeTenantAccessAction.type, removeTenantAccess),
    takeLatest(getTenantInvitationStatusAction.type, getTenantInvitationStatus),
    takeLatest(uploadSignedLeaseAction.type, uploadSignedLease),
    takeLatest(uploadDocumentAction.type, uploadDocument),
    takeLatest(removeDocumentAction.type, removeDocument),
    takeLatest(addCommentToLeaseAction.type, addComment),
    takeLatest(markLeaseSignedAction.type, markAsSigned),
    takeLatest(getAvailableDepositBalanceAction.type, getAvailableDepositBalance),
    takeLatest(getLeaseDepositBalanceAction.type, getLeaseDepositBalance),
    takeLatest(getDepositBankAccountBalanceAction.type, getDepositBankAccountBalance),
    takeLatest(getAmountOwedToTenantAction.type, getAmountOwedToTenant),
    takeLatest(markLeaseAsPreleasedAction.type, markLeaseAsPreleased),
    takeLatest(updateLeaseAction.type, updateLease),
    takeLatest(updateCurrentLeaseTermsAction.type, updateCurrentLeaseTerms),
    takeLatest(markLeaseAsEvictionAction.type, markLeaseAsEviction),
    takeLatest(markLeaseAsMovedOutAction.type, markLeaseAsMovedOut),
    takeLatest(rollbackLeaseToActiveAction.type, rollbackLeaseToActive),
    takeLatest(markLeaseAsActiveAction.type, markLeaseAsActive),
    takeLatest(markLeaseAsClosedAction.type, markLeaseAsClosed),
    takeLatest(addWatcherToLeaseAction.type, addWatcherToLease),
    takeLatest(removeWatcherFromLeaseAction.type, removeWatcherFromLease),
  ]);
}
