import { ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';

export const getLeaseTenantNames = (
  lease: ReduxLease,
): { primaryTenantName: string | undefined; otherTenantNames: string | undefined } => {
  let primaryTenantName;
  let otherTenantNames: string | undefined;
  const mostRecentTenantGroup =
    lease.leaseTerms && lease.leaseTerms[lease?.leaseTerms?.length - 1].tenantGroup?.tenants;
  mostRecentTenantGroup?.map((t) => {
    if (t.isMainTenant) {
      primaryTenantName = `${t.firstName} ${t.lastName}`;
    } else {
      const tenantName = `${t.firstName} ${t.lastName}`;
      otherTenantNames = otherTenantNames ? otherTenantNames.concat(`, ${tenantName}`) : tenantName;
    }
  });
  return { primaryTenantName, otherTenantNames };
};
