import { PieLabelRenderProps, TooltipProps } from 'recharts';
import React, { FC } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { pieColors } from './useFormatPieData';

const RADIAN = Math.PI / 180;

export const renderCenteredLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, name }: PieLabelRenderProps) => {
  //TODO: null check numbers without returning on 0
  const numberInnerRadius = parseInt(innerRadius!.toString());
  const numberOuterRadius = parseInt(outerRadius!.toString());
  const numberCx = parseInt(cx!.toString());
  const numberCy = parseInt(cy!.toString());

  const radius = numberInnerRadius + (numberOuterRadius - numberInnerRadius) * 0.5;
  const x = numberCx + radius * Math.cos(-midAngle * RADIAN);
  const y = numberCy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text style={{ fontSize: '.6rem' }} x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central">
      {`${name}`}
    </text>
  );
};

export const renderNameAsLabel = ({ cx, cy, midAngle, outerRadius, name, value }: PieLabelRenderProps) => {
  if (value === 0) return;
  if (name === 'remainder') return;
  const numberOuterRadius = parseInt(outerRadius!.toString());
  const numberCx = parseInt(cx!.toString());
  const numberCy = parseInt(cy!.toString());

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const mx = numberCx + (numberOuterRadius + 5) * cos;
  const my = numberCy + (numberOuterRadius + 5) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1);
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <text x={ex} y={ey} textAnchor={textAnchor} fill="#333" style={{ fontSize: '.6rem' }}>
      {name}: {value}
    </text>
  );
};

type CustomLegendProps = {
  currentValues: string[];
};

export const CustomLegend: FC<CustomLegendProps> = ({ currentValues }) => {
  return (
    <List dense disablePadding sx={{ fontSize: '.75rem' }}>
      {Object.entries(pieColors).map(([k, v]) => {
        if (currentValues.includes(k))
          return (
            <ListItem key={`${k}`} disableGutters>
              <Box width={'.5rem'} height={'.5rem'} sx={{ backgroundColor: v, mr: '.2rem' }} />
              {k}
            </ListItem>
          );
      })}
    </List>
  );
};

export const CustomTooltip = ({ active, payload }: TooltipProps<any, any>) => {
  if (active && payload && payload.length && payload[0].name !== 'remainder') {
    return (
      <div
        style={{
          backgroundColor: 'white',
          border: '.1rem solid black',
          paddingRight: '.5rem',
          paddingLeft: '.5rem',
        }}
      >
        <p className="label">{`${payload[0].name} : ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};
