import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import { ApplicationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { AddApplicantForm } from '../applicantForms/AddApplicantForm';
import { DialogLayout } from '../../../../_shared/dialogs/DialogLayout';

type Props = {
  propertyId: string | undefined;
  open: boolean;
  onClose: () => void;
  isWebView?: boolean;
};

export const AddApplicantDialog = ({ propertyId, open, onClose, isWebView }: Props) => {
  return (
    <DialogLayout
      title={isWebView ? 'Apply Now!' : 'Add Applicant'}
      maxWidth="md"
      fullWidth={true}
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <AddApplicantForm
          type={ApplicationType.Primary}
          propertyId={propertyId}
          onClose={onClose}
          isWebView={isWebView}
        />
      </DialogContent>
    </DialogLayout>
  );
};
