import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { DetailsTable } from './detailsTables/DetailsTable';
import { AddDetailDialog, DetailsAddDialogType } from './dialogs/AddDetailDialog';
import {
  mapAssetTypeFromDetailAssociationType,
  mapDetailAssociationTypeFromAssetType,
  mapReduxDetail,
  ReduxDetail,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/detailTypes';
import {
  AssetType,
  AssociationType,
  DetailAssociationType,
  IDetailDto,
  TaskSection,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { Feature } from '../../../global/Feature';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetParams } from '../../../../AppRouter';
import AddIcon from '@mui/icons-material/Add';
import { getAssetTypeFromPathname } from '../../utils/getAssetTypeFromPathname';
import {
  newDetailArrivedAction,
  toggleIsGroupedAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/details/detailsSlice';
import { useDispatch } from 'react-redux';
import { ShowChildAssetsToggle } from '../../tasks/components/ShowChildAssetsToggle';
import { IncludeClosedToggle } from '../../tasks/components/IncludeClosedToggle';
import { GroupedDetailsTable } from './detailsTables/GroupedDetailsTable';
import { setNewTaskFromAssociationValuesAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { DetailsTab } from '../../tabs/TabTypeEnums';
import Connector from '@monkeyjump-labs/cam-fe-shared/dist/services/signalrConnection';
import { ReorderDetailsDialog } from './dialogs/ReorderDetailsDialog';

export const Details = () => {
  const dispatch = useDispatch();
  const pathAssetType = getAssetTypeFromPathname();
  const { id, outerTab, innerTab } = useParams<AssetParams>();
  const [openAdd, setOpenAdd] = useState(false);
  const navigate = useNavigate();
  const [includeClosed, setIncludeClosed] = useState(false);
  const [showChildAssets, setShowChildAssets] = useState(false);
  const [openReorderDialog, setOpenReorderDialog] = useState(false);

  useEffect(() => {
    const connector = Connector.getExistingInstance();
    connector.onReceiveDetail((payload: IDetailDto) => {
      dispatch(newDetailArrivedAction(mapReduxDetail(payload)));
    });

    return function cleanup() {
      connector.offReceiveDetail();
    };
  }, []);

  const handleSwitchChildAssetToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowChildAssets(event.target.checked);
    dispatch(toggleIsGroupedAction(event.target.checked));
  };

  const pathToAssetType = (pathAsset: string) => {
    switch (pathAsset) {
      case 'unit':
        return AssetType.BuildingUnit;
      case 'building':
        return AssetType.Building;
      default:
        return AssetType.RentalProperty;
    }
  };

  const assetType = pathToAssetType(pathAssetType);
  const detailAssociationType =
    mapDetailAssociationTypeFromAssetType(assetType) ?? DetailAssociationType.RentalProperty;

  const onEditDetails = (detail: ReduxDetail) => {
    navigate(`/assets/${pathAssetType}/${id}/${outerTab}/${innerTab}/detail/${detail.id}/${DetailsTab.Info}`);
  };

  const onClose = () => {
    setOpenAdd(false);
  };

  const startAddDetail = () => {
    setOpenAdd(true);
  };

  const handleToggleIncludeClosed = () => {
    setIncludeClosed(!includeClosed);
  };

  const handleOpenAddTaskFromDetail = (detail: ReduxDetail) => {
    detail.id &&
      dispatch(
        setNewTaskFromAssociationValuesAction({
          id: detail.id,
          valueAssociationType: AssociationType.Detail,
          name: detail.name,
          description: detail.description,
          valueAssetType: mapAssetTypeFromDetailAssociationType(detail.associationType),
          valueAssociatedId: detail.associatedId,
          taskSection: TaskSection.Operations,
        }),
      );
  };

  return (
    <>
      {id && (
        <StyledInfoBox label={'Details'}>
          <Stack spacing={2}>
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
              <Feature flag={'Feature.Details'}>
                <LoadingButton onClick={startAddDetail} variant={'outlined'} startIcon={<AddIcon />}>
                  Add Detail
                </LoadingButton>
              </Feature>
              <Box>
                {assetType !== AssetType.BuildingUnit && (
                  <ShowChildAssetsToggle value={showChildAssets} onChange={handleSwitchChildAssetToggle} />
                )}
                <IncludeClosedToggle
                  label={'Include Closed Details'}
                  includeClosed={includeClosed}
                  onToggleIncludeClosed={handleToggleIncludeClosed}
                />
              </Box>
            </Box>
            {showChildAssets ? (
              <GroupedDetailsTable
                includeClosed={includeClosed}
                associationType={detailAssociationType}
                associatedId={id}
                onViewDetails={onEditDetails}
                onOpenAddTask={handleOpenAddTaskFromDetail}
              />
            ) : (
              <DetailsTable
                associationType={detailAssociationType}
                associatedId={id}
                onViewDetails={onEditDetails}
                includeClosed={includeClosed}
                onOpenAddTask={handleOpenAddTaskFromDetail}
                onOpenReorganize={() => setOpenReorderDialog(true)}
              />
            )}
          </Stack>
          <AddDetailDialog
            associationType={detailAssociationType}
            associatedId={id}
            type={DetailsAddDialogType.AddDetail}
            onClose={onClose}
            open={openAdd}
          />
          <ReorderDetailsDialog
            open={openReorderDialog}
            onClose={() => setOpenReorderDialog(false)}
            associationType={detailAssociationType}
            associatedId={id}
          />
        </StyledInfoBox>
      )}
    </>
  );
};
