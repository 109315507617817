import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { StripedDataGrid } from '../../../datagrids/StripedDataGrid';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import Typography from '@mui/material/Typography';
import { DetailedAnalysisRow } from '../../redux/expenseData';

type POAdjustmentDetailPanelProps = {
  row: DetailedAnalysisRow;
};

export const POAdjustmentDetailPanel: FC<POAdjustmentDetailPanelProps> = ({ row }) => {
  return (
    <Box
      sx={{
        py: 2,
        height: 1,
        boxSizing: 'border-box',
        backgroundColor: 'lightgray',
      }}
    >
      <Paper sx={{ flex: 1, mx: 'auto', width: '65%', minHeight: '80%', p: 1 }}>
        <Stack direction="column" spacing={1}>
          {row.poChangeOrders && row.poChangeOrders.length > 0 ? (
            <StripedDataGrid
              disableRowGrouping
              columns={[
                {
                  field: 'description',
                  headerName: 'Description',
                  flex: 2,
                },
                {
                  field: 'amount',
                  headerName: 'PO Change Orders',
                  flex: 1,
                  align: 'right',
                  headerAlign: 'right',
                  renderCell: (params: GridRenderCellParams) => {
                    if (params.value === 0) return <></>;
                    return <>${params.value.toFixed(2)}</>;
                  },
                },
              ]}
              rows={row.poChangeOrders}
              autoHeight
              getRowClassName={(params) => {
                return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
              }}
              hideFooter
            />
          ) : (
            <Typography align={'center'}>No PO Change Orders</Typography>
          )}
        </Stack>
      </Paper>
    </Box>
  );
};
