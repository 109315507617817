import React, { useEffect, useState } from 'react';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import { PhoneTextField } from '../../../../../_shared/texfields/PhoneTextField';
import {
  ApplicationType,
  RentFrequency,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ReduxHousingHistory } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { randomId } from '@mui/x-data-grid-generator';
import { useDispatch } from 'react-redux';
import { updateApplicationAction } from '../../../../redux/applicationSlice';
import { tryFormatDate } from '../../../../../_shared/utils/TryFormatDate';

type Props = {
  onOpen?: () => void;
  applicantHousingHistory: ReduxHousingHistory[] | undefined;
  applicantId: string | undefined;
  applicationType: ApplicationType;
};

type HousingHistoryWithId = ReduxHousingHistory & { id: string };

export const HousingHistory = ({ onOpen, applicantHousingHistory, applicantId, applicationType }: Props) => {
  const dispatch = useDispatch();
  const [displayedHousingHistory, setDisplayedHousingHistory] = useState<HousingHistoryWithId[]>([]);

  useEffect(() => {
    if (applicantHousingHistory) {
      const newHousingHistories = applicantHousingHistory?.map((history) => {
        return { ...history, id: randomId() };
      });
      setDisplayedHousingHistory(newHousingHistories);
    }
  }, [applicantHousingHistory]);

  const removeHousingHistory = (id: string) => {
    const updatedHousingHistory = displayedHousingHistory.filter((history) => history.id !== id);
    setDisplayedHousingHistory(updatedHousingHistory);
    //gotta remove id then send it
    applicantId &&
      dispatch(
        updateApplicationAction({
          id: applicantId,
          type: applicationType,
          body: { housingHistory: updatedHousingHistory },
        }),
      );
  };

  return (
    <StyledInfoBox label={'Housing History'}>
      <Stack spacing={1}>
        <Box sx={{ mb: '.5rem' }}>
          <Button variant="outlined" onClick={onOpen}>
            Add Housing History
          </Button>
        </Box>
        <Typography variant="body2">*Please add the past five years of housing history</Typography>
        {!applicantHousingHistory ? (
          <></>
        ) : (
          displayedHousingHistory.map((history) => {
            return (
              <Box
                key={history?.startDate + '--' + history?.endDate + '--' + history?.reasonForLeaving}
                sx={{ border: '.1rem solid black', borderRadius: 2, padding: '.5rem' }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="streetAddress1"
                      label="Street Address 1"
                      type="string"
                      variant="standard"
                      value={history?.address?.streetAddress1 ? history.address.streetAddress1 : ''}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="streetAddress2"
                      label="Street Address 2"
                      type="text"
                      variant="standard"
                      value={history?.address?.streetAddress2 ? history.address.streetAddress2 : ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="city"
                      label="City"
                      type="text"
                      variant="standard"
                      value={history?.address?.city ? history.address.city : ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="state"
                      label="State"
                      type="text"
                      variant="standard"
                      value={history?.address?.state ? history.address.state : ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="zip"
                      label="Zip Code"
                      type="number"
                      variant="standard"
                      value={history?.address?.zip ? history.address.zip : ''}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      margin="dense"
                      label={'Start Date'}
                      id="endDate"
                      value={history.startDate ? tryFormatDate(history.startDate) : undefined}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="endDate"
                      label={'End Date'}
                      value={history.endDate ? tryFormatDate(history.endDate) : undefined}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="reasonForLeaving"
                      label="Reason for Leaving"
                      type="text"
                      variant="standard"
                      value={history.reasonForLeaving ? history.reasonForLeaving : ''}
                    />
                  </Grid>
                  {history.isRental ? (
                    <>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          margin="dense"
                          id="rentAmount"
                          label="Rent Amount"
                          type="number"
                          variant="standard"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          value={history.rentAmount ? history.rentAmount : ''}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          margin="dense"
                          id="rentFrequency"
                          value={history.rentFrequency ? history.rentFrequency : RentFrequency.Monthly}
                          label="Rent Frequency"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          margin="dense"
                          id="landlordFirstName"
                          label="Landlord First Name"
                          type="text"
                          variant="standard"
                          value={history.landlord?.firstName ? history.landlord.firstName : ''}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          margin="dense"
                          id="landlordLastName"
                          label="Landlord Last Name"
                          type="text"
                          variant="standard"
                          value={history.landlord?.lastName ? history.landlord.lastName : ''}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <PhoneTextField
                          fullWidth
                          margin="dense"
                          id="landlordPhone"
                          label="Landlord Phone"
                          type="text"
                          variant="standard"
                          value={history.landlord?.contactInfo?.phone ? history.landlord.contactInfo.phone : ''}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          margin="dense"
                          id="landlordEmail"
                          label="Landlord Email"
                          type="text"
                          variant="standard"
                          value={history.landlord?.contactInfo?.email ? history.landlord.contactInfo.email : ''}
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  <Grid item container xs={12} justifyContent="right">
                    <Button
                      variant="outlined"
                      onClick={(e) => {
                        e.preventDefault();
                        removeHousingHistory(history.id);
                      }}
                    >
                      Remove Housing History
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            );
          })
        )}
      </Stack>
    </StyledInfoBox>
  );
};
