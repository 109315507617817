import { all, debounce, put } from 'redux-saga/effects';
import { searchAllAssetsAction, searchAllAssetsFailAction, searchAllAssetsSuccessAction } from './searchSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  AssociationType,
  SearchHandlerRequest,
  SearchHandlerResponse,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { showErrorAction, showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { mapReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import {
  mapReduxScheduledTask,
  mapReduxTask,
  mapReduxSopTask,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { mapReduxExpense } from '../../expenses/redux/expenseData';

function* searchAllAssets(
  action: PayloadAction<{
    searchText: string;
    searchTypes: AssociationType[];
    includeClosed: boolean;
    propertyId?: string;
    associationType?: AssociationType;
  }>,
) {
  try {
    const searchResult: SearchHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().search_SearchByText,
      new SearchHandlerRequest({
        searchText: action?.payload.searchText || '',
        associationTypes: action.payload.searchTypes ?? [],
        propertyId: action.payload.propertyId,
        includeClosed: action.payload.includeClosed,
      }),
    );

    if (searchResult) {
      const reduxLeases = searchResult.leases?.map((l) => mapReduxLease(l.toJSON()));
      const reduxTasks = searchResult.tasks?.map((t) => mapReduxTask(t.toJSON()));
      const reduxJobs = searchResult.expenses?.map((j) => mapReduxExpense(j.toJSON()));
      const reduxScheduledTasks = searchResult.scheduledTasks?.map((s) => mapReduxScheduledTask(s.toJSON()));
      const reduxSopTasks = searchResult.sopTasks?.map((x) => mapReduxSopTask(x.toJSON()));
      yield put(
        searchAllAssetsSuccessAction({
          ...searchResult.toJSON(),
          leases: reduxLeases,
          tasks: reduxTasks,
          expenses: reduxJobs,
          scheduledTasks: reduxScheduledTasks,
          sopTasks: reduxSopTasks,
        }),
      );
    } else {
      yield put(showToastMessageAction({ message: 'Problem executing search', severity: 'error' }));
    }
  } catch (error: any) {
    yield put(searchAllAssetsFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem with search' }));
  }
}

function* searchAllAssetsSaga() {
  yield debounce(314, searchAllAssetsAction.type, searchAllAssets);
}

export function* searchSagas() {
  yield all([searchAllAssetsSaga()]);
}
