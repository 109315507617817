import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { appTheme } from '../../../AppTheme';
import EditIcon from '@mui/icons-material/Edit';
import { RoomCoreData } from './roomBoxes/roomCoreData';
import { RoomDetails } from './roomBoxes/roomDetails';
import { RoomActionLog } from './roomBoxes/roomActionLog';
import { RoomNotes } from './roomBoxes/roomNotes';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRoomByIdAction, useRoom } from '../redux/roomSlice';
import { EditRoom } from './EditRoom';
import Button from '@mui/material/Button';

type RoomParams = {
  id: string;
};

export const Room = () => {
  const room = useRoom();
  const dispatch = useDispatch();
  const { id } = useParams<RoomParams>();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    id && dispatch(getRoomByIdAction(id));
  }, [id]);

  if (!room.selectedRoom.value) {
    return <></>;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container spacing={2} paddingLeft="1rem">
      <Grid item xs={9}>
        <Typography variant="h2" sx={{ color: appTheme.palette.secondary.main }}>
          {room.selectedRoom.value.name}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          <Button size="large" onClick={handleOpen}>
            <EditIcon />
            Edit Room
          </Button>
          <EditRoom room={room.selectedRoom.value} open={open} onClose={handleClose} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <RoomCoreData />
      </Grid>
      <Grid item xs={12}>
        <RoomDetails />
      </Grid>
      <Grid item xs={6}>
        <RoomActionLog />
      </Grid>
      <Grid item xs={6}>
        <RoomNotes />
      </Grid>
    </Grid>
  );
};
