class PhoneUtils {
  formatPhoneNumber(value: string): string {
    if (!value) {
      return '';
    }
    // First ten digits of input only
    const input = value.replace(/\D/g, '').substring(0, 10);
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      value = `(${areaCode}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      value = `(${areaCode}) ${middle}`;
    } else if (input.length > 0) {
      value = `(${areaCode}`;
    }

    return value;
  }

  isValid(value: string) {
    return value.replace(/\D/g, '').length === 10;
  }

  getDigits(value: string) {
    return value.replace(/[^0-9]/g, '');
  }
}

export const phoneUtils = new PhoneUtils();
