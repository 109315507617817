import React, { FC } from 'react';
import DialogActions from '@mui/material/DialogActions';
import { deleteTaskAutomationAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { appTheme } from '../../../../../../../AppTheme';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { ReduxSopTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { StyledInfoBox } from '../../../../../../_shared/styledComponents/StyledInfoBox';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useIcons } from '../../../../../../_shared/icons/useIcons';

type AutomationsListProps = {
  sopTasks: ReduxSopTask[];
  isLoading: boolean;
  isSubmitting: boolean;
  onAddAutomation?: () => void;
  onEditAutomation: (automation: ReduxSopTask) => void;
  isInAutomationDialog?: boolean;
  isInAddTaskDialog?: boolean;
  onAddTaskFromAutomation?: (id: string) => void;
  page: number;
  pageSize: number;
  associatedId?: string;
  associationType?: AssociationType;
  includePropertyConfigs?: boolean;
  toggleIncludePropertyConfigs?: () => void;
};

export const SopTaskList: FC<AutomationsListProps> = ({
  sopTasks,
  isLoading,
  isSubmitting,
  onAddAutomation,
  onEditAutomation,
  isInAutomationDialog,
  isInAddTaskDialog,
  onAddTaskFromAutomation,
  page,
  pageSize,
  associatedId = undefined,
  associationType = undefined,
  includePropertyConfigs,
  toggleIncludePropertyConfigs,
}) => {
  const dispatch = useDispatch();
  const { getActionIcon, ActionType } = useIcons();
  return (
    <StyledInfoBox label={`${associationType === AssociationType.Detail ? 'Local ' : ''}SOP Task Templates`}>
      <Box display={'flex'} justifyContent={'space-between'}>
        {!isInAutomationDialog && onAddAutomation && (
          <Button variant={'outlined'} onClick={onAddAutomation} startIcon={<AddIcon />}>
            Add {associationType === AssociationType.Detail ? 'Local ' : ''}SOP Task
          </Button>
        )}
        {includePropertyConfigs !== undefined && toggleIncludePropertyConfigs && (
          <FormGroup sx={{ mt: 1 }}>
            <FormControlLabel
              control={
                <Switch size="small" checked={includePropertyConfigs} onChange={() => toggleIncludePropertyConfigs()} />
              }
              label={'Include Property SOP Tasks'}
            />
          </FormGroup>
        )}
      </Box>
      {!sopTasks ||
        (sopTasks.length === 0 && (
          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
            <Typography variant={'h6'} fontStyle={'italic'} color={appTheme.palette.grey['800']}>
              No Current SOP Tasks
            </Typography>
          </Box>
        ))}
      {isInAutomationDialog && <Divider />}
      <Box sx={{ height: '.1rem' }}>{isLoading && <LinearProgress />}</Box>
      <List sx={{ width: '100%', height: '90%', backgroundColor: 'background.paper' }}>
        {sopTasks &&
          sopTasks.map((automation) => {
            return (
              <ListItem key={automation.id} sx={{ display: 'flex', flexGrow: 1, paddingX: '0rem' }}>
                <Card
                  sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}
                  onClick={() => {
                    isInAddTaskDialog
                      ? onAddTaskFromAutomation && onAddTaskFromAutomation(automation.id!)
                      : onEditAutomation(automation);
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ cursor: 'pointer', textDecoration: isInAddTaskDialog ? 'none' : 'underline' }}
                      color="inherit"
                    >
                      {automation.name}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {isInAddTaskDialog && onAddTaskFromAutomation && automation.id ? (
                      <Tooltip title={'See SOP Task Details'}>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            onEditAutomation(automation);
                          }}
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title={'Delete SOP Task'}>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            automation.propertyId &&
                              automation.id &&
                              dispatch(
                                deleteTaskAutomationAction({
                                  propertyId: automation.propertyId,
                                  configId: automation.id,
                                  page: page,
                                  pageSize: pageSize,
                                  associatedId: associatedId,
                                  associationType: associationType,
                                }),
                              );
                          }}
                        >
                          {getActionIcon(ActionType.Delete)}
                        </IconButton>
                      </Tooltip>
                    )}
                  </CardActions>
                </Card>
              </ListItem>
            );
          })}
      </List>
      {isInAutomationDialog && onAddAutomation && (
        <DialogActions>
          <LoadingButton variant={'contained'} onClick={onAddAutomation} loading={isSubmitting}>
            Add SOP Task
          </LoadingButton>
        </DialogActions>
      )}
    </StyledInfoBox>
  );
};
