import { all, put, takeLatest } from 'redux-saga/effects';
import {
  clearPlaidLinkTokenAction,
  createPropertyBankAccountAction,
  createTenantBankAccountAction,
  createPlaidProcessorTokenFailAction,
  createBankAccountSuccessAction,
  getPlaidLinkTokenAction,
  getPlaidLinkTokenFailAction,
  getPlaidLinkTokenSuccessAction,
  updatePlaidLinkTokenAction,
  updatePlaidLinkTokenSuccessAction,
  updatePlaidLinkTokenFailAction,
  plaidRefreshSuccessfulAction,
  plaidRevalidateFinishAction,
} from './bankAccountsSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  CreatePropertyBankAccountResponse,
  CreateTenantBankAccountResponse,
  GetPlaidLinkTokenHandlerRequest,
  IGetPlaidLinkTokenHandlerResponse,
  IUpdatePlaidLinkTokenHandlerResponse,
  UpdateMtAccountPlaidStatusHandlerRequest,
  UpdatePlaidLinkTokenHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { showErrorAction, showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import {
  createPropertyMtAccountSuccessAction,
  plaidRevalidatePropertySuccessAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import {
  createUserMtAccountSuccessAction,
  plaidRevalidateSuccessAction,
} from '../../../tenantPortal/redux/tenantSlice';

function* createPlaidLinkToken(action: PayloadAction<GetPlaidLinkTokenHandlerRequest>) {
  try {
    const response: IGetPlaidLinkTokenHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().plaid_CreatePlaidLinkToken,
      action.payload,
    );
    if (response.plaidLinkToken) {
      yield put(getPlaidLinkTokenSuccessAction({ plaidLinkToken: response.plaidLinkToken }));
    } else {
      yield put(
        showToastMessageAction({
          message: 'Something went wrong initializing Plaid link token',
          severity: 'error',
        }),
      );
    }
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not initialize Plaid link token' }));
    yield put(getPlaidLinkTokenFailAction());
  }
}

function* updatePlaidLinkToken(action: PayloadAction<UpdatePlaidLinkTokenHandlerRequest>) {
  try {
    const response: IUpdatePlaidLinkTokenHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().plaid_UpdatePlaidLinkToken,
      action.payload,
    );
    if (response.plaidLinkToken) {
      yield put(updatePlaidLinkTokenSuccessAction({ plaidLinkToken: response.plaidLinkToken }));
    } else {
      yield put(
        showToastMessageAction({
          message: 'Something went wrong initializing Plaid link token',
          severity: 'error',
        }),
      );
    }
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not initialize Plaid link token' }));
    yield put(updatePlaidLinkTokenFailAction());
  }
}

function* createPropertyBankAccount(action: PayloadAction<CreatePropertyBankAccountResponse>) {
  try {
    const response: CreatePropertyBankAccountResponse = yield apiCall(
      ApiClientSingleton.getInstance().plaid_CreatePropertyBankAccount,
      action.payload,
    );
    if (response) {
      yield put(createPropertyMtAccountSuccessAction(response.toJSON()));

      yield put(clearPlaidLinkTokenAction());
      yield put(createBankAccountSuccessAction());
    } else throw new Error('Expected an account, but received a blank response');
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not add bank account with Plaid' }));
    yield put(createPlaidProcessorTokenFailAction());
  }
}

function* updateModernTreasuryAccountStatus(action: PayloadAction<UpdateMtAccountPlaidStatusHandlerRequest>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().plaid_UpdateMtAccountPlaidStatus, action.payload);
    if (action.payload.propertyId) {
      yield put(
        plaidRevalidatePropertySuccessAction({
          mtAccountId: action.payload.mtAccountId!,
          propertyId: action.payload.propertyId,
        }),
      );
    } else yield put(plaidRevalidateSuccessAction({ mtAccountId: action.payload.mtAccountId! }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not update Plaid account status' }));
  } finally {
    yield put(plaidRevalidateFinishAction());
  }
}

function* createTenantBankAccount(action: PayloadAction<CreateTenantBankAccountResponse>) {
  try {
    const response: CreateTenantBankAccountResponse = yield apiCall(
      ApiClientSingleton.getInstance().plaid_CreateTenantBankAccount,
      action.payload,
    );
    if (response) {
      yield put(createUserMtAccountSuccessAction(response.toJSON().bankAccount));

      yield put(clearPlaidLinkTokenAction());
      yield put(createBankAccountSuccessAction());
    } else throw new Error('Expected an account, but received a blank response');
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not add bank account with Plaid' }));
    yield put(createPlaidProcessorTokenFailAction());
  }
}

export function* bankAccountsSaga() {
  yield all([
    takeLatest(getPlaidLinkTokenAction.type, createPlaidLinkToken),
    takeLatest(updatePlaidLinkTokenAction.type, updatePlaidLinkToken),
    takeLatest(plaidRefreshSuccessfulAction.type, updateModernTreasuryAccountStatus),
    takeLatest(createPropertyBankAccountAction.type, createPropertyBankAccount),
    takeLatest(createTenantBankAccountAction.type, createTenantBankAccount),
  ]);
}
