import React, { FC, useEffect, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { appTheme } from '../../../../../../../../AppTheme';
import { CustomizedDot } from './CustomizedDot';
import AddIcon from '@mui/icons-material/Add';
import SquareIcon from '@mui/icons-material/Square';
import StarIcon from '@mui/icons-material/Star';
import CircleIcon from '@mui/icons-material/Circle';
import { HistoricalLeaseStatusData } from './formatHistoricalData';

type HistoricalLeaseStatusMetricsProps = {
  leaseStatusData: HistoricalLeaseStatusData[];
};

export const HistoricalLeaseStatusMetrics: FC<HistoricalLeaseStatusMetricsProps> = ({ leaseStatusData }) => {
  const [isHidden, setIsHidden] = useState({
    preLease: false,
    moveOut: false,
    monthToMonth: false,
    toBeClosed: false,
    underReview: false,
  });
  const [dataVersion, setDataVersion] = useState(0);

  useEffect(() => {
    setDataVersion((value) => value + 1);
  }, [leaseStatusData]);

  // Note: the signature type should be DataKey, but for some reason
  //       recharts isn't letting us import that type
  //       See issue: https://github.com/recharts/recharts/issues/3619
  const handleLegendClick = (dataKey: string | unknown) => {
    switch (dataKey) {
      case 'Pre-Lease':
        setIsHidden({ ...isHidden, preLease: !isHidden.preLease });
        break;
      case 'Move-Outs Scheduled':
        setIsHidden({ ...isHidden, moveOut: !isHidden.moveOut });
        break;
      case 'Month-To-Month':
        setIsHidden({ ...isHidden, monthToMonth: !isHidden.monthToMonth });
        break;
      case 'To Be Closed':
        setIsHidden({ ...isHidden, toBeClosed: !isHidden.toBeClosed });
        break;
      case 'Leases Under Review':
        setIsHidden({ ...isHidden, underReview: !isHidden.underReview });
        break;
      default:
        return;
    }
  };

  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart
        width={350}
        height={350}
        data={leaseStatusData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend onClick={(e) => handleLegendClick(e.dataKey)} />
        <Line
          type="monotone"
          dataKey="Pre-Lease"
          stroke={appTheme.palette.primary.dark}
          hide={isHidden.preLease}
          key={`prelease-${dataVersion}`}
        />
        <Line
          type="monotone"
          dataKey="Move-Outs Scheduled"
          stroke={appTheme.palette.secondary.main}
          hide={isHidden.moveOut}
          legendType={'cross'}
          dot={<CustomizedDot large icon={<AddIcon color={'secondary'} />} />}
          key={`moveout-${dataVersion}`}
        />
        <Line
          type="monotone"
          dataKey="Month-To-Month"
          stroke={appTheme.palette.warning.dark}
          hide={isHidden.monthToMonth}
          legendType={'square'}
          dot={<CustomizedDot icon={<SquareIcon color={'warning'} />} />}
          key={`monthtomonth-${dataVersion}`}
        />
        <Line
          type="monotone"
          dataKey="To Be Closed"
          stroke={appTheme.palette.error.main}
          hide={isHidden.toBeClosed}
          legendType={'star'}
          dot={<CustomizedDot icon={<StarIcon color={'error'} />} />}
          key={`tobeclosed-${dataVersion}`}
        />
        <Line
          type="monotone"
          dataKey="Leases Under Review"
          hide={isHidden.underReview}
          legendType={'circle'}
          dot={<CustomizedDot icon={<CircleIcon color={'info'} />} />}
          key={`underreview-${dataVersion}`}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
