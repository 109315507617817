import React, { FC, FormEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DialogLayout } from '../../dialogs/DialogLayout';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { TaskSection } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  changeSectionTaskAction,
  closeChangeSectionTaskAction,
  useTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { useLocation } from 'react-router-dom';

export const ChangeTaskSectionDialog: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { changeSectionTaskId } = useTasks();
  const [selectedSection, setSelectedSection] = React.useState<TaskSection | null>(null);
  const [currentSection, setCurrentSection] = React.useState<TaskSection | null>(null);

  useEffect(() => {
    const section = location.pathname.split('/')[4]?.toLowerCase();
    switch (section) {
      case 'leasing':
        setCurrentSection(TaskSection.Leasing);
        break;
      case 'operations':
        setCurrentSection(TaskSection.Operations);
        break;
      case 'financials':
        setCurrentSection(TaskSection.Financials);
        break;
      default:
        setCurrentSection(null);
    }
  }, [location]);

  const handleClose = () => {
    dispatch(closeChangeSectionTaskAction());
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    changeSectionTaskId &&
      selectedSection &&
      dispatch(
        changeSectionTaskAction({
          taskId: changeSectionTaskId,
          section: selectedSection,
        }),
      );
  };

  return (
    <DialogLayout
      title={'Change Task Section'}
      onClose={handleClose}
      open={!!changeSectionTaskId}
      maxWidth="xs"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <Box p={2}>
          <DialogTitle>Move a Task from one section to another</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Autocomplete
                value={selectedSection}
                onChange={(event, newValue) => {
                  setSelectedSection(newValue);
                }}
                renderInput={(params) => <TextField {...params} required label="Section" />}
                options={[TaskSection.Leasing, TaskSection.Operations, TaskSection.Financials].filter(
                  (s) => s !== currentSection,
                )}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained">
              Change Section
            </Button>
          </DialogActions>
        </Box>
      </form>
    </DialogLayout>
  );
};
