import React, { FC, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { useDispatch } from 'react-redux';
import { GlobalSnackbar } from '../../global/components/globalSnackbar/GlobalSnackbar';
import { PaymentsCard } from './payments/PaymentsCard';
import { LeaseCard } from './lease/LeaseCard';
import { MaintenanceRequestCard } from './maintenanceRequests/MaintenanceRequestCard';
import { MakePaymentDialog } from './payments/MakePaymentDialog';
import { LeaseDetailsDialog } from './lease/LeaseDetailsDialog';
import { ScheduleMoveOutDialog } from './lease/ScheduleMoveOutDialog';
import {
  clearAllTenantInfoAction,
  getTenantLeaseByIdAction,
  getUserBankAccountsAction,
  setSelectedLeaseAction,
  useTenant,
} from '../redux/tenantSlice';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { SelectUnitAndLease } from './SelectUnitAndLease';
import { LeaseDetailedStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { Claims } from '@monkeyjump-labs/cam-fe-shared/dist/types/constants';
import { BankingInfoCard } from './manageAccounts/BankingInfoCard';
import Grid from '@mui/material/Grid';
import { useMediaQuery, useTheme } from '@mui/material';
import { useOutlet, useParams } from 'react-router-dom';
import { DepositCard } from './lease/DepositCard';
import { getLeaseDepositBalanceAction } from '../../_shared/leases/redux/leasesSlice';

export const TenantPortal: FC = () => {
  const dispatch = useDispatch();
  const outlet = useOutlet();
  const userInfo = useUser((u) => u.currentUser.value);
  const { leaseId } = useParams();
  const { leases } = useTenant();
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [openLeaseDetails, setOpenLeaseDetails] = useState(false);
  const [openMoveOut, setOpenMoveOut] = useState(false);
  const [selectedLeaseStatus, setSelectedLeaseStatus] = useState<LeaseDetailedStatus | undefined>();
  const propertyHasPaymentsEnabled = leases.selectedValue?.propertyHasPaymentsEnabled ?? false;
  const propertyHasCreditCardPaymentsEnabled = leases.selectedValue?.propertyHasCreditCardPaymentsEnabled ?? false;
  const theme = useTheme();
  const defaultClosedCards = useMediaQuery(theme.breakpoints.up('md'));

  const fetchTenantData = () => {
    dispatch(clearAllTenantInfoAction());
    dispatch(getUserBankAccountsAction());
    const tenantClaimIds = new Set<string>();
    userInfo?.claims?.map((claim) => {
      if (claim.type === Claims.Tenant && claim.value && !tenantClaimIds.has(claim.value)) {
        tenantClaimIds.add(claim.value);
      }
    });
    Array.from(tenantClaimIds).map((claimId) => {
      dispatch(getTenantLeaseByIdAction(claimId));
    });
  };

  useEffect(() => {
    leases.selectedValue?.id && dispatch(getLeaseDepositBalanceAction(leases.selectedValue?.id));
  }, [leases.selectedValue?.id]);

  useEffect(() => {
    fetchTenantData();
  }, [userInfo]);

  useEffect(() => {
    setSelectedLeaseStatus(leases.selectedValue?.detailedStatus);
  }, [leases.selectedValue]);

  useEffect(() => {
    leaseId && dispatch(setSelectedLeaseAction(leaseId));
  }, [leaseId]);

  const handleOpenPaymentDialog = () => {
    setOpenPaymentDialog(true);
  };

  const handleClosePaymentDialog = () => {
    setOpenPaymentDialog(false);
  };

  const handleOpenLeaseDetails = () => {
    setOpenLeaseDetails(true);
  };

  const handleCloseLeaseDetails = () => {
    setOpenLeaseDetails(false);
  };

  const handleOpenMoveOut = () => {
    setOpenMoveOut(true);
  };

  const handleCloseMoveOut = () => {
    setOpenMoveOut(false);
  };

  return (
    <>
      <Container sx={{ mb: '1rem', minHeight: 'calc(100vh - 104px)' }}>
        {outlet ?? (
          <Grid container spacing={3} sx={{ mt: '.1rem' }}>
            <Grid item xs={12}>
              <SelectUnitAndLease />
              <Divider sx={{ borderBottomWidth: 5 }} />
            </Grid>
            <Grid item xs={6}>
              <LeaseCard
                onOpenLeaseDetails={handleOpenLeaseDetails}
                onOpenMoveOut={handleOpenMoveOut}
                selectedLease={leases.selectedValue}
                selectedLeaseStatus={selectedLeaseStatus}
                focus={defaultClosedCards}
              />
            </Grid>
            <Grid item xs={6}>
              <DepositCard
                onOpenPayDeposit={handleOpenPaymentDialog}
                selectedLease={leases.selectedValue}
                selectedLeaseStatus={selectedLeaseStatus}
                focus={defaultClosedCards}
                propertyHasPaymentsEnabled={propertyHasPaymentsEnabled}
              />
            </Grid>
            <Grid item xs={12}>
              <PaymentsCard
                onOpenPaymentDialog={handleOpenPaymentDialog}
                propertyHasPaymentsEnabled={propertyHasPaymentsEnabled}
                propertyHasCreditCardPaymentsEnabled={propertyHasCreditCardPaymentsEnabled}
                focus={defaultClosedCards}
                selectedLease={leases.selectedValue}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BankingInfoCard focus={defaultClosedCards} propertyHasPaymentsEnabled={propertyHasPaymentsEnabled} />{' '}
            </Grid>
            <Grid item xs={12} md={6}>
              <MaintenanceRequestCard focus={defaultClosedCards} selectedLeaseId={leases.selectedValue?.id} />
            </Grid>
          </Grid>
        )}
      </Container>
      <GlobalSnackbar />
      <MakePaymentDialog
        open={openPaymentDialog}
        onClose={handleClosePaymentDialog}
        leaseId={leases.selectedValue?.id}
        unitName={leases.selectedValue?.unitName}
        hasCreditCardPaymentsEnabled={leases.selectedValue?.propertyHasCreditCardPaymentsEnabled ?? false}
      />

      <LeaseDetailsDialog
        open={openLeaseDetails}
        onClose={handleCloseLeaseDetails}
        selectedLease={leases.selectedValue}
      />
      <ScheduleMoveOutDialog
        open={openMoveOut}
        onClose={handleCloseMoveOut}
        currentMoveOutDate={leases.selectedValue?.moveOutDate}
        leaseId={leases.selectedValue?.id}
      />
    </>
  );
};
