import {
  Filter,
  initInteractable,
  initLoadable,
  initPageableCollection,
  initSubmittable,
  Interactable,
  Loadable,
  Openable,
  PageableCollection,
  SortDirection,
  Submittable,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import {
  LeaseDispatchType,
  ReduxDraftLease,
  ReduxLease,
  ReduxLeaseTerm,
  ReduxTenant,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import {
  ApplicationStatus,
  GetTenantInvitationStatusesHandlerInvitationStatus,
  IGetTenantInvitationStatusesHandlerSingleResponse,
  IManualQueryParameter_DateOnly,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ReduxDate, ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

type LeaseSpecificFilters = {
  additionalFilters?: {
    tenantName?: string;
    startDate?: IManualQueryParameter_DateOnly;
    endDate?: IManualQueryParameter_DateOnly;
  };
};

type PageableLeases = PageableCollection<ReduxLease> & LeaseSpecificFilters;

export interface LeasesState {
  allLeases: PageableLeases;
  includeClosedStatuses: boolean;
  renewalLeases: PageableLeases;
  draftLeases: PageableLeases;
  activeLease: Interactable<ReduxLease> & Openable;
  newTenant: Submittable<ReduxTenant>;
  tenantInvites: Loadable<IGetTenantInvitationStatusesHandlerSingleResponse[]>;
  quickEditLease: { editType?: LeaseDispatchType; value?: ReduxLease; renewalStartDate?: ReduxDate };
  editingLeaseId: string | undefined;
  editingLeaseType: LeaseDispatchType | undefined;
  leaseDepositBalance: Loadable<number | undefined>;
  availableDepositBalance: Loadable<number | undefined>;
  /* Note: This is different from the above as it is the amount that remains in the deposit bank account after returning any owed to the tenant */
  depositBankAccountBalance: Loadable<number | undefined>;
}

const initialState: LeasesState = {
  allLeases: initPageableCollection<ReduxLease>([]),
  includeClosedStatuses: false,
  renewalLeases: initPageableCollection<ReduxLease>([]),
  draftLeases: initPageableCollection<ReduxLease>([]),
  activeLease: { ...initInteractable<ReduxLease>(), open: false },
  newTenant: initSubmittable<ReduxTenant>(),
  tenantInvites: initLoadable<IGetTenantInvitationStatusesHandlerSingleResponse[]>(),
  quickEditLease: {},
  editingLeaseId: undefined,
  editingLeaseType: undefined,
  availableDepositBalance: initLoadable<number>(),
  depositBankAccountBalance: initLoadable<number>(),
  leaseDepositBalance: initLoadable<number>(),
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const leasesSlice = createSlice({
  name: 'leases',
  initialState,
  reducers: {
    getAllLeasesByAssetIdAction: (
      state,
      action: PayloadAction<{ propertyId: string; assetId: string; page?: number; pageSize?: number }>,
    ) => {
      state.allLeases.loading = true;
      state.allLeases.loaded = false;
      if (action.payload.page) {
        state.allLeases.page = action.payload.page;
      }
      if (action.payload.pageSize) {
        state.allLeases.pageSize = action.payload.pageSize;
      }
    },
    getAllLeasesByAssetIdSuccessAction: (
      state,
      action: PayloadAction<{ leases: ReduxLease[]; totalCount: number }>,
    ) => {
      state.allLeases.loaded = true;
      state.allLeases.loading = false;
      state.allLeases.value = action.payload.leases;
      state.allLeases.totalCount = action.payload.totalCount;
    },
    getAllLeasesByAssetIdFailAction: (state) => {
      state.allLeases.loaded = true;
      state.allLeases.loading = false;
      state.allLeases.value = undefined;
      state.allLeases.totalCount = undefined;
    },
    toggleLeaseIncludeClosedStatusesAction: (
      state,
      action: PayloadAction<{ includeClosedStatuses: boolean; propertyId: string; assetId: string }>,
    ) => {
      state.includeClosedStatuses = action.payload.includeClosedStatuses;
    },
    getRenewalLeasesForPropertyIdAction: (
      state,
      action: PayloadAction<{ propertyId: string; page?: number; pageSize?: number }>,
    ) => {
      state.renewalLeases.loading = true;
      state.renewalLeases.loaded = false;
      if (action.payload.page) {
        state.renewalLeases.page = action.payload.page;
      }
      if (action.payload.pageSize) {
        state.renewalLeases.pageSize = action.payload.pageSize;
      }
    },
    getRenewalLeasesForPropertyIdSuccessAction: (
      state,
      action: PayloadAction<{ leases: ReduxLease[]; totalCount: number }>,
    ) => {
      state.renewalLeases.loaded = true;
      state.renewalLeases.loading = false;
      state.renewalLeases.value = action.payload.leases;
      state.renewalLeases.totalCount = action.payload.totalCount;
    },
    getRenewalLeasesForPropertyIdFailAction: (state) => {
      state.renewalLeases.loaded = true;
      state.renewalLeases.loading = false;
      state.renewalLeases.value = undefined;
      state.renewalLeases.totalCount = undefined;
    },
    getDraftLeasesForPropertyIdAction: (
      state,
      action: PayloadAction<{ propertyId: string; page?: number; pageSize?: number }>,
    ) => {
      state.draftLeases.loading = true;
      state.draftLeases.loaded = false;
      if (action.payload.page) {
        state.draftLeases.page = action.payload.page;
      }
      if (action.payload.pageSize) {
        state.draftLeases.pageSize = action.payload.pageSize;
      }
    },
    getDraftLeasesForPropertyIdSuccessAction: (
      state,
      action: PayloadAction<{ leases: ReduxLease[]; totalCount: number }>,
    ) => {
      state.draftLeases.loaded = true;
      state.draftLeases.loading = false;
      state.draftLeases.value = action.payload.leases;
      state.draftLeases.totalCount = action.payload.totalCount;
    },
    getDraftLeasesForPropertyIdFailAction: (state) => {
      state.draftLeases.loaded = true;
      state.draftLeases.loading = false;
      state.draftLeases.value = undefined;
      state.draftLeases.totalCount = undefined;
    },
    setLeaseTableFiltersAction: (
      state,
      action: PayloadAction<{
        assetId: string;
        propertyId: string;
        leaseType: LeaseDispatchType;
        tenantName?: string;
        startDateFilter?: IManualQueryParameter_DateOnly;
        endDateFilter?: IManualQueryParameter_DateOnly;
        filters: Filter<ReduxLease>[] | undefined;
      }>,
    ) => {
      switch (action.payload.leaseType) {
        case 'all':
          state.allLeases.additionalFilters = {
            tenantName: action.payload.tenantName,
            startDate: action.payload.startDateFilter,
            endDate: action.payload.endDateFilter,
          };
          state.allLeases.filters = action.payload.filters;
          break;
        case 'draft':
          state.draftLeases.additionalFilters = {
            tenantName: action.payload.tenantName,
            startDate: action.payload.startDateFilter,
            endDate: action.payload.endDateFilter,
          };
          state.draftLeases.filters = action.payload.filters;
          break;
        case 'renewal':
          state.renewalLeases.additionalFilters = {
            tenantName: action.payload.tenantName,
            startDate: action.payload.startDateFilter,
            endDate: action.payload.endDateFilter,
          };
          state.renewalLeases.filters = action.payload.filters;
          break;
      }
    },
    setLeaseTableSortAction: (
      state,
      action: PayloadAction<{
        assetId: string;
        propertyId: string;
        leaseType: LeaseDispatchType;
        sortDirection: SortDirection | undefined;
        sortBy: keyof ReduxLease | undefined;
      }>,
    ) => {
      switch (action.payload.leaseType) {
        case 'all':
          state.allLeases.sortBy = action.payload.sortBy;
          state.allLeases.sortDirection = action.payload.sortDirection;
          break;
        case 'draft':
          state.draftLeases.sortBy = action.payload.sortBy;
          state.draftLeases.sortDirection = action.payload.sortDirection;
          break;
        case 'renewal':
          state.renewalLeases.sortBy = action.payload.sortBy;
          state.renewalLeases.sortDirection = action.payload.sortDirection;
          break;
      }
    },
    clearLeaseFiltersAndSortsAction: (state) => {
      state.allLeases.filters = undefined;
      state.allLeases.additionalFilters = undefined;
      state.allLeases.sortBy = undefined;
      state.allLeases.sortDirection = undefined;
      state.draftLeases.filters = undefined;
      state.draftLeases.additionalFilters = undefined;
      state.draftLeases.sortBy = undefined;
      state.draftLeases.sortDirection = undefined;
      state.renewalLeases.filters = undefined;
      state.renewalLeases.additionalFilters = undefined;
      state.renewalLeases.sortBy = undefined;
      state.renewalLeases.sortDirection = undefined;
    },
    createLeaseAction: (state, action: PayloadAction<ReduxDraftLease>) => {
      state.draftLeases.submitting = true;
      state.draftLeases.submitted = false;
      state.draftLeases.submittingValue = action.payload as ReduxLease;
    },
    createLeaseSuccessAction: (state, action: PayloadAction<ReduxLease>) => {
      state.draftLeases.submitting = false;
      state.draftLeases.submitted = true;
      state.draftLeases.submittingValue = undefined;
      state.draftLeases.selectedValue = action.payload;
    },
    createLeaseFailAction: (state) => {
      state.draftLeases.submitting = false;
      state.draftLeases.submitted = false;
    },
    getSingleLeaseAction: (state, action: PayloadAction<{ leaseId: string; openLeaseModal: boolean }>) => {
      state.activeLease.loading = true;
      state.activeLease.loaded = false;
      state.activeLease.open = action.payload.openLeaseModal;
    },
    openLeaseModalAction: (state, action: PayloadAction<boolean>) => {
      state.activeLease.open = action.payload;
    },
    successGetSingleLeaseAction: (state, action: PayloadAction<ReduxLease>) => {
      state.activeLease.loading = false;
      state.activeLease.loaded = true;
      state.activeLease.submitting = false;
      state.activeLease.value = action.payload;
    },
    failGetSingleLeaseAction: (state) => {
      state.activeLease.loading = false;
      state.activeLease.loaded = true;
      state.activeLease.value = undefined;
    },
    unsetSingleLeaseAction: (state) => {
      state.activeLease.loaded = false;
      state.activeLease.value = undefined;
      state.activeLease.open = false;
    },
    setEditingLeaseAction: (state, action: PayloadAction<{ leaseId: string; leaseType?: LeaseDispatchType }>) => {
      state.editingLeaseId = action.payload.leaseId;
      if (action.payload.leaseType) {
        state.editingLeaseType = action.payload.leaseType;
      }
    },
    quickEditLeaseAction: (state, action: PayloadAction<{ lease: ReduxLease; editType: LeaseDispatchType }>) => {
      state.quickEditLease.value = action.payload.lease;
      state.quickEditLease.editType = action.payload.editType;
    },
    getLeaseRenewalStartDateSuccessAction: (state, action: PayloadAction<ReduxDate>) => {
      state.quickEditLease.renewalStartDate = action.payload;
    },
    getLeaseRenewalStartDateFailAction: (state) => {},
    clearQuickEditLeaseAction: (state) => {
      state.quickEditLease = {};
    },
    uploadDocumentAction: (
      state,
      action: PayloadAction<{ propertyId: string; leaseId: string; file: File; assetId: string }>,
    ) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    uploadSignedLeaseAction: (state, action: PayloadAction<{ leaseId: string; propertyId: string }>) => {},
    successUploadedDocumentAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = true;
    },
    failUploadedDocumentAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = false;
    },
    removeDocumentAction: (
      state,
      action: PayloadAction<{ propertyId: string; leaseId: string; documentId: string }>,
    ) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    successRemoveDocumentAction: (state, action: PayloadAction<{ documentId: string }>) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = true;

      const documentIndex = state.activeLease.value?.documents?.findIndex(
        (x: ReduxDocument) => x.id === action.payload.documentId,
      );
      if (documentIndex !== undefined && documentIndex !== -1) {
        state.activeLease.value?.documents?.splice(documentIndex, 1);
      }
    },
    failRemoveDocumentAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = false;
    },
    cancelRemoveDocumentAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = false;
    },
    addCommentToLeaseAction: (
      state,
      action: PayloadAction<{ propertyId: string; leaseId: string; comment: string; assetId: string }>,
    ) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    successAddCommentToLeaseAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = true;
    },
    failAddCommentToLeaseAction: (state) => {
      state.activeLease.submitted = false;
      state.activeLease.submitting = false;
    },
    addTenantAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        assetId: string;
        leaseType: LeaseDispatchType;
        tenant: ReduxTenant;
      }>,
    ) => {
      state.newTenant.submitting = true;
      state.newTenant.submitted = false;
    },
    addTenantSuccessAction: (state) => {
      state.newTenant.submittingValue = undefined;
      state.newTenant.submitting = false;
      state.newTenant.submitted = true;
    },
    addTenantFailAction: (state) => {
      state.newTenant.submitting = false;
      state.newTenant.submitted = false;
    },
    getTenantInvitationStatusAction: (state, action: PayloadAction<string>) => {
      state.tenantInvites.loading = true;
      state.tenantInvites.loaded = false;
    },
    getTenantInvitationStatusSuccessAction: (
      state,
      action: PayloadAction<IGetTenantInvitationStatusesHandlerSingleResponse[]>,
    ) => {
      state.tenantInvites.loading = false;
      state.tenantInvites.loaded = true;
      state.tenantInvites.value = action.payload;
    },
    getTenantInvitationStatusFailAction: (state) => {
      state.tenantInvites.loading = false;
      state.tenantInvites.loaded = false;
      state.tenantInvites.value = undefined;
    },
    openNewTenantDialogAction: (state) => {
      state.newTenant.submitted = false;
      state.newTenant.submitting = false;
      state.newTenant.submittingValue = {} as ReduxTenant;
    },
    closeNewTenantDialogAction: (state) => {
      state.newTenant.submitting = false;
      state.newTenant.submittingValue = undefined;
      state.newTenant.submitted = false;
    },
    inviteTenantAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        tenantId: string;
        email: string;
        assetId: string;
        leaseType: LeaseDispatchType;
      }>,
    ) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    inviteTenantSuccessAction: (
      state,
      action: PayloadAction<{ tenantId: string; email: string; invitationId?: string }>,
    ) => {
      state.activeLease.submitted = true;
      state.activeLease.submitting = false;
      if (state.activeLease.value?.leaseTerms) {
        const lastLeaseTerm = state.activeLease.value.leaseTerms[state.activeLease.value.leaseTerms.length - 1];
        if (lastLeaseTerm?.tenantGroup?.tenants) {
          lastLeaseTerm.tenantGroup.tenants = lastLeaseTerm.tenantGroup.tenants.map((t) => {
            if (t.id === action.payload.tenantId) {
              return { ...t, contactInfo: { ...t.contactInfo, email: action.payload.email } };
            } else {
              return t;
            }
          });
        }
        const newInvitation = {
          tenantId: action.payload.tenantId,
          invitationStatus: GetTenantInvitationStatusesHandlerInvitationStatus.Invited,
          invitationId: action.payload.invitationId,
        };
        state.activeLease.value.leaseTerms[state.activeLease.value.leaseTerms.length - 1] = lastLeaseTerm;
        state.tenantInvites.value
          ? state.tenantInvites.value?.push(newInvitation)
          : (state.tenantInvites.value = [newInvitation]);
      }
    },
    inviteTenantFailAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = false;
    },
    cancelInviteTenantAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        invitationId: string;
        assetId: string;
        leaseType: LeaseDispatchType;
      }>,
    ) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    cancelInviteTenantSuccessAction: (state, action: PayloadAction<string>) => {
      state.activeLease.submitted = true;
      state.activeLease.submitting = false;
      const invitationToRemove = state.tenantInvites.value?.findIndex((x) => x.invitationId === action.payload);
      if (invitationToRemove !== undefined) {
        state.tenantInvites.value?.splice(invitationToRemove, 1);
      }
    },
    cancelInviteTenantFailAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = false;
    },
    removeTenantAccessAction: (
      state,
      action: PayloadAction<{
        tenantId: string;
        assetId: string;
        leaseId: string;
        leaseType: LeaseDispatchType;
      }>,
    ) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    removeTenantAccessSuccessAction: (state, action: PayloadAction<{ tenantId: string }>) => {
      state.activeLease.submitted = true;
      state.activeLease.submitting = false;
      const removedInviteIndex = state.tenantInvites.value?.findIndex((v) => v.tenantId === action.payload.tenantId);
      console.log(removedInviteIndex);
      if (removedInviteIndex !== undefined) {
        console.log('removing access');
        state.tenantInvites.value?.splice(removedInviteIndex, 1);
      }
    },
    removeTenantAccessFailAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = false;
    },
    getAvailableDepositBalanceAction: (state, action: PayloadAction<string>) => {
      state.availableDepositBalance.loading = true;
      state.availableDepositBalance.loaded = false;
    },
    getAvailableDepositBalanceSuccessAction: (state, action: PayloadAction<number>) => {
      state.availableDepositBalance.loading = false;
      state.availableDepositBalance.loaded = true;
      state.availableDepositBalance.value = action.payload;
    },
    getAvailableDepositBalanceFailAction: (state) => {
      state.availableDepositBalance.loading = false;
      state.availableDepositBalance.loaded = false;
      state.availableDepositBalance.value = undefined;
    },
    getLeaseDepositBalanceAction: (state, action: PayloadAction<string>) => {
      state.leaseDepositBalance.loading = true;
      state.leaseDepositBalance.loaded = false;
    },
    getLeaseDepositBalanceSuccessAction: (state, action: PayloadAction<number>) => {
      state.leaseDepositBalance.loading = false;
      state.leaseDepositBalance.loaded = true;
      state.leaseDepositBalance.value = action.payload;
    },
    getLeaseDepositBalanceFailAction: (state) => {
      state.leaseDepositBalance.loading = false;
      state.leaseDepositBalance.loaded = false;
      state.leaseDepositBalance.value = undefined;
    },
    getAmountOwedToTenantAction: (state, action: PayloadAction<string>) => {
      state.availableDepositBalance.loading = true;
      state.availableDepositBalance.loaded = false;
    },
    getAmountOwedToTenantSuccessAction: (state, action: PayloadAction<number>) => {
      state.availableDepositBalance.loading = false;
      state.availableDepositBalance.loaded = true;
      state.availableDepositBalance.value = action.payload;
    },
    getAmountOwedToTenantFailAction: (state) => {
      state.availableDepositBalance.loading = false;
      state.availableDepositBalance.loaded = false;
      state.availableDepositBalance.value = undefined;
    },
    getDepositBankAccountBalanceAction: (state, action: PayloadAction<string>) => {
      state.depositBankAccountBalance.loading = true;
      state.depositBankAccountBalance.loaded = false;
    },
    getDepositBankAccountBalanceSuccessAction: (state, action: PayloadAction<number>) => {
      state.depositBankAccountBalance.loading = false;
      state.depositBankAccountBalance.loaded = true;
      state.depositBankAccountBalance.value = action.payload;
    },
    getDepositBankAccountBalanceFailAction: (state) => {
      state.depositBankAccountBalance.loading = false;
      state.depositBankAccountBalance.loaded = false;
      state.depositBankAccountBalance.value = undefined;
    },
    updateLeaseAction: (state, action: PayloadAction<{ leaseType: string; assetId: string; body: ReduxLease }>) => {
      state.activeLease.submittingValue = action.payload.body;
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    updateCurrentLeaseTermsAction: (
      state,
      action: PayloadAction<{ leaseType: string; assetId: string; body: ReduxLease }>,
    ) => {
      state.activeLease.submittingValue = action.payload.body;
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    updateLeaseSuccessAction: (state) => {
      state.activeLease.loaded = false;
      state.activeLease.submittingValue = undefined;
      state.activeLease.submitting = false;
      state.activeLease.submitted = true;
    },
    updateLeaseFailAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = false;
    },
    cancelUpdateLeaseAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = false;
    },
    cancelUpdateLeaseConfirmedAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = false;
    },
    renewLeaseAction: (
      state,
      action: PayloadAction<{
        leaseType: LeaseDispatchType;
        assetId: string;
        leaseId: string;
        body: ReduxLeaseTerm;
      }>,
    ) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    renewLeaseSuccessAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = true;
    },
    renewLeaseFailAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = false;
    },
    cancelPreLeaseAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        propertyId: string;
        applicationIds: string[];
        newStatus: ApplicationStatus;
        assetId: string;
        leasesType: LeaseDispatchType;
      }>,
    ) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    markLeaseSignedAction: (
      state,
      action: PayloadAction<{ leaseId: string; propertyId: string; document: ReduxDocument }>,
    ) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    markLeaseAsPreleasedAction: (state, action: PayloadAction<{ leaseId: string; propertyId: string }>) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    markLeaseAsActiveAction: (
      state,
      action: PayloadAction<{ leaseId: string; assetId: string; leaseType: LeaseDispatchType }>,
    ) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    markLeaseAsMovedOutAction: (
      state,
      action: PayloadAction<{ leaseId: string; assetId: string; leaseType: LeaseDispatchType }>,
    ) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    rollbackLeaseToActiveAction: (
      state,
      action: PayloadAction<{ leaseId: string; assetId: string; leaseType: LeaseDispatchType }>,
    ) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    markLeaseAsEvictionAction: (
      state,
      action: PayloadAction<{ leaseId: string; assetId: string; leaseType: LeaseDispatchType }>,
    ) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    markLeaseAsClosedAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        assetId: string;
        leaseType: LeaseDispatchType;
        body: {
          reason: string;
          transferRemainingDepositFunds?: boolean;
          returnDepositAccountId?: string | undefined;
          returnDepositCheckNo?: string | undefined;
          returnOutstandingCreditAccountId?: string | undefined;
          returnOutstandingCreditCheckNo?: string | undefined;
        };
      }>,
    ) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    updateLeaseStatusSuccessAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = true;
    },
    updateLeaseStatusFailAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = false;
    },
    resetLeaseStatusSubmissionAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = false;
    },
    updateLeaseFromSignalRAction: (state, action: PayloadAction<ReduxLease>) => {
      const updatedLease = action.payload;
      const allLeasesIndex = state.allLeases.value?.findIndex((x) => x.id === updatedLease.id);
      if (allLeasesIndex !== undefined && allLeasesIndex !== -1) {
        state.allLeases.value?.splice(allLeasesIndex, 1, updatedLease);
      }
      const renewalLeasesIndex = state.renewalLeases.value?.findIndex((x) => x.id === updatedLease.id);
      if (renewalLeasesIndex !== undefined && renewalLeasesIndex !== -1) {
        state.renewalLeases.value?.splice(renewalLeasesIndex, 1, updatedLease);
      }
      const draftLeasesIndex = state.draftLeases.value?.findIndex((x) => x.id === updatedLease.id);
      if (draftLeasesIndex !== undefined && draftLeasesIndex !== -1) {
        state.draftLeases.value?.splice(draftLeasesIndex, 1, updatedLease);
      }
    },
    addWatcherToLeaseAction: (state, action: PayloadAction<{ leaseId: string; userId: string }>) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    removeWatcherFromLeaseAction: (state, action: PayloadAction<{ leaseId: string; userId: string }>) => {
      state.activeLease.submitting = true;
      state.activeLease.submitted = false;
    },
    watcherActionSuccessAction: (state, action: PayloadAction<ReduxLease>) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = true;
      state.activeLease.value = action.payload;
      // Update the lease in the all leases list
      const allLeasesIndex = state.allLeases.value?.findIndex((x) => x.id === action.payload.id);
      if (allLeasesIndex !== undefined && allLeasesIndex !== -1) {
        state.allLeases.value?.splice(allLeasesIndex, 1, action.payload);
      }
      // Update the lease in the renewal leases list
      const renewalLeasesIndex = state.renewalLeases.value?.findIndex((x) => x.id === action.payload.id);
      if (renewalLeasesIndex !== undefined && renewalLeasesIndex !== -1) {
        state.renewalLeases.value?.splice(renewalLeasesIndex, 1, action.payload);
      }
      // Update the lease in the draft leases list
      const draftLeasesIndex = state.draftLeases.value?.findIndex((x) => x.id === action.payload.id);
      if (draftLeasesIndex !== undefined && draftLeasesIndex !== -1) {
        state.draftLeases.value?.splice(draftLeasesIndex, 1, action.payload);
      }
    },
    watcherActionFailAction: (state) => {
      state.activeLease.submitting = false;
      state.activeLease.submitted = false;
    },
  },
});
/* eslint-enable @typescript-eslint/no-unused-vars */

export const {
  getAllLeasesByAssetIdAction,
  getAllLeasesByAssetIdSuccessAction,
  getAllLeasesByAssetIdFailAction,
  toggleLeaseIncludeClosedStatusesAction,
  getDraftLeasesForPropertyIdAction,
  getDraftLeasesForPropertyIdSuccessAction,
  getDraftLeasesForPropertyIdFailAction,
  getRenewalLeasesForPropertyIdAction,
  getRenewalLeasesForPropertyIdSuccessAction,
  getRenewalLeasesForPropertyIdFailAction,
  setLeaseTableFiltersAction,
  setLeaseTableSortAction,
  clearLeaseFiltersAndSortsAction,
  createLeaseAction,
  createLeaseFailAction,
  createLeaseSuccessAction,
  renewLeaseAction,
  cancelPreLeaseAction,
  addTenantFailAction,
  addTenantSuccessAction,
  addTenantAction,
  inviteTenantAction,
  inviteTenantFailAction,
  inviteTenantSuccessAction,
  cancelInviteTenantAction,
  cancelInviteTenantFailAction,
  cancelInviteTenantSuccessAction,
  removeTenantAccessAction,
  removeTenantAccessFailAction,
  removeTenantAccessSuccessAction,
  getTenantInvitationStatusAction,
  getTenantInvitationStatusFailAction,
  getTenantInvitationStatusSuccessAction,
  cancelUpdateLeaseAction,
  cancelUpdateLeaseConfirmedAction,
  renewLeaseFailAction,
  renewLeaseSuccessAction,
  uploadDocumentAction,
  uploadSignedLeaseAction,
  successUploadedDocumentAction,
  failUploadedDocumentAction,
  removeDocumentAction,
  successRemoveDocumentAction,
  failRemoveDocumentAction,
  cancelRemoveDocumentAction,
  addCommentToLeaseAction,
  successAddCommentToLeaseAction,
  failAddCommentToLeaseAction,
  getSingleLeaseAction,
  successGetSingleLeaseAction,
  failGetSingleLeaseAction,
  unsetSingleLeaseAction,
  openLeaseModalAction,
  setEditingLeaseAction,
  quickEditLeaseAction,
  getLeaseRenewalStartDateFailAction,
  getLeaseRenewalStartDateSuccessAction,
  clearQuickEditLeaseAction,
  openNewTenantDialogAction,
  closeNewTenantDialogAction,
  markLeaseSignedAction,
  getAvailableDepositBalanceAction,
  getAvailableDepositBalanceFailAction,
  getAvailableDepositBalanceSuccessAction,
  getAmountOwedToTenantAction,
  getDepositBankAccountBalanceAction,
  getDepositBankAccountBalanceFailAction,
  getDepositBankAccountBalanceSuccessAction,
  getAmountOwedToTenantFailAction,
  getAmountOwedToTenantSuccessAction,
  markLeaseAsPreleasedAction,
  updateLeaseAction,
  updateCurrentLeaseTermsAction,
  updateLeaseSuccessAction,
  updateLeaseFailAction,
  markLeaseAsActiveAction,
  markLeaseAsMovedOutAction,
  rollbackLeaseToActiveAction,
  markLeaseAsEvictionAction,
  markLeaseAsClosedAction,
  updateLeaseStatusFailAction,
  updateLeaseStatusSuccessAction,
  resetLeaseStatusSubmissionAction,
  updateLeaseFromSignalRAction,
  getLeaseDepositBalanceSuccessAction,
  getLeaseDepositBalanceFailAction,
  getLeaseDepositBalanceAction,
  removeWatcherFromLeaseAction,
  addWatcherToLeaseAction,
  watcherActionFailAction,
  watcherActionSuccessAction,
} = leasesSlice.actions;

export const useLeases = () => useSelector((state: RootState) => state.leases);
