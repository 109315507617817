import {
  DetailedAnalysisRow,
  ReduxExpense,
  ReduxExpensePayment,
  ReduxInvoice,
  ReduxPurchaseOrder,
} from '../../redux/expenseData';
import { randomId } from '@mui/x-data-grid-generator';
import { ExpensePaymentExpensePaymentStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

//method to determine which payments are attached to a PO via an invoice and which are not
const handleAttachedPayments = (p: ReduxExpensePayment[], i: ReduxInvoice[], po: ReduxPurchaseOrder[]): Set<string> => {
  const attachedPaymentNumbers = new Set<string>();
  po.forEach((x) => {
    const attachedInvoices = i.filter((invoice) => invoice.associatedPurchaseOrder === x.number);
    const attachedPayments = p.filter((payment) =>
      attachedInvoices.some((invoice) => invoice.number === payment.associatedInvoice),
    );
    attachedPayments.forEach((y) => {
      y.number && attachedPaymentNumbers.add(y.number);
    });
  });

  return attachedPaymentNumbers;
};

export const useExpenseAnalysis = (expense?: ReduxExpense) => {
  const totalCostEstimates = expense?.estimates?.reduce((a, b) => a + (b.amount ?? 0), 0) ?? 0;
  const totalCostPurchaseOrders = expense?.purchaseOrders?.reduce((a, b) => a + (b.totalAmount ?? 0), 0) ?? 0;
  const payments = expense?.payments?.filter((p) => p.status !== ExpensePaymentExpensePaymentStatus.Archived);
  const totalCostPayments = payments?.reduce((a, b) => a + (b.amount ?? 0), 0) ?? 0;
  const attachedPaymentNos = handleAttachedPayments(
    payments ?? [],
    expense?.invoices ?? [],
    expense?.purchaseOrders ?? [],
  );
  const attachedPayments = payments?.filter((x) => x.number && attachedPaymentNos.has(x.number));
  const totalCostWithoutUnattachedPayments = attachedPayments?.reduce((a, b) => a + (b.amount ?? 0), 0) ?? 0;
  const costToCompleteEstimated = totalCostEstimates - totalCostPayments;
  const costToCompletePOs = (totalCostPurchaseOrders ?? 0) - totalCostWithoutUnattachedPayments;
  const unattachedPayments = payments?.filter((x) => x.number && !attachedPaymentNos.has(x.number));
  const unestimatedExpenses = unattachedPayments?.reduce((a, b) => a + (b.amount ?? 0), 0) ?? 0;
  const totalEstimate = totalCostPurchaseOrders + (unattachedPayments?.reduce((a, b) => a + (b.amount ?? 0), 0) ?? 0);
  const costToComplete = totalEstimate - totalCostPayments;
  const handleFormatDetailedAnalysis = (expense?: ReduxExpense): DetailedAnalysisRow[] => {
    const rows: DetailedAnalysisRow[] = [];
    //total of pos and unattached payments
    rows.push({
      id: randomId(),
      title: 'Total Estimate',
      amount: totalEstimate,
    });
    const invoices = expense?.invoices ?? [];
    const pos = expense?.purchaseOrders ?? [];
    pos.forEach((po) => {
      //push in PO row
      rows.push({
        id: randomId(),
        amount: po.totalAmount,
        name: po.name,
        poChangeOrders:
          po.changeOrders?.map((a) => {
            return { id: randomId(), amount: a.amount, description: a.note };
          }) ?? undefined,
      });
    });
    payments?.forEach((payment) => {
      //push in unattached payments
      if (payment.number && !attachedPaymentNos.has(payment.number)) {
        rows.push({
          id: randomId(),
          name: `Unassociated Expense : ${payment.name}`,
          amount: payment.amount,
          payee: payment.vendor?.name,
        });
      }
    });
    //total of all payments
    rows.push({
      id: randomId(),
      title: 'Cost to Date',
      amount: totalCostPayments,
    });
    payments?.forEach((p) => {
      //push attached payments
      if (p.number && attachedPaymentNos.has(p.number)) {
        const invoice = invoices.find((i) => i.number === p.associatedInvoice);
        const po = pos.find((po) => po.number === invoice?.associatedPurchaseOrder);
        rows.push({
          id: randomId(),
          amount: p.amount,
          payee: p.vendor?.name,
          name: po?.name ? `Payment against ${po.name}: ${p.name}` : p.name,
        });
      }
    });
    payments?.forEach((p) => {
      //push unattached payments
      if (p.number && !attachedPaymentNos.has(p.number)) {
        rows.push({
          id: randomId(),
          amount: p.amount,
          payee: p.vendor?.name,
          name: `Unassociated Expense: ${p.name}`,
        });
      }
    });
    //total of pos + unattached payments MINUS total of all payments
    rows.push({
      id: randomId(),
      title: 'Cost to Complete',
      amount: costToComplete,
    });
    return rows;
  };

  return {
    costToComplete,
    totalEstimate,
    totalCostEstimates,
    totalCostPurchaseOrders,
    totalCostPayments,
    totalCostWithoutUnattachedPayments,
    costToCompleteEstimated,
    costToCompletePOs,
    unestimatedExpenses,
    handleFormatDetailedAnalysis,
  };
};
