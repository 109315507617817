import React, { useEffect, useState } from 'react';
import { StripedDataGrid } from '../../../../../_shared/datagrids/StripedDataGrid';
import { GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid-premium';
import { randomId } from '@mui/x-data-grid-generator';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { tryFormatDate } from '../../../../../_shared/utils/TryFormatDate';
import { ReduxPerson } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

type Props = {
  applicantChildren: ReduxPerson[];
  onRemoveChild: (updatedChildrenInApartment: ReduxPerson[]) => void;
};

type tableChild = ReduxPerson & { id?: string };

export const ChildGrid = ({ applicantChildren, onRemoveChild }: Props) => {
  const [tableChildren, setTableChildren] = useState<tableChild[]>([]);

  useEffect(() => {
    const newChildren = applicantChildren.map((child) => {
      return { ...child, id: randomId() };
    });
    setTableChildren(newChildren);
  }, [applicantChildren]);

  const deleteChild = (id: GridRowId) => () => {
    const updatedChildren = tableChildren.filter((child) => child.id !== id);
    onRemoveChild(updatedChildren);
    setTableChildren(updatedChildren);
  };

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: false,
      flex: 0.75,
      editable: false,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: false,
      flex: 0.75,
      editable: false,
    },
    {
      field: 'birthday',
      headerName: 'Birthday',
      type: 'date',
      flex: 0.75,
      editable: false,

      valueFormatter: (value?: string) => {
        return value ? tryFormatDate(value) : '';
      },
    },
    {
      field: 'gender',
      headerName: 'Gender',
      sortable: false,
      flex: 0.75,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      type: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title={'Remove Child'}>
                <DeleteIcon />
              </Tooltip>
            }
            key="delete"
            label="Remove Child"
            onClick={deleteChild(params.row.id)}
            color="inherit"
            showInMenu={undefined}
          />,
        ];
      },
    },
  ];

  return <StripedDataGrid rows={tableChildren} columns={columns} disableRowGrouping hideFooter></StripedDataGrid>;
};
