import React, { FC } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useDispatch } from 'react-redux';
import {
  cancelConfirmDialogAction,
  okConfirmDialogAction,
  useGlobal,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';
import Typography from '@mui/material/Typography';

export const ConfirmDialog: FC = () => {
  const global = useGlobal();
  const dispatch = useDispatch();

  const handleContinue = () => {
    dispatch(cancelConfirmDialogAction());
  };

  const handleConfirmCancel = () => {
    dispatch(okConfirmDialogAction());
  };

  const breakTextAtNewLines = (text?: string) => {
    return text?.split('\n\n').map((paragraph, index) => (
      <Typography key={index} paragraph>
        {paragraph}
      </Typography>
    ));
  };

  return (
    <div>
      <DialogLayout
        title={global.alert.title ?? 'Alert'}
        open={global.alert.isVisible}
        onClose={handleContinue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText sx={{ mt: '1rem' }} id="alert-dialog-description">
            {breakTextAtNewLines(global.alert.message)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleContinue}>{global.alert.cancelText}</Button>
          <Button onClick={handleConfirmCancel} autoFocus variant={'contained'}>
            {global.alert.okText}
          </Button>
        </DialogActions>
      </DialogLayout>
    </div>
  );
};
