import React, { useEffect, useState } from 'react';
import { IPet } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { StripedDataGrid } from '../../../../../_shared/datagrids/StripedDataGrid';
import { GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid-premium';
import { randomId } from '@mui/x-data-grid-generator';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  pets: IPet[];
  onRemovePet: (updatedPets: IPet[]) => void;
};

type tablePet = IPet & { id: string };

export const PetsGrid = ({ pets, onRemovePet }: Props) => {
  const [tablePets, setTablePets] = useState<tablePet[]>([]);

  useEffect(() => {
    const newPets = pets.map((pet) => {
      return { ...pet, id: randomId() };
    });
    setTablePets(newPets);
  }, [pets]);

  const deletePet = (id: GridRowId) => () => {
    const updatedPets = tablePets.filter((pet) => pet.id !== id);
    onRemovePet(updatedPets);
    setTablePets(updatedPets);
  };

  const columns: GridColDef[] = [
    {
      field: 'type',
      headerName: 'Type/Breed',
      sortable: false,
      flex: 0.75,
      editable: false,
    },
    {
      field: 'age',
      headerName: 'Age',
      sortable: false,
      flex: 0.75,
      editable: false,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      sortable: false,
      flex: 0.75,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      type: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title={'Remove Pet'}>
                <DeleteIcon />
              </Tooltip>
            }
            key="delete"
            label="Remove Pet"
            onClick={deletePet(params.row.id)}
            color="inherit"
            showInMenu={undefined}
          />,
        ];
      },
    },
  ];

  return <StripedDataGrid rows={tablePets} columns={columns} disableRowGrouping hideFooter></StripedDataGrid>;
};
