import React, { CSSProperties, useState } from 'react';
import { ExpandableCard } from '../../../_shared/styledComponents/ExpandableCard';
import Box from '@mui/material/Box';
import { ImageViewer } from '../../../_shared/documents/components/ImageViewer';
import { useTheme } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { addOrUpdateUserProfilePictureAction, useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { FileUploadButton } from '../../../_shared/buttons/FileUploadButton';
import { useDispatch } from 'react-redux';

export const ProfilePicture = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { currentUser } = useUser();
  const profilePic = currentUser.value?.profilePicture;
  const [openImageViewer, setOpenImageViewer] = useState(false);

  const handleUploadProfilePic = (file: File) => {
    dispatch(addOrUpdateUserProfilePictureAction({ file }));
  };

  return (
    <ExpandableCard title={'Profile Picture'} subtitle={'View/Upload Profile Picture'} focus={true}>
      <CardContent>
        {profilePic && (
          <Box style={styles.container}>
            <Box
              border={'.2rem solid'}
              borderColor={theme.palette.primary.main}
              sx={styles.imageContainer}
              onClick={() => setOpenImageViewer(true)}
              style={{ backgroundImage: `url(${profilePic.uri})` } as CSSProperties}
            ></Box>
          </Box>
        )}
      </CardContent>
      <CardActions sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
        <FileUploadButton onUpload={handleUploadProfilePic} loading={currentUser.submitting}>
          {profilePic ? 'Upload New Photo' : 'Upload Photo'}
        </FileUploadButton>
      </CardActions>
      {profilePic ? (
        <ImageViewer documents={[profilePic]} open={openImageViewer} onClose={() => setOpenImageViewer(false)} />
      ) : (
        <></>
      )}
    </ExpandableCard>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  imageContainer: {
    width: 300,
    height: 300,
    borderRadius: '50%',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 1,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    cursor: 'zoom-in',
  },
};
