import React, { FC } from 'react';
import { useSopTasks } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SingleDocument } from '../../../../../../_shared/documents/components/SingleDocument';

type AutomationDetailsReadonlyProps = {
  onHideAutomationDetails: () => void;
};

export const SopTaskDetailsReadonly: FC<AutomationDetailsReadonlyProps> = ({ onHideAutomationDetails }) => {
  const { selectedSopTask } = useSopTasks();
  return (
    <>
      <Grid marginTop={1} container spacing={2}>
        {!selectedSopTask.value && <LinearProgress />}
        {selectedSopTask.value && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                fullWidth
                sx={{ margin: 0 }}
                variant={'outlined'}
                label={'SOP Task Name'}
                value={selectedSopTask.value.name}
                inputProps={{
                  readonly: true,
                }}
              />
            </Grid>
            {selectedSopTask.value.category?.name && (
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  fullWidth
                  sx={{ margin: 0 }}
                  variant={'outlined'}
                  label={'Category'}
                  value={selectedSopTask.value.category?.name}
                  inputProps={{
                    readonly: true,
                  }}
                />
              </Grid>
            )}
            {selectedSopTask.value.description && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  variant={'outlined'}
                  label={'Description'}
                  value={selectedSopTask.value.description}
                  inputProps={{
                    readonly: true,
                  }}
                />
              </Grid>
            )}
            {selectedSopTask.value.defaultAssignee && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  variant={'outlined'}
                  label={'Default Assignee'}
                  value={
                    selectedSopTask.value.defaultAssignee.firstName +
                    ' ' +
                    selectedSopTask.value.defaultAssignee.lastName
                  }
                  inputProps={{
                    readonly: true,
                  }}
                />
              </Grid>
            )}
            {selectedSopTask.value.documents && selectedSopTask.value.documents.length > 0 && (
              <Grid item xs={12}>
                <List>
                  <Typography>Documents:</Typography>
                  {selectedSopTask.value.documents.map((item) => (
                    <ListItem key={item.id}>
                      <SingleDocument value={item} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
            {selectedSopTask.value.checklist && selectedSopTask.value.checklist.length > 0 && (
              <Grid item xs={12}>
                <List>
                  <Typography>Checklist Items:</Typography>
                  {selectedSopTask.value.checklist.map((item) => (
                    <ListItem key={item.id}>{item.name}</ListItem>
                  ))}
                </List>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <DialogActions>
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
          <Button onClick={onHideAutomationDetails}>Back to SOP Task List</Button>
        </Box>
      </DialogActions>
    </>
  );
};
