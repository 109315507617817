import React, { FC, Ref } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { TaskStatusMap } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

type TaskStatusSelectProps = {
  ref?: Ref<any>;
  size?: 'small' | 'medium' | undefined;
  value: string;
  onChange: (e: SelectChangeEvent) => void;
};

export const TaskStatusSelect: FC<TaskStatusSelectProps> = ({ ref, value, onChange, size }) => {
  return (
    <FormControl fullWidth size={size}>
      {!ref && <InputLabel id="task-status">Task Status</InputLabel>}
      <Select
        id="task-status"
        labelId="task-status-label"
        label="Task Status"
        aria-label="task-status"
        value={value}
        onChange={(e: SelectChangeEvent) => onChange(e)}
        ref={ref}
      >
        {Array.from(TaskStatusMap).map(([status, label]) => (
          <MenuItem key={status} dense value={status}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
