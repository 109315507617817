export function colorUtils(count: number) {
  const colors = [];
  for (let i = 0; i < count; i++) {
    // Calculate the hue that varies from 0 to 360 degrees across the color spectrum
    const hue = (i / count) * 360;
    // Set saturation to 100% and lightness to 50% to get vivid colors
    colors.push(hslToHex(hue, 100, 50));
  }
  return colors;
}

function hslToHex(h: number, s: number, l: number) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // Convert to Hex and format
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const getBrightness = ({ r, g, b }: { r: number; g: number; b: number }) => {
  return r * 0.299 + g * 0.587 + b * 0.114;
};

export const getContrastColor = (hex: string) => {
  const rgb = hexToRgb(hex);
  if (!rgb) return '#000';
  const brightness = getBrightness(rgb);
  return brightness < 128 ? '#FFF' : '#000';
};

export function generateColors(count: number) {
  const colors = [];
  for (let i = 0; i < count; i++) {
    const hue = (i / count) * 360;
    const saturation = 50 + (i / count) * 50; // Varies from 50% to 100%
    const lightness = 30 + (i / count) * 40; // Varies from 30% to 70%
    colors.push(hslToHex(hue, saturation, lightness));
  }
  return colors;
}
