import Box from '@mui/material/Box';
import { styled } from '@mui/system';

export const HighlighterBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<{ highlight: boolean }>(({ highlight }) => ({
  border: highlight ? '1px solid rgba(81, 203, 238, 1)' : 'none',
  boxShadow: highlight ? '0 0 5px rgba(81, 203, 238, 1)' : 'none',
  padding: '.1rem .1rem .1rem .1rem',
  webkitTransition: 'all 0.30s ease-in-out',
  mozTransition: 'all 0.30s ease-in-out',
  msTransition: 'all 0.30s ease-in-out',
  OTransition: 'all 0.30s ease-in-out',
}));
