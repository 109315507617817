import React, { ChangeEvent, FormEvent, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import {
  IContactInfo,
  IPostalAddress,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { updateManagerAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import { PhoneTextField } from '../../../../../_shared/texfields/PhoneTextField';
import Button from '@mui/material/Button';
import { ReduxRentalProperty } from '@monkeyjump-labs/cam-fe-shared/dist/types/propertyTypes';

type Props = {
  property: ReduxRentalProperty;
};

export const SiteManagerInfo = ({ property }: Props) => {
  const dispatch = useDispatch();
  const [dirty, setDirty] = useState<boolean>(false);
  const [updatedManagerContact, setUpdatedManagerContact] = useState<IContactInfo>({
    postalAddress: {
      streetAddress1: property.details?.siteManagerContactInfo?.postalAddress?.streetAddress1,
      streetAddress2: property.details?.siteManagerContactInfo?.postalAddress?.streetAddress2,
      city: property.details?.siteManagerContactInfo?.postalAddress?.city,
      state: property.details?.siteManagerContactInfo?.postalAddress?.state,
      zip: property.details?.siteManagerContactInfo?.postalAddress?.zip,
    },
    email: property.details?.siteManagerContactInfo?.email,
    phone: property.details?.siteManagerContactInfo?.phone,
    website: property.details?.siteManagerContactInfo?.website,
  });
  const [updatedName, setUpdatedName] = useState(property.details?.siteManagerName);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (property.id && updatedManagerContact && updatedName) {
      dispatch(
        updateManagerAction({
          propertyId: property.id,
          details: { siteManagerName: updatedName, siteManagerContactInfo: updatedManagerContact },
        }),
      );
      setDirty(false);
    } else return;
  };

  const handlePostalAddressChange = (value: string, key: keyof IPostalAddress) => {
    setUpdatedManagerContact({
      ...updatedManagerContact,
      postalAddress: { ...updatedManagerContact.postalAddress, [key]: value },
    });
  };

  const handleContactInfoChange = (value: string, key: keyof IContactInfo) => {
    setUpdatedManagerContact({ ...updatedManagerContact, [key]: value });
    setDirty(true);
  };

  const handleSiteManagerChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUpdatedName(e.target.value);
    setDirty(true);
  };

  if (property && property.details && property.details.siteManagerName && property.details.siteManagerContactInfo) {
    return (
      <StyledInfoBox label={'Site Manager'}>
        <form onSubmit={onSubmit} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="siteManagerName"
                label="Site Manager Name"
                type="text"
                defaultValue={property.details.siteManagerName}
                margin="dense"
                variant="standard"
                fullWidth
                onChange={handleSiteManagerChange}
              />
            </Grid>
            <Grid item xs={6}>
              <PhoneTextField
                id="phone"
                label="Phone Number"
                type="text"
                defaultValue={property.details.siteManagerContactInfo.phone}
                margin="dense"
                variant="standard"
                fullWidth
                onChange={(e) => {
                  handleContactInfoChange(e.target.value, 'phone');
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="email"
                label="Email"
                type="email"
                defaultValue={property.details.siteManagerContactInfo.email}
                margin="dense"
                variant="standard"
                fullWidth
                onChange={(e) => handleContactInfoChange(e.target.value, 'email')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="website"
                label="Website"
                type="text"
                defaultValue={property.details.siteManagerContactInfo.website}
                margin="dense"
                variant="standard"
                fullWidth
                onChange={(e) => handleContactInfoChange(e.target.value, 'website')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="streetAddress1"
                label="Street Address 1"
                type="text"
                defaultValue={property.details.siteManagerContactInfo?.postalAddress?.streetAddress1}
                margin="dense"
                variant="standard"
                fullWidth
                onChange={(e) => {
                  handlePostalAddressChange(e.target.value, 'streetAddress1');
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="streetAddress2"
                label="Street Address 2"
                type="text"
                defaultValue={property.details.siteManagerContactInfo?.postalAddress?.streetAddress2}
                margin="dense"
                variant="standard"
                fullWidth
                onChange={(e) => {
                  handlePostalAddressChange(e.target.value, 'streetAddress2');
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="city"
                label="City"
                type="text"
                defaultValue={property.details.siteManagerContactInfo?.postalAddress?.city}
                margin="dense"
                variant="standard"
                fullWidth
                onChange={(e) => {
                  handlePostalAddressChange(e.target.value, 'city');
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="state"
                label="State"
                type="text"
                defaultValue={property.details.siteManagerContactInfo?.postalAddress?.state}
                margin="dense"
                variant="standard"
                fullWidth
                onChange={(e) => {
                  handlePostalAddressChange(e.target.value, 'state');
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="zip"
                label="Zip Code"
                type="number"
                defaultValue={property.details.siteManagerContactInfo?.postalAddress?.zip}
                margin="dense"
                variant="standard"
                fullWidth
                onChange={(e) => {
                  handlePostalAddressChange(e.target.value, 'zip');
                }}
              />
            </Grid>
            <Grid item container xs={4} justifyContent="right">
              <Button type="submit" variant="contained" disabled={!dirty} sx={{ my: '1rem' }}>
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </StyledInfoBox>
    );
  } else return <></>;
};
