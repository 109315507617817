import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import { AssociationListItem } from '../../../datagrids/AssociationListItem';
import List from '@mui/material/List';
import { useAssociations } from '../../../datagrids/useAssociations';
import {
  AssociationType,
  ICamAssociation,
  ListScheduledTaskAssociationsHandlerResponse,
  ListTaskAssociationsHandlerResponse,
  ListTaskConfigurationAssociationsHandlerResponse,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Typography from '@mui/material/Typography';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import LinearProgress from '@mui/material/LinearProgress';

type TaskAssociationListProps = {
  id?: string;
  associationType: AssociationType.Task | AssociationType.ScheduledTask | AssociationType.TaskConfiguration;
};

export const TaskAssociationList: FC<TaskAssociationListProps> = ({ id, associationType }) => {
  const dispatch = useDispatch();
  const { handleListItemClick } = useAssociations();
  const { selectedContext } = useAssets();
  const [associations, setAssociations] = useState<ICamAssociation[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        if (!id) return;
        switch (associationType) {
          case AssociationType.Task:
            const taskResponse: ListTaskAssociationsHandlerResponse =
              await ApiClientSingleton.getInstance().tasks_ListTaskAssociations(id, false);
            if (taskResponse) setAssociations(taskResponse.toJSON().taskAssociations ?? []);
            setLoading(false);

            break;
          case AssociationType.ScheduledTask:
            const scheduledTaskResponse: ListScheduledTaskAssociationsHandlerResponse =
              await ApiClientSingleton.getInstance().tasks_ListScheduledTaskAssociations(id, false);
            if (scheduledTaskResponse) setAssociations(scheduledTaskResponse.toJSON().taskAssociations ?? []);
            setLoading(false);

            break;
          case AssociationType.TaskConfiguration:
            if (!selectedContext.propertyId) return;
            const sopTaskResponse: ListTaskConfigurationAssociationsHandlerResponse =
              await ApiClientSingleton.getInstance().properties_ListTaskConfigurationAssociations(
                selectedContext.propertyId,
                id,
                false,
              );
            if (sopTaskResponse) setAssociations(sopTaskResponse.toJSON().taskAssociations ?? []);
            setLoading(false);
            break;
        }
      } catch {
        setLoading(false);
        dispatch(showToastMessageAction({ message: 'Error fetching task associations', severity: 'error' }));
      }
    })();
  }, [id, associationType]);

  return (
    <StyledInfoBox label={'Associations'}>
      {loading ? (
        <LinearProgress />
      ) : associations.length > 0 ? (
        <List>
          {associations.map((a) => (
            <AssociationListItem key={a.associatedId} a={a} onListItemSelect={() => handleListItemClick(a, false)} />
          ))}
        </List>
      ) : (
        <Typography>No Associations</Typography>
      )}
    </StyledInfoBox>
  );
};
