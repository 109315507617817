import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import { showErrorAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { BankAccountType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  clearPlaidLinkTokenAction,
  createPropertyBankAccountAction,
  createTenantBankAccountAction,
  getPlaidLinkTokenAction,
  useBankAccounts,
} from '../../../../../_shared/bankAccounts/redux/bankAccountsSlice';

interface AddBankAccountButtonProps {
  bankAccountType: BankAccountType;
  userOrPropertyId: string;
}

export const AddBankAccountButton = ({ bankAccountType, userOrPropertyId }: AddBankAccountButtonProps) => {
  const { plaidLinkToken } = useBankAccounts();

  const dispatch = useDispatch();
  const [getToken, setToken] = useState('');

  useEffect(() => {
    if (plaidLinkToken.loaded && plaidLinkToken.value) {
      setToken(plaidLinkToken.value);
    }
  }, [plaidLinkToken.loaded]);

  const config: PlaidLinkOptions = {
    onSuccess: (publicToken, metadata) => {
      if (bankAccountType == BankAccountType.Business)
        dispatch(
          createPropertyBankAccountAction({
            publicToken,
            propertyId: userOrPropertyId,
            accountId: metadata.accounts[0].id,
            mask: metadata.accounts[0].mask,
            accountName: metadata.accounts[0].name,
            type: metadata.accounts[0].type,
            subType: metadata.accounts[0].subtype,
            verificationStatus: metadata.accounts[0].verification_status,
          }),
        );
      else
        dispatch(
          createTenantBankAccountAction({
            publicToken,
            userId: userOrPropertyId,
            accountId: metadata.accounts[0].id,
            mask: metadata.accounts[0].mask,
            accountName: metadata.accounts[0].name,
            type: metadata.accounts[0].type,
            subType: metadata.accounts[0].subtype,
            verificationStatus: metadata.accounts[0].verification_status,
          }),
        );
    },

    onExit: (err) => {
      if (err != null)
        dispatch(
          showErrorAction({
            error: err?.display_message,
            fallbackMessage: "Couldn't create Plaid processor token",
          }),
        );
      else dispatch(clearPlaidLinkTokenAction());
    },

    token: getToken,
  };

  const plaidLink = usePlaidLink(config);

  useEffect(() => {
    if (plaidLink.ready && getToken && getToken !== '') plaidLink.open();
  }, [getToken, plaidLink.open, plaidLink.ready]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Button
          variant={'outlined'}
          startIcon={<Add />}
          onClick={() => dispatch(getPlaidLinkTokenAction({ bankAccountType, userOrPropertyId }))}
        >
          Add Account
        </Button>
      </Box>
    </>
  );
};
