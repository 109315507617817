import * as React from 'react';
import { FC, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Box from '@mui/material/Box';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useSearchHook } from '../useSearchHook';
import { getKeyByValue } from '../../utils/getMapKeyByValue';

type SearchTypeButtonGroupProps = {
  options: string[];
  onChangeAssociationType: (a: AssociationType | undefined) => void;
};

export const SearchTypeButtonGroup: FC<SearchTypeButtonGroupProps> = ({ options, onChangeAssociationType }) => {
  const { searchButtonGroupStyles, searchButtonMap, bigButtonInGroupStyles, littleButtonInGroupStyles } =
    useSearchHook();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number, option: string) => {
    setSelectedIndex(index);
    setOpen(false);
    const aType = getKeyByValue(searchButtonMap, option);
    onChangeAssociationType(aType);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box minWidth={'12rem'}>
      <ButtonGroup
        sx={searchButtonGroupStyles}
        ref={anchorRef}
        variant={'outlined'}
        size={'large'}
        aria-label="search type split button"
      >
        <Button sx={bigButtonInGroupStyles}>{options[selectedIndex]}</Button>
        <Button
          size="small"
          aria-controls={open ? 'search-type-split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select search type"
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={littleButtonInGroupStyles}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 999999,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="search-type-split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index, option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
