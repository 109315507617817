import React, { FC, useEffect } from 'react';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import { DetailAssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import {
  getDetailTreeAction,
  updateDetailParentIdAction,
  useDetails,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/details/detailsSlice';
import DialogContent from '@mui/material/DialogContent';
import { RichTreeViewPro, RichTreeViewProProps } from '@mui/x-tree-view-pro/RichTreeViewPro';
import { TreeItem2 } from '@mui/x-tree-view/TreeItem2';
import Box from '@mui/material/Box';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useTreeViewApiRef } from '@mui/x-tree-view-pro';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';

type ReorderDetailsDialogProps = {
  open: boolean;
  onClose: () => void;
  associationType: DetailAssociationType;
  associatedId: string;
};

export const ReorderDetailsDialog: FC<ReorderDetailsDialogProps> = ({
  open,
  onClose,
  associationType,
  associatedId,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { detailTree } = useDetails();
  const [localDetailTree, setLocalDetailTree] = React.useState(detailTree.value);
  const [lastReorder, setLastReorder] = React.useState<
    Parameters<NonNullable<RichTreeViewProProps<any, any>['onItemPositionChange']>>[0] | null
  >(null);
  const apiRefTreeView = useTreeViewApiRef();

  useEffect(() => {
    if (open) {
      dispatch(getDetailTreeAction({ associatedId, associationType }));
    }
  }, [open]);

  useEffect(() => {
    setLocalDetailTree(detailTree.value);
  }, [detailTree.value]);

  useEffect(() => {
    if (lastReorder) {
      const newParentId: string | null = lastReorder.newPosition.parentId;
      if (lastReorder.newPosition.parentId === lastReorder.oldPosition.parentId) {
        dispatch(showToastMessageAction({ message: 'Same parent selected; no changes made', severity: 'info' }));
        return;
      }
      dispatch(updateDetailParentIdAction({ detailId: lastReorder.itemId, parentId: newParentId }));
      const newTree = apiRefTreeView.current!.getItemTree();
      setLocalDetailTree(newTree);
    }
  }, [lastReorder]);

  return (
    <DialogLayout maxWidth={'sm'} fullWidth title={'Reorganize Details'} onClose={onClose} open={open}>
      <DialogContent>
        <Box
          sx={{
            padding: 5,
            marginTop: 3,
            minHeight: 500,
            border: `.2rem solid ${theme.palette.primary.main}`,
            borderRadius: theme.shape.borderRadius,
          }}
        >
          <RichTreeViewPro
            apiRef={apiRefTreeView}
            items={localDetailTree ?? []}
            slots={{ item: TreeItem2 }}
            getItemLabel={(item) => item.name ?? ''}
            experimentalFeatures={{ indentationAtItemLevel: true, itemsReordering: true }}
            itemsReordering
            onItemPositionChange={(params) => setLastReorder(params)}
            isItemDisabled={() => detailTree.submitting}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ mb: 1 }}>
        <Button variant={'outlined'} onClick={onClose}>
          Done
        </Button>
      </DialogActions>
    </DialogLayout>
  );
};
