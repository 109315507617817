import {
  Filter,
  initInteractable,
  initPageableCollection,
  initSubmittable,
  Interactable,
  PageableCollection,
  SortDirection,
  Submittable,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import {
  ApplicationStatus,
  ApplicationType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  Cosigner,
  CreateApplicationData,
  IAllApplicationType,
  OtherAdults,
  ReduxApplication,
} from './applicationTypes';

export interface ApplicationState {
  allApplications: PageableCollection<ReduxApplication>;
  selectedApplication: Interactable<ReduxApplication | undefined>;
  otherAdultApplications: OtherAdults | undefined;
  cosignerApplications: Cosigner | undefined;
  includeClosedStatuses: boolean;
  emailSubmitting: Submittable<never>;
}

const initialState: ApplicationState = {
  allApplications: initPageableCollection<ReduxApplication>([]),
  selectedApplication: initInteractable(undefined),
  otherAdultApplications: undefined,
  cosignerApplications: undefined,
  includeClosedStatuses: false,
  emailSubmitting: initSubmittable(),
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const applicationSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    getApplicationsByPropertyIdAction: (
      state,
      action: PayloadAction<{
        rentalPropertyId: string | undefined;
        applicationType: ApplicationType;
        page?: number;
        pageSize?: number;
      }>,
    ) => {
      state.allApplications.loading = true;
      state.allApplications.loaded = false;
      if (action.payload.page) {
        state.allApplications.page = action.payload.page;
      }
      if (action.payload.pageSize) {
        state.allApplications.pageSize = action.payload.pageSize;
      }
    },
    getApplicationsByPropertyIdFailAction: (state) => {
      state.allApplications.loading = false;
      state.allApplications.loaded = false;
      state.allApplications.value = undefined;
      state.allApplications.totalCount = undefined;
    },
    getApplicationsByPropertyIdSuccessAction: (
      state,
      action: PayloadAction<{ applications: ReduxApplication[]; totalCount: number }>,
    ) => {
      state.allApplications.loading = false;
      state.allApplications.loaded = true;
      state.allApplications.value = action.payload.applications;
      state.allApplications.totalCount = action.payload.totalCount;
    },
    getSingleApplicationByIdAction: (state, action: PayloadAction<string>) => {
      state.selectedApplication.loading = true;
      state.selectedApplication.loaded = false;
    },
    getSingleApplicationByIdFailAction: (state) => {
      state.selectedApplication.loading = false;
      state.selectedApplication.loaded = false;
      state.selectedApplication.value = undefined;
    },
    getSingleApplicationByIdSuccessAction: (state, action: PayloadAction<ReduxApplication>) => {
      state.selectedApplication.loading = false;
      state.selectedApplication.loaded = true;
      state.selectedApplication.value = action.payload;
    },
    getOtherAdultApplicationsByParentIdAction: (
      state,
      action: PayloadAction<{ propertyId: string; parentApplicationId: string }>,
    ) => {
      state.allApplications.loading = true;
      state.allApplications.loaded = false;
    },
    getOtherAdultApplicationsByParentIdFailAction: (state) => {
      state.allApplications.loading = false;
      state.allApplications.loaded = false;
    },
    getOtherAdultApplicationsByParentIdSuccessAction: (
      state,
      action: PayloadAction<{ parentApplicationId: string; body: ReduxApplication[] }>,
    ) => {
      state.allApplications.loading = false;
      state.allApplications.loaded = true;
      state.otherAdultApplications = {
        ...state.otherAdultApplications,
        [action.payload.parentApplicationId]: action.payload.body,
      };
    },
    getCoSignerApplicationsByParentIdAction: (
      state,
      action: PayloadAction<{ propertyId: string; parentApplicationId: string }>,
    ) => {
      state.allApplications.loading = true;
      state.allApplications.loaded = false;
    },
    getCoSignerApplicationsByParentIdFailAction: (state) => {
      state.allApplications.loading = false;
      state.allApplications.loaded = false;
    },
    getCoSignerApplicationsByParentIdSuccessAction: (
      state,
      action: PayloadAction<{ parentApplicationId: string; body: ReduxApplication[] }>,
    ) => {
      state.allApplications.loading = false;
      state.allApplications.loaded = true;
      state.cosignerApplications = {
        ...state.cosignerApplications,
        [action.payload.parentApplicationId]: action.payload.body,
      };
    },
    toggleIncludeClosedStatusesAction: (
      state,
      action: PayloadAction<{
        includeClosedStatuses: boolean;
        rentalPropertyId: string;
        applicationType: ApplicationType;
      }>,
    ) => {
      state.includeClosedStatuses = action.payload.includeClosedStatuses;
    },
    setApplicantTableFiltersAction: (
      state,
      action: PayloadAction<{
        rentalPropertyId: string;
        applicationType: ApplicationType;
        filters: Filter<IAllApplicationType>[] | undefined;
      }>,
    ) => {
      state.allApplications.loading = true;
      state.allApplications.loaded = false;
      state.allApplications.filters = action.payload.filters;
    },
    setApplicantTableSortAction: (
      state,
      action: PayloadAction<{
        rentalPropertyId: string;
        applicationType: ApplicationType;
        sortDirection: SortDirection | undefined;
        sortBy: keyof ReduxApplication | undefined;
      }>,
    ) => {
      state.allApplications.sortBy = action.payload.sortBy;
      state.allApplications.sortDirection = action.payload.sortDirection;
    },
    clearApplicantTableFiltersAndSortAction: (state) => {
      state.allApplications.filters = undefined;
      state.allApplications.sortDirection = undefined;
      state.allApplications.sortBy = undefined;
    },
    setApplicantTableFiltersFailAction: (state) => {
      state.allApplications.loading = false;
      state.allApplications.loaded = false;
    },
    setApplicantTableFiltersSuccessAction: (state) => {
      state.allApplications.loading = false;
      state.allApplications.loaded = true;
    },
    createApplicationAction: (
      state,
      action: PayloadAction<{
        propertyId: string;
        applicationType: ApplicationType;
        body: CreateApplicationData;
        isWebView?: boolean;
      }>,
    ) => {
      state.allApplications.submitting = true;
      state.allApplications.submitted = false;
    },
    createApplicationSuccessAction: (state, action: PayloadAction<ReduxApplication>) => {
      state.allApplications.submitting = false;
      state.allApplications.submitted = true;
      state.allApplications.submittingValue = undefined;
    },
    createWebApplicationSuccessAction: (state) => {
      state.allApplications.submitting = false;
      state.allApplications.submitted = true;
    },
    createApplicationFailAction: (state) => {
      state.allApplications.submitting = false;
      state.allApplications.submitted = false;
    },
    resetApplicationSubmissionAction: (state) => {
      state.allApplications.submitting = false;
      state.allApplications.submitted = false;
    },
    updateApplicationAction: (
      state,
      action: PayloadAction<{ id: string; type: ApplicationType; body: ReduxApplication; propertyId?: string }>,
    ) => {
      state.allApplications.submitting = true;
      state.allApplications.submitted = false;
      state.allApplications.submittingValue = action.payload.body;
    },
    updateApplicationSuccessAction: (state) => {
      state.allApplications.submitting = false;
      state.allApplications.submitted = true;
      state.allApplications.submittingValue = undefined;
    },
    updateApplicationFailAction: (state) => {
      state.allApplications.submitting = false;
      state.allApplications.submitted = false;
    },
    updateApplicationStatusAction: (
      state,
      action: PayloadAction<{ id: string; applicationStatus: ApplicationStatus; rentalPropertyId: string }>,
    ) => {
      state.allApplications.submitting = true;
      state.allApplications.submitted = false;
      state.allApplications.submittingValue = { applicationStatus: action.payload.applicationStatus };
    },
    updateApplicationStatusSuccessAction: (state) => {
      state.allApplications.submitting = false;
      state.allApplications.submitted = true;
      state.allApplications.submittingValue = undefined;
    },
    updateApplicationStatusFailAction: (state) => {
      state.allApplications.submitting = false;
      state.allApplications.submitted = false;
    },
    updateSubmitApplicationAction: (state, action: PayloadAction<string>) => {
      state.selectedApplication.submitting = true;
    },
    updateSubmitApplicationSuccessAction: (state) => {
      state.selectedApplication.submitting = false;
      state.selectedApplication.submitted = true;
    },
    updateSubmitApplicationFailAction: (state) => {
      state.selectedApplication.submitting = false;
      state.selectedApplication.submitted = false;
    },
    addCommentToApplicationAction: (state, action: PayloadAction<{ id: string; comment: string }>) => {
      state.selectedApplication.submitting = true;
      state.selectedApplication.submitted = false;
    },
    addCommentToApplicationSuccessAction: (state) => {
      state.selectedApplication.submitting = false;
      state.selectedApplication.submitted = true;
    },
    addCommentToApplicationFailAction: (state) => {
      state.selectedApplication.submitting = false;
      state.selectedApplication.submitted = false;
    },
    uploadDocumentAction: (state, action: PayloadAction<{ applicationId: string; file: File }>) => {
      state.selectedApplication.submitting = true;
      state.selectedApplication.submitted = false;
    },
    successUploadedDocumentAction: (state) => {
      state.selectedApplication.submitting = false;
      state.selectedApplication.submitted = true;
    },
    failUploadedDocumentAction: (state) => {
      state.selectedApplication.submitting = false;
      state.selectedApplication.submitted = false;
    },
    removeDocumentAction: (state, action: PayloadAction<{ applicationId: string; documentId: string }>) => {
      state.selectedApplication.submitting = true;
      state.selectedApplication.submitted = false;
    },
    successRemoveDocumentAction: (state, action: PayloadAction<{ documentId: string }>) => {
      state.selectedApplication.submitting = false;
      state.selectedApplication.submitted = true;

      if (state.selectedApplication.value && state.selectedApplication.value.documents) {
        const documentIndex = state.selectedApplication.value.documents.findIndex(
          (d) => d.id === action.payload.documentId,
        );
        if (documentIndex !== -1) {
          state.selectedApplication.value.documents.splice(documentIndex, 1);
        }
      }
    },
    failRemoveDocumentAction: (state) => {
      state.selectedApplication.submitting = false;
      state.selectedApplication.submitted = false;
    },
    cancelRemoveDocumentAction: (state) => {
      state.selectedApplication.submitting = false;
      state.selectedApplication.submitted = false;
    },
    unsetSelectedApplication: (state) => {
      state.selectedApplication.value = undefined;
    },
    sendEmailAction: (state, payload: PayloadAction<{ rentalPropertyId: string; applicationId: string }>) => {
      state.emailSubmitting.submitting = true;
      state.emailSubmitting.submitted = false;
    },
    sendEmailSuccessAction: (state) => {
      state.emailSubmitting.submitting = false;
      state.emailSubmitting.submitted = true;
    },
    sendEmailFailAction: (state) => {
      state.emailSubmitting.submitting = false;
      state.emailSubmitting.submitted = false;
    },
    refreshApplicationsAction: () => {},
    addWatcherToApplicationAction: (state, action: PayloadAction<{ applicationId: string; userId: string }>) => {
      state.selectedApplication.submitting = true;
      state.selectedApplication.submitted = false;
    },
    removeWatcherFromApplicationAction: (state, action: PayloadAction<{ applicationId: string; userId: string }>) => {
      state.selectedApplication.submitting = true;
      state.selectedApplication.submitted = false;
    },
    applicationWatchSuccessAction: (state) => {
      state.selectedApplication.submitting = false;
      state.selectedApplication.submitted = true;
    },
    applicationWatchFailAction: (state) => {
      state.selectedApplication.submitting = false;
      state.selectedApplication.submitted = false;
    },
  },
});
/* eslint-enable @typescript-eslint/no-unused-vars */

export const {
  getApplicationsByPropertyIdAction,
  getApplicationsByPropertyIdFailAction,
  getApplicationsByPropertyIdSuccessAction,
  getSingleApplicationByIdAction,
  getSingleApplicationByIdSuccessAction,
  getSingleApplicationByIdFailAction,
  getOtherAdultApplicationsByParentIdAction,
  getOtherAdultApplicationsByParentIdFailAction,
  getOtherAdultApplicationsByParentIdSuccessAction,
  getCoSignerApplicationsByParentIdAction,
  getCoSignerApplicationsByParentIdFailAction,
  getCoSignerApplicationsByParentIdSuccessAction,
  toggleIncludeClosedStatusesAction,
  setApplicantTableFiltersAction,
  setApplicantTableFiltersSuccessAction,
  setApplicantTableFiltersFailAction,
  setApplicantTableSortAction,
  clearApplicantTableFiltersAndSortAction,
  createApplicationAction,
  createApplicationFailAction,
  createApplicationSuccessAction,
  createWebApplicationSuccessAction,
  resetApplicationSubmissionAction,
  updateApplicationAction,
  updateApplicationFailAction,
  updateApplicationSuccessAction,
  updateApplicationStatusAction,
  updateApplicationStatusFailAction,
  updateApplicationStatusSuccessAction,
  updateSubmitApplicationAction,
  updateSubmitApplicationSuccessAction,
  updateSubmitApplicationFailAction,
  addCommentToApplicationAction,
  addCommentToApplicationSuccessAction,
  addCommentToApplicationFailAction,
  uploadDocumentAction,
  successUploadedDocumentAction,
  failUploadedDocumentAction,
  removeDocumentAction,
  successRemoveDocumentAction,
  failRemoveDocumentAction,
  cancelRemoveDocumentAction,
  unsetSelectedApplication,
  sendEmailAction,
  sendEmailSuccessAction,
  sendEmailFailAction,
  refreshApplicationsAction,
  applicationWatchFailAction,
  applicationWatchSuccessAction,
  removeWatcherFromApplicationAction,
  addWatcherToApplicationAction,
} = applicationSlice.actions;

export const useApplications = () => useSelector((state: RootState) => state.applications);
