import React, { FC } from 'react';
import FormControl from '@mui/material/FormControl';
import { FormHelperText, Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { UnitStatusDto } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

const unselectableUnitStatuses = [UnitStatusDto.Occupied];

export const unitStatusMap = new Map([
  [UnitStatusDto.Occupied, 'Occupied'],
  [UnitStatusDto.NotRentReady, 'Not Rent Ready'],
  [UnitStatusDto.RentReady, 'Rent Ready'],
  [UnitStatusDto.UnderConstruction, 'Under Construction'],
  [UnitStatusDto.Unavailable, 'Unavailable'],
]);

type UnitStatusSelectProps = {
  onChange: (newValue: string) => void;
  value?: UnitStatusDto;
  fullWidth?: boolean;
  newUnit?: boolean;
};

export const UnitStatusSelect: FC<UnitStatusSelectProps> = ({ onChange, value, fullWidth, newUnit }) => {
  return (
    <FormControl fullWidth={fullWidth} sx={{ mt: '.5rem' }}>
      <InputLabel id={'unit-status'}>Status</InputLabel>
      <Select
        sx={{ px: '1rem' }}
        id="unit-status"
        aria-label="unit-status"
        label={'Status'}
        value={value?.toString() ?? ''}
        onChange={(e: SelectChangeEvent) => onChange(e.target.value)}
        variant={'standard'}
        inputProps={{
          readOnly: newUnit ? false : value === UnitStatusDto.Occupied ? true : false,
        }}
      >
        {value && unselectableUnitStatuses.find((status) => status === value) ? (
          <MenuItem key={value} dense value={value}>
            {unitStatusMap.get(value) ?? 'Unknown'}
          </MenuItem>
        ) : (
          Array.from(unitStatusMap).map(([status, label]) => {
            if (!unselectableUnitStatuses.find((s) => s == status)) {
              return (
                <MenuItem key={status} dense value={status}>
                  {label}
                </MenuItem>
              );
            }
          })
        )}
      </Select>
      {!newUnit && <FormHelperText>*Status can only be changed if unit is unoccupied</FormHelperText>}
    </FormControl>
  );
};
