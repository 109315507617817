import { all, put, takeLatest } from 'redux-saga/effects';
import {
  addGenericListingAction,
  addListingFailAction,
  addListingFromTemplateAction,
  addListingFromUnitsAction,
  addListingSuccessAction,
  addPhotoAction,
  addPhotoFailAction,
  addPhotoSuccessAction,
  deleteListingAction,
  deleteListingFailAction,
  deleteListingSuccessAction,
  getListingAction,
  getListingByUnitIdAction,
  getListingFailAction,
  getListingSuccessAction,
  getPropertyAdvertisingDetailsAction,
  getPropertyAdvertisingDetailsFailAction,
  getPropertyAdvertisingDetailsSuccessAction,
  listListingsAction,
  listListingsFailAction,
  listListingsSuccessAction,
  removePhotoAction,
  removePhotoFailAction,
  removePhotoSuccessAction,
  reorderPhotoAction,
  reorderPhotoFailAction,
  reorderPhotoSuccessAction,
  updateListingAction,
  updateListingFailAction,
  updateListingPromotionAction,
  updateListingPromotionFailAction,
  updateListingPromotionSuccessAction,
  updateListingStatusAction,
  updateListingStatusFailAction,
  updateListingStatusSuccessAction,
  updateListingSuccessAction,
} from './listingSlice';
import { showErrorAction, showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import {
  AddListingFromTemplateHandlerResponse,
  AddListingHandlerRequest,
  AddListingHandlerResponse,
  AddPhotoToListingHandlerResponse,
  GetListingByUnitIdHandlerResponse,
  GetPropertyAdvertisingHandlerResponse,
  IListing,
  IUnitInfo,
  Listing,
  ListingStatus,
  ListListingsHandlerRequest,
  ListListingsHandlerResponse,
  ReorderListingPhotosHandlerRequest,
  ListPublishedListingsHandlerRequest,
  ListPublishedListingsHandlerResponse,
  UpdateListingHandlerRequest,
  UpdateListingStatusHandlerRequest,
  UpdatePromotionHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { mapReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { mapReduxListing, ReduxListing } from './listingData';

function* listListings(
  action: PayloadAction<{ propertyId: string; parameters: { field: string; value: string }[]; isWebView?: boolean }>,
) {
  try {
    if (action.payload.isWebView) {
      const response: ListPublishedListingsHandlerResponse = yield apiCall(
        ApiClientSingleton.getInstance().listings_SearchPublished,
        action.payload.propertyId,
        new ListPublishedListingsHandlerRequest({
          query: {
            page: 0,
            pageSize: 100,
            parameters: action.payload.parameters,
          },
        }),
      );
      const listings: IListing[] = response.toJSON().listings;
      yield put(listListingsSuccessAction(listings.map(mapReduxListing)));
    } else {
      const response: ListListingsHandlerResponse = yield apiCall(
        ApiClientSingleton.getInstance().listings_Search,
        action.payload.propertyId,
        new ListListingsHandlerRequest({
          query: {
            page: 0,
            pageSize: 100,
            parameters: action.payload.parameters,
          },
        }),
      );
      const listings: IListing[] = response.toJSON().listings;
      yield put(listListingsSuccessAction(listings.map(mapReduxListing)));
    }
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to load listings' }));
    yield put(listListingsFailAction());
  }
}

function* getListing(action: PayloadAction<{ listingId: string; isWebView?: boolean }>) {
  try {
    if (action.payload.isWebView) {
      const response: Listing = yield apiCall(
        ApiClientSingleton.getInstance().listings_GetPublished,
        action.payload.listingId,
      );
      yield put(getListingSuccessAction(mapReduxListing(response.toJSON())));
    } else {
      const response: Listing = yield apiCall(ApiClientSingleton.getInstance().listings_Get, action.payload.listingId);
      yield put(getListingSuccessAction(response.toJSON()));
    }
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to get listing' }));
    yield put(getListingFailAction());
  }
}

function* getListingByUnitId(action: PayloadAction<string>) {
  try {
    const response: GetListingByUnitIdHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().listings_GetListingByUnitId,
      action.payload,
    );
    yield put(
      getListingSuccessAction(response.toJSON().listing ? mapReduxListing(response.toJSON().listing) : undefined),
    );
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to get listing' }));
    yield put(getListingFailAction());
  }
}

function* addListingFromUnit(action: PayloadAction<string>) {
  try {
    const response: AddListingHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().listings_AddListingFromUnit,
      action.payload,
    );
    yield put(addListingSuccessAction(mapReduxListing(response.toJSON().listing)));
    yield put(showToastMessageAction({ message: 'Listing added successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to add listing' }));
    yield put(addListingFailAction());
  }
}

function* addListingFromTemplate(action: PayloadAction<string>) {
  try {
    const response: AddListingFromTemplateHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().listings_AddListingFromTemplate,
      action.payload,
    );
    yield put(addListingSuccessAction(mapReduxListing(response.toJSON().listing)));
    yield put(showToastMessageAction({ message: 'Listing added successfully', severity: 'success' }));
    yield put(push(`advertise/listing/${response.toJSON().listing.id}/info`));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to add listing' }));
    yield put(addListingFailAction());
  }
}

function* addGenericListing(action: PayloadAction<{ buildingId: string; info: IUnitInfo }>) {
  try {
    const response: AddListingHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().listings_AddListing,
      action.payload.buildingId,
      new AddListingHandlerRequest({ info: action.payload.info }),
    );

    yield put(addListingSuccessAction(response.toJSON().listing));
    yield put(showToastMessageAction({ message: 'Listing added successfully', severity: 'success' }));
    yield put(push(`advertise/listing/${response.toJSON().listing.id}/info`));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to add listing' }));
    yield put(addListingFailAction());
  }
}

function* updateListing(action: PayloadAction<{ id: string; body: ReduxListing }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().listings_Update,
      action.payload.id,
      new UpdateListingHandlerRequest(action.payload.body),
    );
    yield put(updateListingSuccessAction(action.payload.body));
    yield put(showToastMessageAction({ message: 'Listing updated successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to update listing' }));
    yield put(updateListingFailAction());
  }
}

function* deleteListing(action: PayloadAction<string>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().listings_Delete, action.payload);
    yield put(showToastMessageAction({ message: 'Listing deleted successfully', severity: 'success' }));
    yield put(deleteListingSuccessAction(action.payload));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to delete listing' }));
    yield put(deleteListingFailAction());
  }
}

function* addPhoto(action: PayloadAction<{ id: string; photo: File }>) {
  try {
    const response: AddPhotoToListingHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().listings_AddPhoto,
      action.payload.id,
      {
        data: action.payload.photo,
        fileName: action.payload.photo.name,
      },
    );
    yield put(addPhotoSuccessAction({ id: action.payload.id, photo: mapReduxDocument(response.toJSON().document) }));
    yield put(showToastMessageAction({ message: 'Photo added successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to add photo' }));
    yield put(addPhotoFailAction());
  }
}

function* removePhoto(action: PayloadAction<{ id: string; photoId: string }>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().listings_RemovePhoto, action.payload.id, action.payload.photoId);
    yield put(showToastMessageAction({ message: 'Photo removed successfully', severity: 'success' }));
    yield put(removePhotoSuccessAction({ listingId: action.payload.id, photoId: action.payload.photoId }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to remove photo' }));
    yield put(removePhotoFailAction());
  }
}

function* reorderPhotos(action: PayloadAction<{ id: string; photoIds: string[] }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().listings_ReorderPhotos,
      action.payload.id,
      new ReorderListingPhotosHandlerRequest({ photoIds: action.payload.photoIds }),
    );
    yield put(showToastMessageAction({ message: 'Photos reordered successfully', severity: 'success' }));
    yield put(reorderPhotoSuccessAction({ id: action.payload.id, photoIds: action.payload.photoIds }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to reorder photos' }));
    yield put(reorderPhotoFailAction());
  }
}

function* getPropertyAdvertisingDetails(action: PayloadAction<string>) {
  try {
    const response: GetPropertyAdvertisingHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().properties_GetAdvertising,
      action.payload,
    );
    yield put(
      getPropertyAdvertisingDetailsSuccessAction({
        ...response.toJSON().propertyAdvertisingDto,
        logo: response.toJSON().propertyAdvertisingDto.logo
          ? mapReduxDocument(response.toJSON().propertyAdvertisingDto.logo)
          : undefined,
      }),
    );
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to get property advertising details' }));
    yield put(getPropertyAdvertisingDetailsFailAction());
  }
}

function* updateListingPromotion(action: PayloadAction<{ id: string; promote: boolean }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().listings_UpdatePromotion,
      action.payload.id,
      new UpdatePromotionHandlerRequest({
        isPromoted: action.payload.promote,
      }),
    );
    yield put(showToastMessageAction({ message: 'Listing promotion updated successfully', severity: 'success' }));
    yield put(updateListingPromotionSuccessAction(action.payload));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to update listing promotion' }));
    yield put(updateListingPromotionFailAction());
  }
}

function* updateListingStatus(action: PayloadAction<{ id: string; status: ListingStatus }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().listings_UpdateStatus,
      action.payload.id,
      new UpdateListingStatusHandlerRequest({
        status: action.payload.status,
      }),
    );
    yield put(showToastMessageAction({ message: 'Listing status updated successfully', severity: 'success' }));
    yield put(updateListingStatusSuccessAction(action.payload));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to update listing status' }));
    yield put(updateListingStatusFailAction());
  }
}

export function* listingSaga() {
  yield all([
    takeLatest(listListingsAction.type, listListings),
    takeLatest(getListingAction.type, getListing),
    takeLatest(getListingByUnitIdAction.type, getListingByUnitId),
    takeLatest(addListingFromUnitsAction.type, addListingFromUnit),
    takeLatest(addListingFromTemplateAction.type, addListingFromTemplate),
    takeLatest(addGenericListingAction.type, addGenericListing),
    takeLatest(updateListingAction.type, updateListing),
    takeLatest(deleteListingAction.type, deleteListing),
    takeLatest(addPhotoAction.type, addPhoto),
    takeLatest(removePhotoAction.type, removePhoto),
    takeLatest(reorderPhotoAction.type, reorderPhotos),
    takeLatest(getPropertyAdvertisingDetailsAction.type, getPropertyAdvertisingDetails),
    takeLatest(updateListingPromotionAction.type, updateListingPromotion),
    takeLatest(updateListingStatusAction.type, updateListingStatus),
  ]);
}
