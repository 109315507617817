import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  updateUnitAction,
  updateUnitStatusAction,
  useUnit,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/unitSlice';
import {
  IAddUnitHandlerRequest,
  IBuildingUnitDto,
  IUnitInfo,
  UnitStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { StyledInfoBox } from '../../../../_shared/styledComponents/StyledInfoBox';
import LoadingButton from '@mui/lab/LoadingButton';
import { UnitInformationForm } from '../../UnitInformationForm';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export const UnitInformation = () => {
  const dispatch = useDispatch();
  const { selectedUnit } = useUnit();
  const unit = selectedUnit.value;
  const [unitEdit, setUnitEdit] = useState<IBuildingUnitDto | undefined>(unit);
  const [dirty, setDirty] = useState<boolean>(false);

  useEffect(() => {
    unit && setUnitEdit(unit);
  }, [unit]);

  const onSubmit = () => {
    if (!unit?.id || !unitEdit) return;
    dispatch(updateUnitAction({ id: unit.id, body: unitEdit }));
    setDirty(false);
  };

  const handleChangeField = (
    field: keyof IBuildingUnitDto | keyof IUnitInfo | keyof IAddUnitHandlerRequest,
    value: string,
  ) => {
    let updatedUnit = unitEdit;
    if (field === 'name' || field === 'status') {
      updatedUnit = { ...updatedUnit, [field]: value };
    } else {
      updatedUnit = { ...updatedUnit, info: { ...updatedUnit?.info, [field]: value } };
    }
    setUnitEdit(updatedUnit);
    setDirty(true);
  };

  const handleChangeStatus = (status: string) => {
    if (!unit?.id) return;
    if (unit?.status !== status) {
      dispatch(updateUnitStatusAction({ id: unit?.id, status: status as UnitStatus }));
    }
  };
  if (!unitEdit) return <></>;
  return (
    <StyledInfoBox label={'Information'}>
      <Stack spacing={2}>
        <UnitInformationForm value={unitEdit} onChange={handleChangeField} onChangeStatus={handleChangeStatus} />
        <Box display="flex" flexGrow={1} justifyContent="right">
          <LoadingButton onClick={onSubmit} variant="contained" disabled={!dirty} loading={selectedUnit.submitting}>
            Save
          </LoadingButton>
        </Box>
      </Stack>
    </StyledInfoBox>
  );
};
