import React, { FC, useEffect, useState } from 'react';
import { DrawerLayout, DrawerNavItem } from '../../../../global/layout/DrawerLayout';
import {
  getScheduledTaskByIdAction,
  setSelectedScheduledTaskAction,
  updateScheduledTaskAction,
  useScheduledTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/scheduledTaskSlice';
import { useDispatch } from 'react-redux';
import { EditScheduledTask } from './EditScheduledTask';
import { ReduxScheduledTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { ScheduledTaskTab } from '../../../tabs/TabTypeEnums';
import { getAssetTypeFromPathname } from '../../../utils/getAssetTypeFromPathname';
import { useNavigate, useParams } from 'react-router-dom';
import { useRouting } from '../../../utils/useRouting';
import { useIcons } from '../../../icons/useIcons';
import { AssociationChildType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const ScheduledTaskDrawer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAssociationIcon, getTabIcon } = useIcons();
  const { handleNavigateFromAssociationClose } = useRouting();
  const { id, outerTab, innerTab, scheduledTaskId } = useParams();
  const { selectedScheduledTask } = useScheduledTasks();
  const [subTitle, setSubTitle] = useState<string>('');
  const [tabValue, setTabValue] = useState<string>('info');

  useEffect(() => {
    scheduledTaskId && dispatch(getScheduledTaskByIdAction(scheduledTaskId));
  }, [scheduledTaskId]);

  useEffect(() => {
    if (!selectedScheduledTask.value) setSubTitle('');
    else {
      const t = `${selectedScheduledTask.value.propertyName}`;
      if (selectedScheduledTask.value.buildingName) {
        t.concat(` / ${selectedScheduledTask.value.buildingName}`);
      } else if (selectedScheduledTask.value.unitName) {
        t.concat(` / ${selectedScheduledTask.value.buildingName} / ${selectedScheduledTask.value.unitName}`);
      }
      setSubTitle(t);
    }
  }, [selectedScheduledTask.value]);
  const handleCloseDrawer = () => {
    dispatch(setSelectedScheduledTaskAction(undefined));
    setTabValue('info');
    handleNavigateFromAssociationClose();
  };

  const handleUpdateScheduledTask = (task: ReduxScheduledTask, taskId: string) => {
    dispatch(
      updateScheduledTaskAction({
        id: taskId,
        body: task,
      }),
    );
  };
  const tabs: DrawerNavItem[] = [
    {
      icon: getTabIcon('info'),
      label: 'Info',
      value: ScheduledTaskTab.Info,
    },
    {
      icon: getAssociationIcon(AssociationChildType.ChecklistItem),
      label: 'Description',
      value: ScheduledTaskTab.Description,
    },
    {
      icon: getTabIcon('document'),
      label: 'Documents',
      value: ScheduledTaskTab.Documents,
    },
  ];

  return (
    <DrawerLayout
      title={`Scheduled Task: ${selectedScheduledTask.value?.task?.name}`}
      subTitle={subTitle}
      open={!!selectedScheduledTask.value}
      onCloseDrawer={handleCloseDrawer}
      tabs={tabs}
      tabValue={tabValue}
      onTabChange={(newValue: string) => {
        navigate(
          `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/scheduledTask/${scheduledTaskId}/${newValue}`,
        );
        setTabValue(newValue);
      }}
    >
      <EditScheduledTask
        onClose={handleCloseDrawer}
        onUpdateScheduledTask={handleUpdateScheduledTask}
        currentTab={tabValue}
      />
    </DrawerLayout>
  );
};
