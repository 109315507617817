import React, { FC } from 'react';
import { DrawerLayout } from '../../../../global/layout/DrawerLayout';
import { clearSelectedStatementLineAction, useStatement } from '../../redux/statementSlice';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ReduxEntry } from '../../redux/statementTypes';
import { JournalEntryDetailLine } from './JournalEntryDetailLine';
import Divider from '@mui/material/Divider';
import { JournalEntryStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { dialogChildIsOpenAction, useGlobal } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';

type JournalEntryDetailDrawerProps = {
  onOpenPopupStatement?: (entry: ReduxEntry, subAccountId?: string) => void;
};

export const JournalEntryDetailDrawer: FC<JournalEntryDetailDrawerProps> = ({ onOpenPopupStatement }) => {
  const dispatch = useDispatch();
  const { selectedStatementLine, selectedStatement } = useStatement();
  const { dialogChildIsOpen } = useGlobal();
  const entries = selectedStatementLine.value?.journalEntries;
  //entry selected from the statement
  const selectedEntry = entries?.find((entry) => entry.journalEntryId === selectedStatementLine.value?.selectedLineId);
  //original transaction in status group
  const primaryEntry = entries && entries.length > 1 ? entries[0] : undefined;

  return (
    <DrawerLayout
      title={'Transactions'}
      subTitle={selectedEntry?.status === JournalEntryStatus.Archived ? '(Voided)' : undefined}
      open={!!entries}
      onCloseDrawer={() => {
        if (dialogChildIsOpen) {
          dispatch(dialogChildIsOpenAction(false));
        }
        dispatch(clearSelectedStatementLineAction());
      }}
      error={selectedEntry?.status === JournalEntryStatus.Archived}
    >
      <Box p={1} width={'100%'}>
        {primaryEntry && (
          <>
            <Box py={2}>
              <Typography variant={'h6'} fontWeight={'bold'}>
                Primary Transaction:
              </Typography>
              <Divider />
            </Box>
            <JournalEntryDetailLine
              entry={primaryEntry}
              onOpenPopupStatement={onOpenPopupStatement}
              selectedStatementId={selectedStatement.value?.account?.id}
              addColorIndicator={selectedStatementLine.value?.selectedLineId === primaryEntry.journalEntryId}
            />
            <Box py={2}>
              <Typography variant={'h6'} fontWeight={'bold'}>
                Linked Transactions:
              </Typography>
              <Divider />
            </Box>
          </>
        )}
        {entries?.map((entry, index) => {
          if (primaryEntry && index === 0) return;
          return (
            <JournalEntryDetailLine
              entry={entry}
              key={index}
              onOpenPopupStatement={onOpenPopupStatement}
              selectedStatementId={selectedStatement.value?.account?.id}
              addColorIndicator={
                selectedStatementLine.value?.selectedLineId === entry.journalEntryId && entries.length > 1
              }
            />
          );
        })}
      </Box>
    </DrawerLayout>
  );
};
