import React from 'react';
import { getAssetTypeFromPathname } from '../utils/getAssetTypeFromPathname';
import { UnitInformation } from '../../unit/components/unitTabs/unitInfoTabs/UnitInformation';
import { BuildingInformation } from '../../building/components/buildingTabs/BuildingInformation';
import { PropertyInfo } from '../../property/components/propertyTabs/propertyInfoTabs/info/PropertyInfo';

export const InformationTab = () => {
  const asset = getAssetTypeFromPathname();
  if (asset === 'unit') {
    return <UnitInformation />;
  } else if (asset === 'building') {
    return <BuildingInformation />;
  } else if (asset === 'property') {
    return <PropertyInfo />;
  } else return <></>;
};
