import React, { FC, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';

type ColorPickerProps = {
  color: string;
  onChange: (color: string) => void;
};

export const ColorPicker: FC<ColorPickerProps> = ({ color, onChange }) => {
  const [selectedColor, setSelectedColor] = useState(color);

  const handleChange = (color: ColorResult) => {
    setSelectedColor(color.hex);
    onChange(color.hex);
  };

  return <SketchPicker color={selectedColor} onChange={handleChange} />;
};
