import { ExpandableCard } from '../../../_shared/styledComponents/ExpandableCard';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useEffect, useState } from 'react';
import {
  getUserPhoneSettingsAction,
  updateSmsOptInStatusAction,
  updateUserAction,
  useUser,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { SmsOptInStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { PhoneTextField } from '../../../_shared/texfields/PhoneTextField';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { FullForm } from '../../../_shared/styledComponents/FullForm';

export const PhoneSettings = () => {
  const dispatch = useDispatch();
  const phoneSettings = useUser((u) => u.currentUserPhoneSettings);
  const user = useUser((u) => u.currentUser);
  const smsOptInStatus =
    phoneSettings.value?.smsOptInStatus === undefined ? SmsOptInStatus.OptedOut : phoneSettings.value.smsOptInStatus;
  const smsOptIn = smsOptInStatus === SmsOptInStatus.OptedIn;
  const [phoneNumber, setPhoneNumber] = useState(user.value?.phone ?? '');

  useEffect(() => {
    dispatch(getUserPhoneSettingsAction());
  }, []);

  const handleUpdatePhone = () => {
    dispatch(
      updateUserAction({
        phoneNumber: phoneNumber,
        lastName: user.value?.lastName ?? '',
        firstName: user.value?.firstName ?? '',
      }),
    );
  };
  const handleSmsOptIn = () => {
    dispatch(updateSmsOptInStatusAction({ smsOptIn: !smsOptIn }));
  };

  return (
    <ExpandableCard title={'Phone Settings'} subtitle={''} focus={true}>
      <FullForm
        onSubmit={(e) => {
          e.preventDefault();
          handleUpdatePhone();
        }}
      >
        <CardContent sx={{ flexGrow: 1 }}>
          <Stack spacing={2}>
            <Stack spacing={2} direction={'row'}>
              <PhoneTextField
                label={'Phone Number'}
                value={phoneNumber ?? ''}
                onChange={(e) => setPhoneNumber(e.target.value)}
                fullWidth
              >
                {phoneNumber}
              </PhoneTextField>
              <Box>
                <FormControlLabel
                  control={<Switch checked={smsOptIn} onChange={handleSmsOptIn} />}
                  label="SMS Enabled"
                  slotProps={{ typography: { variant: 'body2' } }}
                />
              </Box>
            </Stack>
            <Typography fontStyle="italic" color="textSecondary" variant="caption">
              Note: By enabling SMS messages you consent to our <Link href="#">Terms of Service</Link> and SMS messaging
              rates may apply.
            </Typography>
          </Stack>
        </CardContent>
        <CardActions sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
          <LoadingButton
            variant={'contained'}
            type={'submit'}
            loading={user.loading}
            disabled={user.value?.phone === phoneNumber || phoneNumber === ''}
          >
            Update Phone Number
          </LoadingButton>
        </CardActions>
      </FullForm>
    </ExpandableCard>
  );
};
