import React, { FC, useCallback, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { useExpenses } from '../../redux/expenseSlice';
import { StripedDataGrid, styledFilterPanelProps } from '../../../datagrids/StripedDataGrid';
import {
  DataGridPremiumProps,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRenderCellParams,
  GridRowParams,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { POAdjustmentDetailPanel } from './POAdjustmentDetailPanel';
import { DetailedAnalysisRow } from '../../redux/expenseData';
import { useExpenseAnalysis } from './useExpenseAnalysis';
import { NumberValueTextField } from '../../../texfields/NumberValueTextField';
import { useTheme } from '@mui/material';

export const DetailedCostAnalysis: FC = () => {
  const { selectedExpense } = useExpenses();
  const theme = useTheme();
  const {
    totalEstimate,
    totalCostPayments,
    costToComplete,
    costToCompletePOs,
    unestimatedExpenses,
    handleFormatDetailedAnalysis,
  } = useExpenseAnalysis(selectedExpense.value);
  const [rows, setRows] = useState(handleFormatDetailedAnalysis(selectedExpense.value));

  useEffect(() => {
    setRows(handleFormatDetailedAnalysis(selectedExpense.value));
  }, [selectedExpense.value]);

  const getDetailPanelContent: DataGridPremiumProps['getDetailPanelContent'] = useCallback(
    ({ row }: GridRowParams<DetailedAnalysisRow>) => <POAdjustmentDetailPanel row={row} />,
    [],
  );
  const getDetailPanelHeight = useCallback<NonNullable<DataGridPremiumProps['getDetailPanelHeight']>>(
    () => 'auto' as const,
    [],
  );

  function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();
    // To avoid calling ´getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);
    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return (
      <IconButton size="small" tabIndex={-1} disabled={!hasDetail} aria-label={isExpanded ? 'Close' : 'Open'}>
        <ExpandMoreIcon
          sx={{
            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
            transition: (theme) =>
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
          }}
          fontSize="inherit"
        />
      </IconButton>
    );
  }

  const columns: GridColDef[] = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params: GridRenderCellParams) =>
        params.row.poChangeOrders && params.row.poChangeOrders.length > 0 ? (
          <CustomDetailPanelToggle id={params.id} value={params.value} />
        ) : (
          <></>
        ),
      flex: 0.05,
    },
    {
      field: 'title',
      headerName: '',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <strong>{params.value}</strong>,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
    },
    {
      field: 'payee',
      headerName: 'Payee',
      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value && !params.row.title) return <></>;
        else if (!params.value) return <>$0.00</>;
        else if (params.row.title) {
          return (
            <strong
              style={{
                color:
                  params.row.title === 'Cost to Complete' && costToCompletePOs < 0
                    ? theme.palette.error.main
                    : 'inherit',
              }}
            >
              ${params.value.toFixed(2)}
            </strong>
          );
        }
        return <>${params.value.toFixed(2)}</>;
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Stack flexDirection={'row'}>
        <NumberValueTextField
          fullWidth
          valueUnits={'dollars'}
          variant={'outlined'}
          value={totalEstimate.toFixed(2)}
          InputProps={{ readOnly: true }}
          label={'Estimated Expenses'}
          sx={{ mr: 1 }}
          error={totalEstimate < 0}
          helperText={totalEstimate < 0 ? '*Estimates need updating' : ''}
        />
        <NumberValueTextField
          fullWidth
          valueUnits={'dollars'}
          variant={'outlined'}
          value={totalCostPayments.toFixed(2)}
          InputProps={{ readOnly: true }}
          label={'Total Cost To Date'}
          sx={{ mr: 1 }}
          error={totalCostPayments < 0}
        />
        <NumberValueTextField
          fullWidth
          valueUnits={'dollars'}
          variant={'outlined'}
          value={costToComplete.toFixed(2)}
          InputProps={{ readOnly: true }}
          label={'Cost to Complete'}
          sx={{ input: { color: costToComplete < 0 ? theme.palette.error.main : 'inherit' } }}
        />
        {unestimatedExpenses > 0 && (
          <NumberValueTextField
            fullWidth
            valueUnits={'dollars'}
            variant={'outlined'}
            value={unestimatedExpenses.toFixed(2)}
            InputProps={{ readOnly: true }}
            label={'Unestimated Expenses'}
            sx={{ ml: 1, input: { color: theme.palette.error.main } }}
            error={true}
          />
        )}
      </Stack>
      <StripedDataGrid
        disableRowGrouping
        rows={rows}
        columns={columns}
        autoHeight
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        getRowClassName={(params) => {
          return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          filterPanel: styledFilterPanelProps,
        }}
        pagination
      />
    </Stack>
  );
};
