import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  updatePropertyConfigurationAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { DefaultTenantInvitationBehavior } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import { ConfigurationLayout } from './ConfigurationLayout';

export const TenantAccessConfiguration = () => {
  const dispatch = useDispatch();
  const { selectedProperty } = useProperty();

  const handleInvitationConfigurationChange = (e: SelectChangeEvent<DefaultTenantInvitationBehavior>) => {
    selectedProperty.value?.id &&
      dispatch(
        updatePropertyConfigurationAction({
          id: selectedProperty.value.id,
          config: {
            ...selectedProperty.value.configuration,
            defaultTenantInvitationBehavior: e.target.value as DefaultTenantInvitationBehavior,
          },
        }),
      );
  };

  return (
    <ConfigurationLayout title={'Tenant Access Configuration'}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: '1rem' }}>
        <Typography id={'tenant-invitation'}>Invitation to Tenant Portal</Typography>
        <Select
          labelId={'tenant-invitation'}
          value={
            selectedProperty.value?.configuration?.defaultTenantInvitationBehavior ??
            DefaultTenantInvitationBehavior.Manual
          }
          onChange={(e) => handleInvitationConfigurationChange(e)}
          sx={{ minWidth: '60%' }}
        >
          <MenuItem value={DefaultTenantInvitationBehavior.InviteAllTenantsOnLeaseActivation}>
            Invite All Tenants on Lease Activation
          </MenuItem>
          <MenuItem value={DefaultTenantInvitationBehavior.InvitePrimaryTenantsOnLeaseActivation}>
            Invite Primary Tenants on Lease Activation
          </MenuItem>
          <MenuItem value={DefaultTenantInvitationBehavior.Manual}>Do Not Invite Tenants on Lease Activation</MenuItem>
        </Select>
      </Box>
    </ConfigurationLayout>
  );
};
