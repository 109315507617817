import React, { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { Roles } from '../../app/constants';
import { NotFound } from '../notFound/NotFound';
import { FullScreenLoading } from '../global/loading/FullScreenLoading';
import { Claim, Claims } from '@monkeyjump-labs/cam-fe-shared/dist/types/constants';

export const convertAccessLevelUriToReadable = (accessLevelUri: string) =>
  accessLevelUri
    .replace(/^.*\//, '')
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .trim();

export const AuthorizedClaims: FC<{
  allowedClaims: Claim[];
  trueUser?: boolean;
  children: React.ReactNode;
  disablePropertyContext?: boolean;
}> = ({ allowedClaims, trueUser, children, disablePropertyContext }) => {
  const { isAuthenticated } = useAuth0();
  const property = useProperty();
  const { loading } = useUser((u) => u.currentUser);
  const userInfo = useUser((u) => (trueUser ? u.currentUser.value?.trueUser : u.currentUser.value));
  if (loading) {
    return <FullScreenLoading />;
  }
  if (userInfo?.roles?.some((r) => r === Roles.SuperAdmin)) {
    return <>{children}</>;
  }

  if (!allowedClaims || !isAuthenticated) {
    return <NotFound></NotFound>;
  }
  if (!disablePropertyContext && !property?.selectedProperty?.value) {
    return <NotFound></NotFound>;
  }
  const matchingClaims = userInfo?.claims?.filter((c) =>
    allowedClaims.some(
      (a) => c.type === Claims[a] && (disablePropertyContext || c.value === property.selectedProperty.value?.id),
    ),
  );

  if (matchingClaims?.length) {
    return <>{children}</>;
  }
  return <NotFound></NotFound>;
};

export const AuthorizedClaim: FC<{
  allowedClaim: Claim;
  trueUser?: boolean;
  children: React.ReactNode;
  disablePropertyContext?: boolean;
}> = ({ allowedClaim, trueUser, children, disablePropertyContext }) => {
  return AuthorizedClaims({ allowedClaims: [allowedClaim], trueUser, children, disablePropertyContext });
};
