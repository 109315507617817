import React, { FormEvent, useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { WizardStepper } from '../../../steppers/WizardStepper';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Person from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import {
  AccountType,
  IRentInfo,
  LeaseType,
  RentFrequency,
  SystemAccountType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch, useSelector } from 'react-redux';
import { createLeaseAction, useLeases } from '../../redux/leasesSlice';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import { tryFormatDate } from '../../../utils/TryFormatDate';
import { getGlCodesAction, useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { ReduxAccount } from '@monkeyjump-labs/cam-fe-shared/dist/types/propertyTypes';
import { RootState } from '../../../../../app/store';
import { initLoadable } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { RentInformationEditor } from '../RentInformationEditor';
import { UnitBuildingAutocomplete, UnitOption } from '../../../autocompletes/UnitBuildingAutocomplete';
import { ReduxApplication } from '../../../../property/redux/applicationTypes';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import { appTheme } from '../../../../../AppTheme';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { addDays, addYears } from 'date-fns';
import { ReduxDate, toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { LeaseStartAndEndDatePicker } from '../LeaseStartAndEndDatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import { DialogLayout } from '../../../dialogs/DialogLayout';

type Props = {
  open: boolean;
  onClose: () => void;
  propertyId?: string;
  applicants: ReduxApplication[];
};

export const CreateLeaseWizard = ({ open, onClose, propertyId, applicants }: Props) => {
  const dispatch = useDispatch();
  const { draftLeases } = useLeases();
  const { selectedProperty } = useProperty();
  const [activeStep, setActiveStep] = useState(0);
  const [requestedMoveInDate, setRequestedMoveInDate] = useState<ReduxDate | undefined>(
    selectedProperty.value?.currentPropertyDate,
  );
  const [leaseEndDate, setLeaseEndDate] = useState<ReduxDate | undefined>(
    selectedProperty.value?.currentPropertyDate &&
      toReduxDate(addYears(new Date(selectedProperty.value?.currentPropertyDate), 1)),
  );
  const [unitRequested, setUnitRequested] = useState<string>('');
  const [unitSelected, setUnitSelected] = useState<UnitOption | null>();
  const [primaryApplicant, setPrimaryApplicant] = useState<string>('');

  useEffect(() => {
    if (draftLeases.submitted) handleClose();
  }, [draftLeases.submitted]);

  useEffect(() => {
    if (!selectedProperty.value?.currentPropertyDate) return;
    setRequestedMoveInDate(selectedProperty.value?.currentPropertyDate);
    setLeaseEndDate(toReduxDate(addYears(new Date(selectedProperty.value?.currentPropertyDate), 1)));
  }, [selectedProperty.value?.currentPropertyDate]);

  const handleUnitSelectionChange = (value: UnitOption | null) => {
    setUnitSelected(value);
  };

  const handleSetRequestedMoveInDate = (date: Date) => {
    const endDate = toReduxDate(addDays(addYears(new Date(date), 1), -1));
    setLeaseEndDate(endDate);
    setRequestedMoveInDate(toReduxDate(date));
  };

  const handleSetLeaseEndDate = (date: Date) => {
    setLeaseEndDate(toReduxDate(date));
  };

  const glCodes = useSelector((state: RootState) => state.property?.glAccounts || initLoadable([]));

  const mapReduxAccountsToRent = (reduxAccounts: ReduxAccount[]): IRentInfo => {
    // Get non-standard revenue accounts as other fees (optional)
    const standardTypes: SystemAccountType[] = [
      SystemAccountType.Rent,
      SystemAccountType.RentFees,
      SystemAccountType.LateFeeRevenue,
      SystemAccountType.MonthToMonthFeeRevenue,
    ];
    const otherFees = reduxAccounts
      .filter((x) => typeof x.systemType === 'undefined' || !standardTypes.includes(x.systemType))
      .filter((x) => x.accountType === AccountType.Revenue)
      .filter((x) => x.isAssignable)
      .filter((x) => x.isEnabled);
    return {
      rentAmount: 0,
      lateFee: 0,
      frequency: RentFrequency.Monthly,
      monthToMonthFee: 0,
      otherFees: otherFees.map((x: ReduxAccount) => ({
        fee: 0,
        account: {
          id: x.id,
          name: x.accountName,
        },
        label: x.accountName,
      })),
    };
  };

  const [rentInfo, setRentInfo] = useState<IRentInfo>({});

  useEffect(() => {
    if (applicants?.length > 0) {
      setUnitRequested(applicants[0].unitRequested ?? '');
      applicants[0].id && setPrimaryApplicant(applicants[0].id);
    } else {
      setUnitRequested('');
    }
  }, [applicants]);

  useEffect(() => {
    dispatch(getGlCodesAction({ propertyId: propertyId || '' }));
  }, [propertyId]);

  useEffect(() => {
    setRentInfo(mapReduxAccountsToRent(glCodes.value || []));
  }, [glCodes.value]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (requestedMoveInDate && leaseEndDate) {
      dispatch(
        createLeaseAction({
          propertyId: propertyId,
          leaseType: LeaseType.Electronic,
          applicationIds: applicants.filter((a) => a.id).map((a) => a.id!),
          mainTenantApplicationId: primaryApplicant,
          rent: rentInfo,
          requestedMoveInDate: requestedMoveInDate,
          endDate: leaseEndDate,
          unitIdRequested: unitSelected?.id,
        }),
      );
      handleClose();
    }
  };

  const handlePrimaryAppChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrimaryApplicant((event.target as HTMLInputElement).value);
  };

  const handleClose = () => {
    setRentInfo(mapReduxAccountsToRent(glCodes.value || []));
    setActiveStep(0);
    setUnitSelected(null);
    setUnitRequested('');
    setPrimaryApplicant('');
    setRequestedMoveInDate(selectedProperty.value?.currentPropertyDate);
    setLeaseEndDate(
      selectedProperty.value?.currentPropertyDate
        ? toReduxDate(addYears(new Date(selectedProperty.value?.currentPropertyDate), 1))
        : undefined,
    );
    onClose();
  };

  const handleNext = () => {
    if (activeStep === 0) {
      if (unitSelected === null || !unitSelected) {
        dispatch(
          showToastMessageAction({
            message: 'You must select a Building Unit before proceeding',
            severity: 'warning',
          }),
        );
      } else if (!primaryApplicant) {
        dispatch(
          showToastMessageAction({
            message: 'You must select a primary applicant before continuing',
            severity: 'warning',
          }),
        );
      } else if (!requestedMoveInDate || !leaseEndDate) {
        dispatch(showToastMessageAction({ message: 'You must select a start and end date before proceeding' }));
      } else setActiveStep(activeStep + 1);
    } else if (activeStep === 1) {
      if (!rentInfo?.rentAmount || !rentInfo.deposit || !rentInfo.lateFee || !rentInfo.monthToMonthFee) {
        dispatch(
          showToastMessageAction({
            message: 'Please fill in all required fields before proceeding',
            severity: 'error',
          }),
        );
      } else setActiveStep(activeStep + 1);
    } else setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <DialogLayout title={'Create New Lease'} maxWidth="md" fullWidth={true} open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <WizardStepper
            steps={['Tenant Information', 'Lease Terms', 'Review and Create Lease']}
            activeStep={activeStep}
            onNext={handleNext}
            onBack={handleBack}
            onReset={handleReset}
          />
          {
            {
              0: (
                <StyledInfoBox label={'Lease Details'}>
                  <Chip
                    icon={<HouseOutlinedIcon />}
                    sx={{ justifyContent: 'space-between', color: 'white' }}
                    color={'primary'}
                    label={`Unit Requested: ${unitRequested}`}
                  />
                  <UnitBuildingAutocomplete
                    required={unitSelected === null}
                    value={unitSelected ?? null}
                    onValueChange={handleUnitSelectionChange}
                  />
                  <LeaseStartAndEndDatePicker
                    onChangeLeaseEndDateOnly={handleSetLeaseEndDate}
                    onChangeLeaseStartAndEndDate={handleSetRequestedMoveInDate}
                    leaseEndDate={leaseEndDate ? toStandardDate(leaseEndDate) : undefined}
                    leaseStartDate={requestedMoveInDate ? toStandardDate(requestedMoveInDate) : undefined}
                  />
                  <FormControl sx={{ mt: '1rem' }}>
                    <FormLabel id="select-primary-tenant-label">Select Primary Tenant</FormLabel>
                    <RadioGroup
                      aria-labelledby="select-primary-tenant-label"
                      name={'select-primary-tenant'}
                      value={primaryApplicant}
                      onChange={handlePrimaryAppChange}
                    >
                      {applicants.map((applicant) => (
                        <Box key={applicant.id} sx={{ display: 'flex', flexGrow: 1 }}>
                          <FormControlLabel control={<Radio />} label={``} value={applicant.id} />
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar
                                sx={
                                  applicant.id === primaryApplicant
                                    ? { backgroundColor: appTheme.palette.primary.main }
                                    : {}
                                }
                              >
                                <Person />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${applicant.firstName} ${applicant.lastName}`}
                              secondary={`Status: ${applicant.applicationStatus}`}
                            />
                          </ListItem>
                        </Box>
                      ))}
                    </RadioGroup>
                  </FormControl>
                </StyledInfoBox>
              ),
              1: (
                <StyledInfoBox label="Lease Terms">
                  <RentInformationEditor isNew editable value={rentInfo} onChange={setRentInfo} />
                </StyledInfoBox>
              ),
              2: (
                <StyledInfoBox label="Does this look right?">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        InputProps={{ readOnly: true }}
                        variant="standard"
                        label={'Building Unit'}
                        value={unitSelected?.label}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={'Lease Start Date'}
                        required
                        fullWidth
                        InputProps={{ readOnly: true }}
                        value={requestedMoveInDate ? tryFormatDate(requestedMoveInDate) : ''}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        label={'Lease End Date'}
                        fullWidth
                        InputProps={{ readOnly: true }}
                        value={leaseEndDate ? tryFormatDate(leaseEndDate) : ''}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6">Tenants</Typography>
                      <List>
                        {applicants.map((applicant) => (
                          <ListItem key={applicant.id}>
                            <ListItemAvatar>
                              <Avatar
                                sx={
                                  applicant.id === primaryApplicant
                                    ? { backgroundColor: appTheme.palette.primary.main }
                                    : {}
                                }
                              >
                                <Person />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${applicant.firstName} ${applicant.lastName}`}
                              secondary={
                                applicant.id === primaryApplicant
                                  ? `Status: ${applicant.applicationStatus}, *Primary Tenant*`
                                  : `Status: ${applicant.applicationStatus}`
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6">Rent Information</Typography>
                      <RentInformationEditor value={rentInfo} isNew={true} />
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Typography></Typography>
                  </Grid>
                </StyledInfoBox>
              ),
            }[activeStep]
          }
          {activeStep === 2 && (
            <DialogActions sx={{ marginTop: '1rem' }}>
              <Button onClick={onClose}>Close Without Saving</Button>
              <LoadingButton type="submit" variant="contained" loading={draftLeases.submitting}>
                Create Lease
              </LoadingButton>
            </DialogActions>
          )}
        </DialogContent>
      </form>
    </DialogLayout>
  );
};
