import React from 'react';
import { DialogLayout } from '../../../../../_shared/dialogs/DialogLayout';
import { useDispatch } from 'react-redux';
import { clearAdditionalReportDataAction, useReports } from '../../../../redux/reportSlice';
import { ReportsDataGrid } from './ReportsDataGrid';
import Box from '@mui/material/Box';

export const AdditionalInfoReportDialog = () => {
  const dispatch = useDispatch();
  const { additionalReport } = useReports();
  return (
    <DialogLayout
      title={'Transaction Report'}
      onClose={() => dispatch(clearAdditionalReportDataAction())}
      open={additionalReport.value !== undefined}
      maxWidth={'lg'}
      fullWidth
    >
      <Box p={3}>
        <ReportsDataGrid type={'additional'} />
      </Box>
    </DialogLayout>
  );
};
