import React, { FC, useEffect, useState } from 'react';
import {
  AccountType,
  IGetAccountsByPropertyIdResponse,
  ISlimAccountRef,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useDispatch } from 'react-redux';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export type AccountAutocompleteProps = {
  propertyId: string;
  accountType?: AccountType;
  onChange: (value: ISlimAccountRef) => void;
  accountFilter?: (account: IGetAccountsByPropertyIdResponse) => boolean;
  accountDefault?: (account: IGetAccountsByPropertyIdResponse) => boolean;
  hideAccountPath?: boolean;
  includeUnassignable?: boolean;
  /**
   * The ID of the account
   */
  value?: ISlimAccountRef;
} & Omit<
  AutocompleteProps<IGetAccountsByPropertyIdResponse, false, false, false>,
  'options' | 'value' | 'onChange' | 'getOptionLabel'
>;

export const AccountAutocomplete: FC<AccountAutocompleteProps> = ({
  accountFilter,
  propertyId,
  accountType,
  onChange,
  value,
  accountDefault,
  includeUnassignable,
  hideAccountPath,
  ...autocompleteProps
}) => {
  const dispatch = useDispatch();
  const [internalValue, setInternalValue] = useState<IGetAccountsByPropertyIdResponse | null>(null);
  const [accountOptions, setAccountOptions] = useState<IGetAccountsByPropertyIdResponse[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const response = await ApiClientSingleton.getInstance().accounts_GetAccounts(
          propertyId,
          accountType,
          false,
          false,
          false,
        );
        if (response) {
          setAccountOptions(response);
          let currentAccount = value ? response.find((x) => x.id === value.id) : null;
          if (!currentAccount && accountDefault) {
            currentAccount = response.find((x) => accountDefault(x));
            if (currentAccount?.id)
              onChange({
                id: currentAccount.id,
                name: currentAccount.accountName,
              });
          }
          setInternalValue(currentAccount ?? null);
          setLoading(false);
        }
      } catch {
        setLoading(false);
        dispatch(showToastMessageAction({ message: 'Error loading accounts', severity: 'error' }));
      }
    })();
  }, [propertyId, accountType, value]);

  const handleChange = (event: React.SyntheticEvent, newValue: IGetAccountsByPropertyIdResponse | null) => {
    if (newValue?.id) {
      onChange({
        id: newValue.id,
        name: newValue.accountName,
      });
    }
    setInternalValue(newValue);
  };

  let options = accountFilter ? accountOptions.filter(accountFilter) : accountOptions;
  if (!includeUnassignable) {
    options = options.filter((account) => account.isAssignable);
  }
  return (
    <Autocomplete
      options={options}
      loading={loading}
      onChange={handleChange}
      value={internalValue}
      getOptionLabel={(option: IGetAccountsByPropertyIdResponse) => option.accountName ?? ''}
      renderOption={(props, option, { index }) => (
        <React.Fragment key={option.id}>
          {index > 0 && <Divider />}
          <ListItem {...props}>
            <Stack>
              {hideAccountPath ? null : (
                <Typography variant={'body2'} fontStyle={'italic'}>
                  {option.accountPath}
                </Typography>
              )}
              <Typography>{option.accountName}</Typography>
            </Stack>
          </ListItem>
        </React.Fragment>
      )}
      {...autocompleteProps}
    />
  );
};
