import { ButtonProps } from '@mui/material/Button';
import React, { FC, useRef } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';

type SingleFileUploadProps = Omit<ButtonProps, 'onClick'> & {
  onUpload: (file: File) => void;
  multiple?: false;
  loading?: boolean;
  isInMenu?: boolean;
  isIcon?: boolean;
  accept?: string;
};

type MultipleFileUploadProps = Omit<ButtonProps, 'onClick'> & {
  onUpload: (files: File[]) => void;
  multiple: true;
  loading?: boolean;
  isInMenu?: boolean;
  isIcon?: boolean;
  accept?: string;
};

type FileUploadButtonProps = SingleFileUploadProps | MultipleFileUploadProps;

export const FileUploadButton: FC<FileUploadButtonProps> = ({
  isInMenu,
  loading,
  children,
  onUpload,
  multiple,
  isIcon,
  accept,
  ...buttonProps
}) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files);
      if (multiple) {
        onUpload(files);
      } else {
        onUpload(files[0]);
      }
      if (fileUploadRef.current) fileUploadRef.current.value = '';
    }
  };

  const inputProps = {
    ref: fileUploadRef,
    type: 'file',
    accept,
    hidden: true,
    onChange: handleChange,
    ...(multiple ? { multiple: true } : {}),
  };

  if (isInMenu) {
    return (
      <MenuItem onClick={() => fileUploadRef?.current?.click()}>
        {loading ? (
          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <>
            {children}
            <input {...inputProps} />
          </>
        )}
      </MenuItem>
    );
  }
  if (isIcon) {
    return (
      <Tooltip title={'Add Attachment'}>
        <IconButton onClick={() => fileUploadRef?.current?.click()}>
          <input {...inputProps} />
          <AttachFileIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <LoadingButton
      variant="contained"
      color="primary"
      onClick={() => fileUploadRef?.current?.click()}
      {...buttonProps}
      loading={loading}
    >
      {children}
      <input {...inputProps} />
    </LoadingButton>
  );
};
