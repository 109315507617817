import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteUnitTemplateAction, listUnitTemplatesAction, useUnitTemplates } from '../redux/unitTemplateSlice';
import { GridColDef, GridPaginationModel, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import { StripedDataGrid } from '../../datagrids/StripedDataGrid';
import { IUnitTemplate } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { UnitTemplateTab } from '../../tabs/TabTypeEnums';
import { getAssetTypeFromPathname } from '../../utils/getAssetTypeFromPathname';
import { AssetParams } from '../../../../AppRouter';
import { ActionCell } from '../../datagrids/ActionCell';
import DeleteIcon from '@mui/icons-material/Delete';

export type UnitTemplateTableProps = {
  propertyId: string;
  buildingId?: string;
};

export const UnitTemplateTable: FC<UnitTemplateTableProps> = ({ propertyId, buildingId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathAssetType = getAssetTypeFromPathname();
  const { id, outerTab, innerTab } = useParams<AssetParams>();
  const { unitTemplates } = useUnitTemplates();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rows, setRows] = useState<IUnitTemplate[]>([]);

  useEffect(() => {
    dispatch(
      listUnitTemplatesAction({ propertyId: propertyId, buildingId: buildingId, page: page, pageSize: pageSize }),
    );
  }, [buildingId]);

  useEffect(() => {
    if (unitTemplates.loaded) {
      setRows(unitTemplates.value ?? []);
    }
  }, [unitTemplates.loaded]);

  const onViewTemplate = (unitTemplate: IUnitTemplate) => {
    navigate(
      `/assets/${pathAssetType}/${id}/${outerTab}/${innerTab}/unitTemplate/${unitTemplate.id}/${UnitTemplateTab.Info}`,
    );
  };

  const onDelete = (unitTemplateId: string) => {
    dispatch(deleteUnitTemplateAction({ id: unitTemplateId }));
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<IUnitTemplate>) => {
        return (
          <Link sx={{ cursor: 'pointer' }} color={'inherit'}>
            <Typography onClick={() => onViewTemplate(params.row)}>{params.value}</Typography>
          </Link>
        );
      },
    },
    {
      field: 'bedrooms',
      headerName: 'Bedrooms',
      type: 'number',
      flex: 3,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<IUnitTemplate>) => params.row.info?.bedrooms ?? 0,
    },
    {
      field: 'bathrooms',
      headerName: 'Bathrooms',
      type: 'number',
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<IUnitTemplate>) => params.row.info?.bathrooms ?? 0,
    },
    {
      field: 'squareFootage',
      headerName: 'Square Footage',
      type: 'number',
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<IUnitTemplate>) => params.row.info?.squareFootage ?? 0,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1.25,
      display: 'flex',
      cellClassName: 'actions',
      getActions: (params: GridRowParams<IUnitTemplate>) => [
        <ActionCell
          key={params.row.id}
          showInMenu={false}
          label={'Delete'}
          onClick={() => params.row.id && onDelete(params.row.id)}
          icon={<DeleteIcon />}
        />,
      ],
    },
  ];

  return (
    <StripedDataGrid
      disableRowGrouping
      autoHeight
      getRowHeight={() => 'auto'}
      rowHeight={75}
      rowCount={unitTemplates.totalCount ?? 0}
      rows={rows}
      columns={columns}
      loading={unitTemplates.loading}
      paginationMode={'server'}
      sortingMode={'server'}
      paginationModel={{ page: page, pageSize: pageSize }}
      onPaginationModelChange={(model: GridPaginationModel) => {
        setPage(model.page);
        setPageSize(model.pageSize);
      }}
      pageSizeOptions={[10, 25, 50, 100]}
    ></StripedDataGrid>
  );
};
