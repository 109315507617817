import React, { FC, forwardRef, useEffect, useRef, useState } from 'react';
import { StripedDataGrid } from '../../../../../_shared/datagrids/StripedDataGrid';
import { IContactPerson } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  DataGridPremiumProps,
  GridColDef,
  GridRenderEditCellParams,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarProps,
  GridValidRowModel,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { PhoneTextField } from '../../../../../_shared/texfields/PhoneTextField';
import { useIcons } from '../../../../../_shared/icons/useIcons';

type ContactWithId = IContactPerson & { id: number };

type ContactsTableProps = {
  loading?: boolean;
  value: IContactPerson[];
  onSave: (values: IContactPerson[]) => void;
};

function EditToolbar(props: GridToolbarProps) {
  const { nextId, setRows } = props;
  const apiRef = useGridApiContext();

  const handleClick = () => {
    setRows((oldRows: GridRowsProp<ContactWithId>) => [
      ...oldRows,
      {
        id: nextId,
      },
    ]);
    apiRef.current.startRowEditMode({ id: nextId });
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" variant={'outlined'} startIcon={<AddIcon />} onClick={handleClick}>
        Add contact
      </Button>
    </GridToolbarContainer>
  );
}

export const PhoneNumberCell = forwardRef<HTMLDivElement, GridRenderEditCellParams>((props, ref) => {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();

  React.useLayoutEffect(() => {
    if (hasFocus && ref && typeof ref !== 'function' && ref.current) {
      ref.current.focus();
    }
  }, [hasFocus, ref]);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <PhoneTextField
      ref={ref}
      InputProps={{
        disableUnderline: true,
      }}
      includeHelperText={false}
      variant="standard"
      type="text"
      value={value}
      onChange={handleValueChange}
    />
  );
});
PhoneNumberCell.displayName = 'PhoneNumberCell';

export const ContactsTable: FC<ContactsTableProps> = ({ loading, value, onSave }) => {
  const { getActionIcon, ActionType } = useIcons();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = React.useState<ContactWithId[]>(value.map((x, i) => ({ ...x, id: i })));
  const gridRef = useRef<HTMLDivElement & DataGridPremiumProps>(null);
  useEffect(() => {
    setRows(value.map((x, i) => ({ ...x, id: i })));
  }, [value]);

  const columns: GridColDef<IContactPerson>[] = [
    { field: 'firstName', headerName: 'First Name', flex: 1, editable: true },
    { field: 'lastName', headerName: 'Last Name', flex: 1, editable: true },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      editable: true,
      valueSetter: (value, row) => {
        return { ...row, contactInfo: { ...row?.contactInfo, email: value } };
      },
      valueGetter: (value, row) => row.contactInfo?.email ?? '',
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
      editable: true,
      renderEditCell: (params) => <PhoneNumberCell {...params} />,
      renderCell: (params) => {
        return params.value ? (
          <a href={`tel:${params.value}`} style={{ textDecoration: 'none' }}>
            {params.value}
          </a>
        ) : (
          ''
        );
      },
      valueSetter: (value, row) => {
        return { ...row, contactInfo: { ...row?.contactInfo, phone: value } };
      },
      valueGetter: (value, row) => row.contactInfo?.phone ?? '',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <Stack direction={'row'} spacing={1}>
          <Tooltip title={'Delete'}>
            <IconButton size={'small'} onClick={() => onSave(rows.filter((r) => r.id !== params.id))}>
              {getActionIcon(ActionType.Delete)}
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  const handleSave = (updatedRow: GridValidRowModel) => {
    const updatedRows = rows.map((row) => (row.id === updatedRow?.id ? updatedRow : row));
    onSave(updatedRows);
    return updatedRow;
  };

  return (
    <StripedDataGrid
      disableRowGrouping
      autoHeight
      ref={gridRef}
      columns={columns}
      rows={rows}
      loading={loading}
      editMode={'row'}
      rowModesModel={rowModesModel}
      onRowModesModelChange={(model) => setRowModesModel(model)}
      processRowUpdate={handleSave}
      onCellEditStop={(params) => onSave(params.value)}
      slots={{
        toolbar: EditToolbar,
      }}
      slotProps={{
        toolbar: {
          nextId: Math.max(...rows.map((r) => r.id)) + 1,
          setRows,
        },
      }}
    />
  );
};
