import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { StripedDataGrid } from '../../../_shared/datagrids/StripedDataGrid';
import React, { FC, useEffect, useState } from 'react';
import { useGlobal } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';
import { GridColDef } from '@mui/x-data-grid-premium';

type ErrorDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const ErrorDialog: FC<ErrorDialogProps> = ({ open, onClose }) => {
  const global = useGlobal();
  const [rows, setRows] = useState<{ id: string; key: string; value: string }[]>([]);

  useEffect(() => {
    setRows(getRows());
  }, [global.toast.additionalDetails?.errors]);

  const getRows = () => {
    const rows: { id: string; key: string; value: string }[] = [];
    Object.entries(global.toast.additionalDetails?.errors ?? {}).flatMap(([key, value], index) => {
      value.forEach((v, i) => {
        rows.push({
          id: `${index}-${i}`,
          key: key,
          value: v,
        });
      });
    });
    return rows;
  };

  const columns: GridColDef[] = [
    {
      field: 'key',
      headerName: 'Association',
      flex: 1,
    },
    {
      field: 'value',
      headerName: 'Error',
      flex: 3,
    },
  ];

  return (
    <DialogLayout
      title={global.toast.additionalDetails?.title ?? 'Error'}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={'lg'}
    >
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="h6">Reason: {global.toast.additionalDetails?.detail}</Typography>
          {Object.keys(global.toast.additionalDetails?.errors ?? {}).length > 0 && (
            <>
              <Typography variant={'h6'}>Errors:</Typography>
              <Box
                sx={{
                  height: 'auto',
                  width: 1,
                  '& .super-app-theme--inactive': {
                    color: 'lightgray',
                  },
                }}
              >
                <StripedDataGrid
                  disableRowGrouping
                  rows={rows}
                  columns={columns}
                  hideFooter
                  getRowClassName={(params) => {
                    return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
                  }}
                  autoHeight
                />
              </Box>
            </>
          )}
        </Stack>
      </DialogContent>
    </DialogLayout>
  );
};
