import React, { FC } from 'react';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import ListItem from '@mui/material/ListItem';
import { ImagePreviewComponent } from './ImagePreviewComponent';
import Typography from '@mui/material/Typography';
import { getFileExtension } from '../../utils/getFileExtension';
import InsertDriveFileSharpIcon from '@mui/icons-material/InsertDriveFileSharp';
import IconButton from '@mui/material/IconButton';
import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material';
import { DocumentInformation } from './DocumentInformation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import Link from '@mui/material/Link';
import {
  ICamAssociation,
  SimpleDocumentType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { downloadFileUtils } from '@monkeyjump-labs/cam-fe-shared/dist/utils/DownloadFileUtils';
import { useIcons } from '../../icons/useIcons';
import { selectedItemBorder } from '../../../../AppTheme';
import { createDocumentLink } from '../../paymentProcessing/authorizeNet/helpers';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';

export type DocumentLinkProps = {
  propertyId?: string;
  association: ICamAssociation;
  document: ReduxDocument;
  showAsIcons?: boolean;
  onDeleteDocument?: (id: string) => void;
};

export const DocumentLink: FC<DocumentLinkProps> = ({
  propertyId,
  association,
  document,
  onDeleteDocument,
  showAsIcons,
}) => {
  const { getActionIcon, ActionType } = useIcons();
  const highlightedDocId = new URLSearchParams(location.search).get('selected');
  const displayDocument: ReduxDocument | undefined =
    document?.metadata?.documentType === SimpleDocumentType.Image360 && document?.children && document.children[0]
      ? document?.children[0]
      : document;
  const docType = getFileExtension(displayDocument.name);
  const theme = useTheme();

  const styles = {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  };

  const handleDownloadClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      !propertyId ||
      association.associationType === undefined ||
      association.associatedId === undefined ||
      document.id === undefined ||
      document.metadata?.documentType === SimpleDocumentType.Image360
    )
      return;
    const fileResponse = await ApiClientSingleton.getInstance().documents_DownloadFile(
      propertyId,
      association.associationType,
      association.associatedId,
      document.id,
      association.associationChildType,
      association.associationChildNumber,
    );
    downloadFileUtils(fileResponse.data, fileResponse.fileName ?? document.name ?? 'no name');
  };

  return (
    <>
      {showAsIcons ? (
        <ImagePreviewComponent imageUrl={displayDocument.uri} docType={docType}>
          <Tooltip title={displayDocument.name ?? ''} placement={'top-start'}>
            <Link href={displayDocument.uri} download={displayDocument.name} onClick={handleDownloadClick}>
              <IconButton>
                <InsertDriveFileSharpIcon />
              </IconButton>
            </Link>
          </Tooltip>
        </ImagePreviewComponent>
      ) : typeof association.associatedId === undefined || !association.associatedId ? (
        <ListItem
          key={document.uri}
          sx={{
            border: highlightedDocId === document.id ? selectedItemBorder : 'inherit',
            borderRadius: 1,
          }}
        >
          <Link href={displayDocument.uri} download={displayDocument.name}>
            <Typography style={styles}>{displayDocument.name}</Typography>
          </Link>
        </ListItem>
      ) : (
        <ListItem
          key={document.id}
          sx={{
            width: '100%',
            border: highlightedDocId === document.id ? selectedItemBorder : 'inherit',
            borderRadius: 1,
          }}
        >
          <Accordion sx={{ width: '100%', border: '.1rem solid gray' }} elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${document.name}-content`}
              id={`${document.name}-header`}
            >
              <Box paddingRight={1}>
                {document.metadata?.documentType === SimpleDocumentType.Image360 ? (
                  <Tooltip title={'360 Image'}>
                    <CameraswitchIcon sx={styles} />
                  </Tooltip>
                ) : (
                  <DownloadIcon sx={styles} />
                )}
              </Box>
              <ImagePreviewComponent imageUrl={displayDocument.uri} docType={docType}>
                {typeof propertyId === 'undefined' && <Typography>{displayDocument.name}</Typography>}
                {document.metadata?.documentType === SimpleDocumentType.Image360 ? (
                  <Typography>{displayDocument.name}</Typography>
                ) : (
                  <Link href={createDocumentLink(propertyId, association, document.id)} onClick={handleDownloadClick}>
                    {displayDocument.name}
                  </Link>
                )}
              </ImagePreviewComponent>
              <Box display={'flex'} flexGrow={1}></Box>
              {onDeleteDocument && (
                <Tooltip title={'Delete Document'}>
                  <IconButton
                    size={'small'}
                    onClick={(e) => {
                      e.stopPropagation();
                      document.id && onDeleteDocument(document.id);
                    }}
                  >
                    {getActionIcon(ActionType.Delete, { fontSize: 'small' })}
                  </IconButton>
                </Tooltip>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <DocumentInformation
                document={document}
                association={association}
                displayDocument={displayDocument}
                docType={docType}
              />
            </AccordionDetails>
          </Accordion>
        </ListItem>
      )}
    </>
  );
};
