import React, { FC } from 'react';
import { UnitStatusDto } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { useUnit } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/unitSlice';
import Tooltip from '@mui/material/Tooltip';

type AssociateWithCurrentLeaseToggleProps = {
  value: boolean;
  onChange: () => void;
  inScheduledTask?: boolean;
};

export const AssociateWithCurrentLeaseToggle: FC<AssociateWithCurrentLeaseToggleProps> = ({
  value,
  onChange,
  inScheduledTask,
}) => {
  const { selectedContext } = useAssets();
  const { selectedUnit } = useUnit();
  return (
    <Box sx={{ mt: '.5rem' }}>
      {selectedContext.unitId ? (
        selectedUnit.value?.status === UnitStatusDto.Occupied || inScheduledTask ? (
          <FormControl>
            <Tooltip title={'This will make the task visible to current tenants'}>
              <FormControlLabel
                control={<Checkbox checked={value} onChange={onChange} />}
                label={`Associate With Active Lease ${inScheduledTask ? '(if applicable at time of scheduling)' : ''}`}
              />
            </Tooltip>
          </FormControl>
        ) : (
          <Typography fontStyle={'italic'}>*There are no open leases associated with this unit.</Typography>
        )
      ) : (
        <></>
      )}
    </Box>
  );
};
