import React from 'react';
import { getAssetTypeFromPathname } from '../utils/getAssetTypeFromPathname';
import { PropertyConfiguration } from '../../property/components/propertyTabs/propertyInfoTabs/info/PropertyConfiguration';

export const ConfigurationTab = () => {
  const asset = getAssetTypeFromPathname();
  if (asset === 'property') {
    return <PropertyConfiguration />;
  } else return <></>;
};
