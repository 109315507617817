import {
  LeaseDetailedStatus,
  SignedStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { addDays } from 'date-fns';

export enum LeaseActionType {
  RenewLease,
  AcceptDeposit,
  MarkAsSigned,
  PreLease,
  MarkAsLeased,
  CancelPreLease,
  MoveOut,
  RectifyDeposit,
  CloseLease,
  ReturnFunds,
  BeginEviction,
  PaymentOptions,
  RollbackLease,
}

export const useLeaseActions = (currentPropertyDate: string | undefined) => {
  const leaseStatusActionsMap = new Map<LeaseDetailedStatus[], LeaseActionType>([
    [[LeaseDetailedStatus.PreLeased], LeaseActionType.MarkAsLeased],
    [[LeaseDetailedStatus.Draft], LeaseActionType.AcceptDeposit],
    [
      [LeaseDetailedStatus.Draft, LeaseDetailedStatus.DepositPaid, LeaseDetailedStatus.PreLeased],
      LeaseActionType.CancelPreLease,
    ],
    [
      [
        LeaseDetailedStatus.Active,
        LeaseDetailedStatus.ActivePendingEviction,
        LeaseDetailedStatus.ActiveMonthToMonthPendingEviction,
        LeaseDetailedStatus.ActiveMonthToMonth,
      ],
      LeaseActionType.MoveOut,
    ],
    [[LeaseDetailedStatus.Active, LeaseDetailedStatus.ActiveMonthToMonth], LeaseActionType.BeginEviction],
    [[LeaseDetailedStatus.MovedOut, LeaseDetailedStatus.MovedOutEviction], LeaseActionType.CloseLease],
    [
      [
        LeaseDetailedStatus.Active,
        LeaseDetailedStatus.PreLeased,
        LeaseDetailedStatus.ActiveMonthToMonth,
        LeaseDetailedStatus.ReturnFundsThenClose,
        LeaseDetailedStatus.ReturnFundsEvicted,
        LeaseDetailedStatus.ActivePendingEviction,
        LeaseDetailedStatus.MovedOutEviction,
        LeaseDetailedStatus.MovedOut,
        LeaseDetailedStatus.Cancelling,
        LeaseDetailedStatus.Closed,
        LeaseDetailedStatus.ClosedEviction,
      ],
      LeaseActionType.PaymentOptions,
    ],
    [
      [
        LeaseDetailedStatus.MovedOut,
        LeaseDetailedStatus.MovedOutEviction,
        LeaseDetailedStatus.ActivePendingEviction,
        LeaseDetailedStatus.ActiveMonthToMonthPendingEviction,
      ],
      LeaseActionType.RollbackLease,
    ],
  ]);

  const getActionsByLeaseStatus = (lease: ReduxLease) => {
    if (!lease.id || !lease.processStatus || !currentPropertyDate) return;
    const actions: LeaseActionType[] = [];

    if (
      lease.detailedStatus === LeaseDetailedStatus.Active ||
      lease.detailedStatus === LeaseDetailedStatus.ActiveMonthToMonth
    ) {
      if (lease.endDate && lease.endDate < addDays(new Date(currentPropertyDate), 62).toISOString()) {
        actions.push(LeaseActionType.RenewLease);
      } else if (lease.detailedStatus === LeaseDetailedStatus.ActiveMonthToMonth) {
        actions.push(LeaseActionType.RenewLease);
      }
    }

    if (
      lease.detailedStatus === LeaseDetailedStatus.ReturnFundsThenClose ||
      lease.detailedStatus === LeaseDetailedStatus.ReturnFundsEvicted
    ) {
      actions.push(LeaseActionType.CloseLease);
    }

    if (lease.detailedStatus === LeaseDetailedStatus.Cancelling) {
      actions.push(LeaseActionType.CloseLease);
    }

    leaseStatusActionsMap.forEach((type, leaseStatusArray) => {
      if (leaseStatusArray.includes(lease.detailedStatus!)) {
        actions.push(type);
      }
    });
    if (
      lease.detailedStatus !== LeaseDetailedStatus.Cancelling &&
      lease.leaseTerms?.some((term) => term.signedStatus === SignedStatus.Unsigned)
    ) {
      actions.push(LeaseActionType.MarkAsSigned);
    }
    if (
      lease.detailedStatus === LeaseDetailedStatus.DepositPaid &&
      lease.leaseTerms?.some((term) => term.signedStatus === SignedStatus.Signed)
    ) {
      actions.push(LeaseActionType.PreLease);
    }

    return actions;
  };

  return { getActionsByLeaseStatus };
};
