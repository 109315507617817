import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import { appTheme } from '../../../../../../AppTheme';
import React from 'react';
import { LeaseDetailedStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const DelinquencyCell = (props: GridRenderCellParams) => {
  if (props.value > 0) {
    return (
      <Box
        sx={{
          backgroundColor: appTheme.palette.error.main,
          color: 'white',
          margin: '.1rem',
          padding: '.4rem',
          borderRadius: appTheme.shape.borderRadius,
          opacity:
            props.row.status === LeaseDetailedStatus.ClosedEviction || props.row.status === LeaseDetailedStatus.Closed
              ? '50%'
              : '100%',
        }}
      >
        {'$' + Number(props.value).toFixed(2)}
      </Box>
    );
  }
  return <></>;
};
