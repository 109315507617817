import { all, put, takeLatest } from 'redux-saga/effects';
import {
  addIncomeToAssetAction,
  addIncomeToAssetFailAction,
  addIncomeToAssetSuccessAction,
  getIncomeForAssetAction,
  getIncomeForAssetFailAction,
  getIncomeForAssetSuccessAction,
  getPlatformPeriodsAction,
  getPlatformPeriodsFailAction,
  getPlatformPeriodsSuccessAction,
  getPlatformStatementAction,
  getPlatformStatementFailAction,
  getPlatformStatementSuccessAction,
  getPropertyAccountStatementAction,
  getPropertyAccountStatementFailAction,
  getPropertyAccountStatementSuccessAction,
  getStatementByLeaseIdAction,
  getStatementByLeaseIdFailAction,
  getStatementByLeaseIdSuccessAction,
  getStatementPeriodsByLeaseIdAction,
  getStatementPeriodsByLeaseIdFailAction,
  getStatementPeriodsByLeaseIdSuccessAction,
  getStatementPeriodsForPropertyAccountAction,
  getStatementPeriodsForPropertyAccountFailAction,
  getStatementPeriodsForPropertyAccountSuccessAction,
  getTenantStatementByLeaseIdAction,
  getTenantStatementByLeaseIdFailAction,
  getTenantStatementByLeaseIdSuccessAction,
  markAsPendingAction,
  markAsPendingSuccessAction,
  markAsPostedAction,
  markAsPendingOrPostedFailAction,
  markAsPostedSuccessAction,
  journalEntryArchivingAction,
  journalEntryArchivingSuccessAction,
  removeIncomeFromAssetAction,
  removeIncomeFromAssetFailAction,
  removeIncomeFromAssetSuccessAction,
  updateAssetIncomeAction,
  updateAssetIncomeFailAction,
  updateAssetIncomeSuccessAction,
  journalEntryArchivingFailAction,
  bulkDepositJournalEntriesAction,
  bulkDepositJournalEntriesSuccessAction,
  bulkDepositJournalEntriesFailAction,
  changeDepositAccountAction,
  changeDepositAccountSuccessAction,
  setSelectedIncomeAction,
  getSelectedIncomeAction,
  getStatementLineDetailByJournalEntryIdAction,
  getStatementLineDetailByJournalEntryIdSuccessAction,
  getStatementLineDetailByJournalEntryIdFailAction,
} from './statementSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  AddIncomeToAssetHandlerRequest,
  AssetType,
  ChangeDepositAccountHandlerResponse,
  GetIncomeForAssetHandlerResponse,
  GetIncomeLineDetailHandlerResponse,
  GetStatementLineDetailHandlerResponse,
  IAddIncomeToAssetHandlerRequest,
  IStatementLineDto,
  IUpdateIncomeHandlerRequest,
  JournalEntryArchivingHandlerResponse,
  LeaseStatementListHandlerResponse,
  ListPlatformStatementHandlerResponse,
  PlatformStatementDto,
  PostJournalEntryGroupHandlerRequest,
  PropertyAccountStatementListHandlerResponse,
  StatementDto,
  StatementPeriodType,
  UpdateIncomeHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { showErrorAction, showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { mapPlatformReduxStatement, mapReduxEntry, mapReduxStatement, ReduxStatement } from './statementTypes';
import { parse } from 'date-fns';
import { isPeriod, ReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

function* getStatementByLeaseId(
  action: PayloadAction<{
    leaseId: string;
    startDate: ReduxDate;
    statementPeriodType: StatementPeriodType;
    endDate?: ReduxDate;
    silent?: boolean;
    isDepositStatement?: boolean;
  }>,
) {
  try {
    const statement: StatementDto = yield apiCall(
      ApiClientSingleton.getInstance().statements_GetPropertyLeaseStatement,
      action.payload.leaseId,
      action.payload.startDate,
      action.payload.statementPeriodType,
      action.payload.endDate ?? undefined,
      action.payload.isDepositStatement ?? false,
    );
    const mappedStatement = mapReduxStatement(statement.toJSON());
    yield put(getStatementByLeaseIdSuccessAction({ statement: mappedStatement, silent: action.payload.silent }));
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem getting statement' }));
    yield put(getStatementByLeaseIdFailAction());
  }
}

function* getStatementMonthsByLeaseId(
  action: PayloadAction<{ leaseId: string; statementPeriodType: StatementPeriodType }>,
) {
  try {
    const response: LeaseStatementListHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().statements_GetLeaseStatementList,
      action.payload.leaseId,
      action.payload.statementPeriodType,
    );
    if (response.rentPeriods) {
      const periods = response.rentPeriods.filter(isPeriod);
      yield put(getStatementPeriodsByLeaseIdSuccessAction(periods));
    } else {
      yield put(showToastMessageAction({ message: 'No statement months available', severity: 'error' }));
    }
  } catch (error) {
    yield put(getStatementPeriodsByLeaseIdFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem getting statement months' }));
  }
}

function* getStatementMonthsForPropertyAccount(
  action: PayloadAction<{ propertyId: string; accountId: string; periodType?: StatementPeriodType; isPopup?: boolean }>,
) {
  try {
    const response: PropertyAccountStatementListHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().statements_GetPropertyAccountStatementList,
      action.payload.propertyId,
      action.payload.accountId,
      action.payload.periodType ?? StatementPeriodType.Month,
    );
    if (response.periods) {
      const periods = response.periods.filter(isPeriod);
      yield put(
        getStatementPeriodsForPropertyAccountSuccessAction({ periods: periods, isPopup: action.payload.isPopup }),
      );
    } else {
      yield put(showToastMessageAction({ message: 'No statement months available', severity: 'error' }));
    }
  } catch (error) {
    yield put(getStatementPeriodsForPropertyAccountFailAction({ isPopup: action.payload.isPopup }));
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting statement months' }));
  }
}

function* getPropertyAccountStatement(
  action: PayloadAction<{
    propertyId: string;
    accountId: string;
    periodType: StatementPeriodType;
    startDate: ReduxDate;
    endDate?: ReduxDate;
    includeArchived?: boolean;
    isPopup?: boolean;
  }>,
) {
  try {
    const statement: StatementDto = yield apiCall(
      ApiClientSingleton.getInstance().statements_GetPropertyAccountStatement,
      action.payload.propertyId,
      action.payload.accountId,
      action.payload.startDate,
      action.payload.periodType,
      action.payload.endDate ?? undefined,
      action.payload.includeArchived,
    );
    const mappedStatement = mapReduxStatement(statement.toJSON());
    yield put(
      getPropertyAccountStatementSuccessAction({ statement: mappedStatement, isPopup: action.payload.isPopup }),
    );
  } catch (error) {
    yield put(getPropertyAccountStatementFailAction({ isPopup: action.payload.isPopup }));
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting statement' }));
  }
}

function* getTenantStatementById(action: PayloadAction<{ leaseId: string; periodStart: ReduxDate }>) {
  try {
    const response: StatementDto = yield apiCall(
      ApiClientSingleton.getInstance().statements_GetTenantStatement,
      action.payload.leaseId,
      action.payload.periodStart,
    );
    const mappedStatement: ReduxStatement = mapReduxStatement(response.toJSON());
    yield put(getTenantStatementByLeaseIdSuccessAction(mappedStatement));
  } catch (error: any) {
    yield put(getTenantStatementByLeaseIdFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting tenant statement' }));
  }
}

function* getAssetIncome(
  action: PayloadAction<{
    assetId: string;
    assetType: AssetType;
    includeRentalIncome: boolean;
    includeArchived: boolean;
    includeChildAssets: boolean;
    startDate: ReduxDate;
    endDate: ReduxDate;
    page: number;
    pageSize: number;
    orderBy: keyof IStatementLineDto | undefined;
    ascending: boolean;
  }>,
) {
  try {
    const response: GetIncomeForAssetHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().statements_GetIncomeStatement,
      action.payload.assetId,
      action.payload.assetType,
      action.payload.startDate,
      action.payload.endDate,
      action.payload.includeRentalIncome,
      action.payload.includeArchived,
      action.payload.includeChildAssets,
      action.payload.page,
      action.payload.pageSize,
      action.payload.orderBy,
      action.payload.ascending,
    );
    if (response) {
      yield put(
        getIncomeForAssetSuccessAction({
          income: response.toJSON().incomeLines ?? [],
          totalCount: response.toJSON().count,
        }),
      );
    }
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting income for property' }));
    yield put(getIncomeForAssetFailAction());
  }
}

function* getSelectedIncome(action: PayloadAction<string>) {
  try {
    const response: GetIncomeLineDetailHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().statements_GetIncomeLineDetail,
      action.payload,
    );
    yield put(setSelectedIncomeAction(response.toJSON().incomeEntry));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting selected income' }));
  }
}

function* getPlatformPeriods(action: PayloadAction<{ propertyId: string }>) {
  try {
    const response: ListPlatformStatementHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().statements_ListPlatformStatementMonths,
      action.payload.propertyId,
    );
    const periods = response.rentPeriods?.filter(isPeriod);
    yield put(getPlatformPeriodsSuccessAction(periods ?? []));
  } catch (error: any) {
    yield put(getPlatformPeriodsFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Could not get platform statement periods' }));
  }
}

function* markAsPosted(
  action: PayloadAction<{
    journalEntryId: string;
    datePosted: ReduxDate;
    isPopup?: boolean;
    isIncomeStatement?: boolean;
  }>,
) {
  try {
    const { journalEntryId, datePosted } = action.payload;
    yield apiCall(
      ApiClientSingleton.getInstance().statements_PostJournalEntry,
      journalEntryId,
      toStandardDate(datePosted),
    );
    yield put(showToastMessageAction({ message: 'Line marked as posted', severity: 'success' }));
    yield put(
      markAsPostedSuccessAction({
        journalEntryId: journalEntryId,
        datePosted: datePosted,
        isPopup: action.payload.isPopup,
        isIncomeStatement: action.payload.isIncomeStatement,
      }),
    );
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not mark line as posted' }));
    yield put(
      markAsPendingOrPostedFailAction({
        isPopup: action.payload.isPopup,
        isIncomeStatement: action.payload.isIncomeStatement,
      }),
    );
  }
}

function* archivingJournalEntry(
  action: PayloadAction<{
    journalEntryId: string;
    isArchiving: string;
    isIncome?: boolean;
  }>,
) {
  try {
    const { journalEntryId, isArchiving } = action.payload;
    const newStatus: JournalEntryArchivingHandlerResponse = isArchiving
      ? yield apiCall(ApiClientSingleton.getInstance().statements_VoidJournalEntry, journalEntryId)
      : yield apiCall(ApiClientSingleton.getInstance().statements_UnVoidJournalEntry, journalEntryId);

    if (newStatus) {
      yield put(showToastMessageAction({ message: 'Transaction changed', severity: 'success' }));
      yield put(
        journalEntryArchivingSuccessAction({
          journalEntryId: journalEntryId,
          updatedJournalEntryStatus: newStatus.updatedJournalEntryStatus!,
          isIncome: action.payload.isIncome,
        }),
      );
    } else throw new Error("Didn't receive response from the server.");
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not modify transaction.' }));
    yield put(journalEntryArchivingFailAction({ isIncome: action.payload.isIncome }));
  }
}

function* markAsPending(
  action: PayloadAction<{
    journalEntryId: string;
    isPopup?: boolean;
    isIncomeStatement?: boolean;
  }>,
) {
  try {
    const { journalEntryId } = action.payload;
    yield apiCall(ApiClientSingleton.getInstance().statements_UnPostJournalEntry, journalEntryId);
    yield put(showToastMessageAction({ message: 'Line marked as pending deposit', severity: 'success' }));
    yield put(
      markAsPendingSuccessAction({
        journalEntryId: journalEntryId,
        isPopup: action.payload.isPopup,
        isIncomeStatement: action.payload.isIncomeStatement,
      }),
    );
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not mark line as pending deposit' }));
    yield put(
      markAsPendingOrPostedFailAction({
        isPopup: action.payload.isPopup,
        isIncomeStatement: action.payload.isIncomeStatement,
      }),
    );
  }
}

function* getPlatformStatement(action: PayloadAction<{ propertyId: string; monthYear: string }>) {
  try {
    const date = parse(action.payload.monthYear, 'MMMM yyyy', new Date());
    const response: PlatformStatementDto = yield apiCall(
      ApiClientSingleton.getInstance().statements_GetPlatformStatement,
      action.payload.propertyId,
      date,
    );
    const reduxStatement = mapPlatformReduxStatement(response);
    yield put(getPlatformStatementSuccessAction(reduxStatement));
  } catch (error: any) {
    yield put(getPlatformStatementFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Could not get platform statement' }));
  }
}

function* addIncome(
  action: PayloadAction<{ assetId: string; assetType: AssetType; body: IAddIncomeToAssetHandlerRequest }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().statements_AddIncome,
      action.payload.assetId,
      action.payload.assetType,
      new AddIncomeToAssetHandlerRequest(action.payload.body),
    );
    yield put(addIncomeToAssetSuccessAction());
    yield put(showToastMessageAction({ message: 'Income added successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding income' }));
    yield put(addIncomeToAssetFailAction());
  }
}

function* updateIncome(
  action: PayloadAction<{
    journalEntryId: string;
    body: IUpdateIncomeHandlerRequest;
  }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().statements_UpdateIncome,
      action.payload.journalEntryId,
      new UpdateIncomeHandlerRequest(action.payload.body),
    );
    yield put(updateAssetIncomeSuccessAction());
    yield put(showToastMessageAction({ message: 'Income updated successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating income' }));
    yield put(updateAssetIncomeFailAction());
  }
}

function* removeIncome(action: PayloadAction<{ journalEntryId: string }>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().statements_ArchiveIncome, action.payload.journalEntryId);
    yield put(removeIncomeFromAssetSuccessAction());
    yield put(showToastMessageAction({ message: 'Income removed successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem removing income' }));
    yield put(removeIncomeFromAssetFailAction());
  }
}

function* bulkDepositJournalEntries(
  action: PayloadAction<{ propertyId: string; jeIds: string[]; dateDeposited: ReduxDate; isPopup?: boolean }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().statements_PostJournalEntryGroup,
      action.payload.propertyId,
      new PostJournalEntryGroupHandlerRequest({
        journalEntryIds: action.payload.jeIds,
        datePosted: toStandardDate(action.payload.dateDeposited),
      }),
    );
    yield put(
      bulkDepositJournalEntriesSuccessAction({
        jeIds: action.payload.jeIds,
        dateDeposited: action.payload.dateDeposited,
        isPopup: action.payload.isPopup,
      }),
    );
    yield put(showToastMessageAction({ message: 'Journal entries deposited successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(bulkDepositJournalEntriesFailAction({ isPopup: action.payload.isPopup }));
    yield put(showErrorAction({ error, fallbackMessage: 'Problem depositing journal entries' }));
  }
}

function* changeDepositAccount(
  action: PayloadAction<{
    jeId: string;
    newDepositAccountId: string;
    isPopup?: boolean;
    isBankAccountStatement?: boolean;
    isIncomeStatement?: boolean;
  }>,
) {
  try {
    const response: ChangeDepositAccountHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().statements_ChangeDepositAccount,
      action.payload.jeId,
      action.payload.newDepositAccountId,
    );
    yield put(showToastMessageAction({ message: 'Deposit account changed successfully', severity: 'success' }));
    yield put(
      changeDepositAccountSuccessAction({
        jeId: action.payload.jeId,
        newDepositAcct: response.toJSON().newDepositAccount,
        isPopup: action.payload.isPopup,
        isBankAccountStatement: action.payload.isBankAccountStatement,
        isIncomeStatement: action.payload.isIncomeStatement,
      }),
    );
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem changing deposit account' }));
    yield put(
      markAsPendingOrPostedFailAction({
        isPopup: action.payload.isPopup,
        isIncomeStatement: action.payload.isIncomeStatement,
      }),
    );
  }
}

function* getStatementLineDetail(action: PayloadAction<string>) {
  try {
    const response: GetStatementLineDetailHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().statements_GetStatementLineDetail,
      action.payload,
    );
    yield put(
      getStatementLineDetailByJournalEntryIdSuccessAction(response.toJSON().statementLineDetails.map(mapReduxEntry)),
    );
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting statement line detail' }));
    yield put(getStatementLineDetailByJournalEntryIdFailAction());
  }
}

export function* statementSagas() {
  yield all([
    takeLatest(getStatementByLeaseIdAction.type, getStatementByLeaseId),
    takeLatest(getStatementPeriodsByLeaseIdAction.type, getStatementMonthsByLeaseId),
    takeLatest(getStatementPeriodsForPropertyAccountAction.type, getStatementMonthsForPropertyAccount),
    takeLatest(getPropertyAccountStatementAction.type, getPropertyAccountStatement),
    takeLatest(getTenantStatementByLeaseIdAction.type, getTenantStatementById),
    takeLatest(getIncomeForAssetAction.type, getAssetIncome),
    takeLatest(getPlatformPeriodsAction.type, getPlatformPeriods),
    takeLatest(getPlatformStatementAction.type, getPlatformStatement),
    takeLatest(markAsPostedAction.type, markAsPosted),
    takeLatest(markAsPendingAction.type, markAsPending),
    takeLatest(journalEntryArchivingAction.type, archivingJournalEntry),
    takeLatest(addIncomeToAssetAction.type, addIncome),
    takeLatest(updateAssetIncomeAction.type, updateIncome),
    takeLatest(removeIncomeFromAssetAction.type, removeIncome),
    takeLatest(bulkDepositJournalEntriesAction.type, bulkDepositJournalEntries),
    takeLatest(changeDepositAccountAction.type, changeDepositAccount),
    takeLatest(getSelectedIncomeAction.type, getSelectedIncome),
    takeLatest(getStatementLineDetailByJournalEntryIdAction.type, getStatementLineDetail),
  ]);
}
