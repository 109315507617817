import React, { FC } from 'react';
import { IUnitInfo } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Stack from '@mui/material/Stack';
import { StyledInfoBox } from '../../../_shared/styledComponents/StyledInfoBox';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

export type ListingInfoFormProps = {
  editingListing: IUnitInfo;
  onEditListing: (listing: IUnitInfo) => void;
};

export const ListingInfoForm: FC<ListingInfoFormProps> = ({ editingListing, onEditListing }) => {
  return (
    <Stack>
      <StyledInfoBox label={'Info'}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              variant={'outlined'}
              label={'Bedrooms'}
              type={'number'}
              value={editingListing?.bedrooms ?? 0}
              onChange={(e) =>
                onEditListing({
                  ...editingListing,
                  bedrooms: Number(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              variant={'outlined'}
              label={'Bathrooms'}
              type={'number'}
              value={editingListing?.bathrooms ?? 0}
              onChange={(e) =>
                onEditListing({
                  ...editingListing,
                  bathrooms: Number(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              variant={'outlined'}
              label={'Square Footage'}
              type={'number'}
              value={editingListing?.squareFootage ?? 0}
              onChange={(e) =>
                onEditListing({
                  ...editingListing,
                  squareFootage: Number(e.target.value),
                })
              }
            />
          </Grid>
        </Grid>
      </StyledInfoBox>
    </Stack>
  );
};
