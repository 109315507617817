import React, { FC } from 'react';
import { CustomTreeItemContent } from './customTreeItemContent/customTreeItemContent';
import { UnitTreeItem } from './UnitTreeItem';
import { IAssetNode } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { StyledTreeItem } from './StyledTreeItem';
import { convertAssetTypeToAssetNodeType } from '../../../../_shared/paymentProcessing/authorizeNet/helpers';

type BuildingItemProps = {
  value: IAssetNode;
  onOpenUnit: () => void;
  onOpenRoom: () => void;
};

export const BuildingTreeItem: FC<BuildingItemProps> = ({ value, onOpenUnit, onOpenRoom }) => {
  if (value && value.id) {
    return (
      <StyledTreeItem
        ContentComponent={CustomTreeItemContent}
        ContentProps={
          {
            assetName: convertAssetTypeToAssetNodeType(value.assetNodeType),
            onOpen: onOpenUnit,
            isSimulated: value.isSimulated,
            isArchived: value.isArchived,
          } as any
        }
        key={value.id}
        itemId={value.id}
        label={value.name}
      >
        {value.children?.map((u) => <UnitTreeItem key={u.id} value={u} onOpenRoom={onOpenRoom} />)}
      </StyledTreeItem>
    );
  } else {
    return <></>;
  }
};
