import React, { FC } from 'react';
import { IListingRentFee } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import IconButton from '@mui/material/IconButton';
import { useIcons } from '../../../../_shared/icons/useIcons';
import TextField from '@mui/material/TextField';
import { NumberValueTextField } from '../../../../_shared/texfields/NumberValueTextField';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import { updateListingAction, useListings } from '../../../redux/listingSlice';

export const AdditionalFees: FC = () => {
  const dispatch = useDispatch();
  const { selectedListing } = useListings();
  const { ActionType, getActionIcon } = useIcons();
  const fees = selectedListing.value?.fees || [];
  const [newFee, setNewFee] = React.useState<IListingRentFee>({
    description: '',
    amount: 0,
  });

  const addFee = () => {
    selectedListing.value?.id &&
      dispatch(
        updateListingAction({
          id: selectedListing.value.id,
          body: { ...selectedListing.value, fees: [...fees, newFee] },
        }),
      );
    setNewFee({ description: '', amount: 0 });
  };

  const removeFee = (index: number) => {
    const newFees = fees.filter((fee, i) => i !== index);
    selectedListing.value?.id &&
      dispatch(
        updateListingAction({
          id: selectedListing.value.id,
          body: { ...selectedListing.value, fees: newFees },
        }),
      );
  };

  return (
    <Stack spacing={1}>
      {fees.map((fee, index) => (
        <Stack direction={'row'} key={index}>
          <NumberValueTextField
            fullWidth
            valueUnits={'dollars'}
            InputProps={{ readOnly: true }}
            value={fee.amount}
            label={fee.description}
          />
          <IconButton onClick={() => removeFee(index)}>{getActionIcon(ActionType.Delete)}</IconButton>
        </Stack>
      ))}
      <Stack direction={'row'} spacing={1}>
        <TextField
          fullWidth
          label={'Fee Description'}
          variant={'standard'}
          type="text"
          value={newFee.description}
          onChange={(e) => setNewFee({ ...newFee, description: e.target.value })}
        />
        <NumberValueTextField
          fullWidth
          label={'Fee Amount'}
          variant={'standard'}
          valueUnits={'dollars'}
          value={newFee.amount}
          onChange={(e) => {
            setNewFee({ ...newFee, amount: parseFloat(e.target.value) });
          }}
        />
        <IconButton onClick={addFee}>{getActionIcon(ActionType.Add)}</IconButton>
      </Stack>
    </Stack>
  );
};
