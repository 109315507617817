import React, { FC, useEffect, useState } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { IGetUsersForAssetHandlerUserRoleInfo } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useDispatch } from 'react-redux';

export type UserAutocompleteProps = {
  assetId: string;
  assetType: string;
  value?: string;
  onChange: (value?: string) => void;
} & Omit<
  AutocompleteProps<IGetUsersForAssetHandlerUserRoleInfo, false, false, false>,
  'options' | 'value' | 'onChange' | 'getOptionLabel'
>;

export const UserAutocomplete: FC<UserAutocompleteProps> = ({
  assetId,
  assetType,
  value,
  onChange,
  ...autocompleteProps
}) => {
  const dispatch = useDispatch();
  const [propertyUsers, setPropertyUsers] = useState<IGetUsersForAssetHandlerUserRoleInfo[]>([]);
  const [internalValue, setInternalValue] = useState<IGetUsersForAssetHandlerUserRoleInfo | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const response = await ApiClientSingleton.getInstance().user_GetUsersForProperty(assetId, assetType);
        if (response) {
          setPropertyUsers(response.toJSON().users);
          const currentUser = value ? response.users?.find((x) => x.id === value) : null;
          setInternalValue(currentUser ?? null);
          setLoading(false);
        }
      } catch {
        setLoading(false);
        dispatch(showToastMessageAction({ message: 'Error loading asset users', severity: 'error' }));
      }
    })();
  }, [assetType, assetId, value]);
  const handleChange = (event: React.SyntheticEvent, newValue: IGetUsersForAssetHandlerUserRoleInfo | null) => {
    onChange(newValue?.id);
    setInternalValue(newValue);
  };
  return (
    <Autocomplete
      fullWidth
      options={propertyUsers}
      loading={loading}
      onChange={handleChange}
      value={internalValue ?? null}
      getOptionLabel={(option: IGetUsersForAssetHandlerUserRoleInfo) => `${option.firstName} ${option.lastName}`}
      {...autocompleteProps}
    />
  );
};
