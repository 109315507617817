import React, { FC, useEffect, useState } from 'react';
import { USStatesSelect } from './USStateSelect';
import { DriversLicenseNumberInput } from './DriversLicenseNumberInput';
import { licenseFormats } from '../utils/licenseFormats';
import Stack from '@mui/material/Stack';

type DriversLicenseProps = {
  state?: string;
  licenseNumber?: string;
  onChangeState: (state: string) => void;
  onChangeLicenseNumber: (licenseNumber: string) => void;
};

export const DriversLicense: FC<DriversLicenseProps> = ({
  state,
  licenseNumber,
  onChangeLicenseNumber,
  onChangeState,
}) => {
  const [pattern, setPattern] = useState<{ regex: RegExp; formatString: string }>();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (state) {
      const pattern = licenseFormats[state];
      setPattern(pattern);
      licenseNumber && handleIsValid(licenseNumber, pattern.regex);
    }
  }, [state]);

  const handleBlur = () => {
    if (licenseNumber && pattern) {
      handleIsValid(licenseNumber, pattern.regex);
    }
  };

  const handleIsValid = (licenseNumber: string, regex: RegExp) => {
    const isValid = regex.test(licenseNumber);
    if (isValid) {
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <Stack spacing={2} direction={'row'}>
      <USStatesSelect label={"Driver's License State"} value={state ?? ''} onChange={onChangeState} />
      <DriversLicenseNumberInput
        value={licenseNumber}
        onChange={(e) => onChangeLicenseNumber(e.target.value)}
        pattern={pattern}
        fullWidth
        label={'Driver License Number'}
        onBlur={handleBlur}
        error={error}
        disabled={!state}
      />
    </Stack>
  );
};
