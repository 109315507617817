import React, { FormEvent, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { IPostalAddress } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  updatePropertyMailingAddressAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReduxRentalProperty } from '@monkeyjump-labs/cam-fe-shared/dist/types/propertyTypes';

type Props = {
  property: ReduxRentalProperty;
};

export const PropertyAddressInfo = ({ property }: Props) => {
  const dispatch = useDispatch();
  const { selectedProperty } = useProperty();
  const [dirty, setDirty] = useState<boolean>(false);
  const [updatedMailingAddress, setUpdatedMailingAddress] = useState(property.details?.mailingAddress);
  const [propertyName, setPropertyName] = useState(property.name);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (property.id && updatedMailingAddress && propertyName) {
      dispatch(
        updatePropertyMailingAddressAction({
          propertyId: property.id,
          name: propertyName,
          details: { mailingAddress: updatedMailingAddress },
        }),
      );
      setDirty(false);
    } else return;
  };

  const handleAddressChange = (value: string, key: keyof IPostalAddress) => {
    setUpdatedMailingAddress({ ...updatedMailingAddress, [key]: value });
    setDirty(true);
  };

  const handleNameChange = (value: string) => {
    setPropertyName(value);
    setDirty(true);
  };

  if (property && property.details && property.details.mailingAddress) {
    return (
      <StyledInfoBox label={'Property Address'}>
        <form onSubmit={onSubmit} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="name"
                label="Property Name"
                type="text"
                defaultValue={propertyName}
                margin="dense"
                variant="standard"
                fullWidth
                onChange={(e) => {
                  handleNameChange(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="streetAddress1"
                label="Street Address 1"
                type="text"
                defaultValue={property.details.mailingAddress.streetAddress1}
                margin="dense"
                variant="standard"
                fullWidth
                onChange={(e) => {
                  handleAddressChange(e.target.value, 'streetAddress1');
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="streetAddress2"
                label="Street Address 2"
                type="text"
                defaultValue={property.details.mailingAddress.streetAddress2}
                margin="dense"
                variant="standard"
                fullWidth
                onChange={(e) => {
                  handleAddressChange(e.target.value, 'streetAddress2');
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="city"
                label="City"
                type="text"
                defaultValue={property.details.mailingAddress.city}
                margin="dense"
                variant="standard"
                fullWidth
                onChange={(e) => {
                  handleAddressChange(e.target.value, 'city');
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="state"
                label="State"
                type="text"
                defaultValue={property.details.mailingAddress.state}
                margin="dense"
                variant="standard"
                fullWidth
                onChange={(e) => {
                  handleAddressChange(e.target.value, 'state');
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="zip"
                label="Zip Code"
                type="number"
                defaultValue={property.details.mailingAddress.zip}
                margin="dense"
                variant="standard"
                fullWidth
                onChange={(e) => {
                  handleAddressChange(e.target.value, 'zip');
                }}
              />
            </Grid>
            <Grid item container xs={8} justifyContent="right">
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={!dirty}
                sx={{ my: '1rem' }}
                loading={selectedProperty.submitting}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </StyledInfoBox>
    );
  } else return <></>;
};
