import React, { FC, useEffect, useState } from 'react';
import { StripedDataGrid } from '../../../datagrids/StripedDataGrid';
import {
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import {
  IAccountRef,
  JournalEntryStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useStatement } from '../../redux/statementSlice';
import { tryFormatDate } from '../../../utils/TryFormatDate';
import { OpenEditCell } from '../../../datagrids/OpenEditCell';
import { ReduxEntry } from '../../redux/statementTypes';
import Chip from '@mui/material/Chip';
import { useStatementConfig } from '../../useStatementConfig';

type IncomeTableProps = {
  rows: ReduxEntry[];
  totalCount: number;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  onSortModelChange: (model: GridSortModel) => void;
  onEditIncome: (row: ReduxEntry) => void;
  isGrouped?: boolean;
  onOpenPopupStatement?: (statementLine?: ReduxEntry) => void;
  onDepositClick: (jeId: string) => void;
  onChangeDeposit: (jeId: string) => void;
  onRollbackDeposit: (jeId: string) => void;
};

export const IncomeTable: FC<IncomeTableProps> = ({
  rows,
  totalCount,
  onSortModelChange,
  pageSize,
  setPageSize,
  page,
  setPage,
  onEditIncome,
  isGrouped,
  onOpenPopupStatement,
  onDepositClick,
  onChangeDeposit,
  onRollbackDeposit,
}) => {
  const { incomeStatement } = useStatement();
  const { createActions } = useStatementConfig();
  const [rowGroupingModel, setRowGroupingModel] = useState<string[]>([]);
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (isGrouped) {
      setRowGroupingModel(['assetPath']);
    } else setRowGroupingModel([]);
  }, [isGrouped]);

  const columns: GridColDef<ReduxEntry>[] = [
    {
      field: 'date',
      headerName: 'Date',
      display: 'flex',

      flex: 1,
      renderCell: (params) => <>{tryFormatDate(params.value)}</>,
    },
    {
      field: 'description',
      headerName: 'Description',
      display: 'flex',

      flex: 2,
      renderCell: (params) => {
        const value = params.value
          ? params.value + (params.row.paymentNo ? `: Check No ${params.row.paymentNo}` : '')
          : undefined;
        if (params.row.leaseId) return value;
        return <OpenEditCell onClick={() => onEditIncome(params.row)} displayValue={value} {...params} />;
      },
    },
    {
      field: 'depositAccount',
      headerName: 'Bank Account',
      type: 'string',
      flex: 2,
      display: 'flex',
      editable: false,
      valueGetter: (value: IAccountRef | undefined) => value?.name,
      renderCell: (params: GridRenderCellParams<ReduxEntry, IAccountRef>) => {
        if (!params.row.depositAccount) return null;
        const label = params.row.depositAccount?.name ?? '';
        return (
          <Tooltip title={label}>
            <Chip onClick={() => onOpenPopupStatement && onOpenPopupStatement(params.row)} label={label} />
          </Tooltip>
        );
      },
    },
    {
      field: 'payer',
      headerName: 'Payer',
      flex: 1,
      display: 'flex',
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      display: 'flex',

      renderCell: (params) => (params.value ? <>${params.value?.toFixed(2)}</> : <></>),
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'assetPath',
      headerName: 'Asset',
      flex: 3,
      display: 'flex',

      sortable: false,
      renderCell: (params: GridRenderCellParams<ReduxEntry, string>) => {
        const assetPath = params.value?.split('/');
        if (!assetPath) return <></>;
        return (
          <div>
            <Tooltip title={params.value ?? ''}>
              <Typography variant={'body2'}>{assetPath[assetPath.length - 1]}</Typography>
            </Tooltip>
            {/*{assetPath[1] && <Typography variant={'body2'}>{assetPath[1]}</Typography>}*/}
            {/*{assetPath[2] && <Typography variant={'body2'}>{assetPath[2]}</Typography>}*/}
          </div>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 1,
      display: 'flex',

      align: 'left',
      getActions: (params: GridRowParams) =>
        Array.from(
          createActions(params, incomeStatement.submitting, onDepositClick, onChangeDeposit, onRollbackDeposit, true),
        ),
    },
  ];

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    rowGroupingModel,
  });

  return (
    <Box
      sx={{
        minHeight: 450,
        width: 1,
      }}
    >
      <StripedDataGrid
        disableRowGrouping
        apiRef={apiRef}
        initialState={initialState}
        autoHeight
        rows={rows}
        columns={columns}
        rowCount={totalCount}
        pagination
        paginationMode={'server'}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationModel={{ page: page, pageSize: pageSize }}
        sortingMode={'server'}
        onSortModelChange={onSortModelChange}
        onPaginationModelChange={(model: GridPaginationModel) => {
          setPage(model.page);
          setPageSize(model.pageSize);
        }}
        sx={{
          minHeight: 450,
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
        getRowId={(x) => x.journalEntryId}
        getRowClassName={(params) => {
          let className = params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
          if (params.row.status === JournalEntryStatus.Archived) {
            className += ' archived';
          }
          return className;
        }}
        loading={incomeStatement.loading}
        getRowHeight={() => 'auto'}
        rowGroupingModel={rowGroupingModel}
        onRowGroupingModelChange={(model) => setRowGroupingModel(model)}
        defaultGroupingExpansionDepth={-1}
        groupingColDef={{ flex: 3 }}
      />
    </Box>
  );
};
