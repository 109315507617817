import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  cancelUserInvitationAction,
  getInvitationsForPropertyAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { GridColDef, GridPaginationModel, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import {
  IGetPropertyUserInvitationsHandlerSingleResponse,
  ISlimUser,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { convertAccessLevelUriToReadable } from '../../../../../auth/AuthorizedClaims';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { StripedDataGrid } from '../../../../../_shared/datagrids/StripedDataGrid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';

export const InvitationTable = () => {
  const dispatch = useDispatch();
  const { selectedProperty, invitations } = useProperty();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [includeTenants, setIncludeTenants] = useState(false);

  useEffect(() => {
    selectedProperty.value?.id &&
      dispatch(
        getInvitationsForPropertyAction({
          propertyId: selectedProperty.value.id,
          includeTenants,
          page,
          pageSize,
        }),
      );
  }, [page, pageSize, includeTenants]);

  const handleCancelInvitation = (invitationId: string) => {
    selectedProperty.value?.id &&
      dispatch(
        cancelUserInvitationAction({
          propertyId: selectedProperty.value.id,
          invitationId: invitationId,
          includeTenants,
          page,
          pageSize,
        }),
      );
  };

  const columns: GridColDef<ISlimUser>[] = [
    {
      headerName: 'Email',
      field: 'email',
      flex: 2,
      editable: false,
    },
    {
      headerName: 'Access Level',
      field: 'claim',
      flex: 1,
      editable: false,
      renderCell: (params: GridRenderCellParams<ISlimUser, { type: string; value: string }>) =>
        convertAccessLevelUriToReadable(params.value?.type ?? ''),
    },
    {
      headerName: 'Actions',
      type: 'actions',
      flex: 1,
      field: 'actions',
      editable: false,
      getActions: (params: GridRowParams<IGetPropertyUserInvitationsHandlerSingleResponse>) => {
        return [
          <Tooltip key="cancel" title="Cancel Invitation">
            <IconButton onClick={() => params.row.id && handleCancelInvitation(params.row.id)}>
              <CancelIcon />
            </IconButton>
          </Tooltip>,
        ];
      },
    },
  ];

  return (
    <StyledInfoBox label={'Invitations'}>
      <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right', alignItems: 'center', pb: '1rem' }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch size="small" checked={includeTenants} onChange={() => setIncludeTenants(!includeTenants)} />
            }
            label="Include Tenants"
          />
        </FormGroup>
      </Box>
      <Box
        sx={{
          minHeight: 450,
          width: 1,
        }}
      >
        <StripedDataGrid
          disableRowGrouping
          autoHeight
          sx={{ minHeight: 450 }}
          density="compact"
          rows={invitations.value ?? []}
          loading={invitations.loading}
          columns={columns}
          pagination
          rowCount={invitations.totalCount}
          paginationMode={'server'}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(model: GridPaginationModel) => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          getRowClassName={(params) => {
            return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
          }}
        />
      </Box>
    </StyledInfoBox>
  );
};
