import React, { FC, useEffect, useState } from 'react';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { GridSortModel } from '@mui/x-data-grid-premium';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { ReduxDate, toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useDispatch } from 'react-redux';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { addDays, addMonths } from 'date-fns';
import {
  AssetType,
  ISlimAccountRef,
  IStatementEntryDto,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssetTypeFromPathname } from '../../../utils/getAssetTypeFromPathname';
import { clearSelectedIncomeAction, getIncomeForAssetAction, useStatement } from '../../redux/statementSlice';
import { SortDirection } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { IncomeTable } from './IncomeTable';
import { AccountStatementViewDialog } from '../accountStatementDialog/AccountStatementViewDialog';
import { ReduxEntry } from '../../redux/statementTypes';
import { IncomeDialog } from './IncomeDialog';
import { ChangeDepositAccountPopper } from '../ChangeDepositAccountPopper';
import { useStatementConfig } from '../../useStatementConfig';
import { MarkDepositedPopper } from '../MarkDepositedPopper';

export const IncomeTab: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const assetString = getAssetTypeFromPathname();
  const assetType =
    assetString === 'property'
      ? AssetType.RentalProperty
      : assetString === 'unit'
        ? AssetType.BuildingUnit
        : AssetType.Building;
  const {
    selectedContext: { propertyId, currentDate },
  } = useAssets();
  const { incomeStatement } = useStatement();
  const { handleChangeDeposit, handleRollbackDeposit, handleMarkAsPosted } = useStatementConfig();
  const [rows, setRows] = useState<ReduxEntry[]>([]);
  const [startDate, setStartDate] = useState<ReduxDate>(
    currentDate ? toReduxDate(addMonths(toStandardDate(currentDate), -1)) : toReduxDate(new Date()),
  );
  const [endDate, setEndDate] = useState<ReduxDate>(
    currentDate ? toReduxDate(addDays(toStandardDate(currentDate), 1)) : toReduxDate(new Date()),
  );
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [orderBy, setOrderBy] = useState<keyof IStatementEntryDto>('date');
  const [orderDirection, setOrderDirection] = useState<SortDirection | undefined>();
  const [includeRentalIncome, setIncludeRentalIncome] = useState(false);
  const [journalEntryToDepositId, setJournalEntryToDepositId] = useState<string | undefined>();
  const [includeArchived, setIncludeArchived] = useState(false);
  const [includeChildAssets, setIncludeChildAssets] = useState(false);
  const [openPopupStatement, setOpenPopupStatement] = useState(false);
  const [selectedStatementLine, setSelectedStatementLine] = useState<ReduxEntry | undefined>(undefined);
  const [openIncomeDialog, setOpenIncomeDialog] = useState(false);
  const [openDepositPopper, setOpenDepositPopper] = useState(false);
  const [openChangeDepositAccount, setOpenChangeDepositAccount] = useState(false);
  const [dateDeposited, setDateDeposited] = useState<Date | null>(currentDate ? toStandardDate(currentDate) : null);

  useEffect(() => {
    setRows(incomeStatement.value ?? []);
    setTotalCount(incomeStatement.totalCount ?? 0);
  }, [incomeStatement.value]);

  useEffect(() => {
    id &&
      dispatch(
        getIncomeForAssetAction({
          assetId: id,
          assetType: assetType,
          startDate,
          endDate,
          includeRentalIncome,
          includeArchived,
          includeChildAssets,
          page: page,
          pageSize: pageSize,
          orderBy: orderBy,
          ascending: orderDirection === 'Ascending',
        }),
      );
  }, [
    page,
    pageSize,
    startDate,
    endDate,
    includeRentalIncome,
    includeArchived,
    includeChildAssets,
    incomeStatement.submitted,
    orderBy,
    orderDirection,
  ]);

  useEffect(() => {
    if (incomeStatement.submitted) {
      setJournalEntryToDepositId(undefined);
      dispatch(clearSelectedIncomeAction());
      setOpenIncomeDialog(false);
      setOpenDepositPopper(false);
    }
  }, [incomeStatement.submitted]);

  const handleOpenAddIncome = () => {
    setOpenIncomeDialog(true);
  };

  const handleEditIncome = (row: ReduxEntry) => {
    navigate(`selectedIncome/${row.journalEntryId}`);
  };

  const handleOpenPopupStatement = (statementLine?: ReduxEntry) => {
    setSelectedStatementLine(statementLine);
    setOpenPopupStatement(true);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setOrderBy('date');
      setOrderDirection(undefined);
    } else {
      setOrderBy(model[0].field as keyof IStatementEntryDto);
      setOrderDirection(model[0].sort === 'asc' ? 'Ascending' : 'Descending');
    }
  };

  const handleChangeDepositOpen = (jeId?: string) => {
    setJournalEntryToDepositId(jeId);
    setOpenChangeDepositAccount(true);
  };

  const handleChangeDepositAction = (newDepositAccount?: ISlimAccountRef) => {
    if (journalEntryToDepositId === undefined) return;
    handleChangeDeposit(journalEntryToDepositId, newDepositAccount?.id, false, false, true);
    setOpenChangeDepositAccount(false);
  };

  const onRollbackDeposit = (jeId: string) => {
    if (!propertyId) return;
    handleRollbackDeposit(jeId, propertyId, false, true);
  };
  return (
    <StyledInfoBox label={'Income'}>
      <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center', pb: '1rem' }}>
        <Button variant={'outlined'} onClick={handleOpenAddIncome} startIcon={<Add />}>
          Add Income
        </Button>
        <Stack>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={includeRentalIncome}
                  onChange={() => setIncludeRentalIncome(!includeRentalIncome)}
                />
              }
              label="Include Rental Income"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch size="small" checked={includeArchived} onChange={() => setIncludeArchived(!includeArchived)} />
              }
              label="Include Archived Income"
            />
          </FormGroup>
          {assetType !== AssetType.BuildingUnit && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={includeChildAssets}
                    onChange={() => setIncludeChildAssets(!includeChildAssets)}
                  />
                }
                label="Include Child Assets"
              />
            </FormGroup>
          )}
        </Stack>
      </Box>
      <Stack direction={'row'}>
        <DatePicker
          onChange={(date) => date && setStartDate(toReduxDate(date))}
          value={toStandardDate(startDate)}
          label={'Start Date'}
          slotProps={{ textField: { fullWidth: true } }}
        />
        <Box minWidth={'.5rem'}></Box>
        <DatePicker
          onChange={(date) => date && setEndDate(toReduxDate(date))}
          value={toStandardDate(endDate)}
          slotProps={{ textField: { fullWidth: true } }}
          label={'End Date'}
        />
      </Stack>
      <IncomeTable
        onEditIncome={handleEditIncome}
        rows={rows}
        totalCount={totalCount}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        onSortModelChange={handleSortModelChange}
        isGrouped={includeChildAssets}
        onOpenPopupStatement={handleOpenPopupStatement}
        onDepositClick={(jeId: string) => {
          setOpenDepositPopper(true);
          setJournalEntryToDepositId(jeId);
        }}
        onChangeDeposit={handleChangeDepositOpen}
        onRollbackDeposit={onRollbackDeposit}
      />
      <MarkDepositedPopper
        open={openDepositPopper}
        onDepositClose={() => {
          setJournalEntryToDepositId(undefined);
          setOpenDepositPopper(false);
        }}
        dateDeposited={dateDeposited}
        setDateDeposited={setDateDeposited}
        onMarkAsPosted={() => handleMarkAsPosted(dateDeposited, journalEntryToDepositId, propertyId, false, true)}
      />
      <AccountStatementViewDialog
        open={openPopupStatement}
        onClose={() => setOpenPopupStatement(false)}
        isPopupStatement
        linkedGlAccountId={selectedStatementLine?.depositAccount?.id}
        highlightedStatementRow={selectedStatementLine}
      />
      <IncomeDialog open={openIncomeDialog} onClose={() => setOpenIncomeDialog(false)} />
      <ChangeDepositAccountPopper
        open={openChangeDepositAccount}
        onChangeDepositClose={() => {
          setJournalEntryToDepositId(undefined);
          setOpenChangeDepositAccount(false);
        }}
        onChangeDepositAccount={handleChangeDepositAction}
        currentDepositAccountId={
          incomeStatement.value?.find((line) => line.journalEntryId === journalEntryToDepositId)?.depositAccount?.id
        }
      />
    </StyledInfoBox>
  );
};
