import React, { FormEvent, useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { PhoneTextField } from '../../../../../_shared/texfields/PhoneTextField';
import InputAdornment from '@mui/material/InputAdornment';
import { DatePicker } from '@mui/x-date-pickers-pro';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { RentFrequency } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ReduxHousingHistory, toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { phoneUtils } from '../../../../../_shared/utils/phoneUtils';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useDispatch } from 'react-redux';
import { useApplications } from '../../../../redux/applicationSlice';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { DialogLayout } from '../../../../../_shared/dialogs/DialogLayout';

type Props = {
  open: boolean;
  onClose: () => void;
  addHousingHistory: (type: string, newDependent: ReduxHousingHistory) => void;
};

const blankHousingHistory = {
  address: {
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zip: '',
  },
  isRental: true,
  rentAmount: undefined,
  rentFrequency: RentFrequency.Monthly,
  startDate: undefined,
  endDate: undefined,
  landlord: {
    firstName: '',
    lastName: '',
    contactInfo: {
      phone: '',
      email: '',
    },
  },
  reasonForLeaving: '',
};

export const AddHousingHistoryDialog = ({ open, onClose, addHousingHistory }: Props) => {
  const dispatch = useDispatch();
  const { allApplications } = useApplications();
  const [housingHistory, setHousingHistory] = useState<ReduxHousingHistory>(blankHousingHistory);

  useEffect(() => {
    if (allApplications.submitted) handleClose();
  }, [allApplications.submitted]);

  const handleClose = () => {
    setHousingHistory(blankHousingHistory);
    onClose();
  };

  const handleAddHousingHistory = (e: FormEvent) => {
    e.preventDefault();
    const phoneIsValid =
      housingHistory.landlord?.contactInfo?.phone && phoneUtils.isValid(housingHistory.landlord?.contactInfo?.phone);
    if (!phoneIsValid) {
      dispatch(showToastMessageAction({ message: 'Please enter a valid phone number', severity: 'error' }));
    } else {
      const newHousingHistory = {
        ...housingHistory,
        rentAmount: Number(housingHistory.rentAmount),
        startDate: toReduxDate(housingHistory.startDate),
        endDate: toReduxDate(housingHistory.endDate),
      };
      addHousingHistory('housingHistory', newHousingHistory);
      setHousingHistory(blankHousingHistory);
    }
  };
  return (
    <DialogLayout open={open} onClose={handleClose} maxWidth="md" title={'Add Housing History'}>
      <form onSubmit={handleAddHousingHistory} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                fullWidth
                required
                margin="dense"
                id="streetAddress1"
                label="Street Address 1"
                type="string"
                variant="standard"
                value={housingHistory.address?.streetAddress1 ? housingHistory.address?.streetAddress1 : ''}
                onChange={(e) =>
                  setHousingHistory({
                    ...housingHistory,
                    address: { ...housingHistory.address, streetAddress1: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="dense"
                id="streetAddress2"
                label="Street Address 2"
                type="text"
                variant="standard"
                value={housingHistory.address?.streetAddress2 ? housingHistory.address?.streetAddress2 : ''}
                onChange={(e) =>
                  setHousingHistory({
                    ...housingHistory,
                    address: { ...housingHistory.address, streetAddress2: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required
                margin="dense"
                id="city"
                label="City"
                type="text"
                variant="standard"
                value={housingHistory.address?.city ? housingHistory.address?.city : ''}
                onChange={(e) =>
                  setHousingHistory({
                    ...housingHistory,
                    address: { ...housingHistory.address, city: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required
                margin="dense"
                id="state"
                label="State"
                type="text"
                variant="standard"
                value={housingHistory.address?.state ? housingHistory.address?.state : ''}
                onChange={(e) =>
                  setHousingHistory({
                    ...housingHistory,
                    address: { ...housingHistory.address, state: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required
                margin="dense"
                id="zip"
                label="Zip Code"
                type="number"
                variant="standard"
                value={housingHistory.address?.zip ? housingHistory.address?.zip : ''}
                onChange={(e) =>
                  setHousingHistory({
                    ...housingHistory,
                    address: { ...housingHistory.address, zip: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                label={'Start Date'}
                slotProps={{ field: { clearable: true }, textField: { required: true, fullWidth: true } }}
                value={toStandardDate(housingHistory.startDate) ?? null}
                onChange={(e) => {
                  e && setHousingHistory({ ...housingHistory, startDate: toReduxDate(e) });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                label={'End Date'}
                slotProps={{ field: { clearable: true }, textField: { required: true, fullWidth: true } }}
                value={toStandardDate(housingHistory.endDate) ?? null}
                onChange={(e) => {
                  e && setHousingHistory({ ...housingHistory, endDate: toReduxDate(e) });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required
                margin="dense"
                id="reasonForLeaving"
                label="Reason for Leaving"
                type="text"
                variant="standard"
                value={housingHistory.reasonForLeaving ? housingHistory.reasonForLeaving : ''}
                onChange={(e) =>
                  setHousingHistory({
                    ...housingHistory,
                    reasonForLeaving: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={housingHistory.isRental}
                    onChange={(e) => {
                      setHousingHistory({ ...housingHistory, isRental: e.target.checked });
                    }}
                  />
                }
                label="I rented this home"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required
                disabled={!housingHistory.isRental}
                margin="dense"
                id="rentAmount"
                label="Rent Amount"
                type="number"
                variant="standard"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                value={housingHistory.rentAmount ? housingHistory.rentAmount : ''}
                onChange={(e) =>
                  setHousingHistory({
                    ...housingHistory,
                    rentAmount: Number(e.target.value),
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth disabled={!housingHistory.isRental} required>
                <InputLabel id="rent-frequency">Rent Frequency</InputLabel>
                <Select
                  labelId="rent-frequency"
                  id="rentFrequency"
                  value={housingHistory.rentFrequency ? housingHistory.rentFrequency : ''}
                  label="Rent Frequency"
                  onChange={(e) => {
                    switch (e.target.value) {
                      case 'Weekly':
                        setHousingHistory({
                          ...housingHistory,
                          rentFrequency: RentFrequency.Weekly,
                        });
                        break;
                      case 'Monthly':
                        setHousingHistory({
                          ...housingHistory,
                          rentFrequency: RentFrequency.Monthly,
                        });
                        break;
                      case 'Biweekly':
                        setHousingHistory({
                          ...housingHistory,
                          rentFrequency: RentFrequency.Biweekly,
                        });
                        break;
                      case 'Annual':
                        setHousingHistory({
                          ...housingHistory,
                          rentFrequency: RentFrequency.Annual,
                        });
                        break;
                      default:
                        setHousingHistory({
                          ...housingHistory,
                          rentFrequency: RentFrequency.Monthly,
                        });
                    }
                  }}
                >
                  <MenuItem value={RentFrequency.Weekly}>Weekly</MenuItem>
                  <MenuItem value={RentFrequency.Biweekly}>Bi-weekly</MenuItem>
                  <MenuItem value={RentFrequency.Monthly}>Monthly</MenuItem>
                  <MenuItem value={RentFrequency.Annual}>Yearly</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                disabled={!housingHistory.isRental}
                margin="dense"
                id="landlordFirstName"
                label="Landlord First Name"
                type="text"
                variant="standard"
                value={housingHistory.landlord?.firstName ? housingHistory.landlord.firstName : ''}
                onChange={(e) =>
                  setHousingHistory({
                    ...housingHistory,
                    landlord: { ...housingHistory.landlord, firstName: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                disabled={!housingHistory.isRental}
                margin="dense"
                id="landlordName"
                label="Landlord Last Name"
                type="text"
                variant="standard"
                value={housingHistory.landlord?.lastName ? housingHistory.landlord.lastName : ''}
                onChange={(e) =>
                  setHousingHistory({
                    ...housingHistory,
                    landlord: { ...housingHistory.landlord, lastName: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <PhoneTextField
                fullWidth
                required
                disabled={!housingHistory.isRental}
                margin="dense"
                id="landlordPhone"
                label="Landlord Phone"
                variant="standard"
                value={housingHistory.landlord?.contactInfo?.phone ? housingHistory.landlord?.contactInfo?.phone : ''}
                onChange={(e) =>
                  setHousingHistory({
                    ...housingHistory,
                    landlord: {
                      ...housingHistory.landlord,
                      contactInfo: {
                        ...housingHistory.landlord?.contactInfo,
                        phone: e.target.value,
                      },
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled={!housingHistory.isRental}
                margin="dense"
                id="landlordEmail"
                label="Landlord Email"
                type="email"
                variant="standard"
                value={housingHistory.landlord?.contactInfo?.email ? housingHistory.landlord?.contactInfo?.email : ''}
                onChange={(e) =>
                  setHousingHistory({
                    ...housingHistory,
                    landlord: {
                      ...housingHistory.landlord,
                      contactInfo: {
                        ...housingHistory.landlord?.contactInfo,
                        email: e.target.value,
                      },
                    },
                  })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <LoadingButton type="submit" variant="contained" loading={allApplications.submitting}>
            Add Housing History
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};
