import { all, put, race, take, takeLatest } from 'redux-saga/effects';
import {
  cancelCreateRoomAction,
  cancelCreateRoomConfirmedAction,
  cancelUpdateRoomAction,
  cancelUpdateRoomConfirmedAction,
  createRoomAction,
  createRoomFailAction,
  createRoomSuccessAction,
  deleteRoomAction,
  deleteRoomFailAction,
  deleteRoomSuccessAction,
  getRoomByIdAction,
  getRoomByIdFailAction,
  getRoomByIdSuccessAction,
  updateRoomAction,
  updateRoomFailAction,
  updateRoomSuccessAction,
} from './roomSlice';
import { roomData } from './roomData';
import {
  cancelConfirmDialogAction,
  okConfirmDialogAction,
  showConfirmDialogAction,
  showErrorAction,
  showToastMessageAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  AddRoomHandlerRequest,
  AddRoomHandlerResponse,
  IAddRoomHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { getAllAssetsAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { push } from 'redux-first-history';

function* getRoomById() {
  try {
    yield put(getRoomByIdSuccessAction(roomData));
  } catch {
    yield put(getRoomByIdFailAction());
  }
}

function* createRoom(action: PayloadAction<IAddRoomHandlerRequest>) {
  try {
    const createRoom: AddRoomHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().rooms_Create,
      new AddRoomHandlerRequest(action.payload),
    );
    if (createRoom) {
      yield put(createRoomSuccessAction({ ...createRoom.toJSON() }));
      yield put(showToastMessageAction({ message: 'Room created successfully!', severity: 'success' }));
      yield put(getAllAssetsAction());
      yield put(push(`/assets/room/${createRoom.id}`));
    } else {
      yield put(showToastMessageAction({ message: 'Whoops! Something went wrong :(', severity: 'error' }));
    }
  } catch (error: any) {
    //TODO: type error
    yield put(createRoomFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem creating room' }));
  }
}

function* cancelCreateRoom() {
  yield put(
    showConfirmDialogAction({
      message: 'If you close without saving, any edits you have made will be lost.',
      okText: 'Close without saving',
      cancelText: 'continue to add room',
    }),
  );
  const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
  if (yes) {
    yield put(cancelCreateRoomConfirmedAction());
  }
}

function* deleteRoom(action: PayloadAction<string>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().rooms_Delete, action.payload);
    yield put(deleteRoomSuccessAction());
    yield put(showToastMessageAction({ message: 'Room deleted/archived successfully!', severity: 'success' }));
    yield put(getAllAssetsAction());
  } catch (error: any) {
    yield put(deleteRoomFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem deleting/archiving room' }));
  }
}

function* updateRoom() {
  try {
    yield put(updateRoomSuccessAction(roomData));
    yield put(showToastMessageAction({ message: 'Room updated successfully!', severity: 'success' }));
  } catch {
    yield put(updateRoomFailAction());
    yield put(showToastMessageAction({ message: 'Whoops! Something went wrong :(', severity: 'error' }));
  }
}

function* cancelUpdateRoom() {
  yield put(
    showConfirmDialogAction({
      message: 'If you close without saving, any edits you have made will be lost.',
      okText: 'Close without saving',
      cancelText: 'continue to edit room',
    }),
  );
  const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
  if (yes) {
    yield put(cancelUpdateRoomConfirmedAction());
  }
}

export function* roomSagas() {
  yield all([
    takeLatest(getRoomByIdAction.type, getRoomById),
    takeLatest(createRoomAction.type, createRoom),
    takeLatest(deleteRoomAction.type, deleteRoom),
    takeLatest(updateRoomAction.type, updateRoom),
    takeLatest(cancelUpdateRoomAction.type, cancelUpdateRoom),
    takeLatest(cancelCreateRoomAction.type, cancelCreateRoom),
  ]);
}
