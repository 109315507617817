import {
  AssetType,
  AssociationChildType,
  ExpenseInvoiceStatus,
  ExpensePaymentExpensePaymentStatus,
  ExpensePaymentPaymentSource,
  ExpensePurchaseOrderStatus,
  ExpenseQuoteStatus,
  ExpenseStatus,
  IExpenseDto,
  IExpenseEstimate,
  IExpenseInvoice,
  IExpensePayment,
  IExpensePurchaseOrder,
  IExpensePurchaseOrderChangeOrder,
  IExpensePurchaseOrderItem,
  IExpenseQuote,
  IExpenseResponse,
  IVendor,
  IWorkOrder,
  WorkOrderStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  mapApiComment,
  mapApiDocument,
  mapReduxComment,
  mapReduxDocument,
  ReduxComment,
  ReduxDocument,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { PageableCollection } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { formatISO, isDate, parseISO } from 'date-fns';

export type GroupedJobLists = {
  [id: string]: PageableCollection<ReduxExpense> & { propertyId?: string; associationType?: AssetType };
};

export enum ExpenseElementType {
  Expense = 'job',
  Payment = 'payment',
  Invoice = 'invoice',
  PurchaseOrder = 'purchaseOrder',
  WorkOrder = 'workOrder',
  Quote = 'quote',
}

export function updateEditingPurchaseOrderLineItem(
  item: IExpensePurchaseOrderItem,
  key: string,
  editValues: Record<string, any>,
): IExpensePurchaseOrderItem {
  const newItem =
    item.number === key
      ? {
          number: key,
          amount: editValues.amount.value,
          description: editValues.description.value,
          name: editValues.name.value,
          quantity: editValues.quantity.value,
          associations: item.associations ?? [],
        }
      : item;
  return newItem;
}

export type ReduxExpense = Omit<
  IExpenseDto & IExpenseResponse,
  'payments' | 'invoices' | 'workOrders' | 'quotes' | 'purchaseOrders' | 'dateCreated' | 'dateCompleted' | 'estimates'
> & {
  payments?: ReduxExpensePayment[];
  invoices?: ReduxInvoice[];
  workOrders?: ReduxWorkOrder[];
  quotes?: ReduxQuote[];
  purchaseOrders?: ReduxPurchaseOrder[];
  dateCreated?: string;
  dateCompleted?: string;
  estimates?: ReduxExpenseEstimate[];
};

export type ReduxExpenseEstimate = Omit<IExpenseEstimate, 'dateCreated'> & {
  dateCreated?: string;
};

export type ReduxExpensePayment = Omit<IExpensePayment, 'dateCreated' | 'comments' | 'documents' | 'datePaid'> & {
  dateCreated?: string;
  comments?: ReduxComment[];
  documents?: ReduxDocument[];
  datePaid?: string;
};

export type ReduxInvoice = Omit<
  IExpenseInvoice,
  'dateCreated' | 'dateReceived' | 'comments' | 'documents' | 'dueDate'
> & {
  dateCreated?: string;
  comments?: ReduxComment[];
  documents?: ReduxDocument[];
  dateReceived?: string;
  dueDate?: string;
};

export type ReduxChangeOrder = Omit<IExpensePurchaseOrderChangeOrder, 'dateCreated'> & {
  dateCreated?: string;
};

export type ReduxPurchaseOrder = Omit<
  IExpensePurchaseOrder,
  'dateCreated' | 'dateCompleted' | 'documents' | 'comments' | 'changeOrders' | 'startDate'
> & {
  dateCreated?: string;
  comments?: ReduxComment[];
  documents?: ReduxDocument[];
  dateCompleted?: string;
  changeOrders?: ReduxChangeOrder[];
  startDate?: string;
};

export type ReduxQuote = Omit<IExpenseQuote, 'dateReceived' | 'dateCreated' | 'comments' | 'documents'> & {
  dateReceived?: string;
  dateCreated?: string;
  comments?: ReduxComment[];
  documents?: ReduxDocument[];
};

export type ReduxWorkOrder = Omit<IWorkOrder, 'documents' | 'dateCreated' | 'dateCompleted' | 'comments'> & {
  dateCreated?: string;
  comments?: ReduxComment[];
  documents?: ReduxDocument[];
  dateCompleted?: string;
};

export function mapReduxExpense(job: IExpenseDto | IExpenseResponse): ReduxExpense {
  return {
    ...job,
    dateCreated: isDate(job.dateCreated) ? formatISO(job.dateCreated!) : (job.dateCreated as any)?.toString(),
    dateCompleted: isDate(job.dateCompleted) ? formatISO(job.dateCompleted!) : (job.dateCompleted as any)?.toString(),
    payments: job.payments?.map((x) => mapReduxExpensePayment(x)),
    quotes: job.quotes?.map((x) => mapReduxQuote(x)),
    purchaseOrders: job.purchaseOrders?.map((x) => mapReduxPurchaseOrder(x)),
    workOrders: job.workOrders?.map((x) => mapReduxWorkOrder(x)),
    invoices: job.invoices?.map((x) => mapReduxInvoice(x)),
    estimates: job.estimates?.map((x) => mapReduxEstimate(x)),
  };
}

export function mapApiExpense(expense: ReduxExpense): IExpenseDto {
  return {
    ...expense,
    dateCreated: expense.dateCreated ? parseISO(expense.dateCreated) : undefined,
    dateCompleted: expense.dateCompleted ? parseISO(expense.dateCompleted) : undefined,
    payments: expense.payments?.map((x) => mapApiExpensePayment(x)),
    workOrders: expense.workOrders?.map((x) => mapApiWorkOrder(x)),
    purchaseOrders: expense.purchaseOrders?.map((x) => mapApiPurchaseOrder(x)),
    quotes: expense.quotes?.map((x) => mapApiQuote(x)),
    invoices: expense.invoices?.map((x) => mapApiInvoice(x)),
    estimates: expense.estimates?.map((x) => mapApiEstimate(x)),
  };
}

export function mapReduxEstimate(estimate: IExpenseEstimate): ReduxExpenseEstimate {
  return {
    ...estimate,
    dateCreated: isDate(estimate.dateCreated)
      ? formatISO(estimate.dateCreated!)
      : (estimate.dateCreated as any)?.toString(),
  };
}

export function mapApiEstimate(estimate: ReduxExpenseEstimate): IExpenseEstimate {
  return {
    ...estimate,
    dateCreated: estimate.dateCreated ? parseISO(estimate.dateCreated) : undefined,
  };
}

export function mapReduxInvoice(invoice: IExpenseInvoice): ReduxInvoice {
  return {
    ...invoice,
    dateCreated: isDate(invoice.dateCreated)
      ? formatISO(invoice.dateCreated!)
      : (invoice.dateCreated as any)?.toString(),
    dateReceived: isDate(invoice.dateReceived)
      ? formatISO(invoice.dateReceived!)
      : (invoice.dateReceived as any)?.toString(),
    dueDate: isDate(invoice.dueDate) ? formatISO(invoice.dueDate!) : (invoice.dueDate as any)?.toString(),
    documents: invoice.documents?.map((x) => mapReduxDocument(x)),
    comments: invoice.comments?.map((x) => mapReduxComment(x)),
  };
}

export function mapApiInvoice(invoice: ReduxInvoice): IExpenseInvoice {
  return {
    ...invoice,
    dateCreated: invoice.dateCreated ? parseISO(invoice.dateCreated) : undefined,
    dateReceived: invoice.dateReceived ? parseISO(invoice.dateReceived) : undefined,
    dueDate: invoice.dueDate ? parseISO(invoice.dueDate) : undefined,
    comments: invoice.comments?.map((x) => mapApiComment(x)),
    documents: invoice.documents?.map((x) => mapApiDocument(x)),
  };
}

export function mapReduxChangeOrder(changeOrder: IExpensePurchaseOrderChangeOrder): ReduxChangeOrder {
  return {
    ...changeOrder,
    dateCreated: isDate(changeOrder.dateCreated)
      ? formatISO(changeOrder.dateCreated!)
      : (changeOrder.dateCreated as any)?.toString(),
  };
}

export function mapApiPurchaseOrderAdjustment(poAdjustment: ReduxChangeOrder): IExpensePurchaseOrderChangeOrder {
  return { ...poAdjustment, dateCreated: poAdjustment.dateCreated ? parseISO(poAdjustment.dateCreated) : undefined };
}

export function mapReduxPurchaseOrder(purchaseOrder: IExpensePurchaseOrder): ReduxPurchaseOrder {
  return {
    ...purchaseOrder,
    dateCreated: isDate(purchaseOrder.dateCreated)
      ? formatISO(purchaseOrder.dateCreated!)
      : (purchaseOrder.dateCreated as any)?.toString(),
    dateCompleted: isDate(purchaseOrder.dateCompleted)
      ? formatISO(purchaseOrder.dateCompleted!)
      : (purchaseOrder.dateCompleted as any)?.toString(),
    startDate: isDate(purchaseOrder.startDate)
      ? formatISO(purchaseOrder.startDate!)
      : (purchaseOrder.startDate as any)?.toString(),
    documents: purchaseOrder.documents?.map((x) => mapReduxDocument(x)),
    comments: purchaseOrder.comments?.map((x) => mapReduxComment(x)),
    changeOrders: purchaseOrder.changeOrders?.map(mapReduxChangeOrder),
  };
}

export function mapApiPurchaseOrder(purchaseOrder: ReduxPurchaseOrder): IExpensePurchaseOrder {
  return {
    ...purchaseOrder,
    dateCreated: purchaseOrder.dateCreated ? parseISO(purchaseOrder.dateCreated) : undefined,
    dateCompleted: purchaseOrder.dateCompleted ? parseISO(purchaseOrder.dateCompleted) : undefined,
    startDate: purchaseOrder.startDate ? parseISO(purchaseOrder.startDate) : undefined,
    documents: purchaseOrder.documents?.map((x) => mapApiDocument(x)),
    comments: purchaseOrder.comments?.map((x) => mapApiComment(x)),
    changeOrders: purchaseOrder.changeOrders?.map(mapApiPurchaseOrderAdjustment),
  };
}

export function mapReduxWorkOrder(workOrder: IWorkOrder): ReduxWorkOrder {
  return {
    ...workOrder,
    dateCreated: isDate(workOrder.dateCreated)
      ? formatISO(workOrder.dateCreated!)
      : (workOrder.dateCreated as any)?.toString(),
    dateCompleted: isDate(workOrder.dateCompleted)
      ? formatISO(workOrder.dateCompleted!)
      : (workOrder.dateCompleted as any)?.toString(),
    documents: workOrder.documents?.map((x) => mapReduxDocument(x)),
    comments: workOrder.comments?.map((x) => mapReduxComment(x)),
  };
}

export function mapApiWorkOrder(workOrder: ReduxWorkOrder): IWorkOrder {
  return {
    ...workOrder,
    dateCreated: workOrder.dateCreated ? parseISO(workOrder.dateCreated) : undefined,
    dateCompleted: workOrder.dateCompleted ? parseISO(workOrder.dateCompleted) : undefined,
    documents: workOrder.documents?.map((x) => mapApiDocument(x)),
    comments: workOrder.comments?.map((x) => mapApiComment(x)),
  };
}

export function mapReduxExpensePayment(payment: IExpensePayment): ReduxExpensePayment {
  return {
    ...payment,
    dateCreated: isDate(payment.dateCreated)
      ? formatISO(payment.dateCreated!)
      : (payment.dateCreated as any)?.toString(),
    documents: payment.documents?.map((x) => mapReduxDocument(x)),
    comments: payment.comments?.map((x) => mapReduxComment(x)),
    datePaid: isDate(payment.datePaid) ? formatISO(payment.datePaid!) : (payment.datePaid as any)?.toString(),
  };
}

export function mapApiExpensePayment(payment: ReduxExpensePayment): IExpensePayment {
  return {
    ...payment,
    dateCreated: payment.dateCreated ? parseISO(payment.dateCreated) : undefined,
    documents: payment.documents?.map((x) => mapApiDocument(x)),
    comments: payment.comments?.map((x) => mapApiComment(x)),
    datePaid: payment.datePaid ? parseISO(payment.datePaid) : undefined,
  };
}

export function mapReduxQuote(quote: IExpenseQuote): ReduxQuote {
  return {
    ...quote,
    dateReceived: isDate(quote.dateReceived) ? formatISO(quote.dateReceived!) : (quote.dateReceived as any)?.toString(),
    dateCreated: isDate(quote.dateCreated) ? formatISO(quote.dateCreated!) : (quote.dateCreated as any)?.toString(),
    documents: quote.documents?.map((x) => mapReduxDocument(x)),
    comments: quote.comments?.map((x) => mapReduxComment(x)),
  };
}

export function mapApiQuote(quote: ReduxQuote): IExpenseQuote {
  return {
    ...quote,
    dateReceived: quote.dateReceived ? parseISO(quote.dateReceived) : undefined,
    dateCreated: quote.dateCreated ? parseISO(quote.dateCreated) : undefined,
    documents: quote.documents?.map((x) => mapApiDocument(x)),
    comments: quote.comments?.map((x) => mapApiComment(x)),
  };
}

export type ExpenseElementOption = {
  label: string;
  number?: string;
  amount?: number;
  vendorName?: string;
  status?: string;
  vendor?: IVendor;
  propertyAccountId?: string;
  propertyAccountName?: string;
  type?: ExpenseElementType;
};

export const associationChildMap = new Map([
  [AssociationChildType.Quote, 'Quote'],
  [AssociationChildType.PurchaseOrder, 'Purchase Order'],
  [AssociationChildType.Invoice, 'Invoice'],
  [AssociationChildType.WorkOrder, 'Work Order'],
  [AssociationChildType.Payment, 'Payment'],
]);

export const expenseStatusMap = new Map([
  [ExpenseStatus.Created, 'Created'],
  [ExpenseStatus.SimpleExpense, 'Simple Expense'],
  [ExpenseStatus.QuotePhase, 'Quote Phase'],
  [ExpenseStatus.InProcess, 'In Process'],
  [ExpenseStatus.Completed, 'Completed'],
  [ExpenseStatus.CompletedAndFullyPaid, 'Completed and Fully Paid'],
  [ExpenseStatus.Abandoned, 'Abandoned'],
  [ExpenseStatus.Archived, 'Archived'],
]);

export const quoteStatusMap = new Map([
  [ExpenseQuoteStatus.Created, 'Created'],
  [ExpenseQuoteStatus.AwaitingInfo, 'Awaiting Info'],
  [ExpenseQuoteStatus.QuoteComplete, 'Quote Complete'],
  [ExpenseQuoteStatus.Committed, 'Committed'],
  [ExpenseQuoteStatus.NotAccepted, 'Not Accepted'],
  [ExpenseQuoteStatus.Abandoned, 'Abandoned'],
]);

export const invoiceStatusMap = new Map([
  [ExpenseInvoiceStatus.NotPaid, 'Not Paid'],
  [ExpenseInvoiceStatus.PartiallyPaid, 'Partially Paid'],
  [ExpenseInvoiceStatus.PaidInFull, 'Paid In Full'],
  [ExpenseInvoiceStatus.DonePaying, 'Done Paying'],
  [ExpenseInvoiceStatus.Archived, 'Void'],
]);

export const purchaseOrderStatusMap = new Map([
  [ExpensePurchaseOrderStatus.Created, 'Created'],
  [ExpensePurchaseOrderStatus.Assigned, 'Assigned'],
  [ExpensePurchaseOrderStatus.Completed, 'Completed'],
  [ExpensePurchaseOrderStatus.Abandoned, 'Abandoned'],
]);

export const workOrderStatusMap = new Map([
  [WorkOrderStatus.Created, 'Created'],
  [WorkOrderStatus.AwaitingInfo, 'Awaiting Info'],
  [WorkOrderStatus.InProcess, 'In Process'],
  [WorkOrderStatus.Reviewing, 'Reviewing'],
  [WorkOrderStatus.Completed, 'Completed'],
  [WorkOrderStatus.Abandoned, 'Abandoned'],
]);

export const expensePaymentStatusMap = new Map([
  [ExpensePaymentExpensePaymentStatus.Initiated, 'Initiated'],
  [ExpensePaymentExpensePaymentStatus.Paid, 'Paid'],
  [ExpensePaymentExpensePaymentStatus.PaidAndSettled, 'Paid and Settled'],
  [ExpensePaymentExpensePaymentStatus.Archived, '**Voided**'],
]);

export const blankPayment: ReduxExpensePayment = {
  description: '',
  amount: 0,
  payment: ExpensePaymentPaymentSource.Cash,
  paymentDetail: '',
};

export const blankInvoice: ReduxInvoice = {
  name: '',
  description: '',
  invoiceAmount: 0,
};

export const blankPurchaseOrder: ReduxPurchaseOrder = {
  name: '',
  description: '',
  amount: 0,
  isItemized: false,
  items: [],
};

export const blankWorkOrder: ReduxWorkOrder = {
  name: '',
  description: '',
};

export const blankQuote: ReduxQuote = {
  name: '',
  description: '',
  quoteAmount: 0,
};

export type POChangeOrder = {
  id: string;
  amount?: number;
  description?: string;
};

export type DetailedAnalysisRow = {
  id: string;
  title?: string;
  name?: string;
  payee?: string;
  amount?: number;
  poChangeOrders?: POChangeOrder[];
};
