import React, { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

type LoadingIconButtonProps = {
  loading: boolean;
  icon: JSX.Element;
  label?: string;
} & IconButtonProps;

export const LoadingIconButton: FC<LoadingIconButtonProps> = ({ loading, icon, label, onClick, ...props }) => {
  return (
    <Tooltip title={label}>
      <span>
        <IconButton onClick={onClick} disabled={loading || props.disabled} {...props}>
          {loading ? <CircularProgress size={24} /> : icon}
        </IconButton>
      </span>
    </Tooltip>
  );
};
