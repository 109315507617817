import React, { FC, useEffect, useState } from 'react';
import { ReduxDetail } from '@monkeyjump-labs/cam-fe-shared/dist/types/detailTypes';
import { DrawerBreadcrumb, DrawerLayout, DrawerNavItem } from '../../../../global/layout/DrawerLayout';
import {
  getDetailByIdAction,
  resetDetailSubmissionAction,
  unsetSelectedDetailAction,
  useDetails,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/details/detailsSlice';
import { useDispatch } from 'react-redux';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {
  AssociationChildType,
  AssociationType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssetTypeFromPathname } from '../../../utils/getAssetTypeFromPathname';
import { DetailsTab } from '../../../tabs/TabTypeEnums';
import LinkIcon from '@mui/icons-material/Link';
import { SopTaskDialog } from '../../../../property/components/propertyTabs/propertyInfoTabs/sopTaskTemplates/components/SopTaskDialog';
import { useRouting } from '../../../utils/useRouting';
import { useIcons } from '../../../icons/useIcons';
import { DetailsDrawerTabs } from './DetailsDrawerTabs';

enum SopTaskDialogOpen {
  None,
  Add,
  Edit,
}

export const DetailsDrawer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAssociationIcon, getTabIcon } = useIcons();
  const { handleNavigateFromAssociationClose } = useRouting();
  const { id, outerTab, innerTab, detailId, tab } = useParams();
  const { details, selectedDetail, selectedSubDetails } = useDetails();
  const [editingDetail, setEditingDetail] = useState<ReduxDetail>();
  const [isDisplayingSubDetail, setIsDisplayingSubDetail] = useState(false);
  const [previousDetails, setPreviousDetails] = useState<ReduxDetail[]>([]);
  const [sopTaskDialog, setSopTaskDialog] = useState<SopTaskDialogOpen>(SopTaskDialogOpen.None);

  useEffect(() => {
    id && detailId && dispatch(getDetailByIdAction({ detailId: detailId }));
  }, [detailId]);

  useEffect(() => {
    if (!details.submitted && !selectedSubDetails.submitted) return;
    const updatedDetail =
      details.value?.find((d: ReduxDetail) => d.id === detailId) ??
      selectedSubDetails.value?.find((d: ReduxDetail) => d.id === detailId);
    if (!updatedDetail) return;
    setEditingDetail(updatedDetail);
    dispatch(resetDetailSubmissionAction());
  }, [details.submitted, selectedSubDetails.submitted]);

  useEffect(() => {
    if (!selectedDetail.value) return;

    setEditingDetail(selectedDetail.value);
    setIsDisplayingSubDetail(!!selectedDetail.value.parentId);
  }, [selectedDetail.value]);

  const handleReturnToParentDetail = (targetId?: string) => {
    if (!editingDetail?.parentId) return;

    if (targetId) {
      const detailToNavigateTo = previousDetails.find((d) => d.id === targetId);
      if (!detailToNavigateTo) return;

      previousDetails.splice(previousDetails.indexOf(detailToNavigateTo));
      setPreviousDetails(previousDetails);

      navigate(
        `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/detail/${targetId}/${
          DetailsTab.SubDetails
        }`,
      );
    } else {
      navigate(
        `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/detail/${editingDetail.parentId}/${
          DetailsTab.SubDetails
        }`,
      );
    }
  };

  const handleViewSubDetail = (subDetail: ReduxDetail) => {
    if (!editingDetail) return;

    if (!editingDetail.parentId || previousDetails.length > 0) setPreviousDetails([...previousDetails, editingDetail]);
    navigate(
      `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/detail/${subDetail.id}/${DetailsTab.Info}`,
    );
  };

  const onAddSopTask = () => {
    setSopTaskDialog(SopTaskDialogOpen.Add);
  };

  const handleCloseSopTaskDialog = () => {
    setSopTaskDialog(SopTaskDialogOpen.None);
  };

  const breadcrumbs: DrawerBreadcrumb[] =
    previousDetails.length === 0 && isDisplayingSubDetail
      ? [
          {
            id: 'parent',
            label: 'Return to Parent',
            onClick: () => handleReturnToParentDetail(),
          },
        ]
      : [
          ...previousDetails.map((p) => {
            return {
              id: p.id ?? '',
              label: p.name ?? '',
              onClick: () => handleReturnToParentDetail(p.id),
            };
          }),
          ...(previousDetails.length > 0
            ? [{ id: editingDetail?.id ?? '', label: editingDetail?.name ?? '', onClick: undefined }]
            : []),
        ];

  const handleDrawerClose = () => {
    setPreviousDetails([]);
    if (isDisplayingSubDetail) {
      handleReturnToParentDetail();
    } else {
      setIsDisplayingSubDetail(false);
      dispatch(unsetSelectedDetailAction());
      setEditingDetail(undefined);
      handleNavigateFromAssociationClose();
    }
  };

  const tabs: DrawerNavItem[] = [
    {
      icon: getTabIcon('info'),
      label: 'Info',
      value: DetailsTab.Info,
    },
    {
      icon: getAssociationIcon(AssociationChildType.Observation),
      label: 'Observations',
      value: DetailsTab.Observations,
    },
    {
      icon: getTabIcon('document'),
      label: 'Documents',
      value: DetailsTab.Documents,
    },
    {
      icon: <LinkIcon />,
      label: 'Associations',
      value: DetailsTab.Associations,
    },
    {
      icon: <FormatListBulletedIcon />,
      label: 'Components',
      value: DetailsTab.SubDetails,
    },
  ];

  return (
    <>
      {editingDetail && (
        <>
          <DrawerLayout
            title={isDisplayingSubDetail ? `Component: ${editingDetail.name!}` : `Detail: ${editingDetail.name!}`}
            open={true}
            tabs={tabs}
            tabValue={tab}
            breadcrumbs={breadcrumbs}
            onTabChange={(newValue: string) => {
              navigate(
                `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/detail/${detailId}/${newValue}`,
              );
            }}
            onCloseDrawer={handleDrawerClose}
          >
            <DetailsDrawerTabs
              tab={tab as DetailsTab}
              editingDetail={editingDetail}
              onViewSubDetail={handleViewSubDetail}
              isDisplayingSubDetail={isDisplayingSubDetail}
              setEditingDetail={setEditingDetail}
              onAddSopTask={onAddSopTask}
            />
          </DrawerLayout>
          {editingDetail.propertyId && (
            <>
              <SopTaskDialog
                open={sopTaskDialog !== SopTaskDialogOpen.None}
                onClose={handleCloseSopTaskDialog}
                showAddAutomation={sopTaskDialog === SopTaskDialogOpen.Add}
                onShowAddAutomation={() => {}}
                onHideAddAutomation={() => {}}
                onShowEditAutomation={() => {}}
                isListInDialog={false}
                propertyId={editingDetail.propertyId}
                page={0}
                pageSize={100}
                associatedId={editingDetail.id}
                associationType={AssociationType.Detail}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
