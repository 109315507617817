import React from 'react';
import { ApplicationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { AddApplicantForm } from '../AddApplicantForm';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { ReduxApplication } from '../../../../redux/applicationTypes';

type Props = {
  open: boolean;
  onClose: () => void;
  applicant: ReduxApplication | undefined;
};

export const AddCosignerDialog = ({ open, onClose, applicant }: Props) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h6">Add Cosigner</DialogTitle>
      <DialogContent>
        <AddApplicantForm
          type={ApplicationType.CoSigner}
          propertyId={applicant?.rentalPropertyId}
          parentApplicationId={applicant?.id}
          onClose={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};
