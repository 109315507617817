import React, { FC } from 'react';
import Button from '@mui/material/Button';
import { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { UserImpersonator } from './UserImpersonator';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';

type UserImpersonationDialogProps = Omit<DialogProps, 'onclose'> & {
  onClose: () => void;
};

export const UserImpersonationDialog: FC<UserImpersonationDialogProps> = ({ open, onClose }) => {
  return (
    <DialogLayout title={'Impersonate User'} open={open} onClose={onClose} fullWidth>
      <DialogContent>
        <UserImpersonator onClose={onClose} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </DialogLayout>
  );
};
