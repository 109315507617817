import React, { useEffect, useState } from 'react';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import { IVehicle } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { VehicleGrid } from './VehiclesGrid';
import { ReduxApplication } from '../../../../redux/applicationTypes';

type Props = {
  applicant: ReduxApplication;
  onRemoveVehicle: (updatedDependents: ReduxApplication) => void;
  onOpen: () => void;
};

export const Vehicles = ({ applicant, onRemoveVehicle, onOpen }: Props) => {
  const [applicantVehicles, setApplicantVehicles] = useState(applicant.vehicles ?? []);

  useEffect(() => {
    setApplicantVehicles(applicant?.vehicles ?? []);
  }, [applicant?.vehicles]);

  const removeVehicle = (updatedVehicles: IVehicle[]) => {
    setApplicantVehicles(updatedVehicles);
    onRemoveVehicle({ vehicles: updatedVehicles });
  };

  return (
    <StyledInfoBox label={'Vehicles'}>
      <Stack>
        <Box sx={{ mb: '.5rem' }}>
          <Button variant="outlined" onClick={onOpen}>
            Add Vehicle
          </Button>
        </Box>
        <Box sx={{ height: 200, width: '100%' }}>
          <VehicleGrid vehicles={applicantVehicles} onRemoveVehicle={removeVehicle} />
        </Box>
      </Stack>
    </StyledInfoBox>
  );
};
