import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { useTheme } from '@mui/material';

export const NotLoggedIn: FC = () => {
  const theme = useTheme();
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();
  const [fadeIn, setFadeIn] = useState(false);
  const location = useLocation();
  const currentUser = useUser((u) => u.currentUser);
  useEffect(() => setFadeIn(true), []);

  useEffect(() => {
    // Fix to prevent "bad cycle" of being logged in with Auth0 but not being logged in with our app
    if (isAuthenticated && currentUser.loadingFailed)
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
  }, [isAuthenticated]);

  const redirectLoginOptions: RedirectLoginOptions = {
    authorizationParams: {
      scope: 'openid profile email',
    },
    appState: {
      redirectUri: location.pathname,
      search: location.search,
    },
  };

  return (
    <Grid
      container
      sx={{
        transition: 'opacity 1s ease-in-out',
        opacity: fadeIn ? 1 : 0,
        height: '100vh',
        backgroundColor: theme.palette.primary.dark,
      }}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Paper sx={{ position: 'relative', height: 320 }}>
        <Box height={'100%'} padding={2}>
          <Stack height={'100%'} alignItems={'center'} justifyContent={'center'} spacing={2}>
            <Typography variant="h4">You are not currently logged in</Typography>
            <Typography variant="h6">Please log in to continue</Typography>
            <Box flexGrow={1} />
            <Button variant={'contained'} fullWidth onClick={() => loginWithRedirect(redirectLoginOptions)}>
              Log In
            </Button>
          </Stack>
        </Box>
      </Paper>
    </Grid>
  );
};
