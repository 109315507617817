import React, { FC } from 'react';
import { ButtonProps } from '@mui/material/Button';
import { AddTaskType } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { SplitButton } from '../../buttons/SplitButton';

type TaskSplitButtonProps = {
  taskType: AddTaskType;
  onOpenAdd: (taskType: AddTaskType) => void;
} & ButtonProps;

export const TaskSplitButton: FC<TaskSplitButtonProps> = ({ taskType, onOpenAdd }) => {
  const buttonTitleType = taskType === AddTaskType.NewTask || taskType === AddTaskType.SopTask ? 'Add' : 'Schedule';

  return (
    <SplitButton
      mainActionTitle={`${buttonTitleType} Task`}
      secondaryActionTitle={`${buttonTitleType} from SOP Tasks`}
      tertiaryActionTitle={`${buttonTitleType} Task`}
      onMainAction={() => onOpenAdd(taskType)}
      onSecondaryAction={() =>
        onOpenAdd(taskType === AddTaskType.NewTask ? AddTaskType.SopTask : AddTaskType.ScheduledSopTask)
      }
      onTertiaryAction={() => onOpenAdd(taskType === AddTaskType.NewTask ? taskType : AddTaskType.NewScheduledTask)}
    />
  );
};
