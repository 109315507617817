import React, { FC } from 'react';
import { ExpenseElementType } from '../../../redux/expenseData';
import { useExpenses } from '../../../redux/expenseSlice';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import {
  ExpenseInvoiceStatus,
  ExpensePaymentExpensePaymentStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type AssociatedValueCellProps = {
  type: ExpenseElementType; //type of parent thing
  distantAssociationType?: ExpenseElementType; //optional, if new directionality is needed besides what is present in the switch statement
  number?: string;
} & GridRenderCellParams;
export const AssociatedValueCell: FC<AssociatedValueCellProps> = ({ type, number, distantAssociationType }) => {
  const { selectedExpense } = useExpenses();
  const getAssociatedValue = (type: ExpenseElementType, number: string) => {
    switch (type) {
      case ExpenseElementType.PurchaseOrder:
        const associatedInvoices = selectedExpense.value?.invoices
          ?.filter((x) => x.expenseInvoiceStatus !== ExpenseInvoiceStatus.Archived)
          ?.filter((x) => x.associatedPurchaseOrder === number);
        if (associatedInvoices)
          if (distantAssociationType === ExpenseElementType.Payment) {
            const associatedPayments = selectedExpense.value?.payments
              ?.filter((x) => x.status !== ExpensePaymentExpensePaymentStatus.Archived)
              ?.filter((x) => associatedInvoices.some((y) => y.number === x.associatedInvoice));
            return associatedPayments?.reduce((acc, curr) => (curr.amount ? acc + curr.amount : 0), 0);
          } else {
            return associatedInvoices.reduce((acc, curr) => (curr.invoiceAmount ? acc + curr.invoiceAmount : 0), 0);
          }
        break;
      case ExpenseElementType.Invoice:
        if (distantAssociationType === ExpenseElementType.Payment) {
          const associatedPayments = selectedExpense.value?.payments?.filter(
            (x) => x.associatedInvoice === number && x.status !== ExpensePaymentExpensePaymentStatus.Archived,
          );
          return associatedPayments?.reduce((acc, curr) => (curr.amount ? acc + curr.amount : 0), 0);
        } else {
          const associatedPOs = selectedExpense.value?.purchaseOrders?.filter((x) => x.number === number);
          if (associatedPOs)
            return associatedPOs.reduce((acc, curr) => (curr.totalAmount ? acc + curr.totalAmount : 0), 0);
        }
        break;
      case ExpenseElementType.Payment:
        const paymentAssociatedInvoices = selectedExpense.value?.invoices?.filter((x) => x.number === number);
        if (paymentAssociatedInvoices)
          return paymentAssociatedInvoices
            ?.filter((inv) => inv.expenseInvoiceStatus !== ExpenseInvoiceStatus.Archived)
            .reduce((acc, curr) => (curr.invoiceAmount ? acc + curr.invoiceAmount : 0), 0);
        break;
      default:
        break;
    }
  };
  if (number) {
    const amount = getAssociatedValue(type, number);
    if (amount) return <>${amount}</>;
  }
  return <></>;
};
