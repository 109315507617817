import React, { FC, useEffect, useState } from 'react';
import { StripedDataGrid, styledFilterPanelProps } from '../../datagrids/StripedDataGrid';
import { ExpenseElementType, expenseStatusMap, GroupedJobLists, ReduxExpense } from '../redux/expenseData';
import { Filter, SortDirection } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { getExpenseGroupByAssetIdAction, getExpensesAction, useExpenses } from '../redux/expenseSlice';
import {
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { dateFilters, FilterOperator, stringFilters } from '../../utils/filteringUtils';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { tryFormatDate } from '../../utils/TryFormatDate';
import EditIcon from '@mui/icons-material/Edit';
import { StatusCell, StatusEditCell } from './expenseElementTables/cells/StatusCell';
import { AssetType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export type JobsTableProps = {
  onSetSelectedJob: (id: string, groupId: string, groupList: GroupedJobLists) => void;
  assetId: string;
  assetType: AssetType;
  includeClosed: boolean;
  isDetailPanel?: boolean;
};

export const ExpenseTable: FC<JobsTableProps> = ({
  onSetSelectedJob,
  assetType,
  assetId,
  includeClosed,
  isDetailPanel,
}) => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const { expenses, selectedExpense, groupedExpenseLists, groupingInfo } = useExpenses();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState<keyof ReduxExpense | undefined>();
  const [orderDirection, setOrderDirection] = useState<SortDirection | undefined>();
  const [filters, setFilters] = useState<Filter<ReduxExpense>[] | undefined>();
  const [rows, setRows] = useState<ReduxExpense[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    if (isDetailPanel) {
      dispatch(
        getExpenseGroupByAssetIdAction({
          associationId: assetId,
          associationType: assetType,
          includeClosed,
          page,
          pageSize,
        }),
      );
    } else {
      selectedContext.propertyId &&
        dispatch(
          getExpensesAction({
            propertyId: selectedContext.propertyId,
            assetId: assetId,
            assetType: assetType,
            includeClosed: includeClosed,
            page: page,
            pageSize: pageSize,
            sortBy: orderBy,
            sortDirection: orderDirection,
            filters: filters,
          }),
        );
    }
  }, [includeClosed, page, pageSize, orderBy, orderDirection, filters, expenses.submitted, selectedExpense.submitted]);

  useEffect(() => {
    if (groupingInfo.isGrouped) {
      typeof groupedExpenseLists[assetId] !== 'undefined' && setLoading(groupedExpenseLists[assetId].loading);
      typeof groupedExpenseLists[assetId] !== 'undefined' &&
        groupedExpenseLists[assetId].value &&
        setRows(groupedExpenseLists[assetId].value ?? []);
      typeof groupedExpenseLists[assetId] !== 'undefined' &&
        setTotalCount(groupedExpenseLists[assetId].totalCount ?? 0);
    } else {
      expenses.value && setRows(expenses.value);
      setLoading(expenses.loading);
      setTotalCount(expenses.totalCount ?? 0);
    }
  }, [expenses.value, groupedExpenseLists]);
  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setOrderBy(undefined);
      setOrderDirection(undefined);
    } else {
      setOrderBy(model[0].field as keyof ReduxExpense);
      setOrderDirection(model[0].sort === 'asc' ? 'Ascending' : 'Descending');
    }
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    if (model.items.length === 0) {
      setFilters(undefined);
    } else {
      const parameterHasValues = model.items.length > 0 && model.items.every((parameter) => parameter.value);
      if (parameterHasValues) {
        const filters = model.items.map((items) => {
          return {
            name: items.field as keyof ReduxExpense,
            operator: items.field as FilterOperator,
            value: items.value,
          };
        });
        setFilters(filters);
      }
    }
  };

  const columns: GridColDef<ReduxExpense>[] = [
    {
      field: 'name',
      headerName: 'Name',
      filterOperators: stringFilters,
      filterable: !isDetailPanel,
      sortable: !isDetailPanel,
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <Link sx={{ cursor: 'pointer' }} color="inherit">
          <Typography variant={'body2'} onClick={() => onSetSelectedJob(params.row.id!, assetId, groupedExpenseLists)}>
            {params.value}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      filterOperators: stringFilters,
      flex: 2,
      filterable: !isDetailPanel,
      sortable: !isDetailPanel,
    },
    {
      field: 'project',
      headerName: 'Project',
      filterOperators: stringFilters,
      flex: 1,
      filterable: !isDetailPanel,
      sortable: !isDetailPanel,
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      filterOperators: dateFilters,
      flex: 1,
      filterable: !isDetailPanel,
      sortable: !isDetailPanel,
      renderCell: (params: GridRenderCellParams) => <>{params.value ? tryFormatDate(params.value ?? '') : ''}</>,
    },
    {
      field: 'expenseStatus',
      headerName: 'Status',
      flex: 1,
      display: 'flex',
      filterable: !isDetailPanel,
      sortable: !isDetailPanel,
      editable: true,
      renderHeader: () => {
        return (
          <>
            {'Status '}
            <EditIcon fontSize="small" color="disabled" />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return <StatusCell statusMap={expenseStatusMap} {...params} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <StatusEditCell type={ExpenseElementType.Expense} statusMap={expenseStatusMap} {...params} />;
      },
    },
  ];
  return (
    <StripedDataGrid
      disableRowGrouping
      rows={rows}
      columns={columns}
      autoHeight
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
      rowCount={totalCount ?? 0}
      loading={loading}
      pagination
      paginationMode={'server'}
      pageSizeOptions={[10, 25, 50, 100]}
      paginationModel={{ page: page, pageSize: pageSize }}
      onPaginationModelChange={(model: GridPaginationModel) => {
        setPage(model.page);
        setPageSize(model.pageSize);
      }}
      sortingMode={'server'}
      onSortModelChange={handleSortModelChange}
      slotProps={{
        filterPanel: styledFilterPanelProps,
      }}
      filterMode={'server'}
      onFilterModelChange={handleFilterModelChange}
    />
  );
};
