import {
  IFinancialMetrics,
  IOccupancyMetrics,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { tryFormatDate } from '../../../../../../../_shared/utils/TryFormatDate';

export class HistoricalFinancialData {
  public name: string;
  public valuesAreNull?: boolean;
  public 'Monthly Collections'?: number;
  public Delinquency?: number;
  public 'Delinquency For Evicted Units'?: number;

  private constructor(
    name: string,
    valuesAreNull?: boolean,
    monthlyCollections?: number,
    delinquency?: number,
    delinquencyForEvictedUnits?: number,
  ) {
    this.name = name;
    this['Monthly Collections'] = monthlyCollections;
    this.Delinquency = delinquency;
    this['Delinquency For Evicted Units'] = delinquencyForEvictedUnits;
    this.valuesAreNull = valuesAreNull;
  }

  public static createTableData(
    dateArray: string[],
    financialMetrics: { [key: string]: Required<IFinancialMetrics> },
  ): HistoricalFinancialData[] {
    const tableData: HistoricalFinancialData[] = [];
    for (let i = 0; i < dateArray.length; i++) {
      const dateKey = dateArray[i];
      const match = financialMetrics[dateKey];
      if (match) {
        tableData.push(
          new HistoricalFinancialData(
            tryFormatDate(dateKey),
            false,
            match.monthlyCollections,
            match.totalDelinquency,
            match.evictionUnitsDelinquency,
          ),
        );
      } else tableData.push(new HistoricalFinancialData(tryFormatDate(dateKey), true));
    }
    return tableData;
  }
}

export class HistoricalOccupancyData {
  public name: string;
  public valuesAreNull?: boolean;
  public Vacant?: number;
  public 'Fully Paid'?: number;
  public Delinquent?: number;
  public Eviction?: number | null;

  private constructor(
    name: string,
    valuesAreNull?: boolean,
    vacant?: number,
    fullyPaid?: number,
    delinquent?: number,
    eviction?: number,
  ) {
    this.name = name;
    this.valuesAreNull = valuesAreNull;
    this.Vacant = vacant;
    this['Fully Paid'] = fullyPaid;
    this.Delinquent = delinquent;
    this.Eviction = eviction;
  }

  public static createTableData(
    dateArray: string[],
    occupancyMetrics: { [key: string]: Required<IOccupancyMetrics> },
  ): HistoricalOccupancyData[] {
    const tableData: HistoricalOccupancyData[] = [];
    for (let i = 0; i < dateArray.length; i++) {
      const dateKey = dateArray[i];
      const match = occupancyMetrics[dateKey];
      if (match) {
        const vacantUnits = match.rentableUnits - match.occupiedUnits;
        const delinquentUnits = match.occupiedUnits - match.occupiedAndFullyPaidUnits - match.unitsUnderEviction;
        tableData.push(
          new HistoricalOccupancyData(
            tryFormatDate(dateKey),
            false,
            vacantUnits,
            match.occupiedAndFullyPaidUnits,
            delinquentUnits,
            match.unitsUnderEviction,
          ),
        );
      } else tableData.push(new HistoricalOccupancyData(tryFormatDate(dateKey), true));
    }
    return tableData;
  }
}

export class HistoricalLeaseStatusData {
  public name: string;
  public valuesAreNull?: boolean;
  public 'Pre-Lease'?: number;
  public 'Move-Outs Scheduled'?: number;
  public 'Month-To-Month'?: number;
  public 'To Be Closed'?: number;
  public 'Leases Under Review'?: number;

  private constructor(
    name: string,
    valuesAreNull?: boolean,
    preLease?: number,
    moveOuts?: number,
    monthToMonth?: number,
    toBeClosed?: number,
    underReview?: number,
  ) {
    this.name = name;
    this.valuesAreNull = valuesAreNull;
    this['Pre-Lease'] = preLease;
    this['Move-Outs Scheduled'] = moveOuts;
    this['Month-To-Month'] = monthToMonth;
    this['To Be Closed'] = toBeClosed;
    this['Leases Under Review'] = underReview;
  }

  public static createTableData(
    dateArray: string[],
    occupancyMetrics: { [key: string]: Required<IOccupancyMetrics> },
  ): HistoricalLeaseStatusData[] {
    const tableData: HistoricalLeaseStatusData[] = [];
    for (let i = 0; i < dateArray.length; i++) {
      const dateKey = dateArray[i];
      const match = occupancyMetrics[dateKey];
      if (match) {
        tableData.push(
          new HistoricalLeaseStatusData(
            tryFormatDate(dateKey),
            false,
            match.preLeaseUnits,
            match.moveOutsScheduled,
            match.monthToMonthUnits,
            match.leasesToBeClosed,
            match.leasesUnderReview,
          ),
        );
      } else tableData.push(new HistoricalLeaseStatusData(tryFormatDate(dateKey), true));
    }
    return tableData;
  }
}
