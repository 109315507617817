import React, { Ref, useImperativeHandle, useRef } from 'react';
import { GridFilterInputValueProps, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Select, SelectChangeEvent } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { LeaseDetailedStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { leaseStatusMap, ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { LeaseStatusChip } from '../LeaseStatusChip';

export const LeaseStatusCell = (props: GridRenderCellParams<ReduxLease, LeaseDetailedStatus>) => {
  return props.row ? <LeaseStatusChip lease={props.row} /> : <></>;
};

export function LeaseStatusFilterCell(props: GridFilterInputValueProps) {
  const { item, applyValue, focusElementRef } = props;

  const filterRef: Ref<any> = useRef(null);

  useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      filterRef?.current?.querySelector(`input[value="${item.value || ''}"]`)?.focus();
    },
  }));

  const handleFilterChange = (event: SelectChangeEvent) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <FormControl fullWidth size="small">
      <Select
        id="lease-status"
        aria-label="lease-status"
        value={item.value ? item.value : ''}
        onChange={(e: SelectChangeEvent) => handleFilterChange(e)}
        ref={filterRef}
      >
        {Array.from(leaseStatusMap).map(([status, label]) => (
          <MenuItem key={status} dense value={status}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
