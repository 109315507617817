import React, { FC, useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import { SelectChangeEvent } from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {
  addCommentToTaskAction,
  addWatcherToTaskAction,
  getSelectedTaskAction,
  removeTaskSchedulingAction,
  removeWatcherFromTaskAction,
  unsetSelectedTaskAction,
  updateTaskAction,
  useTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { useDispatch } from 'react-redux';
import { DocumentList } from '../../../documents/components/DocumentList';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import { CommentList } from '../../../comments/CommentList';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssetTypeFromPathname } from '../../../utils/getAssetTypeFromPathname';
import { TaskInfo } from './TaskInfo';
import { TaskDescription } from './TaskDescription';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  AssetType,
  AssociationType,
  ITaskCategory,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  ReduxTask,
  ReduxTimeConfiguration,
  ReduxTimeTracking,
  TaskStatusMap,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { UserOption } from '../AssigneeAutocomplete';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import Box from '@mui/material/Box';
import { AssetRouteType } from '@monkeyjump-labs/cam-fe-shared/dist/types/assetTypes';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import { useTimeConfigurationChecks } from '../useTimeConfigurationChecks';
import { TaskDialogTab } from '../../../tabs/TabTypeEnums';
import { Emails } from '../../../communications/components/Emails';
import { NotIntegrated } from '../../../../global/NotIntegrated';
import { SmsView } from '../../../communications/components/SmsView';
import { useRouting } from '../../../utils/useRouting';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { TaskAssociationList } from './TaskAssociationList';
import {
  attachmentAddedToAssociationAction,
  useCommunication,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/communications/communicationSlice';

type TaskTabParams = {
  id: string;
  outerTab: string;
  innerTab: string;
  taskId: string;
  tab: TaskDialogTab;
};

export const TaskDialog: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleNavigateFromAssociationClose } = useRouting();
  const { selectedTask } = useTasks();
  const { selectedContext } = useAssets();
  const { attachmentAddedToAssociation } = useCommunication();
  const { id, outerTab, innerTab, taskId, tab } = useParams<TaskTabParams>();
  const [assetType, setAssetType] = useState<AssetRouteType | undefined>();
  const [task, setTask] = useState<ReduxTask | undefined>(selectedTask.value);
  const [dirty, setDirty] = useState(false);
  const usersForAsset = useUser((u) => u.usersForAsset);
  const { checkTimeConfiguration } = useTimeConfigurationChecks();

  useEffect(() => {
    setAssetType(getAssetTypeFromPathname());
  }, [selectedTask.value]);

  useEffect(() => {
    setTask(selectedTask.value);
  }, [selectedTask.value]);

  useEffect(() => {
    taskId &&
      dispatch(
        getSelectedTaskAction({
          id: taskId,
          includeClosed: selectedTask.value?.includeClosed ?? false,
        }),
      );
  }, [taskId]);

  useEffect(() => {
    if (attachmentAddedToAssociation === AssociationType.Task) {
      taskId &&
        dispatch(
          getSelectedTaskAction({
            id: taskId,
            includeClosed: selectedTask.value?.includeClosed ?? false,
          }),
        );
      dispatch(attachmentAddedToAssociationAction(undefined));
    }
  }, [attachmentAddedToAssociation]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: TaskDialogTab) => {
    navigate(`/assets/${assetType}/${id}/${outerTab}/${innerTab}/task/${taskId}/${newValue}`);
  };

  const handleClose = () => {
    dispatch(unsetSelectedTaskAction());
    handleNavigateFromAssociationClose();
  };

  const handleAddComment = (comment: string) => {
    if (selectedTask.value?.id) {
      dispatch(
        addCommentToTaskAction({
          taskId: selectedTask.value.id,
          comment,
        }),
      );
    }
  };

  const handleSetTask = (key: keyof ReduxTask, value: string) => {
    task && setTask({ ...task, [key]: value });
    setDirty(true);
  };

  const handleSetTaskDate = (config: ReduxTimeConfiguration | undefined) => {
    setTask({ ...task, timeConfiguration: config });
    setDirty(true);
  };

  const handleSetTaskTimeTracking = (tracking: ReduxTimeTracking) => {
    setTask({ ...task, taskTimeTracking: tracking });
    setDirty(true);
  };

  const handleCategorySelection = (value: ITaskCategory | null) => {
    setTask({ ...task, category: value ?? undefined });
    setDirty(true);
  };

  const handleStatusSelection = (e: SelectChangeEvent) => {
    Array.from(TaskStatusMap).map(([status]) => {
      if (e.target.value === status) {
        setTask({ ...task, status: status });
      }
    });
    setDirty(true);
  };

  const handleAssigneeChange = (value: UserOption | null | undefined) => {
    if (value === null || value === undefined) {
      setTask({ ...task, assignee: undefined });
    }
    value &&
      usersForAsset.value?.map((user) => {
        user.id === value?.id && setTask({ ...task, assignee: { ...user } });
      });
    setDirty(true);
  };

  const updateTask = () => {
    if (task && task.id && task.associatedId && task.assetType) {
      dispatch(
        updateTaskAction({
          id: task.id,
          associatedId: assetType === 'property' ? task.propertyId! : task.associatedId!,
          associationType: assetType === 'property' ? AssetType.RentalProperty : task.assetType!,
          includeClosed: selectedTask.value!.includeClosed,
          body: task,
        }),
      );
    }
  };

  const handleRemoveScheduling = () => {
    task?.id &&
      dispatch(
        removeTaskSchedulingAction({
          id: task.id,
          associatedId: assetType === 'property' ? task.propertyId! : task.associatedId!,
          associationType: assetType === 'property' ? AssetType.RentalProperty : task.assetType!,
          includeClosed: selectedTask.value!.includeClosed,
        }),
      );
  };

  const handleLeaseSelection = (value?: string) => {
    setTask({ ...task, visibleToLeaseId: value });
    setDirty(true);
  };

  const handleSubmit = () => {
    if (task?.timeConfiguration) {
      checkTimeConfiguration(task.timeConfiguration, updateTask);
    } else updateTask();
    setDirty(false);
  };

  const handleChangeWatchers = (updatedWatchers: string[]) => {
    const watchers = task?.watchers?.map((watcher) => watcher.id ?? '') ?? [];
    const addUser = updatedWatchers.filter((watcher) => !watchers.some((w) => w === watcher));
    if (addUser.length === 1) {
      dispatch(
        addWatcherToTaskAction({
          taskId: task?.id ?? '',
          userId: addUser[0],
        }),
      );
    }

    const removeUser = watchers.filter((watcher) => !updatedWatchers.some((w) => w === watcher));
    if (removeUser.length === 1) {
      dispatch(
        removeWatcherFromTaskAction({
          taskId: task?.id ?? '',
          userId: removeUser[0],
        }),
      );
    }
  };

  if (!task) return <></>;

  return (
    <DialogLayout
      title={`Task: ${task.name}`}
      subtitle={task.assetPath}
      open={!!selectedTask.value}
      fullWidth
      fullScreen
      maxWidth={'lg'}
      onClose={handleClose}
    >
      <DialogContent sx={{ mt: '.1rem' }}>
        <Tabs value={tab} onChange={(e, v) => handleTabChange(e, v)}>
          <Tab value={TaskDialogTab.Info} label={'Information'} />
          <Tab value={TaskDialogTab.Description} label={'Description'} />
          <Tab value={TaskDialogTab.Documents} label={'Documents'} />
          <Tab value={TaskDialogTab.Comments} label={'Comments'} />
          <Tab value={TaskDialogTab.Communications} label={'Communications'} />
        </Tabs>
        {
          {
            info: (
              <TaskInfo
                task={task}
                onSetTask={handleSetTask}
                onSetTaskTimeConfiguration={handleSetTaskDate}
                onSetTaskTimeTracking={handleSetTaskTimeTracking}
                onCategorySelection={handleCategorySelection}
                onStatusSelection={handleStatusSelection}
                onAssigneeSelection={handleAssigneeChange}
                onRemoveScheduling={handleRemoveScheduling}
                onLeaseSelection={handleLeaseSelection}
                onUpdateWatchers={handleChangeWatchers}
              />
            ),
            description: (
              <>
                <StyledInfoBox label={'Description'}>
                  <TaskDescription onSetTask={handleSetTask} task={task} />
                </StyledInfoBox>
                <TaskAssociationList id={selectedTask.value?.id} associationType={AssociationType.Task} />
              </>
            ),
            documents: (
              <StyledInfoBox label={'Documents'}>
                <DocumentList
                  propertyId={selectedContext.propertyId}
                  association={{
                    associatedId: selectedTask.value?.id,
                    associationType: AssociationType.Task,
                  }}
                  value={selectedTask.value?.documents ?? []}
                  uploading={selectedTask.submitting}
                />
              </StyledInfoBox>
            ),
            comments: (
              <StyledInfoBox label={'Comments'}>
                <CommentList
                  comments={selectedTask.value?.comments ?? []}
                  onAddComment={handleAddComment}
                  loading={selectedTask.submitting}
                />
              </StyledInfoBox>
            ),
            communications: (
              <>
                <Emails
                  associationInfo={{
                    associatedId: task?.id,
                    associationType: AssociationType.Task,
                    emailAddresses: [],
                    label: task?.name ?? 'Task',
                  }}
                />
                <NotIntegrated>
                  <SmsView />
                </NotIntegrated>
              </>
            ),
          }[tab ?? TaskDialogTab.Info]
        }
        <DialogActions>
          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            <Button onClick={handleClose}>Close</Button>
            {tab === TaskDialogTab.Info || tab === TaskDialogTab.Description ? (
              <LoadingButton
                sx={{ ml: '1rem' }}
                variant={'contained'}
                disabled={!dirty}
                onClick={handleSubmit}
                loading={selectedTask.loading}
              >
                Update Task
              </LoadingButton>
            ) : (
              <></>
            )}
          </Box>
        </DialogActions>
      </DialogContent>
    </DialogLayout>
  );
};
