import React, { useEffect, useState } from 'react';
import { StyledInfoBox } from '../../../_shared/styledComponents/StyledInfoBox';
import { CreateLeaseWizard } from '../../../_shared/leases/components/leaseDialogs/CreateLeaseWizard';
import Grid from '@mui/material/Grid';
import { AddApplicantDialog } from './applicationDialogs/AddApplicantDialog';
import { GridFilterModel, GridRowId, GridSortModel } from '@mui/x-data-grid-premium';
import { SendApplication } from './applicationDialogs/SendApplication';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { ApplicantTable } from './applicantTable/ApplicantTable';
import {
  ApplicationType,
  DefaultSelection,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  getApplicationsByPropertyIdAction,
  resetApplicationSubmissionAction,
  setApplicantTableFiltersAction,
  setApplicantTableSortAction,
  toggleIncludeClosedStatusesAction,
  useApplications,
} from '../../redux/applicationSlice';
import { useDispatch } from 'react-redux';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useNavigate } from 'react-router-dom';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { ReduxApplication } from '../../redux/applicationTypes';
import { formatReduxFilters } from '../../../_shared/utils/filteringUtils';
import Button from '@mui/material/Button';
import { BankAccountNeededDialog } from './applicationDialogs/BankAccountNeededDialog';

export const ApplicantList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const property = useProperty();
  const applications = useApplications();
  const [openLeaseWizard, setOpenLeaseWizard] = useState(false);
  const [openAddApplicant, setOpenAddApplicant] = useState(false);
  const [openSendApplication, setOpenSendApplication] = useState(false);
  const [rows, setRows] = useState<ReduxApplication[]>([]);
  const [selectedRow, setSelectedRow] = useState<ReduxApplication | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<ReduxApplication[]>([]);
  const [includeClosedStatuses, setIncludeClosedStatuses] = useState(applications.includeClosedStatuses);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [openAddBankAccount, setOpenAddBankAccount] = useState(false);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  useEffect(() => {
    property.selectedProperty.value?.id &&
      dispatch(
        getApplicationsByPropertyIdAction({
          rentalPropertyId: property.selectedProperty.value.id,
          applicationType: ApplicationType.Primary,
          page: page,
          pageSize: pageSize,
        }),
      );
  }, [page, pageSize]);

  useEffect(() => {
    setSelectedRow(applications.selectedApplication.value);
  }, [applications.selectedApplication.value]);

  useEffect(() => {
    setRows(applications.allApplications.value ?? []);
  }, [applications.allApplications.value]);

  useEffect(() => {
    setRowCount(applications.allApplications.totalCount ?? 0);
  }, [applications.allApplications.totalCount]);

  const handleSortModelChange = (model: GridSortModel) => {
    if (!property.selectedProperty.value?.id) return;
    if (model.length === 0) {
      dispatch(
        setApplicantTableSortAction({
          rentalPropertyId: property.selectedProperty.value?.id,
          applicationType: ApplicationType.Primary,
          sortBy: undefined,
          sortDirection: undefined,
        }),
      );
    } else
      dispatch(
        setApplicantTableSortAction({
          rentalPropertyId: property.selectedProperty.value?.id,
          applicationType: ApplicationType.Primary,
          sortBy: model[0].field as keyof ReduxApplication,
          sortDirection: model[0].sort === 'asc' ? 'Ascending' : 'Descending',
        }),
      );
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    if (property.selectedProperty.value?.id) {
      if (model.items.length === 0) {
        // this clears the filters when they are removed
        dispatch(
          setApplicantTableFiltersAction({
            rentalPropertyId: property.selectedProperty.value?.id,
            applicationType: ApplicationType.Primary,
            filters: undefined,
          }),
        );
      } else {
        const filters = formatReduxFilters(model);
        dispatch(
          setApplicantTableFiltersAction({
            rentalPropertyId: property.selectedProperty.value?.id,
            applicationType: ApplicationType.Primary,
            filters,
          }),
        );
      }
    }
  };

  const handleSwitchToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeClosedStatuses(event.target.checked);
    property.selectedProperty.value?.id &&
      dispatch(
        toggleIncludeClosedStatusesAction({
          includeClosedStatuses: event.target.checked,
          rentalPropertyId: property.selectedProperty.value?.id,
          applicationType: ApplicationType.Primary,
        }),
      );
  };

  const handleOpenEditApplicant = (id: GridRowId) => {
    const row = rows.find((row) => row.id === id);
    if (row) {
      setSelectedRow(row);
      navigate(`application/${row.id}/info`);
    } else {
      dispatch(showToastMessageAction({ message: 'Sorry, something went wrong :(', severity: 'error' }));
    }
  };

  const handleCreateLeaseClick = (individualApplicationId?: GridRowId) => {
    const propertyHasOnboardedGeneralAccount = property.selectedProperty.value?.rentalBillingInfo?.find((acct) =>
      acct.bankAccount?.defaultSelection?.includes(DefaultSelection.General),
    );
    const propertyHasOnboardedDepositAccount = property.selectedProperty.value?.rentalBillingInfo?.find((acct) =>
      acct.bankAccount?.defaultSelection?.includes(DefaultSelection.Deposit),
    );
    if (propertyHasOnboardedGeneralAccount && propertyHasOnboardedDepositAccount) {
      if (individualApplicationId) {
        const row = rows.find((row) => row.id === individualApplicationId);
        setSelectedRows(row ? [row] : []);
      }
      handleOpenLeaseWizard();
    } else {
      setOpenAddBankAccount(true);
    }
  };

  const handleOpenLeaseWizard = () => {
    setOpenLeaseWizard(true);
  };

  const handleCloseLeaseWizard = () => {
    setOpenLeaseWizard(false);
  };

  const handleOpenAddApplicant = () => {
    dispatch(resetApplicationSubmissionAction());
    setOpenAddApplicant(true);
  };

  const handleCloseAddApplicant = () => {
    setOpenAddApplicant(false);
  };

  const handleSendApplication = (id: GridRowId) => {
    const row = rows.find((row) => row.id === id);
    setSelectedRow(row);
    setOpenSendApplication(true);
  };

  const handleCloseSendApplication = () => {
    setOpenSendApplication(false);
    setSelectedRow(undefined);
  };

  const columnVisibility = {};

  return (
    <StyledInfoBox label={'Applicant List'}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Button
            variant="outlined"
            sx={{ mr: '1rem' }}
            disabled={selectedRows.length === 0}
            onClick={() => handleCreateLeaseClick()}
          >
            Create Lease
          </Button>
          <Button variant="outlined" onClick={handleOpenAddApplicant}>
            Add Applicant
          </Button>
        </Grid>
        <Grid item container justifyContent="right" xs={7}>
          <FormGroup>
            <FormControlLabel
              control={<Switch size="small" checked={includeClosedStatuses} onChange={handleSwitchToggle} />}
              label="Include Closed Applications"
            />
          </FormGroup>
        </Grid>
        <Grid item sx={{ height: 400, width: '100%' }}>
          <ApplicantTable
            rows={rows}
            loading={applications.allApplications.loading}
            onOpenEditApplicant={handleOpenEditApplicant}
            onSendApplication={handleSendApplication}
            columnVisibility={columnVisibility}
            checkboxSelection={true}
            selectedRows={selectedRows}
            onSelectedRowsChange={setSelectedRows}
            disableColumnFilter={false}
            onFilterModelChange={handleFilterModelChange}
            pagination={true}
            page={page}
            pageSize={pageSize}
            rowCount={rowCount}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePageChange}
            onSortModelChange={handleSortModelChange}
            onOpenCreateLease={handleCreateLeaseClick}
          />
        </Grid>
      </Grid>
      <CreateLeaseWizard
        open={openLeaseWizard}
        onClose={handleCloseLeaseWizard}
        propertyId={property.selectedProperty.value?.id}
        applicants={selectedRows}
      />
      <AddApplicantDialog
        propertyId={property.selectedProperty.value?.id}
        open={openAddApplicant}
        onClose={handleCloseAddApplicant}
      />
      {selectedRow && (
        <SendApplication open={openSendApplication} onClose={handleCloseSendApplication} applicant={selectedRow} />
      )}
      <BankAccountNeededDialog onClose={() => setOpenAddBankAccount(false)} open={openAddBankAccount} />
    </StyledInfoBox>
  );
};
