import React, { FC, useEffect, useState } from 'react';
import { StatementView } from './StatementView';
import { StatementMonthSelectorProps } from './StatementPeriodSelector';
import { useStatement } from '../redux/statementSlice';
import { StatementTimeSelectorProps } from './StatementTimeSelector';
import Stack from '@mui/material/Stack';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Period } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { StatementPeriodType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { useStatementConfig } from '../useStatementConfig';
import { ReduxEntry } from '../redux/statementTypes';

type LeaseStatementProps = {
  showActions?: boolean;
  isTenantPortalView?: boolean;
  showPeriodSelector?: boolean;
  onOpenPopupStatement?: (statementLine?: ReduxEntry, subAccountId?: string) => void;
  leaseId?: string;
};

export type StatementType = 'current' | 'relative' | 'custom';

export const LeaseStatement: FC<LeaseStatementProps> = ({
  leaseId,
  showActions,
  isTenantPortalView,
  showPeriodSelector,
  onOpenPopupStatement,
}) => {
  const {
    selectedContext: { propertyId },
  } = useAssets();
  const { selectedStatement, statementPeriods } = useStatement();
  const { handleFetchLeaseStatementPeriods, handleFetchLeaseStatement, handleRollbackDeposit, handleMarkAsPosted } =
    useStatementConfig();
  const [periodValue, setPeriodValue] = useState<Period>();
  const [customDateRange, setCustomDateRange] = React.useState<DateRange<Date> | undefined>(undefined);
  const [selectedPeriodType, setSelectedPeriodType] = React.useState<StatementPeriodType>(StatementPeriodType.Month);
  const [statementType, setStatementType] = React.useState<StatementType>('current');

  //fetch periods when periodType changes
  useEffect(() => {
    handleFetchLeaseStatementPeriods(leaseId, selectedPeriodType);
  }, [propertyId, leaseId, selectedPeriodType]);

  //if our periods change, default to the first period
  useEffect(() => {
    if (statementPeriods.value) setPeriodValue(statementPeriods.value[0]);
  }, [statementPeriods.value]);

  //refetch statement when periodValue, custom dates changes
  useEffect(() => {
    handleFetchLeaseStatement(
      customDateRange,
      selectedPeriodType,
      statementType,
      periodValue,
      leaseId,
      isTenantPortalView,
    );
  }, [periodValue, statementType, customDateRange, leaseId]);

  function onRollbackDeposit(rowId: string) {
    handleRollbackDeposit(rowId, propertyId);
  }

  function onMarkAsPosted(dateDeposited: Date | null, selectedJeId: string | undefined) {
    handleMarkAsPosted(dateDeposited, selectedJeId, propertyId);
  }

  const periodSettings: StatementMonthSelectorProps | undefined =
    (!showPeriodSelector && {
      periods: statementPeriods.value,
      selectedPeriod: periodValue,
      onPeriodSelection: setPeriodValue,
      selectedPeriodType: selectedPeriodType,
    }) ||
    undefined;

  const timeSettings: StatementTimeSelectorProps | undefined =
    (showPeriodSelector && {
      dateRange: customDateRange,
      setDateRange: setCustomDateRange,
      selectedPeriodType,
      setSelectedPeriodType,
      onPeriodSelection: setPeriodValue,
      periodValue,
      statementType,
      setStatementType,
      periods: statementPeriods.value,
    }) ||
    undefined;

  return leaseId ? (
    <Stack spacing={3}>
      <StatementView
        fullView
        statement={selectedStatement.value}
        loading={selectedStatement.loading}
        submitting={selectedStatement.submitting}
        showActions={showActions}
        isTenantPortalView={isTenantPortalView}
        statementPeriod={selectedPeriodType}
        periodSettings={periodSettings!}
        timeSettings={timeSettings!}
        onRollbackDeposit={onRollbackDeposit}
        onMarkAsPosted={onMarkAsPosted}
        showDepositChip={!isTenantPortalView && leaseId !== undefined ? true : false}
        onRefreshData={() =>
          handleFetchLeaseStatement(
            customDateRange,
            selectedPeriodType,
            statementType,
            periodValue,
            leaseId,
            isTenantPortalView,
          )
        }
        onOpenPopupStatement={onOpenPopupStatement}
      />
    </Stack>
  ) : (
    <></>
  );
};
