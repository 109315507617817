import * as React from 'react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import {
  createPropertyAction,
  createSimulatedPropertyAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { IAddPropertyHandlerRequest } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { StyledInfoBox } from '../../../_shared/styledComponents/StyledInfoBox';
import { appTheme } from '../../../../AppTheme';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { PhoneTextField } from '../../../_shared/texfields/PhoneTextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';
import { NumberValueTextField } from '../../../_shared/texfields/NumberValueTextField';
import { USStatesSelect } from '../../../_shared/texfields/USStateSelect';

type Props = {
  onClose: () => void;
  open: boolean;
};

export const AddProperty = ({ onClose, open }: Props) => {
  const dispatch = useDispatch();
  const { selectedProperty } = useProperty();
  const userRoles = useUser((u) => u.currentUser.value?.roles);
  const [newProperty, setNewProperty] = useState<IAddPropertyHandlerRequest>({
    name: '',
    details: {
      mailingAddress: {
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        zip: '',
      },
      serviceProviders: [],
      siteManagerName: '',
      siteManagerContactInfo: {
        postalAddress: {
          streetAddress1: '',
          streetAddress2: '',
          city: '',
          state: '',
          zip: '',
        },
        email: '',
        phone: '',
        website: '',
      },
    },
  });

  const handleClose = () => {
    setNewProperty({
      name: '',
      details: {
        mailingAddress: {
          streetAddress1: '',
          streetAddress2: '',
          city: '',
          state: '',
          zip: '',
        },
        serviceProviders: [],
        siteManagerName: '',
        siteManagerContactInfo: {
          postalAddress: {
            streetAddress1: '',
            streetAddress2: '',
            city: '',
            state: '',
            zip: '',
          },
          email: '',
          phone: '',
          website: '',
        },
      },
    });
    onClose();
  };

  useEffect(() => {
    if (selectedProperty.submitted) handleClose();
  }, [selectedProperty.submitted]);

  const handleName = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNewProperty({ ...newProperty, name: e.target.value });
  };

  const handleAddress = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, key: string) => {
    setNewProperty({
      ...newProperty,
      details: {
        ...newProperty.details,
        mailingAddress: { ...(newProperty.details?.mailingAddress ?? {}), [key]: e.target.value },
      },
    });
  };

  const handleSiteManagerName = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNewProperty({ ...newProperty, details: { ...newProperty.details, siteManagerName: e.target.value } });
  };

  const handleSiteManagerContact = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, key: string) => {
    setNewProperty({
      ...newProperty,
      details: {
        ...newProperty.details,
        siteManagerContactInfo: {
          ...newProperty.details?.siteManagerContactInfo,
          [key]: e.target.value,
        },
      },
    });
  };

  const handleSiteManagerAddress = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, key: string) => {
    setNewProperty({
      ...newProperty,
      details: {
        ...newProperty.details,
        siteManagerContactInfo: {
          ...newProperty.details?.siteManagerContactInfo,
          postalAddress: {
            ...newProperty.details?.siteManagerContactInfo?.postalAddress,
            [key]: e.target.value,
          },
        },
      },
    });
  };

  const addProperty = (e: FormEvent) => {
    e.preventDefault();
    dispatch(createPropertyAction(newProperty));
  };

  const addSimulatedProperty = () => {
    dispatch(createSimulatedPropertyAction());
  };

  const handleSelectState = (state: string) => {
    setNewProperty({
      ...newProperty,
      details: { ...newProperty.details, mailingAddress: { ...newProperty.details?.mailingAddress, state: state } },
    });
  };

  return (
    <DialogLayout title={'Add Property'} maxWidth="md" fullWidth={true} open={open} onClose={handleClose}>
      <DialogContent>
        {userRoles && userRoles.includes('SUPERADMIN') && (
          <Box sx={{ my: '1rem' }}>
            <Button
              onClick={addSimulatedProperty}
              variant="contained"
              sx={{ backgroundColor: appTheme.palette.warning.main }}
            >
              Add Auto-Gen Simulated Property
            </Button>
          </Box>
        )}
        <form onSubmit={addProperty} autoComplete="off">
          <Stack spacing={2}>
            <StyledInfoBox label={'Property Info'}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    margin="dense"
                    id="propertyName"
                    label="Property Name"
                    type="text"
                    variant="standard"
                    onChange={handleName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    margin="dense"
                    id="streetAddress1"
                    label="Street Address 1"
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      handleAddress(e, 'streetAddress1');
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="dense"
                    id="streetAddress2"
                    label="Street Address 2"
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      handleAddress(e, 'streetAddress2');
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    margin="dense"
                    id="city"
                    label="City"
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      handleAddress(e, 'city');
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <USStatesSelect
                    value={newProperty.details?.mailingAddress?.state ?? ''}
                    onChange={handleSelectState}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberValueTextField
                    fullWidth
                    required
                    valueUnits={'none'}
                    margin="dense"
                    id="zip"
                    label="Zip"
                    variant="standard"
                    onChange={(e) => {
                      handleAddress(e, 'zip');
                    }}
                  />
                </Grid>
                {userRoles && userRoles.includes('SUPERADMIN') && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: appTheme.palette.warning.main,
                            '&.Mui-checked': { color: appTheme.palette.warning.main },
                          }}
                          checked={newProperty.isSimulated ? newProperty.isSimulated : false}
                          onChange={(e) => {
                            setNewProperty({ ...newProperty, isSimulated: e.target.checked });
                          }}
                        />
                      }
                      label="This is a simulated property"
                    />
                  </Grid>
                )}
              </Grid>
            </StyledInfoBox>
            <StyledInfoBox label={'Site Manager Info'}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    margin="dense"
                    id="siteManagerName"
                    label="Site Manager Name"
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      handleSiteManagerName(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PhoneTextField
                    fullWidth
                    required
                    margin="dense"
                    id="managerPhone"
                    label="Phone Number"
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      handleSiteManagerContact(e, 'phone');
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="dense"
                    id="managerEmail"
                    label="Email"
                    type="email"
                    variant="standard"
                    onChange={(e) => {
                      handleSiteManagerContact(e, 'email');
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="dense"
                    id="managerStreetAddress1"
                    label="Street Address 1"
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      handleSiteManagerAddress(e, 'streetAddress1');
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="dense"
                    id="managerStreetAddress2"
                    label="Street Address 2"
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      handleSiteManagerAddress(e, 'streetAddress2');
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="dense"
                    id="managerCity"
                    label="City"
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      handleSiteManagerAddress(e, 'city');
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="dense"
                    id="managerState"
                    label="State"
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      handleSiteManagerAddress(e, 'state');
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="dense"
                    id="managerZip"
                    label="Zip"
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      handleSiteManagerAddress(e, 'zip');
                    }}
                  />
                </Grid>
              </Grid>
            </StyledInfoBox>
          </Stack>
          <Grid item xs={12}>
            <DialogActions sx={{ marginTop: '1rem' }}>
              <Button onClick={onClose}>Close Without Saving</Button>
              <LoadingButton type={'submit'} variant="contained" loading={selectedProperty.submitting}>
                Add Property
              </LoadingButton>
            </DialogActions>
          </Grid>
        </form>
      </DialogContent>
    </DialogLayout>
  );
};
