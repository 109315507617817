import React, { FC, FormEvent, useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  AssetType,
  AssociationType,
  IAssociation,
  TaskSection,
  TaskType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { useDispatch } from 'react-redux';
import {
  addScheduledTaskAction,
  addScheduledTaskWithDetailAssociationAction,
  addScheduledTaskWithEmailThreadAssociationAction,
  addScheduledTaskWithPurchaseOrderLineItemAssociationAction,
  createScheduledTaskFromSopTaskAction,
  createScheduledTaskFromSopTaskWithDetailAssociationAction,
  createScheduledTaskFromSopTaskWithEmailThreadAssociationAction,
  createScheduledTaskFromSopTaskWithPurchaseOrderLineItemAssociationAction,
  useScheduledTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/scheduledTaskSlice';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { AddTaskForm } from '../AddTaskForm';
import { addDays, addHours } from 'date-fns';
import { ReduxTask, ReduxTimeConfiguration } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import {
  endpointIsntIntegratedAction,
  setBackUrlAction,
  showToastMessageAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { ScheduleTimeConfiguration } from './ScheduleTimeConfiguration';
import { SopTaskAutocomplete } from './SopTaskAutocomplete';
import { useTimeConfigurationChecks } from '../useTimeConfigurationChecks';
import TextField from '@mui/material/TextField';
import { AssociateWithCurrentLeaseToggle } from '../AssociateWithCurrentLeaseToggle';
import { useParams } from 'react-router-dom';
import { AssetParams } from '../../../../../AppRouter';
import { getAssetTypeFromPathname } from '../../../utils/getAssetTypeFromPathname';

type AddScheduledTaskProps = {
  onClose: () => void;
  assetId?: string;
  assetType?: AssetType;
  isSopAdd: boolean;
  page?: number;
  pageSize?: number;
  createdFromAssociation?: {
    id?: string;
    type?: AssociationType;
    poInfo?: {
      poNumber: string;
      lineItemNumber: string;
      changeOrderId?: string;
    };
  };
  localSopTaskAssociation?: IAssociation;
  value?: ReduxTask;
  taskSection?: TaskSection;
};

export const blankTaskTemplate: ReduxTask = {
  name: '',
  description: '',
  taskType: TaskType.Normal,
};

export const AddScheduledTask: FC<AddScheduledTaskProps> = ({
  onClose,
  assetType,
  assetId,
  isSopAdd,
  page,
  pageSize,
  localSopTaskAssociation,
  createdFromAssociation,
  value,
  taskSection,
}) => {
  const { selectedScheduledTask } = useScheduledTasks();
  const { selectedContext } = useAssets();
  const dispatch = useDispatch();
  const initializeDate = selectedContext.currentDate
    ? addDays(toStandardDate(selectedContext.currentDate), 1)
    : undefined;
  const blankTimeConfiguration: ReduxTimeConfiguration = {
    startDateTime: initializeDate ? addHours(initializeDate, 6).toISOString() : undefined, //beginning of day
    endDateTime: initializeDate ? addHours(initializeDate, 18).toISOString() : undefined, //EOD
    isAllDay: true,
  };
  const [timeConfiguration, setTimeConfiguration] = useState<ReduxTimeConfiguration>(blankTimeConfiguration);
  const [newTaskTemplate, setNewTaskTemplate] = useState<ReduxTask>(value ?? blankTaskTemplate);
  const [configId, setConfigId] = useState<string | undefined>();
  const [daysPriorToStartDateToConvert, setDaysPriorToStartDateToConvert] = useState(0);
  const { checkTimeConfiguration } = useTimeConfigurationChecks();
  const [associateWithCurrentLease, setAssociateWithCurrentLease] = useState<boolean>(false);
  const { id, outerTab, innerTab } = useParams<AssetParams>();

  const parentPath = `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}`;

  useEffect(() => {
    selectedScheduledTask.submitted && handleClose();
  }, [selectedScheduledTask.submitted]);

  const handleCreateTaskFromSopTask = () => {
    if (!configId) {
      dispatch(showToastMessageAction({ message: 'An SOP task must be selected', severity: 'error' }));
    } else if (!assetType || !assetId) {
      dispatch(showToastMessageAction({ message: 'An associated asset must be selected', severity: 'error' }));
    } else {
      const body = {
        propertyId: selectedContext.propertyId!,
        buildingId:
          assetType === AssetType.Building ? assetId : AssetType.BuildingUnit ? selectedContext.buildingId : undefined,
        unitId: assetType === AssetType.BuildingUnit ? assetId : undefined,
        timeConfig: {
          ...timeConfiguration,
          startDateTime: timeConfiguration.startDateTime,
          endDateTime: timeConfiguration.endDateTime,
        },
        daysPriorToStartDayToConvert: daysPriorToStartDateToConvert,
        associateWithCurrentLease: associateWithCurrentLease,
        taskSection: taskSection,
      };
      switch (createdFromAssociation?.type) {
        case undefined:
          page !== undefined &&
            pageSize &&
            dispatch(
              createScheduledTaskFromSopTaskAction({
                configId,
                assetType,
                assetId,
                page,
                pageSize,
                body: body,
              }),
            );
          break;
        case AssociationType.ExpensePurchaseOrder:
          if (
            !createdFromAssociation.id ||
            !createdFromAssociation.poInfo?.poNumber ||
            !createdFromAssociation.poInfo?.lineItemNumber
          ) {
            console.log('conditions not met');
          }
          createdFromAssociation?.id &&
            createdFromAssociation?.poInfo?.poNumber &&
            createdFromAssociation?.poInfo?.lineItemNumber &&
            dispatch(
              createScheduledTaskFromSopTaskWithPurchaseOrderLineItemAssociationAction({
                configId: configId,
                expenseId: createdFromAssociation.id,
                poNumber: createdFromAssociation.poInfo?.poNumber,
                changeOrderId: createdFromAssociation.poInfo.changeOrderId,
                lineItemNumber: createdFromAssociation.poInfo?.lineItemNumber,
                body: body,
                parentPath: parentPath,
              }),
            );
          dispatch(setBackUrlAction(location.pathname));
          break;
        case AssociationType.Detail:
          createdFromAssociation.id &&
            dispatch(
              createScheduledTaskFromSopTaskWithDetailAssociationAction({
                configId: configId,
                detailId: createdFromAssociation.id,
                body: body,
                parentPath: parentPath,
              }),
            );
          dispatch(setBackUrlAction(location.pathname));
          break;
        case AssociationType.EmailThread:
          createdFromAssociation.id &&
            dispatch(
              createScheduledTaskFromSopTaskWithEmailThreadAssociationAction({
                configId: configId,
                emailThreadId: createdFromAssociation.id,
                body: body,
                parentPath: parentPath,
              }),
            );
          dispatch(setBackUrlAction(location.pathname));
          break;
        default:
          dispatch(endpointIsntIntegratedAction());
      }
    }
  };

  const handleManuallyCreateScheduledTask = () => {
    const newTask = {
      propertyId: selectedContext.propertyId,
      buildingId:
        assetType === AssetType.Building ? assetId : AssetType.BuildingUnit ? selectedContext.buildingId : undefined,
      unitId: assetType === AssetType.BuildingUnit ? assetId : undefined,
      timeConfiguration: {
        ...timeConfiguration,
        startDateTime: timeConfiguration.startDateTime,
        endDateTime: timeConfiguration.endDateTime,
      },
      task: { ...newTaskTemplate, taskSection: taskSection },
      daysPriorToStartDateToConvert: daysPriorToStartDateToConvert,
      associateWithCurrentLease: associateWithCurrentLease,
    };
    switch (createdFromAssociation?.type) {
      case undefined:
        selectedContext.propertyId &&
          page !== undefined &&
          pageSize &&
          dispatch(
            addScheduledTaskAction({
              newTask: newTask,
              page,
              pageSize,
            }),
          );
        break;
      case AssociationType.ExpensePurchaseOrder:
        if (
          !createdFromAssociation.id ||
          !createdFromAssociation.poInfo?.poNumber ||
          !createdFromAssociation.poInfo?.lineItemNumber
        ) {
          console.log('conditions not met');
        }
        createdFromAssociation.id &&
          createdFromAssociation.poInfo?.poNumber &&
          createdFromAssociation.poInfo?.lineItemNumber &&
          dispatch(
            addScheduledTaskWithPurchaseOrderLineItemAssociationAction({
              newTask: newTask,
              expenseId: createdFromAssociation.id,
              purchaseOrderNumber: createdFromAssociation.poInfo?.poNumber,
              changeOrderId: createdFromAssociation.poInfo.changeOrderId,
              lineItemNumber: createdFromAssociation.poInfo?.lineItemNumber,
              parentPath: parentPath,
            }),
          );
        dispatch(setBackUrlAction(location.pathname));
        break;
      case AssociationType.Detail:
        createdFromAssociation.id &&
          dispatch(
            addScheduledTaskWithDetailAssociationAction({
              newTask: newTask,
              detailId: createdFromAssociation.id,
              parentPath: parentPath,
            }),
          );
        dispatch(setBackUrlAction(location.pathname));
        break;
      case AssociationType.EmailThread:
        createdFromAssociation.id &&
          dispatch(
            addScheduledTaskWithEmailThreadAssociationAction({
              newTask: newTask,
              emailThreadId: createdFromAssociation.id,
              parentPath: parentPath,
            }),
          );
        dispatch(setBackUrlAction(location.pathname));
        break;
      default:
        dispatch(endpointIsntIntegratedAction());
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isSopAdd) {
      checkTimeConfiguration(timeConfiguration, handleCreateTaskFromSopTask);
    } else {
      checkTimeConfiguration(timeConfiguration, handleManuallyCreateScheduledTask);
    }
  };

  const handleClose = () => {
    setNewTaskTemplate(blankTaskTemplate);
    setTimeConfiguration(blankTimeConfiguration);
    setConfigId(undefined);
    onClose();
  };

  const handleChangeTaskTemplate = (value: ReduxTask) => {
    setNewTaskTemplate(value);
  };

  const handleSetTimeConfiguration = (config: ReduxTimeConfiguration) => {
    setTimeConfiguration(config);
  };

  const handleSopTaskSelection = (configId: string | null) => {
    if (configId === null) setConfigId(undefined);
    else setConfigId(configId);
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        {isSopAdd ? (
          <SopTaskAutocomplete
            onValueChange={handleSopTaskSelection}
            value={configId}
            localSopTaskAssociation={localSopTaskAssociation}
          />
        ) : (
          <AddTaskForm
            onChange={handleChangeTaskTemplate}
            value={newTaskTemplate}
            propertyId={selectedContext.propertyId}
          />
        )}
        <AssociateWithCurrentLeaseToggle
          value={associateWithCurrentLease}
          onChange={() => setAssociateWithCurrentLease(!associateWithCurrentLease)}
          inScheduledTask
        />
        <TextField
          fullWidth
          label={'Days Before Start Date to Schedule'}
          required
          type={'number'}
          value={daysPriorToStartDateToConvert}
          onChange={(e) => setDaysPriorToStartDateToConvert(Number(e.target.value))}
        />
        <ScheduleTimeConfiguration
          timeConfiguration={timeConfiguration}
          onSetTimeConfiguration={handleSetTimeConfiguration}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <LoadingButton type={'submit'} variant={'contained'} loading={selectedScheduledTask.submitting}>
          Schedule Task
        </LoadingButton>
      </DialogActions>
    </form>
  );
};
