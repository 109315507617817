import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { format } from 'date-fns';
import { cancelPreLeaseAction, useLeases } from '../../redux/leasesSlice';
import { LeaseDispatchType, ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ApplicationStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import LoadingButton from '@mui/lab/LoadingButton';
import { DialogLayout } from '../../../dialogs/DialogLayout';

type Props = {
  onClose: () => void;
  open: boolean;
  row: ReduxLease;
  leaseType: LeaseDispatchType;
  assetId: string;
};

export const CancelPreLeaseDialog = ({ onClose, open, row, leaseType, assetId }: Props) => {
  const dispatch = useDispatch();
  const { activeLease } = useLeases();
  const [newApplicationStatus, setNewApplicationStatus] = useState<ApplicationStatus | undefined>(undefined);

  useEffect(() => {
    if (activeLease.submitted) onClose();
  }, [activeLease.submitted]);

  const handleCancelPreLease = () => {
    if (!newApplicationStatus) {
      dispatch(
        showToastMessageAction({
          message: 'Please select a status to update applications with',
          severity: 'warning',
        }),
      );
      return;
    }
    const applicationIds = row?.leaseTerms
      ?.flatMap((term) => term.tenantGroup?.tenants?.map((tenant) => tenant.applicationId))
      .filter((id) => id !== undefined) as string[];
    row?.id &&
      dispatch(
        cancelPreLeaseAction({
          leaseId: row.id,
          propertyId: row.propertyId!,
          applicationIds: applicationIds ?? [],
          newStatus: newApplicationStatus,
          assetId: assetId,
          leasesType: leaseType,
        }),
      );
  };

  const handleUpdateApplicationStatuses = (e: SelectChangeEvent) => {
    setNewApplicationStatus(e.target.value as ApplicationStatus);
  };

  return (
    <DialogLayout title={'Cancel Pre-Lease'} open={open} onClose={onClose}>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: '1rem' }}>
          <DialogContentText>
            This lease status is '{row?.processStatus}' with a start date of{' '}
            {row?.startDate ? format(Date.parse(row.startDate), 'MM/dd/yyyy') : ''}. If you are sure you would like to
            cancel this pre-lease, please select a status to update all related applications with:
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel id="select-status">Select Updated Status</InputLabel>
            <Select
              labelId="select-status"
              id="status"
              value={newApplicationStatus ?? ''}
              label="Select Updated Status"
              onChange={(e) => handleUpdateApplicationStatuses(e)}
            >
              <MenuItem value={ApplicationStatus.Approved}>Approved</MenuItem>
              <MenuItem value={ApplicationStatus.ApprovedWithCosigner}>Approved With Cosigner</MenuItem>
              <MenuItem value={ApplicationStatus.Withdrawn}>Withdrawn</MenuItem>
              <MenuItem value={ApplicationStatus.Denied}>Denied</MenuItem>
            </Select>
          </FormControl>
          <DialogActions>
            <Button onClick={onClose}>Go Back</Button>
            <LoadingButton onClick={handleCancelPreLease} variant="contained" loading={activeLease.submitting}>
              Cancel Pre-lease
            </LoadingButton>
          </DialogActions>
        </Stack>
      </DialogContent>
    </DialogLayout>
  );
};
