import React, { FC, FormEvent } from 'react';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { AccountAutocomplete } from '../../autocompletes/AccountAutocomplete';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import {
  AccountType,
  ISlimAccountRef,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Stack from '@mui/material/Stack';
import { NumberValueTextField } from '../../texfields/NumberValueTextField';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { FundsFlowDirection } from '@monkeyjump-labs/cam-fe-shared/dist/types/propertyTypes';
import { ReduxDate, toReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

type AddOrWithdrawFundsFormProps = {
  onSubmit: (secondaryAccount: ISlimAccountRef, description: string, amount: number, date: ReduxDate) => void;
  onClose: () => void;
  flowOfFundsDirection?: FundsFlowDirection;
  loading: boolean;
};

export const AddOrWithdrawFundsForm: FC<AddOrWithdrawFundsFormProps> = ({
  onSubmit,
  onClose,
  flowOfFundsDirection,
  loading,
}) => {
  const { selectedContext } = useAssets();
  const [secondaryAccount, setSecondaryAccount] = React.useState<ISlimAccountRef>();
  const [description, setDescription] = React.useState<string | undefined>(undefined);
  const [amount, setAmount] = React.useState<number | undefined>(undefined);
  const [date, setDate] = React.useState<Date | null>(null);

  return (
    <form
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        if (!secondaryAccount || !description || !amount || !date) return; //form should prevent this
        onSubmit(secondaryAccount, description, amount, toReduxDate(date));
      }}
    >
      <DialogContent>
        <Stack spacing={2}>
          <NumberValueTextField
            valueUnits={'dollars'}
            autoFocus
            required
            label="Amount"
            type="number"
            value={amount ?? ''}
            onChange={(e) => setAmount(Number(e.target.value))}
          />
          <TextField
            label="Description"
            required
            value={description ?? ''}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={3}
          />
          <DatePicker
            label="Date of Transaction"
            value={date}
            onChange={(date) => setDate(date)}
            slotProps={{ textField: { required: true } }}
          />
          <AccountAutocomplete
            propertyId={selectedContext.propertyId!}
            value={secondaryAccount}
            onChange={(value: ISlimAccountRef) => setSecondaryAccount(value)}
            renderInput={(params) => (
              <TextField
                required
                label="Secondary Account"
                variant={'standard'}
                {...params}
                helperText={`Select which GL Account the funds are ${flowOfFundsDirection === 'Add Funds' ? 'coming from' : 'allocated for'}`}
              />
            )}
            filterOptions={(o) =>
              o.filter(
                (option) =>
                  option.accountType === AccountType.Assets ||
                  option.accountType === AccountType.Equity ||
                  option.accountType === AccountType.Liabilities,
              )
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton type="submit" variant={'contained'} loading={loading}>
          {flowOfFundsDirection ?? 'Submit'}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};
