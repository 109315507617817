import React from 'react';
import Container from '@mui/material/Container';
import { PhoneSettings } from './PhoneSettings';
import Stack from '@mui/material/Stack';
import { UserInfo } from './UserInfo';
import { ProfilePicture } from './ProfilePicture';
import Box from '@mui/material/Box';
import { Alerts } from './Alerts';
import { BackButton } from '../../../_shared/buttons/BackButton';

export const Profile = () => {
  return (
    <Container
      maxWidth={'md'}
      sx={{
        padding: '1rem',
        height: '100%',
        minHeight: 'calc(100vh - 104px)',
      }}
    >
      <Stack spacing={1}>
        <BackButton />
        <UserInfo />
        <Box display={'flex'} flexGrow={1} justifyContent={'space-between'}>
          <Box width={'50%'} mr={1}>
            <PhoneSettings />
          </Box>
          <Box width={'50%'}>
            <ProfilePicture />
          </Box>
        </Box>
        <Alerts />
      </Stack>
    </Container>
  );
};
