import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowModesModel,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { StripedDataGrid } from '../../../datagrids/StripedDataGrid';
import Box from '@mui/material/Box';
import { ReduxScheduledTask, ReduxTimeConfiguration } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { useScheduledTasks } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/scheduledTaskSlice';
import { tryFormatDate } from '../../../utils/TryFormatDate';
import { ActionCell } from '../../../datagrids/ActionCell';
import { AssigneeCell, AssigneeEditCell } from '../taskTables/taskTableCells/AssigneeCell';
import { CategoryCell, CategoryEditCell } from '../taskTables/taskTableCells/CategoryCell';
import {
  IGetUsersForAssetHandlerUserRoleInfo,
  ITaskCategory,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { AssetPathCell } from '../taskTables/taskTableCells/AssetPathCell';
import { useIcons } from '../../../icons/useIcons';

type ScheduledTaskTableProps = {
  onOpenEdit: (id: string) => void;
  onDeleteScheduledTask: (id: string) => void;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onUpdateScheduledTask: (task: ReduxScheduledTask, taskId: string) => void;
};

export const ScheduledTaskTable: FC<ScheduledTaskTableProps> = ({
  onOpenEdit,
  onDeleteScheduledTask,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  onUpdateScheduledTask,
}) => {
  const { getActionIcon, ActionType } = useIcons();
  const [rows, setRows] = useState<ReduxScheduledTask[]>([]);
  const { scheduledTasks } = useScheduledTasks();
  const [editRowsModel, setEditRowsModel] = useState<GridRowModesModel>({});

  const handleEditRowsModelChange = useCallback((model: GridRowModesModel) => {
    setEditRowsModel(model);
  }, []);

  useEffect(() => {
    scheduledTasks.value && setRows(scheduledTasks.value);
  }, [scheduledTasks.value]);

  const handleAssigneeChange = (newValue: IGetUsersForAssetHandlerUserRoleInfo | undefined, taskId: string) => {
    const task = rows.find((row) => row.id === taskId);
    if (!task) return;
    const updatedTask: ReduxScheduledTask = { ...task, task: { ...task.task, assignee: newValue } };
    onUpdateScheduledTask(updatedTask, taskId);
  };

  const handleCategoryChange = (value: ITaskCategory | null, taskId: string) => {
    const task = rows.find((row) => row.id === taskId);
    if (!task) return;
    const updatedTask: ReduxScheduledTask = { ...task, task: { ...task.task, category: value ?? undefined } };
    onUpdateScheduledTask(updatedTask, taskId);
  };

  const columns: GridColDef<ReduxScheduledTask>[] = [
    {
      field: 'taskName',
      headerName: 'Task',
      renderCell: (params: GridRenderCellParams<ReduxScheduledTask, string>) => {
        return (
          <Link sx={{ cursor: 'pointer' }} color="inherit">
            <Typography onClick={() => onOpenEdit(params.row.id!)} variant={'body2'}>
              {params.row.task?.name}
            </Typography>
          </Link>
        );
      },
      flex: 1,
      display: 'flex',
    },
    {
      field: 'associatedName',
      type: 'string',
      headerName: 'Asset',
      flex: 2,
      display: 'flex',
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<ReduxScheduledTask, string>) => {
        return <AssetPathCell {...params} />;
      },
    },
    {
      field: 'startDateTime',
      headerName: 'Scheduled Date',
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<ReduxScheduledTask, ReduxTimeConfiguration>) => {
        return (
          <Typography variant={'body2'}>{tryFormatDate(params.row.timeConfiguration?.startDateTime ?? '')}</Typography>
        );
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <CategoryCell {...params} isScheduled />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CategoryEditCell {...params} onChange={handleCategoryChange} isScheduled />;
      },
      flex: 1,
      display: 'flex',
    },
    {
      field: 'assignee',
      headerName: 'Assignee',
      editable: true,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => {
        return <AssigneeCell {...params} isScheduled />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <AssigneeEditCell {...params} onChange={handleAssigneeChange} isScheduled />;
      },
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      cellClassName: 'actions',
      getActions: (params: GridRowParams<ReduxScheduledTask>) => {
        return [
          <ActionCell
            icon={getActionIcon(ActionType.Delete)}
            key="delete"
            label="Delete Scheduled Task"
            className="textPrimary"
            color="inherit"
            onClick={() => params.row.id && onDeleteScheduledTask(params.row.id)}
            showInMenu={undefined}
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 1,
      }}
    >
      <StripedDataGrid
        disableRowGrouping
        autoHeight
        rowHeight={75}
        rows={rows}
        columns={columns}
        getRowClassName={(params) => {
          return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
        }}
        pagination
        paginationMode={'server'}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationModel={{ page: page, pageSize: pageSize }}
        onPaginationModelChange={(model: GridPaginationModel) => {
          onPageChange(model.page);
          onPageSizeChange(model.pageSize);
        }}
        rowCount={scheduledTasks.totalCount ?? 0}
        rowModesModel={editRowsModel}
        onRowModesModelChange={handleEditRowsModelChange}
        loading={scheduledTasks.loading}
      />
    </Box>
  );
};
