import React, { FormEvent } from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import { useApplications } from '../../property/redux/applicationSlice';

type Props = {
  steps: string[];
  activeStep: number;
  onNext: (e: FormEvent) => void;
  onBack: () => void;
  onReset?: () => void;
  onValidate?: () => boolean;
  continueButtonText?: string;
};

export const WizardStepper = ({ steps, activeStep, onNext, onBack, onValidate, continueButtonText }: Props) => {
  const { selectedApplication } = useApplications();
  const handleNext = (e: FormEvent) => {
    if (typeof onValidate === 'undefined' || onValidate()) onNext(e);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 8 }}>
          <Button
            variant={'outlined'}
            onClick={onBack}
            disabled={activeStep === 0 || selectedApplication.submitted}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
        </Box>
      ) : (
        <React.Fragment>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              variant={'outlined'}
              onClick={onBack}
              disabled={activeStep === 0 || selectedApplication.submitted}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep === steps.length - 1 ? (
              <></>
            ) : (
              <Button variant={'outlined'} onClick={handleNext}>
                {continueButtonText ?? 'Save and Continue'}
              </Button>
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};
