import React, { FC } from 'react';
import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';

type TextFieldWithHighlightsProps = {
  allowedKeys: string[];
} & TextFieldProps;

export const TextFieldWithHighlights: FC<TextFieldWithHighlightsProps> = ({ allowedKeys, ...props }) => {
  const parseText = (text: string) => {
    const regex = /{{(.*?)}}/g;
    let result = '';
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(text)) !== null) {
      const word = match[1];
      result += text.substring(lastIndex, match.index);

      const isAllowed = allowedKeys.some((key) =>
        Array.from({ length: key.length }, (_, i) => key.toLowerCase().substring(0, i + 1)).includes(
          word.toLowerCase(),
        ),
      );

      if (!isAllowed) {
        result += `<span style="text-decoration: red wavy underline;">{{${word}}}</span>`;
      } else {
        result += `{{${word}}}`;
      }
      lastIndex = match.index + match[0].length;
    }
    result += text.substring(lastIndex);
    return result;
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <TextField
        value={props.value ?? ''}
        onChange={props.onChange}
        multiline
        fullWidth
        InputProps={{
          style: { position: 'relative', color: 'RGBA(255, 255, 255, 0.1)', caretColor: 'black', zIndex: 1 },
        }}
        spellCheck={false}
        {...props}
      />
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          padding: '18.5px 14px',
          paddingTop: '1.5rem',
          pointerEvents: 'none',
          whiteSpace: 'pre-wrap',
          width: '100%',
          boxSizing: 'border-box',
          zIndex: 0,
          color: 'black',
          fontFamily: 'inherit',
        }}
        dangerouslySetInnerHTML={{ __html: parseText((props.value as string) ?? '') }}
      />
    </div>
  );
};
