import React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { LeaseDispatchType, ReduxLease, ReduxTenant } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { useDispatch } from 'react-redux';
import { updateLeaseAction } from '../../redux/leasesSlice';
import { TenantInfo } from '../../../tenants/TenantInfo';

export const LeaseDetailPanel = ({
  row,
  leasesType,
  assetId,
}: {
  row: ReduxLease;
  leasesType: LeaseDispatchType;
  assetId: string;
}) => {
  const dispatch = useDispatch();

  const handleUpdateTenants = (tenants: ReduxTenant[]) => {
    dispatch(
      updateLeaseAction({
        leaseType: leasesType,
        assetId: assetId,
        body: {
          ...row,
          leaseTerms:
            row.leaseTerms?.map((term) => ({
              ...term,
              tenantGroup: { ...term.tenantGroup, tenants: tenants },
            })) ?? [],
        },
      }),
    );
  };

  const tenantGroup = row.leaseTerms ? row.leaseTerms[row.leaseTerms.length - 1]?.tenantGroup : undefined;

  return (
    <Stack sx={{ py: 2, height: 0.75, boxSizing: 'border-box', backgroundColor: 'lightgray' }} direction="column">
      <Paper sx={{ flex: 1, mx: 'auto', width: '80%', minHeight: '80%', p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 0.75 }}>
          {row.id && row.unitName && (
            <TenantInfo
              tenants={tenantGroup?.tenants ?? []}
              leaseId={row.id}
              onUpdateTenants={handleUpdateTenants}
              leasesType={leasesType}
              assetId={assetId}
            />
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};
