import {
  initInteractable,
  initInteractableCollection,
  initLoadable,
  Interactable,
  InteractableCollection,
  Loadable,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import {
  DefaultSelection,
  IAddTaskFromTenantRequestHandlerRequest,
  IGetRecurringPaymentHandlerResponse,
  IInitiateTenantAchPaymentHandlerRequest,
  IModernTreasuryAccount,
  InitiateAuthorizeNetPaymentHandlerResponse,
  IRecurringPayment,
  PlaidVerificationStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { mapReduxMtAccount, ReduxDate, ReduxMtAccount } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { ReduxTenantTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { AuthorizeNetCreditCardInitialization } from '../../_shared/paymentProcessing/paymentData';

export interface TenantState {
  leases: InteractableCollection<ReduxLease>;
  paymentHistoryMonths: Loadable<string[]>;
  bankAccounts: Interactable<ReduxMtAccount[]>;
  recurringPayment: Interactable<IRecurringPayment[]>;
  maintenanceRequests: InteractableCollection<ReduxTenantTask>;
  creditCardPayment: Loadable<AuthorizeNetCreditCardInitialization>;
  creditCardResult: any;
}

const initialState: TenantState = {
  leases: initInteractableCollection<ReduxLease>(),
  paymentHistoryMonths: initLoadable<string[]>(),
  bankAccounts: initInteractable<ReduxMtAccount[]>(),
  recurringPayment: initInteractableCollection<IRecurringPayment>(),
  maintenanceRequests: initInteractableCollection<ReduxTenantTask>(),
  creditCardPayment: initLoadable<AuthorizeNetCreditCardInitialization>(),
  creditCardResult: {},
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    getTenantLeaseByIdAction: (state, action: PayloadAction<string>) => {
      state.leases.loaded = false;
      state.leases.loading = true;
    },
    getTenantLeaseByIdSuccessAction: (state, action: PayloadAction<ReduxLease>) => {
      state.leases.loaded = true;
      state.leases.loading = false;
      const leaseIds = state.leases.value?.map((l) => l.id);
      if (leaseIds && !leaseIds.includes(action.payload.id)) {
        state.leases.value = state.leases.value ? [...state.leases.value, action.payload] : [action.payload];
      }
    },
    getTenantLeaseByIdFailAction: (state) => {
      state.leases.loaded = false;
      state.leases.loading = false;
    },
    setSelectedLeaseAction: (state, action: PayloadAction<string | undefined>) => {
      state.leases.selectedValue = state.leases?.value?.find((l) => l.id === action.payload);
    },
    clearAllTenantInfoAction: (state) => {
      state.leases.value = [];
      state.leases.selectedValue = undefined;
      state.bankAccounts.value = undefined;
      state.paymentHistoryMonths.value = undefined;
    },
    getUserBankAccountsAction: (state) => {
      state.bankAccounts.loading = true;
      state.bankAccounts.loaded = false;
    },
    getUserBankAccountsSuccessAction: (state, action: PayloadAction<ReduxMtAccount[]>) => {
      state.bankAccounts.loaded = true;
      state.bankAccounts.loading = false;
      state.bankAccounts.value = action.payload;
    },
    getUserBankAccountsFailAction: (state) => {
      state.bankAccounts.loading = false;
      state.bankAccounts.loaded = false;
      state.bankAccounts.value = undefined;
    },
    initiateTenantPaymentAction: (
      state,
      action: PayloadAction<{
        leaseId?: string;
        body: IInitiateTenantAchPaymentHandlerRequest;
        isDepositPayment: boolean;
      }>,
    ) => {},
    initiateTenantPaymentSuccessAction: (state) => {},
    initiateTenantPaymentFailAction: (state) => {},
    deleteTenantAccountAction: (state, action: PayloadAction<{ acctId: string }>) => {
      state.bankAccounts.loading = true;
    },
    deleteTenantAccountSuccessAction: (state) => {
      state.bankAccounts.loading = false;
    },
    deleteTenantAccountFailAction: (state) => {
      state.bankAccounts.loading = false;
    },
    makeAccountDefaultAction: (state, action: PayloadAction<string>) => {
      state.bankAccounts.submitting = true;
      state.bankAccounts.submitted = false;
    },
    makeAccountDefaultSuccessAction: (state) => {
      state.bankAccounts.submitting = false;
      state.bankAccounts.submitted = true;
    },
    makeAccountDefaultFailAction: (state) => {
      state.bankAccounts.submitting = false;
      state.bankAccounts.submitted = false;
    },
    getRecurringPaymentAction: (state) => {
      state.recurringPayment.loading = true;
      state.recurringPayment.loaded = false;
    },
    getRecurringPaymentSuccessAction: (state, action: PayloadAction<IGetRecurringPaymentHandlerResponse>) => {
      state.recurringPayment.loading = false;
      state.recurringPayment.loaded = true;
      state.recurringPayment.value = action.payload.recurringPayments;
    },
    getRecurringPaymentFailAction: (state) => {
      state.recurringPayment.loading = false;
      state.recurringPayment.loaded = false;
      state.recurringPayment.value = undefined;
    },
    createRecurringPaymentAction: (state, action: PayloadAction<IRecurringPayment>) => {
      state.recurringPayment.submitting = true;
      state.recurringPayment.submitted = false;
    },
    createRecurringPaymentSuccessAction: (state) => {
      state.recurringPayment.submitting = false;
      state.recurringPayment.submitted = true;
    },
    createRecurringPaymentFailAction: (state) => {
      state.recurringPayment.submitting = false;
      state.recurringPayment.submitted = false;
    },
    deleteRecurringPaymentAction: (state, action: PayloadAction<string>) => {
      state.recurringPayment.submitting = true;
      state.recurringPayment.submitted = false;
    },
    deleteRecurringPaymentSuccessAction: (state) => {
      state.recurringPayment.submitting = false;
      state.recurringPayment.submitted = true;
    },
    deleteRecurringPaymentFailAction: (state) => {
      state.recurringPayment.submitting = false;
      state.recurringPayment.submitted = false;
    },
    listTenantTasksAction: (state, action: PayloadAction<{ leaseId: string; selectedTaskId?: string }>) => {
      state.maintenanceRequests.loaded = false;
      state.maintenanceRequests.loading = true;
    },
    listTenantTasksSuccessAction: (state, action: PayloadAction<ReduxTenantTask[]>) => {
      state.maintenanceRequests.loaded = true;
      state.maintenanceRequests.loading = false;
      state.maintenanceRequests.value = action.payload;
    },
    listTenantTasksFailAction: (state) => {
      state.maintenanceRequests.loaded = false;
      state.maintenanceRequests.loading = false;
      state.maintenanceRequests.value = undefined;
    },
    createTaskFromTenantRequestAction: (
      state,
      action: PayloadAction<{ leaseId: string; body: IAddTaskFromTenantRequestHandlerRequest }>,
    ) => {
      state.maintenanceRequests.submitting = true;
      state.maintenanceRequests.submitted = false;
    },
    createTaskFromTenantRequestSuccessAction: (state) => {
      state.maintenanceRequests.submitting = false;
      state.maintenanceRequests.submitted = true;
    },
    createTaskFromTenantRequestFailAction: (state) => {
      state.maintenanceRequests.submitting = false;
      state.maintenanceRequests.submitted = false;
    },
    setSelectedMaintenanceRequestAction: (state, action: PayloadAction<string | undefined>) => {
      state.maintenanceRequests.selectedValue = state.maintenanceRequests?.value?.find((r) => r.id === action.payload);
    },
    updateTenantTaskAction: (
      state,
      action: PayloadAction<{ leaseId: string; taskId: string; body: ReduxTenantTask }>,
    ) => {
      state.maintenanceRequests.submitting = true;
      state.maintenanceRequests.submitted = false;
    },
    updateTenantTaskSuccessAction: (state) => {
      state.maintenanceRequests.submitting = false;
      state.maintenanceRequests.submitted = true;
    },
    updateTenantTaskFailAction: (state) => {
      state.maintenanceRequests.submitting = false;
      state.maintenanceRequests.submitted = false;
    },
    uploadTenantDocToTaskAction: (
      state,
      action: PayloadAction<{
        taskId: string;
        leaseId: string;
        file: File;
      }>,
    ) => {
      state.maintenanceRequests.submitting = true;
      state.maintenanceRequests.submitted = false;
    },
    uploadTenantDocToTaskSuccessAction: (state) => {
      state.maintenanceRequests.submitting = false;
      state.maintenanceRequests.submitted = true;
    },
    uploadTenantDocToTaskFailAction: (state) => {
      state.maintenanceRequests.submitting = false;
      state.maintenanceRequests.submitted = false;
    },
    addTenantCommentToTaskAction: (
      state,
      action: PayloadAction<{ taskId: string; leaseId: string; comment: string }>,
    ) => {
      state.maintenanceRequests.submitting = true;
      state.maintenanceRequests.submitted = false;
    },
    addTenantCommentToTaskSuccessAction: (state) => {
      state.maintenanceRequests.submitting = false;
      state.maintenanceRequests.submitted = true;
    },
    addTenantCommentToTaskFailAction: (state) => {
      state.maintenanceRequests.submitting = false;
      state.maintenanceRequests.submitted = false;
    },
    initializeCreditCardPaymentAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        requestedAmount: number;
        description: string;
        isDepositPayment: boolean;
      }>,
    ) => {
      state.creditCardPayment.loading = true;
      state.creditCardPayment.loaded = false;
      state.creditCardPayment.value = {
        requestedAmount: action.payload.requestedAmount,
        leaseId: action.payload.leaseId,
      };
    },
    initializeCreditCardPaymentSuccessAction: (
      state,
      action: PayloadAction<InitiateAuthorizeNetPaymentHandlerResponse>,
    ) => {
      state.creditCardPayment.loading = false;
      state.creditCardPayment.loaded = true;
      state.creditCardPayment.value = action.payload;
    },
    initializeCreditCardPaymentFailAction: (state) => {
      state.creditCardPayment.loading = false;
      state.creditCardPayment.loaded = false;
    },
    creditCardPaymentSuccessAction: (state, action: PayloadAction<{ result: any }>) => {
      state.creditCardResult = action.payload;
    },
    completeAuthorizeNetPaymentAction: (
      state,
      action: PayloadAction<{
        leaseId: string;
        body: {
          leaseId?: string;
          totalAmount?: number;
          merchantReferenceId?: string | undefined;
          transactionId?: string | undefined;
          dateCompleted?: string | undefined;
        };
      }>,
    ) => {},
    requestMoveOutDateAction: (
      state,
      action: PayloadAction<{ leaseId: string; moveOutDate: ReduxDate | undefined }>,
    ) => {
      state.leases.submitting = true;
      state.leases.submitted = false;
    },
    requestMoveOutDateSuccessAction: (state, action: PayloadAction<ReduxDate | undefined>) => {
      state.leases.submitting = false;
      state.leases.submitted = true;
      state.leases.selectedValue = {
        ...state.leases.selectedValue,
        moveOutDate: action.payload,
      };
    },
    requestMoveOutDateFailAction: (state) => {
      state.leases.submitting = false;
      state.leases.submitted = false;
    },
    updateIndividualAccountsFromSignalR: (state, action: PayloadAction<{ accounts: IModernTreasuryAccount[] }>) => {
      if (state.bankAccounts.value) {
        state.bankAccounts.value = action.payload.accounts.map((a) => mapReduxMtAccount(a));
      }
    },
    createUserMtAccountSuccessAction: (
      state,
      action: PayloadAction<{
        bankAccount: {
          namedByUser?: string | undefined;
          mtAccountId?: string;
          defaultSelection?: DefaultSelection;
          safeBankAccount?: string | undefined;
          name?: string | undefined;
          accountId?: string | undefined;
          mask?: string | undefined;
          type?: string | undefined;
          subType?: string | undefined;
          verificationStatus?: PlaidVerificationStatus;
          itemId?: string | undefined;
        };
      }>,
    ) => {
      if (state.bankAccounts.value) {
        if (!state.bankAccounts.value) state.bankAccounts.value = [action.payload.bankAccount];
        else state.bankAccounts.value.push(action.payload.bankAccount);
      }
    },
    plaidRevalidateSuccessAction: (state, action: PayloadAction<{ mtAccountId: string }>) => {
      if (state.bankAccounts.value) {
        const account = state.bankAccounts.value.find((a) => a.mtAccountId === action.payload.mtAccountId);
        if (account) account.isNeedsPlaidRefresh = false;
      }
    },
  },
});

export const {
  getTenantLeaseByIdFailAction,
  getTenantLeaseByIdSuccessAction,
  getTenantLeaseByIdAction,
  clearAllTenantInfoAction,
  getUserBankAccountsAction,
  getUserBankAccountsSuccessAction,
  getUserBankAccountsFailAction,
  initiateTenantPaymentAction,
  initiateTenantPaymentFailAction,
  initiateTenantPaymentSuccessAction,
  deleteTenantAccountAction,
  deleteTenantAccountFailAction,
  deleteTenantAccountSuccessAction,
  makeAccountDefaultAction,
  makeAccountDefaultFailAction,
  makeAccountDefaultSuccessAction,
  createRecurringPaymentAction,
  createRecurringPaymentFailAction,
  createRecurringPaymentSuccessAction,
  getRecurringPaymentAction,
  getRecurringPaymentFailAction,
  getRecurringPaymentSuccessAction,
  deleteRecurringPaymentAction,
  deleteRecurringPaymentFailAction,
  deleteRecurringPaymentSuccessAction,
  listTenantTasksAction,
  listTenantTasksFailAction,
  listTenantTasksSuccessAction,
  createTaskFromTenantRequestAction,
  createTaskFromTenantRequestFailAction,
  createTaskFromTenantRequestSuccessAction,
  setSelectedMaintenanceRequestAction,
  updateTenantTaskAction,
  updateTenantTaskFailAction,
  updateTenantTaskSuccessAction,
  uploadTenantDocToTaskAction,
  uploadTenantDocToTaskFailAction,
  uploadTenantDocToTaskSuccessAction,
  addTenantCommentToTaskAction,
  addTenantCommentToTaskFailAction,
  addTenantCommentToTaskSuccessAction,
  initializeCreditCardPaymentAction,
  initializeCreditCardPaymentSuccessAction,
  createUserMtAccountSuccessAction,
  initializeCreditCardPaymentFailAction,
  creditCardPaymentSuccessAction,
  completeAuthorizeNetPaymentAction,
  requestMoveOutDateAction,
  requestMoveOutDateFailAction,
  requestMoveOutDateSuccessAction,
  updateIndividualAccountsFromSignalR,
  setSelectedLeaseAction,
  plaidRevalidateSuccessAction,
} = tenantSlice.actions;

export const useTenant = () => useSelector((state: RootState) => state.tenant);
