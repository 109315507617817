import React, { FC } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { ListItem } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArticleIcon from '@mui/icons-material/Article';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import ClearIcon from '@mui/icons-material/Clear';
import { FileUploadButton } from '../../buttons/FileUploadButton';
import IconButton from '@mui/material/IconButton';

type AddAttachmentsProps = {
  attachments: File[];
  onAddAttachment: (file: File) => void;
  onDeleteAttachment: (file: File) => void;
};

export const AddAttachments: FC<AddAttachmentsProps> = ({ attachments, onAddAttachment, onDeleteAttachment }) => {
  return (
    <Stack direction={'row'}>
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <List dense>
          {attachments.map((a) => (
            <ListItem key={a.lastModified}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText>{a.name}</ListItemText>
              <ListItemIcon>
                <IconButton onClick={() => onDeleteAttachment(a)}>
                  <ClearIcon />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box>
        <FileUploadButton onUpload={onAddAttachment} isIcon />
      </Box>
    </Stack>
  );
};
