import React, { CSSProperties, FC, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { NotIntegrated } from '../../NotIntegrated';
import { Authorized } from '../../../auth/Authorized';
import { Roles } from '../../../../app/constants';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { TokenSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/tokenSingleton';
import { getAllAssetsAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { getVersionInfoAction, useGlobal } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useDispatch } from 'react-redux';
import { getCurrentUserInfoAction, useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { UserImpersonationDialog } from './UserImpersonationDialog';
import { appRoutes } from '../../../../AppRouter';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import pjson from '../../../../../package.json';

type UserMenuProps = {
  onlyHasTenantClaims: boolean;
};

export const UserMenu: FC<UserMenuProps> = ({ onlyHasTenantClaims }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useUser();
  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();
  const { version } = useGlobal();
  const webversion = `${pjson.version}.${process.env.REACT_APP_BUILD_NUMBER ?? '0'}`;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isImpersonating = TokenSingleton.isImpersonating();
  const [openImpersonation, setOpenImpersonation] = useState(false);
  const impersonatedUser = TokenSingleton.getImpersonatingUser();
  const profilePic = currentUser.value?.profilePicture?.uri
    ? currentUser.value?.profilePicture?.uri
    : isAuthenticated
      ? user?.picture
      : null;
  const userName = isImpersonating
    ? `${currentUser.value?.trueUser?.firstName} ${currentUser.value?.trueUser?.lastName}`
    : `${currentUser.value?.firstName} ${currentUser.value?.lastName}`;
  const hasTenantClaims = currentUser.value?.claims?.some((c) => c.type === 'Tenant');
  const isSuperAdmin = currentUser.value?.roles?.includes('SUPERADMIN');

  useEffect(() => {
    if (!version.api.loaded && currentUser.value?.roles?.includes('SUPERADMIN')) {
      dispatch(getVersionInfoAction());
    }
  }, [currentUser.value]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenImpersonation = () => {
    setOpenImpersonation(true);
    handleClose();
  };

  const handleCloseImpersonation = () => {
    setOpenImpersonation(false);
  };

  const handleEndImpersonation = () => {
    TokenSingleton.resetImpersonation();
    dispatch(getAllAssetsAction());
    dispatch(getCurrentUserInfoAction());
    handleClose();
  };
  const handleProfileClick = () => {
    navigate(`/${appRoutes.userRoutes.profile}`);
    handleClose();
  };

  const handleGoToTenantPortal = () => {
    navigate(`/${appRoutes.tenantPortal}`);
    handleClose();
  };

  return (
    <>
      {isAuthenticated ? (
        <>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Tooltip title={isImpersonating ? `Impersonating User: ${impersonatedUser}` : userName}>
              <Badge
                badgeContent={'i'}
                color="warning"
                invisible={!isImpersonating}
                overlap="circular"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                {profilePic ? (
                  <Box sx={styles.profilePicture} style={{ backgroundImage: `url(${profilePic})` } as CSSProperties} />
                ) : (
                  <AccountCircle sx={{ color: 'white', fontSize: '3rem' }} />
                )}
              </Badge>
            </Tooltip>
          </IconButton>
        </>
      ) : (
        <Button onClick={() => loginWithRedirect()} sx={{ boxShadow: 'none', color: 'white' }}>
          Login
        </Button>
      )}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem sx={{ fontStyle: 'italic' }}>{userName}</MenuItem>
        <MenuItem sx={{ fontStyle: 'italic' }}>{currentUser.value?.trueUser?.email}</MenuItem>
        <Divider />
        <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
        <NotIntegrated>
          <MenuItem onClick={handleClose}>My Account</MenuItem>
        </NotIntegrated>
        {!onlyHasTenantClaims && (hasTenantClaims || isSuperAdmin) && (
          <MenuItem onClick={handleGoToTenantPortal}>Tenant Portal</MenuItem>
        )}
        <Authorized trueUser role={Roles.SuperAdmin}>
          {isImpersonating ? (
            <>
              <MenuItem onClick={handleOpenImpersonation}>Switch User</MenuItem>
              <MenuItem onClick={handleEndImpersonation}>End Impersonation</MenuItem>
            </>
          ) : (
            <>
              <MenuItem onClick={handleOpenImpersonation}>Impersonate User</MenuItem>
              <MenuItem>Web Version: {webversion}</MenuItem>
              <MenuItem>API Version: {version.api.value}</MenuItem>
            </>
          )}
        </Authorized>
        <MenuItem onClick={() => logout({ logoutParams: { returnTo: `${process.env.REACT_APP_WEB_URL}/logout` } })}>
          Logout
        </MenuItem>
      </Menu>
      <UserImpersonationDialog open={openImpersonation} onClose={handleCloseImpersonation} />
    </>
  );
};

const styles = {
  profilePicture: {
    borderRadius: '50%',
    width: '3.5rem',
    height: '3.5rem',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 1,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
};
