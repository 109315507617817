import { AssetType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { AssetTab, OverviewTab } from '../tabs/TabTypeEnums';

export const getRouteForAssociation = (associationType: AssetType, id: string): string => {
  switch (associationType) {
    case AssetType.RentalProperty:
      return `/assets/property/${id}/${AssetTab.Overview}/${OverviewTab.Metrics}`;
    case AssetType.Building:
      return `/assets/building/${id}/${AssetTab.Overview}/${OverviewTab.Notifications}`;
    case AssetType.BuildingUnit:
      return `/assets/unit/${id}//${AssetTab.Overview}/${OverviewTab.Notifications}`;
    case AssetType.Room:
      return `/assets/room/${id}`;
  }
};
