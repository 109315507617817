import React, { FC, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { getKeyByValue } from '../../../../../_shared/utils/getMapKeyByValue';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { getAvailableReportsAction, setSelectedReportAction, useReports } from '../../../../redux/reportSlice';
import { useDispatch } from 'react-redux';
import { convertVariableToDisplayName, reportTypeMap } from './useFormatReportData';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { ReportType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { endpointIsntIntegratedAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';

export const SelectReport: FC = () => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const { selectedReport, reportOptions } = useReports();
  const [reportType, setReportType] = useState<ReportType | undefined | 'Favorites'>();
  const [rOptions, setROptions] = useState(reportOptions.value);
  const typeOptions = ['Favorites', ...Array.from(reportTypeMap.values())];

  useEffect(() => {
    if (reportType === undefined) setROptions(reportOptions.value);
    else if (reportType === 'Favorites') {
      dispatch(endpointIsntIntegratedAction());
    } else setROptions(rOptions?.filter((o) => o.reportType === reportType));
  }, [reportType]);

  useEffect(() => {
    selectedContext.propertyId && dispatch(getAvailableReportsAction(selectedContext.propertyId));
  }, []);

  useEffect(() => {
    setROptions(reportOptions.value);
  }, [reportOptions.value]);

  return (
    <Stack spacing={2}>
      <Autocomplete
        renderInput={(params) => <TextField label={'Filter By Report Type'} {...params} />}
        options={typeOptions}
        value={
          reportType && reportType !== 'Favorites'
            ? reportTypeMap.get(reportType)
            : reportType === 'Favorites'
              ? 'Favorites'
              : null
        }
        onChange={(event, newValue) => {
          if (newValue === 'Favorites') {
            setReportType('Favorites');
            dispatch(endpointIsntIntegratedAction());
          } else {
            const v = getKeyByValue(reportTypeMap, newValue);
            setReportType(v);
          }
        }}
      />
      <Box border={'.1rem solid gray'} borderRadius={1} display={'flex'} flexGrow={1} minHeight={350}>
        <Stack spacing={1} sx={{ display: 'flex', flexGrow: 1, p: 1 }}>
          {rOptions?.map((r) => (
            <Card elevation={0} key={r.name} sx={{ border: '.1rem solid gray', p: 1 }}>
              <Stack>
                <Tooltip title={r.description ?? ''}>
                  <Box display={'flex'} flexGrow={1} justifyContent={'space-between'}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={selectedReport.value === r}
                            onChange={() => {
                              if (selectedReport.value === r) {
                                dispatch(setSelectedReportAction(undefined));
                              } else dispatch(setSelectedReportAction(r));
                            }}
                          />
                        }
                        label={convertVariableToDisplayName(r.name)}
                      />
                    </FormControl>
                    {/*<Tooltip title={r.favorite ? 'Favorite' : ''}>*/}
                    {/*  <IconButton>{r.favorite ? <StarIcon /> : <StarBorderIcon />}</IconButton>*/}
                    {/*</Tooltip>*/}
                    <Box>
                      <IconButton onClick={() => dispatch(endpointIsntIntegratedAction())}>
                        <StarBorderIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Tooltip>
              </Stack>
            </Card>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};
