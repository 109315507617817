import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type ImageProcessingProps = {
  onClick?: () => void;
  failMessage?: string;
};

export const ImageProcessing: FC<ImageProcessingProps> = ({ onClick, failMessage }) => {
  return (
    <Box
      height={'100%'}
      minHeight={'5rem'}
      padding={1}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      bgcolor={'background.paper'}
      borderRadius={2}
      border={'1px dashed'}
      borderColor={'divider'}
      width={'100%'}
      onClick={onClick}
    >
      <Typography variant={'body2'}>{failMessage ? failMessage : '*IMAGE PROCESSING*'}</Typography>
    </Box>
  );
};
