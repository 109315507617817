import React from 'react';
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { ISlimAccountRef } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const GlCodeCell = <TGridValidRowModel extends GridValidRowModel>(
  params: GridRenderCellParams<TGridValidRowModel, ISlimAccountRef>,
) => {
  const account = params.value;
  if (account) return <>{account.name}</>;
  else return <></>;
};
