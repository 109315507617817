import React from 'react';
import { KpiDisplay } from './KpiDisplay';
import Grid from '@mui/material/Grid';
import { useOccupancyMetrics } from '@monkeyjump-labs/cam-fe-shared/dist/redux/metrics/metricsSlice';

export const CurrentLeaseStatusMetrics = () => {
  const { currentPropertyMetrics } = useOccupancyMetrics();
  return (
    <Grid container spacing={2} paddingY={'1rem'} paddingX={'.2rem'}>
      <Grid item sm={4} xs={12}>
        <KpiDisplay
          title={'Leases to Close'}
          kpi={currentPropertyMetrics.value?.occupancyMetrics?.leasesToBeClosed ?? 0}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <KpiDisplay
          title={'Leases Under Review'}
          kpi={currentPropertyMetrics.value?.occupancyMetrics?.leasesUnderReview ?? 0}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <KpiDisplay
          title={'Month-to-Month Leases'}
          kpi={currentPropertyMetrics.value?.occupancyMetrics?.monthToMonthUnits ?? 0}
        />
      </Grid>
    </Grid>
  );
};
