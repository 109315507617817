import React, { FC, ReactElement, ReactNode } from 'react';
import Stack from '@mui/material/Stack';

type ListingCardDetailProps = {
  icon?: ReactElement;
  children?: ReactNode;
  direction?: 'row' | 'column';
};

export const ListingDetail: FC<ListingCardDetailProps> = ({ icon, children, direction }) => {
  return (
    <Stack direction={'row'} alignItems={'center'}>
      {icon
        ? React.cloneElement(icon, {
            sx: styles.icon,
          })
        : null}
      <Stack direction={direction ?? 'column'}>{children}</Stack>
    </Stack>
  );
};

const styles = {
  icon: {
    marginRight: 1,
    color: 'primary.main',
    fontSize: 30,
  },
};
