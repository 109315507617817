import { put, takeLatest } from 'redux-saga/effects';
import {
  getEventsAction,
  getEventsFailAction,
  getEventsSuccessAction,
  updateEventDateRangeAction,
  updateEventDateRangeFailAction,
  updateEventDateRangeSuccessAction,
} from './calendarSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  AssetType,
  AssociationType,
  ICalendarEvent,
  IListEventsForAssetHandlerRequest,
  ListEventsForAssetHandlerRequest,
  ListEventsForAssetHandlerResponse,
  UpdateEventDateRangeHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { showErrorAction, showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { mapReduxCalendarEvent } from './calendarData';
import { toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

function* getEvents(
  action: PayloadAction<{ assetId: string; assetType: AssetType; body: IListEventsForAssetHandlerRequest }>,
) {
  try {
    const response: ListEventsForAssetHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().calendar_ListCalendarEventsForAsset,
      action.payload.assetId,
      action.payload.assetType,
      new ListEventsForAssetHandlerRequest(action.payload.body),
    );
    if (response) {
      yield put(
        getEventsSuccessAction(
          response.toJSON().events.map((calendarEvent: ICalendarEvent) => {
            return mapReduxCalendarEvent(calendarEvent);
          }),
        ),
      );
    }
  } catch (error) {
    yield put(getEventsFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting calendar events' }));
  }
}

function* updateEventDateRange(
  action: PayloadAction<{ id: string; associationType: AssociationType; start: string; end?: string }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().calendar_UpdateEventDateRange,
      action.payload.id,
      action.payload.associationType,
      new UpdateEventDateRangeHandlerRequest({
        startDateTime: toStandardDate(action.payload.start),
        endDateTime: toStandardDate(action.payload.end),
      }),
    );
    yield put(updateEventDateRangeSuccessAction());
    yield put(showToastMessageAction({ message: 'Event date range updated', severity: 'success' }));
  } catch (error) {
    yield put(updateEventDateRangeFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating event date range' }));
  }
}

export function* calendarSaga() {
  yield takeLatest(getEventsAction.type, getEvents);
  yield takeLatest(updateEventDateRangeAction.type, updateEventDateRange);
}
