import React, { FC } from 'react';
import { GridActionsCellItem, GridActionsCellItemProps } from '@mui/x-data-grid-premium';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

type ActionCellProps = {
  disabledLabel?: string;
  color?: 'inherit' | 'default' | 'warning' | 'error' | 'success' | 'info' | 'primary' | 'secondary' | undefined;
  loading?: boolean;
} & GridActionsCellItemProps;

export const ActionCell: FC<ActionCellProps> = ({ disabledLabel, disabled, label, loading, color, ...props }) => {
  const internalLabel = disabled && disabledLabel ? disabledLabel : label;

  if (loading) {
    return props.showInMenu ? (
      <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', minWidth: 100 }}>
        <CircularProgress size={24} />
      </Box>
    ) : (
      <CircularProgress size={24} />
    );
  }
  return props.showInMenu ? (
    <GridActionsCellItem
      {...props}
      label={internalLabel}
      disabled={disabled}
      className="textPrimary"
      color={color ?? 'inherit'}
    />
  ) : (
    <Tooltip title={internalLabel}>
      <span>
        <GridActionsCellItem
          {...props}
          label={internalLabel}
          disabled={disabled}
          className="textPrimary"
          color={color ?? 'inherit'}
        />
      </span>
    </Tooltip>
  );
};
