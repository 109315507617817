import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Sapphire } from '../../../AppTheme';
import { useTheme } from '@mui/material';

type Props = {
  label: string;
  children?: ReactNode;
};

export const StyledBox = styled(Box)(({ theme }) => ({
  minHeight: '5rem',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(4),
}));

const labelStyles = {
  fontSize: '1.2rem',
  fontWeight: 600,
  padding: '.3rem',
  margin: '.3rem',
  color: Sapphire[700],
  '&.Mui-focused': {
    color: Sapphire[700],
  },
  backgroundColor: 'white',
};

const borderStyles = {
  border: '.25rem solid transparent',
  borderRadius: 4,
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box, border-box',
};

export const StyledInfoBox = ({ label, children }: Props) => {
  const theme = useTheme();
  const boxRef = useRef<HTMLDivElement>(null);
  const [backgroundImage, setBackgroundImage] = useState<string>(
    `linear-gradient(white, white), linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main})`,
  );

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (boxRef.current) {
        const rect = boxRef.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        const degree = Math.atan2(y - rect.height / 2, x - rect.width / 2) * (180 / Math.PI) + 90;
        const gradient = `linear-gradient(white, white), linear-gradient(${degree}deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`;
        setBackgroundImage(gradient);
      }
    };

    const handleMouseLeave = () => {
      setBackgroundImage(
        `linear-gradient(white, white), linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main})`,
      );
    };

    if (boxRef.current) {
      boxRef.current.addEventListener('mousemove', handleMouseMove);
      boxRef.current.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (boxRef.current) {
        boxRef.current.removeEventListener('mousemove', handleMouseMove);
        boxRef.current.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);
  return (
    <FormControl component="fieldset" sx={{ ...borderStyles, backgroundImage: backgroundImage }} fullWidth={true}>
      <FormLabel component="legend" sx={labelStyles}>
        {label}
      </FormLabel>
      <StyledBox ref={boxRef}>{children}</StyledBox>
    </FormControl>
  );
};
