import React from 'react';
import Stack from '@mui/material/Stack';
import { ApplicantList } from '../../applications/ApplicantList';
import { VacantUnitsTable } from './VacantUnitsTable';

export const ApplicationsTab = () => {
  return (
    <Stack direction="column" spacing={2}>
      <ApplicantList />
      <VacantUnitsTable />
    </Stack>
  );
};
