import React from 'react';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { UnitListing } from './UnitListing';
import { ListListings } from './ListListings';

export const AdvertiseListings = () => {
  const { selectedContext } = useAssets();

  return selectedContext.unitId ? <UnitListing /> : <ListListings />;
};
