import React, { CSSProperties, FC, ReactElement } from 'react';
import { Box, IconProps, useTheme } from '@mui/material';

type ImageWithOverlayProps = {
  uri: string;
  icon?: ReactElement<IconProps>;
  onClick?: () => void;
  maxWidth?: number;
  maxHeight?: number;
};

export const ImageWithOverlay: FC<ImageWithOverlayProps> = ({ uri, icon, onClick, maxHeight, maxWidth }) => {
  const theme = useTheme();

  const imgStyle: CSSProperties = {
    objectFit: 'cover',
    cursor: 'zoom-in',
    maxWidth,
    maxHeight,
  };
  const internalContainerStyle: CSSProperties = {
    maxWidth,
    maxHeight,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  };

  return (
    <Box
      border={'.2rem solid'}
      borderColor={theme.palette.primary.main}
      sx={{ ...internalContainerStyle, position: 'relative' }}
      onClick={onClick}
    >
      <img src={uri} alt={'360 Image'} style={imgStyle} />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          fontSize: '1.5em',
          opacity: 1,
          transition: 'opacity 0.5s ease',
        }}
      >
        {icon}
      </Box>
    </Box>
  );
};
