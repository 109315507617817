import React, { FC, FormEvent, useEffect, useState } from 'react';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { useDispatch } from 'react-redux';
import { createExpenseAction, useExpenses } from '../../redux/expenseSlice';
import Stack from '@mui/material/Stack';
import { AssetType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type AddJobDialogProps = {
  onClose: () => void;
  assetId: string;
  assetType: AssetType;
} & Omit<DialogProps, 'onClose'>;

export const AddExpenseDialog: FC<AddJobDialogProps> = ({ onClose, assetId, assetType, ...props }) => {
  const { selectedContext } = useAssets();
  const { expenses } = useExpenses();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  useEffect(() => {
    expenses.submitted && handleClose();
  }, [expenses.submitted]);

  const handleClose = () => {
    setName('');
    setDescription('');
    onClose();
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    selectedContext.propertyId &&
      dispatch(
        createExpenseAction({
          propertyId: selectedContext.propertyId,
          body: { name: name, description: description, assetType: assetType, associatedId: assetId },
        }),
      );
  };

  return (
    <DialogLayout title={'Add Expense'} onClose={handleClose} open={props.open}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              variant={'outlined'}
              label={'Name'}
              value={name}
              required
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              label={'Description'}
              value={description}
              fullWidth
              multiline
              rows={4}
              variant={'outlined'}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <LoadingButton variant={'contained'} type={'submit'} loading={expenses.submitting}>
            Add Expense
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};
