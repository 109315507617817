import React, { FormEvent, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Gender, IPet } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import LoadingButton from '@mui/lab/LoadingButton';
import { useApplications } from '../../../../redux/applicationSlice';
import DialogActions from '@mui/material/DialogActions';

type Props = {
  addPet: (type: string, newPet: IPet) => void;
  open: boolean;
  onClose: () => void;
};

const blankPet = {
  gender: Gender.PreferNotToSay,
  type: '',
  age: undefined,
};

export const AddPetDialog = ({ addPet, open, onClose }: Props) => {
  const { allApplications } = useApplications();
  const [dirty, setDirty] = useState(false);
  const [newPet, setNewPet] = useState<IPet>(blankPet);

  useEffect(() => {
    if (allApplications.submitted) handleClose();
  }, [allApplications.submitted]);

  const handleUpdatePet = (key: keyof IPet, value: string) => {
    setNewPet({ ...newPet, [key]: value });
    setDirty(true);
  };

  const handleClose = () => {
    onClose();
    setNewPet(blankPet);
  };

  const handleAddPet = (e: FormEvent) => {
    e.preventDefault();
    setDirty(false);
    addPet('pets', newPet);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleAddPet} autoComplete="off">
        <DialogTitle variant="h6">Add Pet</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: '.2rem' }}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required
                margin="dense"
                id="type"
                label="Type/Breed"
                type="text"
                variant="standard"
                value={newPet.type ? newPet.type : ''}
                onChange={(e) => handleUpdatePet('type', e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                fullWidth
                required
                margin="dense"
                id="age"
                label="Age"
                type="number"
                variant="standard"
                value={newPet.age ? newPet.age : ''}
                onChange={(e) => handleUpdatePet('age', e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="select-gender">Gender</InputLabel>
                <Select
                  labelId="select-gender"
                  id="gender"
                  value={newPet.gender}
                  label="Gender"
                  onChange={(e) => handleUpdatePet('gender', e.target.value)}
                >
                  <MenuItem value={Gender.PreferNotToSay}>Other/Prefer Not To Say</MenuItem>
                  <MenuItem value={Gender.Male}>Male</MenuItem>
                  <MenuItem value={Gender.Female}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <LoadingButton type="submit" variant="contained" disabled={!dirty} loading={allApplications.submitting}>
            Add Pet
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
